
import { styled } from '@mui/material/styles';

// Components
import { FormControl, InputLabel, Badge, Select, MenuItem, IconButton } from '@mui/material';

// Icons
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";

import { MediaQuerys } from "../../../styles/GlobalStyle";
import { useColors } from '../../../Context/ColorsContext';

const { mobile, tablet, desktop } = MediaQuerys;


export const ContainerFilters = styled('form')(({ activeswitch }) => {
    return {
        display: 'flex',
        width: '100%',
        marginTop: '16px',
        flexDirection: 'row',
        gap: '0.80rem',
        justifyContent: 'flex-end',
        alignItems: 'center',
        margin: '10px 0px',
        flexWrap: 'wrap',
        [desktop]: {
            justifyContent: activeswitch && 'start',
            alignItems: 'baseline',
        }
    }
});


export const CleanIcon = styled(CleaningServicesIcon)(() => {
    const { colors } = useColors();
    return {
        color: colors.white,
        fontSize: 18,
        [tablet]: {
            width: 17,
            height: 17,
            padding: '1px',
            '.MuiSvgIcon-root': {
                width: 16,
                height: 16,
            }
        },
        [desktop]: {
            width: '16px',
            height: '16px',
        }
    };
});

export const ContainerSearch = styled("div")(() => ({
    display: "flex",
    flexDirection: "row",
    gap: "2px",
    alignItems: "center",
}));

export const ContainerGroup = styled("div")(({ needMagin }) => {
    return {
        display: "flex",
        alignItems: "center",
        gap: "0.50rem",
        flexDirection: "row",
        marginLeft: needMagin && 5,
    };
});

export const ContainerInputsDate = styled("div")(({theme, errorstart, errorend}) => ({
    '& .MuiButtonBase-root': {
        color: (errorstart || errorend) ? theme.palette.error.main : 'inherit', 
    },
    '& .css-4jnixx-MuiStack-root>.MuiTextField-root': {
        minWidth: "80px",
    },
    '& .MuiFormLabel-root': {
        fontSize: "0.8rem",
        [desktop]: {
            fontSize: "0.75rem"
        }
    },
    '& .MuiInputBase-root': {
        fontSize: "0.8rem",
    },
    '& .MuiStack-root': {
        paddingTop: '0px !important',
        overflow: 'visible !important',
    },
    '& .MuiTextField-root': {
        minWidth: '120px !important',
        width: '150px !important',
        [desktop]: {
            width: '120px',
        }
    },
    // '& .css-4jnixx-MuiStack-root': {
    //     overflow: "visible",
    //     paddingTop: '0px !important',
    //     width: '150px',
    //     [desktop]: {
    //         width: '120px',
    //     },
    // },
}));





export const CustomFormControl = styled(FormControl)(() => {
    return {
        width: "150px",
        [desktop]: {
            width: "110px",
            "& .MuiFormLabel-root": {
                fontSize: "0.75rem",
            },
        },
    };
});

export const CustomInputLabel = styled(InputLabel)(() => {
    return {
        fontSize: "0.8em",
        position: "absolute",
        top: "50%",
        left: "40%",
        transform: "translate(-50%, -50%)",
        [tablet]: {
            fontSize: "0.75rem",
        }
    };
});

export const CustomBadge = styled(Badge)(() => {
    return {
        color: "white",
        [desktop]: {
            "& .MuiBadge-badge": {
                minWidth: "15px !important",
                width: "15px !important",
                height: "15px !important",
            },
            fontSize: "0.75rem",
        },
    };
});

export const CustomSelect = styled(Select)(() => {
    return {
        width: "150px",
        height: "32px",
        [desktop]: {
            width: "110px",
        },
        [tablet]: {
            width: "120px",
            height: 26,
        },
    };
});

export const CustomMenuItem = styled(MenuItem)(() => {
    return {
        // '& .MuiList-root': {
        //     paddingTop: '0px !important',
        // },
        // '& .MuiFormControl-root': {
        //     marginTop: '8px !important',
        // },
        [desktop]: {
            "& .MuiTypography-root": {
                fontSize: "0.75rem",
            },
        },
    };
});

export const ContainerSelects = styled('div')(() => {
    return {
        display: "flex",
        flexDirection: "row",
        gap: '0.80rem',
    }
});