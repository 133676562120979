import {styled} from "@mui/material/styles";
import {
	Drawer,
  Icon,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { MediaQuerys } from "../../styles/GlobalStyle";
import { useColors } from "../../Context/ColorsContext"

const drawerWidth = 250;
const { mobile, tablet, desktop } = MediaQuerys;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  [desktop]:{
    width: '200px'
  }
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

export const DrawerHeader = styled("div")(({ theme }) => ({
  color: "white",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

export const CustomDrawer = styled(Drawer)(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
		[theme.breakpoints.up('md')]: {
			whiteSpace: "nowrap",
			boxShadow: "rgb(61 71 82 / 20%) 0px 4px 8px",
			boxSizing: "border-box",
			zIndex: 999,
			...(open && {
				...openedMixin(theme),
				"& .MuiDrawer-paper": openedMixin(theme),
			}),
			...(!open && {
				...closedMixin(theme),
				"& .MuiDrawer-paper": closedMixin(theme),
			}),
		}
}));


export const Item = styled(ListItemButton)(() => {
    const { colors } = useColors();

    return {
        '&.Mui-selected > *': {
            color: colors.white,
        },
        '&.Mui-selected': {
            background: colors.sidebarSelectColor,
            ':hover': {
                background: colors.sidebarSelectColor,
                opacity: 0.9
            }
        },
        ':hover > *': {
            color: colors.white,
        },
        ':hover': {
            background: colors.sidebarSelectColor,
        },
        color: colors.grayFont, 
        width: '100%',
        paddingLeft: '10px'
    }
})

export const ItemIcon = styled(ListItemIcon)(() => {
    const { colors } = useColors();

    return  {
        padding: '10px 1px',
        minWidth: '30px',
        color: colors.grayFont,
        [desktop]: {
          ".MuiListItemIcon-root": {
              fontSize: "0.20rem !important",
            },
          ".material-icons-outlined": {
            fontSize: '20px !important',
          },
        },
    }
})

export const ItemText = styled(ListItemText)({
  '.MuiTypography-root': {
    fontSize: "0.85rem !important",
  },
  [desktop]: {
    ".MuiTypography-root": {
      fontSize: "10px  !important",
    },
  },
  [mobile]: {
    ".MuiTypography-root": {
      fontSize: "10px !important",
    },
  },
  [tablet]: {
    ".MuiTypography-root": {
      fontSize: "10px !important",
    },
  },
});

// export const CustomIcon = styled(Icon)({
//     fontSize: 24,
//     [mobile]: {
//         // fontSize: 0.75rem,
//     },
//     [tablet]:{
//         fontSize: 20
//     }
// })

export const CustomIcon = styled(Icon)(({ theme }) => ({
  fontSize: 24,
  // [theme.breakpoints.down('sm')]: {
  //     fontSize: '0.75rem',
  // },
  // [theme.breakpoints.down('md')]: {
  //     fontSize: '0.75rem',
  // }
}));
