import {styled} from "@mui/material/styles";
import { Box, Card, CardContent, CircularProgress, Typography, Grid } from "@mui/material";
import { MediaQuerys } from "../../styles/GlobalStyle";

const { mobile, tablet, desktop } = MediaQuerys;

export const Content = styled(CardContent)(() => ({
    padding: '10px !important'
}))

export const CardDetail = styled(Card)(() => ({
    width: '100%',
    height: '350px', 
    flex: 1,
    overflowY: 'auto'
}))

export const Loading = styled(CircularProgress)(() => ({
    margin: '0 auto', 
    position: 'absolute'
}))

export const Title = styled(Typography)(() => ({
    fontWeight: 'bold',
    textAlign: 'center'
}))

export const Container = styled('div')(() => ({
    height: '100%',
    overflow: 'auto',
     '&::-webkit-scrollbar': {
        width: 5,
        height: 5
    },
}))

export const BoxContainer = styled(Box)(() => ({
    '&::-webkit-scrollbar': {
        width: 5,
        height: 5
    },
    overflowY: 'auto',
    maxHeight: '530px', 
    // '@media only screen and (max-width: 1199px)': {
    //     maxHeight: '530px', 
    // },
    // '@media only screen and (min-width: 1200px)': {
    //     maxHeight: '380px', 
    // },
    // '@media only screen and (min-width: 1400px)': {
    //     maxHeight: '530px',
    // },
    [desktop]: {
        maxHeight: '380px'
    },
    [mobile]:{
        overflowX: 'auto',
        maxHeight: '300px',
    },
    [tablet]: {
        maxHeight: '380px',
    }
}))

export const ContainerContent = styled('div')(() => {
    return {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        gap: '0.70rem',
        [mobile]: {
            flexDirection: 'column',
        }
    }
})

export const ContainerLeft = styled('div')(() => {
    return {
        width: '30%',
        [mobile]: {
            width: '100%',
        }
    }
})

export const ContainerRight = styled('div')(() => {
    return {
        width: '70%',
        [mobile]: {
            width: '100%',
        }
    }
})

export const ContainerCards = styled(Grid)(() => {
    return {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.50rem',
    marginBottom: 2,
    ":not(:last-child)": {
      marginBottom: 16,
    },
    [tablet]: {
      display: "flex",
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
    },
    [mobile]: {
      flexDirection: 'row',
    }
    }
})
