import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";

// Components
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next'
import { Icon, Modal, useMediaQuery } from '@mui/material';

// Custom components
import { PageTitle } from '../../components/PageTitle';
import { BackdropLoading } from '../../components/BackdropLoading';
import { TabPanel } from '../../components/atoms/tabPanel';
import { Attachments } from '../../components/Attachments';
import { InstanceStateModalCard } from '../../components/atoms/modalCards/InstanceStateModalCard';
import { WorkPermitDetailTab } from '../WorkPermitDetailTab';
import { TimeLine } from '../../components/TimeLine';
import { ShowDialog } from '../../components/ShowDialog';
import { InstanceNotificationsModal } from '../../components/atoms/modalCards/InstanceNotificationsModal';
import WorkPermitDetailData from '../../components/atoms/workPermitDetailData';
import { WorkflowDataRelation } from '../WorkflowDataRelation';
import { WorkflowDataRelationDetail } from '../WorkflowDataRelationDetail';

// Icons
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// Styles
import { BoxModal, Container } from '../styles';
import { SectionPageTitle, CustomCard, CustomTabs, ItemTab, ContainerContent, ContainerRight, ContainerLeft, IconArea, DetailInfo } from './styles';

// API
import Router from '../../router';
import { WorkflowApi } from '../../services/WorkflowApi';
import { WorkflowDataApi } from '../../services/WorkflowDataApi';
import { WorkflowDataFileApi } from '../../services/WorkflowDataFileApi';
import { WorkflowStatusApi } from '../../services/WorkflowStatusApi';

// Utils
import { CheckResponse } from '../../utils/checkResponse';
import { redirectToAction, returnInstanceNotifications } from '../../utils/commonFunctions';
import { downloadPDF } from '../../utils/commonFunctions/downloadPdf';
import { Auth } from "../../utils/auth";

// Context
import { ColorsContext } from "../../Context/ColorsContext"

// Permissions
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../Context/PermissionsContext';

import { pdfTableStyleDetail } from '../DynamicForm/pdfTableStyleDetail';

export const WorkPermitDetail = (props) => {

    // Obtener el id para hacer el request, mientras usar un random del api fake
    const location = useLocation();
    const params = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { colors } = React.useContext(ColorsContext);
    const ability = useAbility(AbilityContext);
    const isMediumOrSmaller = useMediaQuery(theme => theme.breakpoints.down('md'));

    const workflowDataApi = new WorkflowDataApi();
    const workflowApi = new WorkflowApi();
    const workflowDataFileApi = new WorkflowDataFileApi();
    const workflowStatusApi = new WorkflowStatusApi();
    const { check } = new CheckResponse();
    const { getUser } = new Auth();

    const [formOrig, setFormOrig] = useState(null)
    const [row, setRow] = useState(null)
    const [canCancel, setCanCancel] = useState(null)
    const [rowOrig, setRowOrig] = useState(null)
    const [workflow, setWorkflow] = useState(null)
    const [timeline, setTimeline] = useState([]);
    const [instanceTime, setInstanceTime] = useState(null);
    const [formVersion, setFormVersion] = useState('form_builder');
    const [detailForm, setDetailForm] = useState([]);
    const [detailPendingForm, setDetailPendingForm] = useState([]);
    const [flowRelations, setFlowRelations] = useState([]);
    const [attachments, setAttachments] = useState([]);
    const [expanded, setExpanded] = useState(false);
    const [attachmentsId, setAttachmentsId] = useState(null);
    const [from, setFrom] = useState(null);
    const [title, setTitle] = useState('');
    const [idDisable, setIdDisable] = useState({})
    const originalStylesRef = useRef("");
    const currentFormRef = useRef(undefined);

    // State buttons
    const [showFormButtons, setShowFormButtons] = useState(false);
    const [showDeleteButton, setShowDeleteButton] = useState(false);
    const [showActiveButton, setShowActiveButton] = useState(false);
    const [showPreActiveButton, setShowPreActiveButton] = useState(false);
    const [showPauseButton, setShowPauseButton] = useState(false);
    const [showResumeButton, setShowResumeButton] = useState(false);

    const [observations, setObservations] = useState('');
    const [errorObservations, setErrorObservations] = useState('');

    // Loading and actions messages
    const [loading, setLoading] = useState(true);
    const [loadingForm, setLoadingForm] = useState(false);
    const [loadingProcess, setLoadingProcess] = useState(false);
    const [formsTitles, setFormsTitles] = useState([]);
    const [pendingFormsTitles, setPendingFormsTitles] = useState([]);
    const [dialog, setDialog] = useState({
        status: false,
        title: t('workflow.detail.messages.saveData.title'),
        message: t('workflow.detail.messages.saveData.message'),
        type: null
    });
    const [dialogPop, setDialogPop] = useState({
        status: false,
        title: t('workflow.detail.messages.noActions.title'),
        message: '',
    });

    // Instance state
    const [openInstanceStateModal, setOpenInstanceStateModal] = useState({
        status: false,
        action: null,
        isDelete: false
    })

    const [reload, setReload] = useState(false)
    const [tab, setTab] = useState(0)
    const formRef = useRef(null);
    const [loadingPdf, setLoadingPdf] = useState(false)
    const [notificationsModal, setNotificationsModal] = useState({
        status: false,
        notifications: [],
        instance: {}
    });

    // External
    const [isExternal, setIsExternal] = useState(false)

    useEffect(() => {
    }, [location?.state?.row?.notificationId, reload])

    function clearData() {
        let btnCanCancel = JSON.parse(localStorage.getItem('workflowData'))?.canCancel;
        setCanCancel(btnCanCancel)
        setRow(null)
        setWorkflow(null)
        setTab(0)
        setShowPreActiveButton(false)
        setShowActiveButton(false)
        setShowPauseButton(false)
        setShowResumeButton(false)
        setShowDeleteButton(false)
        setShowFormButtons(false)
        localStorage.removeItem("workflowData");
        localStorage.removeItem("workflowStatus");
        localStorage.removeItem("previousData");
        localStorage.removeItem("parentPreviousData");
    }
    
    useEffect(() => {
        clearData()

        async function call() {
            if (params.id && params.workflowId) {
                setLoading(true)

                let workflow = await getWorkflow(params.workflowId);
                let workflowInstance = await getWorkflowInstance(params.id);

                if (workflowInstance === false) {
                    navigate(Router.appMyWork)
                    return
                }

                await getAttachments(params.id);
                await getRelationWorkflows(params.id, workflowInstance?.parentRelation);
                await callData(workflowInstance);

                setRow(workflowInstance)
                setRowOrig(workflowInstance)
                setWorkflow(workflow)
                setAttachmentsId(params.id)
                setFrom(location.state?.from || Router.appMyWork)
                setDialogPop({ ...dialogPop, status: location.state?.row?.showActionsPop || false })

                checkActionButtons(workflowInstance)
                configTitle(workflow)

                setLoading(false)
            }
            else {
                navigate(Router.appMyWork);
            }
        }
        call()
    }, [location?.state?.row?.id, location?.state?.row?.notificationId, reload])

    async function getRelationWorkflows(id, parent) {
        let response = await workflowDataApi.relations(id);
        let result = check(response);
        if (result.status) {
            let data = response.data
            if (parent?.id) {
                data.unshift(parent);
            }
            setFlowRelations(data)
        }
        else {
            if (parent?.id) {
                setFlowRelations([parent])
            }
        }
    }

    async function getWorkflowInstance(id) {
        let response = await workflowDataApi.detailWorkflowInstance(id);
        let result = check(response);
        if (result.status) {
            let data = response.data
            data.plotPlans = data.characteristics?.plotPlans || []
            data = mergeStateData(data)
            if (data.parent) {
                data.parentRelation = {
                    id: 'parent',
                    workflowId: data.parent.workflow?.id,
                    name: data.parent.workflow?.name,
                    relations: [data.parent]
                }
            }
            return data
        }
        else {
            toast.error(t('common.empty.notFound'))
            return false
        }
    }

    function mergeStateData(data) {
        if (location.state?.row !== null && location.state?.row !== undefined) {
            let row = location.state?.row;
            data = {
                ...data,
                isNotification: row.isNotification,
                notificationId: row.notificationId,
                nextStatus: row.nextStatus,
                type: row.type,
                showActionsPop: row.showActionsPop,
                previousRouteData: row.previousRouteData
            }
        }

        return data
    }

    async function getWorkflow(id) {
        let response = await workflowApi.detail(id);
        if (response.status === 200) {
            return response.data
        }
    }

    async function getAttachments(id) {
        let response = await workflowDataFileApi.getFile(id);
        let result = check(response)
        if (result.status) {
            setAttachments(response.data)
        }
    }

    function configTitle(workflow) {
        setTitle(`${t('workflow.detail.detail')} ${workflow?.name || t('workflow.detail.workPermit')}`)
    }

    function checkActionButtons(row) {
        //10002 Cerrado, 10003 Anulado, 10005 Preactivar, 10006 Pausar, 10007 Continuar/Reanudar, 10008 Activar

        let statusId = row.workflowStatus?.statusId
        setIdDisable(statusId)

        if (row.isNotification) {
            if (ability.can("UpdateStatusWorkflowData")) {
                if (statusId === 10005) { // Estado Pre activo, mostrar botón Activar
                    setShowActiveButton(true)
                }
                else if (statusId === 10008) { // Estado Activo, mostrar botones Pausar y Continuar
                    setShowPauseButton(true)
                    setShowResumeButton(true)
                }
                else if (statusId === 10006) { // Estado Pausado, mostrar botón Reanudar
                    setShowResumeButton(true)
                }
            }
            if (statusId !== 10003 && statusId !== 10002) { // Si los estados no son ni Cerrado ni Anulado
                // Si el tipo de estado es aprobación, mostrar campos observaciones, aprobar y rechazar
                setShowDeleteButton(true)
                if (row.type === 'check_form') {
                    setShowFormButtons(true)
                }
            }
        }
    }

    async function callData(data) {
        setLoadingForm(true)

        let extraForms = data.extraForms?.length > 0 ? data.extraForms : [];

        let newExtraForms = []
        extraForms.map((obj) => { newExtraForms.push({ formId: obj, data: {}, form: { structure: [] } }) })

        let extraPendingForms = data.extraPendingForms?.length > 0 ? data.extraPendingForms : [];
        let newExtraPendingForms = []
        extraPendingForms.map((obj, index) => {
            newExtraPendingForms.push({
                formId: obj,
                data: {},
                notification: data.extraPendingNotificationIds?.[index],
                form: { structure: [] }
            })
        })

        setFormOrig(data.form)

        setDetailForm([...newExtraForms]);
        setDetailPendingForm([...newExtraPendingForms])

        setFormVersion(data?.form?.form?.renderer || 'form_builder')

        setFormsTitles(data.extraFormsTitles?.length > 0 ? [...data.extraFormsTitles] : [])
        setPendingFormsTitles(data.extraPendingFormsTitles?.length > 0 ? [...data.extraPendingFormsTitles] : [])

        setTimeline(data.timeline);
        setInstanceTime(data.instanceTime);

        setLoadingForm(false)
        setLoading(false)
    }

    async function callAccordeonItem(detail, index, areaData) {
        if (!detail.form?._id && detail.formId) {
            setLoadingForm(true)

            let params = {}
            if(formOrig._id === detail.formId) params = { id: row.id, formId: detail.formId }
            else params = { id: row.id, externalId: detail.formId, read_only: true }

            let response = await workflowDataApi.getFormData(params);
            let result = check(response)

            if (result.status) {
                let array = areaData === 0 ? [...detailForm] : [...detailPendingForm];
                if (response.data?.characteristics === null) {
                    response.data = {
                        ...response.data,
                        characteristics: array[0]?.characteristics
                    }
                    currentFormRef.current = response.data
                }
                array[index] = { notification: array[index].notification, ...response.data }
                areaData === 0 ? setDetailForm(array) : setDetailPendingForm(array)
            }
            else {
                toast.error(() =>
                    <div>
                        {result.errors}
                    </div>
                );
            }

            setLoadingForm(false)
        }
    }

    const handleChange = (panel, detail, index, areaData) => async (event, isExpanded) => {
        if (event.target.id?.includes('process-button') || !ability.can("GetFormData")) return

        if (isExpanded) {
            await callAccordeonItem(detail, index, areaData);
        }
        if (event.target.id?.includes('pdf-button')) {
            setExpanded(panel);
        }
        else {
            setExpanded(isExpanded ? panel : false);
        }
    };

    async function showInstanceState(action, data, isDelete, nextStatus, type) { 
        // Si se da clic en Botón "Pausar" cambiar el estado a "Pausado"
        if (nextStatus && data === 10006) {
            let response = await workflowStatusApi.detail(-6);
            let value = response.data;
            setRow({
                ...row, nextStatus: {
                    ...row.nextStatus,
                    title: t('workflow.detail.paused'),
                    color: value.color,
                    icon: value.icon,
                }
            })
        }
        else setRow({ ...rowOrig })

        if (type === 'approve') {
            if (!data && validateApproval()) return
            setRow({ ...row, 'approveInstance': data })
        }
        setOpenInstanceStateModal({ status: true, action: action, isDelete: isDelete, data: data })
    }

    async function processAction(detail) {
        let notifications = await returnInstanceNotifications({ id: detail.notification }, true);
        if (notifications?.length === 1) {
            await redirectToAction(notifications[0].workflowData, notifications, navigate, location)
        }
    }

    async function downloadPdf(detail, index, areaData) {
        // setLoadingPdf(true);
        setTimeout(() => {
            generatePdf(
                currentFormRef.current,
                formRef,
                (areaData === 0 ? formsTitles?.[index] : pendingFormsTitles?.[index]) || `${t('workflow.detail.form')} ${index + 1}`,
            )
        }, [2000])
    }

    async function changeState(users) {
        setLoading(true)
        let actionName = ''
        //10002 Cerrado, 10003 Anulado, 10005 Preactivar, 10006 Pausar, 10007 Continuar/Reanudar, 10008 Activar
        if (users.extraData === 10008) actionName = t('workflow.detail.activated')
        else if (users.extraData === 10006) actionName = t('workflow.detail.paused')
        else if (users.extraData === 10007) actionName = showPauseButton ? t('workflow.detail.updated') : t('workflow.detail.resumed')

        let data = {
            id: row.workflowDataId || row.id,
            statusId: users.extraData,
            notificationId: location?.state?.row?.notificationId,
            users: users.users
        }
        let response = await workflowDataApi.updateStatus(data);
        let result = check(response);

        if (result.status) {
            handleCloseInstance()
            setDialog({
                status: true,
                title: `${t('workflow.detail.instance')} ${actionName || t('workflow.detail.updated')}`,
                message: t('workflow.detail.messages.updated.title'),
                type: null
            })
        }
        else {
            toast.error(() => <div>{result.errors}</div>);
        }
        setLoading(false)
    }

    function validateApproval() {
        if (!observations) {
            toast.error(() =>
                <>{t('forms.dynamicForm.messages.errors')}:<br />
                    <ul style={{ listStyle: 'circle', marginLeft: 16 }}>
                        <li><p>{t('forms.dynamicForm.validations.observations')}</p></li>
                    </ul>
                </>
            );
            setErrorObservations(t('common.mandatory', { field: '' }))
            return true;
        }
        return false;
    }

    async function deleteForm(users) {
        setLoading(true)

        let data = {
            id: row.id,
            notificationId: row.notificationId,
            statusId: row.workflowStatus?.statusId,
            users: users.users,
            cancelObservations: users.cancelObservations
        }
        let response = await workflowDataApi.delete(data);
        let result = check(response);

        if (result.status) {
            handleCloseInstance()
            setDialog({
                status: true,
                title: t('workflow.detail.messages.deleted.title'),
                message: t('workflow.detail.messages.deleted.message'),
                type: null,
            })

        }
        else {
            toast.error(() => <div>{result.errors}</div>);
        }

        setLoading(false)
    }

    async function handleOpenDelete() {
        setDialog({
            status: true,
            title: t('workflow.detail.messages.deleted.confirm'),
            message: t('workflow.detail.messages.deleted.confirmMessage'),
            type: 'confirmation'
        })
    }

    async function handleCloseDialog(value, isClose) {
        setDialog({ ...dialog, status: false })

        if (value) {
            showInstanceState(deleteForm, null, true)
            return
        }
        if (isClose) {
            return
        }

        if (rowOrig.previousRouteData?.pathname) {
            let newState = { ...rowOrig.previousRouteData.state, previousRouteData: location }
            navigate(rowOrig.previousRouteData.pathname, { state: newState });
        }
        else {
            navigate(Router.appWorkflow.replace(':id', row?.workflowId || 1));
        }
    }

    async function handleCloseInstance() {
        setOpenInstanceStateModal({ status: false })
    }

    function handleCloseNotifications() {
        setNotificationsModal({ status: false, notifications: [], instance: {} });
    }

    async function handleClose() {
        setDialog({ status: false })
    }

    function handleClosePop() {
        setDialogPop({ ...dialogPop, status: false })
    }

    async function handleOpenStatus(row) {
        setLoadingProcess(true)
        let notifications = await returnInstanceNotifications(row, false, props.updateWorkflow);
        setReload(!reload)
        if (notifications?.length > 1) {
            setNotificationsModal({ status: true, notifications: notifications, instance: row })
        }
        else {
            await redirectToAction(row, notifications, navigate, location)
        }
        setLoadingProcess(false)
    }

    function handleChangeTab(_, value) {
        setTab(value);
    }

    let data = row;

    const titleTab = (forms, title) => {
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <span x={{ fontSize: '1rem' }}>{title}</span>
                <IconArea sx={{ margin: '0 .5rem' }}>{forms.length}</IconArea>
            </div>
        )
    }

    const generatePdf = async (formDownload, formReference, name) => {
        let isExecuteDownload = false;
        //Nos devuelve la ruta del elemento style
        let foundElementStyle = findElement(formDownload);
        // Navegar al elemento style del formulario que llega actualmente
        let route = foundElementStyle.path.join(".")
        // let route = foundElementStyle.path?.length > 1 ? foundElementStyle.path?.join('.') : foundElementStyle.path.toString();
        // Se crea una copia de la instancia
        let copyFormInstance = formDownload;
        // Se ejecuta la ruta en la instancia que llega, para dirigirnos a su elemento style
        let instanceElement = eval(`copyFormInstance.${route}`);
        originalStylesRef.current = instanceElement.component.content;
        instanceElement.component.content = instanceElement.component.content + pdfTableStyleDetail();

        formDownload = copyFormInstance;
        formDownload.triggerRedraw();
        formDownload.refresh();

        setTimeout(async () => {
            if (formReference?.current !== null) {
                isExecuteDownload = true;
                await downloadPDF(formDownload, formRef, name, setLoadingPdf)
            }
            else {
                setLoadingPdf(false)
            }
        }, 1000)

        setTimeout(() => {
            if (isExecuteDownload) {
                instanceElement.component.content = originalStylesRef.current;
                currentFormRef.current = copyFormInstance;

                formDownload.triggerRedraw();
                formDownload.refresh();
            }
        }, 2000);
    }

    const findElement = (obj, path = []) => {
        if (obj.component?.tag === "style") {
            return { element: obj, path };
        }

        if (obj.components) {
            for (let i = 0; i < obj.components.length; i++) {
                const component = obj.components[i];
                const result = findElement(component, [...path, `components[${i}]`]);
                if (result) {
                    return result;
                }
            }
        }

        return null;
    }


    return (
        <Container open={props.open} isExternal={isExternal} customWidth={props.customExternalWidth} mb={10}>
            <SectionPageTitle>
                {!isExternal &&
                    <PageTitle title="" isbutton={true}
                        navigate={from || Router.appWorkflow.replace(':id', row?.workflowId || 1)}
                        icon={<ArrowBackIcon />}
                    />
                }
                <PageTitle title={title}
                    icon={<Icon baseClassName="material-icons-outlined">{workflow?.icon || ''}</Icon>}
                />
            </SectionPageTitle>

            <ContainerContent>
            <ContainerLeft>
                <DetailInfo>
                    <WorkPermitDetailData
                        data={data}
                        workflow={workflow}
                        idDisable={idDisable}
                        isDemo={props.isDemo}
                        handleOpenStatus={handleOpenStatus}
                        isExternal={isExternal}
                    />
                </DetailInfo>

                {!isMediumOrSmaller && ( // Renderizamos los TabsForms solo si la pantalla es mayor que "md"
                    <CustomCard id='TabsForms'>
                        <CustomTabs
                            value={tab}
                            onChange={handleChangeTab}
                            textColor="secondary"
                            indicatorColor="secondary"
                            variant="scrollable"
                            aria-label="secondary tabs example"
                            TabIndicatorProps={{ style: { backgroundColor: colors.buttonActionColor, color: colors.buttonActionColor } }}
                        >
                            <ItemTab value={0} label={titleTab(detailForm, t('workflow.detail.formsActive'))} />
                            <ItemTab value={1} label={titleTab(detailPendingForm, t('workflow.detail.formsPending'))} />
                            {flowRelations.length > 0 && <ItemTab value={2} label={titleTab(flowRelations, t('workflow.detail.flowRelations'))} />}
                            {(!isExternal && (ability.can("GetWorkflowDataFile") || ability.can("UploadWorkflowDataFile"))) &&
                                <ItemTab value={flowRelations.length === 0 ? 2 : 3} label={titleTab(attachments, t('workflow.detail.attachments'))} />
                            }
                        </CustomTabs>
                        <TabPanel value={tab} index={0}>
                            {!loading &&
                                <WorkPermitDetailTab
                                    areaData={0}
                                    detailForm={detailForm}
                                    formsTitles={formsTitles}
                                    loadingForm={loadingForm}
                                    expanded={expanded}
                                    data={data}
                                    formVersion={formVersion}
                                    formRef={formRef}
                                    showPreActiveButton={showPreActiveButton}
                                    showActiveButton={showActiveButton}
                                    showResumeButton={showResumeButton}
                                    showPauseButton={showPauseButton}
                                    showFormButtons={showFormButtons}
                                    showDeleteButton={showDeleteButton}
                                    changeState={changeState}
                                    showInstanceState={showInstanceState}
                                    observations={observations}
                                    setObservations={setObservations}
                                    setErrorObservations={setErrorObservations}
                                    errorObservations={errorObservations}
                                    isExternal={isExternal}
                                    handleOpenDelete={handleOpenDelete}
                                    handleChange={handleChange}
                                    btnCancel={canCancel}
                                    processAction={processAction}
                                    downloadPdf={downloadPdf}
                                    currentFormRef={currentFormRef}
                                />
                            }
                        </TabPanel>
                        <TabPanel value={tab} index={1}>
                            {!loading &&
                                <WorkPermitDetailTab
                                    areaData={1}
                                    detailForm={detailPendingForm}
                                    formsTitles={pendingFormsTitles}
                                    loadingForm={loadingForm}
                                    expanded={expanded}
                                    data={data}
                                    formVersion={formVersion}
                                    formRef={formRef}
                                    showPreActiveButton={showPreActiveButton}
                                    showActiveButton={showActiveButton}
                                    showResumeButton={showResumeButton}
                                    showPauseButton={showPauseButton}
                                    showFormButtons={showFormButtons}
                                    showDeleteButton={showDeleteButton}
                                    changeState={changeState}
                                    showInstanceState={showInstanceState}
                                    observations={observations}
                                    setObservations={setObservations}
                                    setErrorObservations={setErrorObservations}
                                    errorObservations={errorObservations}
                                    isExternal={isExternal}
                                    handleOpenDelete={handleOpenDelete}
                                    handleChange={handleChange}
                                    btnCancel={canCancel}
                                    processAction={processAction}
                                    downloadPdf={downloadPdf}
                                    currentFormRef={currentFormRef}
                                />
                            }
                        </TabPanel>
                        {flowRelations.length > 0 &&
                            <TabPanel value={tab} index={2}>
                                <WorkflowDataRelation data={flowRelations} />
                            </TabPanel>
                        }
                        {(ability.can("GetWorkflowDataFile") || ability.can("UploadWorkflowDataFile")) &&
                            <TabPanel value={tab} index={flowRelations.length === 0 ? 2 : 3}>
                                <Attachments id={attachmentsId} disabled={row?.workflowStatus?.statusId === 10003 || row?.workflowStatus?.statusId === 10002} userData={getUser()?.email ?? ""} statusData={row?.workflowStatus} setAttachments={setAttachments} />
                            </TabPanel>
                        }
                    </CustomCard>
                )}

                {isMediumOrSmaller && ( // renderiza el timeline si la pantalla es "md" o mas pequeña
                    <DetailInfo id='TimeLine'>
                        {!loading &&
                            <TimeLine data={timeline} dataTime={instanceTime} />
                        }
                    </DetailInfo>
                )}
            </ContainerLeft>

            <ContainerRight>
                {!isMediumOrSmaller && ( // renderiza el timeline solo si la pantalla es mayor que "md"
                    <DetailInfo id='TimeLine'>
                        {!loading &&
                            <TimeLine data={timeline} dataTime={instanceTime} />
                        }
                    </DetailInfo>
                )}

                {isMediumOrSmaller && ( // renderia el TabsForms si la pantalla es "md" o mas pequeña
                    <CustomCard id='TabsForms'>
                        <CustomTabs
                            value={tab}
                            onChange={handleChangeTab}
                            textColor="secondary"
                            indicatorColor="secondary"
                            variant="scrollable"
                            aria-label="secondary tabs example"
                            TabIndicatorProps={{ style: { backgroundColor: colors.buttonActionColor, color: colors.buttonActionColor } }}
                        >
                            <ItemTab value={0} label={titleTab(detailForm, t('workflow.detail.formsActive'))} />
                            <ItemTab value={1} label={titleTab(detailPendingForm, t('workflow.detail.formsPending'))} />
                            {flowRelations.length > 0 && <ItemTab value={2} label={titleTab(flowRelations, t('workflow.detail.flowRelations'))} />}
                            {(!isExternal && (ability.can("GetWorkflowDataFile") || ability.can("UploadWorkflowDataFile"))) &&
                                <ItemTab value={flowRelations.length === 0 ? 2 : 3} label={titleTab(attachments, t('workflow.detail.attachments'))} />
                            }
                        </CustomTabs>
                        <TabPanel value={tab} index={0}>
                            {!loading &&
                                <WorkPermitDetailTab
                                    areaData={0}
                                    detailForm={detailForm}
                                    formsTitles={formsTitles}
                                    loadingForm={loadingForm}
                                    expanded={expanded}
                                    data={data}
                                    formVersion={formVersion}
                                    formRef={formRef}
                                    showPreActiveButton={showPreActiveButton}
                                    showActiveButton={showActiveButton}
                                    showResumeButton={showResumeButton}
                                    showPauseButton={showPauseButton}
                                    showFormButtons={showFormButtons}
                                    showDeleteButton={showDeleteButton}
                                    changeState={changeState}
                                    showInstanceState={showInstanceState}
                                    observations={observations}
                                    setObservations={setObservations}
                                    setErrorObservations={setErrorObservations}
                                    errorObservations={errorObservations}
                                    isExternal={isExternal}
                                    handleOpenDelete={handleOpenDelete}
                                    handleChange={handleChange}
                                    btnCancel={canCancel}
                                    processAction={processAction}
                                    downloadPdf={downloadPdf}
                                    currentFormRef={currentFormRef}
                                />
                            }
                        </TabPanel>
                        <TabPanel value={tab} index={1}>
                            {!loading &&
                                <WorkPermitDetailTab
                                    areaData={1}
                                    detailForm={detailPendingForm}
                                    formsTitles={pendingFormsTitles}
                                    loadingForm={loadingForm}
                                    expanded={expanded}
                                    data={data}
                                    formVersion={formVersion}
                                    formRef={formRef}
                                    showPreActiveButton={showPreActiveButton}
                                    showActiveButton={showActiveButton}
                                    showResumeButton={showResumeButton}
                                    showPauseButton={showPauseButton}
                                    showFormButtons={showFormButtons}
                                    showDeleteButton={showDeleteButton}
                                    changeState={changeState}
                                    showInstanceState={showInstanceState}
                                    observations={observations}
                                    setObservations={setObservations}
                                    setErrorObservations={setErrorObservations}
                                    errorObservations={errorObservations}
                                    isExternal={isExternal}
                                    handleOpenDelete={handleOpenDelete}
                                    handleChange={handleChange}
                                    btnCancel={canCancel}
                                    processAction={processAction}
                                    downloadPdf={downloadPdf}
                                    currentFormRef={currentFormRef}
                                />
                            }
                        </TabPanel>
                        {flowRelations.length > 0 &&
                            <TabPanel value={tab} index={2}>
                                <WorkflowDataRelation data={flowRelations} />
                            </TabPanel>
                        }
                        {(ability.can("GetWorkflowDataFile") || ability.can("UploadWorkflowDataFile")) &&
                            <TabPanel value={tab} index={flowRelations.length === 0 ? 2 : 3}>
                                <Attachments id={attachmentsId} disabled={row?.workflowStatus?.statusId === 10003 || row?.workflowStatus?.statusId === 10002} userData={getUser()?.email ?? ""} statusData={row?.workflowStatus} setAttachments={setAttachments} />
                            </TabPanel>
                        }
                    </CustomCard>
                )}
            </ContainerRight>
        </ContainerContent>

            <Modal open={openInstanceStateModal.status}>
                <BoxModal>
                    <InstanceStateModalCard
                        data={row}
                        extraData={openInstanceStateModal.data}
                        isDelete={openInstanceStateModal.isDelete}
                        handleSave={openInstanceStateModal.action}
                        handleClose={handleCloseInstance} />
                </BoxModal>
            </Modal>

            <Modal open={notificationsModal.status}>
                <BoxModal>
                    <InstanceNotificationsModal
                        notifications={notificationsModal.notifications}
                        instance={notificationsModal.instance}
                        handleClose={handleCloseNotifications}
                    />
                </BoxModal>
            </Modal>

            <BackdropLoading open={loading} />
            <BackdropLoading open={loadingPdf} />
            <BackdropLoading open={loadingProcess} />
            <ShowDialog openDialog={dialog.status} dialog={dialog} handleCloseDialog={dialog.isClose ? handleClose : handleCloseDialog} />
            <ShowDialog openDialog={dialogPop.status} dialog={dialogPop} handleCloseDialog={handleClosePop} />
        </Container>
    )
}
