import { styled } from "@mui/material/styles";
import { useColors } from "../../../../Context/ColorsContext";
import { MediaQuerys } from "../../../../styles/GlobalStyle";


const { mobile, desktop } = MediaQuerys;

export const Container = styled('div')(({theme}) => ({
  display: 'flex',
  width: '100%',
  height: '100%',
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    width: '850px'
  },
  [theme.breakpoints.up('lg')]: {
    width: '1024px'
  },
  [theme.breakpoints.down('md')]: {
    width: '395px'
  },
}))

export const Header = styled('div')(() => {
  const { colors } = useColors();
  return {
    display: 'flex',
    background: colors.white,
    color: colors.detailTextColor,
    width: '100%',
    height: 'fit-content',
    borderRadius:'10px 10px 0 0',
    padding:'16px',
    textTransform:'uppercase',
    fontWeight:600,
    [desktop]: {
      fontSize:'0.95rem',
    }
  }
})

export const Body = styled('div')(() => {
  const { colors } = useColors();
  return {
    display: 'flex',
    flexDirection:'column',
    flex:'1',
    width: '100%',
    background: colors.white,
    borderTop: `1px solid ${colors.gray}`,
    borderBottom: `1px solid ${colors.gray}`
  }
})

export const Footer = styled('div')(() => {
  const { colors } = useColors();
  return {
    display: 'flex',
    background: colors.white,
    width: '100%',
    height: 'fit-content',
    borderRadius:'0 0 10px 10px',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '8px 16px',
    margin: 'auto'
  }
})

export const SectionRight = styled('section')(() => ({
  display: 'flex',
}))

export const Spacer = styled('section')(() => ({
  padding: '0.5rem',
}))