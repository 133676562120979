import React, { useEffect } from "react"
import { Grid } from "@mui/material";
import Slider from '@mui/material/Slider';
import { toast } from 'react-toastify';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import AutorenewIcon from "@mui/icons-material/Autorenew";

// Styles
import { ContainerStyles, ContainerOpt, CustomIconButton, Input, Text } from './styles';
import { generatePassword } from "../../../utils/commonFunctions";
import { ColorsContext } from "../../../Context/ColorsContext"
import { useTranslation } from 'react-i18next'

export const PasswordGenerator = ({ onGenerate, buttonRef }) => {
    const { t } = useTranslation();
    const { colors } = React.useContext(ColorsContext);

    const passRef = React.useRef(true)

    const [sliderValue, setSliderValue] = React.useState(16);
    const [fieldValue, setFieldValue] = React.useState(16);
    const [password, setPassword] = React.useState('');
    const [isBottom, setIsBottom] = React.useState(true);
    const [limitSlider, setLimitSlider] = React.useState({ min: 8, max: 32 })

    const handleSliderChange = (newValue) => {
        if (sliderValue !== newValue) {
            handleGeneratePassword(newValue)
        }
        setSliderValue(newValue);
        setFieldValue(newValue);
    };
  
    const handleGeneratePassword = React.useCallback((newValue = sliderValue) => {
        const generatedPassword = generatePassword(newValue);
        setPassword(generatedPassword)
    }, [sliderValue]);

    const handleCopyToClipboard = async () => {
        try {
          await navigator.clipboard.writeText(password);
          toast.success(t('common.passwordClipboard'))
        } catch (error) {
            console.error('Permission not granted!:', error);
        }
        onGenerate(password)
    };

    const handleInputChange = (evt) => {
        const newValue = evt.target.value === '' ? limitSlider.min : Number(evt.target.value);
        setFieldValue(newValue);
    };

    const handleBlur = (evt) => {
        let newValue = evt.target.value === '' ? limitSlider.min : Number(evt.target.value);
        if (newValue < limitSlider.min) {
            newValue = limitSlider.min
        } else if (newValue > limitSlider.max) {
            newValue = limitSlider.max
        }
        setSliderValue(newValue);
        setFieldValue(newValue);
        handleSliderChange(newValue)
    };

    useEffect(() => {
        if (passRef.current) {
            // Verificar si hay suficiente espacio en la parte inferior
            const spaceBottom = window.innerHeight - buttonRef.current.getBoundingClientRect().bottom;
            setIsBottom(spaceBottom > 200); // Ajusta el valor según necesites
            handleGeneratePassword()
            passRef.current = false
        }
      }, [buttonRef, isBottom, handleGeneratePassword]);

    return (
        <ContainerStyles 
            isBottom={isBottom}
            buttonRef={buttonRef.current}
        >
            <Grid container spacing={2}>
                <Grid item xs={9}>
                    <Slider
                        value={sliderValue}
                        onChange={(evt) => handleSliderChange(evt.target.value)}
                        valueLabelDisplay="auto"
                        min={limitSlider.min}
                        max={limitSlider.max}
                        sx={{
                            '& .MuiSlider-rail': {
                                color: colors.buttonActionColor,
                            },
                            '& .MuiSlider-track': {
                                backgroundColor: colors.buttonActionColor,
                                border: `1px solid ${colors.buttonActionColor}`
                            },
                            '& .MuiSlider-thumb': {
                                backgroundColor: colors.buttonActionColor, // Cambiar el color de la bolita del slider
                                '&:hover, &.Mui-focusVisible': {
                                  boxShadow: `0px 0px 0px 8px ${colors.buttonActionColor}33`, // Cambiar el color del borde al hacer hover o enfocar
                                },
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Input
                        value={fieldValue}
                        onChange={(evt) => handleInputChange(evt)}
                        onBlur={(evt) => handleBlur(evt)}
                        inputProps={{
                            maxLength: 2,
                            step: 1,
                            min: limitSlider.min,
                            max: limitSlider.max,
                            type: 'number'
                        }}
                    />
                </Grid>
            </Grid>
            <Text>{t('common.passwordHint')}</Text>
            <ContainerOpt>
                <Input
                    value={password}
                    inputProps={{
                        readOnly: true,
                        style: {
                          cursor: 'pointer',
                        },
                    }}
                />
                <CustomIconButton
                    size="small"
                    onClick={() => handleCopyToClipboard()}
                    >
                    <LibraryAddCheckIcon />
                </CustomIconButton>
                <CustomIconButton
                    size="small"
                    onClick={() => handleGeneratePassword(sliderValue)}
                    >
                    <AutorenewIcon />
                </CustomIconButton>
            </ContainerOpt>
        </ContainerStyles>
    )
}