import React, { useEffect, useState } from 'react';

// Components
import { Card, Grid, Button } from '@mui/material';
import { toast } from 'react-toastify';

// Custom components
import { PageTitle } from '../../components/PageTitle';
import TableModel from '../../components/atoms/tables/TableModel';
import { BackdropLoading } from '../../components/BackdropLoading';
import { ShowDialog } from '../../components/ShowDialog';

// Services
import { ListApi } from '../../services/ListApi';

// Router
import Router from '../../router';
import { useLocation, useNavigate } from "react-router-dom";

// Styles
import { Container, SearchButton, } from '../styles';
import {
    SectionPageTitle,
    Spacer,
    Input,
    Content,
    ContainerContent,
    ContainerInput,
    IconAction,
    NewIcon,
    ContainerHeader,
    ContainerGroup,
} from './styles';

// Icons
import { ArrowBack, List } from '@mui/icons-material';

// Utils
import { CheckResponse } from '../../utils/checkResponse';
import { useTranslation } from 'react-i18next'

// Permissions
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../Context/PermissionsContext';

export const ListDetail = (props) => {
    const { t } = useTranslation();
    const { check } = new CheckResponse();
    const listApi = new ListApi();
    const ability = useAbility(AbilityContext);

    const location = useLocation();
    const navigate = useNavigate();

    const [isEdit, setIsEdit] = useState(false);
    const [items, setItems] = useState([]);
    const [deletedItems, setDeletedItems] = useState([]);
    const [newItems, setNewItems] = useState([]);
    const [formData, setFormData] = useState({
        name: "",
        item: "",
        items: []
    });
    const [loading, setLoading] = useState(false)
    const [dialog, setDialog] = useState({
        title: '',
        message: '',
        status: false
    });

    const idListDetail = location.pathname === "/lists/detail"

    useEffect(() => {
        setIsEdit(false)

        if (location?.state?.id) {
            setIsEdit(true)

            async function call() {
                let response = await listApi.detail(location.state.id);
                let result = check(response)

                if (result.status) {
                    let data = response.data;
                    data.items.map(obj => obj.isDetail = true)
                    setFormData({ ...formData, name: data.name, items: data.items })
                    setItems(data.items)
                }
                else {
                    toast.error(() =>
                        <div>{t('lists.messages.loadInfo.title')}<br />
                            {result.errors}
                        </div>
                    );
                }
            }
            if (ability.can("GetList")) {
                call()
            }
        }
    }, [])

    const headers = [
        {
            id: 'name',
            label: 'lists.table.item',
            center: false,
            disablePadding: true,
            sortArrow: true,
            isRender: true,
            customSort: 'name',
        },
        {
            id: 'actions',
            label: 'common.actions.title',
            center: false,
            disablePadding: false,
            sortArrow: false,
            type: 'actions',
            sticky: true,
            direction: 'right',
            keys: ['delete']
        },
    ];

    function handleChange(e, key) {
        let value = e.target.value;
        setFormData({ ...formData, [key]: value });
    }

    function toLower(value) {
        if (typeof value === 'string') return value.toString().toLowerCase()
        return value
    }

    async function handleCreateNew() {

        if (items.filter(el => toLower(el.name) === toLower(formData.item))?.length > 0) {
            toast.error(t('lists.alreadyCreated'))
            return
        }

        let array = addItems(items, { id: formData.item, name: formData.item })
        setItems(array)
        setFormData({ ...formData, item: '', items: array.map(el => el.name) })

        if (isEdit) {
            setNewItems(addItems(newItems, formData.item))
        }
    }

    async function handleDelete(row, index) {
        setItems(deleteItems(items, index));

        if (isEdit) {
            if (row.isDetail) setDeletedItems(addItems(deletedItems, row.id))
            if (newItems.includes(row.name)) setNewItems(deleteItems(newItems, newItems.indexOf(row.name)))
        }
    }

    function deleteItems(items, index) {
        let array = [...items]
        array.splice(index, 1)
        setFormData({...formData, items: array.map((el) => el.name)});
        return array
    }

    function addItems(items, add) {
        let array = [...items]
        array.push(add)
        return array
    }

    function validateForm() {
        let errors = []
        if (!formData.name) errors.push(t('lists.alreadyCreated'))
        if (formData.items?.length <= 0) errors.push(t('lists.mustAddItems'))

        if (errors.length > 0) {
            toast.error(() =>
                <div>{t('lists.messages.errors.title')}<br />
                    <ul style={{ listStyle: 'circle', marginLeft: 16 }}>
                        {errors?.map(row => (
                            <li key={row}><p>{row}</p></li>
                        ))}
                    </ul>
                </div>
            );
            return true
        }

        return false
    }

    async function submit() {
        if (validateForm()) return

        setLoading(true)
        let response = null

        
        if (isEdit) {
            if (ability.can("UpdateList")) {
                let edited = { name: formData.name, deletedItems: deletedItems, newItems: items.map((el) => el.name) }
                response = await listApi.update(location.state.id, edited)
            }
        }
        else {
            if (ability.can("CreateList")) {
                response = await listApi.create(formData)
            }
        }

        let result = check(response)

        if (result.status) {
            setDialog({
                status: true,
                title: t('lists.messages.savedList.title'),
                message: t('lists.messages.savedList.message')
            })
        }
        else {
            toast.error(() =>
                <div>{t('lists.messages.savedList.error')}<br />
                    {result.errors}
                </div>
            );
        }

        setLoading(false)
    }

    function handleCloseDialog() {
        setDialog({ ...dialog, status: false })
        navigate(Router.appLists)
    }

    return (
        <Container open={props.open}>
            <SectionPageTitle>
                <PageTitle title="" isbutton={true} navigate={Router.appLists} icon={<ArrowBack />} />
                <PageTitle title={`${isEdit ? t('common.edit') : t('common.create')} ${t('lists.list')}`} icon={<List />} />
                <Spacer />
            </SectionPageTitle>
            <Card sx={{ marginTop: '8px' }}>
                <BackdropLoading open={loading} />
                <Content>
                    <ContainerContent>
                        <ContainerHeader>
                            <ContainerInput>
                                <Input
                                    placeholder={t('lists.table.listName')}
                                    label={t('lists.table.listName')}
                                    value={formData.name}
                                    onChange={(e) => handleChange(e, 'name')}
                                />
                            </ContainerInput>

                            <ContainerGroup>
                                <ContainerInput items={true}>
                                    <Input
                                        placeholder={t('lists.addNewItem')}
                                        label={t('lists.addNewItem')}
                                        value={formData.item}
                                        onChange={(e) => handleChange(e, 'item')}
                                    />
                                </ContainerInput>

                                <IconAction
                                    size="medium"
                                    aria-label="new"
                                    onClick={handleCreateNew}
                                >
                                    <NewIcon />
                                </IconAction>
                            </ContainerGroup>
                        </ContainerHeader>

                        <Grid item xs={13}>
                            <TableModel
                                headers={headers}
                                filteredRequest={items}
                                rows={items}
                                idListDetail={idListDetail}
                                preventOrderBy={true}
                                hideFilterDate={true}
                                customAdd={true}
                                elementAdd={
                                    <Input
                                        placeholder={t('lists.table.item')}
                                        label={t('lists.table.item')}
                                        value={formData.item}
                                        size="small"
                                        onChange={(e) => handleChange(e, 'item')}
                                    />
                                }
                                handleCreateNew={handleCreateNew}
                                labelCreateNew={t('lists.addNewItem')}
                                handleDelete={handleDelete}
                            />
                        </Grid>
                        <Grid item xs={12} container direction="row" justifyContent="flex-end">
                            <SearchButton variant="contained" onClick={submit}>{t('common.save')}</SearchButton>
                        </Grid>
                    </ContainerContent>
                </Content>
            </Card>
            <ShowDialog openDialog={dialog.status} dialog={dialog} handleCloseDialog={handleCloseDialog} />
        </Container>
    );
}