import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next'
import { Accordion, AccordionDetails, AccordionSummary, Grid, Tooltip, Box } from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { ButtonApprove, PdfIcon, CustomTypography, CustomTextField, Title, Space, IconContainer, ContainerIconsRight, Content, ContainerText } from './styles';

import { FormViewer } from '../../components/FormViewer';
import { SlowMotionVideo } from '@mui/icons-material';

import { useLocation } from 'react-router-dom';

// Permissions
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../Context/PermissionsContext';
import { Visibility } from 'semantic-ui-react';

export const WorkPermitDetailTab = ({
    areaData,
    detailForm, 
    formsTitles,
    loadingForm,
    expanded,
    data,
    formVersion,
    formRef,
    showPreActiveButton,
    showActiveButton,
    showResumeButton,
    showPauseButton,
    showFormButtons,
    showDeleteButton,
    changeState,
    showInstanceState,
    observations,
    setObservations,
    setErrorObservations,
    errorObservations,
    isExternal,
    handleOpenDelete,
    handleChange,
    btnCancel,
    processAction,
    downloadPdf,
    currentFormRef,
}) => {

    const location = useLocation();
    const { t } = useTranslation();
    const ability = useAbility(AbilityContext);
    // Hide cancel button when processing a "viabilización" study with no pending actions
    const hideBtnCancel = location.state?.row.notificationId;

    return (
        <Fragment>
            {detailForm.map((obj, index) => (
                <Accordion 
                    key={`${obj.id}-${index}`} 
                    expanded={expanded === `panel${areaData}${index+1}`} 
                    onChange={handleChange(`panel${areaData}${index+1}`, obj, index, areaData)}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"                
                    >
                        <Content>
                            <ContainerText>
                                <CustomTypography mr={2}>{formsTitles?.[index] || `${t('workflow.detail.form')} ${index+1}`}</CustomTypography>
                            </ContainerText>

                            <ContainerIconsRight>
                                {obj.notification &&
                                    <Tooltip title={t("common.actions.process")} id="process-button-tool">
                                        <IconContainer id="process-button-cont" onClick={() => processAction(obj)}>
                                            <SlowMotionVideo id="process-button" fontSize='30px'/>
                                        </IconContainer>
                                    </Tooltip>
                                }
                                {ability.can("GetFormData") &&
                                    <Tooltip title={t("common.downloadPdf")} id="pdf-button-tool">
                                        <Box component="span" onClick={() => downloadPdf(obj, index, areaData)}>
                                            <PdfIcon id="pdf-button">picture_as_pdf</PdfIcon>
                                        </Box>
                                    </Tooltip>
                                }
                            </ContainerIconsRight>
                        </Content>
                    </AccordionSummary>
                    <AccordionDetails id='Acordeon Details'>
                        <Grid container>
                            <Grid item xs={12}>
                                {(!loadingForm && expanded === `panel${areaData}${index+1}`) && 
                                    <FormViewer 
                                        form={obj.form?.structure} 
                                        data={obj.data} 
                                        characteristics={obj.characteristics} 
                                        formData={data}
                                        isDetail={1}
                                        hideFiles={true}
                                        formVersion={formVersion}
                                        formRef={formRef}
                                        loadingForm={loadingForm}
                                        formName={obj.extraFormsTitles?.[index] || (t('workflow.detail.form') + ' ' + index+1)}
                                        currentFormRef={currentFormRef}
                                    />
                                }
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            ))}
            {detailForm.length === 0 && <Title sx={{ textAlign: 'center', margin: 'auto' }}>{areaData === 0 ? t('workflow.detail.formsActiveEmpty') : t('workflow.detail.formsPendingEmpty')}</Title>}
            <Grid container spacing={2} pt={1} pb={3} paddingX={2} width="100%">
                {showPreActiveButton &&
                    <Grid item xs={2}>
                        <ButtonApprove variant='contained' type="accept" onClick={() => showInstanceState(changeState, 10008)}>{t('workflow.detail.preActivate')}</ButtonApprove>
                    </Grid>
                }
                {showActiveButton &&
                    <Grid item xs={2}>
                        <ButtonApprove variant='contained' type="accept" onClick={() => showInstanceState(changeState, 10008)}>{t('workflow.detail.activate')}</ButtonApprove>
                    </Grid>
                }
                {/* Show daily closing button */}
                {(showResumeButton && showPauseButton && (ability.can("DailyClosing"))) &&
                  <Grid item xs={2}>
                    <ButtonApprove variant='contained' type="accept" onClick={() => showInstanceState(changeState, 10007)}>
                      {t('workflow.detail.continue') }
                    </ButtonApprove>
                  </Grid>
                }
                {/* Show resume button */}
                {(showResumeButton && !showPauseButton) &&
                  <Grid item xs={2}>
                    <ButtonApprove variant='contained' type="accept" onClick={() => showInstanceState(changeState, 10009)}>
                      {t('workflow.detail.resume')}
                    </ButtonApprove>
                  </Grid>
                }
                {/* Show suspend button */}
                {(showPauseButton && (ability.can("Suspend"))) &&
                    <Grid item xs={2}>
                        <ButtonApprove variant='contained' type="accept" onClick={() => showInstanceState(changeState, 10006, false, true)}>{t('workflow.detail.pause')}</ButtonApprove>
                    </Grid>
                }
                {(showFormButtons && (ability.can("ApproveWorkflowData") || ability.can("ApproveWorkflowDataCredentials"))) &&
                <>
                    {(row.type === 'check_form' || row.type === 'check_form_credentials') &&
                        <Grid item xs={12} mt={2}>
                            <CustomTypography>{t('forms.characteristics.observations')}:</CustomTypography>
                            <CustomTextField
                                value={observations || ''}
                                size="small"
                                type="text"
                                onChange={e => {
                                        setObservations(e.target.value)
                                        setErrorObservations('')
                                    }
                                }
                                error={!(!errorObservations)}
                                helperText={errorObservations}
                                multiline
                                rows={2}
                            />
                        </Grid>
                    }
                    <Grid item xs={2}>
                        <ButtonApprove variant='contained' type="approve" onClick={() => showInstanceState(approveForm, true, null, null, "approve")}>{t('workflow.detail.approve')}</ButtonApprove>
                    </Grid>
                    <Grid item xs={2}>
                        <ButtonApprove variant='contained' type="reject" onClick={() => showInstanceState(approveForm, false, null, null, "approve")}>{t('workflow.detail.reject')}</ButtonApprove>
                    </Grid>
                </>
                }
                <Grid item xs></Grid>
                <Grid item xs={2}>
                    {(showDeleteButton && !isExternal && ability.can("DeleteWorkflowData")) &&
                       <ButtonApprove 
                       sx={{ visibility: hideBtnCancel === undefined ||
                        btnCancel === false
                         ? 'hidden' : 'visible' }} 
                       variant='contained' 
                       type="delete" 
                       onClick={handleOpenDelete}
                       >
                        {t('workflow.detail.cancel')}
                       </ButtonApprove>
                    }
                </Grid>
            </Grid>
        </Fragment>
    )
}