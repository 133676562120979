import { Auth } from "../utils/auth";
import { HealthCheckApi } from "./HealthCheckApi";
import Router from "../router";
import axios from "./AxiosConfig";

export class AppearanceApi {
    healthCheck = null;
    token = null;
    user = null;

    constructor() {
        let auth = new Auth();
        this.token = auth.getToken();
        this.user = auth.getUser();
        this.healthCheck = new HealthCheckApi();
    }

    async get(hasToken = true) {
        return await axios.get(`${Router.apiBaseUrl}${hasToken ? Router.apiAppearanceRestricted : Router.apiAppearance}`)
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)
        })
    }

    async create(data) {
        return await axios.post(`${Router.apiBaseUrl}${Router.apiAppearance}`, data)
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)
        });
    }

    async update(data) {
        return await axios.put(`${Router.apiBaseUrl}${Router.apiAppearance}`, data)
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)
        })
    }
}