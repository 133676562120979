import { Auth } from "../utils/auth";
import { HealthCheckApi } from "./HealthCheckApi";
import Router from "../router";
import axios from "./AxiosConfig";

export class ExternalActionApi {
    healthCheck = null;
    token = null;

    constructor() {
        let auth = new Auth();
        this.token = auth.getToken();
        this.healthCheck = new HealthCheckApi();
    }

    async getNotification(hash) {
        return await axios.get(Router.apiBaseUrl + Router.apiNotificationByHash + `?hash=${hash}`, {
            headers: {
                'Authorization': `Bearer ${this.token}`
            }
        })
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)
        })
    }
}