import {styled} from "@mui/material/styles";
import { MediaQuerys } from "../../../styles/GlobalStyle";

const { mobile, tablet, desktop } = MediaQuerys;

export const ContainerFilterBar = styled('div')(() => {
  return {
    width: '100%',
    background: '#eaeaea',
    padding:' .7em 1em',
    [mobile]: {
      padding:'1em 2.5em',
    }
  }
})

export const InputFilter = styled('input')(() => {
  return {
    width: '100%',
    padding:'.2em 1em',
    borderRadius:'5px',
    color:'gray',
    [mobile]: {
      padding:'.5em 1em',
      fontSize:'1.2em',
    }
  }
})