import React from 'react'

// Components
import { Form } from '@formio/react';

// Utils
import { useTranslation } from 'react-i18next'
import Es from '../../../../utils/i18n/languages/es.json'
import En from '../../../../utils/i18n/languages/en.json'
import Pt from '../../../../utils/i18n/languages/pt.json'

export const FormIo = (props) => {
	const {form, data, setData, readOnly, formInstance, setFormInstance, flag} = props
    const { i18n } = useTranslation()

    React.useEffect(() => {
        if(!flag){
            if (formInstance !== null && formInstance !== undefined) {
                formInstance.options.i18next.changeLanguage(i18n.language)
                formInstance.triggerRedraw()
            }
        }
    }, [i18n.language])

  	return (
		<Isolate value={props}>
			<Form 
				form={{ components: form, }}
				submission={{ data: data }}
				options={{ 
                    readOnly: readOnly,
                    language: i18n.language,
                    i18n: {
                        resources: {
                            es: {
                                translation: Es.translation.forms.formIo
                            },
                            en: {
                                translation: En.translation.forms.formIo
                            },
                            pt: {
                                translation: Pt.translation.forms.formIo
                            }
                        }
                    },
                }}
				onChange={(schema) => {
                    if (setData) {
                        setData({...data, ...schema.data})
                    }
				}}
				formReady={(instance) => {
                    instance.options.i18next.init({ resources: {
                        es: {
                            translation: Es.translation.forms.formIo
                        },
                        en: {
                            translation: En.translation.forms.formIo
                        },
                        pt: {
                            translation: Pt.translation.forms.formIo
                        }
                    } })
                    instance.options.i18next.changeLanguage(i18n.language)
                    if(flag){
                        formInstance.current = instance
                    }   
					if (setFormInstance !== undefined) {
                        setFormInstance(instance)
                    }
				}}
			/>
		</Isolate>
  )
}

export class Isolate extends React.Component {

	constructor(props) {
		super(props);
	}

  shouldComponentUpdate () {
    return false // prevent parent state changes from re-rendering FormBuilder
  }

  render () {
    return <React.Fragment>
			{React.Children.map(this.props.children, child => {
				return React.cloneElement(child, { ...this.props })
			})}
    </React.Fragment>
  }
}
