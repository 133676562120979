import { styled } from "@mui/material/styles";
import { Button, TextField, Typography } from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';

import { useColors } from "../../Context/ColorsContext";
import { MediaQuerys } from "../../styles/GlobalStyle";

const { mobile, desktop } =  MediaQuerys;

function buttonType(type) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { colors } = useColors();
    if (type === 'reject') return colors.grayGraphic
    if (type === 'delete') return colors.red
    if (type === 'accept') return colors.green
    return colors.main
}

export const ButtonApprove = styled(Button)(({type, hideBtnCancel}) => {
    const { colors } = useColors();

    return {
        // visibility: hideBtnCancel === undefined && 'hidden',
        margin: '0.8rem 0 0 0',
        width: '100%',
        height: 40,
        background: buttonType(type),
        color: colors.white,
        "&:hover": {
            background: buttonType(type),
            opacity: 0.8
        }
    }
})

export const PdfIcon = styled(DownloadIcon)(() => {
    const { colors } = useColors();
    return {
        fontSize: 30,
        color: colors.buttonActionColor,
        padding: '.1rem',
        backgroundColor: colors.background,
        borderRadius: "10%",
        "&:hover": {
            backgroundColor: colors.buttonActionColor,
            opacity: 0.9,
            color: colors.background,
            cursor: 'pointer'
        },
    }
})

export const CustomTypography = styled('p')(() => {
    return {
        
            fontSize: '1rem',
          [desktop]: {
              fontSize: '0.75rem'
          }
      }
})

export const CustomTextField = styled(TextField)(() => {
    return {
        width: '100%',
        '.MuiInputBase-input': {
            [desktop]: {
                fontSize: '0.85rem'
            }
        }
    }
})

export const Title = styled(Typography)(() => {
    const { colors } = useColors();
    return {
        color: colors.grayFont,
        fontSize: '1rem',
        lineHeight: 1.2,
        [desktop]: {
            fontSize: '0.75rem'
        }
    }
})

export const Space = styled('div')(() => {
    return {
        padding: 2
    }
})

export const IconContainer = styled("div")(() => {
    const { colors } = useColors();
    return {
        color: colors.buttonActionColor,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 30,
        cursor: "pointer",
        backgroundColor: colors.background,
        borderRadius: "10%",
        "&:hover": {
            backgroundColor: colors.buttonActionColor,
            opacity: 0.9,
            color: colors.background
        },
    };
});

export const ContainerIconsRight = styled('div')(() => {
    return {
        display: 'flex',
        width: '20%',
        justifyContent: 'flex-end',
        flexDirection: 'row',
        gap: '1.2rem',
        marginRight: '1.8rem',
        [mobile]: {
            marginRight: '0.40rem',
        }
    }
})

export const Content = styled('div')(() => {
    return {
        display: 'flex',
        flexDirection: 'row',
        gap: '0.50rem',
        width: '100%',
    }
})

export const ContainerText = styled('div')(() => {
    return {
        width: '80%'
    }
})

