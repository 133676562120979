import React, { useState, useEffect, useCallback } from 'react'
import { toast } from 'react-toastify';

import { Appearance } from '../../components/Appearance'
import { AppearanceApi } from '../../services/AppearanceApi';
import { ShowDialog } from '../../components/ShowDialog';

import { ColorsContext } from '../../Context/ColorsContext';

// Utils
import { useTranslation } from 'react-i18next'

export const AppearanceForm = (props) => {

    const { t } = useTranslation();
    const { updateColors, resetColors } = React.useContext(ColorsContext);

    const appearanceApi = new AppearanceApi();

    const actionRef = React.useRef(false)
    const [previewImage, setPreviewImage] = useState({
        status: false,
        file: null
    });
    const [previewIcon, setPreviewIcon] = useState({
        status: false,
        file: null
    });
    const [previewLoginIcon, setPreviewLoginIcon] = useState({
        status: false,
        file: null
    });
    const [previewFavicon, setPreviewFavicon] = useState({
        status: false,
        file: null
    });
    const [loading, setLoading] = React.useState(false)
    const [form, setForm] = React.useState({
        id: 0,
        navbarColor: '#fff',
        footerColor: '#fff',
        sidebarSelectColor: '#fff',
        sectionTitleColor: '#fff',
        buttonActionColor: '#fff',
        detailTextColor: '#fff',
        iconUrl: '',
        imageLoginUrl: '',
        faviconUrl: '',
        loginIconUrl: '',
        isActive: false,
        imageLogin: null,
        imageIcon: null,
        faviconImage: null,
        loginImageIcon: null,
        title: ''
    })
    const [formReset, setFormReset] = React.useState({
        id: 0,
        navbarColor: '#fff',
        footerColor: '#fff',
        sidebarSelectColor: '#fff',
        sectionTitleColor: '#fff',
        buttonActionColor: '#fff',
        detailTextColor: '#fff',
        iconUrl: '',
        imageLoginUrl: '',
        faviconUrl: '',
        loginIconUrl: '',
        isActive: false,
        imageLogin: null,
        imageIcon: null,
        faviconImage: null,
        loginImageIcon: null,
        title: ''
    })

    const [dialog, setDialog] = useState({
        status: false,
        title: '',
        message: ''
    })

    const [error, setError] = React.useState({
        general: { hasError: false, message: '' }
    })

    const resetError = () => {
        setError({
            general: { hasError: false, message: '' }
        })
    }

    const handleChange = (event, key, type) => {
        if (type === 'text') {
            setForm({...form, [`${key}`]: event.target.value});
        }
        // else if (event.target?.type === 'file') {
        else if (type === 'file') {

            const file = event.target.files[0];
            const reader = new FileReader();
            reader.onload = () => {
                if (key === 'iconUrl') {
                    setPreviewIcon({
                        ...previewIcon,
                        status: true,
                        file: reader.result
                    });
                }
                else if (key === 'imageLoginUrl') {
                    setPreviewImage({
                        ...previewImage,
                        status: true,
                        file: reader.result
                    });
                }
                else if (key === 'faviconUrl') {
                    setPreviewFavicon({
                        ...previewFavicon,
                        status: true,
                        file: reader.result
                    });
                }
                else {
                    setPreviewLoginIcon({
                        ...previewLoginIcon,
                        status: true,
                        file: reader.result
                    });
                }
            };
            reader.readAsDataURL(file);
            
            let typeFile = 'imageLogin'
            if (key === 'iconUrl') {
                typeFile = 'imageIcon'
            }
            else if (key === 'faviconUrl') {
                typeFile = 'faviconImage'
            }
            else if (key === 'loginIconUrl') {
                typeFile = 'loginImageIcon'
            }

            setForm({...form, 
                [`${key}`]: event.target.value, 
                [`${typeFile}`]: file
            })
        }
        else {
            setForm({...form, [`${key}`]: event.hex});
        }
    }

    const handleState = (value) => {
        let data = {...form}
        setForm({
            ...data,
            isActive: value
        })
    }

    const renderError = (message) => {
        let errors = {...error}
        errors.general = { hasError: true, message: message || t('system.errorSaving') }
        setError(errors)
    }

    function handleConfirmation() {
        let data = {...form}
        if (data.isActive === false)
        {
            setDialog({
                status: true,
                title: t('system.appearance.messages.confirm'),
                message: t('system.appearance.messages.confirmMessage'),
                type: 'confirmation'
            })
        } else {
            handleAppearance()
        }
    }

    async function handleCloseDialog(value) {
        setDialog({status: false})
        if (value) {
            handleAppearance()
        }
    }

    const handleAppearance = () => {
        resetError()

        let formData = new FormData();
        formData.append('navbarColor', form.navbarColor);
        formData.append('footerColor', form.footerColor);
        formData.append('sidebarSelectColor', form.sidebarSelectColor);
        formData.append('sectionTitleColor', form.sectionTitleColor);
        formData.append('buttonActionColor', form.buttonActionColor);
        formData.append('detailTextColor', form.detailTextColor);
        formData.append('isActive', form.isActive);
        formData.append('imageLogin', form.imageLogin);
        formData.append('imageIcon', form.imageIcon);
        formData.append('faviconImage', form.faviconImage);
        formData.append('loginImageIcon', form.loginImageIcon);
        formData.append('iconUrl', form.iconUrl === null ? "" : form.iconUrl);
        formData.append('imageLoginUrl', form.imageLoginUrl === null ? "" : form.imageLoginUrl);
        formData.append('faviconUrl', form.faviconUrl === null ? "" : form.faviconUrl);
        formData.append('loginIconUrl', form.loginIconUrl === null ? "" : form.loginIconUrl);
        formData.append('title', form.title);

        setLoading(true)
        appearanceApi.update(formData).then((response) => {
            setLoading(false)
            if (response?.status === 200) {
                toast.success(t('system.appearance.messages.savedAppearance.title'))
                loadAppearanceApi()
                resetError()
            } else {
                renderError(response?.data?.description || response?.data?.data?.description)
            }
        })
    }

    const handleReset = () => {
        let data = {...formReset}
        data.imageLogin = null
        data.imageIcon = null
        data.faviconImage = null
        data.loginImageIcon = null

        setPreviewIcon({ ...previewIcon, status: false, file: null });
        setPreviewImage({ ...previewImage, status: false, file: null });
        setPreviewFavicon({ ...previewFavicon, status: false, file: null });
        setPreviewLoginIcon({ ...previewLoginIcon, status: false, file: null });
        setForm({...data, imageLogin: null, imageIcon: null, faviconImage: null, loginImageIcon: null})
    }
    
    const removePreviewImage = () => {
        let data = {...form}
        setForm({...data, imageLoginUrl: '', imageLogin: null})
        setPreviewImage({ ...previewImage, status: false, file: null });
    }

    const removePreviewIcon = () => {
        let data = {...form}
        setForm({...data, iconUrl: '', imageIcon: null})
        setPreviewIcon({ ...previewIcon, status: false, file: null });
    }

    const removePreviewFavicon = () => {
        let data = {...form}
        setForm({...data, faviconUrl: '', faviconImage: null})
        setPreviewFavicon({ ...previewFavicon, status: false, file: null });
    }

    const removePreviewLoginIcon = () => {
        let data = {...form}
        setForm({...data, loginIconUrl: '', loginImageIcon: null})
        setPreviewLoginIcon({ ...previewLoginIcon, status: false, file: null });
    }

    const loadAppearanceApi = useCallback(async() => {
        setLoading(true)
        let response = await appearanceApi.get()
        if (response?.status === 200) {
            setLoading(false)
            let resp = response.data
            if (resp?.id !== null) {
                if (resp.isActive) {updateColors(resp);}
                else {resetColors(); }

                setForm({...form, ...resp})
                setFormReset({...form, ...resp})
            } else {
                resetColors();
            }
        } else {
            resetColors()
        }
    }, [appearanceApi])

    useEffect(() => {
      if (actionRef.current === false) {
        loadAppearanceApi()
        actionRef.current = true
      }
    }, [loadAppearanceApi])
    

    return (
        <>
            {actionRef.current && 
                <Appearance 
                    data={form}
                    errors={error}
                    loading={loading} 
                    handleChange={handleChange}
                    handleState={handleState}
                    previewImage={previewImage}
                    removePreviewImage={removePreviewImage}
                    previewIcon={previewIcon}
                    removePreviewIcon={removePreviewIcon}
                    previewFavicon={previewFavicon}
                    removePreviewFavicon={removePreviewFavicon}
                    previewLoginIcon={previewLoginIcon}
                    removePreviewLoginIcon={removePreviewLoginIcon}
                    handleConfirmation={handleConfirmation}
                    handleReset={handleReset}
                />
            }
            <ShowDialog openDialog={dialog.status} dialog={dialog} handleCloseDialog={handleCloseDialog}/>
        </>
    )
}