import {styled} from "@mui/material/styles";
import { Button, Grid, Typography } from "@mui/material";
import { useColors } from "../../Context/ColorsContext"

export const Content = styled(Grid)(() => {
    const { colors } = useColors();    
    return {
        background: colors.main,
        width: '100%',
        height: '100%'
    }
})

export const Container = styled(Grid)(() => ({
    direction: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
}))

export const Text = styled(Typography)(() => {
    const { colors } = useColors();    
    return {
        color: colors.white,
        fontSize: 26,
        margin: 0,
        padding: 0,
    }
})

export const CustomButton = styled(Button)(() => {
    const { colors } = useColors();   
    return {
        marginTop: 20,
        background: colors.main,
        fontSize: 18,
        textTransform: 'none'
    }
})

export const TextResource = styled(Typography)(() => {
    const { colors } = useColors();   
    return {
        textAlign: 'center',
        color: colors.white,
        fontSize: 26,
        fontWeight: 400,
        fontStyle: 'italic'
    }
})

export const TextCode = styled(Typography)(() => {
    const { colors } = useColors();   
    return {
        fontFamily: '"Comfortaa", cursive',
        display: 'inline-block',
        fontSize: '100px',
        color: colors.white,
        padding: '0 20px'
    }
})

export const TextLock = styled(Typography)(() => {
    const { colors } = useColors();   
    return {
        display: 'inline-block',
        transition: '0.5s ease',
        position: 'relative',
        overflow: 'hidden',
        '&::after': {
        content: '""',
        background: colors.gray,
        opacity: 0.48,
        display: 'block',
        position: 'absolute',
        height: '40%',
        width: '50%',
        bottom: 0,
        left: 0
        }
    }
})

export const TextLockBottom = styled(Typography)(() => {
    const { colors } = useColors();   
    return {
        background: colors.gray,
        height: 40,
        width: 60,
        display: 'block',
        position: 'relative',
        margin: '0 auto'
    }
})

export const TextLockTop = styled(Typography)(() => ({
    height: 60,
    width: 50,
    borderRadius: '50%',
    border: '10px solid #fff',
    display: 'block',
    position: 'relative',
    top: 30,
    margin: '0 auto',
    '&::after': {
      padding: 10,
      borderRadius: '50%'
    }
    })
)