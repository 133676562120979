import React, { useEffect } from 'react';
import { PermissionApi } from '../../../../services/PermissionApi';

// Components
import TablePermissions from '../../tables/TablePermissions';

// Styles
import {
    Container,
    Header,
    Body,
    Footer,
    SectionRight
} from './styles'

// Utils
import { SearchButton } from '../../../../pages/styles';
import { useTranslation } from 'react-i18next'

export const ListPermissions = ({ roleId, title, handleClose }) => {
    const { t } = useTranslation();
    const permissionApi = new PermissionApi();

    const refData = React.useRef(false)

    const [filteredRequest, setFilteredRequest] = React.useState([])
    const [rows, setRows] = React.useState([])

    useEffect(() => {
        if (refData.current === false) {
            permissionApi.list(roleId).then((response) => {
            if (response?.status === 200) {
                let resp = response.data.data;
                setFilteredRequest(resp)
                setRows(resp)
            }
          })
          refData.current = true
        }
    }, [permissionApi, roleId])

    return (
        <Container>
            <Header>{title}</Header>
            <Body>
                <TablePermissions 
                    filteredRequest={filteredRequest}
                    rows={rows}
                />
            </Body>
            <Footer>
                <SectionRight>
                    <SearchButton
                        isStandard={true}
                        variant="contained"
                        sx={{ 
                            height: 30, 
                            padding: '0 20px', 
                            border: 'none'
                        }}
                        onClick={() => handleClose()}
                    >
                        {t('common.close')}
                    </SearchButton>
                </SectionRight>
            </Footer>
        </Container>
    )
}