import React from 'react';

// Components

// Custom components
import { CardImage } from '../CardImage';

// Styles
import { Container, ContainerContent } from './styles';

export const PlotPlanModal = (props) => {
    const {handleSelect, root, loading, parentNode, handleStepBack, handleStepBegin} = props;

    return (
        <ContainerContent>
            <Container>
                <CardImage 
                    root={root}
                    loading={loading}
                    data={parentNode} 
                    onSelect={handleSelect}
                    goBack={handleStepBack}
                    goBegin={handleStepBegin}
                    isModal={true}
                />
            </Container>
        </ContainerContent>
    )
}
