import {styled} from "@mui/material/styles";
import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { useColors } from "../../Context/ColorsContext";
import { MediaQuerys } from "../../styles/GlobalStyle";

const { mobile, tablet, desktop } = MediaQuerys;

export const SectionFiles = styled('section')(({disabled}) => {
    const { colors } = useColors();
    return {
        display: 'flex',
        justifyContent: 'center',
        height: 50,
        alignItems: 'center',
        marginTop: 0,
        border: `1px dashed ${colors.grayFont}`,
        borderRadius: 8,
        padding: 10,
        color: disabled ? colors.disabled : colors.blackFont,
        cursor: disabled ? 'not-allowed' : 'pointer'
    }
})

export const Container = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0'
}))

export const CustomSpan = styled(Typography)(() => {
    const { colors } = useColors();
    return {
        color: colors.grayFont,
        fontSize: 14,
        [desktop]: {
            fontSize: '0.90rem',
        }
    }
})


export const CustomTypography = styled(Typography)(() => {
    return {
        [desktop]: {
            fontSize: '0.90rem',
        }
    }
})

export const Text = styled(Typography)(() => {
    const { colors } = useColors();
    return {
        color: colors.grayFont,
        fontSize: 14,
        margin: 0,
        padding: 0,
        [desktop]: {
            fontSize: '0.95rem',
        },
        [tablet]: {
            fontSize: '0.85rem',
        }
    }
})

export const ContainerFiles = styled('ul')(() => {
    const { colors } = useColors();
    return {
        border: `1px solid ${colors.grayFont}`, 
        height: '100px', 
        overflowY: 'auto',
        borderRadius: '5px'
    }
})