import React, { useEffect, useRef, useState } from 'react';

// API
import { WorkflowDataApi } from '../../services/WorkflowDataApi';
import { PlotPlanApi } from '../../services/PlotPlanApi';
import Router from '../../router';

// Custom components
import { PageTitle } from '../../components/PageTitle';
import { BackdropLoading } from '../../components/BackdropLoading';
import { ShowDialog } from '../../components/ShowDialog';
import { CharacteristicsForm } from '../../components/molecules/forms/CharacteristicsForm';
import { Attachments } from '../../components/Attachments';
import { InstanceStateModalCard } from '../../components/atoms/modalCards/InstanceStateModalCard';
import { FormIo } from '../../components/molecules/forms/FormIo';

// Components
import { toast } from 'react-toastify';
import { Dialog, DialogActions, DialogContent, Grid, Modal } from '@mui/material';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import moment from 'moment';

// Router
import { useLocation, useNavigate } from 'react-router-dom';

// Styles
import { BoxModal, Container, FormButton } from '../styles';
import { CustomTextField, CustomTypography, FormContainer, CustomFormControl, CustomInputLabel, CustomSelect, CustomMenuItem } from './styles';
import 'semantic-ui-css/semantic.min.css'
import 'react-toastify/dist/ReactToastify.css';
import './styles.css'

// Utils
import { Auth } from "../../utils/auth";
import { CheckResponse } from '../../utils/checkResponse';
import { useTranslation } from 'react-i18next'
import { downloadPDF } from '../../utils/commonFunctions/downloadPdf';
import { arrayMultipleData } from '../../utils/commonFunctions/returnArrayMultiple';

// Icons
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';

// Permissions
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../Context/PermissionsContext';
import { pdfTableStyles } from './pdfTableStyles';

export const DynamicForm = (props) => {
  const { t } = useTranslation();
  const ability = useAbility(AbilityContext);

  const workflowDataApi = new WorkflowDataApi();
  const plotPlanApi = new PlotPlanApi();
  const { check } = new CheckResponse();
  const { getUser } = new Auth();

  const location = useLocation();
  const workflowData = JSON.parse(localStorage.getItem('workflowData'));
  const navigate = useNavigate();
  const [form, setForm] = useState(null);
  const [data, setData] = useState({});
  const [observations, setObservations] = useState('');
  const [errorObservations, setErrorObservations] = useState('');
  const [params, setParams] = useState(null);
  const [notificationId, setNotificationId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [initLoading, setInitLoading] = useState(false);
  const [formsLoading, setFormsLoading] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [formType, setFormType] = useState({
    type: '',
    title: '',
    route: '',
    state: {}
  });
  const [dialog, setDialog] = useState({
    title: '',
    message: '',
    type: null
  });
  const [workflowDataId, setworkflowDataId] = useState(null);
  const [locationData, setLocationData] = useState({})
  const [dialogAttachments, setDialogAttachments] = useState(false);
  const [action, setAction] = useState('save');
  const [rejection, setRejection] = useState('');
  const [isReqReject, setIsReqReject] = useState(false);

  const [formInstance, setFormInstance] = useState(null)

  // Instance state
  const [openInstanceStateModal, setOpenInstanceStateModal] = useState({
    status: false,
    action: null,
    isDelete: false,
    isCredentials: false
  })

  // External
  const [isExternal, setIsExternal] = useState(false)
  const formRef = useRef(null);
  const originalStylesRef = useRef("");

  const [itemsRejection, setItemsRejection] = useState([]);

  const handleRejectionSelect = (event) => {
    if(event){
      setRejection(event.target.value);
      setIsReqReject(false)
    }
  };

  const handleObservation = (event) => {
    setObservations(event.target.value);
    let selectRejection = rejection.observationsRequired
    if (selectRejection || !selectRejection && observations) {
      showErrorMessage('')
      setErrorObservations('')
    }
  }

  useEffect(() => {
    setInitLoading(true)
    clearData()
    let loc = location.state || props?.data;
    setItemsRejection(loc?.workflowStatus.cancellationReasons)
    setLocationData(loc)
    setIsExternal(loc?.isExternal)

    if (loc?.id) {
      async function fetchData() {
        let params = loc.externalId ? { externalId: loc.externalId } : { formId: loc.formId };
        params.id = loc.id;
        setParams(params);
        setNotificationId(loc.notificationId)
        await formTypeConfig(loc);

        await callFormType(loc, params);
        setworkflowDataId(loc.id)
      }
      fetchData();
    }
    setInitLoading(false)
  }, [location?.state?.notificationId])

  function clearData() {
    setOpenInstanceStateModal({
      status: false,
      action: null,
      isDelete: false,
      isCredentials: false
    })
  }

  function getTitle(type, typeName) {
    if (type === 'fill_form' || type === 'update_status') return `${t('forms.dynamicForm.fillOutForm')} ${typeName}`
    else if (type === 'edit_form') return `${t('forms.dynamicForm.editForm')} ${typeName}`
    else if (type === 'check_form') return `${t('forms.dynamicForm.approve')} ${typeName}`
    else return `${t('forms.dynamicForm.fillOutForm')} ${typeName}`
  }

  async function formTypeConfig(loc) {
    setFormType({
      type: '',
      title: {
        name: getTitle(loc.type, ''),
        prefix: loc.workflowData?.code,
      },
      route: loc.previousRouteData?.pathname || Router.appWorkflow.replace(':id', loc.workFlowId),
      state: loc.previousRouteData?.state
    });
  }

  async function callFormType(loc, params) {
    setLoading(true)

    setReadOnly(false)
    if (loc.type === 'check_form' || loc.type === 'check_form_credentials') {
      setReadOnly(loc.workflowStatus.fillForm === true ? false : true)
      setAction('approve')
      await formData(params, loc, true)
    }
    else {
      setAction('save')
      await formData(params, loc, false)
    }
    setLoading(false)
  }

  async function formData(params, workflowData, isDisabled) {
    setFormsLoading(true)
    let response = await workflowDataApi.getFormData(params);
    let result = check(response)

    if (result.status) {
      let resp = response.data
      if (resp.previousData !== undefined) {
        localStorage.setItem('previousData', JSON.stringify(resp.previousData))
        localStorage.setItem('parentPreviousData', JSON.stringify(resp.parentPreviousData))
        localStorage.setItem('characteristics', JSON.stringify(resp.characteristics))
        localStorage.setItem('apiUrl', process.env.REACT_APP_API_URL)
      }

      let structure = resp.form?.structure || []

      await formatCharacteristics(resp, workflowData, isDisabled);
      if (response.data.observations) setObservations(response.data.observations)
      setForm(structure);
      setData(resp.data || {});
    }
    else {
      toast.error(() =>
        <div>{t('forms.dynamicForm.messages.showForm.title')}<br />
          {result.errors}
        </div>
      );
    }
    setFormsLoading(false)
  }

  async function findCharacteristics(id) {
    let response = await workflowDataApi.detailWorkflowInstance(id);
    let result = check(response);

    if (result.status) {
      return response.data
    }
    return null
  }

  async function formatCharacteristics(resp, workflowData, isDisabled) {
    let data = null
    if (resp.characteristics === null) {
      let result = await findCharacteristics(resp.id);
      if (Object.keys(result.characteristics)?.length > 0) {
        result.characteristics = {
          ...result.characteristics,
          disabled: true
        }
        resp.characteristics = result.characteristics
      }
    }
    else {
      resp.characteristics = {
        ...resp.characteristics,
        disabled: isDisabled
      }
    }

    let arrayPlotPlans = resp.characteristics?.plotPlan?.id ? [resp.characteristics?.plotPlan] : resp.characteristics?.plotPlans
    let config = await configPlotPlans(resp.characteristics, arrayPlotPlans, workflowData)
    data = config.resp

    if (data !== null) {
      data.validityFrom = data.validityFrom ? moment(data.validityFrom).format("YYYY-MM-DD") : ''
      data.validityTo = data.validityTo ? moment(data.validityTo).format("YYYY-MM-DD") : ''

      // If the form is readOnly then show all the plot plans (even the deleted ones)
      // If is not readOnly only show the plot plans that are available
      if (!isDisabled) {
        let plotPlans = arrayMultipleData(arrayPlotPlans, config.plans)
        data.plotPlans = plotPlans
      }
      else {
        data.plotPlans = arrayPlotPlans
      }
    }
  }

  // Return the plot plans associated to the plant of the instance
  async function plotPlans(plantId) {
    let response = await plotPlanApi.list();
    if (response.status === 200) {
      let filtered = response.data
      if (plantId) {
        filtered = response.data.filter((el) => el.plant?.id === plantId)
      }
      return filtered
    }
  }

  // Check if the plot plans of the instance are associated to the plant of the instance
  async function configPlotPlans(resp, arrayPlotPlans, workflowData) {
    let data = { plans: [], resp: resp }
    if (resp?.hasPlotPlan) {
      data.plans = await plotPlans(workflowData?.workflowData?.plant?.id);
      let selectedPlans = arrayPlotPlans?.map((obj) => obj.id)
      let plans = data.plans.filter((el) => selectedPlans.includes(el.id))

      // If the plot plan doesn't exist in the plot plans associated to the plant then assign the plot plans of the workflow data
      if ((selectedPlans?.length !== plans?.length) && workflowData.workflowData?.plotPlans?.length > 0) {
        plans = workflowData.workflowData.plotPlans
      }

      // If the plot plans exist
      if (plans?.length > 0 && resp?.plotPlans?.length > 0) data.resp.plotPlans = plans
      else data.resp.plotPlans = []
    }

    return data
  }

  function validateApproval() {
    // If reject select doesn't have a selected value
    if(!rejection){
      setIsReqReject(true)
      showErrorMessage([t('forms.dynamicForm.validations.rejections')])
      return true
    }
    // If the option selected in the select has ObservationsRequired as true,
    // and the observations field is empty, then observations will be required.
    if (rejection.observationsRequired === true && !observations) {
      showErrorMessage([t('forms.dynamicForm.validations.observations')])
      setErrorObservations(t('common.mandatory', { field: '' }))
      return true;
    }

    return false;
  }

  async function saveForm(users) {
    let dataSend = {
      ...params,
      notificationId: notificationId,
      data: data,
      users: users.users
    }
    let toastInfo;
    setLoading(true)
    if (data._id) delete data._id;
    // extraemos la key formUploadFiles del objeto que se va enviar
    let findFormFiles = findFormUploadFiles(data);
    // verificamos si en el formulario que se va a enviar se han cargado archivos, si es asi que muestre el toast mientras se guarda el formulario
    if(findFormFiles?.length > 0){
      toastInfo = toast.info(t('files.messages.saveFile.process'), {autoClose: false})
    }
    let response = await workflowDataApi.saveFormData(dataSend);
    let result = check(response)

    if (result.status) {
      setOpenDialog(true)
      setDialog({
        title: t('forms.dynamicForm.messages.saveForm.title'),
        message: t('forms.dynamicForm.messages.saveForm.message')
      })
      if (toastInfo) {
        toast.dismiss(toastInfo);
      }
    }
    else {
      toast.error(() =>
        <div>{t('forms.dynamicForm.messages.saveForm.error')}<br />
          {result.errors}
        </div>
      );
      if (toastInfo) {
        toast.dismiss(toastInfo);
      }
    }
    setLoading(false)
  }

  async function saveApproveForm(users) {

    setLoading(true)
    let dataSend = {
      ...params,
      approved: users.extraData,
      notificationId: notificationId,
      users: users.users,
      observations: observations,
      cancellationReasonId: rejection?.id
    }
    if (locationData.workflowStatus?.fillForm) {
      if (data._id) delete data._id
      dataSend.data = data;
    }

    let response = await workflowDataApi.approveForm(dataSend);
    let result = check(response)

    if (result.status) {
      setOpenDialog(true)
      setDialog({
        title: t('forms.dynamicForm.messages.approveForm.title'),
        message: t('forms.dynamicForm.messages.approveForm.message',
          {
            value: users.extraData
              ? 'forms.dynamicForm.approved'
              : 'forms.dynamicForm.rejected'
          })
      })

    }
    else {
      toast.error(() =>
        <div>{t('forms.dynamicForm.messages.approveForm.error')}<br />
          {result.errors}
        </div>
      );
    }
    setLoading(false)
  }

  async function saveApproveFormCredentials(users) {

    setLoading(true)
    let dataSend = {
      ...params,
      approved: users.extraData,
      notificationId: notificationId,
      users: users.users,
      credentials: users.credentials,
      observations: observations,
      cancellationReasonId: rejection?.id
    }
    if (locationData.workflowStatus?.fillForm) {
      if (data._id) delete data._id
      dataSend.data = data;
    }

    let response = await workflowDataApi.checkFormCredentials(dataSend);
    let result = check(response)

    if (result.status) {
      setOpenDialog(true)
      setDialog({
        title: t('forms.dynamicForm.messages.approveForm.title'),
        message: t('forms.dynamicForm.messages.approveForm.message',
          {
            value: users.extraData
              ? 'forms.dynamicForm.approved'
              : 'forms.dynamicForm.rejected'
          })
      })

    }
    else {
      toast.error(() =>
        <div>{t('forms.dynamicForm.messages.approveForm.error')}<br />
          {result.errors}
        </div>
      );
    }
    setLoading(false)
  }

  function handleOpenDelete() {
    setDialog({
      title: t('forms.dynamicForm.messages.deleteForm.confirm'),
      message: t('forms.dynamicForm.messages.deleteForm.confirmMessage'),
      type: 'confirmation'
    })
    setOpenDialog(true)
  }

  async function deleteForm(users) {

    let data = {
      id: params.id,
      notificationId: notificationId,
      statusId: location?.state?.workflowStatus?.statusId,
      users: users.users,
      cancelObservations: users.cancelObservations
    }
    setLoading(true)
    const response = await workflowDataApi.delete(data);
    let result = check(response)

    if (result.status) {
      setOpenDialog(true)
      setDialog({
        title: t('forms.dynamicForm.messages.deleteForm.title'),
        message: t('forms.dynamicForm.messages.deleteForm.message'),
        type: null,
      })
    }
    else {
      toast.error(() =>
        <div>{t('forms.dynamicForm.messages.deleteForm.error')}<br />
          {result.errors}
        </div>
      );
    }

    setLoading(false)
  }

  async function handleCloseDialog(value, isClose) {
    setOpenDialog(false)
    if (value) {
      showInstanceState(deleteForm, null, true)
      return
    }
    if (isClose) {
      return
    }

    clearData()

    if (formType.route) navigate(formType.route, { state: formType.state });
    else navigate(-1);
  }

  function openAttachments() {
    setDialogAttachments(true)
  }

  async function showInstanceState(action, data, isDelete, type) {
    if (type === 'approve') setLocationData({ ...locationData, 'approveInstance': data })

    setOpenInstanceStateModal({
      status: true,
      action: action,
      isDelete: isDelete,
      data: data,
      isCredentials: locationData.type === 'check_form_credentials'
    })
  }

  async function handleCloseInstance() {
    setOpenInstanceStateModal({ status: false })
  }

  function showErrorMessage(errors) {
    if (errors?.length > 0) {
      toast.error(() =>
        <>{t('forms.dynamicForm.messages.errors')}:<br />
          <ul style={{ listStyle: 'circle', marginLeft: 16 }}>
            {errors?.map(row => (<li key={row}><p>{row}</p></li>))}
          </ul>
        </>
      );
    }
  }

  async function checkKeys(key) {
    if (key === 'btnSave') {
      if (!formInstance.checkValidity(data, true)) {
        toast.error(t('forms.dynamicForm.messages.errorsForm'))
        return
      }
      showInstanceState(saveForm)
    }
    if (key === 'btnCancel') {
      navigate(formType.route, { state: formType.state })
    }
    if (key === 'btnApprove') {
      setIsReqReject(false);
      if (!formInstance.checkValidity(data, true)) {
        toast.error(t('forms.dynamicForm.messages.errorsForm'))
        return
      }
      showInstanceState(
        locationData.type === 'check_form_credentials' ?
          saveApproveFormCredentials :
          saveApproveForm,
        true,
        null,
        'approve'
      )
    }
    if (key === 'btnReject') {
      if (validateApproval()) return
      showInstanceState(
        locationData.type === 'check_form_credentials' ?
          saveApproveFormCredentials :
          saveApproveForm,
        false,
        null,
        'approve'
      )
    }
    if (key === 'btnDelete') {
      handleOpenDelete();
    }
  }

  const renderButtons = () => {
    let buttons = [];
    let spaceGrid = 6;

    if (action === 'save') {
      buttons = [{ 
        title: 'close', 
        grid: 2, 
        key: 'btnCancel', 
        type: 'cancel', 
        variant: 'outlined', 
        isUpdateStatus: locationData.type === 'update_status' ? true : false
      }]
      spaceGrid = 8;

      if (ability.can("SaveFormData")) {
        buttons.push({ 
          title: 'save', 
          grid: 2, 
          key: 'btnSave', 
          variant: 'contained',  
          isUpdateStatus: locationData.type === 'update_status' ? true : false  
        });
        spaceGrid = 6;
      }
    }
    else {
      if (ability.can("ApproveWorkflowData") || ability.can("ApproveWorkflowDataCredentials")) {
        buttons = [
          { title: 'reject', grid: 2, key: 'btnReject', type: 'cancel', variant: 'outlined' },
          { title: 'approve', grid: 2, key: 'btnApprove', variant: 'contained' }
        ]
        spaceGrid = 6;
      }
      spaceGrid = 10;
    }

    buttons.push({ title: '', grid: spaceGrid, key: '' });

    if (ability.can("DeleteWorkflowData")) {
      buttons.push({ title: 'cancel', grid: 2, key: 'btnDelete', type: 'delete', variant: 'contained', disabled: workflowData?.canCancel });
    }

    return buttons
  }

  const generatePdf = async () => {
    let foundElement = findElement(formInstance)
    let route = foundElement.path.join(".")
    let copyFormInstance = formInstance
    let instanceElement = eval(`copyFormInstance.${route}`)
    originalStylesRef.current = instanceElement.component.content
    instanceElement.component.content = instanceElement.component.content + pdfTableStyles()
    setFormInstance(copyFormInstance)

    formInstance.triggerRedraw()
    formInstance.refresh()

    setTimeout(async() => {
      await downloadPDF(form?.[0], formRef, locationData?.workflow?.name || 'form_pdf', setLoading)
    }, 1000);

    setTimeout(() => {
      instanceElement.component.content = originalStylesRef.current
      setFormInstance(copyFormInstance)
  
      formInstance.triggerRedraw()
      formInstance.refresh()
    }, 1000);
  }

  const findElement = (obj, path = []) => {
    if (obj.component?.tag === "style") {
      return { element: obj, path };
    }

    if (obj.components) {
      for (let i = 0; i < obj.components.length; i++) {
        const component = obj.components[i];
        const result = findElement(component, [...path, `components[${i}]`]);
        if (result) {
          return result;
        }
      }
    }

    return null;
  }

  // función recursiva para encontrar la key formUploadFiles del objeto formulario que se enviara en el post
  function findFormUploadFiles(obj) {
    if (obj.hasOwnProperty('formUploadFiles')) {
        return obj['formUploadFiles'];
    }

    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            let value = obj[key];

            if (value !== null && (typeof value === 'object' || Array.isArray(value))) {
                if (Array.isArray(value)) {
                    for (let item of value) {
                        let result = findFormUploadFiles(item);
                        if (result !== undefined) {
                            return result;
                        }
                    }
                } else {
                    let result = findFormUploadFiles(value);
                    if (result !== undefined) {
                        return result;
                    }
                }
            }
        }
    }

    return undefined;
}


  return (
    <Container open={props.open} isExternal={isExternal} mb={10}>
      <PageTitle title={formType.title} icon={<DriveFileRenameOutlineIcon />} dinamicForm={true} />

      <FormContainer container spacing={2}>
        <Grid item xs={12}>
          {!initLoading &&
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={12}>
                <CharacteristicsForm
                  openAttachments={openAttachments}
                  generatePdf={generatePdf}
                />
              </Grid>
              {(observations &&
                (locationData.type === 'edit_form' || locationData.type === 'fill_form')
              ) &&
                <Grid item xs={12} mt={2}>
                  <CustomTypography>{t('forms.characteristics.observations')}:</CustomTypography>
                  <CustomTypography>{observations}</CustomTypography>
                </Grid>
              }
            </Grid>
          }
        </Grid>
        <Grid item xs={12}>
          {!formsLoading &&
            <>
              <div ref={formRef}>
                <FormIo
                  form={form}
                  data={data}
                  setData={setData}
                  readOnly={readOnly}
                  checkKeys={checkKeys}
                  formInstance={formInstance}
                  setFormInstance={setFormInstance}
                />
              </div>

              {(ability.can("ApproveWorkflowData") || ability.can("ApproveWorkflowDataCredentials")) &&

                <>
                  {
                    locationData.type === 'check_form' &&
                      <Grid item xs={12} mt={2}>
                        <CustomTypography error={isReqReject}>{t('forms.characteristics.rejection')}:</CustomTypography>
                        <CustomFormControl required={isReqReject} error={isReqReject}>
                          {
                            isReqReject && 
                            <CustomInputLabel>
                              {t('forms.characteristics.rejection')}
                            </CustomInputLabel>
                          }
                          <CustomSelect
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={rejection}
                            onChange={handleRejectionSelect}
                            renderValue={(e) => e.name}
                          >
                            {itemsRejection.map((item) => (
                              <CustomMenuItem key={item.id} value={item}>
                                {item.name}
                              </CustomMenuItem>
                            ))}
                          </CustomSelect>
                        </CustomFormControl>
                      </Grid>
                  }

                  <Grid container>
                    {(locationData.type === 'check_form' || locationData.type === 'check_form_credentials') &&
                      <Grid item xs={12} mt={2}>
                        <CustomTypography>{t('forms.characteristics.observations')}:</CustomTypography>
                        <CustomTextField
                          value={observations || ''}
                          size="small"
                          type="text"
                          onChange={handleObservation}
                          error={!(!errorObservations)}
                          helperText={errorObservations}
                          multiline
                          rows={2}
                        />
                      </Grid>
                    }
                  </Grid>
                </>
              }

              <Grid container mt={2}>
                {renderButtons().map((row, index) => {
                  return (
                    <Grid item xs={12} sm={row.grid} key={`${row.title}-${index}`}>
                      {row.title &&
                        <FormButton variant={row.variant} type={row.type} btnCancel={row?.disabled} isUpdateStatus={row?.isUpdateStatus} sx={{ width: '90%' }}
                          onClick={() => checkKeys(row.key)}
                          disabled={row?.disabled === false}
                        >
                          {t(`workflow.detail.${row.title}`)}
                        </FormButton>
                      }
                    </Grid>
                  )
                })}
              </Grid>
            </>
          }
        </Grid>

      </FormContainer>

      <BackdropLoading open={loading} />
      <ShowDialog openDialog={openDialog} dialog={dialog} handleCloseDialog={handleCloseDialog} />

      <Dialog
        open={dialogAttachments}
        onClose={() => setDialogAttachments(false)}
        maxWidth="md"
        fullWidth={true}
      >
        <DialogContent dividers>
          <Attachments id={workflowDataId} disabled={false} userData={getUser()?.email ?? ""} statusData={location?.state?.workflowStatus} />
        </DialogContent>
        <DialogActions>
          <FormButton onClick={() => setDialogAttachments(false)}>{t('common.close')}</FormButton>
        </DialogActions>
      </Dialog>

      <Modal open={openInstanceStateModal.status}>
        <BoxModal>
          <InstanceStateModalCard
            data={locationData}
            extraData={openInstanceStateModal.data}
            isDelete={openInstanceStateModal.isDelete}
            isCredentials={openInstanceStateModal.isCredentials}
            handleSave={openInstanceStateModal.action}
            handleClose={handleCloseInstance} />
        </BoxModal>
      </Modal>
    </Container>
  )
}