import React, { useEffect, useState } from 'react';

// Components
import { toast } from 'react-toastify';

// Custom components
import { InstanceState } from '../../../molecules/forms/InstanceState';
import { BackdropLoading } from '../../../BackdropLoading';

// API
import { UserApi } from '../../../../services/UserApi';
import { WorkflowApi } from '../../../../services/WorkflowApi';

// Styles
import { Container, Header, Body, Footer, SectionRight, Spacer, } from './styles'
import { FormButton } from '../../../../pages/styles';

// Utils
import { CheckResponse } from '../../../../utils/checkResponse';
import { validateForm } from '../../../../utils/commonFunctions';
import { useTranslation } from 'react-i18next'
import { Auth } from '../../../../utils/auth';

// Permissions
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../../../Context/PermissionsContext';

export const InstanceStateModalCard = ({
    data, extraData,
    isCreateInstance, isDuplicate, isDelete, isCredentials,
    handleSave, handleClose, isFashApprove, setOpenInstanceStateModal
}) => {
    const userApi = new UserApi();
    const workflowApi = new WorkflowApi();
    const {getUser} = new Auth();
    const {check} = new CheckResponse();
    const { t } = useTranslation();
    const ability = useAbility(AbilityContext);

    const [users, setUsers] = useState([])
    const [plants, setPlants] = useState([])
    const [plant, setPlant] = useState({})
    const [contracts, setContracts] = useState([])
    const [contract, setContract] = useState({})
    const [isContractor, setIsContractor] = useState(false)
    const [tab, setTab] = useState(0)
    const [plantDisabled, setPlantDisabled] = useState(false)
    const [workflow, setWorkflow] = useState({})
    const [roles, setRoles] = useState([])
    const [loading, setLoading] = useState(false)
    const [isReqReject, setIsReqReject] = useState(false);
    const [form, setForm] = useState({
        nextStatusId: null,
        notificationTo: [],
        observations: '',
        cancelObservations: '',
        reasonRejection: {}
    })
    const [formCredentials, setFormCredentials] = useState({
        username: '',
        password: ''
    })
    const [errors, setErrors] = useState({
        notificationTo: {error: "", field: t('workflow.instanceState.interNotify'), required: false},
    })
    const [itemsRejection, setItemsRejection] = useState([])
    const [errorObservations, setErrorObservations] = useState('')
    const [errorCancelObservations, setErrorCancelObservations] = useState('')

    useEffect(() => {
        async function call() {
            setLoading(true)

            if (isCreateInstance) await getUserDetail(getUser().email);
            else setContract(data.contract || data.workflowData?.contract || {})

            let id = data.workflowId || data.workflow?.id || data.initialWorkflowStatus?.workflowId
            let workflowData = null
            setItemsRejection(data?.workflowStatus?.cancellationReasons || [])

            if (id) {
                workflowData = await getWorkflow(id)
                setWorkflow(workflowData)
            }

            if (isCreateInstance || isDuplicate) {
                setRoles(workflowData?.initialWorkflowStatus?.roles || [])
            }
            else {
                let actionRoles = data.approveInstance === false ? data.rejectStatus?.roles : data.nextStatus?.roles
                setRoles(actionRoles || [])
            }

            let userPlant = getPlant()
            if (userPlant) await getUsers(userPlant)

            setLoading(false)
        }
        call()
    }, [data])

    async function getWorkflow(id) {
        let response = await workflowApi.detail(id);
        let result = check(response)

        if (result.status) {
            return response.data
        }
        else {
            toast.error(() => <>{result.errors}</>)
        }
    }

    async function getUserDetail(filter) {
        if (ability.can("GetUser")) {
            let response = await userApi.detail(filter);
            let result = check(response)

            if (result.status) {
                let data = response.data.data
                let arrayContracts = []

                if (data.isContractor) {
                    if (data.contractPlants?.length > 0) {
                        data.contractPlants.map((obj) => {
                            obj.contract.listPlants = obj.plants
                            arrayContracts.push(obj.contract)
                        })
                        setIsContractor(true)
                    }
                    else {
                        setIsContractor(false)
                    }
                    setContracts(arrayContracts)
                    setPlantDisabled(true)
                }
                else {
                    setIsContractor(false)
                    setPlantDisabled(false)
                    setPlants(data.plants)
                }
            }
            else {
                toast.error(() => <>{result.errors}</>)
            }
        }
    }

    async function getUsers(id) {
        let response = await userApi.list(true, id, true);
        let result = check(response)

        if (result.status) {
            setForm({...form, assignedTo: [], notificationTo: []})
            setUsers(response.data?.data)
        }
        else {
            toast.error(() => <>{result.errors}</>)
        }
    }

    function getPlant() {
        let plantData = ''

        if (!isCreateInstance) {
            plantData = data.workflowData?.plant || data.plant || ''
            setPlantDisabled(true)
        }

        setPlant(plantData)
        return plantData?.id
    }

    function handleChange(event, name) {
        setErrors({...errors, [name]: {...errors[name], error: ''}})
        setForm({...form, [name]: event})
        if (name === 'cancelObservations') setErrorCancelObservations('')
        if (name === 'reasonRejection' && event?.id) {
          setIsReqReject(false)
          if (!event.observationsRequired) setErrorObservations('')
        }
    }

    function handleChangeCredentials(event, name) {
        setFormCredentials({...formCredentials, [name]: event.target.value})
    }

    async function handleChangePlants(event) {
        setPlant(event)
        await getUsers(event.id)
    }

    async function handleChangeContract(event) {
        setContract(event)
        setPlants(event.listPlants || [])
        setPlantDisabled(false)
        setPlant({})
    }

    function handleChangeTab(_, value) {
        setTab(value);
    }

    function checkSave() {
        let hasError = false
        let validate = validateForm(form, errors)
        if (validate.hasErrors) {
            setErrors(validate.errors)
            hasError = true
        }
        if (data.showApproveForm && !data.approveInstance) {
          if (Object.keys(form.reasonRejection)?.length === 0) {
            setIsReqReject(true)
            toast.error(t('common.mandatory', {field: t('forms.characteristics.rejection')}))
            hasError = true
          }
        }
        if (form.reasonRejection.observationsRequired && !form.observations) {
          toast.error(t('common.mandatory', {field: t('forms.characteristics.observations')}))
          setErrorObservations(t('common.mandatory', {field: t('forms.characteristics.observations')}))
          hasError = true
        }
        if (isDelete && !form.cancelObservations) {
            setErrorCancelObservations(t('common.mandatory', {field: t('forms.characteristics.deletionJustification')}))
            hasError = true
        }
        let nextStatusId = data?.nextStatus?.id || data?.initialWorkflowStatus?.id || workflow?.initialWorkflowStatus?.id
        if (data?.approveInstance === false) {
            nextStatusId = data.rejectStatus?.id
        }
        if (extraData?.approveInstance === false) {
            nextStatusId = extraData.rejectStatus?.id
        }
        if(isDuplicate){
            nextStatusId = data.id
        }

        if(!hasError){
            handleSave({
                users: {
                    nextStatusId: nextStatusId,
                    notificationTo: form.notificationTo.map(({id}) => {return id}),
                    plantId: plant.id,
                    contractId: isContractor ? contract.id : null
                },
                extraData: extraData,
                cancellationReasonId: form.reasonRejection?.id,
                observations: form.observations,
                cancelObservations: form.cancelObservations,
                credentials: formCredentials,
            })

            if (setOpenInstanceStateModal) {
                setOpenInstanceStateModal({status: false});
            }
        }
    }

    return (
        <Container>
            <Header>{t('workflow.instanceState.title')}</Header>
            <Body>
                <InstanceState
                    data={data}
                    isDelete={isDelete}
                    isDuplicate={isDuplicate}
                    isCreateInstance={isCreateInstance}
                    isCredentials={isCredentials}
                    users={users}
                    plants={plants}
                    plant={plant}
                    contracts={contracts}
                    contract={contract}
                    isContractor={isContractor}
                    plantDisabled={plantDisabled}
                    tab={tab}
                    form={form}
                    formCredentials={formCredentials}
                    errors={errors}
                    workflow={workflow}
                    roles={roles}
                    isReqReject={isReqReject}
                    itemsRejection={itemsRejection}
                    errorObservations={errorObservations}
                    errorCancelObservations={errorCancelObservations}
                    handleChange={handleChange}
                    handleChangePlants={handleChangePlants}
                    handleChangeContract={handleChangeContract}
                    handleChangeTab={handleChangeTab}
                    handleChangeCredentials={handleChangeCredentials}
                />
            </Body>
            <Footer>
                <SectionRight>
                    <FormButton
                        variant="outlined"
                        type="close"
                        onClick={() => handleClose(false)}
                        >
                        {t('common.cancel')}
                    </FormButton>
                        <Spacer/>
                    <FormButton
                        variant="contained"
                        onClick={checkSave}
                    >
                        {t('common.confirm')}
                    </FormButton>
                </SectionRight>
            </Footer>
            <BackdropLoading open={loading}/>
        </Container>
    )
}