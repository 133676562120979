import React, { useEffect } from 'react';
import { toast } from 'react-toastify';

// Components
import { Button, Grid, TextField } from '@mui/material';

import { UserApi } from '../../../../services/UserApi';

// Styles
import {
    Container,
    Header,
    Body,
    Footer,
    ContainerInfo,
    SectionRight,
    Spacer,
    SectionTopInfo,
    Text,
    TitleInfo,
    SubtitleInfo
} from './styles'

// Utils
import { ColorsContext } from "../../../../Context/ColorsContext"
import { SearchButton } from '../../../../pages/styles';
import { useTranslation } from 'react-i18next'

export const ChangePassword = ({ username, handleClose }) => {

	const { t } = useTranslation();
    const userApi = new UserApi();
    const { colors } = React.useContext(ColorsContext);

    const [loading, setLoading] = React.useState(false)

    // const [openChangePasswordForm, setOpenChangePasswordForm] = React.useState(false)
    const [formChangePassword, setFormChangePassword] = React.useState({
		currentPassword: '',
		newPassword: '',
		confirmPassword: ''
	})

    const [errorChangePassword, setErrorChangePassword] = React.useState({
		currentPassword: { hasError: false, message: '' },
        newPassword: { hasError: false, message: '' },
        confirmPassword: { hasError: false, message: '' },
        general: { hasError: false, message: '', color: 'red' },
	})

    const handleChangePassword = (event, key) => {
		setFormChangePassword({
            ...formChangePassword,
            [`${key}`]: event.target.value
        })
	}

	const resetErrorChangePassword = () => {
        setErrorChangePassword({
            ...errorChangePassword,
            currentPassword: { hasError: false, message: '' },
            newPassword: { hasError: false, message: '' },
            confirmPassword: { hasError: false, message: '' },
            general: { hasError: false, message: '' },
        })
    }

	const validateForm = () => {
		let errors = { ...errorChangePassword }
        let hasError = false

        if (formChangePassword.currentPassword === '') {
            errors.currentPassword = { hasError: true, message: t('password.validations.currentPassword') }
            hasError = true
        }
		if (formChangePassword.newPassword === '') {
            errors.newPassword = { hasError: true, message: t('password.validations.newPassword') }
            hasError = true
        }
		if (formChangePassword.confirmPassword === '') {
            errors.confirmPassword = { hasError: true, message: t('password.validations.confirmPassword') }
            hasError = true
        }
		if (formChangePassword.newPassword !== formChangePassword.confirmPassword) {
			errors.newPassword = { hasError: true, message: t('password.validations.matchPasswords') }
			errors.confirmPassword = { hasError: true, message: t('password.validations.matchPasswords') }
            hasError = true
		}
		if (formChangePassword.currentPassword === formChangePassword.confirmPassword) {
			errors.newPassword = { hasError: true, message: t('password.validations.differentPasswords') }
            hasError = true
		}
        setErrorChangePassword(errors)
        return hasError
	}

    const getData = () => {
        const data = {...formChangePassword}
        data.username = username
        data.oldPassword = data.currentPassword

        return data
    }

    const renderMsgGeneral = (message, success = false) => {
        let errors = {...errorChangePassword}
        errors.general = { hasError: true, message: message || t('password.messages.updatePassword.error'), color: success ? "green" : "red" }
        setErrorChangePassword(errors)
    }

	const handleAcceptChangePassword = () => {
        resetErrorChangePassword()
        if (!validateForm()) {
            resetErrorChangePassword()
			const data = getData()
			setLoading(true)
            userApi.updatePassword(data).then((resp) => {
                setLoading(false)
                if (resp?.status === 204) {
                    renderMsgGeneral(t('password.messages.updatePassword.title'), true)
                    // toast.success("Contraseña actualizada satisfactoriamente", {
                    //     toastId: "change-password-success",
                    //     position: toast.POSITION.TOP_CENTER
                    // })
                    setTimeout(() => {
                        handleClose()
                    }, 1500)
                } else {
                    // let message = response.data.description || response.data.data.description || 'No es posible actualizar la contraseña, intentelo nuevamente'
                    // toast.error(message, {
                    //     toastId: "change-password-error",
                    //     position: toast.POSITION.TOP_CENTER
                    // })
                    renderMsgGeneral(resp?.response.data.description || resp?.response.data.data.description)
                }
            })
		}
	}

    return (
        <>
            <Container>
                <Header>
                    <TitleInfo>
                        {`${t('password.passwordUpdate')}`}
                    </TitleInfo>
                    <SubtitleInfo>
                        {username}
                    </SubtitleInfo>
                </Header>
                <Body>
                    <ContainerInfo>
                        <SectionTopInfo>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        type={'password'}
                                        label={t('password.currentPassword')}
                                        value={formChangePassword.currentPassword}
                                        onChange={e => handleChangePassword(e, 'currentPassword')}
                                        sx={{ width: '100%' }}
                                        error={errorChangePassword.currentPassword.hasError}
                                        helperText={errorChangePassword.currentPassword.message}
                                        disabled={loading}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        type={'password'}
                                        label={t('password.newPassword')}
                                        value={formChangePassword.newPassword}
                                        onChange={e => handleChangePassword(e, 'newPassword')}
                                        sx={{ width: '100%' }}
                                        error={errorChangePassword.newPassword.hasError}
                                        helperText={errorChangePassword.newPassword.message}
                                        disabled={loading}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        type={'password'}
                                        label={t('password.confirmPassword')}
                                        value={formChangePassword.confirmPassword}
                                        onChange={e => handleChangePassword(e, 'confirmPassword')}
                                        sx={{ width: '100%' }}
                                        error={errorChangePassword.confirmPassword.hasError}
                                        helperText={errorChangePassword.confirmPassword.message}
                                        disabled={loading}
                                    />
                                </Grid>
                                {errorChangePassword.general.hasError && 
                                    <Grid item xs={12}>
                                        <Text color={errorChangePassword.general.color}>{errorChangePassword.general.message}</Text>
                                    </Grid>
                                }
                            </Grid>
                        </SectionTopInfo>
                    </ContainerInfo>
                </Body>
                <Footer>
                    <SectionRight>
                        <Button
                            variant="contained"
                            sx={{ 
                                height: 30, 
                                padding: '0 20px', 
                                backgroundColor: colors.white, 
                                color: colors.buttonActionColor, 
                                "&:hover": {
                                    backgroundColor: colors.white,
                                    opacity: 0.9,
                                    color: colors.buttonActionColor,
                                }
                            }}
                            onClick={() => handleClose()}
                            disabled={loading}
                        >
                            {t('common.close')}
                        </Button>
                        <Spacer />
                        <Button
                            variant="contained"
                            sx={{ 
                                height: 30, padding: '0 20px', border: 'none',
                                backgroundColor: colors.buttonActionColor, 
                                color: colors.white, 
                                "&:hover": {
                                    backgroundColor: colors.buttonActionColor,
                                    opacity: 0.9,
                                    color: colors.white,
                                }
                            }}
                            onClick={() => handleAcceptChangePassword()}
                            disabled={loading}
                        >
                            {t('common.confirm')}
                        </Button>
                    </SectionRight>
                </Footer>
            </Container>
        </>
    )
}