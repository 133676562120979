import { Auth } from "../utils/auth";
import { HealthCheckApi } from "./HealthCheckApi";
import Router from "../router";
import axios from "./AxiosConfig";

export class PasswordPolicyApi {
    healthCheck = null;
    token = null;

    constructor() {
        let auth = new Auth();
        this.token = auth.getToken();
        this.healthCheck = new HealthCheckApi();
    }

    async get() {
        let auth_token = { 'Authorization': `Bearer ${this.token}`}

        return await axios.get(`${Router.apiBaseUrl}${Router.apiPasswordPolicy}`, { headers: auth_token })
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)
        })
    }

    async update(id, data) {
        let auth_token = { 'Authorization': `Bearer ${this.token}`}

        return await axios.put(
            `${Router.apiBaseUrl}${Router.apiPasswordPolicy}/${id}`, 
            {...data}, 
            { headers: auth_token }
        )
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)
        })
    }

    async validate(data) {
        return await axios.post(`${Router.apiBaseUrl}${Router.apiPasswordPolicy}/ValidatePassword`, {...data})
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)
        });
    }
}