import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMsal } from "@azure/msal-react";
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next'

// Components
import { BackdropLoading } from '../../components/BackdropLoading';

// Router
import Router from '../../router';

// Services
import { SecurityApi } from '../../services/SecurityApi';

// Styles
import { Content } from './styles';

// Utils
import { Auth } from '../../utils/auth';
import { loginRequest } from '../../utils/msal/authConfig'
import { callMsGraph } from '../../utils/msal/graph'
import { callPermissionsRole } from '../../utils/commonFunctions';


export const HomeSigninAzure = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { authenticate, saveProfile } = new Auth();

    const securityApi = new SecurityApi();
    const [loading, setLoading] = React.useState(false)
    const { instance, accounts } = useMsal();

    const locHash = React.useRef(null);

    const handleSubmit = React.useCallback(async(responseToken, accessToken) => {

        let userLogin = localStorage.getItem('userLogin')

        // Validación de userPrincipalName vs usuario login digitado
        if (responseToken?.userPrincipalName?.toString() !== userLogin?.toString())
        {
            toast.error(t('password.validations.invalidCredentials'))
            navigate(Router.appLogin)
            return
        }

        let data = {
            username: userLogin ?? "",
            token: accessToken
        }

        localStorage.removeItem('userLogin')
        setLoading(true)
        securityApi.loginAD(data).then(async(resp) => {
            setLoading(false)
            if (resp?.status === 200) {
                localStorage.setItem("typeLog", 'tenant')
                let token = resp.data.data.accessToken
        
                authenticate(token)
                saveProfile(resp.data.data.profile)
        
                await callPermissionsRole(resp.data.data.profile.Role.Id);
        
                if (location?.state?.redirect) {
                    navigate(location?.state?.redirect, {state: location?.state?.data})
                    return
                }
                navigate(Router.appMyWork)
            }
            else {
                let errMessage = resp?.response?.data?.description || resp?.response?.data?.data?.description || ''
                if (errMessage !== '') toast.error(errMessage)
                navigate(Router.appLogin)
                return
            }
        })
    }, [])

    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    useEffect(() => {
        let isMounted = true;

        const requestProfileData = async() => {
            setLoading(true)
            try {
                await delay(8000); // Espera 8 segundos

                // Verificar si el componente está aún montado antes de continuar
                if (!isMounted) return;

                if (!accounts?.length) {
                    navigate(Router.appLogin)
                    return
                }
                else
                {
                    const hashRedirect = locHash.current

                    const fragmentParams = new URLSearchParams(hashRedirect.slice(1)); // Elimina el primer carácter '#'
                    const client_info = fragmentParams.get('client_info');

                    // Decodifica la carga útil del token de base64 a UTF-8
                    const decodeBase64Url = (str) => {
                        const base64 = str.replace(/-/g, '+').replace(/_/g, '/');
                        const decoded = atob(base64);
                        return decodeURIComponent(escape(decoded));
                    };
                    const decodeClientInfo = JSON.parse(decodeBase64Url(client_info));

                    let acc = accounts.filter(x => x.localAccountId === decodeClientInfo.uid)
                    if (acc.length === 0) {
                        toast.error(t('password.validations.invalidCredentials'))
                        navigate(Router.appLogin)
                        return
                    }

                    await instance.acquireTokenSilent({
                        ...loginRequest,
                        account: acc[0],
                    }).then(async(resp) => {
                        setLoading(false)
                        let token = resp?.accessToken ?? ''
                        callMsGraph(token).then((response) => {
                            handleSubmit(response, token)
                        });
                    })
                }
            } catch (error) {
                // Manejar errores si la obtención del token falla
                console.error("Error al obtener el token de sesión:", error);
                setLoading(false)
                toast.error(t('login.loginFailed'))
                navigate(Router.appLogin)
                return
            }
        }
        requestProfileData()

        return () => {
            isMounted = false;
        };
    }, [accounts, navigate, setLoading, instance, handleSubmit, location])

    useEffect(() => {
        if (location.hash) {
            locHash.current = location.hash
        }
    }, [location.hash])
    
    

    return (
        <Content container>
            <BackdropLoading open={loading}/>
        </Content>
    )
}

