import React, { Fragment } from 'react';
import { Grid } from '@mui/material';
import { Content, CustomDivider, Title, CustomLink } from './styles';
import { FormatDate } from '../../../utils/formatDate';
import { useTranslation } from 'react-i18next'

export const StatusCardArea = ({data, onClick}) => {
    const { t } = useTranslation();
    const {format} = new FormatDate()

    return (
        <>
            {data.map((row, index) => (
                <Fragment key={"detail-status-" + index}>
                    <Content>
                        <Grid container alignItems="center" justifyContent="center">
                            <Grid item xs={12}>
                                <Title>{t('plotPlans.cardArea.code')}: <CustomLink onClick={() => onClick(row)}>{row.code}</CustomLink></Title>
                            </Grid>
                            {row.title &&
                                <Grid item xs={12}>
                                    <Title>{t('plotPlans.cardArea.title')}: {row.title}</Title>
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <Title>{t('plotPlans.cardArea.lastUpdate')}: {format(row.updatedAt, true)}</Title>
                            </Grid>
                        </Grid>
                    </Content>
                    <CustomDivider/>
                </Fragment>
            ))}
        </>
    )
}

