import { styled } from "@mui/material/styles";
import { Card, TableCell, IconButton } from "@mui/material";
import { useColors } from "../../Context/ColorsContext";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { MediaQuerys } from "../../styles/GlobalStyle";

const { mobile, tablet, desktop, desktoplarge } = MediaQuerys;

export const CustomCard = styled(Card)(() => ({
  minHeight: 430,
  maxHeight: "calc(100vh - 100px)",
  width: "100%",
  borderRadius: 6,
  boxShadow: "rgb(61 71 82 / 20%) 0px 4px 8px",
  padding: "30px 20px",
}));

export const SectionPageTitle = styled("section")(() => ({
  display: "flex",
  width: "100%",
}));
export const CustomTableCell = styled(TableCell)(() => {
  const { colors } = useColors();
  return {
    color: colors.main,
    fontSize: "12px",
    fontWeight: 600,
  }
});

export const TableCellStatus = styled(TableCell)(() => ({
  display: "flex",
}));
export const ContainerCell = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
}));

export const SectionInfo = styled("section")(() => ({
  display: "flex",
  flexDirection: "column",
}));

export const InfoCell = styled("div")(() => ({}));

export const Spacer = styled("div")(() => ({
  display: "flex",
  flex: 1,
}));


export const IconAction = styled(IconButton)(() => {
  const { colors } = useColors();
  return {
    display: 'none',
    borderRadius: 20,
    pointerEvents: "pointer",
    background: colors.buttonActionColor,
    marginLeft: 5,
    "&:hover": {
      backgroundColor: colors.buttonActionColor,
      opacity: 0.9,
      color: colors.white,
    },
    [desktop]: {
      fontSize: "0.25rem",
      width: '28px',
      height: '28px',
      "&MuiSvgIcon-root": {
        width: '16px'
      }
    },
    [tablet]: {
      height: 28,
      width: 28,
    },
    [mobile]: {
      display: "flex",
    },
  };
});


export const NewIcon = styled(AddCircleIcon)(() => {
  const { colors } = useColors();
  return {
    height: '20px',
    width: '20px',
    color: colors.white,
    [desktop]: {
      height: '16px',
      width: '16px'
    }
  };
});


export const ContainerButtonDownload = styled("div")(() => ({
  width: 140,
  height: 40,
  [desktop]: {
    width: "120px",
    height: "30px",
    "& button": {
      fontSize: "0.65rem",
    },
  },
  [tablet]: {
    width: "100px",
    height: 28,
    "& button": {
      fontSize: "7px",
    },
  },
}));


export const ContainersButtonsGroup = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  [mobile]: {
    justifyContent: 'space-between',
    alignItems: 'center',
  }
}))