import { IconButton, Menu } from "@mui/material";
import React from "react";
import { useTranslation } from 'react-i18next'
import { languages } from "../../../utils/i18n/languages/languages";
import { CustomItem, CustomMenuItem, Image } from "./style";

export const LanguageSelect = () => {
    const { i18n, t } = useTranslation(); 
    const [language, setLanguage] = React.useState(languages[0]);
    const [anchorEl, setAnchorEl] = React.useState(null);

    React.useEffect(() => {
        let lang = localStorage.getItem("lang")
        if (lang !== null) {
            let filtered = languages.filter((el) => el.value === lang)[0]
            i18n.changeLanguage(lang)
            setLanguage(filtered);
        }
    }, [])

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
  
    const handleClose = (value) => {
        setAnchorEl(null);
        if (value.value) {
            setLanguage(value);
            i18n.changeLanguage(value.value)
            localStorage.setItem("lang", value.value)
        }
    };

    return (
        <>
        {language !== null &&
            <IconButton
                size="small"
                aria-label="language"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpen}
                color="inherit"
                style={{ borderRadius: 0 }}
            >
                <Image src={language.icon} alt={language.text}/>
                {/* <Image src={language.icon} alt={language.text} width="26px"/>  */}
                <CustomItem bold="true">{t(`language.${language.value}`)}</CustomItem>
            </IconButton>
        }
        <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            {languages.map((option, key) => (
                <CustomMenuItem value={option.value} key={key} onClick={() => handleClose(option)}>
                    <Image src={option.icon} alt={option.text} />
                    {/* <Image src={option.icon} alt={option.text} width="26px"/>  */}
                    <CustomItem>{t(`language.${option.value}`)}</CustomItem>
                </CustomMenuItem>
        ))}
        </Menu>
        </>
    );
}
