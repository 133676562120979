import React, { useEffect, useCallback } from 'react'
import { toast, ToastContainer } from 'react-toastify';

import { PageTitle } from '../../components/PageTitle';
import { InactivityTime } from '../../components/InactivityTime'
import HourglassTopIcon from '@mui/icons-material/HourglassTop';


import { InactivityTimeApi } from '../../services/InactivityTimeApi';

import { Container, } from '../styles';
// Styles
import {
    SectionPageTitle,
} from './styles'

import { useTranslation } from 'react-i18next'

export const SessionCheckConfigForm = (props) => {

    const { t } = useTranslation();
    const inactivityTimeApi = new InactivityTimeApi();

    const actionRef = React.useRef(false)
    const [loading, setLoading] = React.useState(false)
    const [form, setForm] = React.useState({
        id: 0,
        time: 0,
        timeMessage: '',
        isActive: true
    })
    const [error, setError] = React.useState({
        time: { hasError: false, message: '' },
        timeMessage: { hasError: false, message: '' },
        general: { hasError: false, message: '' }
    })

    const resetError = () => {
        setError({
            time: { hasError: false, message: '' },
            timeMessage: { hasError: false, message: '' },
            general: { hasError: false, message: '' }
        })
    }

    const handleChange = (event, key) => {
        setForm({
            ...form,
            [`${key}`]: event.target.value
        })
    }

    const handleState = (value) => {
        let data = form
        setForm({
            ...data,
            isActive: value
        })
    }

    const validateForm = () => {
        let errors = { ...error }
        let hasError = false

        if (isNaN(form.time) || (isNaN(form.time) === false && form.time <= 0)) {
            errors.time = { hasError: true, message: t('system.validations.biggerThan') }
            hasError = true
        }
        if (form.isActive && form.timeMessage === '') {
            errors.timeMessage = { hasError: true, message: t('system.inactivityTimes.validations.inactivityMessage') }
            hasError = true
        }
        setError(errors)
        return hasError
    }

    const renderError = (message) => {
        let errors = {...error}
        errors.general = { hasError: true, message: message || t('system.errorSaving') }
        setError(errors)
    }

    const getData = () => {
        const data = { ...form }
        data.time = parseInt(data.time) || 0
        
        return data
    }
    
    const handleInactivityTime = () => {
        resetError()
        if (!validateForm()) {
            const data = getData()
            setLoading(true)
            inactivityTimeApi.update(data.id, data).then((resp) => {
                setLoading(false)
                if (resp?.status === 204) {
                    toast.success(t('system.inactivityTimes.messages.updatedTimes.title'))
                    loadInactivityTime()
                    resetError()
                } else {
                    renderError(resp.data.description || resp.data.data.description || resp?.response?.data?.description || resp?.response?.data?.data?.description)
                }
            })
        }
    }

    const loadInactivityTime = useCallback(async() => {
        let response = await inactivityTimeApi.get()
        if (response?.status === 200) {
            let resp = response.data
            setForm({
                ...resp
            })
        }
    }, [inactivityTimeApi])

    useEffect(() => {
      if (actionRef.current === false) {
        loadInactivityTime()
        actionRef.current = true
      }
    }, [loadInactivityTime])
    

    return (
        <>
            <ToastContainer theme="colored" position="top-center" autoClose={3000}/>
            {actionRef.current && 
                <InactivityTime 
                    data={form}
                    errors={error}
                    loading={loading} 
                    handleChange={handleChange}
                    handleState={handleState}
                    handleInactivityTime={handleInactivityTime}
                />
            }
        </>
    )
}