import { Button, IconButton  } from "@mui/material";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import Switch from '@mui/material/Switch';

import { styled } from "@mui/material/styles";
import { useColors } from "../../../../Context/ColorsContext";
import { MediaQuerys } from "../../../../styles/GlobalStyle";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Badge from "@mui/material/Badge";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";


const { mobile, tablet, desktop } = MediaQuerys;

export const Container = styled('div')(() => {
    return {
      display: 'flex',
      maxWidth: '1000px',
      minWidth: '700px',
      maxHeight: '800px',
      flexDirection: 'column',
      [tablet]: {
        minWidth: '600px'
      },
      [mobile]: {
        minWidth: '400px'
      }
    }
  })

  export const Header = styled('div')(() => {
    const { colors } = useColors();
    return {
      display: 'flex',
      background: colors.white,
      color: colors.detailTextColor,
      width: '100%',
      height: 'fit-content',
      borderRadius:'10px 10px 0 0',
      padding:'16px',
      textTransform:'uppercase',
      fontWeight:600,
      [desktop]: {
        fontSize: '0.75rem',
      }
    }
  })

  export const Body = styled('div')(() => {
    const { colors } = useColors();
    return {
      display: 'flex',
      flexDirection:'column',
      flex:'1',
      width: '100%',
      background: colors.white,
      maxHeight: 600,
      overflowY: 'hidden'
    }
  })

  export const Footer = styled('div')(() => {
    const { colors } = useColors();
    return {
      display: 'flex',
      background: colors.white,
      width: '100%',
      gap: '1rem',
      height: 'fit-content',
      borderRadius:'0 0 10px 10px',
      justifyContent: 'flex-end',
      alignItems: 'center',
      padding: '8px 16px',
      margin: 'auto'
    }
  })

  export const CustomBtnFilter = styled(Button)(() => {
    const { colors } = useColors();
    return {
      display: 'none',
      height: 25, 
      padding: '0 15px',
      width: '140px',
      color: colors.white,
      backgroundColor: colors.buttonActionColor,
      "&:hover": {
          backgroundColor: colors.buttonActionColor,
          opacity: 0.9,
          color: colors.white,
      },
      [tablet]: {
        display: 'flex',
        // height: '28px',
        fontSize: '0.80rem',
      },
      [mobile]: {
        height: '25px',
      },
    }
  })

  export const ClearIconButton = styled(IconButton)(() => {
    const { colors } = useColors();
    return {
      borderRadius: 20, 
      pointerEvents: 'pointer', 
      background: colors.buttonActionColor,
      color: colors.white,
      marginLeft: 5,
      "&:hover": {
          backgroundColor: colors.buttonActionColor,
          opacity: 0.9,
          color: colors.white,
      },
      [desktop]: {
        fontSize: "0.25rem",
      },
      [tablet]: {
        display: "inline-flex",
        height: 28,
        width: 28,
      },
    }
})

export const ContainerInfo = styled('div')(() => {
    const { colors } = useColors();
    return {
      display: 'flex',
      flexDirection:'column',
      gap: '1.5rem',
      background: colors.white,
      width: '100%',
      // height: 'fit-content',
      padding:'16px 24px',
    }
  })

  export const CleanIcon = styled(CleaningServicesIcon)(() => {
    const { colors } = useColors();
    return {
      color: colors.white,
      fontSize: 18,
      [tablet]: {
        width: 17,
        height: 17,
        padding: '1px',
        '.MuiSvgIcon-root': {
          width: 16,
          height: 16,
        }
      },
    };
  });

// Inputs Dates

export const ContainerGroup = styled("div")(() => {
  return {
    display: "flex",
    width: '100%',
    alignItems: "center",
    justifyContent: 'space-around',
    flexDirection: "row",
    [mobile]: {
      flexDirection: "column",
      gap: '2rem'
    }
  };
});

export const ContainerInputsDate = styled("div")(() => ({
  '& .css-4jnixx-MuiStack-root>.MuiTextField-root': {
    minWidth: "80px",
  },
  '& .MuiFormLabel-root': {
    fontSize: "0.8rem",
    [desktop]: {
      fontSize: "0.75rem"
    }
  },
  '& .MuiInputBase-root': {
    fontSize: "0.8rem",
    overflow: "hidden",
    paddingTop: '0px',
    [desktop]: {
      fontSize: "0.75rem",
    },
  },
  '& .css-4jnixx-MuiStack-root': {
    overflow: "visible",
    paddingTop: '0px',
    width: '180px',
    [desktop]: {
      width: '130px',
    },
    [tablet]: {
      width: '200px'
    },
  },
}));


// Select's

export const ContainerGroupSelects = styled('div')(() => {
  return {
    display: "flex",
    width: '100%',
    alignItems: "center",
    justifyContent: 'space-around',
    flexDirection: "row",
    [mobile]: {
      flexDirection: "column",
      gap: '2rem'
    }
  }
})

export const CustomFormControl = styled(FormControl)(() => {
  return {
    width: "200px",
    "& .MuiFormLabel-root": {
      fontSize: "0.75rem",
    },
  }
});

export const CustomInputLabel = styled(InputLabel)(() => {
  return {
    fontSize: "0.8em",
    position: "absolute",
    top: "50%",
    left: "40%",
    transform: "translate(-50%, -50%)",
    [tablet]: {
      fontSize: "0.75rem",
    }
  };
});

export const CustomBadge = styled(Badge)(() => {
  return {
    color: "white",
    [desktop]: {
      "& .MuiBadge-badge": {
        minWidth: "15px !important",
        width: "15px !important",
        height: "15px !important",
      },
      fontSize: "0.75rem",
    },
  };
});

export const CustomSelect = styled(Select)(() => {
  return {
      width: "200px",
      height: 32,
    // [mobile]: {
    //   width: "80px",
    //   height: 20,
    // },
  };
});

export const CustomMenuItem = styled(MenuItem)(() => {
  return {
    [desktop]: {
      "& .MuiTypography-root": {
        fontSize: "0.75rem",
      },
    },
  };
});

// Switch 

export const ContainerSwith = styled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-around',
  }
});

export const ContainerGroupSwitch = styled('div')(() => {
  return {
    width: '200px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [mobile]: {
      width: '150px',
      justifyContent: 'space-around',
    }
  }
});

export const ContainerSwitch = styled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    width: 'max-content',
    textAlign: 'center',
    height:'30px',
    marginRight:'5px',
    [mobile]: {
      height:'35px',
    },
  }
})

export const LabelSwitch = styled('label')(() => {
  return {
    fontSize: '12px',
    marginBottom:'-5px',
    marginTop:'-3px',
    [mobile]: {
      marginTop:'0',
      marginBottom:'-5px',
    },
  }
})

export const CustomSwitch = styled(Switch)(() => {
  const { colors } = useColors();
  return {
    '& .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track':{
      backgroundColor:colors.buttonActionColor || '#64b5f6'
    },
    '& .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked':{
      color:colors.buttonActionColor || '#2196f3'
    }
  }
})

export const ContainerBtnClear = styled('div')(() => {
  return {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '200px',
    [mobile]: {
      width: '100px',
      justifyContent: 'flex-end',
    }
  }
})

export const ContainerGroupDown = styled('div')(() => {
  return {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-around',
    [mobile]: {
      width: '200px',
      flexDirection: 'row',
      justifyContent: 'space-between',
    }
  }
})