import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useColors } from "../../../../Context/ColorsContext"

export const Container = styled('div')(() => ({
  display: 'flex',
  width: '100%',
  minWidth: '500px',
  height: '100%',
  flexDirection: 'column',
}))

export const Header = styled('div')(() => {
  const { colors } = useColors();
  return {
    display: 'flex',
    flexDirection: 'column',
    background: colors.white,
    color: colors.detailTextColor,
    width: '100%',
    height: 'fit-content',
    borderRadius:'10px 10px 0 0',
    padding:'16px',
    fontWeight:600,
    fontSize: '1.2rem',
  }
})

export const TitleInfo = styled('div')(() => {
  return {
      textTransform:'uppercase',
  }
})

export const SubtitleInfo = styled('div')(() => {
  return {
    fontSize: '1rem',
    textTransform:'lowercase'
  }
})

export const Body = styled('div')(() => {
  const { colors } = useColors();
  return {
    display: 'flex',
    flexDirection:'column',
    flex:'1',
    width: '100%',
    background: colors.white,
  }
})

export const Footer = styled('div')(() => {
  const { colors } = useColors();
  return {
    display: 'flex',
    background: colors.white,
    width: '100%',
    height: 'fit-content',
    borderRadius:'0 0 10px 10px',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '8px 16px',
    margin: 'auto'
  }
})

export const ContainerInfo = styled('div')(() => {
  const { colors } = useColors();
  return {
    display: 'flex',
    flexDirection:'column',
    background: colors.gray,
    width: '100%',
    height: 'fit-content',
    padding:'16px 24px',
  }
})

export const SectionRight = styled('section')(() => ({
  display: 'flex',
}))

export const Spacer = styled('section')(() => ({
  padding: '0.5rem',
}))

export const SectionTopInfo = styled('section')(() => ({
  display:'flex',
  alignItems:'center',
  paddingBottom:'12px'
}))

export const Text = styled(Typography)((props) => ({
  color: `${props.color}`,
  fontSize: 16,
  fontWeight: 'bold',
  margin: 0,
  padding: 0,
  whiteSpace: 'pre-wrap'
}))