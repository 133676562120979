import {styled} from "@mui/material/styles";
import { useColors } from "../../Context/ColorsContext"
import { CardContent, Grid, Typography, Card } from '@mui/material';
import ListAltIcon from '@mui/icons-material/ListAlt';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { MediaQuerys } from "../../styles/GlobalStyle";

const { mobile, tablet, desktop } =  MediaQuerys;

export const Title = styled(Typography)(() => {
    const { colors } = useColors();
    return {
        color: colors.grayFont,
        fontSize: 12,
        lineHeight: 1.2,
        [desktop]: {
            fontSize: '0.75rem'
        }
    }
})

export const Content = styled(CardContent)(() => {
    const { colors } = useColors();
    return {
        padding: '10px 10px 6px 15px',
        cursor: 'pointer',
        '&:hover': {
            background: colors.gray
        }
    }
})

export const IconArea = styled('div')(() => {
    const { colors } = useColors();
    return {
        background: colors.orange,
        color: colors.white,
        width: 26,
        height: 26,
        fontSize: 16,
        fontWeight: 'bold',
        borderRadius: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [desktop]: {
            width: 15,
            height: 15,
            fontSize: '11px'
        }
    }
})

export const ContentTitle = styled(Grid)(() => ({
    padding: '4px 4px 4px 15px'
}))

export const ListAlt = styled(ListAltIcon)(() => {
    const { colors } = useColors();
    return {
        color: colors.grayFont,
        fontsize: 14,
        [desktop]: {
            fontsize: '12px'
        }
    }
})

export const Figure = styled('figure')(({title}) => ({
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    background: title,
    height:'30px',
    width:'30px',
    minWidth:'30px',
    borderRadius:'50%',
    margin: 0,
    marginRight:'10px !important',
  }))

export const GridContainer = styled(Grid)(() => {
    const { colors } = useColors();
    return {
        padding: '4px 10px 0px 10px',
        color: colors.grayFont
    }
})

export const ArrowLeft = styled(KeyboardArrowLeftIcon)(({disabled}) => {
    const { colors } = useColors();
    return {
        color: colors.white,
        background: disabled ? colors.grayFont : colors.main,
        borderRadius: 20,
        cursor: disabled ? 'default' : 'pointer'
    }
})

export const ArrowsLeft = styled(KeyboardDoubleArrowLeftIcon)(({disabled}) => {
    const { colors } = useColors();
    return {
        color: colors.white,
        background: disabled ? colors.grayFont : colors.main,
        borderRadius: 20,
        cursor: disabled ? 'default' : 'pointer'
    }
})

export const CustomCard = styled(Card)(({ index }) => {
    return {
        marginTop: index > 0 ? 2 : 0,
        [tablet]: {
            marginTop: 0,
            width: '100%',
            height: index > 0 ? 'fit-content' : 'auto',
            overflow: index !== 0 ? 'auto' : '',
        }
    }
})