import React from 'react'
import { Link } from 'react-router-dom'
import Router from '../../../../router'

import Recaptcha from 'react-google-recaptcha';

import {
    Container,
    Form,
    Title,
    SectionRow,
    SectionInput,
    Label,
    ContainerInput,
    Input,
    Span,
    SectionBtn,
    BtnSubmit,
    SpanLink
} from './styles'

import { useTranslation } from 'react-i18next'

export const PasswordResetForm = ({ 
    form, 
    setForm, 
    isLoading, 
    isRedirect, 
    handleSubmit, 
    handleChange, 
    messageResponse,
    recaptchaRef
}) => {
    const { t } = useTranslation();

    const handleRecaptchaVerify = (response) => {
        setForm({ ...form, reCaptchaToken: response })
    };

    return (
        <Container>
            <Form onSubmit={handleSubmit}>
                <img src='cenit-logo.png' width={300} alt='logo'/>
                <Title>{t('password.passwordReset')}</Title>
                {!isRedirect && (
                    <>
                        <SectionRow>
                            <SectionInput>
                                <Label>{t('password.password')}</Label>
                                <ContainerInput>
                                <Input
                                    name='password'
                                    type={'password'}
                                    value={form.password}
                                    onChange={(e) => handleChange(e, 'password')}
                                    disabled={isLoading}
                                />
                                </ContainerInput>
                            </SectionInput>
                        </SectionRow>
                        <SectionRow>
                            <SectionInput>
                                <Label>{t('password.confirmPassword')}</Label>
                                <ContainerInput>
                                <Input
                                    name='confirmPassword'
                                    type={'password'}
                                    value={form.confirmPassword}
                                    onChange={(e) => handleChange(e, 'confirmPassword')}
                                    disabled={isLoading}
                                />
                                </ContainerInput>
                            </SectionInput>
                        </SectionRow>
                        {process.env.REACT_APP_CAPTCHA !== undefined && !isRedirect &&
                            <SectionRow>
                                <div style={{ margin: '0 auto' }}>
                                    <Recaptcha ref={recaptchaRef} sitekey={process.env.REACT_APP_CAPTCHA} onChange={handleRecaptchaVerify} />
                                </div>
                            </SectionRow>
                        }
                    </>
                )}
                <SectionBtn>
                    {isRedirect && 
                        <Link to={Router.appLogin} disabled={isLoading}>
                            <SpanLink>{t('password.goToLogin')}</SpanLink>
                        </Link>
                    }
                    {!isRedirect && (
                        <>
                            <BtnSubmit type='submit' disabled={isLoading || form.reCaptchaToken === null}>{t('common.send')}</BtnSubmit>
                            <Link to={Router.appLogin} disabled={isLoading}>
                                <SpanLink>{t('common.cancel')}</SpanLink>
                            </Link>
                        </>
                        )
                    }
                </SectionBtn>
                {messageResponse !== '' &&
                    <SectionRow>
                        <SectionInput>
                            <Span color={isRedirect}>{messageResponse}</Span>
                        </SectionInput>
                    </SectionRow>
                }
            </Form>
        </Container>
    )
}