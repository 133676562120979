import React, { Fragment } from 'react';
import { Divider } from '@mui/material';
import {
    Container,
    Content,
    CustomTimeLine,
    Title,
    CustomIcon,
    CustomTitle,
    IconArea,
    CustomGrid,
    CustomTimelineDot,
    ContainerLeft,
    ContainerRight,
    CustomTimeLineItem,
    InstaTimeContent,
    ContainerGrid,
} from './styles';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';

import moment from 'moment';
import { FormatDate } from '../../utils/formatDate';
import { EmptyContainer } from '../../pages/styles';
import { ColorsContext } from "../../Context/ColorsContext"
import { useTranslation } from 'react-i18next';

export const TimeLine = ({ data, dataTime = null }) => {
    const { format } = new FormatDate();
    const { t } = useTranslation();
    const { colors } = React.useContext(ColorsContext);

    const getInstanceTime = () => {
        if (dataTime !== null) {
            const tempActiveTime = moment.duration(dataTime.activeTime);
            const tempSuspendedTime = moment.duration(dataTime.suspendedTime);

            let textActiveTime = (tempActiveTime.hours() > 0 ? `${tempActiveTime.hours()} h` : '')
            textActiveTime += ' ' + (tempActiveTime.minutes() > 0 ? `${tempActiveTime.minutes()} min` : `${tempActiveTime.seconds()} seg`)

            let textSuspendedTime = (tempSuspendedTime.hours() > 0 ? `${tempSuspendedTime.hours()} h` : ' ')
            textSuspendedTime += ' ' + (tempSuspendedTime.minutes() > 0 ? `${tempSuspendedTime.minutes()} min` : `${tempSuspendedTime.seconds()} seg`)

            return (
                <InstaTimeContent>
                    <CustomTitle type="title">{t('workflow.detail.activeTime')}: {textActiveTime}</CustomTitle>
                    <CustomTitle type="title">{t('workflow.detail.suspendedTime')}: {textSuspendedTime}</CustomTitle>
                </InstaTimeContent>
            )
        }
    }

    const convertToArray = (string, tooltip) => {
        if (!string) return [];

        const stringEmail = string.split(',');
        const array = stringEmail.map(e => e.trim())

        if (tooltip) {
            return array.slice(1);
        }
        return array
    }

    const dateHour = ({ date, hour }) => {
        let result;

        if (date) {
            // Use obj.createdAt if obj.updatedAt is null
            let effectiveDate = date || obj.createdAt;
            result = format({ date: effectiveDate, formatWithoutHour: true });
            return result;
        }

        // Use obj.createdAt if obj.updatedAt is null
        let effectiveHour = hour || obj.createdAt;
        result = format({ date: effectiveHour, hour: true });
        return result;
    };

    const countWords = (wordsString, tooltip) => {

        wordsString = wordsString.trim();

        if (wordsString.length > 53 && tooltip) {
            const wordsArray = wordsString.split(' ');
            let wordsToShow = Math.ceil(wordsArray.length * (53 / wordsString.length));
            if (wordsToShow === 0) wordsToShow = 1;
            let remainingWords = wordsArray.slice(wordsToShow).join(' ');
            return remainingWords;
        }

        if (wordsString.length > 53) {
            let truncatedString = wordsString.substring(0, 53);
            const lastSpaceIndex = truncatedString.lastIndexOf(' ');
            if (lastSpaceIndex !== -1) {
                truncatedString = truncatedString.substring(0, lastSpaceIndex);
            }
            return truncatedString + '...';
        }

        return wordsString;

    }

    return (
        <Container>
            <Title variant="h6">{t('workflow.detail.timeLine')}</Title>
            <Divider></Divider>

            <Content>
                <CustomTimeLine>
                    {data.length > 0 ?
                        <Fragment>
                            {data.map((obj) => (
                                <CustomTimeLineItem key={obj.id}>
                                    <ContainerLeft>
                                        <CustomTitle>
                                            {dateHour({ date: obj.updatedAt })}
                                        </CustomTitle>

                                        <CustomTitle>
                                            {dateHour({ hour: obj.updatedAt })}
                                            {/* {format({ date: obj.updatedAt, hour: true })} */}
                                        </CustomTitle>

                                    </ContainerLeft>

                                    <TimelineSeparator sx={{ width: '10%' }}>
                                        <CustomTimelineDot
                                            sx={{
                                                background: obj.workflowStatus?.color || colors.grayFont,
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: '32px',
                                            }}
                                        >
                                            <CustomIcon>{obj.workflowStatus?.icon}</CustomIcon>
                                        </CustomTimelineDot>

                                        <TimelineConnector />

                                    </TimelineSeparator>

                                    <ContainerRight>
                                        <CustomTitle type="title">
                                            {obj.workflowStatus.title}
                                        </CustomTitle>

                                        {/* Executed By */}
                                        <>
                                            <Grid container>
                                                <CustomGrid xs="auto">
                                                    <CustomTitle type="title">
                                                        {t('workflow.detail.ExecutedBy')}:
                                                    </CustomTitle>

                                                    <CustomTitle>
                                                        {obj.executedByEmail && obj.executedByEmail}
                                                    </CustomTitle>
                                                </CustomGrid>
                                            </Grid>
                                        </>

                                        {/* Notified To */}
                                        {
                                            obj.notifiedTo !== null ? (
                                                <>
                                                    <Tooltip title={convertToArray(obj.notifiedTo, true).join(', ')}>
                                                        <ContainerGrid container>
                                                            <CustomGrid xs="auto">
                                                                <CustomTitle type="title">
                                                                    {t('workflow.detail.NotifiedTo')}:
                                                                </CustomTitle>

                                                                <CustomTitle>
                                                                    {obj.notifiedTo && convertToArray(obj.notifiedTo)[0] || ''}
                                                                </CustomTitle>
                                                            </CustomGrid>

                                                            <div>
                                                                {
                                                                    obj.notifiedTo !== null && convertToArray(obj.notifiedTo, true).length > 0 && (
                                                                        <IconArea>{`+${convertToArray(obj.notifiedTo, true).length}`}</IconArea>
                                                                    )
                                                                }
                                                            </div>

                                                        </ContainerGrid>
                                                    </Tooltip>
                                                </>
                                            ) : (
                                                <Grid container>
                                                    <CustomGrid xs='auto'>
                                                        <CustomTitle type="title">
                                                            {t('workflow.detail.NotifiedTo')}:
                                                        </CustomTitle>

                                                        <CustomTitle>
                                                            {obj.notifiedTo && convertToArray(obj.notifiedTo)[0] || ''}
                                                        </CustomTitle>
                                                    </CustomGrid>
                                                </Grid>
                                            )
                                        }

                                        {/*  reason for rejection */}

                                        {
                                            obj.workflowDataCancellationReason !== null &&
                                            <Grid container>
                                                <CustomGrid xs="auto" fixWidth={true}>
                                                    <CustomTitle type="title">
                                                        {t('workflow.detail.reasonRejection')}:
                                                        <Tooltip title={countWords(obj.workflowDataCancellationReason.observations, true)}>
                                                            <span style={{ fontWeight: 'normal', marginLeft: '3px'}}>
                                                                {`${obj.workflowDataCancellationReason.workflowStatusCancellationReason.name}
                                                                    ${obj.workflowDataCancellationReason.observations ? ` - ${countWords(obj.workflowDataCancellationReason.observations)}` : ''}
                                                                `}
                                                            </span>
                                                        </Tooltip>
                                                    </CustomTitle>
                                                </CustomGrid>
                                            </Grid>
                                        }
                                    </ContainerRight >
                                </CustomTimeLineItem>

                            ))}
                        </Fragment>
                        :
                        <EmptyContainer>{t('common.empty.noInfo')}</EmptyContainer>
                    }
                </CustomTimeLine>
            </Content>

            {dataTime !== null && getInstanceTime()}
        </Container>
    )
}

