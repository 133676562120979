import React, { useEffect, useState } from 'react';

// Components
import { toast } from 'react-toastify';

// Custom components
import { PlotPlanModal } from '../../components/PlotPlanModal';
import { PlotPlanPage } from '../../components/PlotPlanPage';
import { StatusCell } from '../../components/atoms/statusCell';
import { ShowDialog } from '../../components/ShowDialog';

// API
import { PlotPlanApi } from '../../services/PlotPlanApi';
import Router from '../../router';
import { useLocation, useNavigate } from 'react-router-dom';

// Styles
import { Container } from '../styles';

// Utils
import { useTranslation } from 'react-i18next';
import { NumberToString } from '../../utils/numberToString';
import { CheckResponse } from '../../utils/checkResponse';
import { ExportExcel } from "../../utils/exportExcel";
import { FormatDate } from '../../utils/formatDate';

export const PlotPlan = (props) => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    const plotPlanApi = new PlotPlanApi();
    const { convert } = new NumberToString();
    const { check } = new CheckResponse()
    const { format } = new FormatDate();

    const [filteredRequest, setFilteredRequest] = React.useState([])
    const selectedItem = React.useRef({
        elementNode: '',
        workflowId: ''
    })
    const [selectAll, setSelectAll] = useState(false);
    const plotPlanDetail = React.useRef({});
    const [plants, setPlants] = useState([]);
    const [typeArea, setTypeArea] = useState([]);
    const [selectedTypeArea, setSelectedTypeArea] = useState([]);
    const [plant, setPlant] = useState({});
    const [root, setRoot] = useState({});
    const [childNodes, setChildNodes] = useState([]);
    const [statusList, setStatusList] = useState({ title: {}, workPermit: {}, detail: [] });
    const [parentNode, setParentNode] = useState({});
    const [loading, setLoading] = useState(true);
    const [loadingData, setLoadingData] = useState(false);
    const [dialog, setDialog] = useState({
        status: false,
        title: "",
        message: "",
        type: null,
    })

    const [height, setHeight] = useState("calc(100vh - 120px)");

    // Modal Plot Plan
    const [isModal, setIsModal] = useState(false);

    // Create new plot plan
    const [plotPlans, setPlotPlans] = useState([]);

    // Table
    const [tableList, setTableList] = useState([]);
    const tempTableList = React.useRef([]);

    const headers = [
        {
            id: 'code',
            label: 'plotPlans.table.id',
            center: true,
            disablePadding: true,
            sortArrow: true,
            type: 'link',
            onClick: linkComponent
        },
        {
            id: 'title',
            label: 'plotPlans.table.title',
            center: false,
            disablePadding: true,
            sortArrow: true,
            isRender: true,
        },
        {
            id: 'workflow',
            label: 'plotPlans.table.workflow',
            center: false,
            disablePadding: true,
            sortArrow: true,
            type: 'object',
            keys: ['name'],
            customSort: "workflow?.name"
        },
        {
            id: 'status',
            label: 'plotPlans.table.status',
            center: false,
            disablePadding: true,
            sortArrow: true,
            type: 'custom',
            customSort: "workflowStatus?.title"
        },
        {
            id: 'validityFrom',
            id2: 'validityTo',
            label: 'plotPlans.table.validity',
            center: true,
            disablePadding: true,
            sortArrow: true,
            type: 'validity',
            customSort: 'validityFrom',
        },
        {
            id: 'plotPlan',
            label: 'plotPlans.table.plotPlan',
            center: false,
            disablePadding: true,
            sortArrow: true,
            type: 'object-icon',
            keys: ['name'],
            customSort: "plotPlan?.name"
        },
        {
            id: 'createdAt',
            label: 'plotPlans.table.createdAt',
            center: false,
            disablePadding: true,
            sortArrow: true,
            type: 'shortDate',
        },
        {
            id: 'updatedAt',
            label: 'plotPlans.table.updatedAt',
            center: false,
            disablePadding: true,
            sortArrow: true,
            type: 'shortDate',
        },
    ];

    function linkComponent(row) {
        navigate(Router.appWorkflowDetail.replace(':workflowId', row.workflow?.id).replace(':id', row.id),
            { state: { row: row, workflow: row.workflow, from: Router.appDashboardReport } }
        )
    }

    function statusCellComponent(workflowStatus) {
        if (workflowStatus) return <StatusCell workflowStatus={workflowStatus} />
        return <div></div>
    }

    async function getHistorical(id, areaTypeId) {
        let response = await plotPlanApi.getHistorical({plotPlanId: id, areaTypeId: areaTypeId})
        let result = check(response)

        if (result.status) {
            return response.data
        }
        return []
    }

    async function getAreaTypes(){
        let response = await plotPlanApi.getAreaType();
        let result = check(response)
        if(result.status){
            setTypeArea(response.data)
        }
    }

    const filteredData = () => {
        if (selectedItem.current.elementNode !== '' && selectedItem.current.workflowId !== '') {
            const data = tableList.filter(item => item.workflow.id === selectedItem.current.workflowId && item.element === selectedItem.current.elementNode);
            setFilteredRequest(data)
        } else {
            setFilteredRequest(tableList)
        }
    };

    async function selectArea(row, index, indexChild, area) {

        let elementNode = childNodes[index]?.element
        let workflowId = childNodes[index].workflowDatas[indexChild]?.workflow?.id

        if (selectedItem.current.elementNode === elementNode && selectedItem.current.workflowId === workflowId) {
            elementNode = ''
            workflowId = ''
            setStatusList({ title: {}, workPermit: {}, detail: [] })
        } else {
            setStatusList({
                title: childNodes[index],
                workPermit: childNodes[index].workflowDatas[indexChild],
                typeAreas: selectedTypeArea,
                detail: []
            })
        }

        selectedItem.current = { elementNode, workflowId }
        filteredData()
    }

    function handleBackArea() {
        selectedItem.current = { elementNode: '', workflowId: '' }
        setFilteredRequest(tableList)
        setStatusList({ title: {}, workPermit: {}, detail: [] })
    }

    function onClickCode(row) {
        navigate(Router.appWorkflowDetail.replace(':workflowId', row.workflowId).replace(':id', row.id),
            { state: { row: row, workflow: statusList.workPermit?.workflow, from: Router.appPlotPlans } }
        )
    }

    async function getListPlotPlan(response, selectedTypeArea) {
        let result = await getHistorical(response.id, selectedTypeArea);

        result = await result?.map(obj => ({ ...obj, element: '' }))
        tempTableList.current = tempTableList.current.concat(result)
    }

    // lógica para indicar a que chilnode corresponde cada registro
    async function getListItems(response) {
        const modifyArr = await tempTableList.current.map((el) => {
            let found = false;
            el.plotPlans?.forEach((plot) => {
                if (found) return
                response.childNodes?.forEach((child, index) => {
                    if (plot?.nodePath?.includes(child.name)) {
                        el.element = index + 1
                        found = true
                    }
                })
            })
            return el
        })

        tempTableList.current = modifyArr
    }

    async function storeData(response, selectedTypeArea) {

        setLoadingData(true)
        tempTableList.current = []
        setParentNode(response);


        await getListPlotPlan(response, selectedTypeArea)
        await getListItems(response);

        setChildNodes(response.childNodes);
        handleBackArea()

        const uniqueArr = tempTableList.current.reduce((acc, curr) => {
            const existingItemIndex = acc.findIndex(item => item?.id === curr?.id);
            if (existingItemIndex === -1) {
                acc.push(curr);
            } else if (curr.element !== '' && acc[existingItemIndex].element === '') {
                acc[existingItemIndex] = curr;
            }
            return acc;
        }, []);

        setTableList(uniqueArr)
        setFilteredRequest(uniqueArr)
        setLoadingData(false)
    }

    async function getData(id) {
        let response = await getPlotPlan(id, selectedTypeArea);
        await storeData(response, selectedTypeArea);
    }

    async function getPlotPlan(id, typeAreas) {
        setLoading(true);
        let plotPlanCurrent = plotPlanDetail.current;
        if (plotPlanDetail.current?.id !== id) {
            let response = await plotPlanApi.getById(id, typeAreas);
            if (response.status === 200) {
                plotPlanCurrent = response.data;
            }
            else plotPlanCurrent = plotPlanDetail.current;
        }

        if (plotPlanCurrent?.id) {
            const el = document.getElementById('card-image');
            let width = el?.clientWidth || 581;
            let height = el?.clientHeight || 500;

            if (plotPlanCurrent.childNodes?.length > 0) {
                await plotPlanCurrent.childNodes?.map(async (child, index) => {
                    if (plotPlanCurrent.imgSource && child.parentX && child.parentY) {

                        await addImageProcess(`${Router.apiBaseUrl}/${plotPlanCurrent.imgSource}`).then(res => {
                            child.coordX = (child.parentX / res.width) * width
                            child.coordY = (child.parentY / res.height) * height
                        })
                        .catch(_ => {
                            child.coordX = 0;
                            child.coordY = 0;
                        })
                    }
                    child.element = index + 1;
                    setLoading(false);
                    return child;
                })
            }
            else {
                setLoading(false);
            }

            return plotPlanCurrent;
        }

        setLoading(false);
        return [];
    }

    async function handleChangePlants(event, isRedirected) {
        setPlant(event);

        if (!event.id) return;

        let plotPlanCurrent = plotPlanDetail.current;
        if (plotPlanDetail.current?.id !== event.id) {
            let response = await plotPlanApi.getById(event.id, selectedTypeArea);
            if (response.status === 200) {
                plotPlanCurrent = response.data;
            }
            else plotPlanCurrent = plotPlanDetail.current;
        }
        if (!plotPlanCurrent?.id) return;

        if (isRedirected) {
            setRoot(plotPlanCurrent)
            return
        }

        if (plotPlanCurrent.childNodes?.length <= 0 && plotPlanCurrent.parentNode?.id && !plotPlanCurrent.imgSource) {
            let parent = await getPlotPlan(plotPlanCurrent.parentNode.id, selectedTypeArea);
            parent.isRoot = true;
            setRoot(parent);
            await storeData(parent, selectedTypeArea);
        }
        else if (plotPlanCurrent.parentNode?.id) {
            await getData(event.id);
        }
        else {
            setRoot(event);
            await getData(event.id, true);
        }
    }

    async function handleSelect(row) {
        setPlant(row);
        let response = await getPlotPlan(row.id, selectedTypeArea);
        // if (response.imgSource) {
        if (response?.id !== undefined) {
            await storeData(response, selectedTypeArea);
        }
    }

    function handleOpenForm() {
        let plots = JSON.stringify(plotPlans);
        navigate(Router.appCreatePlotPlans, { state: { plots: plots } });
    }

    async function addImageProcess(src) {
        return new Promise((resolve, reject) => {
            let img = new Image()
            img.onload = () => resolve(img)
            img.onerror = reject
            img.src = src
        })
    }

    async function handleStepBack(row) {
        if (row.id === root.id || row.isRoot || row.parentNode?.nodeLevel === undefined) return;
        if (row.parentNode?.id) {
            setPlant(row.parentNode)
            let response = await getPlotPlan(row.parentNode.id, selectedTypeArea);
            await storeData(response, selectedTypeArea);
        }
    }

    async function handleStepBegin(row) {
        if (row.id === root.id || row.isRoot || row.parentNode?.nodeLevel === undefined) return;

        let plotPlanCurrent = plotPlanDetail.current;
        if (plotPlanDetail.current?.id !== root.id) {
            let response = await plotPlanApi.getById(root.id);
            if (response.status === 200) {
                plotPlanCurrent = response.data;
            }
            else plotPlanCurrent = plotPlanDetail.current;
        }

        setPlant(plotPlanCurrent)
        if (plotPlanCurrent.childNodes?.length <= 0 && plotPlanCurrent.parentNode?.id) {
            let parent = await getPlotPlan(plotPlanCurrent.parentNode.id, selectedTypeArea);
            await storeData(parent, selectedTypeArea);
            return;
        }
        await getData(root.id);
    }

    const downloadData = () => {
        const data = filteredRequest.map((item) => ({
            [t('plotPlans.table.id')]: item.code,
            [t('plotPlans.table.title')]: item.title,
            [t('plotPlans.table.workflow')]: item.workflow?.name,
            [t('plotPlans.table.status')]: item.workflowStatus?.title + (item.workflowStatus?.subtitle && item.workflowStatus.subtitle.trim() !== '' ? ` - ${item.workflowStatus?.subtitle}` : ''),
            [t('plotPlans.table.validity')]: format({ date: item.validityFrom, formatWithoutHour: true }) + (item.validityFrom ? " - " : "") + format({ date: item.validityTo, formatWithoutHour: true }),
            [t('plotPlans.table.plotPlan')]: item.plotPlan?.name,
            [t('plotPlans.table.createdAt')]: format({ date: item.createdAt, format: true }),
            [t('plotPlans.table.updatedAt')]: format({ date: item.updatedAt, format: true }),
        }));
        ExportExcel({ apiData: data, fileName: "PlotPlans" });
    }

    async function handleEdit() {
        let plots = JSON.stringify(plotPlans);
        let data = JSON.stringify(parentNode);
        navigate(Router.appCreatePlotPlans, { state: { data: data, plots: plots } });
    }

    async function handleCloseDialog(value) {
        setDialog({ status: false });

        if (value) {
            await handleDelete()
        }
    }

    function handleOpenDelete() {
        setDialog({
            status: true,
            title: t('plotPlans.plotPlanForm.messages.confirmDelete.title'),
            message: t('plotPlans.plotPlanForm.messages.confirmDelete.message', { name: parentNode.name }),
            note: t('plotPlans.plotPlanForm.messages.confirmDelete.note'),
            type: "confirmation",
            typeResponse: 'error'
        })
    }

    async function handleDelete() {
        let response = await plotPlanApi.delete(parentNode.id);
        let result = check(response);

        if (result.status) {
            setDialog({
                status: true,
                title: t('plotPlans.plotPlanForm.messages.delete.title'),
                message: t('plotPlans.plotPlanForm.messages.delete.message', { name: parentNode.name }),
                type: null
            })
            await loadPlants(1)
        }
        else {
            toast.error(() => <>{result.errors}</>)
        }
    }

    async function loadPlants(id, isFirstCall = false) {
        let response = await plotPlanApi.list();
        if (response.status === 200) {
            let findPlant = response.data?.filter((el) => el.id === id);
            setPlants(response.data);
            setPlotPlans(response.data)

            if (findPlant?.length >= 0) {
                handleChangePlants(findPlant[0], isFirstCall);
                await getData(findPlant[0].id);
            }
        }
    }

    const validityTooltip = (fromDate, toDate) => {
        let validity = '';

        if (fromDate && toDate !== null) {

            validity = `${t('common.from')} ${format({ date: fromDate, formatWithoutHour: true })}
          ${t('common.to')} ${format({ date: toDate, formatWithoutHour: true })} 
          `
        }
        return validity
    }

    useEffect(() => {
        let id = location.state?.id || props?.id || 1;
        setSelectedTypeArea([]);
        if (props?.isModal) {
            setHeight("calc(100vh - 220px)")
            setIsModal(props.isModal)
        }

        async function fetchData() {
            plotPlanDetail.current = props.plotPlansChildren?.find(el => el.id === id);
            await loadPlants(id, true)
            await getAreaTypes()
        }
        fetchData()
    }, [props.isDemo])

    const handleChangeTypeAreas = (event) => {
        const { target: { value } } = event;

        if (value.includes('select-all')) {
            if (selectAll) {
                setSelectedTypeArea([]);
                setSelectAll(false);
            } else {
                setSelectedTypeArea(typeArea.map(item => item.id));
                setSelectAll(true);
            }
        } else {
            setSelectedTypeArea(value);
            setSelectAll(value.length === typeArea.length);
        }
    };

    const handleClickTypeAreas = async() => {
        let response = await getPlotPlan(plant?.id, selectedTypeArea)
        if (response?.id !== undefined) {
            await storeData(response, selectedTypeArea);
        }
    }

    return (
        <>
            {isModal ?
                <PlotPlanModal
                    childNodes={childNodes}
                    handleSelect={handleSelect}
                    root={root}
                    loading={loading}
                    loadingData={loadingData}
                    parentNode={parentNode}
                    handleStepBack={handleStepBack}
                    handleStepBegin={handleStepBegin}
                />
                :
                <Container open={props.open} mb={6} isModal={isModal}>
                    <PlotPlanPage
                        height={height}
                        plant={plant}
                        plants={plants}
                        childNodes={childNodes}
                        root={root}
                        loading={loading}
                        parentNode={parentNode}
                        statusList={statusList}
                        headers={headers}
                        filteredRequest={filteredRequest}
                        loadingData={loadingData}
                        selectedItem={selectedItem}
                        handleOpenForm={handleOpenForm}
                        handleChangePlants={handleChangePlants}
                        handleSelect={handleSelect}
                        handleStepBack={handleStepBack}
                        handleStepBegin={handleStepBegin}
                        statusCellComponent={statusCellComponent}
                        downloadData={downloadData}
                        selectArea={selectArea}
                        handleBackArea={handleBackArea}
                        onClickCode={onClickCode}
                        handleEdit={handleEdit}
                        handleOpenDelete={handleOpenDelete}
                        validityTooltip={validityTooltip}
                        typeArea={typeArea}
                        selectedTypeArea={selectedTypeArea}
                        handleChangeTypeAreas={handleChangeTypeAreas}
                        handleClickTypeAreas={handleClickTypeAreas}
                        selectAll={selectAll}
                    />
                </Container>
            }

            <ShowDialog
                openDialog={dialog.status}
                dialog={dialog}
                handleCloseDialog={handleCloseDialog}
            />
        </>
    )
}