import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Company } from '../../../molecules/forms/Company';

// Custom components
import { BackdropLoading } from '../../../BackdropLoading';

// Styles
import { Container, Header, Body, Footer, SectionRight, Spacer, } from './styles'
import { FormButton } from '../../../../pages/styles';

// Utils
import { validateForm } from '../../../../utils/commonFunctions';

export const CompanyModalCard = ({ data, handleSave, handleClose }) => {

    const { t } = useTranslation();

    const [form, setForm] = useState({
        nit: '',
        dv: '',
        name: '',
        isActive: false,
    })
    const [errors, setErrors] = useState({
        nit: {error: '', field: t('company.table.nit'), required: true},
        dv: {error: '', field: t('company.table.dv'), required: true},
        name: {error: '', field: t('company.table.name'), required: true},
    })
    const [disabled, setDisabled] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        async function call() {
            setLoading(true)

            if (data?.id) {
                setForm({...form,
                    id: data.id,
                    nit: data.nit,
                    dv: data.dv,
                    name: data.name,
                    isActive: data.isActive
                })
            }
            setLoading(false)
        }
        call()
    }, [])

    function handleChange(event, name, limit) {
        let value = event.target.value
        //Se verifica si existe límite de caracteres indicado por el componente
        if (limit !== undefined && typeof limit === 'number') value = value.slice(0, limit)

        setForm({...form, [name]: value})
        setErrors({...errors, [name]: {...errors[name], error: ''}})
    }

    function checkForm() {
        let validate = validateForm(form, errors)
        if (validate.hasErrors) {
            setErrors(validate.errors)
            return
        }
        handleSave(form)
    }

    const handleState = (value) => {
        let data = {...form}
        setForm({
            ...data,
            isActive: value
        })
    }

    return (
        <Container>
            <Header>{data?.id ? t('company.modal.edit') : t('company.modal.create')}</Header>
            <Body>
                <Company
                    isNew={data.id == null} 
                    form={form}
                    errors={errors}
                    disabled={disabled}
                    handleChange={handleChange}
                    handleState={handleState}
                />
            </Body>
            <Footer>
                <SectionRight>
                    <FormButton 
                        variant="outlined" 
                        type="close" 
                        onClick={() => handleClose(false)}
                    >
                        {t('common.cancel')}
                    </FormButton>
                    <Spacer/>
                    <FormButton
                        variant="contained"
                        onClick={checkForm}
                    >
                        {t('common.save')}
                    </FormButton>
                </SectionRight>
            </Footer>
            <BackdropLoading open={loading}/>
        </Container>
    )
}