import React from 'react'
import { toast } from 'react-toastify';

import { Grid, FormControlLabel, Tooltip } from '@mui/material';
import InputColor from 'react-input-color';

// Styles
import { 
    Text, 
    Section, 
    CustomSwitch, 
    CustomSpanColors, 
    GridCenter, 
    ImageContainer, 
    TextSwitch, 
    UploadButton, 
    SaveButton, 
    Container, 
    ContainerColors, 
    ContainerButtonsDown, 
    CustomCardMedia, 
    CustomSpan, 
    ContainerImages, 
    CustomTextField, 
    CustomChip, 
    ErrorText 
} from './styles'

import { FormButton } from '../../pages/styles';
import { Cancel } from '@mui/icons-material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import { useTranslation } from 'react-i18next'
import Router from '../../router';
import { ColorsContext } from "../../Context/ColorsContext"

// Permissions
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../Context/PermissionsContext';

export const Appearance = ({ data, errors, loading, handleChange, handleState, previewImage, removePreviewImage, previewIcon, removePreviewIcon, previewFavicon, removePreviewFavicon, previewLoginIcon, removePreviewLoginIcon, handleConfirmation, handleReset }) => {

    const MAX_SIZE_IMAGE = "2MB";

    const { t } = useTranslation();
    const { colors } = React.useContext(ColorsContext);
    const ability = useAbility(AbilityContext);

    const { file: fileIcon, status: statusIcon } = previewIcon
    const { file: fileImage, status: statusImage } = previewImage
    const { file: fileFavicon, status: statusFavicon } = previewFavicon
    const { file: fileLoginIcon, status: statusLoginIcon } = previewLoginIcon

    function handleFileChange(e, field, type) {
        let file = e.target.files[0];
      
        if (file) {
            const acceptAttribute = e.target.accept; // Obtiene el atributo accept del input
            const allowedExtensions = acceptAttribute.split(',').map(ext => ext.trim());

            const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB
            if (file.size <= maxSizeInBytes) {
                const fileExtension = file.name.slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2); // Obtener la extensión del archivo
                if (allowedExtensions.includes('.' + fileExtension.toLowerCase())) {
                    handleChange(e, field, type);
                } else {
                    toast.error( t('system.appearance.messages.uploadTypeError', {type: allowedExtensions.join(' o ')}))
                }
            } else {
                toast.error(`${t('system.appearance.sections.maxSizeImage')}: ${MAX_SIZE_IMAGE}`)
            }
        }
        file = null
    }

    return (

        <Container>
            {errors.general.hasError &&
                <Grid item xs={12}>
                    <ErrorText>{errors.general.message}</ErrorText>
                </Grid>
            }
            <Grid container spacing={2} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                <Grid item xs={9} md={6}>
                    <Grid container display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                        <Grid item xs={4} md={3}>
                            <Text>{t('system.appearance.titleApp')}</Text>
                        </Grid>
                        <Grid item xs={8} md={9}>
                            <CustomTextField
                                label={t('system.appearance.titleApp')}
                                value={data.title}
                                onChange={e => handleChange(e, 'title', 'text')}
                                sx={{ width: '100%' }}
                                disabled={data.isActive === false}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={3} md={6}>
                    <Grid container display={'flex'} flexDirection={'row'} justifyContent={'flex-end'} alignItems={'center'} style={{ textAlign: 'right'}}>
                        <Grid item xs={12}>
                            <FormControlLabel
                                disabled={loading}
                                control={
                                    <CustomSwitch
                                        checked={data.isActive}
                                        onChange={() => handleState(!data.isActive)}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                }
                                label={
                                    <TextSwitch enabled={data.isActive}>{data.isActive ? t('system.appearance.active') : t('system.appearance.inactive')}</TextSwitch>
                                } 
                            />

                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={2} display={'flex'} flexDirection={'row'} justifyContent={'flex-start'}>
                <Grid item xs={12}>
                    <Section>{t('system.appearance.sections.colors')}</Section>
                </Grid>
                <Grid item xs={12}>
                    <div style={{ padding: '1rem 0.5rem', borderRadius: '4px', boxShadow: `0px 0px 7px #C1C1C1` }}>
                        <Grid container spacing={2} display={'flex'} flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'} >
                            <Grid item xs={6} md={2} lg={2}>
                                <ContainerColors>
                                    <CustomSpanColors>{t('system.appearance.navbarColor')}</CustomSpanColors>
                                    <InputColor
                                        className="input-color-picker"
                                        initialValue={data.navbarColor}
                                        onChange={(e) => handleChange(e, 'navbarColor', 'hex')}
                                        placement="start"
                                        xs={{ marginTop: '10' }}
                                        style={{
                                            width: '40px',
                                            height: '2.5rem',
                                        }}
                                        disabled={data.isActive === false}
                                    />
                                </ContainerColors>
                            </Grid>
                            <Grid item xs={6} md={2} lg={2}>
                                <ContainerColors>
                                    <CustomSpanColors>{t('system.appearance.footerColor')}</CustomSpanColors>
                                    <InputColor
                                        initialValue={data.footerColor}
                                        onChange={(e) => handleChange(e, 'footerColor', 'hex')}
                                        placement="start"
                                        xs={{ marginTop: '10' }}
                                        style={{ width: '40px', height: '2.5rem', }}
                                        disabled={data.isActive === false}
                                    />
                                </ContainerColors>
                            </Grid>
                            <Grid item xs={6} md={2} lg={2}>
                                <ContainerColors>
                                    <CustomSpanColors>{t('system.appearance.sidebarSelectColor')}</CustomSpanColors>
                                    <InputColor
                                        initialValue={data.sidebarSelectColor}
                                        onChange={(e) => handleChange(e, 'sidebarSelectColor', 'hex')}
                                        placement="start"
                                        xs={{ marginTop: '10' }}
                                        style={{ width: '40px', height: '2.5rem', }}
                                        disabled={data.isActive === false}
                                    />
                                </ContainerColors>
                            </Grid>
                            <Grid item xs={6} md={2} lg={2}>
                                <ContainerColors>
                                    <CustomSpanColors>{t('system.appearance.sectionTitleColor')}</CustomSpanColors>
                                    <InputColor
                                        initialValue={data.sectionTitleColor}
                                        onChange={(e) => handleChange(e, 'sectionTitleColor', 'hex')}
                                        placement="start"
                                        xs={{ marginTop: '10' }}
                                        style={{ width: '40px', height: '2.5rem', }}
                                        disabled={data.isActive === false}
                                    />
                                </ContainerColors>
                            </Grid>
                            <Grid item xs={6} md={2} lg={2}>
                                <ContainerColors>
                                    <CustomSpanColors>{t('system.appearance.buttonActionColor')}</CustomSpanColors>
                                    <InputColor
                                        initialValue={data.buttonActionColor}
                                        onChange={(e) => handleChange(e, 'buttonActionColor', 'hex')}
                                        placement="start"
                                        xs={{ marginTop: '10' }}
                                        style={{ width: '40px', height: '2.5rem', }}
                                        disabled={data.isActive === false}
                                    />
                                </ContainerColors>
                            </Grid>
                            <Grid item xs={6} md={2} lg={2}>
                                <ContainerColors>
                                    <CustomSpanColors>{t('system.appearance.detailTextColor')}</CustomSpanColors>
                                    <InputColor
                                        initialValue={data.detailTextColor}
                                        onChange={(e) => handleChange(e, 'detailTextColor', 'hex')}
                                        placement="start"
                                        xs={{ marginTop: '10' }}
                                        style={{ width: '40px', height: '2.5rem', }}
                                        disabled={data.isActive === false}
                                    />
                                </ContainerColors>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
            <Grid container spacing={2} display={'flex'} flexDirection={'row'} justifyContent={'flex-start'}>
                <Grid item xs={12}>
                    <Section>{t('system.appearance.sections.images')}</Section>
                    <CustomSpan>{t('system.appearance.sections.maxSizeImage')}: {MAX_SIZE_IMAGE}</CustomSpan>
                </Grid>
                <Grid item xs={12}>
                    <div style={{ padding: '1rem 0.5rem', borderRadius: '4px', boxShadow: `0px 0px 7px #C1C1C1` }}>
                        <Grid container spacing={2} display={'flex'} flexDirection={'row'} justifyContent={'flex-start'} alignItems={'flex-start'}>
                            <Grid item xs={12} md={4} lg={3}>
                                <ContainerImages>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%'}}>
                                        <CustomSpanColors alignCenter>{t('system.appearance.imageLoginUrl')}</CustomSpanColors>
                                        <ImageContainer>
                                            {((data.imageLoginUrl === '' || data.imageLoginUrl === null) && statusImage === false) ?
                                                <GridCenter container direction="column">
                                                    <UploadButton component="label" startIcon={<CloudUploadIcon />} sx={{ color: colors.buttonActionColor }} disabled={!data.isActive}>
                                                        {t('system.appearance.uploadImage')}
                                                        <input type="file" hidden accept=".jpg, .jpeg" onChange={e => handleFileChange(e, 'imageLoginUrl', 'file')} />
                                                    </UploadButton>
                                                </GridCenter>
                                                :
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12}>
                                                        <CustomCardMedia
                                                            component="img"
                                                            image={
                                                                statusImage === true
                                                                    ? `${fileImage}`
                                                                    : `${Router.apiBaseUrl}/${encodeURI(data.imageLoginUrl)}`
                                                            }
                                                            sx={{ backgroundColor: colors.gray, objectFit: 'contain' }}
                                                            alt={'Login Image'}
                                                        />

                                                    </Grid>
                                                </Grid>
                                            }
                                        </ImageContainer>
                                        {(data?.imageLogin !== null || data?.imageLoginUrl !== "") &&
                                            <Tooltip title={t('files.remove')}>
                                                <CustomChip 
                                                    label={data?.imageLogin !== null ? data.imageLogin?.name : data.imageLoginUrl?.toString().split(/[\/\\]/).pop()}
                                                    variant="outlined"
                                                    size='small'
                                                    deleteIcon={<Cancel sx={{ color: colors.red }} />}
                                                    onDelete={() => removePreviewImage()}
                                                />
                                            </Tooltip>
                                        }
                                    </div>
                                </ContainerImages>
                            </Grid>

                            <Grid item xs={12} md={4} lg={3}>
                                <ContainerImages>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%'}}>
                                        <CustomSpanColors alignCenter>{t('system.appearance.iconUrl')}</CustomSpanColors>
                                        <ImageContainer>
                                            {((data.iconUrl === '' || data.iconUrl === null) && statusIcon === false) ?
                                                <GridCenter container direction="column">
                                                    <UploadButton component="label" startIcon={<CloudUploadIcon />} sx={{ color: colors.buttonActionColor }} disabled={!data.isActive}>
                                                        {t('system.appearance.uploadImage')}
                                                        <input type="file" hidden accept=".png" onChange={e => handleFileChange(e, 'iconUrl', 'file')} />
                                                    </UploadButton>
                                                </GridCenter>
                                                :
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12}>
                                                        <CustomCardMedia
                                                            component="img"
                                                            image={
                                                                statusIcon === true
                                                                    ? `${fileIcon}`
                                                                    : `${Router.apiBaseUrl}/${encodeURI(data.iconUrl)}`
                                                            }
                                                            sx={{ backgroundColor: colors.gray, objectFit: 'contain' }}
                                                            alt={'Icon navbar'}
                                                        />

                                                    </Grid>
                                                </Grid>
                                            }
                                        </ImageContainer>
                                        {(data?.imageIcon !== null || data?.iconUrl !== "") &&
                                            <Tooltip title={t('files.remove')}>
                                                <CustomChip 
                                                    label={data?.imageIcon !== null ? data.imageIcon?.name : data.iconUrl?.toString().split(/[\/\\]/).pop()}
                                                    variant="outlined"
                                                    size='small'
                                                    deleteIcon={<Cancel sx={{ color: colors.red }} />}
                                                    onDelete={() => removePreviewIcon()}
                                                />
                                            </Tooltip>
                                        }
                                    </div>
                                </ContainerImages>
                            </Grid>

                            <Grid item xs={12} md={4} lg={3}>
                                <ContainerImages>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%'}}>
                                        <CustomSpanColors alignCenter>{t('system.appearance.favicon')}</CustomSpanColors>
                                        <ImageContainer>
                                            {((data.faviconUrl === '' || data.faviconUrl === null) && statusFavicon === false) ?
                                                <GridCenter container direction="column">
                                                    <UploadButton component="label" startIcon={<CloudUploadIcon />} sx={{ color: colors.buttonActionColor }} disabled={!data.isActive}>
                                                        {t('system.appearance.uploadImage')}
                                                        <input type="file" hidden accept=".ico" onChange={e => handleFileChange(e, 'faviconUrl', 'file')} />
                                                    </UploadButton>
                                                </GridCenter>
                                                :
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12}>
                                                        <CustomCardMedia
                                                            component="img"
                                                            image={
                                                                statusFavicon === true
                                                                    ? `${fileFavicon}`
                                                                    : `${Router.apiBaseUrl}/${encodeURI(data.faviconUrl)}`
                                                            }
                                                            sx={{ backgroundColor: colors.gray, objectFit: 'contain' }}
                                                            alt={'Favicon image'}
                                                        />

                                                    </Grid>
                                                </Grid>
                                            }
                                        </ImageContainer>
                                        {(data?.faviconImage !== null || data?.faviconUrl !== "") &&
                                            <Tooltip title={t('files.remove')}>
                                                <CustomChip 
                                                    label={data?.faviconImage !== null ? data.faviconImage?.name : data.faviconUrl?.toString().split(/[\/\\]/).pop()}
                                                    variant="outlined"
                                                    size='small'
                                                    deleteIcon={<Cancel sx={{ color: colors.red }} />}
                                                    onDelete={() => removePreviewFavicon()}
                                                />
                                            </Tooltip>
                                        }
                                    </div>
                                </ContainerImages>
                            </Grid>

                            <Grid item xs={12} md={4} lg={3}>
                                <ContainerImages>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%'}}>
                                        <CustomSpanColors alignCenter>{t('system.appearance.loginIcon')}</CustomSpanColors>
                                        <ImageContainer>
                                            {((data.loginIconUrl === '' || data.loginIconUrl === null) && statusLoginIcon === false) ?
                                                <GridCenter container direction="column">
                                                    <UploadButton component="label" startIcon={<CloudUploadIcon />} sx={{ color: colors.buttonActionColor }} disabled={!data.isActive}>
                                                        {t('system.appearance.uploadImage')}
                                                        <input type="file" hidden accept=".png" onChange={e => handleFileChange(e, 'loginIconUrl', 'file')} />
                                                    </UploadButton>
                                                </GridCenter>
                                                :
                                                <Grid container alignItems="center">
                                                    <Grid item xs={12}>
                                                        <CustomCardMedia
                                                            component="img"
                                                            image={
                                                                statusLoginIcon === true
                                                                    ? `${fileLoginIcon}`
                                                                    : `${Router.apiBaseUrl}/${encodeURI(data.loginIconUrl)}`
                                                            }
                                                            sx={{ backgroundColor: colors.gray, objectFit: 'contain' }}
                                                            alt={'Login icon image'}
                                                        />

                                                    </Grid>
                                                </Grid>
                                            }
                                        </ImageContainer>
                                        {(data?.loginImageIcon !== null || data?.loginIconUrl !== "") &&
                                            <Tooltip title={t('files.remove')}>
                                                <CustomChip 
                                                    label={data?.loginImageIcon !== null ? data.loginImageIcon?.name : data.loginIconUrl?.toString().split(/[\/\\]/).pop()}
                                                    variant="outlined"
                                                    size='small'
                                                    deleteIcon={<Cancel sx={{ color: colors.red }} />}
                                                    onDelete={() => removePreviewLoginIcon()}
                                                />
                                            </Tooltip>
                                        }
                                    </div>
                                </ContainerImages>
                            </Grid>

                        </Grid>
                    </div>
                </Grid>
            </Grid>

            <ContainerButtonsDown>
                <FormButton
                    variant="outlined"
                    type="close"
                    onClick={() => handleReset()}
                    disabled={loading}
                >
                    {t('system.appearance.resetAppearance')}
                </FormButton>
				{ability.can("UpdateAppearance") &&
                    <SaveButton
                        variant="contained"
                        sx={{
                            height: 30,
                            padding: '0 20px',
                            border: 'none',
                            color: colors.white,
                            backgroundColor: colors.buttonActionColor,
                            "&:hover": {
                                backgroundColor: colors.buttonActionColor,
                                opacity: 0.9,
                                color: colors.white,
                            }
                        }}
                        onClick={() => handleConfirmation()}
                        disabled={loading}
                    >
                        {t('common.save')}
                    </SaveButton>
                }
            </ContainerButtonsDown>
        </Container>
    )
}