import { defineAbility } from '@casl/ability';

export default defineAbility((can, cannot) => {
  const permissions = JSON.parse(localStorage.getItem("permissions"))
  if (permissions?.length > 0) {
    permissions.map((el) => {
      can(el.action)
    })
  }
  else {
    can("All");
  }
});