import { Auth } from "../utils/auth";
import { HealthCheckApi } from "./HealthCheckApi";
import Router from "../router";
import axios from "./AxiosConfig";

// Permissions
import ability from '../config/defineAbility.js';

export class RoleApi {
    healthCheck = null;
    token = null;

    constructor() {
        let auth = new Auth();
        this.token = auth.getToken();
        this.healthCheck = new HealthCheckApi();
    }

    async list(roleId) {
        if (ability.can("RoleList")) {
            let auth_token = { 'Authorization': `Bearer ${this.token}`}

            return await axios.get(`${Router.apiBaseUrl}${Router.apiRole}`, { 
                headers: auth_token,
                params: {
                    roleId: roleId
                }
            })
            .then(async response => {
                return await this.healthCheck.checkApi(response)
            }).catch(async error => {
                return await this.healthCheck.checkApi(error)
            })
        }
        return {status: false};
    }

    async create(data) {
        if (ability.can("CreateRole")) {
            let auth_token = { 'Authorization': `Bearer ${this.token}`}

            return await axios.post(`${Router.apiBaseUrl}${Router.apiRole}`, data, { headers: auth_token })
            .then(async response => {
                return await this.healthCheck.checkApi(response)
            }).catch(async error => {
                return await this.healthCheck.checkApi(error)
            })
        }
        return {status: false};
    }

    async update(id, data) {
        if (ability.can("UpdateRole")) {
            let auth_token = { 'Authorization': `Bearer ${this.token}`}

            return await axios.put(`${Router.apiBaseUrl}${Router.apiRole}/${id}`, data, { headers: auth_token })
            .then(async response => {
                return await this.healthCheck.checkApi(response)
            }).catch(async error => {
                return await this.healthCheck.checkApi(error)
            })
        }
        return {status: false};
    }

    async toggleState(id, data) {
        if (ability.can("ToggleStateRole")) {
            let auth_token = { 'Authorization': `Bearer ${this.token}`}

            return await axios.patch(`${Router.apiBaseUrl}${Router.apiRole}/toggleState/${id}`, data, { headers: auth_token })
            .then(async response => {
                return await this.healthCheck.checkApi(response)
            }).catch(async error => {
                return await this.healthCheck.checkApi(error)
            })
        }
        return {status: false};
    }

    async relation(id) {
        if (ability.can("GetRelationRoles")) {
            let auth_token = { 'Authorization': `Bearer ${this.token}`}

            return await axios.get(`${Router.apiBaseUrl}${Router.apiRole}/relations/${id}`, { headers: auth_token })
            .then(async response => {
                return await this.healthCheck.checkApi(response)
            }).catch(async error => {
                return await this.healthCheck.checkApi(error)
            })
        }
        return {status: false};
    }

    async updateRelation(id, data) {
        if (ability.can("UpdateRelationRoles")) {
            let auth_token = { 'Authorization': `Bearer ${this.token}`}

            return await axios.put(`${Router.apiBaseUrl}${Router.apiRole}/updateRelations/${id}`, data, { headers: auth_token })
            .then(async response => {
                return await this.healthCheck.checkApi(response)
            }).catch(async error => {
                return await this.healthCheck.checkApi(error)
            })
        }
        return {status: false};
    }
}