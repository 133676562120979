import { Auth } from "../utils/auth";
import { HealthCheckApi } from "./HealthCheckApi";
import Router from "../router";
import axios from "./AxiosConfig";

export class InactivityTimeApi {
    healthCheck = null;
    token = null;

    constructor() {
        let auth = new Auth();
        this.token = auth.getToken();
        this.healthCheck = new HealthCheckApi();
    }

    async get(hasToken = true) {
        let auth_token = { 'Authorization': `Bearer ${this.token}`}

        return await axios.get(`${Router.apiBaseUrl}${hasToken ? Router.apiInactivityTimeRestricted : Router.apiInactivityTime}`, { headers: auth_token })
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)
        })
    }

    async update(id, data) {
        let auth_token = { 'Authorization': `Bearer ${this.token}`}

        return await axios.put(
            `${Router.apiBaseUrl}${Router.apiInactivityTime}/${id}`, 
            {...data}, 
            { headers: auth_token }
        )
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)
        })
    }
}