import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import { Link, useNavigate } from 'react-router-dom';
import Router from '../../../../router';

//Utils
import { ColorsContext, useColors } from "../../../../Context/ColorsContext"
import { FormatDate } from '../../../../utils/formatDate';

import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import LocationSearchingOutlinedIcon from '@mui/icons-material/LocationSearchingOutlined';
import LocationDisabledOutlinedIcon from '@mui/icons-material/LocationDisabledOutlined';
import { Button, Grid, Icon, Tooltip } from '@mui/material';
import { LoadingProgress } from '../../../LoadingProgress';

import {
    CustomTableCell,
    FigureHead,
    Cell,
    IconAction,
    NewIcon,
    SelectInput,
    Input,
    ContainerContent,
    ContainerInputSelect,
    TableContent
  } from './styles';
import { Delete } from '@mui/icons-material';
import { CustomIcon } from '../../../../layouts/Sidebar/styles';
import { height } from '@mui/system';
import { useTranslation } from 'react-i18next'

// Permissions
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../../../Context/PermissionsContext';

function getCheck(props) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { colors } = useColors()
  if (props === true) return <CheckCircleOutlineOutlinedIcon sx={{ color: colors.grayFont }} fontSize="medium" />
}

function getUrlPlot(props) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { colors } = useColors()
  if (props === '') return <LocationDisabledOutlinedIcon fontSize="inherit" sx={{ color: colors.white }} />
  if (props != '') return <LocationSearchingOutlinedIcon fontSize="inherit" sx={{ color: colors.white }} />
}
  
  //MUI code
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
  // This method is created for cross-browser compatibility, if you don't
  // need to support IE11, you can use Array.prototype.sort() directly
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

const headCells = [
    {
      id: 'id',
      center: false,
      disablePadding: false,
      sortArrow: true,
      label: 'workflowManagement.table.number'
    },
    {
        id: 'name',
        center: false,
        disablePadding:true,
        sortArrow: true,
        label: 'workflowManagement.table.name'
    },
    {
        id: 'integrationKey',
        center: false,
        disablePadding:true,
        sortArrow: true,
        label: 'workflowManagement.table.integrationKey'
    },
    {
      id: 'lastUpdated',
      center:false,
      disablePadding: true,
      sortArrow: true,
      label: 'workflowManagement.table.lastUpdate'
    },
    {
      id: 'actions',
      center: true,
      disablePadding: true,
      sortArrow: false,
      label: 'common.actions.title'
    }
  ];

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
		const { t } = useTranslation();
    const { colors } = React.useContext(ColorsContext);

    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <CustomTableCell
              key={headCell.id}
              align={headCell.center ? 'center' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
              sx={{
                padding: '0 16px',
                color: colors.buttonActionColor,
                "&:hover": {
                  color: colors.buttonActionColor,
                  opacity: 0.9
                },
              }}
            >
              {headCell.sortArrow == true &&
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {t(headCell.label) || headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component="span">
                      {order === 'desc' ? '' : ''}
                    </Box>
                  ) : null}
                </TableSortLabel>
              }
              {headCell.sortArrow == false &&
                <>
                  <Box component="span">
                    {t(headCell.label) || headCell.label}
                  </Box>
                </>
              }
            </CustomTableCell>
          ))}
        </TableRow>
      </TableHead >
    );
  }

  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
  };

  function EnhancedTableToolbar(props) {
		const { t } = useTranslation();
    const { colors } = React.useContext(ColorsContext);
    const ability = useAbility(AbilityContext);

    return (
      <Toolbar
        sx={{
          background: colors.gray,
          borderRadius: '5px 5px 0 0',
          minHeight: '60px !important',
          marginTop: '16px',
          // display: 'block'
        }}
      >
        {ability.can("WorkflowList") &&
          <ContainerContent>
              <ContainerInputSelect>
                <SelectInput
                  id="combo-box-demo"                   
                  onChange={(_, value) => {
                      props.handleSelectedWorkflow(value);
                  }}
                  value={props.selectedWorkflow}
                  isOptionEqualToValue={(option, value) => option.id == value.id}
                  getOptionLabel={(option) => option != null ? option.name : ''}
                  size={'small'}
                  options={props.workflows.map((obj) => {return obj})}
                  sx={{ width: '100%' }}
                  renderInput={(params) => <Input {...params} label={t('workflowManagement.selectRelation')} />}
                />
              </ContainerInputSelect>

              <Tooltip title={t('workflowManagement.addRelation')}>
                <IconAction
                    size="medium"
                    aria-label="new"
                    onClick={props.handleAddRelation}
                  >
                    <NewIcon />
                </IconAction>
              </Tooltip>
          </ContainerContent>
        }
      </Toolbar>
    );
  }

EnhancedTableToolbar.propTypes = {
};

  export default function TableWorkflowRelation({
    filteredRequest,
    rows,
    loading,
    handleDelete,
    handleAddRelation,
    handleSelectedWorkflow,
    workflows,
    selectedWorkflow
  }) {
    
    const navigate = useNavigate();
		const { t } = useTranslation();
    const { colors } = React.useContext(ColorsContext);
  
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [page, setPage] = React.useState(0);
    const [dense,] = React.useState(true);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
  
    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };
    const {format} = new FormatDate();
  
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
  
    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  
    return (
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          {loading && <LoadingProgress/>}
          <EnhancedTableToolbar
          workflows={workflows}
          handleAddRelation={handleAddRelation}
          selectedWorkflow={selectedWorkflow}
          handleSelectedWorkflow={handleSelectedWorkflow}
                  />
          {!loading && 
            <React.Fragment>
              <TableContainer>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size={dense ? 'small' : 'medium'}
                >
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  />
                  <TableContent>
                    {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                      rows.sort(getComparator(order, orderBy)).slice() */}
                    {
                      rows.map((row, index) => {
  
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.id}
                          >
                            <Cell>{row.id}</Cell>
                            <Cell component="th" scope="row">
                              {row.name}
                            </Cell>
                            <Cell>
                                {row.integrationKey}
                            </Cell>
                            <Cell>
                              {format({ date: row.updatedAt, format: true })}
                            </Cell>
                            <Cell>
                              <Grid container spacing={1}>
                                    <Grid item>   
                                    {/* boton delete  */}
                                      <IconButton
                                        size="small"
                                        sx={{
                                          borderRadius: '10%',
                                          background: colors.background,
                                          color: colors.buttonActionColor
                                        }}
                                        aria-label="delete"
                                        onClick={() => handleDelete(row, index)}
                                      >
                                        <Delete/>
                                      </IconButton>
                                    </Grid>
                              </Grid>
                            </Cell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: (dense ? 33 : 53) * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableContent>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={t('common.itemsPerPage')}
                labelDisplayedRows={
                  ({ from, to, count }) => {
                    return '' + from + '-' + to + ` ${t('common.of')} ` + count
                  }
                }
                sx={{
                  borderTop: '1px solid ' + colors.gray,
                }}
              />
            </React.Fragment>
          }
        </Paper>
        {/*
        <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Dense padding"
        />
        */}
      </Box>
    );
  }