import {styled} from "@mui/material/styles";
import { Grid, Typography, TextField } from '@mui/material';
import { useColors } from "../../Context/ColorsContext";
import { MediaQuerys } from "../../styles/GlobalStyle";

const { mobile, tablet, desktop } = MediaQuerys;

export const SectionFiles = styled('section')(({disabled}) => {
    const { colors } = useColors();
    return {
        display: 'flex',
        justifyContent: 'center',
        height: 50,
        alignItems: 'center',
        marginTop: 0,
        border: `1px dashed ${colors.grayFont}`,
        borderRadius: 8,
        padding: 10,
        color: disabled ? colors.disabled : colors.blackFont,
        cursor: disabled ? 'not-allowed' : 'pointer'
    }
})

export const Container = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0'
}))

export const CustomSpan = styled(Typography)(() => {
    const { colors } = useColors();
    return {
        color: colors.grayFont,
        fontSize: 14,
        [desktop]: {
            fontSize: '0.90rem',
        }
    }
})

export const CustomTypography = styled(Typography)(() => {
    return {
        [desktop]: {
            fontSize: '0.90rem',
        }
    }
})

export const Text = styled(Typography)(() => {
    const { colors } = useColors();
    return {
        color: colors.red,
        fontSize: 14,
        fontWeight: 'bold',
        margin: 0,
        padding: 0,
        [desktop]: {
            fontSize: '0.95rem',
        },
        [tablet]: {
            fontSize: '0.85rem',
        }
    }
})

export const CustomGrid = styled(Grid)(() => {
    return {
        border: 'none',
        marginTop: '5px',
        '> .MuiGrid-item': {
            padding: 8,
            paddingLeft: 6,
            border: '1px solid #ccc',
        }
    }
})

export const StickyHeader = styled(Grid)(() => {
    const { colors } = useColors();
    return {
        position: "sticky",
        top: 0,
        height: 'auto',
        color: colors.grayFont,
        backgroundColor: colors.gray, // Customize this background color as needed
        zIndex: 1, // Ensure the header stays above the content when scrolling
        fontWeight: 'bold',
        fontSize: '14px',
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center'
    };
});

export const ContainerInput = styled('div')(() => {
    return {
        height:'3em',
        padding:'.5em .6em',
        display:'contents',
        alignItems:'center',
        borderRadius:'5px'
    }
})

export const CustomTextField = styled(TextField)(() => {
    return {
      width: '100%',
      '.MuiInputBase-input': {
        [desktop]: {
          fontSize: '0.85rem'
        }
      }
    }
})
