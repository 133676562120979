import React from 'react';
import { FormControl } from '@mui/material';
import { Input, CustomAutocomplete } from './styles';

export const SelectInput = ({name, label, placeholder, onChange, value, options, keyValue, error, disabled=false, size, width, multiple, freeSolo, limitTags, disableClearable = true}, avoidEnterKey) => {
    const [inputValue, setInputValue] = React.useState('')

    return (
        <FormControl fullWidth>
            <CustomAutocomplete
                id="combo-box-demo"
                disabled={disabled}
                disableClearable={disableClearable}
                defaultValue={multiple ? value : value?.[keyValue]}
                value={value || null}
                onChange={(_, value) => { onChange(value) }}
                onKeyDown={(event) => {
                    if (event.key === 'Enter' && avoidEnterKey) {
                      event.defaultMuiPrevented = true;
                      event.preventDefault();
                    }
                  }}
                options={options}
                getOptionLabel={(option) => keyValue ? option?.[keyValue] || "" : option}
                sx={{ width: width || '100%' }}
                multiple={multiple || false}
                freeSolo={freeSolo || false}
                inputValue={inputValue}
                limitTags={limitTags}
                size={size || 'medium'}
                onInputChange={(_, newInputValue) => {
                    if (freeSolo) setInputValue(newInputValue.trim());
                    else setInputValue(newInputValue);
                }}
                getOptionDisabled={(option) =>
                    option.isDisabled === true
                }
                onKeyPress={(event) => {
                    if (freeSolo) {
                        if ((event.key === ' ' || event.key === 'Spacebar') && inputValue !== '') {
                            let array = [...value]
                            array.push(inputValue.trim())
                            setInputValue('')
                            onChange(array, name)
                        }
                    }
                }}
                onBlur={(_) => {
                    if (freeSolo && inputValue !== '') {
                        let array = [...value]
                        array.push(inputValue.trim())
                        setInputValue('')
                        onChange(array, name)
                    }
                }}
                renderInput={
                    (params) => <Input {...params} 
                        label={label} 
                        placeholder={placeholder}
                        error={!(!error)} 
                        helperText={error} 
                        size={size || "medium"}    
                    />
                }
                renderOption={(props, option) => {
                    return (
                        <li {...props} key={keyValue ? option.id : option}>
                            {keyValue ? option[keyValue] || "" : option}
                        </li>
                    );
                }}
            />
        </FormControl>
    )
}

