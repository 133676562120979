import React from 'react'

// Components
import { Switch } from '@mui/material';
import InputColor from 'react-input-color';

// Custom components
import { SearchButton } from '../../../../pages/styles';
import { FormButton } from '../../../../pages/styles';

// Styles
import {
    Container,
    Header,
    Body,
    Footer,
    ContainerInfo,
    SectionRight,
    Input,
    ContainerInput,
    ContainerContentUp,
    ContainerContentDown,
    Label,
    ContainerContentModal,
    ContainerInputColor,
    CustomButton,
    CustomFormControl,
    ContainerContentColor,
    SectionLeft,
    ContainerGroup,
    ContainerRol,
    ContainerBtnSearch,
    ContainerSelectInput,
} from './styles'

// Utils
import { ColorsContext } from "../../../../Context/ColorsContext"
import { useTranslation } from 'react-i18next'
import { getIcon } from '../../../../utils/iconConfig';
import Router from '../../../../router';
import { SelectInput } from '../../../SelectInput';

export const StandardWorkflowFormCard = ({
    title,
    form,
    errors,
    roles,
    executeOptions,
    isAssignRole,
    handleClose,
    handleSave,
    handleChange,
    isStandardFlow
}) => {
    const { t } = useTranslation();
    const { colors } = React.useContext(ColorsContext);

    return (
        <Container >
            {!isAssignRole && <Header>{title}</Header>}
            <Body isStandardFlow={isStandardFlow}>
                <ContainerInfo disabled={isAssignRole ? 'true' : 'false'}>
                    <ContainerContentModal isStandardFlow={isStandardFlow}>
                        <ContainerContentUp>
                            <ContainerInput width="33%">
                                <Input
                                    placeholder={t('standardStatus.table.title')}
                                    label={t('standardStatus.table.title')}
                                    value={form.title}
                                    onChange={e => handleChange(e, 'title')}
                                    sx={{ width: '100%' }}
                                    error={!(!errors?.title?.error)}
                                    helperText={errors?.title?.error}
                                    disabled={isAssignRole}
                                />
                            </ContainerInput>

                            <ContainerInput width="33%">
                                <Input
                                    placeholder={t('standardStatus.table.description')}
                                    label={t('standardStatus.table.description')}
                                    value={form.subtitle}
                                    onChange={e => handleChange(e, 'subtitle')}
                                    sx={{ width: '100%' }}
                                    disabled={isAssignRole}
                                />
                            </ContainerInput>

                            <ContainerInputColor>
                                <ContainerContentColor>
                                    <Label>
                                        {t('standardStatus.changeColor')}
                                    </Label>
                                    <InputColor
                                        initialValue={form.color}
                                        onChange={(e) => handleChange(e, 'color')}
                                        placement="start"
                                        disabled={isAssignRole}
                                    />
                                </ContainerContentColor>

                                <div>
                                    {getIcon(form)}
                                </div>
                            </ContainerInputColor>
                        </ContainerContentUp>

                        <ContainerContentDown>
                            <ContainerGroup>
                                <SectionLeft>
                                    <Input
                                        placeholder={t('standardStatus.table.icon')}
                                        label={t('standardStatus.table.icon')}
                                        value={form.icon}
                                        onChange={(e) => handleChange(e, 'icon')}
                                        disabled={isAssignRole}
                                        isMobile={true}
                                    />
                                    <Input
                                        type={'number'}
                                        placeholder={t('standardStatus.table.maxTime')}
                                        label={t('standardStatus.table.maxTime')}
                                        value={form.maxTime}
                                        onChange={(e) => handleChange(e, 'maxTime')}
                                        disabled={isAssignRole}
                                        isMobile={true}
                                    />
                                    <Input
                                        type={'number'}
                                        placeholder={t('standardStatus.table.minTime')}
                                        label={t('standardStatus.table.minTime')}
                                        value={form.minTime}
                                        onChange={(e) => handleChange(e, 'minTime')}
                                        disabled={isAssignRole}
                                        isMobile={true}
                                    />
                                </SectionLeft>

                                <SectionRight content={true}>
                                    <CustomFormControl
                                        control={
                                            <Switch checked={form.notificationEmail} onChange={(e) => handleChange(e, 'notificationEmail')} inputProps={{ 'aria-label': 'controlled' }}
                                                sx={{
                                                    '& .MuiSwitch-switchBase.Mui-checked': {
                                                        color: colors.buttonActionColor
                                                    },
                                                    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                                        backgroundColor: colors.buttonActionColor
                                                    },
                                                }} />
                                        }
                                        label={t('workflowManagement.flowState.notificacionEmail')}
                                        disabled={isAssignRole}
                                    />
                                </SectionRight>

                            </ContainerGroup>

                            {isAssignRole &&
                                <>
                                    <ContainerRol>
                                        <ContainerSelectInput>
                                            <SelectInput
                                                label={t('workflowManagement.flowState.responsibleRole')}
                                                id="combo-box-demo"
                                                disableClearable
                                                onChange={(value) => handleChange(value, 'roles')}
                                                keyValue="name"
                                                value={form.roles}
                                                options={roles}
                                                multiple={true}
                                            />
                                        </ContainerSelectInput>

                                        <ContainerSelectInput>
                                            <SelectInput
                                                label={t('workflowManagement.flowState.table.roleExecute')}
                                                onChange={(value) => handleChange(value, 'executeOption')}
                                                keyValue="name"
                                                value={form.executeOption}
                                                options={executeOptions}
                                            />
                                        </ContainerSelectInput>
                                    </ContainerRol>

                                    <ContainerBtnSearch>
                                        <SearchButton variant="contained" isStandard={true}
                                            onClick={() => handleSave(Router.appWorkflowStatus.replace(':id', form.idDetail))}
                                        >
                                            {t('common.edit')}
                                        </SearchButton>
                                    </ContainerBtnSearch>
                                </>
                            }
                        </ContainerContentDown>

                    </ContainerContentModal>
                </ContainerInfo>
            </Body>
            {!isAssignRole &&
                <Footer>
                    <SectionRight>
                        <FormButton
                            variant="outlined"
                            type='close'
                            onClick={handleClose}
                        >
                            {t('common.cancel')}
                        </FormButton>
                        <FormButton
                            variant="contained"
                            onClick={handleSave}
                        >
                            {t('common.save')}
                        </FormButton>
                    </SectionRight>
                </Footer>
            }
        </Container>
    )
}
