import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { useColors } from "../../Context/ColorsContext";
import { MediaQuerys } from "../../styles/GlobalStyle";

const { mobile, tablet, desktop, desktoplarge } = MediaQuerys;

export const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: 'flex-start',
  gap: '1rem',
  [desktop]: {
    // justifyContent: "flex-start",
    flexDirection: "row",
    // gap: "0.75rem",
    marginRight: "0px",
  },
}));

// export const Figure = styled("figure")(() => ({
//   display: "flex",
//   marginRight: "5px !important",
//   width: "100%",
//   height: "100%",
//   [desktop]: {
//     // width: "0.75rem",
//     marginRight: "5px",
//   },
//   [tablet]: {
//     // width: "0.65rem",
//     marginRight: "5px",
//   },
// }));

export const Title = styled('p')(({ isWorkflowRoute, standarflows }) => {
  const { colors } = useColors();
  return {
    // width: standarflows && '170px',
    color: colors.sectionTitleColor,
    textAlign: "center",
    fontWeight: "600",
    textTransform: "uppercase",
    fontSize: '1rem',
    // lineHeight: isWorkflowRoute ? "inherit" : standarflows ? "1.5" : "2.5",
    [desktop]: {
      width: standarflows && '100px',
      fontSize: standarflows ? "0.65rem" : '0.75rem',
      // lineHeight: isWorkflowRoute ? "2" : standarflows ? "1.5" : "3",
    },
    [mobile]: {
      fontSize: standarflows ? "0.65rem" : '0.75rem',
      // lineHeight: standarflows ? "1.5" : "2.5",
    },
  };
});

export const IconContainer = styled("div")(({ isbutton }) => {
  const { colors } = useColors();
  return {
    color: colors.sectionTitleColor,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // width: '100%',
    // height: '100%',
    fontSize: 18,
    cursor: isbutton ? "pointer" : "default",
    // height: "24px",
    [tablet]: {
      // width: "0.65rem",
    },
  };
});


export const ContainerPageTitle = styled('div')(() => {
  return {
    // width: '100%',
    // height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
})

export const ContainerGroup = styled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'row',
    gap: '5px',
    width: '100%',
    height: '100%',
  }
})
