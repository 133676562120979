import { styled } from '@mui/material/styles';
import SpeedDial from '@mui/material/SpeedDial';
import Box from '@mui/material/Box';

import { MediaQuerys } from '../../../styles/GlobalStyle';
import { useColors } from '../../../Context/ColorsContext';

const { mobile } = MediaQuerys;

export const StyledSpeedDial = styled(SpeedDial)(({ open }) => {

    const { colors } = useColors();

    return {
        right: 15,
        top: 5,
        '& .MuiButtonBase-root': {
            width: '30px',
            height: '30px',
            minHeight: '30px',
            boxShadow: 'none',
            backgroundColor: colors.background,
            color: colors.buttonActionColor,
            "&:hover": {
                backgroundColor: colors.buttonActionColor,
                opacity: 0.9,
                color: colors.background,
            },
        },
        '& .MuiSpeedDialIcon-icon': {
            '-webkit-transform': 'none !important',
            transform: 'none !important',
        },
        '& .MuiSpeedDial-actions': {
            display: open ? 'block' : 'none',
        },
        '& .MuiSpeedDialAction-staticTooltipLabel': {
            fontSize: '0.70rem !important',
            top: '10px',
            width: 'max-content',
        }
    }
});

export const CustomBox = styled(Box)(() => {
    return {
        position: 'relative',
        display: 'none',
        [mobile]: {
            display: 'block',
        }
    }
})
