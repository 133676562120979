import {styled} from "@mui/material/styles";
import { Button } from '@mui/material';
import { useColors } from "../../../Context/ColorsContext"

export const ButtonContainer = styled('div')(() => {
    return {
    }
})

export const CustomButton = styled(Button)(() => {
    const { colors } = useColors();
    return {
        padding: '5px 10px',
        backgroundColor: colors.background,
        color: colors.buttonActionColor,
        "&:hover": {
            backgroundColor: colors.buttonActionColor,
            opacity: 0.9,
            color: colors.gray,
            '.MuiSvgIcon-root': {
                color: colors.gray,
            },
        },
        ":disabled": {
            backgroundColor: colors.background,
            color: 'rgba(0, 0, 0, 0.2)',
            '.MuiSvgIcon-root': {
                color: 'rgba(0, 0, 0, 0.2)',
            },
        }
    }
})