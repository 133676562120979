import { useColors } from "../../Context/ColorsContext";
import { styled } from "@mui/material/styles";
import { TextField, Divider, CardContent, FormControlLabel } from "@mui/material";
import { BaseAutocomplete, MediaQuerys } from "../../styles/GlobalStyle";

const { mobile, tablet, desktop } = MediaQuerys;

export const SectionPageTitle = styled("section")(() => ({
  display: "flex",
  width: "100%",
}));

export const Spacer = styled("div")(() => ({
  display: "flex",
  flex: 1,
}));

export const SelectInput = styled(BaseAutocomplete)(({ width }) => {
  const { colors } = useColors();
  return {
    width: width || "100%",
    background: colors.white,
    borderRadius: 6,
    fontSize: 13,
    ".MuiInputBase-input": {
      padding: 8,
    },
    [tablet]: {
      width: width || "100%",
      '.MuiFormControl-root': {
        width: "100%",
      }
    }, 
  };
});

export const Input = styled(TextField)(({ isNotification, width }) => {
  const { colors } = useColors();
  return {
    width: width || "100%",
    height: "100%",
    fontSize: "0.75rem",
    ".MuiInputBase-root": {
      background: colors.white,
      borderRadius: 6,
      fontSize: 13,
    },
    [desktop]: {
      ".MuiInputBase-root": {
        background: colors.white,
        borderRadius: 6,
        fontSize: "0.85rem",
      },
      ".MuiInputLabel-root": {
        fontSize: "0.85rem",
      },
    },
    [mobile]: {
      width: isNotification ? '100%' : '50%'
    }
  };
});

export const Content = styled(CardContent)(() => ({
  padding: 30,
  [mobile]: {
    padding: 20
  }
}))

export const Label = styled("label")(() => {
  const { colors } = useColors();
  return {
    fontSize: '1rem',
    color: colors.blackFont,
    fontWeight: "500",
    paddingRight: "5px",
    [tablet]: {
      fontSize: "0.75rem",
    },
    [desktop]: {
      fontSize: "0.85rem",
    }
  };
});

export const Form = styled("form")(() => {
  return {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  };
});


export const ContainerInputColor = styled('div')(() => {
  return {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '0.5rem',
  }
})

export const ContainerContentColor = styled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'flex-start',
    // gap: '5px',
  }
})

export const ContainerSelectInput = styled('div')(() => {
  return {
    width: '100%'
  }
})

export const CustomFormControl = styled(FormControlLabel)(() => {
  return {
    [desktop]: {
      '.MuiTypography-root': {
        fontSize: '0.85rem'
      }
    },
    [tablet]: {
      '.MuiTypography-root': {
        fontSize: '0.75rem'
      }
    }
  }
})

export const ContainerButtonsDown = styled('div')(() => {
  return {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: '10px'
  }
})

export const Subtitle = styled('span')(() => {
  const { colors } = useColors();

  return {
    color: colors.blackFont,
    display: 'block', 
    fontSize: '1.1rem'
  }
})

export const CustomDivider = styled(Divider)(() => {
  const { colors } = useColors();

  return {
    color: colors.grayFont,
    fontWeight: 'bold',
    borderBottomWidth: '0.2rem'
  }
})