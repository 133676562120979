import { FormControlLabel, Tabs, TextField, Tab, Box, MenuItem, Typography, FormControl, InputLabel, Select } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useColors } from "../../../../Context/ColorsContext"
import LoopIcon from '@mui/icons-material/Loop';
import { MediaQuerys } from "../../../../styles/GlobalStyle";

const { mobile, tablet, desktop } = MediaQuerys;


export const Label = styled('label')(({nextline, istitle}) => {
    const { colors } = useColors();
    return {
        fontWeight:'bold',
        paddingRight:'5px',
        display: nextline ? 'block' : 'inherit',
        color: istitle ? colors.blue : colors.blackFont,
        paddingLeft: istitle ? 10 : 0,
        textTransform: istitle ? 'uppercase' : 'none',
        [desktop]: {
            fontSize: '0.85rem'
        },
    }
})

export const CustomTabs = styled(Tabs)(() => {
    const { colors } = useColors();
    return {
        '&.MuiButtonBase-root-MuiTab-root .Mui-selected': {
            color: `${colors.green} !important`
        },
        '.Mui-selected': {
            color: `${colors.green} !important`
        },
        '.MuiButtonBase-root': {
            fontWeight: 'bold',
            fontSize: '1.2rem',
            fontFamily: 'Montserrat, sans-serif',
            textTransform: 'inherit'
        },
    }
});

export const FormLabel = styled(FormControlLabel)(() => ({
    '.MuiTypography-root': {
        fontWeight: 'bold',
        fontSize: '1.2rem'
    }
}));

export const IconContainer = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    width: '100%',
    height: '100%',
    // padding: 10
}));

export const IconLoop = styled(LoopIcon)(() => {
    const { colors } = useColors();
    return {
        fontSize: 50,
        color: colors.grayFont,
        [desktop]: {
            width: '2.85rem',
            height: '2.85rem',
        },
        [tablet]: {
            width: '1.85rem',
            height: '1.85rem',

        }
    }
});

export const Input = styled(TextField)(() => {
    const { colors } = useColors();
    return {
        width: '100%',
        '.MuiInputBase-root': {
            background: colors.white,
            borderRadius: 6,
            fontSize: 13,
            padding: 0
        }
    }
})

export const LineTop = styled('div')(() => {
    const { colors } = useColors();
    return {
        paddingBottom: 10,
        borderTop: `1px solid ${colors.gray}`
    }
})

export const LineBottom = styled('div')(() => {
    const { colors } = useColors();
    return {
        paddingTop: 10,
        borderBottom: `1px solid ${colors.gray}`
    }
})

export const CustomTab = styled(Tab)(() => {
    return {
        [desktop]: {
            fontSize: '0.85rem !important'
        }
    }
})

export const ContainerTop = styled('div')(() => {
    return {
        width: '100%',
        display: 'flex',
        padding: '16px',
        flexDirection: 'row',
        justifyContent: 'flex-start',
    }
})

export const ContainerGroup = styled('div')(() => {
    return {
        display: 'flex',
        width: '50%',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        gap: '0.20rem'
    }
})

export const ContainerMid = styled('div')(() => {
    return {
        width: '100%',
        padding: '0px 16px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    }
})

export const ContainerContentMid = styled('div')(({ isMiddle }) => {
    return {
        display: 'flex',
        flexDirection: 'column',
        gap: '0.75rem',
        width: isMiddle ? '20%' : '40%',
        alignItems: 'center',
        justifyContent: 'center'
    }
})

export const BoxContainer = styled(Box)(({theme}) => {
    return {
        padding: '1rem',
        maxWidth: 800,
        [theme.breakpoints.up('md')]: {
            minWidth: 700,
        },
    }
})

export const CustomTypography = styled(Typography)(() => {
    return {
          [desktop]: {
            fontSize: '0.85rem'
        }
    }
})

export const CustomFormControl = styled(FormControl)(() => {
    return {
      width: "100%",
      [desktop]: {
        // width: "250px",
        "& .MuiFormLabel-root": {
          fontSize: "0.75rem",
        },
      },
      // [tablet]: {
      //   width: "100px",
      //   height: 20,
      // },
      // [mobile]: {
      //   width: "80px",
      //   height: 20,
      // },
    };
});

export const CustomInputLabel = styled(InputLabel)(() => {
    return {
      color: 'black',
      fontSize: "1em",
      position: "absolute",
      top: "50%",
      left: "10%",
      transform: "translate(-50%, -50%)",
      [tablet]: {
        fontSize: "0.75rem",
      }
    };
});

export const CustomSelect = styled(Select)(() => {
    return {
      width: "100%",
      height: "40px",
      [desktop]: {
        // width: "250px",
        height: 30,
      },
      // [tablet]: {
      //   width: "100px",
      //   height: 20,
      // },
      // [mobile]: {
      //   width: "80px",
      //   height: 20,
      // },
    };
});

export const CustomMenuItem = styled(MenuItem)(() => {
    return {
      [desktop]: {
        "& .MuiTypography-root": {
          fontSize: "0.75rem",
        },
      },
    };
  });