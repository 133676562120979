import React from 'react'

// Components
import { Grid } from '@mui/material'

// Styles
import { ContainerContent, ContainerBox } from './styles'

// Utils
import { ContractWorkersAssign } from '../../../atoms/ContractWorkersAssign';
import { useTranslation } from 'react-i18next'

export const TechnicalSupervisor = ({
    form,
    users, 
    handleChange,
    plantList,
    handleAddRow,
    handleRemoveRow,
    loading,
    handleFilesDrop,
    deleteAttach,
    downloadFile
}) => {

    const { t } = useTranslation();

    return (
        <ContainerBox>
            <ContainerContent>
                <Grid container spacing={2} justifyContent={'flex-start'} alignItems={'flex-start'}>
                    <Grid item xs={12} md={12}>
                        <ContractWorkersAssign
                            data={form.technicalSupervisors} 
                            users={users} 
                            plantList={plantList}
                            handleChange={handleChange}
                            clickAdd={handleAddRow}
                            clickRemove={handleRemoveRow}
                            loading={loading}
                            isTechnicalSupervisor={true}
                            titleWorker={t('contract.table.technicalSupervisor')}
                            handleFilesDrop={handleFilesDrop}
                            deleteAttach={deleteAttach}
                            downloadFile={downloadFile}
                        />
                    </Grid>
                </Grid>

            </ContainerContent>
        </ContainerBox>
    )
}