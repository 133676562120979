import {Box, Grid} from "@mui/material"
import {styled} from "@mui/material/styles";
import { useColors } from "../../Context/ColorsContext"

const drawerWidth = 250; 

export const LayoutContainer = styled(Box, { shouldForwardProp: (prop) => prop !== 'isOpen' })(({isOpen}) => (() => {
    const { colors } = useColors();
    return {
        minWidth: `calc(100vw - ${isOpen ? drawerWidth : 73}px)`,
        minHeight: '100vh',
        backgroundColor: colors.background,
    }})
)

export const Container = styled(Grid, { shouldForwardProp: (prop) => prop !== 'isOpen' })(({isOpen}) => (({theme}) => ({
    [theme.breakpoints.up('sm')]: {
        height: '100%'
    },
    [theme.breakpoints.up('md')]: {
        height: '100vh'
    },
})))

export const Content = styled(Grid)({
    margin: 0,
    padding: 0
})

export const DrawerHeader = styled('div')(({theme}) => ({
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    height: 80
}));