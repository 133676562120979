import React, { createContext, useState, useEffect } from 'react';
import { AppearanceApi } from "../services/AppearanceApi";
import Router from "../router";
import { useTranslation } from 'react-i18next'

const ColorsContext = createContext();

const ColorsProvider = ({ children }) => {

  const { t } = useTranslation();

  const initialColors = {
    navbarColor: '#3DCD58',
    footerColor: '#3DCD58',
    sidebarSelectColor: '#3DCD58',
    sectionTitleColor: '#3DCD58',
    buttonActionColor: '#42B4E6',
    detailTextColor: '#53c3f5',
    main: '#42B4E6',
    mainClear: '#53c3f5',
    mainGreen: '#3DCD58',
    mainGreenClear: '#42e060',
    disabled: '#9fa4a9',
    disabledInput: '#f0f0f0',
    background: '#F5F5F5',
    grayFont: '#919191',
    grayGraphic: '#C3C3C3',
    blackFont: '#0d0c22',
    white: '#FFFFFF',
    gray: '#eaeaea',
    orange: '#ff9406',
    red: '#f44e5b',
    purple: '#99539b',
    green: '#4dd331',
    blue: '#21a2ea',
    notification: '#d2ebfa',

    imageLoginUrl: '',
    iconUrl: '',
    loginIconUrl: '',
    faviconUrl: null,
    title: null
  }

  const actionRef = React.useRef(false)
  const [colors, setColors] = useState(initialColors);
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [contentRender, setContentRender] = useState(false);
  const [flagNotification, setFlagNotification] = useState(false);

  const updateColors = (newColors) => {
    setColors({...initialColors, ...newColors});
  };

  const resetColors = () => {
    setColors(initialColors);
  };
  
  const updatePageTitle = (newTitle) => {
    let titleElement = document.getElementsByTagName('title')[0];
    if (titleElement) {
      titleElement.innerHTML = newTitle;
    } else {
      document.title = newTitle;
    }
  }

  //?? Renderizar contenido 
  const renderContent = () => {
    setTimeout(() => {
      setContentRender(true)
      setOpenBackDrop(false);
    }, 1000)
  }

  const getAppearance = React.useCallback(async() => {
    const workflowDataApi = new AppearanceApi();
    setOpenBackDrop(true);
    let response = await workflowDataApi.get(false);
    if (response?.status === 200) {
        renderContent();
        let data = response.data
        if (data?.isActive === true) {
          setColors({...colors, ...data});
          updatePageTitle((data.title !== null && data.title !== "") ? data.title : t('login.titleApp'));
        }
        else {
          updatePageTitle(t('login.titleApp'));
        }
      } else {
        updatePageTitle(t('login.titleApp'));
      }
  }, [colors, t])

  const memoizedColorsValue = React.useMemo(() => ({
    colors,
    setColors,
    updateColors,
    resetColors,
    openBackDrop,
    contentRender,
    flagNotification,
    setFlagNotification,
  }), [colors, updateColors, resetColors]);

  useEffect(() => {
    if (!actionRef.current) {
        getAppearance()
        actionRef.current = true
    } else {
      if (window.location.pathname === Router.appLogin) {
        if (!actionRef.current) {
          getAppearance(); // Llamar a getAppearance al ingresar al login
        }
      }
    }

  }, [getAppearance])
  

  return (
    <ColorsContext.Provider value={memoizedColorsValue}>
      {children}
    </ColorsContext.Provider>
  );
};

const useColors = () => {
    return React.useContext(ColorsContext);
};

export { ColorsProvider, ColorsContext, useColors };
