import Router from "../router"
import { WorkflowDataApi } from "../services/WorkflowDataApi";
import { PermissionApi } from "../services/PermissionApi";
import { UserApi } from "../services/UserApi";
import { t } from 'i18next';
import { toast } from "react-toastify";
import { CheckResponse } from "./checkResponse";
import defineAbility from '../config/defineAbility';

export async function getNotifications() {
    const workflowDataApi = new WorkflowDataApi();
    let response = await workflowDataApi.notification();
    if (response.status === 200) {
        return response.data
    }
    return []
}

async function getDetailNotification(id, isMinimal) {
    const workflowDataApi = new WorkflowDataApi();
    let response = await workflowDataApi.getDetailNotification(id, isMinimal);
    if (response.status === 200) {
        return response.data
    }
    return {}
}

export async function returnInstanceNotifications(row, isNotification, notificationsArray) {
    if (!isNotification) {
        let notifications = [];
        if (notificationsArray?.length > 0) {
            notifications = notificationsArray;
        }
        else {
            notifications = await getNotifications();
        }

        let instanceNotifications = []
        instanceNotifications = notifications.filter((el) => el.workflowDataId === row.id)
        return instanceNotifications
    }
    else {
        let detailNotification = await getDetailNotification(row.id, row.isMinimal);
        detailNotification.isDetailNotification = true;
        return [detailNotification]
    }
}

export async function redirectToAction(instance, notifications, navigate, location) {
    let redirectToDetail = false
    let notification = null
    
    if (notifications?.length === 1) {
        if (!notifications[0].isDetailNotification) {
            notification = await getDetailNotification(notifications[0].id);
        }
        else {
            notification = notifications[0];
        }
        // Pre Activar, Pausar, Reanudar, Activar
        let isChangeState = [10005, 10006, 10007, 10008].includes(notification?.workflowStatus?.statusId);
        // Si es una acción de cambio de estado o si no se encuentra formulario o si no existe notificación asociada
        redirectToDetail = isChangeState || (!notification?.formId && !notification?.externalId) || !notification?.id
    }
    if (notifications?.length === 0) {
        redirectToDetail = true
    }

    let workflowStatus = notification?.workflowStatus || '';
    let workflowData = notification?.workflowData || instance || '';

    localStorage.setItem("workflowStatus", JSON.stringify(workflowStatus))
    localStorage.setItem("workflowData", JSON.stringify(workflowData))

    let data = {
        workflowDataId: instance.id || notification?.workflowDataId,
        workflowId: instance.workflowId || notification?.workflowId,
        workflow: instance?.workflow || notification?.workflow
    }

    let route = findRoute(redirectToDetail, instance, notification, data, location)
    if (navigate) navigate(route.route, route.data)
    else return route
}

function findRoute(redirectToDetail, instance, notification, data, location) {
    if (redirectToDetail) {
        let rowData = {...instance, 
            id: data.workflowDataId, 
            workflowId: data.workflowId, 
            isNotification: true, 
            notificationId: notification?.id,
            nextStatus: notification?.nextStatus,
            rejectStatus: notification?.rejectStatus,
            createdAt: "",
            type: notification?.type,
            showActionsPop: !notification?.id ? true : false,
            previousRouteData: location
        }
        if (notification?.workflowStatus?.statusId) rowData.workflowStatus = notification.workflowStatus
        return {
            route: Router.appWorkflowDetail.replace(':workflowId', data.workflowId).replace(':id', data.workflowDataId), 
            data: {state: {row: rowData, workflow: data.workflow}}
        }
    }

    return {
        route: Router.appDynamicForm, 
        data: {state: {
            ...notification,
            id: data.workflowDataId, 
            formId: notification?.formId || instance.formId, 
            externalId: notification?.externalId || instance.externalId, 
            type: notification?.type,
            workFlowId: data.workflowId,
            notificationId: notification?.id,
            previousRouteData: location
        }}
    } 
}

export async function findNotificationTable(rows, notifications=[]) {
  if (notifications === false) notifications = []
    rows?.map((row) => {
        notifications?.map((notification) => {
            if ((row.id === notification.workflowDataId) && (row.workflowStatus?.notificationMessage === notification.message)) {
                row.notificationId = notification.id
                row.type = notification.type
                row.isApprove = notification.type === 'check_form' || notification.type === 'check_form_credentials'
                row.nextStatus = notification.nextStatus
                row.rejectStatus = notification.rejectStatus
            }
        })
    })
    return rows
}

export async function validateFiles(files = []) {
    let resultCheck = false;
    if (files.length > 0) {

        let formData = new FormData()
        formData.append('userId', 0)
        files?.map(file => { formData.append("files", file) })

        const userApi = new UserApi();
        const {check}= new CheckResponse();
        let toastInfo = toast.info(t('files.messages.saveFile.process'), {autoClose: false});
        const response = await userApi.upload(formData, true);
        let result = check(response)

        if (result.status) {
            resultCheck = true;
        }
        else {
            toast.error(() => <div>{result.errors}</div>);
        }
        toast.dismiss(toastInfo)
    }
    return resultCheck;
}

export function validateFormatFiles(files = []) {
    let validFiles = true;
    let allowedFormats = ['image/jpeg', 'image/png', 'application/pdf', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
    files.map((el) => {
        if (!allowedFormats.includes(el.type)) {
            validFiles = false;
        }
    })
    if (!validFiles) toast.error(t('files.messages.errorFile.invalidFormat'))
    return validFiles;
}

export function validateForm(form, errors) {
    let hasErrors = false;
    let errorObject = {}

    // console.log('form', form)
    // console.log('errors', errors)

    Object.entries(errors).forEach((value) => {
        if ((!form[value[0]] || Object.keys(form[value[0]])?.length === 0) && value[1].required) {
            hasErrors = true;
            errorObject = {...errorObject, [value[0]]: {...value[1], error: t('common.mandatory', {field: value[1]?.field})}}
        }
    })

    return {errors: {...errors, ...errorObject}, hasErrors: hasErrors};
}

export function generatePassword(length) {
    const lowercase = 'abcdefghijklmnopqrstuvwxyz';
    const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numbers = '0123456789';
    const specialChars = '!@#$%^&*()_+{}[]:;<>,.?-=_|~';

    let validChars = lowercase + uppercase + numbers + specialChars;
    let password = '';

    const requiredChars = [lowercase, uppercase, numbers, specialChars];

    requiredChars.forEach((chars) => {
        password += chars[Math.floor(Math.random() * chars.length)];
    });

    for (let i = password.length; i < length; i++) {
        password += validChars[Math.floor(Math.random() * validChars.length)];
    }

    return password;
}

export function returnStringContract(array, cutFirst) {
    if (!array?.length) return ''
    let newArray = []
    array.map((contract) => {
      newArray.push(contract.contract.code)
    })

    if(cutFirst){
        let arrCutFirts = newArray.slice(1)
        return arrCutFirts.join(', ')
    }

    return newArray.join(', ')
}

export function returnStringPlants(array, notString, cutFirts) {
    if (!array?.length) return ''
    let newArray = []
    array.map((contract) => {
      contract.plants?.map((plant) => {
        if (!newArray.includes(plant.name)) {
            newArray.push(plant.name)
        }
      })
    })

    if(notString){
        return [newArray.length, newArray]
    }
    // remove the first string to display tooltip
    if(cutFirts){
        let arrCutFirts = newArray.slice(1)
        return [newArray.length, arrCutFirts.join(', ')]
    }

    return [newArray.length, newArray.join(', ')]
}

export function returnStringFullnames(array, cutFirst) {
    if (!array?.length) return ''
    let newArray = []
    array.map((user) => {
      newArray.push(`${user?.name ?? ""} ${user?.lastName ?? ""}`)
    })

    if(cutFirst){
        let arrCutFirts = newArray.slice(1)
        return arrCutFirts.join(', ')
    }

    return newArray.join(', ')
}

export const lngResources = (Es, En, Pt) => {
    return {
        resources: {
            es: {
                translation: Es.translation.forms.formIo
            },
            en: {
                translation: En.translation.forms.formIo
            },
            pt: {
                translation: Pt.translation.forms.formIo
            }
        }
    };
};

export async function callPermissionsRole(id) {
    const permissionApi = new PermissionApi();
    const response = await permissionApi.list(id);
    if (response?.status === 200) {
      let resp = response.data.data
      let permissions = []
      permissions = resp?.map((el) => {return {action: el.name}})
      permissions.push({action: "All"})
      localStorage.setItem("permissions", JSON.stringify(permissions))
      defineAbility.update(permissions)
    }
}

export function returnStringCertificateNames(array, cutFirst) {
    if (!array?.length) return ''
    let newArray = []
    array.map((cert) => {
        if (cert.status) newArray.push(`${cert?.name ?? ""}`)
    })

    if(cutFirst){
        let arrCutFirts = newArray.slice(1)
        return arrCutFirts.join(', ')
    }

    return newArray.join(', ')
}