import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import Layout from "../../layouts/Layout";
import { SessionCheckForm } from "../../pages/SessionCheckForm";
import Router from "../../router";
import { Auth } from "../../utils/auth";
import { Container } from "./styles";

// Permissions
import { useAbility } from '@casl/react';
import { AbilityContext } from "../../Context/PermissionsContext";

export const PrivateRoute = ({children, permissionName}) => {
    const ability = useAbility(AbilityContext);
    const location = useLocation()
    const {isAuthenticated} = new Auth()
    const [open, setOpen] = useState(true)
	const [isDemo, setIsDemo] = React.useState(false)
	const [reloadNotifications, setReloadNotifications] = React.useState(false)
	const [reloadTable, setReloadTable] = React.useState(false)
	const [updateWorkflow, setUpdateWorkflow] = React.useState(false)

    useEffect(() => {
        setIsDemo(false);
    }, [])
    
    function getOpenState(value) {
        setOpen(value)
    }

	function handleChangeDemo(event) {
		setIsDemo(event.target.checked);
		localStorage.setItem("isDemo", event.target.checked);
	};

	function updateNotifications() {
        setReloadNotifications(!reloadNotifications)
	};

	function updateTable() {
        setReloadTable(!reloadTable)
	};

    async function reloadWorkflows(data) {
      setUpdateWorkflow(data)
    }

    function checkPermissions() {
        if (typeof permissionName !== 'string') {
            return eval(permissionName[0])
        }
        return ability.can(permissionName)
    }

    return (
        <>
            {isAuthenticated() ? 
                <Layout 
                    updateTable={updateTable} 
                    openState={getOpenState} 
                    onChangeDemo={handleChangeDemo} 
                    isDemo={isDemo}
                    reloadNotifications={reloadNotifications}
                    reloadWorkflows={reloadWorkflows}
                >
                    <Container>
                        <>
                            <SessionCheckForm />
                            {checkPermissions() ?
                                <>
                                    {React.Children.map(children, child => {
                                        return React.cloneElement(child, { 
                                            open, 
                                            isDemo,
                                            updateNotifications,
                                            reloadTable,
                                            updateWorkflow
                                        })
                                    })}
                                </>
                            :
                                <Navigate
                                    to={Router.appMyWork}
                                    state={{from: location}}
                                />
                            }
                        </>
                    </Container>
                </Layout>
            :
                <Navigate
                    to={Router.appLogin}
                    state={{from: location}}
                />
            }
        </>
    )
}
