import { Auth } from "../utils/auth";
import { HealthCheckApi } from "./HealthCheckApi";
import Router from "../router";
import axios from "./AxiosConfig";

// Permissions
import ability from '../config/defineAbility.js';

export class PermissionApi {
    healthCheck = null;
    token = null;

    constructor() {
        let auth = new Auth();
        this.token = auth.getToken();
        this.healthCheck = new HealthCheckApi();
    }

    async all() {
        let auth_token = { 'Authorization': `Bearer ${this.token}`}

        return await axios.get(`${Router.apiBaseUrl}${Router.apiPermission}`, { headers: auth_token })
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)
        })
    }

    async list(id) {
        let auth_token = { 'Authorization': `Bearer ${this.token}`}

        return await axios.get(`${Router.apiBaseUrl}${Router.apiPermission}/allByRole/${id}`, { headers: auth_token })
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)
        })
    }

    async updatePermissions(id, data) {
        if (ability.can("UpdatePermissions")) {
            let auth_token = { 'Authorization': `Bearer ${this.token}`}

            return await axios.put(`${Router.apiBaseUrl}${Router.apiPermission}/updatePermissions/${id}`, data, { headers: auth_token })
            .then(async response => {
                return await this.healthCheck.checkApi(response)
            }).catch(async error => {
                return await this.healthCheck.checkApi(error)
            })
        }
        return {status: false};
    }
}