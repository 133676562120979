import React, { Fragment } from 'react';
import { Button, Dialog, DialogActions, DialogContentText, Grid, Icon, Typography } from '@mui/material';

// Styles
import { CustomDialogContent, CustomTypography, CustomDialogContentText } from './styles';
import { ColorsContext } from "../../Context/ColorsContext"

// Utils
import { useTranslation } from 'react-i18next'
import { FormButton } from '../../pages/styles';

export const ShowDialog = (props) => {
    const {openDialog, dialog, handleCloseDialog} = props;
    const { t } = useTranslation();

    const { colors } = React.useContext(ColorsContext);

    function typeConfiguration() {
        if (dialog.typeResponse === 'error') return [colors.red, 'warning']
        return [colors.green, 'check_circle']
    }

    return (
        <Dialog
            open={openDialog}
            onClose={() => handleCloseDialog()}
            maxWidth="sm"
            fullWidth={true}
        >
            <CustomDialogContent>
                <Icon sx={{ color: typeConfiguration()[0] }}>
                    {typeConfiguration()[1]}
                </Icon>
                <CustomTypography>{dialog.title}</CustomTypography>
            </CustomDialogContent>
            <CustomDialogContent dividers={dialog.message || dialog.note}>
                {dialog.isArray ?
                    <ul>
                        {dialog.message?.map((obj, index) => (
                            <li key={"error-" + index}>
                                <CustomDialogContentText>{obj}</CustomDialogContentText>
                                {/* <DialogContentText fontSize={16}>{obj}</DialogContentText> */}
                            </li>
                        ))}
                    </ul>
                :
                    <>
                        <Grid container>
                            <Grid item xs={12}>
                                <CustomDialogContentText fontSize={16} sx={{ widht: '100%' }}>
                                    {dialog.message}
                                </CustomDialogContentText>
                            </Grid>
                            <Grid item xs={12}>
                                {dialog.note && 
                                    <CustomDialogContentText fontSize={16}>
                                        <strong>{dialog.note}</strong>
                                    </CustomDialogContentText>
                                }
                            </Grid>
                        </Grid>
                    </>
                }
            </CustomDialogContent>
            <DialogActions>
                {dialog.type === 'confirmation' ?
                    <Fragment>
                        <FormButton onClick={() => handleCloseDialog(false, true)} type='close' variant="outlined">{t('common.cancel')}</FormButton>
                        <FormButton onClick={() => handleCloseDialog(true)}>{t('common.confirm')}</FormButton>
                    </Fragment> 
                :
                    <FormButton onClick={() => handleCloseDialog(false)}>{t('common.close')}</FormButton>
                }
            </DialogActions>
        </Dialog>
    )
}
