import { DialogContent, Grid, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles"
import { useColors } from "../../Context/ColorsContext"
import { MediaQuerys } from "../../styles/GlobalStyle";
import DownloadIcon from '@mui/icons-material/Download';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const { desktop, tablet } =  MediaQuerys;

export const CustomDialogContent = styled(DialogContent)(() => ({
    display: "flex",
    alignItems: "center",
    padding: '10px 20px 5px 20px'
}));

export const FormContainer = styled(Grid)(() => {
    const { colors } = useColors();   
    return {
        background: colors.white,
        padding: 20,
        margin: 4,
        borderRadius: '4px',
        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
    }
});

export const CustomTextField = styled(TextField)(() => {
    return {
        width: '100%',
        '.MuiInputBase-input': {
            [desktop]: {
                fontSize: '0.85rem'
            }
        }
    }
})

export const CustomTypography = styled(Typography)(() => {
  return {
        [desktop]: {
            fontSize: '0.85rem'
        }
    }
})

export const PdfDownloadIcon = styled(DownloadIcon)(() => {
    const { colors } = useColors();
    return {
        fontSize: 50,
        color: colors.buttonActionColor,
        padding: '.5rem',
        backgroundColor: colors.background,
        borderRadius: "10%",
        "&:hover": {
            backgroundColor: colors.buttonActionColor,
            opacity: 0.9,
            color: colors.background,
            cursor: 'pointer'
        }
    }
})

export const CustomFormControl = styled(FormControl)(() => {
    return {
      width: "300px",
      [desktop]: {
        width: "250px",
        "& .MuiFormLabel-root": {
          fontSize: "0.75rem",
        },
      },
      // [tablet]: {
      //   width: "100px",
      //   height: 20,
      // },
      // [mobile]: {
      //   width: "80px",
      //   height: 20,
      // },
    };
  });
  
 export const CustomInputLabel = styled(InputLabel)(() => {
    return {
      color: 'black',
      fontSize: "1em",
      position: "absolute",
      top: "50%",
      left: "40%",
      transform: "translate(-50%, -50%)",
      [tablet]: {
        fontSize: "0.75rem",
      }
    };
  });

  export const CustomSelect = styled(Select)(() => {
    return {
      width: "300px",
      height: "40px",
      [desktop]: {
        width: "250px",
        height: 30,
      },
      // [tablet]: {
      //   width: "100px",
      //   height: 20,
      // },
      // [mobile]: {
      //   width: "80px",
      //   height: 20,
      // },
    };
  });

  export const CustomMenuItem = styled(MenuItem)(() => {
    return {
      [desktop]: {
        "& .MuiTypography-root": {
          fontSize: "0.75rem",
        },
      },
    };
  });