import { useContext, useEffect } from 'react';
import Inbox from '@mui/icons-material/Inbox';
import { Container, Figure, Title, IconContainer, ContainerPageTitle, ContainerGroup } from './styles';
import { useNavigate, useLocation, Link } from "react-router-dom";

import Router from '../../router';

import { ColorsContext } from '../../Context/ColorsContext';
import { Workflow } from '../../pages/Workflow';

export const PageTitle = ({ title, icon, isbutton, navigate, dinamicForm }) => {
    const { colors } = useContext(ColorsContext);
    const navigator = useNavigate();
    const location = useLocation();
    const isWorkflowRoute = /^\/workflow\/\d+$/.test(location.pathname);
    const isStandardFlows = location.pathname === '/standard-workflow-management';
    // const isStandardFlows = location.pathname === '/standard-workflow-management' || location.pathname === '/permissions';

    const { state } = location;
    const workflowData = state && state.workflowData ? state.workflowData : {};
    const { id, workflowId } = workflowData;

    return (
        <Container>
            <ContainerGroup>
                <IconContainer
                    isbutton={isbutton}
                    onClick={isbutton ? () => navigator(navigate) : null}
                >
                    {icon ? icon : <Inbox />}
                </IconContainer>
                <ContainerPageTitle>
                    <Title isWorkflowRoute={isWorkflowRoute} standarflows={isStandardFlows}>
                        {
                            dinamicForm ? (
                                <>
                                    <span>{title.name} -</span>

                                    <Link
                                        target='_blank'
                                        to={Router.appWorkflowDetail
                                            .replace(":workflowId", workflowId)
                                            .replace(":id", id)}
                                        state={{ state: location.state }}
                                    >
                                        <span style={{ color: colors.detailTextColor, marginLeft: '3px' }}>{title?.prefix}</span>
                                    </Link>
                                </>
                            ) : (
                                <>
                                    {title}
                                </>
                            )
                        }
                    </Title>
                </ContainerPageTitle>
            </ContainerGroup>
        </Container>
    )
}

