import React, { useState } from 'react'
import { useLocation } from 'react-router-dom';

// Components
import { Badge, Grid } from '@mui/material'

// Custom components
import { SelectInput } from '../../../SelectInput'
import { TabPanel } from '../../../atoms/tabPanel'
import { StatusCell } from '../../../atoms/statusCell'

// Icons

// Styles
import { 
    CustomTabs, 
    Label, 
    IconContainer, 
    IconLoop, 
    Input, 
    LineTop, 
    LineBottom, 
    CustomTab, 
    BoxContainer,

    CustomTypography,
    CustomFormControl,
    CustomInputLabel,
    CustomSelect,
    CustomMenuItem,
} from './styles'

// Utils
import { ColorsContext } from "../../../../Context/ColorsContext"
import { useTranslation } from 'react-i18next'
import { CustomTextField } from '../CharacteristicsForm/styles'
import { FormatDate } from '../../../../utils/formatDate'
import moment from 'moment'

// Permissions
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../../../Context/PermissionsContext'

export const InstanceState = ({
    data, isDelete, isDuplicate, isCreateInstance, users, form, errors, handleChange,
    plants, plant, plantDisabled, contracts, contract, isContractor, handleChangePlants, handleChangeContract, 
    tab, handleChangeTab, roles, workflow, isCredentials, 
    formCredentials, handleChangeCredentials, errorObservations, errorCancelObservations, isReqReject,
    itemsRejection
}) => {
    const { t } = useTranslation();
    const { format } = new FormatDate();
    const { colors } = React.useContext(ColorsContext);
    const ability = useAbility(AbilityContext);
    const [countAction, setCountAction] = React.useState(0)
    const [countNotify, setCountNotify] = React.useState(0)
    
    React.useEffect(() => {
        setCountNotify(form.notificationTo.length)
    }, [form.notificationTo])

    const formatCurrentState = () => {
        let newData = data?.workflowStatus
        if (isDuplicate || isCreateInstance) {
            newData = { subtitle: '', title: t('workflow.messages.started'), color: '#02cff7ff', icon: 'play_arrow' }
        }
        return <StatusCell workflowStatus={newData} />
    }

    const formatNextState = () => {
        let newData = {
            ...data?.nextStatus,
            title: data?.nextStatus?.title || data?.initialWorkflowStatus?.title
        }
        if (isDelete) {
            newData = {
                subtitle: '',
                title: t('workflow.messages.canceled'),
                color: colors.red,
                icon: 'close'
            }
        }
        if (isDuplicate || isCreateInstance) {
            newData = {
                subtitle: '',
                title: workflow?.initialWorkflowStatus?.title,
                color: workflow?.initialWorkflowStatus?.color,
                icon: workflow?.initialWorkflowStatus?.icon
            }
        }
        if (data.approveInstance === false) {
            newData = {
                subtitle: data.rejectStatus?.subtitle,
                title: data.rejectStatus?.title,
                color: data.rejectStatus?.color,
                icon: data.rejectStatus?.icon
            }
        }
        return <StatusCell workflowStatus={newData} />
    }

    const renderContractValidity = () => {
        let validUntil = data.contract?.validUntil || data.workflowData?.contract?.validUntil || contract?.validUntil || '';
        if (!validUntil) return <></>

        let validUntilDate = format({ date: validUntil, formatWithoutHour: true })
        let message = ""
        let messageStyle = {fontSize: 12, color: colors.blue}
        
        if (moment(validUntil).isAfter(new Date())) {
            message = `${t('workflow.instanceState.validity')}: ${validUntilDate}`
        }
        else {
            messageStyle.color = colors.red
            message = `${t('workflow.instanceState.validity')}: ${validUntilDate} - ${t('workflow.instanceState.notCurrent')}`
        }

        return <>
            <Grid item xs={6} sx={{ '&.MuiGrid-item': { paddingTop: '6px !important' } }}>
                <Label sx={messageStyle}>{message}</Label>
            </Grid>
            <Grid item xs={6}></Grid> 
        </>
    }

    return (
        <BoxContainer>
            <Grid container spacing={2} justifyContent={"center"} alignItems={"center"}>
                <Grid item xs={6}>
                    <Label>{t('workflow.instanceState.contract')}:</Label>
                    <SelectInput
                        name='contract'
                        onChange={handleChangeContract}
                        value={contract}
                        keyValue="code"
                        options={contracts}
                        size="small"
                        width="100%"
                        disabled={!isCreateInstance || !isContractor}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Label>{t('workflow.instanceState.plant')}:</Label>
                    <SelectInput
                        name='plant'
                        onChange={handleChangePlants}
                        value={plant || {}}
                        keyValue="name"
                        options={plants || []}
                        size="small"
                        width={"100%"}
                        disabled={plantDisabled}
                    />
                </Grid>

                {renderContractValidity()}
            </Grid>
            

            <Grid container spacing={2} justifyContent={"center"} alignItems={"flex-start"} mt={1}>
                <Grid item xs={5}>
                    <Label>{t('workflow.instanceState.currentState')}:</Label>
                    {formatCurrentState()}
                </Grid>
                <Grid item xs={2}>
                    <IconContainer sx={{ paddingTop: 2 }}>
                        <IconLoop />
                    </IconContainer>
                </Grid>
                <Grid item xs={5}>
                    <Label>{t('workflow.instanceState.nextState')}:</Label>
                    {formatNextState()}
                </Grid>
            </Grid>

            <Grid container spacing={2} justifyContent={"start"} alignItems={"start"} mt={2}>
                <Grid item xs={12}>
                    <CustomTabs
                        value={tab}
                        onChange={handleChangeTab}
                        TabIndicatorProps={{ style: { backgroundColor: colors.green, color: colors.green } }}
                    >
                        {!isDelete &&
                            <Badge badgeContent={countAction} color="primary" onClick={() => handleChangeTab(null, 0)}>
                                <CustomTab value={0} label={t('workflow.instanceState.rolesNextAction')} />
                            </Badge>
                        }
                        {ability.can("UserList") &&
                            <Badge badgeContent={countNotify} color="primary" onClick={() => handleChangeTab(null, isDelete ? 0 : 1)}>
                                <CustomTab value={1} label={t('workflow.instanceState.usersNextNotify')} />
                            </Badge>
                        }
                    </CustomTabs>
                    {!isDelete &&
                        <TabPanel value={tab} index={0}>
                            <Grid container spacing={2} justifyContent="start" alignItems="left">
                                <Grid item xs={12}>
                                    <SelectInput
                                        name='assignedTo'
                                        value={roles}
                                        keyValue="name"
                                        options={roles}
                                        size="small"
                                        width="100%"
                                        multiple={true}
                                        disabled={true}
                                    />
                                </Grid>
                            </Grid>
                        </TabPanel>
                    }
                    {ability.can("UserList") &&
                        <TabPanel value={tab} index={isDelete ? 0 : 1}>
                            <Grid container spacing={2} justifyContent={"center"} alignItems={"center"}>
                                <Grid item xs={12}>
                                    <SelectInput
                                        name='notificationTo'
                                        onChange={e => handleChange(e, 'notificationTo')}
                                        value={form.notificationTo}
                                        keyValue="email"
                                        options={users}
                                        error={errors.notificationTo.error}
                                        size="small"
                                        width="100%"
                                        multiple={true}
                                        disabled={!plant?.id}
                                    />
                                </Grid>
                            </Grid>
                        </TabPanel>
                    }
                    {isDelete &&
                        <Grid item xs={12} mt={2}>
                            <Label>{t('forms.characteristics.deletionJustification')}:</Label>
                            <CustomTextField
                                value={form.cancelObservations || ''}
                                size="small"
                                type="text"
                                onChange={e => handleChange(e.target.value, 'cancelObservations')}
                                error={!(!errorCancelObservations)}
                                helperText={errorCancelObservations}
                                multiline
                                rows={2}
                            />
                        </Grid>
                    }
                </Grid>

                {isCredentials &&
                    <>
                        <Grid item xs={12}>
                            <LineTop />
                            <Label istitle="true">{t('forms.dynamicForm.credentialsModal.checkUser')}</Label>
                            <LineBottom />
                        </Grid>
                        <Grid item xs={6}>
                            <Label>{t('forms.dynamicForm.credentialsModal.user')}:</Label>
                            <Input
                                placeholder={t('forms.dynamicForm.credentialsModal.user')}
                                value={formCredentials.username}
                                onChange={e => handleChangeCredentials(e, 'username')}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Label>{t('forms.dynamicForm.credentialsModal.password')}:</Label>
                            <Input
                                placeholder={t('forms.dynamicForm.credentialsModal.password')}
                                value={formCredentials.password}
                                type="password"
                                onChange={e => handleChangeCredentials(e, 'password')}
                            />
                        </Grid>
                    </>
                }

                {(data.showApproveForm && !data.approveInstance) &&
                    <>
                        <Grid item xs={12}>
                            <CustomTypography error={isReqReject}>{t('forms.characteristics.rejection')}:</CustomTypography>
                            <CustomFormControl required={isReqReject} error={isReqReject}>
                            {
                                isReqReject && 
                                <CustomInputLabel>
                                    {t('forms.characteristics.rejection')}
                                </CustomInputLabel>
                            }
                            <CustomSelect
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={form.reasonRejection || ''}
                                onChange={(e) => handleChange(e.target.value, 'reasonRejection')}
                                renderValue={(e) => e.name}
                            >
                                {itemsRejection?.map((item) => (
                                    <CustomMenuItem key={item.id} value={item}>
                                        {item.name}
                                    </CustomMenuItem>
                                ))}
                            </CustomSelect>
                            </CustomFormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <Label>{t('forms.characteristics.observations')}:</Label>
                            <CustomTextField
                                value={form.observations || ''}
                                size="small"
                                type="text"
                                onChange={e => handleChange(e.target.value, 'observations')}
                                error={!(!errorObservations)}
                                helperText={errorObservations}
                                multiline
                                rows={2}
                            />
                        </Grid>
                    </>
                }
            </Grid>
        </BoxContainer>
    )
}