import {styled} from "@mui/material/styles";
import { TextField, FormControl } from "@mui/material";
import { useColors } from "../../Context/ColorsContext"
import { BaseAutocomplete, MediaQuerys } from "../../styles/GlobalStyle";

const { mobile, tablet, desktop } = MediaQuerys

export const SelectInput = styled(BaseAutocomplete)(() => {
    const { colors } = useColors();
    return {
        width: '100%',
        background: colors.white,
        borderRadius: 6,
        fontSize: 12,
        '.MuiInputBase-input': {
            padding: 8
        },
        '.MuiAutocomplete-listbox': {
                scrollbarWidth: 'thin',
            '&::-webkit-scrollbar': {
                width: 5,
                // height: 5
            },
        },
    }
})

export const Input = styled(TextField)(() => {
    const { colors } = useColors();
    return {
        width: '100%',
        '.MuiInputBase-root': {
            background: colors.white,
            borderRadius: 6,
            fontSize: 13
        }
    }
})


export const CustomFormControl = styled(FormControl)(({ isPlotplan }) => {
    return {
        width: '100%',
        '.MuiAutocomplete-listbox': {
            scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
            width: 5,
            // height: 5
        },
    },
        [mobile]: {
            width: isPlotplan && '40%',
        }
    }
})