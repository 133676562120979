import {styled} from "@mui/material/styles";
import { Button, Stepper } from '@mui/material';
import { useColors } from "../../../Context/ColorsContext";

export const Wizard = styled(Stepper)(() => ({
    '.MuiStepLabel-label': {
        fontSize: 14
    },
    '.MuiSvgIcon-root': {
        fontSize: '2rem'
    },
    marginTop: 10
}))

export const CustomButton = styled(Button)(({type}) => {
    const { colors } = useColors();
    const nextButton = '#1976d2';
    const backButtonText = 'rgba(0, 0, 0, 0.5)';
    const backButtonBorder = 'rgba(0, 0, 0, 0.2)';
    const backButtonBg = '#F0F0F0';
    return {
        minWidth: 90,
        backgroundColor: type === 'back' ? backButtonBg : nextButton,
        color: type === 'back' ? backButtonText : colors.white,
        border: `1px solid ${type === 'back' ? backButtonBorder : nextButton}`,
        "&:hover": {
            backgroundColor: type === 'back' ? backButtonBg : nextButton,
            opacity: 0.8,
            color: type === 'back' ? backButtonText : colors.white
        }
    }
})