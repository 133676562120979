import React from 'react'

// Components
import { Grid } from '@mui/material'

// Styles
import { ContainerContent, ContainerBox } from './styles'

// Utils
import { ContractWorkersAssign } from '../../../atoms/ContractWorkersAssign';
import { useTranslation } from 'react-i18next'

// Permissions
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../../../Context/PermissionsContext';

export const Contractor = ({
    form,
    users, 
    handleChange,
    plantList,
    handleAddRow,
    handleRemoveRow,
    loading
}) => {

    const { t } = useTranslation();
    const ability = useAbility(AbilityContext);

    return (
        <ContainerBox>
            <ContainerContent>
                <Grid container spacing={2} justifyContent={'flex-start'} alignItems={'flex-start'}>
                    {ability.can("GetContractorExecutors") &&
                        <Grid item xs={12} md={12}>
                            <ContractWorkersAssign 
                                data={form.contractors} 
                                users={users} 
                                plantList={plantList}
                                handleChange={handleChange}
                                clickAdd={handleAddRow}
                                clickRemove={handleRemoveRow}
                                loading={loading}
                            />
                        </Grid>
                    }
                </Grid>

            </ContainerContent>
        </ContainerBox>
    )
}