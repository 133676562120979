import { styled } from "@mui/material/styles";
import { useColors } from "../../Context/ColorsContext"
import { Typography } from "@mui/material";
import { MediaQuerys } from "../../styles/GlobalStyle";

const { mobile, tablet, desktop } = MediaQuerys;

export const SectionPageTitle = styled("section")(() => ({
  display: "flex",
  width: "100%",
}));

export const FormContainer = styled('div')(() => {
  const { colors } = useColors();  
  return {
    background: colors.white,
    padding: 20,
    marginTop: '10px',
    borderRadius: '4px',
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
  }
});

export const Title = styled('h1')(() => ({
  fontWeight: 'bold',
  fontSize: 14,
  [desktop]: {
    fontSize: '1rem'
  },
  [mobile]: {
    fontSize: '0.75rem'
  }
}));

export const Text = styled('p')(() => ({
  fontSize: 14,
  [desktop]: {
    fontSize: '0.85rem'
  },
  [mobile]: {
    fontSize: '0.75rem'
  }
}));

export const ContainerContent = styled('div')(() => {
  return {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '10px',
  }
})

export const ContainerTitle = styled('div')(() => {
  return {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '33%',
  }
})

