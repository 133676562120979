import { Box, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useColors } from "../../../../Context/ColorsContext";
import { MediaQuerys } from "../../../../styles/GlobalStyle";

const { mobile, desktop } = MediaQuerys;

export const Container = styled('div')(() => ({
  display: 'flex',
  width: '100%',
  minWidth: '500px',
  height: '100%',
  flexDirection: 'column',
  [mobile]: {
    minWidth: '380px',
  }
}))

export const Header = styled('div')(() => {
  const { colors } = useColors();
  return {
    display: 'flex',
    background: colors.white,
    color: colors.detailTextColor,
    width: '100%',
    height: 'fit-content',
    borderRadius:'10px 10px 0 0',
    padding:'16px',
    textTransform:'uppercase',
    fontWeight:600,
    [desktop]: {
      fontSize: '0.85rem',
    },
  }
})

export const Body = styled('div')(() => {
  const { colors } = useColors();
  return {
    display: 'flex',
    flexDirection:'column',
    flex:'1',
    width: '100%',
    background: colors.white,
  }
})

export const Footer = styled('div')(() => {
  const { colors } = useColors();
  return {
    display: 'flex',
    background: colors.white,
    width: '100%',
    height: 'fit-content',
    borderRadius:'0 0 10px 10px',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '8px 16px',
    margin: 'auto'
  }
})

export const SectionRight = styled('section')(() => ({
  display: 'flex',
}))

export const BoxContainer = styled(Box)(({theme}) => {
    return {
        maxWidth: 800,
        maxHeight: 500,
        overflowY: 'auto',
        [theme.breakpoints.up('md')]: {
            minWidth: 700,
        },
    }
})

export const MenuNotification = styled(MenuItem)(() => {
    const { colors } = useColors();
    return {
        borderBottom: `1px solid ${colors.gray}`
    }
})