export const arrayMultipleData = (arraySelected, list) => {
    // Logic for MUI Autocomplete component bug
    // Data of autocomplete multiple must be taken directly from options array
    let setlData = []
    if (!list) return []
    arraySelected?.map((el) => {
        let objData = list?.filter((obj) => obj.id === el.id)
        setlData.push(...objData)
    })
    return setlData
}