import React, { useEffect } from 'react'

import { Button, Card, CardHeader, Grid, Divider, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';

import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import { CustomCheckbox, CustomListItemText, CustomList, ContainerCustomButton, ColumnCenter, ColumnLeft, ColumnRight, ContainerGroupButtons, ContainerContent, } from './styles'

import { ColorsContext } from "../../Context/ColorsContext"
import { useTranslation } from 'react-i18next';
import { MediaQuerys } from '../../styles/GlobalStyle';
import { FilterBarPermitsRole } from '../atoms/FilterBarPermitsRole';

// functions
function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1)
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1)
}

function union(list1, list2) {
  return [...list1, ...not(list2, list1)]
}

export const TransferList = ({ leftList, rightList, onChange, titleLeft = null, titleRight = null, listPermissions, setListPermissionsFilter, listPermissionsFilter, itemsFilter, setItemsFilter, placeholder}) => {

    const { t } = useTranslation();
    const { colors } = React.useContext(ColorsContext);
    const { mobile, tablet, desktop, desktoplarge } = MediaQuerys;

    const isInitRef = React.useRef(false)
    const listRef = React.useRef([])
    const [checked, setChecked] = React.useState([])

    const [left, setLeft] = React.useState([])
    const [right, setRight] = React.useState([])

    const [leftFilter, setLeftFilter] = React.useState([])
    const [rightFilter, setRightFilter] = React.useState([])

    const [filterInput, setFilterInput]=React.useState("")


    
  const handleFilterLeft = (arrayFiltered, arrayColum) =>{
    const filterArray = arrayFiltered.filter(permission_id => arrayColum.includes(permission_id));
    setLeftFilter(filterArray)
  }
  const handleFilterRight = (arrayFiltered, arrayColum) =>{
    const filterArray = arrayFiltered.filter(permission_id => arrayColum.includes(permission_id));
    setRightFilter(filterArray)
  }

  React.useEffect(() => {
      setLeftFilter(left)
      setRightFilter(right)
      handleFilterLeft(itemsFilter, left)
      handleFilterRight(itemsFilter, right)
  }, [itemsFilter])


  React.useEffect(() => {
    if(left.length>0){
    }
    setLeftFilter(left)
  }, [left])
  React.useEffect(() => {
    setRightFilter(right)
    onChange(union(right, checked))

  }, [right])



    const leftChecked = intersection(checked, leftFilter)
    const rightChecked = intersection(checked, right)
    


    const handleToggle = (value) => () => {
      const currentIndex = checked.indexOf(value)
      const newChecked = [...checked]
  
      if (currentIndex === -1) {
        newChecked.push(value)
      } else {
        newChecked.splice(currentIndex, 1)
      }
  
      setChecked(newChecked)
    }

    const numberOfChecked = (itemsFilter) => intersection(checked, itemsFilter).length

    const handleToggleAll = (itemsFilter) => () => {
      if (numberOfChecked(itemsFilter) === itemsFilter.length) {
        setChecked(not(checked, itemsFilter))
      } else {
        setChecked(union(checked, itemsFilter))
      }
    }

    const customList = (title, items) => (
      <>
        <Card sx={{ width: '100%'}}>

            <CardHeader  sx={{ [mobile]: { padding: 0 } }}  style={{ padding: '10px 20px',}}
                avatar={
                  <CustomCheckbox
                    onClick={handleToggleAll(items)}
                    checked={
                      numberOfChecked(items) === items.length && items.length !== 0
                    }
                    indeterminate={
                      numberOfChecked(items) !== items.length &&
                      numberOfChecked(items) !== 0
                    }
                    disabled={items.length === 0}
                    inputProps={{ 'aria-label': 'all items selected' }}
                  />
                }
                title={title}
                subheader={`${numberOfChecked(items)}/${items.length} ${t('system.permissions.selected')}`}
            />

          <Divider />
          
          <CustomList>
          {items.map((value) => {
              const labelId = `transfer-list-all-item-${value}-label`
              
              return (
                <ListItem
                  key={value}
                  role="listitem"
                  button
                  onClick={handleToggle(value)}
                  sx={{ '.MuiListItemIcon-root': { [mobile]: { minWidth: '30px !important' } }}}
                >
                  <ListItemIcon>
                    <CustomCheckbox
                      checked={checked.indexOf(value) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                      sx={{ padding: 0 }}
                    />
                  </ListItemIcon>

                  <CustomListItemText
                    id={labelId}
                    primary={
                      listRef.current.find((item) => item.key === value)?.text
                    }
                  />
                </ListItem>
              )
            })}
            <ListItem />
          </CustomList>
        </Card>
      </>
    )

    const handleCheckedRight = () => {
      setRight(right.concat(leftChecked))
      setLeft(not(left, leftChecked))
      setChecked(not(checked, leftChecked))
      onChange(union(right, checked))
      setFilterInput("")
    }
  
    const handleCheckedLeft = () => {
      setLeft(left.concat(rightChecked))
      setRight(not(right, rightChecked))
      setChecked(not(checked, rightChecked))
      onChange(not(right, rightChecked))
      setFilterInput("")
    }

    useEffect(() => {
      if (isInitRef.current === false) {
        let aux = []
        leftList.forEach((item) => {
            listRef.current[listRef.current.length] = item
            aux[aux.length] = item.key
        })
        setLeft(aux)
        aux = []
        rightList.forEach((item) => {
            listRef.current[listRef.current.length] = item
            aux[aux.length] = item.key
        })
        setRight(aux)
        isInitRef.current = true
      }
    }, [isInitRef, leftList, listRef, rightList])

    return (
      <>
          <FilterBarPermitsRole 
            listPermissions={listPermissions}
            setListPermissionsFilter={setListPermissionsFilter}
            listPermissionsFilter={listPermissionsFilter}
            setItemsFilter={setItemsFilter}
            filterInput={filterInput}
            setFilterInput={setFilterInput}
            placeholder={placeholder}
          />
        <ContainerContent>
          

            <ColumnLeft>
              {customList(titleLeft || t('system.permissions.toAssign'), leftFilter)}
            </ColumnLeft>

                <ColumnCenter>
                
                  <ContainerGroupButtons>

                      <ContainerCustomButton>
                        <Button
                          sx={{ 
                            margin: 0,
                            color: colors.buttonActionColor,
                            width: '100%',
                            height: '100%',
                            borderColor: colors.buttonActionColor,
                            "&.MuiButton-outlined.Mui-disabled": {
                              color: colors.grayGraphic,
                            },
                            "&:hover": {
                              opacity: 0.7,
                              borderColor: colors.buttonActionColor,
                            }
                          }}
                          variant="outlined"
                          size="small"
                          onClick={handleCheckedRight}
                          disabled={leftChecked.length === 0}
                          aria-label="move selected right"
                          >
                          <NavigateNextIcon />
                        </Button>
                      </ContainerCustomButton>

                      <ContainerCustomButton>
                        <Button
                          sx={{ 
                            margin: 0,
                            color: colors.buttonActionColor,
                            width: '100%',
                            height: '100%',
                            borderColor: colors.buttonActionColor,
                            "&.MuiButton-outlined.Mui-disabled": {
                              color: colors.grayGraphic,
                            },
                            "&:hover": {
                              opacity: 0.7,
                              borderColor: colors.buttonActionColor,
                            }
                          }}
                          variant="outlined"
                          size="small"
                          onClick={handleCheckedLeft}
                          disabled={rightChecked.length === 0}
                          aria-label="move selected left"
                        >
                          <NavigateBeforeIcon />
                        </Button>
                      </ContainerCustomButton>

                  </ContainerGroupButtons>
                </ColumnCenter>


              <ColumnRight>
                 {customList(titleRight || t('system.permissions.assigned'), rightFilter)}
              </ColumnRight>
        </ContainerContent>
      </>
    )
}