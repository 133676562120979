import React from 'react';
import { FormControl } from '@mui/material';
import { Input, SelectInput, CustomFormControl } from './styles';
import { MediaQuerys } from '../../styles/GlobalStyle';
const { desktop } = MediaQuerys ;

export const SelectField = ({ onChange, value, label, options, isPlotplan }) => {

    return (
        <CustomFormControl isPlotplan={isPlotplan}>
            <SelectInput
                size={ desktop ? 'small' : null }
                id="combo-box-demo"
                disableClearable
                defaultValue={value.nodePath}
                value={value.nodePath || null}
                onChange={(_, value) => {
                    let selected = options.filter((obj) => value === obj.nodePath)
                    if (selected[0].id) onChange(selected[0])
                }}
                placeholder={label}
                options={options.map((obj) => {return obj.nodePath})}
                sx={{ width: '100%' }}
                renderInput={(params) => <Input {...params} label={""}/>}
            />
        </CustomFormControl>
    )
}

