import { useState } from 'react';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';

// Icons
import SlowMotionVideoIcon from "@mui/icons-material/SlowMotionVideo";
import { ContentCopy, Delete } from "@mui/icons-material";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MenuIcon from '@mui/icons-material/Menu';

import { StyledSpeedDial, CustomBox } from './style';
import { useTranslation } from "react-i18next";

// Permissions
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../../Context/PermissionsContext';



export default function MenuSpeedDial({ options }) {

const { t } = useTranslation();
const ability = useAbility(AbilityContext);
const [open, setOpen] = useState(false);


  return (
    <CustomBox>
      <StyledSpeedDial
        ariaLabel="Actions"
        icon={<SpeedDialIcon icon={<MenuIcon/>}/>}
        direction='down'
        onClick={() => setOpen(!open)}
        open={open}
      >
        {options.map((action, index) => (
          (!('show' in action) || action.show) && (
            <SpeedDialAction
              key={`${action.name}-${index}`}
              icon={action.icon}
              tooltipTitle={action.tooltip}
              onClick={action.onClick}
              tooltipOpen
              disabled={action.disabled}
              sx={{
                display: action.disabled ? 'none' : 'block',
                '& .MuiTooltip-tooltip': {
                  fontSize: '0.70rem',
                },
              }}
            />
          )
        ))}
      </StyledSpeedDial>
  </CustomBox>
  );
}