import React from 'react'

import { Grid, TextField, FormControlLabel } from '@mui/material';

// Styles
import {
    Text,
    TextSwitch,
    CustomSwitch,
    Container,
    ContainerGroupOne,
    ContainerGroupTwo,
    CustomTextField,
} from './styles'
import { SearchButton } from '../../pages/styles';

import { useTranslation } from 'react-i18next'

// Permissions
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../Context/PermissionsContext';

export const InactivityTime = ({ data, errors, loading, handleChange, handleState, handleInactivityTime }) => {
    const { t } = useTranslation();
    const ability = useAbility(AbilityContext);

    return (
        <Container>
            <ContainerGroupTwo>
                <FormControlLabel
                    sx={{ width: '40%', height: "100%" }}
                    disabled={loading}
                    control={
                        <CustomSwitch
                            checked={data.isActive}
                            onChange={() => handleState(!data.isActive)}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    }
                    label={
                        <TextSwitch enabled={data.isActive}>{data.isActive ? t('system.inactivityTimes.active') : t('system.inactivityTimes.inactive')}</TextSwitch>
                    } />

                <CustomTextField
                    label={t('system.inactivityTimes.inMinutes')}
                    type={'number'}
                    value={data.time}
                    onChange={e => handleChange(e, 'time')}
                    onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3)
                    }}
                    sx={{ width: '100%' }}
                    disabled={loading || !data.isActive}
                    error={errors.time.hasError}
                    helperText={errors.time.message}

                />

            </ContainerGroupTwo>
            <ContainerGroupOne isDown={true}>
                <CustomTextField
                    multiline
                    fullWidth
                    rows={4}
                    label={t('system.inactivityTimes.message')}
                    value={data.timeMessage}
                    onChange={e => handleChange(e, 'timeMessage')}
                    disabled={loading || !data.isActive}
                    error={errors.timeMessage.hasError}
                    helperText={errors.timeMessage.message}

                />
            </ContainerGroupOne>
            {ability.can("UpdateInactivityTime") &&
                <ContainerGroupOne isDown={true}>
                    <SearchButton
                        isStandard={true}
                        variant="contained"
                        sx={{ height: 30, padding: '0 20px', border: 'none' }}
                        onClick={() => handleInactivityTime()}
                        disabled={loading}
                    >
                        {t('common.save')}
                    </SearchButton>
                </ContainerGroupOne>
            }
            {errors.general.hasError &&
                <Grid item xs={8}>
                    <Text>{errors.general.message}</Text>
                </Grid>
            }
        </Container>
    )
}