import React from 'react'
import { useTranslation } from 'react-i18next'

// Styles
import { ContainerCell, SectionInfo, InfoCell } from './styles'

// Utils
import { ColorsContext } from "../../../Context/ColorsContext"
import { getIcon } from '../../../utils/iconConfig';
import { snakeToCamel } from '../../../utils/commonFunctions/convert';


export const StatusCell = ({ workflowStatus }) => {
    const { colors } = React.useContext(ColorsContext);
    const { t } = useTranslation();
  
    return (
        <ContainerCell>
            {getIcon(workflowStatus)}
            <SectionInfo>
                <InfoCell>
                    <p style={{ fontSize: '0.875rem', color: colors.grayFont }}>
                        {(workflowStatus?.id >= 0 && t(`workflowManagement.flowState.status.${snakeToCamel(workflowStatus?.type)}`)) || ''}
                    </p>
                </InfoCell>
                <InfoCell>
                    {workflowStatus?.title || ''}
                </InfoCell>
            </SectionInfo>
        </ContainerCell>
    )
}
