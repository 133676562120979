import { Button, Grid, TextField, Typography } from '@mui/material';
import styled from 'styled-components';
import { useColors } from "../../../../Context/ColorsContext";
import { MediaQuerys } from '../../../../styles/GlobalStyle';

const { mobile, tablet, desktop } =  MediaQuerys;

export const Container = styled('div')(() => {
  return {
    width: '100%',
    height: '100%',
  }
})

export const TextInput = styled(TextField)(() => ({
  width: '100%'
}))

export const Image = styled('img')(() => ({
  // width: '100%',
  // height: '100%',
  width: 500,
  height: 400,
  maxHeight: 500,
  maxWidth: 500,
}))

export const ImageContainer = styled('div')(() => ({
  width: '100%',
  height: '96%',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  transition: 'opacity 0.4s ease-in',
  cursor: 'pointer',
  [mobile]: {
    height: 400,
  }
}))

export const Area = styled('div')(({top, left, zIndex}) => {
  const { colors } = useColors();
  return {
    position: 'absolute',
    color: colors.white,
    top: top,
    left: left,
    zIndex: zIndex
  }
})

export const IconArea = styled('div')(({selected}) => {
  const { colors } = useColors();
  return {
      background: selected ? colors.mainGreen : colors.orange,
      color: colors.white,
      width: 30,
      height: 30,
      fontSize: 16,
      fontWeight: 'bold',
      borderRadius: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '2px solid #73480e',
      [desktop]: {
        width: '22px',
        height: '22px',
        fontSize: 12,
      }
  }
})

export const UploadButton = styled(Button)(() => ({
    background: 'none'
}))

export const GridCenter = styled(Grid)(() => {
  const { colors } = useColors();
  return {
    textAlign: 'center',
    height: '100%',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
    background: colors.white,
    border: '1px solid #d8e0e5'
  }
})

export const ErrorText = styled('p')(() => ({
  color: '#d32f2f',
  fontSize: '0.75rem',
  marginLeft: '14px'
}))

export const InfoContainer = styled(Grid)(() => ({
  alignItems: 'center',
  display: 'flex',
  flexWrap: 'wrap'
}))

export const InfoText = styled('span')(() => ({
  fontSize: 14,
  marginLeft: 4,
  [desktop]: {
    fontSize: '0.90rem',
  }
}))

export const Form = styled('form')(() => {
  return {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.80rem',
    width: '100%',
    [mobile]: {
      flexDirection: 'column-reverse',
    },
    [tablet]: {
      gap: '2rem'
    }
  }
})

export const ContainerLeft = styled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '50%',
    [mobile]: {
      width: '100%'
    }
  }
})

export const ContainerRight = styled('div')(() => {
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '50%',
    [mobile]: {
      width: '100%'
    }
  }
})

export const ContainerInput = styled('div')(() => {
  return {
    display: 'flex',
    width: '80%',
    flexDirection: 'column',
    marginBottom: '10px',
    [tablet]: {
      width: '100%',
    }
  }
})


export const ContainerButtons = styled('div')(() => {
  return {
    display: 'flex',
    width: '80%',
    gap: '1rem',
    justifyContent: 'flex-end',
    [tablet]: {
      width: '100%',
    }
  }
})

export const CustomTypography = styled(Typography)(() => {
  return {
    [desktop]: {
      fontSize: '0.90rem !important',
    }
  }
})

export const ContainerImage = styled('div')(() => {
  return {
    width: '100%',
    height: '100%',
    [desktop]: {
      overflow: 'auto',
      "&::-webkit-scrollbar": {
        width: 5,
        height: 5
      },
    }
  }
})

