import { styled } from "@mui/material/styles";
import { FormControl, Select,  } from "@mui/material";
import { useColors } from "../../Context/ColorsContext"
import { MediaQuerys } from "../../styles/GlobalStyle";

// const { mobile } = MediaQuerys;



export const CustomFormControl = styled(FormControl)(({ }) => {
    const { colors } = useColors();

    return {
        width: '100%',
        marginTop: 1,
        '.MuiAutocomplete-listbox': {
            scrollbarWidth: 'thin',
            '&::-webkit-scrollbar': {
                width: 5,
            },
        },
    }
})

// export const CustomSelect