import { useState, useEffect, useCallback, createContext } from "react";
import { Grid, Tooltip } from "@mui/material";
import { ClearIconButton, SearchButton } from "../../../../pages/styles";
import { useLocation, useParams } from "react-router-dom";
// Multi Select
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import Zoom from "@mui/material/Zoom";
import Badge from "@mui/material/Badge";
import SearchIcon from "@mui/icons-material/Search";
import moment from "moment";

import { useTranslation } from "react-i18next";

import {
  CleanIcon,
  CustomToolbar,
  IconAction,
  InputDate,
  Label,
  NewIcon,
  Forms,
  ContainerAddIcon,
  // ClearIconButton, 
  CustomFormControl,
  ContainerSearch,
  ContainerInputsDate,
  ContainerGroup,
  ContainerGroupSelects,
  ContainerFiltros,
  ContainerTest,
  CustomSelect,
  CustomInputLabel,
  CustomMenuItem,
  CustomBadge,
  ContainerDates
} from "./styles";


export function TableToolbar(props) {
  const {
    handleDatesFilter,
    handleCreateNew,
    handleClearDate,
    hideFilterDate,
    title,
    initialDate,
    finalDate,
    setInitialDate,
    setFinalDate,
    customAdd,
    elementAdd,
    rows,
    selectedTags,

    setFilteredRequest,
    listWorkFlows,
    listPlants,

    filterStatus,
    standardStatus,
    workflowStatus,

    setWorkflowStatus,

    loadWorkflowsFilters,
    isStandardFlows,
    idListDetail,
    isFormV2,
    isForm,
    isList,
    isMywork,
    setLoading,
    setMaintainFilter,
    copyData,
    setCopyData,
  } = props;

  const ITEM_HEIGHT = 50;
  const ITEM_PADDING_TOP = 8;

  const { t } = useTranslation();
  const [selectedState, setSelectedState] = useState([]);
  const [selectedPlant, setSelectedPlant] = useState([]);

  //? counter's
  const [counterState, setCounterState] = useState(0);
  const [counterPlant, setCounterPlant] = useState(0);

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const filterAll = (filteredTemp, arrayStates, arrayPlants) => {
    let states = arrayStates?.map((obj) => obj.id)
    let plants = arrayPlants?.map((obj) => obj.id)

    if (states?.length > 0) {
      filteredTemp = filteredTemp.filter((el) => states.includes(el.workflowStatus?.id))
    }
    if (plants?.length > 0) {
      filteredTemp = filteredTemp.filter((el) => plants.includes(el.workflowData?.plant?.id))
    }

    if (initialDate && finalDate && filteredTemp?.workflowData) {
      filteredTemp = filteredTemp.filter((e) => {
        return (
          moment(e.workflowData.updatedAt).format('YYYY-MM-DD') >= initialDate &&
          moment(e.workflowData.updatedAt).format('YYYY-MM-DD') <= finalDate
        )
      });
    }

    if (typeof setFilteredRequest === 'function') setFilteredRequest(filteredTemp);
    if (typeof setMaintainFilter === 'function') setMaintainFilter(filteredTemp);
  }

  const handleChangeDates = async () => {
    filterAll(rows, selectedState, selectedPlant);
  }

  const handleChangePlants = async (event) => {
    const selectedPlant = event.target.value;
    setSelectedPlant(selectedPlant);

    setCounterPlant(selectedPlant.length || 0);
    filterAll([...rows], selectedState, selectedPlant);
  };

  const handleChangeStatus = async (event) => {
    const selectedStatus = event.target.value;
    setSelectedState(selectedStatus);

    setCounterState(selectedStatus.length || 0);
    filterAll([...rows], selectedStatus, selectedPlant);
  };

  const ClearFilters = () => {
    setSelectedState([]);
    setSelectedPlant([]);
    if (setFilteredRequest !== undefined) setFilteredRequest([]);
    if (selectedTags !== undefined) selectedTags.current = [];
    setCounterState(0);
    setCounterPlant(0);
    handleClearDate();
  };

  return (
    <CustomToolbar isStandardManagement={hideFilterDate} isList={isList} isMywork={isMywork}>
      <Forms
        isList={isList}
        idListDetail={idListDetail}
        isFormV2={isFormV2}
        isForm={isForm}
        onSubmit={e => {
          e.preventDefault()
          handleChangeDates()
        }}>

        {/* Input add item overview Edit-List */}
        {customAdd && (
          <div>
            {elementAdd}
          </div>
        )}

        {/* Traditional list detail component  */}
        {handleCreateNew && (
          <Tooltip title={title}>
            <IconAction size="medium" idListDetail={idListDetail} onClick={handleCreateNew}>
              <NewIcon />
            </IconAction>
          </Tooltip>
        )}

        {!isList && (
          <>
            <ContainerFiltros isPending={isMywork}>
              {workflowStatus !== undefined && (
                <ContainerGroupSelects>
                  {/* Filter plants */}

                  <CustomFormControl>
                    <CustomInputLabel
                      id="filter-plant"
                    >
                      {t("common.plantsFilter")}
                    </CustomInputLabel>
                    <CustomBadge
                      badgeContent={counterPlant}
                      color="primary"
                    >
                      <CustomSelect
                        labelId="filter-plant"
                        id="filter-plant-select"
                        multiple
                        value={selectedPlant}
                        onChange={handleChangePlants}
                        renderValue={() => ""}
                        MenuProps={MenuProps}
                      >
                        {listPlants
                          .slice()
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map((tag) => (
                            <CustomMenuItem
                              key={tag.id}
                              value={tag}
                              sx={{ fontSize: "0.6em" }}
                            >
                              <Checkbox
                                checked={selectedPlant.indexOf(tag) > -1}
                                sx={{ width: "30px", height: "30px" }}
                              />
                              <ListItemText primary={tag.name} />
                            </CustomMenuItem>
                          ))}
                      </CustomSelect>
                    </CustomBadge>
                  </CustomFormControl>

                  {/* Filter Status */}

                  <CustomFormControl>
                    <CustomInputLabel
                      id="demo-multiple-checkbox-label"
                    >
                      {t("common.statusFilter")}
                    </CustomInputLabel>
                    <CustomBadge badgeContent={counterState} color="primary">
                      <CustomSelect
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={selectedState}
                        renderValue={() => ""}
                        onChange={handleChangeStatus}
                        MenuProps={MenuProps}
                      >
                        {workflowStatus
                          .slice()
                          .sort((a, b) => a.title.localeCompare(b.title))
                          .map((tag) => (
                            <CustomMenuItem
                              key={tag.id}
                              value={tag}
                              sx={{ fontSize: "0.6em" }}
                            >
                              <Checkbox
                                checked={selectedState.indexOf(tag) > -1}
                                sx={{ width: "30px", height: "30px" }}
                              />
                              <Tooltip title={tag.title}>
                                <ListItemText primary={tag.title} />
                              </Tooltip>
                            </CustomMenuItem>
                          ))}
                      </CustomSelect>
                    </CustomBadge>
                  </CustomFormControl>
                </ContainerGroupSelects>
              )}

              {!hideFilterDate &&
                <>
                  {/* Filter dates */}
                  <ContainerDates>

                    <ContainerGroup>
                      <ContainerInputsDate isMywork={isMywork}>
                        <Label>{t("common.initDate")}</Label>
                        <InputDate
                          type="date"
                          value={initialDate}
                          min="2020-03-20"
                          max={finalDate}
                          onChange={(e) => setInitialDate(e.target.value)}
                        />
                      </ContainerInputsDate>

                      <ContainerInputsDate isMywork={isMywork}>
                        <Label>{t("common.lastDate")}</Label>
                        <InputDate
                          type="date"
                          value={finalDate}
                          min={initialDate}
                          max={moment(new Date()).format("YYYY-MM-DD")}
                          onChange={(e) => setFinalDate(e.target.value)}
                        />
                      </ContainerInputsDate>
                    </ContainerGroup>

                    <ContainerSearch>
                      <SearchButton
                        variant="contained"
                        type="submit"
                        isMobile={true}
                      >
                        {t("common.search")}
                      </SearchButton>

                      {/* Icono Search Mobile */}
                      <Tooltip title={t("common.search")}>
                        <ClearIconButton isFirstClearButton={true} type="submit">
                          <SearchIcon
                            // type="submit"
                            sx={{ height: "20px", width: "20px", color: "white" }}
                          />
                        </ClearIconButton>
                      </Tooltip>

                      <Tooltip title={t("common.cleanFilters")}>
                        <ClearIconButton
                          size="small"
                          onClick={ClearFilters}
                        >
                          <CleanIcon />
                        </ClearIconButton>
                      </Tooltip>

                    </ContainerSearch>
                  </ContainerDates>
                </>
              }

            </ContainerFiltros>
          </>
        )}
      </Forms>
    </CustomToolbar>
  );
}
