import axios from 'axios'
import Router from "../router";
import { Auth } from "../utils/auth";

const instance = axios.create({
    baseURL: Router.apiBaseUrl,
});

instance.interceptors.request.use(
    (config) => {
        let auth = new Auth();
        const token = auth.getToken();
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        let language = localStorage.getItem('lang')
        config.headers['Accept-Language'] = language || 'es'
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default instance;