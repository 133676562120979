import {styled} from "@mui/material/styles";
import { Card, TableCell, Box, Button } from "@mui/material";
import { useColors } from "../../Context/ColorsContext"
import { MediaQuerys } from "../../styles/GlobalStyle";

const { mobile, tablet, desktop, desktoplarge } = MediaQuerys;

function setColor(title) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { colors } = useColors();
    if (title === 'Permiso de trabajo') return colors.purple
    if (title === 'Ejecución Plan de aislamiento') return colors.purple
    if (title === 'Ejecución Prueba de gases') return colors.purple
    if (title === 'En Ejecución') return colors.green
    if (title === 'Cerrado') return colors.blue
}

export const CustomCard = styled(Card)(() => ({
    minHeight: 430,
    maxHeight: 'calc(100vh - 100px)',
    width: '100%',
    borderRadius: 6,
    boxShadow: 'rgb(61 71 82 / 20%) 0px 4px 8px',
    padding: '30px 20px'
}))

export const SectionPageTitle = styled('section')(() => ({
    display:'flex',
    width:'100%',
}))

export const CustomTableCell = styled(TableCell)(() => {
    const { colors } = useColors();   
    return {
        color:colors.main,
        fontSize:'12px',
        fontWeight:600,
    }
})

export const TableCellStatus = styled(TableCell)(() => ({
    display:'flex',
}))
export const ContainerCell = styled('div')(() => ({
    display:'flex',
    alignItems:'center',
}))

export const Figure = styled('figure')(({title}) => ({
  display:'flex',
  justifyContent:'center',
  alignItems:'center',
  background: setColor(title),
  height:'30px',
  width:'30px',
  minWidth:'30px',
  borderRadius:'50%',
  marginRight:'10px !important',
}))

export const SectionInfo = styled('section')(() => ({
    display:'flex',
    flexDirection:'column'
}))

export const InfoCell = styled('div')(() => ({
}))

export const Spacer = styled('div')(() => ({
    display:'flex',
    flex:1,
}))

export const BoxModal = styled(Box)(() => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
}))


export const ContainerButtonCreate = styled('div')(() => ({
  width: 170,
  height: 40,
  [desktop]: {
    width: "120px",
    height: "30px",
    "& button": {
      padding: "14px 12px",
      fontSize: "0.55rem",
    },
  },
  [tablet]: {
    width: "110px",
    height: 20,
    "& button": {
      fontSize: "7px",
    },
  },
}))

export const ButtonForm = styled(Button)(() => {
  const { colors } = useColors();   
  return {
    height: '100%',
    width: '100%',
    padding: "0 20px",
    backgroundColor: colors.buttonActionColor,
    color: colors.white,
    "&:hover": {
      backgroundColor: colors.buttonActionColor,
      opacity: 0.9,
      color: colors.white
    }
  }
})