import React from 'react'

// Styles
import {
  Container,
  Body,
  ContainerInfo,
  ContainerInput,
  FormLabel,
} from '../../styles'


// Utils
import { useTranslation } from 'react-i18next'

// Permissions
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../../../../../Context/PermissionsContext';
import { ContractScopeContainer } from '../../../../../../containers/ContractScopeContainer';
import { Grid, Input } from '@mui/material';
import { SelectInput } from '../../../../../SelectInput';

export const ExternalRoleWithContract = ({
  data,
  companies,
  selectedCompany,
  handleSetCompany,
  companyContracts,
  errors,
  setErrors,
  form,
  setForm,
  handleChangeStepTwo,
  user,
  isSupervisor
}) => {
  const ability = useAbility(AbilityContext);
  const { t } = useTranslation();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        {ability.can("AllCompanies") &&
          <Grid item xs={12} mb={1}>
            <SelectInput
                label={t('system.users.table.company')}
                onChange={(value) => handleSetCompany(value) }
                value={selectedCompany}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option != null ? option.name : ''}
                options={companies.map((obj) => { return obj })}
                keyValue="provider"
                sx={{ width: '25%' }}
                error={errors.company?.message}
                renderInput={(params) => <Input {...params} label={t('system.users.selectCompany')} />}
                disableClearable={false}
            />
          </Grid>
        }
      </Grid>
      <Grid item xs={12}>
        <ContractScopeContainer
          data={data}
          form={form}
          setForm={setForm}
          errors={errors}
          setErrors={setErrors}
          companyContracts={companyContracts}
          handleChangeStepTwo={handleChangeStepTwo}
          disabled={!selectedCompany?.id}
          user={user}
          isSupervisor={isSupervisor}
        />
      </Grid>
    </Grid>
  )
}
