import { styled } from "@mui/material/styles";
import { IconButton, TableCell, Autocomplete, TextField } from "@mui/material";
import { useColors } from "../../../../Context/ColorsContext"
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import TableBody from '@mui/material/TableBody';
import { MediaQuerys } from "../../../../styles/GlobalStyle";

const { mobile, tablet, desktop, desktoplarge } = MediaQuerys;


function setColor(title) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { colors } = useColors();
  let titleLower = title ? title.toLowerCase() : '';
  if (titleLower === 'aprobación pendiente') return colors.purple
  if (titleLower === 'ejecución plan de aislamiento') return colors.purple
  if (titleLower === 'ejecución prueba de gases') return colors.purple
  if (titleLower === 'en ejecución') return colors.green
  if (titleLower === 'cerrado') return colors.blue
}

export const CustomTableCell = styled(TableCell)(() => {
  const { colors } = useColors();
  return {
    color: colors.main,
    fontSize: '1rem',
    fontWeight: 600,
    paddingTop: '10px !important',
    paddingBottom: '10px !important',
    alignItems: 'center',
    padding: "0 16px",
    "&:hover": {
      color: colors.buttonActionColor,
      fontWeight: 700,
    },
    [desktop]: {
      fontSize: "0.75rem !important",
    }
  }
})

export const TableCellStatus = styled(TableCell)(() => ({
  display: 'flex',
  alignItems: 'center',
  border: 'none'
}))
export const ContainerCell = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}))

export const Figure = styled('figure')(({ title }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: title,
  height: '30px',
  width: '30px',
  minWidth: '30px',
  borderRadius: '50%',
  marginRight: '10px !important',
}))


export const SectionInfo = styled('section')(() => ({
  display: 'flex',
  flexDirection: 'column'
}))

export const InfoCell = styled('div')(() => ({
}))

export const Label = styled('label')(() => ({
  fontSize: '14px',
  paddingRight: '5px'
}))

export const InputDate = styled('input')(({ theme }) => {
  const { colors } = useColors();
  return {
    marginRight: '20px',
    borderBottom: '1px solid ' + colors.grayFont,
    borderRadius: '3px',
    fontFamily: theme.typography.fontFamily,
    padding: '5px 10px',
    width: '150px',
  }
})

export const FigureHead = styled('figure')(() => ({
  display: 'flex'
}))

export const Cell = styled(TableCell)(() => ({
  border: 'none'
}))

export const IconAction = styled(IconButton)(({ idListDetail }) => {
  const { colors } = useColors();
  return {
    borderRadius: 20,
    pointerEvents: "pointer",
    background: colors.buttonActionColor,
    marginLeft: 5,
    "&:hover": {
      backgroundColor: colors.buttonActionColor,
      opacity: 0.9,
      color: colors.white,
    },
    [desktop]: {
      fontSize: "0.25rem",
      width: '28px',
      height: '28px',
      "&MuiSvgIcon-root": {
        width: '16px'
      }
    },
    [tablet]: {
      height: 28,
      width: 28,
    },
    // [mobile]: {
    //   display: idListDetail ? 'flex' : 'none',
    // },
  };
});

export const CleanIcon = styled(CleaningServicesIcon)(() => {
  const { colors } = useColors();
  return {
    color: colors.white,
    fontSize: 18
  }
})


export const NewIcon = styled(AddCircleIcon)(() => {
  const { colors } = useColors();
  return {
    height: '20px',
    width: '20px',
    color: colors.white,
    // fontSize: 18,
    [desktop]: {
      height: '16px',
      width: '16px'
    }
  };
});

export const SelectInput = styled(Autocomplete)(() => {
  const { colors } = useColors();
  return {
    width: '100%',
    background: colors.white,
    borderRadius: 6,
    fontSize: 13,
    '.MuiInputBase-input': {
      padding: 8
    },
  }
})

export const Input = styled(TextField)(() => {
  const { colors } = useColors();
  return {
    width: '100%',
    '.MuiInputBase-root': {
      background: colors.white,
      borderRadius: 6,
      fontSize: 13
    }
  }
})


export const ContainerContent = styled('div')(() => {
  return {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  }
})

export const ContainerInputSelect = styled('div')(() => {
  return {
    width: '180px',
    [desktop]: {
      '.MuiInputLabel-root': {
        fontSize: '0.85rem',
      },
    }
  }
})


export const TableContent = styled(TableBody)(() => ({
  ".MuiTableCell-root": {
    fontSize: "1rem !important",
  },
  [tablet]: {
    '.MuiTablePagination-displayedRows': {
      fontSize: '0.75rem !important',
    },
    '.MuiTablePagination-selectLabel':{
      fontSize: '0.75rem!important',
    }
  },
}));