import {styled} from "@mui/material/styles";




export const Container = styled('div')(() => {
    return {
        width: '100%',
        height: '100%',
        display: 'flex',
        gap: '0.50rem',
        flexDirection: 'column',
        '&::-webkit-scrollbar': {
            width: 5,
            height: 5
        },
    }
})