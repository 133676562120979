import React, { useState, useContext, useEffect } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { theme } from "../../components/Global/style";
import { DrawerHeader, LayoutContainer, Container, Content } from "./styles";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import Footer from "../Footer";

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { ColorsContext } from "../../Context/ColorsContext";

function Layout({
  children,
  openState,
  onChangeDemo,
  isDemo,
  reloadNotifications,
  updateTable,
  reloadWorkflows,
}) {
  const [open, setOpen] = useState(window.innerWidth >= 770);
  const { openBackDrop, contentRender } = useContext(ColorsContext);
  
  const handleOpen = () => {
    setOpen(!open);
    openState(!open);
  };

  useEffect(() => {
    setOpen(window.innerWidth >= 770);
  }, [window.innerWidth]);

  return (
    <>
      {
        !contentRender ? (
          <>
            <Backdrop
              sx={{ color:"#fff", zIndex: (theme) => theme.zIndex.drawer + 1000 }}
              open={openBackDrop}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </>
        ) : (
          <>
            <LayoutContainer isOpen={open} id="Layout Container">
              <ThemeProvider theme={theme}>
                <Content fixed id="content-layout">
                  <CssBaseline />
                  <Navbar
                    updateTable={updateTable}
                    reloadNotifications={reloadNotifications}
                    handleOpen={handleOpen}
                    onChangeDemo={onChangeDemo}
                    isDemo={isDemo}
                    reloadWorkflows={reloadWorkflows}
                  />
                  <Sidebar open={open} isDemo={isDemo} setOpen={setOpen}/>
                  <Container isOpen={open} component="main">
                    <DrawerHeader />
                    {children}
                  </Container>
                  <Footer open={open} />
                </Content>
              </ThemeProvider>
            </LayoutContainer>
          </>
        )
      }

    </>
  );
  }


  export default Layout;
