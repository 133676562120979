import moment from "moment";
import 'moment/locale/es';
// import 'moment/locale/pt';
import 'moment/locale/pt-br'

export class FormatDate {

    format({ date, isFull, showHourLong, showHourShort, format, formatWithoutHour, hour, showShortWithoutHour }) {
        let result = "";
        let lang = localStorage.getItem("lang") || 'es';
        
        if (date) {

            (lang !== null) ? moment.locale(lang) : moment.locale('es');

            if (isFull) result = moment(date).format("MMMM DD YYYY")
            else result = moment(date).format("MMM DD YYYY")
            
            if (showHourLong) result = moment(date).format("MMMM DD YYYY, h:mm A")
            if (showHourShort) result = moment(date).format("MMM DD YYYY, h:mm A")
            if (format) result = moment(date).format('DD/MM/YYYY HH:mm')
            if (formatWithoutHour) result = moment(date).format('DD/MM/YYYY')
            if(showShortWithoutHour) result = moment(date).format('MMM DD YYYY')
            if(hour) result = moment(date).format("HH:mm")
        }

        if (result) result = result.charAt(0).toUpperCase() + result.slice(1).toLowerCase();
        return result;
    }
    
}



