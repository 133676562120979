import { Auth } from "../utils/auth";
import { HealthCheckApi } from "./HealthCheckApi";
import Router from "../router";
import axios from "./AxiosConfig";

// Permissions
import ability from '../config/defineAbility.js';

export class ContractApi {
    healthCheck = null;
    token = null;

    constructor() {
        let auth = new Auth();
        this.token = auth.getToken();
        this.healthCheck = new HealthCheckApi();
    }

    async list(withoutContractor) {
        if (ability.can("ContractList")) {
            return await axios.get(Router.apiBaseUrl + Router.apiContract, {
                params: {
                    withoutContractor: withoutContractor
                }
            })
            .then(async response => {
                return await this.healthCheck.checkApi(response)
            }).catch(async error => {
                return await this.healthCheck.checkApi(error)
            })
        }
        return {status: false};
    }

    async getById(id) {
        if (ability.can("GetContractById")) {
            return await axios.get(Router.apiBaseUrl + Router.apiContractDetail.replace(':id', id), {})
            .then(async response => {
                return await this.healthCheck.checkApi(response)
            }).catch(async error => {
                return await this.healthCheck.checkApi(error)
            })
        }
        return {status: false};
    }

    async create(data) {
        if (ability.can("CreateContract")) {
            return await axios.post(Router.apiBaseUrl + Router.apiContract, data)
            .then(async response => {
                return await this.healthCheck.checkApi(response)
            }).catch(async error => {
                return await this.healthCheck.checkApi(error)
            });
        }
        return {status: false};
    }

    async update(id, data) {
        if (ability.can("UpdateContract")) {
            return await axios.put(Router.apiBaseUrl + Router.apiContractUpdate.replace(':id', id), data)
            .then(async response => {
                return await this.healthCheck.checkApi(response)
            }).catch(async error => {
                return await this.healthCheck.checkApi(error)
            })
        }
        return {status: false};
    }

    async delete(id) {
        if (ability.can("DeleteContract")) {
            return await axios.delete(Router.apiBaseUrl + Router.apiContractDelete.replace(':id', id), {})
            .then(async response => {
                return await this.healthCheck.checkApi(response)
            }).catch(async error => {
                return await this.healthCheck.checkApi(error)
            })
        }
        return {status: false};
    }

    async removeContractor(id) {
        if (ability.can("UpdateContractor")) {
            return await axios.post(Router.apiBaseUrl + Router.apiRemoveContractor.replace(':id', id), {})
            .then(async response => {
                return await this.healthCheck.checkApi(response)
            }).catch(async error => {
                return await this.healthCheck.checkApi(error)
            })
        }
        return {status: false};
    }

    async getTechnicalSupervisors() {
        if (ability.can("GetTechnicalSupervisors")) {
            return await axios.get(Router.apiBaseUrl + Router.apiTechnicalSupervisors)
            .then(async response => {
                return await this.healthCheck.checkApi(response)
            }).catch(async error => {
                return await this.healthCheck.checkApi(error)
            })
        }
        return {status: false};
    }

    async getContractorData(contractId) {
        if (ability.can("GetContractorsContract")) {
            return await axios.get(Router.apiBaseUrl + Router.apiContractorData + `/${contractId}`)
            .then(async response => {
                return await this.healthCheck.checkApi(response)
            }).catch(async error => {
                return await this.healthCheck.checkApi(error)
            })
        }
        return {status: false};
    }

    async updateContractor(contractId, data) {
        return await axios.put(Router.apiBaseUrl + Router.apiContractUpdateContractor + `/${contractId}`, data)
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)
        })
    }

    async getContractorExecutors(contractId) {
        if (ability.can("GetContractorExecutors")) {
            let auth_token = { 'Authorization': `Bearer ${this.token}`}

            return await axios.get(Router.apiBaseUrl + Router.apiContractorExecutors + `/${contractId}`, { 
                headers: auth_token
            })
            .then(async response => {
                return await this.healthCheck.checkApi(response)
            }).catch(async error => {
                return await this.healthCheck.checkApi(error)
            })
        }
        return {status: false};
    }

    async getSupervisorData(contractId) {
        if (ability.can("GetTechnicalSupervisors")) {
            return await axios.get(Router.apiBaseUrl + Router.apiContractTechnicalSupervisors + `/${contractId}`)
            .then(async response => {
                return await this.healthCheck.checkApi(response)
            }).catch(async error => {
                return await this.healthCheck.checkApi(error)
            })
        }
        return {status: false};
    }

    async updateTechnicalSupervisor(contractId, data) {
        return await axios.put(Router.apiBaseUrl + Router.apiContractTechnicalSupervisors + `/${contractId}`, data)
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)
        })
    }

    async getFiles(id) {
        if (ability.can("GetAttachmentContracts")) {
            return await axios.get(Router.apiBaseUrl + Router.apiContractAttachments + `/${id}`, {})
            .then(async response => {
                return await this.healthCheck.checkApi(response)
            }).catch(async error => {
                return await this.healthCheck.checkApi(error)
            })
        }
        return {status: false};
    }

    async uploadFilesTechnicalSupervisor(contractId, formDataFiles) {
        return await axios.post(Router.apiBaseUrl + Router.apiUploadFilesTechnicalSupervisors + `/${contractId}`, formDataFiles)
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)
        })
    }

    async getAttachmentsContractSupervisor(id) {
        if (ability.can("CheckAttachmentContractSupervisor")) {
            return await axios.get(Router.apiBaseUrl + Router.apiContractAttachmentsContractSupervisor + `/${id}`, {})
            .then(async response => {
                return await this.healthCheck.checkApi(response)
            }).catch(async error => {
                return await this.healthCheck.checkApi(error)
            })
        }
        return {status: false};
    }
    
}