import styled from 'styled-components';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useColors } from "../../Context/ColorsContext"

const tabletStartWidth = 768;
const mobile = `@media (max-width: ${tabletStartWidth}px)`


export const BoxModal = styled(Box)(() => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
}))

export const Container = styled('div')(() => ({
    display: 'flex',
    width: '100%',
    minWidth: '500px',
    height: '100%',
    flexDirection: 'column',
}))

export const Header = styled('div')(() => {
    const { colors } = useColors();   
    return {
        display: 'flex',
        background: colors.white,
        color: colors.detailTextColor,
        width: '100%',
        height: 'fit-content',
        borderRadius:'10px 10px 0 0',
        padding:'16px',
        textTransform:'uppercase',
        fontWeight:600
    }
})

export const Body = styled('div')(() => {
    const { colors } = useColors();   
    return {
        display: 'flex',
        flexDirection:'column',
        flex:'1',
        width: '100%',
        background: colors.white,
    }
})

export const Footer = styled('div')(() => {
    const { colors } = useColors();   
    return {
        display: 'flex',
        background: colors.white,
        width: '100%',
        height: 'fit-content',
        borderRadius:'0 0 10px 10px',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '8px 16px',
        margin: 'auto'
    }
})

export const ContainerInfo = styled('div')(() => {
    const { colors } = useColors();   
    return {
        display: 'flex',
        flexDirection:'column',
        background: colors.gray,
        width: '100%',
        height: 'fit-content',
        padding:'16px 24px',
    }
})

export const SectionRight = styled('section')(() => ({
    display: 'flex',
}))

export const Text = styled(Typography)((props) => {
    const { colors } = useColors();   
    return {
        color: `${(props.color ? props.color : colors.red)}`,
        fontSize: '22px',
        lineHeight: 1.2,
        fontWeight: 600,
        margin: 0,
        padding: 0,
        whiteSpace: 'pre-wrap'
    }
})