import React, { useEffect, useState } from 'react';
// Components
// import { Button } from '@mui/material';
import { toast } from 'react-toastify';

//MUI Material
import { Button, Icon } from '@mui/material';

// Custom components
import { PageTitle } from '../../components/PageTitle';
import { SearchBarForms } from '../../components/atoms/searchBars/SearchBarForms';
import TableModel from '../../components/atoms/tables/TableModel';
import { ShowDialog } from '../../components/ShowDialog';
import { BackdropLoading } from '../../components/BackdropLoading';

// Styles
import { Container, ContainerBarSearch, ContainerButtonDownload, SectionPageLeft, SectionPageTitle } from '../styles';
import { Spacer } from './styles';

// API
import { FormApi } from '../../services/FormApi';

// Router
import { useNavigate, useLocation } from 'react-router-dom'
import Router from '../../router';

// Icons
import { ViewComfy, CloudDownloadOutlined } from '@mui/icons-material';
// import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';

// Utils
import moment from 'moment';
import { CheckResponse } from '../../utils/checkResponse';
import { useTranslation } from 'react-i18next';
import { FormatDate } from './../../utils/formatDate';
import { ColorsContext } from "../../Context/ColorsContext"

// Hook
import { useDownloadExcel } from '../../Hooks/useDownloadExcel'

export const FormManagement = (props) => {
    const { t } = useTranslation();
	const { colors } = React.useContext(ColorsContext);

	const location = useLocation();
    const formApi = new FormApi();
	const {check}= new CheckResponse();
	const { format } = new FormatDate();
	const isForm = location.pathname === "/form-management"

    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const [filteredRequest, setFilteredRequest] = useState([]);
    const [finalDate, setFinalDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
    const [initialDate, setInitialDate] = useState(moment(finalDate).subtract(1, 'months').format("YYYY-MM-DD"));
    const [datesForm, setDatesForm] = useState({
		initialDate: { initialDate },
		finalDate: { finalDate }
    });
	const [dialog, setDialog] = useState({
		status: false,
		title: '',
		message: '',
		type: null
    });

    useEffect(() => {
		async function call() {
            await loadDataDates();
		}
		call()
    }, [props.isDemo])


	//?? Prepare fields to download Excel
	const fieldsConfig = {
		[t('forms.formManagement.number')]: 'id',
		[t('forms.formManagement.formName')]: 'name',
		[t('forms.formManagement.workflow')]: (item) => item?.workflow?.name,
		[t('forms.formManagement.integrationKey')]: 'integrationKey',
		[t('forms.formManagement.lastUpdate')]: (item) => format(item?.updatedAt, false, false, false, true).toString()
	}

	//?? Custom Hook
	const { handleDownloadExcelList } = useDownloadExcel(filteredRequest, fieldsConfig, t('sidebar.formsv1'));

	const headers = [
		{
			id: 'detail',
			label: 'forms.formManagement.seeDetail',
			center: true,
			disablePadding:true,
			sortArrow: false,
			type: 'detail',
		},
		{
			id: 'id',
			label: 'forms.formManagement.number',
			center: true,
			disablePadding:true,
			sortArrow: false,
			isRender: true,
		},
		{
			id: 'name',
			label: 'forms.formManagement.formName',
			center: false,
			disablePadding:true,
			sortArrow: false,
			isRender: true,
		},
		{
			id: 'workflow',
			label: 'forms.formManagement.workflow',
			center: false,
			disablePadding:true,
			sortArrow: false,
			type: 'object',
			keys: ['icon', 'name'],
		},
		{
			id: 'integrationKey',
			label: 'forms.formManagement.integrationKey',
			center: false,
			disablePadding:true,
			sortArrow: false,
			isRender: true,
		},
		{
			id: 'updatedAt',
			label: 'forms.formManagement.lastUpdate',
			center: false,
			disablePadding:true,
			sortArrow: false,
			type: 'date',
		},
		{
			id: 'actions',
			label: 'common.actions.title',
			center: true,
			disablePadding: false,
			sortArrow: false,
			type: 'actions',
			keys: ['edit', 'delete']
		},
	];

    async function loadData() {
		let response = await formApi.list(null, null);
		if (response.status === 200) {
			setData(response.data);
		}
        return response.data || [];
    }

	async function loadDataDates(date1, date2) {
		let startDate = date1 || initialDate
		let endDate = date2 || finalDate

        setLoading(true);
        setDatesForm({ ...datesForm, startDate, endDate });

        let response = await loadData();
        let newData = response?.filter((el) => {
            return (
                moment(el.updatedAt).format("YYYY-MM-DD") >= startDate &&
                moment(el.updatedAt).format("YYYY-MM-DD") <= endDate
            );
        });
        setData(newData);
        setLoading(false);
	}

    async function handleDatesFilter(e) {
		e.preventDefault()
		await loadDataDates();
		e.target.reset()
	}

	async function handleClearDate() {
		let endDate = moment(new Date()).format("YYYY-MM-DD")
		let startDate = moment(endDate).subtract(1, "months").format("YYYY-MM-DD")
        setFinalDate(endDate);
        setInitialDate(startDate);

        await loadDataDates(startDate, endDate);
	}

	async function handleCreateNew() {
		navigate(Router.appCreateForm)
	}

	async function handleDetail(row) { 
		navigate(Router.appFormManagementDetail, {state: {row: row}})
	}

	async function handleEdit(row) { 
		navigate(Router.appCreateForm, {state: {row: row}})
	}

	async function handleDelete(id) { 
		setLoading(true)
		let response = await formApi.delete(id);
		let result = check(response)

		if (result.status) {
			setDialog({
				status: true,
				title: t('forms.formManagement.messages.deletedForm.title'),
				message: t('forms.formManagement.messages.deletedForm.message'),
				type: null,
			})
            await loadDataDates(initialDate, finalDate);
		}
		else {
			toast.error(() => 
			  <div>{t('forms.formManagement.messages.deletedForm.error')}<br/>
				  {result.errors}
			  </div>
			);
		}

		setLoading(false)
	}

	async function handleOpenDialog(row) { 
		setDialog({
			status: true,
			title: t('forms.formManagement.messages.deletedForm.confirm'),
			message: t('forms.formManagement.messages.deletedForm.confirmMessage', {form: row.name}),
			type: 'confirmation',
			id: row.id
		})
	}

	async function handleCloseDialog(value) {
		if (value) {
			setDialog({...dialog, status: false})
			await handleDelete(dialog.id);
		}
		else {
			setDialog({status: false})
		}
	}

    return (
        <Container open={props.open}>
          <SectionPageTitle>
			<SectionPageLeft>
				<PageTitle title={t('forms.formManagement.title')} icon={<ViewComfy/>}/>
				<ContainerBarSearch>
					<SearchBarForms rows={data} setFilteredRequest={setFilteredRequest}/>
				</ContainerBarSearch>
			</SectionPageLeft>
            {/* <Spacer /> */}
			<ContainerButtonDownload>
				<Button
				variant="contained"
				endIcon={<CloudDownloadOutlined />}
				sx={{ 
					height: '100%', width: '100%',  padding: '0 20px', 
					backgroundColor: colors.buttonActionColor,
					color: colors.white,
					"&:hover": {
						backgroundColor: colors.buttonActionColor,
						opacity: 0.9,
						color: colors.white
					}
				}}
				aria-label="download"
				onClick={handleDownloadExcelList}
				>
				{t('common.download')}
				</Button>
			</ContainerButtonDownload>
          </SectionPageTitle>

          <TableModel
		  	headers={headers}
            filteredRequest={filteredRequest}
            rows={data}
			// Manage date
            initialDate={initialDate}
            setInitialDate={setInitialDate}
            finalDate={finalDate}
            setFinalDate={setFinalDate}
            handleDatesFilter={handleDatesFilter}
            handleClearDate={handleClearDate}
			// Handle create, edit, delete
            handleCreateNew={handleCreateNew}
            labelCreateNew={t('forms.formManagement.createNewForm')}
            handleDetail={handleDetail}
            handleEdit={handleEdit}
            handleDelete={handleOpenDialog}
			isForm={isForm}
          />
		  
		  <BackdropLoading open={loading}/>
		  <ShowDialog openDialog={dialog.status} dialog={dialog} handleCloseDialog={handleCloseDialog}/>
        </Container >
      )
}
