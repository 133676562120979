import React from 'react'
import { Button, Grid, Switch, FormGroup, Checkbox, Divider } from '@mui/material';
import SyncLockIcon from '@mui/icons-material/SyncLock';

import ClearIcon from '@mui/icons-material/Clear';

// Components
import { AttachmentUsers } from '../../../../AttachmentUsers';

// Styles
import {
  Container,
  Header,
  Body,
  Footer,
  ContainerInfo,
  SectionRight,
  Spacer,
  SectionMidInfo,
  SectionTopInfo,
  SectionBottomInfo,
  Input,
  Text,
  CustomTextField,
  CustomFormControlLabel,
  CustomIconButton,
} from '../styles'


// Utils
import { useTranslation } from 'react-i18next'
import { ColorsContext } from '../../../../../Context/ColorsContext';
import { PasswordGenerator } from '../../../../atoms/passwordGenerator';

// Permissions
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../../../../Context/PermissionsContext';

export const StepThree = ({
  data,
  validateEmail,
  handleFilesDrop
}) => {
  const { colors } = React.useContext(ColorsContext);
  const ability = useAbility(AbilityContext);
  const { t } = useTranslation();

  return (
    <Container>
      <Body>
        <ContainerInfo>
          <Grid container spacing={2}>
            {ability.can("UserAttachmentCreate") &&
              <Grid item xs={12} md={12} sx={{ marginTop: '5px' }}>
                <AttachmentUsers 
                  id={data.id} 
                  disabled={false || validateEmail} 
                  onFilesDrop={handleFilesDrop}
                  filesHeight="200px"
                />
              </Grid>
            }
          </Grid>
        </ContainerInfo>
      </Body>
    </Container>
  )
}
