import React from 'react'
import { useTranslation } from "react-i18next";
import { Grid } from '@mui/material'
import { useNavigate } from 'react-router-dom';

import { Visibility } from '@mui/icons-material';

import { ColorsContext } from "../../Context/ColorsContext"

import { CustomButton, ContainerMTop } from './styles';

import Router from '../../router';
import { FormatDate } from "../../utils/formatDate";
import { getIcon } from "../../utils/iconConfig";
import { snakeToCamel } from '../../utils/commonFunctions/convert';

export const WorkflowDataRelationDetail = ({data, workflowId, isLastItem}) => {

    const navigate = useNavigate();
    const { format } = new FormatDate();
    const { t } = useTranslation();
    const { colors } = React.useContext(ColorsContext);

    function linkComponent() {
        navigate(Router.appWorkflowDetail.replace(':workflowId', workflowId).replace(':id', data.id), { state: { row: data } } )
    }

    return (
        <Grid container display={'flex'} justifyContent={'space-between'} alignItems={'center'} 
            sx={{ 
                borderBottom: isLastItem ? 'none' : `1px solid ${colors.grayFont}`,
                marginBottom: '1rem', 
                paddingBottom: '.5rem'
            }}>
            <Grid item xs={12} md={5}>
                <Grid container display={'flex'} flexDirection={'column'} justifyContent={'center'}>
                    <Grid item xs={12}>
                        <p style={{ fontSize: '1rem' }}><strong>{t('workflow.detail.number')}: </strong>{data.code}</p>
                    </Grid>
                    <Grid item xs={12}>
                        <p style={{ fontSize: '1rem' }}><strong>{t('workflow.detail.title')}: </strong>{data.title}</p>
                    </Grid>
                    <Grid item xs={12}>
                        <p style={{ fontSize: '1rem' }}><strong>{t('workflow.detail.lastUpdate')}: </strong>{format({date: data.updatedAt, showHourShort: true})}</p>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={6} md={4}>
                <ContainerMTop>
                    <Grid container display={'flex'} flexDirection={'column'} justifyContent={'flex-start'}>
                        <Grid item xs={12}>
                            <p style={{ fontSize: '1rem' }}><strong>{t('workflow.detail.status')}:</strong></p>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container display={'flex'} flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'}>
                                {getIcon(data.workflowStatus)}
                                <Grid item xs={10}>
                                    <Grid container display={'flex'} flexDirection={'column'} justifyContent={'center'}>
                                        <Grid item xs={12}>
                                            <p style={{ fontSize: '0.875rem', color: colors.grayFont }}>
                                                {(data.workflowStatus?.id >= 0 && t(`workflowManagement.flowState.status.${snakeToCamel(data.workflowStatus?.type)}`)) || ""}
                                            </p>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <p style={{ fontSize: '1rem' }}>
                                                {data.workflowStatus?.title || ""}
                                            </p>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </ContainerMTop>
            </Grid>
            <Grid item xs={6} md={3}>
                <CustomButton
                    size="small"
                    isMobile={true}
                    type={'download'}
                    variant="contained"
                    endIcon={<Visibility />}
                    sx={{ padding: '0 20px' }}
                    aria-label="view"
                    onClick={linkComponent}
                >
                    {t('common.see')}
                </CustomButton>
            </Grid>
        </Grid>
    )
}