import React from 'react';

// Components
import { Grid, Tooltip, Box, IconButton } from '@mui/material';

// Custom components
import { SelectField } from '../SelectField';
import { MultiSelectField } from '../MultiSelectField';
import { CardContent } from '../CardContent';
import { CardImage } from '../CardImage';
import TableModelScroll from '../atoms/tables/TableModelScroll';
import { PlotPlanGraphic } from '../PlotPlanGraphic';
import { StatusCardAreaTitle } from '../atoms/statusCardAreaTitle';
import { CardArea } from '../CardArea';
import { PageTitle } from '../PageTitle';
import { LoadingProgress } from '../LoadingProgress';


// Styles
import {
    Title,
    CardDetail,
    CustomButton,
    CustomIconButton,
    ButtonContainer,
    ContainerTitle,
    ContainerButtoms,
    ContainerContent,
    ContainerGroup,
    ContainerGroupBtns,
    ContainerLeft,
    ContainerMid,
    ContainerPage,
    ContainerGroupMid,
    ContainerRight,
    ContainerTop,
    ContainerBottom,
    IconAction,
    NewIcon,
    ContainerDownload,
    CustomToolTip,
    ContainerPLotplanMobile,
    ContainerTablePLotsMobile,
    ContainerPlotPlans,
    ContainerTypesArea,
    ContainerSearchTypesArea,
    ContainerMultiSelect,
} from './styles';
import { EmptyContainer, GridContainer, GridScroll } from '../../pages/styles';

// Icons
import { CloudDownloadOutlined, Delete, Edit, Map } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';

// Utils
import { useTranslation } from 'react-i18next'

// Permissions
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../Context/PermissionsContext';

export const PlotPlanPage = (props) => {
    const {
        height, plant, plants, childNodes, root, loading, parentNode, statusList,
        handleOpenForm, handleChangePlants, handleSelect, handleStepBack, handleStepBegin,
        headers, filteredRequest, statusCellComponent, loadingData, downloadData,
        selectArea, selectedItem, handleBackArea, onClickCode,
        handleEdit, handleOpenDelete, validityTooltip, typeArea, selectedTypeArea,
        handleChangeTypeAreas, handleClickTypeAreas, selectAll
    } = props;
    const { t } = useTranslation();
    const ability = useAbility(AbilityContext);

    return (
        <ContainerPage>
            <ContainerLeft height={height}>
                <ContainerTitle>
                    <PageTitle title={t('plotPlans.titlePlot')} icon={<Map />} iscolumn />
                </ContainerTitle>

                <ContainerGroupBtns>
                    <ContainerContent>
                        {ability.can("CreatePlotPlan") &&
                            <ContainerButtoms isCreate={true}>
                                <CustomButton onClick={handleOpenForm} variant="contained">
                                    {t('plotPlans.createNew')}
                                </CustomButton>
                            </ContainerButtoms>
                        }

                        <ContainerGroup isMiddle={true} >
                            <CustomToolTip>
                                <IconAction size="medium" onClick={handleOpenForm}>
                                    <NewIcon />
                                </IconAction>
                            </CustomToolTip>

                            <ContainerDownload>
                                <CustomButton
                                    size="small"
                                    isMobile={true}
                                    type={'download'}
                                    variant="contained"
                                    endIcon={<CloudDownloadOutlined />}
                                    sx={{ padding: '0 20px' }}
                                    aria-label="download"
                                    onClick={downloadData}
                                >
                                    {t('common.download')}
                                </CustomButton>
                            </ContainerDownload>
                        </ContainerGroup>

                        <ContainerGroup>
                            {ability.can("UpdatePlotPlan") &&
                                <ContainerButtoms isEditAndDelete={true}>
                                    <Tooltip title={t('common.actions.edit')}>
                                        <CustomIconButton onClick={handleEdit} disabled={loadingData || loading || parentNode.id === 1}>
                                            <Edit />
                                        </CustomIconButton>
                                    </Tooltip>
                                </ContainerButtoms>
                            }
                            
                            {ability.can("DeletePlotPlan") &&
                                <ContainerButtoms isEditAndDelete={true}>
                                    <Tooltip title={t('common.actions.delete')}>
                                        <CustomIconButton onClick={handleOpenDelete} disabled={loadingData || loading || parentNode.id === 1}>
                                            <Delete />
                                        </CustomIconButton>
                                    </Tooltip>
                                </ContainerButtoms>
                            }
                        </ContainerGroup>
                    </ContainerContent>

                    <SelectField
                        isPlotplan={true}
                        label={t('plotPlans.selectPlan')}
                        onChange={handleChangePlants}
                        value={plant}
                        options={plants}
                    />
                    
                    <ContainerTypesArea>
                        <ContainerMultiSelect>
                            <MultiSelectField
                                label={t('plotPlans.typesAreas')}
                                selectLabelAll={t('plotPlans.selectAll')}
                                typeArea={typeArea}
                                selectedTypeArea={selectedTypeArea}
                                handleChangeTypeAreas={handleChangeTypeAreas}
                                selectAll={selectAll}
                            />
                        </ContainerMultiSelect>
                        <ContainerSearchTypesArea onClick={handleClickTypeAreas}>
                            <IconButton aria-label="search" size="small">
                                <SearchIcon fontSize="small" />
                            </IconButton>
                        </ContainerSearchTypesArea>
                    </ContainerTypesArea>
                </ContainerGroupBtns>
                
                {/* Mobile */}
                <ContainerPLotplanMobile>
                    <CardImage
                        root={root}
                        loading={loading}
                        data={parentNode}
                        onSelect={handleSelect}
                        goBack={handleStepBack}
                        goBegin={handleStepBegin}
                    />
                </ContainerPLotplanMobile>


                <GridScroll container spacing={0} normal={true} id="CONTRAINER GRIDDD">
                    {(loadingData || loading)
                        ?
                        <LoadingProgress />
                        :
                        <>
                            {childNodes.length > 0
                                ?
                                <ContainerPlotPlans>
                                    <CardContent data={childNodes} onSelect={handleSelect} />
                                </ContainerPlotPlans>
                                :
                                <EmptyContainer childVoid={ childNodes.length <= 0 && true }>{t('common.empty.noZones')}</EmptyContainer>
                            }
                        </>
                    }
                </GridScroll>
            </ContainerLeft>

            <ContainerMid>
                <ContainerGroupMid>
                    <ContainerTop>
                        <CardImage
                            root={root}
                            loading={loading}
                            data={parentNode}
                            onSelect={handleSelect}
                            goBack={handleStepBack}
                            goBegin={handleStepBegin}
                        />
                    </ContainerTop>

                    <ContainerBottom>
                        <TableModelScroll
                            headers={headers}
                            filteredRequest={filteredRequest}
                            rows={filteredRequest}
                            hideFilterDate={true}
                            hideToolbar={true}
                            defaultRowsPerPage={5}
                            custom={statusCellComponent}
                            defaultSort={'createdAt'}
                            loadingData={loadingData}
                            validityTooltip={validityTooltip}
                        />
                    </ContainerBottom>
                </ContainerGroupMid>
            </ContainerMid>


            <ContainerRight>
                {ability.can("GetDashboard") &&
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <CardDetail id="CARD DETAIL">
                                <Title isGraphic={true}>{t('plotPlans.openState')}</Title>
                                <PlotPlanGraphic
                                    plotPlan={statusList.title}
                                    workflow={statusList.workPermit?.workflow}
                                    typeAreas={statusList?.typeAreas}
                                />
                            </CardDetail>
                        </Grid>
                    </Grid>
                }

                <Grid container spacing={2} alignItems="stretch" justify="space-between">
                    <Grid item xs={12}>
                        <CustomButton
                            isDownload={true}
                            type={'download'}
                            variant="contained"
                            endIcon={<CloudDownloadOutlined />}
                            sx={{ padding: '0 20px', height: '34px' }}
                            aria-label="download"
                            onClick={downloadData}
                        >
                            {t('common.download')}
                        </CustomButton>
                    </Grid>
                </Grid>

                <GridScroll container spacing={0} overflow="auto" isStatusCard={true} childVoid={ childNodes.length <= 0 && true }>
                    {childNodes.length > 0 ?
                        <GridContainer item xs={12}>
                            {statusList.detail.length > 0 ?
                                <StatusCardAreaTitle
                                    data={statusList}
                                    handleBack={handleBackArea}
                                    onClickCode={onClickCode}
                                />
                                :
                                childNodes.map((row, index) => (
                                    <CardArea
                                        key={`area-${index}`}
                                        area={row}
                                        index={index}
                                        selectArea={selectArea}
                                        selectedItem={selectedItem.current}

                                    />
                                ))
                            }
                        </GridContainer>
                        :
                        <EmptyContainer>{t('common.empty.noAreas')}</EmptyContainer>
                    }
                </GridScroll>

                    {/* Mobile */}
                <ContainerTablePLotsMobile>
                    <TableModelScroll
                        headers={headers}
                        filteredRequest={filteredRequest}
                        rows={filteredRequest}
                        hideFilterDate={true}
                        hideToolbar={true}
                        defaultRowsPerPage={5}
                        custom={statusCellComponent}
                        defaultSort={'createdAt'}
                        loadingData={loadingData}
                        validityTooltip={validityTooltip}
                    />
                </ContainerTablePLotsMobile>

            </ContainerRight>
        </ContainerPage>
    )
}
