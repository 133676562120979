import styled from 'styled-components';
import { Button, Grid, Typography, Switch, CardMedia, TextField, Chip } from "@mui/material";
import { useColors } from "../../Context/ColorsContext";
import { MediaQuerys } from '../../styles/GlobalStyle';

const { mobile, tablet, desktop, desktoplarge } = MediaQuerys;


export const Text = styled(Typography)(() => {
    const { colors } = useColors();
    return {
        color: colors.grayFont,
        fontSize: 18,
        fontWeight: 'bold',
        margin: 0,
        padding: 0,
        [desktop]: {
            fontSize: '0.95rem',
        },
        [tablet]: {
            fontSize: '1rem',
        }
    }
})

export const TextSwitch = styled(Typography)((props) => {
    const { colors } = useColors();
    return {
        fontSize: '12px',
        fontWeight: 600,
        color: colors.white,
        backgroundColor: `${props.enabled ? colors.green : colors.red}`,
        alignItems: 'center',
        textAlign: 'center',
        borderRadius: '5px',
        padding: '1px 4px',
        [desktop]: {
            fontSize: '0.75rem'
        }
    }
})


export const CustomSwitch = styled(Switch)(() => {
    const { colors } = useColors();
    return {
        "& .MuiSwitch-switchBase": {
            color: colors.disabled
        },
        "& .MuiSwitch-track": {
            backgroundColor: colors.disabled
        },
        "& .MuiSwitch-switchBase.Mui-disabled+.MuiSwitch-track": {
            color: 'red',
            backgroundColor: 'red',
        },
        "& .MuiSwitch-switchBase.Mui-checked": {
            color: 'green'
        },
        "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
            backgroundColor: 'green'
        },
        [desktop]: {
            width: '50px',
            marginRight: '5px'
        }
    }
})

export const UploadButton = styled(Button)(() => ({
    display: 'flex',
    justifyContent: 'center',
    background: 'none',
    [desktop]: {
        fontSize: '0.75rem',
    }
}))

export const Image = styled('img')(() => ({
    width: 400,
    height: 300,
    maxHeight: 500,
    maxWidth: 500,
}))

export const ImageContainer = styled('div')(() => ({
    // width: 400,
    width: '100%',
    height: 200,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    transition: 'opacity 0.4s ease-in',
    cursor: 'pointer',
}))

export const GridCenter = styled(Grid)(() => {
    const { colors } = useColors();
    return {
        width: '100%',
        height: '200px',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        background: colors.white,
        border: '1px solid #d8e0e5'
    }
})

export const SaveButton = styled(Button)(() => {
    const { colors } = useColors();
    return {
        height: 30,
        padding: '0 35px',
        color: colors.white,
        backgroundColor: colors.buttonActionColor,
        "&:hover": {
            backgroundColor: colors.buttonActionColor,
            opacity: 0.9,
            color: colors.white,
        },
        [desktop]: {
            fontSize: '0.75rem',
        },
        [mobile]: {
            padding: '0 10px',
        }
    }
})

export const ResetButton = styled(Button)(() => {
    const { colors } = useColors();
    return {
        height: 30,
        padding: '0 35px',
        backgroundColor: colors.background,
        color: colors.buttonActionColor,
        "&:hover": {
            backgroundColor: colors.buttonActionColor,
            opacity: 0.9,
            color: colors.white,
        },
        [desktop]: {
            fontSize: '0.75rem',
        },
        [mobile]: {
            padding: '0 10px',
        }
    }
})

export const ErrorText = styled('p')(() => {
    const { colors } = useColors();
    return {
        color: colors.red,
        fontSize: '1.5rem',
        fontWeight: 'bold',
        padding: '0 0 1rem 0',
        [tablet]: {
            fontSize: '1rem',
        }
    }
})

export const Container = styled('div')(() => {
    return {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    }
})

export const ContainerColors = styled('div')(() => {
    return {
        display: "flex",
        flexDirection: "row",
        justifyContent:'center',
        alignItems: 'center',
        [tablet]: {
            justifyContent:'space-between',
        }
    }
})

export const ContainerImages = styled('div')(() => {
    return {
        display: "flex",
        flexDirection: "column",
        width: '100%',
        maxWidth: '500px',
        height: '100%',
        padding: '0.55rem',
        margin: '0 auto',
        [tablet]: {
            width: '100%',
        },
        [mobile]: {
            width: '100%',
        }
    }
})

export const ContainerEachImage = styled('div')(() => {
    return {
        width: '50%',
        height: '100%',
        padding: '0.55rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        [tablet]: {
            padding: '0.40rem'
        }
    }
})


export const ContainerCardImage = styled('div')(() => {
    return {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
    }
})

export const ContainerButtonsDown = styled('div')(() => {
    return {
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'row',
        width: '100%',
        marginTop: '16px',
        gap: '3rem',
        [mobile]: {
            justifyContent: 'center'
        }
    }
})

export const ContainerContentColors = styled('div')(() => {
    return {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    }
})

export const CustomCardMedia = styled(CardMedia)(() => {
    return {
        height: '200px',
        // width: 'auto',
        objectPosition: 'center',
    }
})

export const CustomSpan = styled('span')(() => {
    return {
        fontSize: '1.1rem',
        [tablet]: {
            fontSize: '0.85rem',
        }
    }
})

export const CustomTextField = styled(TextField)(() => {
    return {
      [desktop]: {
        '.MuiInputBase-input': {
          fontSize: '0.85rem',
        },
        '.MuiInputLabel-root': {
          fontSize: '0.85rem',
        },
      }
    }
})

export const Section = styled(Typography)(() => {
    const { colors } = useColors();
    return {
        color: colors.blackFont,
        fontSize: 16,
        fontWeight: 'bold',
        margin: 0,
        paddingTop: '1.2rem',
        [desktop]: {
            fontSize: '0.95rem',
        },
        [tablet]: {
            fontSize: '0.85rem',
        }
    }
})

export const CustomSpanColors = styled('span')(( { alignCenter } ) => {
    const { colors } = useColors();
    return {
        color: colors.grayFont,
        fontSize: '14px',
        marginRight: '14px',
        textAlign: alignCenter ? 'center' : 'left',
        [tablet]: {
            fontSize: '0.75rem',
        }
    }
})

export const CustomChip = styled(Chip)(() => {
    const { colors } = useColors();
    return {
        marginTop: '0.5rem',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        border: `1px solid ${colors.buttonActionColor}`,
        color: colors.buttonActionColor
    }
}) 
