import { TextField } from '@mui/material';

import styled from 'styled-components';
import { Typography, Switch } from "@mui/material";
import { useColors } from "../../Context/ColorsContext"
import { MediaQuerys } from '../../styles/GlobalStyle';

const { mobile, tablet, desktop, desktoplarge } = MediaQuerys;

// const tabletStartWidth = 768;
// const mobile = `@media (max-width: ${tabletStartWidth}px)`


export const Text = styled(Typography)(() => {
    const { colors } = useColors();
    return {
        color: colors.red,
        fontSize: 16,
        fontWeight: 'bold',
        margin: 0,
        padding: 0,
    }
})

export const TextSwitch = styled(Typography)((props) => {
    const { colors } = useColors();
    return {
        fontSize: '12px',
        fontWeight: 600,
        color: colors.white,
        backgroundColor: `${props.enabled ? colors.green : colors.red}`,
        alignItems: 'center',
        textAlign: 'center',
        borderRadius: '5px',
        padding: '1px 4px',
        [mobile]: {
            fontSize: '0.65rem'
        },
        [desktop]: {
            fontSize: '0.75rem'
        }
    }
})


export const CustomSwitch = styled(Switch)(() => {
    const { colors } = useColors();
    return {
        "& .MuiSwitch-switchBase": {
            color: colors.disabled
        },
        "& .MuiSwitch-track": {
            backgroundColor: colors.disabled
        },
        "& .MuiSwitch-switchBase.Mui-disabled+.MuiSwitch-track": {
            color: 'red',
            backgroundColor: 'red',
        },
        "& .MuiSwitch-switchBase.Mui-checked": {
            color: 'green'
        },
        "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
            backgroundColor: 'green'
        },
         [desktop]: {
            fontSize: '0.75rem'
        }
    }
})

export const Container = styled('div')(() => {
    return {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    }
})

export const ContainerGroupOne = styled('div')(({ isDown }) => {
    return {
        marginTop: isDown && '16px',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    }
})

export const ContainerGroupTwo = styled('div')(() => {
    return {
        marginTop: '16px',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        gap: '16px',
        justifyContent: 'space-between',
        alignItems: 'center',
    }
})

export const CustomContainer = styled('div')(() => {
    return {
        width: '120px',
        height: '50px',
    }
})


export const ContainerGroupPar = styled('div')(() => {
    return {
        display: 'flex',
        flexDirection: 'row',
    }
})

export const CustomTextField = styled(TextField)(() => {
    return {
        width: '100%',
        height: '100%',
        scrollbarWidth: 'thin', // Ajusta el ancho del scroll (solo Firefox)
            '&::-webkit-scrollbar': {
            // Estilos del scroll horizontal (WebKit)
            width: '0.85rem', // Ajusta el ancho del scroll
        },
        [mobile]: {
            '& .MuiInputBase-input': {
                fontSize: '0.85rem'
            }
        },
        [desktop]: {
            '& .MuiInputBase-input': {
                fontSize: '0.85rem'
            }
        }

    }
})