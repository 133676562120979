import {Box, Grid} from "@mui/material"
import {styled} from "@mui/material/styles";
import { useColors } from "../../Context/ColorsContext"
import { MediaQuerys } from "../../styles/GlobalStyle";

const drawerWidth = 250;
const { mobile, tablet, desktop } = MediaQuerys;

export const LayoutContainer = styled(Box)(({isOpen}) => (() => {
    const { colors } = useColors(); 
    return {
        minWidth: `calc(100vw - ${isOpen ? drawerWidth : null}px)`,
        minHeight: '100vh',
        backgroundColor: colors.background,
    }
}))

export const Container = styled(Grid)(({theme}) => ({
    [theme.breakpoints.up('sm')]: {
        height: '100%'
    },
    [theme.breakpoints.up('md')]: {
        height: '100vh'
    },
}))

export const Content = styled('div')(({theme}) => ({
    minWidth: '100vw',
    margin: 0,
    padding: 0,
    [theme.breakpoints.up('md')]: {
        flexWrap: 'wrap',
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        boxSizing: 'border-box'
    },
}));

export const DrawerHeader = styled('div')(({theme}) => ({
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    height: 80
}));