import {styled} from "@mui/material/styles";
import { Grid } from "@mui/material";
import { useColors } from "../../Context/ColorsContext"

export const Content = styled(Grid)(() => {
    const { colors } = useColors();
    return {
        background: colors.gray,
        width: '100%',
        height: '100%'
    }
})
