import React, { useContext } from 'react'
import { t } from 'i18next'
import { Grid, Tooltip, Chip } from '@mui/material';

// Components
import { Notifications } from "@mui/icons-material";

// Styles
import { Text, Subtitle, ContainerGroupTop, ContainerLeft, CustomChip } from './styles'

// Utils
import { getIcon } from '../../../utils/iconConfig';
import { FormatDate } from '../../../utils/formatDate';

// Context
import { ColorsContext } from '../../../Context/ColorsContext';

// custom hook
import { useTranslateType } from '../../../Hooks/useTranslateType';

export const Notification = ({ data, showFromInstance }) => {

    const { format } = new FormatDate();
    const { colors } = useContext(ColorsContext);

    return (
        <Tooltip title={t('navbar.notifications.tooltip')} placement='right' disableHoverListener={data.isNotified === false} sx={{ position: 'relative' }}>
            <Grid container sx={{ maxWidth: showFromInstance ? '100%' : '385px' }}>
                <Grid item xs={2}>
                    <ContainerLeft>
                        {getIcon(data.workflowStatus, 35, undefined, true)}
                    </ContainerLeft>
                </Grid>

                <Grid item xs={10}>
                    <ContainerGroupTop sx={{ position: 'relative' }}>
                        <Grid container justifyContent="center" xs={8} alignItems="center">
                            <Grid item xs={12}>
                                <CustomChip
                                    label={<Text isCode={true}>{data.workflowData?.code}</Text>}
                                    size='small'
                                />
                            </Grid>
                        </Grid>

                        {!showFromInstance &&
                            <Grid item xs={4}>
                                <Grid container justifyContent="flex-end" alignItems="flex-start">
                                    <Grid item xs={12}>
                                        <Subtitle>{format({ date: data?.createdAt, format: true })}</Subtitle>
                                    </Grid>
                                    {/* {data.isNotified &&
                                        <Grid container item xs={12} sx={{ position: 'absolute', right: '20px', top: '15px', width: 0 }}>
                                            <Notifications color='warning' />
                                        </Grid>
                                    } */}
                                </Grid>
                            </Grid>
                        }
                    </ContainerGroupTop>

                    <Grid item xs={12}>
                        <Text>
                            <strong>{t('navbar.notifications.workflow')}:&nbsp;</strong>
                            {data.workflow?.name}
                        </Text>
                    </Grid>

                    <Grid item xs={12}>
                        <Text>
                            <strong>{t('navbar.notifications.type')}:&nbsp;</strong>
                            {useTranslateType(data.type)}
                        </Text>
                    </Grid>

                    <Grid item xs={12}>
                        <Text>
                            <strong>{t('navbar.notifications.step')}:&nbsp;</strong>
                            {data.workflowStatus?.title}&nbsp; {data.workflowStatus?.subtitle && ('(' + data.workflowStatus?.subtitle + ')')}
                        </Text>
                    </Grid>
                </Grid>
            </Grid>
        </Tooltip>
    )
}
