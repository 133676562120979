import React, { useEffect, useState, Fragment } from 'react';

// Components
import Dropzone from 'react-dropzone'
import { ShowDialog } from '../ShowDialog';
import { Grid, IconButton, Tooltip, Typography, Checkbox } from '@mui/material';
import { toast } from 'react-toastify';
import { BackdropLoading } from '../../components/BackdropLoading';

// Services
import { WorkflowDataFileApi } from '../../services/WorkflowDataFileApi';
import Router from '../../router';

// Icons
import { Cancel, Download } from '@mui/icons-material';

// Styles
import { Container, SectionFiles, CustomSpan, CustomTypography, ContainerLeft, Text, CustomGrid, StickyHeader, CustomControlLabel } from './styles';
import { CustomButton } from '../../pages/styles';

// Utils
import { ColorsContext } from "../../Context/ColorsContext"
import { CheckResponse } from '../../utils/checkResponse';
import { getIcon } from '../../utils/iconConfig';
import { UrlToFile } from '../../utils/urlToFile';
import { useTranslation } from 'react-i18next'
import { validateFiles, validateFormatFiles } from '../../utils/commonFunctions';

// Permissions
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../Context/PermissionsContext';

export const Attachments = (props) => {
    const { id, disabled, userData, statusData, setAttachments } = props
    const workflowDataFileApi = new WorkflowDataFileApi();
    const {check}= new CheckResponse();
    const { t } = useTranslation();
    const { colors } = React.useContext(ColorsContext);
    const ability = useAbility(AbilityContext);

    const [files, setFiles] = useState([])
    const [newFiles, setNewFiles] = useState([])
    const [toDelete, setToDelete] = useState({})
    const ACCEPTED_FILES = {
        'image/jpeg': [], 
        'image/png': [],
        'application/pdf': [],
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': []
    }
    const [loading, setLoading] = useState(false)
    const [dialog, setDialog] = useState({
        status: false,
        title: '',
        message: ''
    })

    useEffect(() => {
        async function call() {
            await callAttachments(id)
        }
        call()
    }, [])

    async function callAttachments(id) {
        setLoading(true)

        let response = await workflowDataFileApi.getFile(id);
        let result = check(response)

        if (result.status) {
            setFiles(response.data)
            if (typeof setAttachments === 'function') setAttachments(response.data)
        }
        else {
            if (result.errors) {
                toast.error(() => 
                    <div>
                        {result.errors}
                    </div>
                );
            }
        } 

        setLoading(false)
    }

    function deleteAttach(index, isDb) {
        let array = [...files]

        if (array[index]?.id && isDb) {
            deleteFromDb(array, index)
            return
        }

        if (array.length > 0) {
            array.splice(index, 1)
            setFiles(array)
            setNewFiles(array.filter((el) => !el.id))
        }
    }

    function deleteFromDb(array, index) {
        setToDelete(array[index])
        setDialog({
            status: true,
            title: t('files.messages.deleteFile.title'),
            message: t('files.messages.deleteFile.message', {file: array[index].route?.toString().split('?')[0].toString().split(/[\/\\]/).pop()}),
            type: 'confirmation',
            index: index
        })
    }

    async function saveFiles() {
        setLoading(true)
        let validFormat = validateFormatFiles(newFiles)
        if (!validFormat) {
            setLoading(false)
            return
        }
        
        let resultCheck = await validateFiles(newFiles)
        if (!resultCheck) {
          setLoading(false)
          return
        }

        setLoading(true)
    
        let formData = new FormData()
        formData.append('workflowDataId', id)
        formData.append('statusId', statusData?.id)
        newFiles?.map(file => { formData.append("files", file) })

        const types = newFiles?.map(file => {
            const dataFile = files.find(el => el.name === file.name);
            return {
                name: file.name,
                isProcedure: dataFile?.isProcedure ?? false
            };
        });
        formData.append('types', JSON.stringify(types));

        const response = await workflowDataFileApi.upload(formData);
        let result = check(response)

        if (result.status) {
            setDialog({
                status: true, isClose: true,
                title: t('files.messages.saveFile.title'),
                message: t('files.messages.saveFile.message')
            })
            await callAttachments(id)
        }
        else {
            toast.error(() => 
                <div>
                    {result.errors}
                </div>
            );
        } 
    
        setLoading(false)
    }
    
    async function deleteFile(file) {
        setLoading(true)

        let data = {
            fileId: file?.id,
            workflowDataId: file?.workflowDataId
        }

        const response = await workflowDataFileApi.delete(data);
        let result = check(response)

        if (result.status) {
            setDialog({
                status: true, isClose: true,
                title: t('files.messages.deleteFile.confirm'),
                message: t('files.messages.deleteFile.confirmMessage')
            })
            await callAttachments(id)
        }
        else {
            toast.error(() => 
                <div>
                    {result.errors}
                </div>
            );
        } 
    
        setLoading(false)
    }

    function onDrop(droppedFiles) {
        setFiles((prev) => [
            ...prev, 
            ...droppedFiles.map(file => ({
                name: file.name,
                isProcedure: false,
                path: file.path
            }))
        ]);
        setNewFiles(droppedFiles)
    };

    function updateProcedureState(index) {
        let array = [...files]

        if (array.length > 0 && array[index]) {
            array[index].isProcedure = !array[index].isProcedure;
            setFiles(array)
        }
    }

    function onDropRejected() {
        toast.warning(t('files.messages.errorFile.invalidFormat'))
    };

    async function handleCloseDialog(value) {
        setDialog({status: false})
        if (value) {
            await deleteFile(toDelete)
            setToDelete({})
        }
    }

    async function downloadFile(file) {
        let downloadFile = file

        if (file.id) {
            let urlToFile = new UrlToFile();
            let urlResource = file.route?.startsWith("http") ? `${(file.route)}` : `${Router.apiBaseUrl}/${(file.route)}`
            downloadFile = await urlToFile.convert(urlResource, null, false)
        }

        const url = window.URL.createObjectURL(new Blob([downloadFile]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file.name || file.route?.toString().split('?')[0].split(/[\/\\]/).pop());
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    }

    return (
        <Container>
            <Dropzone onDrop={onDrop} onDropRejected={onDropRejected} disabled={disabled} accept={ACCEPTED_FILES}>
                {({getRootProps, getInputProps}) => (
                    <section className="container">
                        {ability.can("UploadWorkflowDataFile") &&
                            <SectionFiles {...getRootProps({className: 'dropzone'})} disabled={disabled}>
                                <input {...getInputProps()} />
                                <CustomSpan>{t('files.filesDropDown')}</CustomSpan>
                            </SectionFiles>
                        }
                        <aside>
                            {(files.length > 0 && ability.can("GetWorkflowDataFile")) && 
                                <CustomGrid container sx={{ maxHeight: '150px', overflowY: 'auto' }}>
                                    <StickyHeader item xs={3}>{t('workflow.attachments.table.file')}</StickyHeader>
                                    <StickyHeader item xs={3}>{t('workflow.attachments.table.user')}</StickyHeader>
                                    <StickyHeader item xs={2}>{t('workflow.attachments.table.state')}</StickyHeader>
                                    <StickyHeader item xs={2}>{t('workflow.attachments.table.procedure')}</StickyHeader>
                                    <StickyHeader item xs={2}>{t('workflow.attachments.table.action')}</StickyHeader>
                                    {files.sort((a, b) => {
                                            if (!a.id && !b.id) {
                                                // Si ambos no tienen "id", ordenar por nombre
                                                return a.name.localeCompare(b.name);
                                            }
                                            // Ordenar archivos sin "id" antes que los que tienen "id"
                                            return a.id ? 1 : -1;
                                        }).map((file, index) => (
                                        <Fragment key={`attachment-${index}`}>
                                            <Grid container alignItems="center">
                                                <Grid item xs={3}>
                                                    <CustomTypography>{(file.route?.toString().split('?')[0].split(/[\/\\]/).pop() || file.name)}</CustomTypography>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <CustomTypography>{file?.user?.email || userData}</CustomTypography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <ContainerLeft>
                                                        {getIcon(file?.status || statusData, undefined, undefined, true)}
                                                        <CustomTypography>{file?.status?.title || statusData?.title || ''}</CustomTypography>
                                                    </ContainerLeft>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <CustomControlLabel label={file?.isProcedure ? 'Si' : 'No'}
                                                        control={
                                                            <Checkbox
                                                                checked={file.isProcedure || false}
                                                                sx={{
                                                                    color: colors.grayGraphic,
                                                                    '&.Mui-checked': {
                                                                        color: colors.buttonActionColor
                                                                    },
                                                                    '&.MuiCheckbox-indeterminate': {
                                                                        color: colors.buttonActionColor
                                                                    }
                                                                }}
                                                                onChange={(e) => updateProcedureState(index)}
                                                                disabled={file.id}
                                                            />
                                                        } 
                                                    />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <div style={{width: '100%', textAlign: 'center'}}>
                                                        <Tooltip title={t('common.download')}>
                                                            <IconButton onClick={() => downloadFile(file)}>
                                                                <Download sx={{ color: colors.buttonActionColor }}/>
                                                            </IconButton>
                                                        </Tooltip>
                                                        {((file?.path !== undefined || (file.workflowDataId !== null && file.workflowFormDataId === null)) && (file?.id === undefined || (file?.id !== undefined && file.canDelete && !file.isProcedure)) && ability.can("DeleteWorkflowDataFile")) && 
                                                            <Tooltip title={t('files.remove')}>
                                                                <span>
                                                                    <IconButton onClick={() => deleteAttach(index, !!file.id)} disabled={disabled}>
                                                                        <Cancel sx={{ color: colors.red }}/>
                                                                    </IconButton>
                                                                </span>
                                                            </Tooltip>
                                                        }
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Fragment>
                                    ))}
                                </CustomGrid>
                            }
                        </aside>
                    </section>
                )}
            </Dropzone>
            {ability.can("UploadWorkflowDataFile") &&
                <Grid container justifyContent='center' alignItems='center'>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={8}>
                        <CustomButton disabled={disabled || loading} variant='contained' type="accept" sx={{ width: '100%', marginTop: 2 }} onClick={saveFiles}>
                            {t('files.save')}
                        </CustomButton>
                    </Grid>
                    <Grid item xs={2}></Grid>
                </Grid>
            }
            <ShowDialog openDialog={dialog.status} dialog={dialog} handleCloseDialog={handleCloseDialog}/>
            <BackdropLoading open={loading}/>
        </Container>
    )
}