export const snakeToCamel = (str) => {
    if (!str) return ''
    return str.toLowerCase().replace(/([-_][a-z])/g, group =>
        group
            .toUpperCase()
            .replace('-', '')
            .replace('_', '')
    )
}

export const arrayToString = (array, key, allitems) => {
    if (!array?.length) return ''
    let newArray = key ? array.map((el) => el?.[key]) : array
    if(allitems){
        return newArray.join(', ');
    }else {
      if(key){
        // delete the first element from the array
        newArray = newArray.slice(1)
      }
      return newArray.join(', ')
    }
}