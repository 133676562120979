import React, { useEffect } from "react";

// Components
import { Grid, Typography } from "@mui/material";

// Styles
import { BoxContainer, FooterContainer, ImageContainer } from "./styles";

// Utils
import { useTranslation } from "react-i18next";

export default function Footer({ open }) {
  const { t } = useTranslation();

  useEffect(() => {}, [open]);

  return (
    <BoxContainer open={open}>
      <FooterContainer container open={open} spacing={1} id="FooterContainer">
        <Grid sx={{ textAlign: "right" }}>
          <Typography>{t("footer.poweredBy")}</Typography>
        </Grid>
        <Grid sx={{ textAlign: "left", marginLeft: '8px' }}>
          <ImageContainer src={require("../../assets/images/SE-logo.png")} />
        </Grid>
      </FooterContainer>
    </BoxContainer>
  );
}
