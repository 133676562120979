import React from 'react'

// Components
import { TextField } from '@mui/material'

// Custom components
import { SelectInput } from '../../../SelectInput'
import { SelectContracts } from '../../../SelectContracts'

// Icons

// Styles
import { Label, ContainerInput, ContainerGroup, ContainerContent, ContainerBox } from './styles'

// Utils
import { useTranslation } from 'react-i18next'

// Permissions
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../../../Context/PermissionsContext'

export const Contract = ({
    form, dataForm, errors, plants, documents, companyList, categories, disabled, handleChange
}) => {
    const { t } = useTranslation();
    const ability = useAbility(AbilityContext);

    return (
        <ContainerBox>
            <ContainerContent>
                <ContainerGroup isFirsts={true}>
                    {ability.can("GetContractDocument") &&
                        <ContainerInput>
                            <Label>{t('contract.table.contractDocumentType')}:</Label>
                            <SelectInput
                                name='contractDocument'
                                onChange={e => handleChange(e, 'contractDocument', 'select')}
                                value={form.contractDocument}
                                keyValue="name"
                                options={documents}
                                error={!(!errors?.contractDocument.error)}
                                helperText={errors?.contractDocument.error}
                                size="small"
                                width="100%"
                                disabled={disabled}
                            />
                        </ContainerInput>
                    }

                    <ContainerInput>
                        <Label>{t('contract.table.code')}:</Label>
                        <TextField
                            value={form.code}
                            size="small"
                            onChange={e => {
                                if (e.target.value?.length <= 10 && e.target.value >= 0) {
                                    handleChange(e, 'code')
                                }
                            }}
                            error={!(!errors?.code.error)}
                            helperText={errors?.code.error}
                            style={{ width: '100%' }}
                            disabled={disabled}
                            type="number"
                            onKeyDown={(e) => {
                                if (
                                    e?.key === "e" || 
                                    e?.key === "E" || 
                                    e?.key === "-" || 
                                    e?.key === "+" || 
                                    e?.key === "." || 
                                    e?.key === ","
                                ) {
                                  e.preventDefault()
                                }
                            }}
                            required
                        />

                    </ContainerInput>
                </ContainerGroup>

                {ability.can("AllCompanies") &&
                    <ContainerGroup>
                        <ContainerInput isFull>
                            <Label>{t('contract.table.company')}:</Label>
                            <SelectInput
                                name='company'
                                onChange={e => handleChange(e, 'company', 'select')}
                                value={form.company}
                                keyValue="provider"
                                options={companyList}
                                error={!(!errors?.company.error)}
                                helperText={errors?.company.error}
                                size="small"
                                width="100%"
                                disabled={disabled}
                            />
                        </ContainerInput>
                    </ContainerGroup>
                }


                <ContainerGroup>
                    <ContainerInput isFull>
                        <Label>{t('contract.table.object')}:</Label>
                        <TextField
                            value={form.object}
                            size="small"
                            onChange={e => handleChange(e, 'object')}
                            error={!(!errors?.object.error)}
                            helperText={errors?.object.error}
                            style={{ width: '100%' }}
                            disabled={disabled}
                            required
                        />
                    </ContainerInput>
                </ContainerGroup>

                <ContainerGroup>
                    <ContainerInput>
                        <Label>{t('contract.table.validSince')}:</Label>
                        <TextField
                            value={form.validSince}
                            size="small"
                            type="date"
                            onChange={e => handleChange(e, 'validSince')}
                            error={!(!errors?.validSince.error)}
                            helperText={errors?.validSince.error}
                            style={{ width: '100%' }}
                            required
                        />
                    </ContainerInput>

                    <ContainerInput>
                        <Label>{t('contract.table.validUntil')}:</Label>
                        <TextField
                            value={form.validUntil}
                            size="small"
                            type="date"
                            onChange={e => handleChange(e, 'validUntil')}
                            error={!(!errors?.validUntil.error)}
                            helperText={errors?.validUntil.error}
                            style={{ width: '100%' }}
                            required
                        />
                    </ContainerInput>
                </ContainerGroup>

                <ContainerGroup>
                    {/* <ContainerInput>
                        <Label>{t('contract.table.technicalSupervisor')}:</Label>
                        <SelectInput
                            name='technicalSupervisor'
                            onChange={e => handleChange(e, 'technicalSupervisor', 'select')}
                            value={form.technicalSupervisor}
                            keyValue="fullname"
                            options={technicalSupervisors}
                            error={errors?.technicalSupervisor.error}
                            size="small"
                            width="100%"
                            disabled={disabled}
                        />
                    </ContainerInput> */}
                    
                    {ability.can("PlantList") &&
                        <ContainerInput isFull>
                            <Label>{t('contract.table.scope')}:</Label>
                            <SelectContracts
                                name='plants'
                                placeholder=""
                                onChange={e => handleChange(e, 'plants', 'select')}
                                value={form.plants}
                                categories={categories}
                                selectedPlants={dataForm.plants}
                                keyValue="name"
                                options={plants}
                                width="100%"
                                size="small"
                                multiple={true}
                                hasError={!(!errors?.plants.error)}
                                messageError={errors?.plants.error}
                                disabled={disabled}
                                disableCloseOnSelect={false}
                                limitTags={10}
                            />
                        </ContainerInput>
                    }
                </ContainerGroup>


            </ContainerContent>
        </ContainerBox>
    )
}