import { styled } from '@mui/material/styles';
import { FormControl, InputLabel, Badge, Select, MenuItem, Card, Checkbox } from '@mui/material';
import { useColors } from '../../Context/ColorsContext';

import { MediaQuerys } from '../../styles/GlobalStyle';

const { mobile, tablet, desktop } = MediaQuerys;


export const Container = styled('div')(() => {
    return {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
    }
})

export const ContainerTitle = styled('div')(({ marginLeft }) => {
    return {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        marginLeft: marginLeft ? marginLeft : null,
    }
})

export const ContainerPageTitle = styled('div')(() => {
    return {
        display: 'flex',
        flexDirection: 'row',
        gap: '1rem'
    }
});

export const ContainerFilters = styled('div')(() => {
    return {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        gap: '1rem',
    }
});

export const ContainerTopGrahps = styled('div')(() => {
    return {
        display: 'flex',
        flexDirection: 'row',
        gap: '0.80rem',
        height: '50%',
        width: 'inherit',
    }
});

export const ContainerBottomGrahps = styled('div')(() => {
    return {
        display: 'flex',
        flexDirection: 'row',
        gap: '0.80rem',
        height: '335px',
        width: 'inherit',
    }
});


export const ContainerGraphics = styled(Card)(({ width, height }) => {
    return {
        display: 'flex',
        flexDirection: 'column',
        height: height ? height : '335px',
        width: width,
        padding: '10px',
        overflowX: 'auto',
        '&::-webkit-scrollbar': {
            height: '5px',
        },
        '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
        } 
    }
});

export const ContainerTreeMap = styled('div')(() => {
    return {
        width: '25%',
        display: 'flex',
        flexDirection: 'column',
        gap: '0.80rem',
    }
});

export const ContainerOptionsGraphics = styled('div')(() => {
    return {
        width: '100%',
        flexWrap: 'wrap',
        gap: '0.80rem',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'row',
        margin: '10px 0',
    }
});

export const ContainerOption = styled('div')(() => {
    return {
        display: 'flex',
        alignItems: 'center',
        gap: '0.50rem',
        flexDirection: 'row',
    }
});

export const OptionTitle = styled('span')(() => {
    return {
        fontSize: '0.80rem',
        fontWeight: 'bold',
    }
})

export const OptionColor = styled('div')(({ customColor }) => {
    return {
        borderRadius: '2px',
        width: '13px',
        height: '13px',
        background: customColor,
    }
});

export const CustomCheckbox = styled(Checkbox)(({ customColor }) => ({
    '&.Mui-checked': {
        color: customColor, // Oculta el ícono de verificación
        // backgroundColor: customColor, // Cambia el fondo cuando está chequeado
      },
      '&.MuiCheckbox-root': {
        // color: 'transparent',
        // backgroundColor: 'transparent', // Color por defecto cuando no está chequeado
      },
      '& .MuiSvgIcon-root': {
        color: 'transparent',
        // display: 'none', // Oculta el ícono de verificación
      },
}));


export const ContainerValidate = styled('div')(() => {
    return {
        height: '60vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: '1rem',
    }
})

export const CustomImg = styled('img')(() => {
    return {
        width: '800px',
        [desktop]: {
            width: '600px',
        }       
    }
})


export const CustomFormControl = styled(FormControl)(() => {
    return {
        width: "150px",
        [desktop]: {
            width: "110px",
            "& .MuiFormLabel-root": {
                fontSize: "0.75rem",
            },
        },
    };
});

export const CustomInputLabel = styled(InputLabel)(() => {
    return {
        fontSize: "0.8em",
        position: "absolute",
        top: "50%",
        left: "40%",
        transform: "translate(-50%, -50%)",
        [tablet]: {
            fontSize: "0.75rem",
        }
    };
});

export const CustomBadge = styled(Badge)(() => {
    return {
        color: "white",
        [desktop]: {
            "& .MuiBadge-badge": {
                minWidth: "15px !important",
                width: "15px !important",
                height: "15px !important",
            },
            fontSize: "0.75rem",
        },
    };
});

export const CustomSelect = styled(Select)(() => {
    return {
        width: "150px",
        height: "32px",
        [desktop]: {
            width: "110px",
        },
        [tablet]: {
            width: "120px",
            height: 26,
        },
    };
});

export const CustomMenuItem = styled(MenuItem)(() => {
    return {
        [desktop]: {
            "& .MuiTypography-root": {
                fontSize: "0.75rem",
            },
        },
    };
});
