import React from "react";
import { useColors } from "../Context/ColorsContext";

const withColors = (WrappedComponent) => {
  return (props) => {
    const { colors } = useColors();
    return <WrappedComponent colors={colors} {...props} />;
  };
};

export default withColors;
