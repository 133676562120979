import {styled} from "@mui/material/styles";
import { Card, CardMedia, Grid, Typography } from "@mui/material";
import { useColors } from "../../Context/ColorsContext"
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { MediaQuerys } from "../../styles/GlobalStyle";

const { mobile, tablet, desktop } = MediaQuerys;

export const Container = styled('div')(({modal, isThereImg}) => ({
    width: isThereImg ? 'fit-content' : '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    // width: modal ? '755px' : '100%',
    height: 'inherit',
    position: 'relative',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    transition: 'width 2s',
    maxHeight: 'inherit',
    maxWidth: 'inherit',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 1%), 0px 1px 1px 0px rgb(0 0 0 / 1%), 0px 1px 3px 0px rgb(0 0 0 / 12%);',
    '&::-webkit-scrollbar': {
        width: 5,
        height: 5
    },
    // '@media only screen and (min-width: 1200px)': {
    //     height: '350px'
    // },
    // '@media only screen and (min-width: 1400px)': {
    //     height: '500px'
    // },
    // [desktop]: {
    //     height: '350px'
    // },
    // [tablet]: {
    //     height: '350px',
    //     width: '545px',
    // }
 
}))

export const CardBackground = styled(CardMedia)(() => ({
    height: '100%', 
    backgroundSize: 'cover',
    objectFit: 'fill',
    position:'relative',
    width: '100%',
    maxHeight: 'inherit',
    maxWidth: 'inherit',
}))

export const IconArea = styled('div')(() => {
    const { colors } = useColors();
    return {
        background: colors.orange,
        color: colors.white,
        width: 30,
        height: 30,
        fontSize: 16,
        fontWeight: 'bold',
        borderRadius: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '2px solid #73480e',
        [desktop]: {
           height: 20,
           width: 20,
           fontSize: '0.80rem',
        }
    }
})

export const Area = styled('div')(({top, left, zIndex}) => {
    const { colors } = useColors();
    return {
        cursor: 'pointer',
        position: 'absolute',
        color: colors.white,
        top: `${top}%`,
        left: `${left}%`,
        zIndex: zIndex,
        transform: 'translate(-50%, -50%)'
    }
})

export const ArrowLeft = styled(KeyboardArrowLeftIcon)(({disabled}) => {
    const { colors } = useColors();
    return {
        color: colors.white,
        background: disabled ? colors.grayFont : colors.buttonActionColor,
        borderRadius: 20,
        cursor: disabled ? 'default' : 'pointer'
    }
})

export const ArrowsLeft = styled(KeyboardDoubleArrowLeftIcon)(({disabled}) => {
    const { colors } = useColors();
    return {
        color: colors.white,
        background: disabled ? colors.grayFont : colors.buttonActionColor,
        borderRadius: 20,
        cursor: disabled ? 'default' : 'pointer'
    }
})

export const GridContainer = styled(Grid)(() => {
    const { colors } = useColors();
    return {
        padding: '4px 10px 0px 10px',
        color: colors.grayFont
    }
})

export const GridCenter = styled(Grid)(() => {
    const { colors } = useColors();
    return {
        textAlign: 'center',
        height: '100%',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'center',
        background: colors.white,
        
        '@media only screen and (min-width: 1200px)': {
            height: '350px'
        },
        '@media only screen and (min-width: 1400px)': {
            height: '500px'
        },
    }
})

export const Title = styled(Typography)(() => {
    return {
        [desktop]: {
            fontSize: '0.75rem'
        },
        [tablet]: {
            fontSize: '0.75rem'
        }
    }
})

export const ContainerBottom = styled('div')(() => {
    return {
        display: 'flex',
        padding: '5px 10px 5px',
        justifyContent: 'space-between',
        alignItems: 'center'
    }
})

export const ContainerGroup = styled('div')(() => {
    return {
        display: 'flex',
        flexDirection: 'row',
        gap: '2rem',
        [tablet]: {
            gap: '1rem',
        }
    }
})

export const ContainerLeft = styled('div')(() => {
    return {
        display: 'flex',
    }
})

export const ContainerRight = styled('div')(() => {
    return {
        display: 'flex',
    }
})

export const ContainerTypography = styled('div')(() => {
    return {
        display: 'flex',
    }
})

export const CustomCard = styled(Card)(() => {
    return {
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        [tablet]: {
            width: '545px',
        }
    }
})

export const GroupImage = styled(Card)(({ modal }) => {
    return {
    maxHeight: '500px',
    width: modal ? '755px' : '100%',
    '&::-webkit-scrollbar': {
        width: 5,
        height: 5
    },
    '@media only screen and (min-width: 1400px)': {
        // height: '500px'
        // maxheight: '500px',
    },
    [desktop]: {
        maxHeight: '350px'
    },
    [tablet]: {
        maxHeight: '350px',
        width: '545px',
    }
    }
})