import React from 'react';
import { Grid, Icon } from '@mui/material';
import { Content, Title, } from './styles';
import { ColorsContext } from "../../../Context/ColorsContext"

export const WorkPermitCardArea = ({data, area, index, isDetail, selectArea, selectedItem}) => {

    const { colors } = React.useContext(ColorsContext);

    return (
        <>
            {data.map((row, indexChild) => (
                <Content 
                    key={"work-permit-" + indexChild} 
                    isdetail={isDetail}
                    isSelected={area.element === selectedItem.elementNode && row.workflow.id === selectedItem.workflowId}
                    onClick={() => selectArea(row, index, indexChild, area)}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={2} sx={{ margin: 'auto' }}>
                            <Icon sx={{ color: colors.buttonActionColor, fontSize: 20 }}>{row.workflow?.icon}</Icon>
                        </Grid>
                        <Grid item xs={8}>
                            <Title>{row.workflow?.name || ''}</Title>
                        </Grid>
                        <Grid item xs={2} textAlign="center">
                            <Title>{row.count || 0}</Title>
                        </Grid>
                    </Grid>
                </Content>
            ))}
            
        </>
    )
}

