import { styled } from '@mui/material/styles';
import { FormControl, InputLabel, Badge, Select, MenuItem, Card, Checkbox } from '@mui/material';
import { useColors } from '../../Context/ColorsContext';

import { MediaQuerys } from '../../styles/GlobalStyle';

const { mobile, tablet, desktop } = MediaQuerys;



export const ContainerTopGrahps = styled('div')(() => {
    return {
        display: 'flex',
        flexDirection: 'row',
        gap: '0.80rem',
        height: '50%',
        width: 'inherit',
    }
});

export const ContainerGraphics = styled(Card)(({ width, height }) => {
    return {
        display: 'flex',
        flexDirection: 'column',
        height: height ? height : '335px',
        width: width,
        padding: '10px',
        overflowX: 'auto !important',
        '&::-webkit-scrollbar': {
            height: '5px',
        },
        '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
        } 
    }
});

export const ContainerBottomGrahps = styled('div')(() => {
    return {
        display: 'flex',
        flexDirection: 'row',
        gap: '0.80rem',
        height: '335px',
        width: 'inherit',
    }
});

export const ContainerTreeMap = styled('div')(() => {
    return {
        width: '25%',
        display: 'flex',
        flexDirection: 'column',
        gap: '0.80rem',
    }
});



