import { styled } from "@mui/material/styles";
import { Box, Button, Card, Grid, IconButton, FormControlLabel } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import {useColors} from "../Context/ColorsContext";
import { MediaQuerys } from "../styles/GlobalStyle";
import { PictureAsPdf } from "@mui/icons-material";
import DownloadIcon from '@mui/icons-material/Download';

const drawerWidth = 320;
const { mobile, tablet, desktop, desktoplarge } = MediaQuerys;

function setSize(open, isExternal, customWidth) {
  if (customWidth) return customWidth;
  if (isExternal) return "100%";
  return open ? `calc(100vw - ${drawerWidth}px)` : `calc(100vw - ${160}px)`;
}

export const Container = styled(Box)(
  ({ theme, open, isExternal, customWidth, isModal }) => ({
    [theme.breakpoints.up("md")]: {
      width: setSize(open, isExternal, customWidth),
    },
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginTop: 8,
    marginBottom: isModal ? 8 : 50,
    flexGrow: 1,
  })
);

export const ContainerCard = styled(Card)(() => {
    const { colors } = useColors();
    return {
        backgroundColor: colors.white,
        borderRadius: 6,
        padding: 20
    }
})

export const Search = styled(SearchIcon)(() => {
    const { colors } = useColors();
    return {
        width: 20,
        height: 20,
        cursor: 'pointer',
        color: colors.grayFont
    }
})

export const GridScroll = styled(Grid)((props) => ({
  "&::-webkit-scrollbar": {
    width: 5,
  },
  "&::-webkit-scrollbar-track": {
    background: "#F3F2F2",
    borderRadius: 100,
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#CBCBCB",
    borderRadius: 100,
  },
  scrollbarWidth: "thin",
  scrollbarColor: "#CBCBCB #F3F2F2",
  marginTop: 10,
  padding: 0,
  overflow: "auto",
  height: `${props.normal ? "auto" : "595px"}`,
  [tablet]: {
    height: `${props.normal ? '876px' : (props.childVoid ? 'auto' : '460px')}`
  },
  [mobile]: {
    height:  `${props.isStandard ? '300px' : (props.normal ? 'auto' : props.childVoid && 'auto')}`
  },
}));

export const GridContainer = styled(Grid)(() => {
  return {
    flexDirection: 'column',
    marginBottom: 2,
    ":not(:last-child)": {
      marginBottom: 16,
    },
    [tablet]: {
      display: "flex",
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '0.50rem',
    },
    [mobile]: {
      // flexDirection: 'row',
    }
  }
});

export const EmptyContainer = styled(Grid)(({ childVoid }) => {
    const { colors } = useColors();
    return {
        display: 'flex',
        alignItems: 'center',
        height: 'auto',
        width: '100%',
        marginTop: 20,
        textAlign: 'center',
        justifyContent: 'center',
        fontSize: 12,
        fontWeight: 'bold',
        color: colors.grayFont,
        backgroundColor: 'white',
        [tablet]: {
          // height: childVoid && 'auto'
        },
        [mobile]: {
          height: '170px',
          marginTop: 0,
        }
    }
})

export const CustomButton = styled(Button)(({type}) => {
    const { colors } = useColors();
    return {
        background: colors.buttonActionColor,
        color: colors.white,
        marginBottom: 6,
        bottom: 0,
        right: 0,
        textTransform: 'capitalize',
        fontSize: 13,
        "&:hover": {
            backgroundColor: colors.buttonActionColor,
            opacity: 0.9,
            color: colors.white,
        }
    }
})

function buttonType(type) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { colors } = useColors();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    if (type === 'cancel') return colors.white
    // eslint-disable-next-line react-hooks/rules-of-hooks
    if (type === 'close') return colors.white
    // eslint-disable-next-line react-hooks/rules-of-hooks
    if (type === 'delete') return colors.red
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return colors.buttonActionColor
}
//?? FORM BUTTON
export const FormButton = styled(Button)(({type, btnCancel, isUpdateStatus}) => {
    const { colors } = useColors();
    return{
        display: btnCancel === false || isUpdateStatus ? 'none' : null,
        background: buttonType(type),
        height: 28, 
        padding: '0 35px',
        color: `${type !== 'cancel' && type !== 'close' ? colors.white : colors.buttonActionColor}`,
        borderColor: colors.buttonActionColor,
        "&:hover": {
            background: buttonType(type),
            borderColor: colors.buttonActionColor,
            opacity: 0.8
        },
        [desktop]: {
          height: '25px',
          fontSize: '0.85rem',
        }
    }
})

export const CustomIconButton = styled(IconButton)(({iconColor}) => {
    const { colors } = useColors();
    return {
        borderRadius: '10%',
        backgroundColor: colors.background,
        color: iconColor ? colors[iconColor] : colors.buttonActionColor,
        "&:hover": {
            backgroundColor: iconColor ? colors.background : colors.buttonActionColor,
            opacity: 0.9,
            color: colors.background,
        }
    }
})

export const SearchButton = styled(Button)(({ ButtonModal, headerTable, isStandard, management, isMobile }) => {
    const { colors } = useColors();
    return {
        height: 28, 
        padding: '0 35px', 
        color: colors.white,
        backgroundColor: colors.buttonActionColor,
        "&:hover": {
            backgroundColor: colors.buttonActionColor,
            opacity: 0.9,
            color: colors.white,
        },
        [desktop]: {
          width: management && "140px",
          fontSize: isStandard ? '0.80rem' : "0.60rem",
          padding: '0 20px',
        },
        [tablet]: {
          display: isMobile && 'none',
          height: isStandard ? '28px' : '20px',
          fontSize: ButtonModal ? '0.875rem' : ( isStandard ? '0.80rem' : '8px'),
        },
        [mobile]: {
          display: isStandard || headerTable,
          height: isStandard && '25px',
        },
    }
})

export const ClearIconButton = styled(IconButton)(({ isFirstClearButton }) => {
    const { colors } = useColors();
    return {
      display: isFirstClearButton ? "none" : "",
      borderRadius: 20, 
      pointerEvents: 'pointer', 
      background: colors.buttonActionColor,
      color: colors.white,
      marginLeft: 5,
      "&:hover": {
          backgroundColor: colors.buttonActionColor,
          opacity: 0.9,
          color: colors.white,
      },
      [desktop]: {
        fontSize: "0.25rem",
      },
      [tablet]: {
        display: "inline-flex",
        height: 20,
        width: 20,
      },
      // [mobile]: {
      //   display: "inline-flex",
      // },
    }
})

export const BoxModal = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
}));

export const LanguageContainer = styled("div")(() => ({
  width: "100%",
  textAlign: "right",
  alignItems: "end",
  padding: "6px 6px 0 0",
}));


//TODO: CONTENEDORES BAR SEARCH GLOBALES 

export const ContainerBarSearch = styled("div")(() => ({
  width: 500,
  height: 40,
  [desktop]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "400px",
    height: "",
    "& input": {
      fontSize: "0.75rem",
    },
  },
  [tablet]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "320px",
    height: "25px",
    "& input": {
      fontSize: "0.65rem",
    },
  },
}));

export const ContainersButtonsGroup = styled('div')(({ twoContainers }) => ({
  display: "flex",
  flexDirection: 'row',
  gap: '0.7rem',
  [desktop]: {
    marginTop: '1rem'
  },
  [mobile]: {
    justifyContent: twoContainers ? 'space-between' : 'flex-end',
    flexDirection: twoContainers && 'row-reverse',
  }
}))

export const ContainerButtonDownload = styled("div")(( { widthBtn } ) => ({
  width: widthBtn || 140,
  height: 40,
  [desktop]: {
    width: "110px",
    height: "30px",
    "& button": {
      fontSize: "0.65rem",
    },
  },
  [tablet]: {
    width: "100px",
    height: 28,
    "& button": {
      fontSize: "8px",
    },
  },
}));

export const SectionPageTitle = styled("section")(() => ({
  display: "flex",
  height: "auto",
  width: "100%",
  [desktoplarge]: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  [mobile]: {
    flexDirection: "column",
    gap: '10px'
  }
}));

export const SectionPageLeft = styled("div")(() => ({
  height: "auto",
  width: "auto",
  display: "flex",
  flexDirection: "row",
  gap: "1rem",
  [mobile]: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export const Forms = styled('form')(({ isManagement }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'flex-start',
  justifyContent: isManagement ? 'space-between' : 'flex-end',
  [tablet]: {
    justifyContent: isManagement ? 'space-between' : 'flex-end',
  },
  [mobile]: {
    height: 'auto',
    // marginTop: '5px',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },
  [desktop]: {
    // justifyContent: 'start',
    // alignItems: 'center',
}
}))


export const ContainerGroupBar = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row'
}))


export const IconsButtonsTable = styled(IconButton)(({ needMargin }) => {
  const { colors } = useColors();
  return {
    borderRadius: "10%",
    margin: needMargin ? "0 .5px" : 0,
    backgroundColor: colors.background,
    color: colors.buttonActionColor,
    "&:hover": {
      backgroundColor: colors.buttonActionColor,
      color: colors.white,
    },
  }
})

export const PdfIconButton = styled(PictureAsPdf)(() => {
    const { colors } = useColors();
    return {
        cursor: 'pointer',
        fontSize: 30,
        color: colors.red,
        "&:hover": {
            opacity: 0.9,
        }
    }
})

export const CustomFormControlLabel = styled(FormControlLabel)(() => {
  return {
    [desktop]: {
      '.MuiTypography-root': {
        fontSize: '0.85rem'
      }
    }
  }
})

export const PdfDownloadIcon = styled(DownloadIcon)(() => {
  const { colors } = useColors();
  return {
      fontSize: 50,
      color: colors.buttonActionColor,
      padding: '.5rem',
      backgroundColor: colors.background,
      borderRadius: "10%",
      margin: '0 .5rem',
      "&:hover": {
          backgroundColor: colors.buttonActionColor,
          opacity: 0.9,
          color: colors.background,
          cursor: 'pointer'
      }
  }
})