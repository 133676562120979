import {styled} from "@mui/material/styles";
import { Card, TableCell, Typography, TableContainer } from "@mui/material";
import { useColors } from "../../../../Context/ColorsContext"
import TableBody from "@mui/material/TableBody";
import { MediaQuerys } from "../../../../styles/GlobalStyle";
import { red } from "@mui/material/colors";

const { mobile, tablet, desktop, desktoplarge } = MediaQuerys;


function setColor(code) {
  if (code === '1') return colors.purple
  if (code === '2') return colors.blue
  if (code === '3') return colors.red
}

export const CustomTableCell = styled(TableCell)(() => {
  const { colors } = useColors();
  return {
    color:colors.detailTextColor,
    fontSize:'1rem',
    fontWeight:600,
    paddingTop:'10px !important',
    paddingBottom:'10px !important',
    alignItems:'center',
    [desktop]: {
      fontSize:'0.75rem !important',
    }
  }
})

export const Text = styled(Typography)((props) => {
  const { colors } = useColors();
  return {
    fontSize: '12px',
    fontWeight: 600,
    color: colors.white,
    backgroundColor: `${props.enabled ? colors.green : colors.red}`,
    alignItems: 'center',
    textAlign: 'center',
    borderRadius: '5px',
    padding: '.5px 1px',
    [desktop]: {
      fontSize: '0.75rem'
    }
  }
})

export const TableCellStatus = styled(TableCell)(() => ({
    display:'flex',
    alignItems: 'center'

}))
export const ContainerCell = styled('div')(() => ({
    display:'flex',
    alignItems:'center',
}))

export const Figure = styled('figure')(({code}) => ({
  display:'flex',
  justifyContent:'center',
  alignItems:'center',
  background: code,
  height:'30px',
  width:'30px',
  minWidth:'30px',
  borderRadius:'50%',
  marginRight:'10px !important',
}))

export const SectionInfo = styled('section')(() => ({
    display:'flex',
    flexDirection:'column'
}))

export const InfoCell = styled('div')(() => ({
}))

export const Label = styled('label')(() => ({
  fontSize:'14px',
  paddingRight:'5px'
}))

export const InputDate = styled('input')(({theme}) => {
  const { colors } = useColors();
  return {
    marginRight:'20px',
    borderBottom:'1px solid '+colors.grayFont,
    borderRadius:'3px',
    fontFamily: theme.typography.fontFamily,
    padding:'5px 10px',
    width:'150px',
  }
})

export const FigureHead = styled('figure')(() => ({
  display:'flex'
}))

export const TableContent = styled(TableBody)(() => ({
  ".MuiTableCell-root": {
    fontSize: "1rem !important",
    [desktop]: {
      fontSize: "0.75rem !important",
    },
    [mobile]: {
      fontSize: "0.70rem !important",
    },
  },
  [tablet]: {
    ".MuiTablePagination-displayedRows": {
      fontSize: "0.75rem !important",
    },
    ".MuiTablePagination-selectLabel": {
      fontSize: "0.75rem!important",
    },
  },

}));

export const GroupAction = styled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.50rem',
  }
})

export const ContainerGroup = styled('div')(() => {
  return {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexDirection: 'row',
      gap: '0.75rem'
  }
})

export const IconArea = styled('div')(() => {
  const { colors } = useColors();
  return {
      width: 15,
      height: 15,
      fontSize: 10,
      fontWeight: 'bold',
      color: colors.white,
      background: colors.buttonActionColor,
      borderRadius: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: 'auto',
      [desktop]: {
          height: 15,
          width: 15
      }
  }
})

export const CustomTyphography = styled('span')(() => {
  return {
      [desktop]: {
          // fontSize: '0.65rem',
      }
  }
})

export const CustomTableContainer = styled(TableContainer)(() => {
  return {
    height: 'calc(100vh - 340px)'
  }
})