import {styled} from "@mui/material/styles";
import { Grid, IconButton } from '@mui/material';
import { useColors } from "../../../../Context/ColorsContext";

export const CustomGrid = styled(Grid)(() => {
    return {
        fontSize: 14,
        border: '1px solid #ccc',
        '> .MuiGrid-item': {
            padding: 4,
            paddingLeft: 6,
            border: '1px solid #ccc',
        }
    }
})

export const RemoveIconButton = styled(IconButton)(() => {
    const { colors } = useColors();
    return {
        borderRadius: '10%',
        backgroundColor: colors.background,
        color: colors.buttonActionColor,
        padding: 2,
        "&:hover": {
            backgroundColor: colors.buttonActionColor,
            opacity: 0.8,
            color: colors.background
        }
    }
})

export const StickyHeader = styled(Grid)(() => {
    const { colors } = useColors();
    return {
        position: "sticky",
        top: 0,
        height: 'auto',
        color: colors.grayFont,
        backgroundColor: colors.gray, // Customize this background color as needed
        zIndex: 1, // Ensure the header stays above the content when scrolling
        fontWeight: 'bold',
        fontSize: '14px',
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center'
    };
});
