import React, { useCallback, useEffect, useState } from 'react';

// Components
import { toast } from 'react-toastify';

// Custom components
import { PageTitle } from '../../components/PageTitle';
import { PlotPlanForm } from '../../components/molecules/forms/PlotPlanForm';
import { BackdropLoading } from '../../components/BackdropLoading';
import { ShowDialog } from '../../components/ShowDialog';

// Utils
import { NumberToString } from '../../utils/numberToString';

// Styles
import { Container } from '../styles';

// API
import { PlotPlanApi } from '../../services/PlotPlanApi';
import { PlantCategoryApi } from '../../services/PlantCategoryApi';

// Router
import Router from '../../router';
import { useLocation, useNavigate } from 'react-router-dom';

// Styles
import { SectionPageTitle } from '../FormManagement/styles';
import { Content } from './styles';

// Icons
import { ArrowBack, Map } from '@mui/icons-material';

// Utils
import { CheckResponse } from '../../utils/checkResponse';
import { useTranslation } from 'react-i18next'

export const PlotPlanCreate = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const plotPlanApi = new PlotPlanApi();
    const plantCategoryApi = new PlantCategoryApi();
    const {convert} = new NumberToString();
    const {check}= new CheckResponse();

    const [loadingForm, setLoadingForm] = useState(false);
    const [loadingChildren, setLoadingChildren] = useState(false);
    const [plotPlans, setPlotPlans] = useState([])
    const [categories, setCategories] = useState([])
    const [areaTypes, setAreaTypes] = useState([])
    const [imageSize, setImageSize] = useState({width: null, height: null})
    const [form, setForm] = useState({
        name: "",
        parentX: "-",
        parentY: "-",
        height: "-",
        parent: {},
        image: "",
        parentImg: "",
        file: "",
        children: [],
        category: "",
        areaType: "",
        email: ""
    });
    //removed "required: true" of "image" key
    const [errors, setErrors] = useState({
        parentX: {error: "", field: t('plotPlans.plotPlanForm.xPosition')},
        parentY: {error: "", field: t('plotPlans.plotPlanForm.yPosition')},
        height: {error: "", field: t('plotPlans.height')},
        parent: {error: "", field: t('plotPlans.plotPlanForm.parentNode'), required: true},
        name: {error: "", field: t('plotPlans.plotPlanForm.name'), required: true},
        image: {error: "", field: t('plotPlans.plotPlanForm.image')},
        category: {error: "", field: t('plotPlans.plotPlanForm.category')},
        email: {error: "", field: t('plotPlans.plotPlanForm.email')},
        areaType: {error: "", field: t('plotPlans.plotPlanForm.areaType')},
    });
    const [dialog, setDialog] = React.useState({
      title: '',
      message: '',
      status: false
    });
    const WIDTH = 500
    const HEIGHT = 400
    
    useEffect(() => {
        if (location?.state?.plots) {
            let plots = location.state.plots;
            setPlotPlans(JSON.parse(plots))
        }
        async function call() {
            setLoadingForm(true)
            const categories = await getPlantCategories();
            await loadAreaType();
            if (location?.state?.data) {
                let data = JSON.parse(location.state.data);
                await plotPlanDetail(data, categories)
            }
            else {
                setForm({...form, category: categories?.find((el) => el.name === 'Estación') || ''})
            }
            setLoadingForm(false)
        }
        call()
    }, [])

    async function plotPlanDetail(data, categories) {
        setLoadingChildren(true)
        let children = []
        if (data.parentNode?.imgSource) children = await loadNodes(data.parentNode);
        let index = children.findIndex(obj => {return obj.id === data.id})
        children[index] = {...children[index], isEdit: true}

        let formData = {
            id: data.id,
            isEdit: true,
            name: data.name,
            parentX: data.parentX,
            parentY: data.parentY,
            height: data.height,
            parent: data.parentNode,
            image: data.imgSource,
            parentImg: data.parentNode?.imgSource ? `${Router.apiBaseUrl}/${data.parentNode?.imgSource}` : null,
            file: '',
            children: children,
            childNodes: children,
            parentNodeLevel: data.parentNode?.nodeLevel,
            areaType: data.plotPlanAreaType
        }

        if (data.parentNode?.id === 1) {
            let categoryData = data.plant?.category?.id
            let findCategory = categories.find((el) => categoryData ? el.id === categoryData : el.name === 'Estación')

            formData = {...formData,
                category: findCategory,
                email: data.plant.email
            }
        }

        setLoadingChildren(false)

        setForm({...form, ...formData})
    }

    async function getPlantCategories() {
        const response = await plantCategoryApi.list();
        const result = check(response);

        if (result.status) {
            setCategories(response.data);
            return response.data
        }
        else {
            toast.error(() => 
                <div>{t('plotPlans.plotPlanForm.plotPlanCreated.error')}<br/>
                    {result.errors}
                </div>
            );
        }
    }

    const ref = useCallback(node => {
    }, []);

    async function addImageProcess(src) {
        return new Promise((resolve, reject) => {
          let img = new Image()
          img.onload = () => resolve(img)
          img.onerror = reject
          img.src = src
        })
    }
    
    async function handleChangeForm(event, name) {
        let e = event?.target;
        let positionErrors = {}

        if (e?.type === 'file') {
            setForm({...form, 
                [name]: e.value, 
                ['file']: e.files[0], 
            })
            if (form.isEdit) {
                setDialog({...dialog, 
                    status: true,
                    title: t('plotPlans.plotPlanForm.messages.updatedImage.title'),
                    message: t('plotPlans.plotPlanForm.messages.updatedImage.message', {name: form.name}),
                    handleClose: () => setDialog({status: false})
                })
            }
        }
        else if (e?.type === 'text' || e?.type === 'email') {
            setForm({...form, [name]: e.value})
        }
        else if (e?.type === 'number') {
            let value = isNaN(parseFloat(e.value)) ? 0 : e.value
            setForm({...form, 
                [name]: parseFloat(value), 'children': updateHeight(null, value)
            })
        }
        else {
            setLoadingChildren(true)
            let children = []
            if (event.imgSource) children = await loadNodes(event);

            setForm({...form, 
                [name]: event,
                ['parentImg']: event.imgSource ? `${Router.apiBaseUrl}/${event.imgSource}` : null,
                ['childNodes']: children,
                ['children']: children,
                ['parentX']: "-",
                ['parentY']: "-",
                ['height']: "-",
                ['parentNodeLevel']: event.nodeLevel
            })
            positionErrors = {
                ['parentX']: {...errors['parentX'], error: ''},
                ['parentY']: {...errors['parentY'], error: ''},
                ['height']: {...errors['height'], error: ''},
            }
            setLoadingChildren(false)
        }
        
        setErrors({...errors, 
            [name]: {...errors[name], error: ""},
            ...positionErrors
        })
    }

    function updateHeight(array, value) {
        let children = []
        let arrayChildren = !array ? form.children : array
        arrayChildren.map(obj => {
            if (!obj.id || obj.isEdit) obj.height = parseFloat(value)
            children.push(obj)
        })
        return children
    }

    async function loadAreaType() {
        let response = await plotPlanApi.getAreaType();
        let result = check(response)

        if (result.status) {
            setAreaTypes(response.data);
        }
        else {
            toast.error(() => <>{result.errors}</>)
        }
    }
    
    async function loadNodes(event) {
        let response = await plotPlanApi.getById(event.id);

        if (response.status === 200) {
            let width = WIDTH;
            let height = HEIGHT;
            let data = response.data;
            let children = await childNodes(`${Router.apiBaseUrl}/${data.imgSource}`, data.childNodes, width, height)

            return children || []
        }
        return []
    }

    async function childNodes(img, children, width, height) {
        await addImageProcess(img).then(async res => {
            setImageSize({width: res.width, height: res.height})
            
            await children?.map(async (child, index) => {
                if (img && child.parentX && child.parentY) {
                    child.coordX = (child.parentX / res.width) * width
                    child.coordY = (child.parentY / res.height) * height
                    child.element = index + 1;
                }
            })
        })
        return children
    }

    async function handleClickImage(event) {
        let rect = event?.target?.getBoundingClientRect();
        let x = event.clientX - rect.left - 10;
        let y = event.clientY - rect.top - 10;

        let array = [...form.childNodes]
        let index = form.childNodes?.length;
        if (form.isEdit && form.id) {
            index = array.findIndex(obj => {return obj.id === form.id})
            array[index] = {...array[index], coordX: x, coordY: y, element: index + 1}
        }
        else {
            array.splice(index, 0, {coordX: x, coordY: y, element: index + 1})  
        }
        let parentX = (x / WIDTH) * imageSize.width
        let parentY = (y / HEIGHT) * imageSize.height

        setForm({...form, 
            ['parentX']: parentX,
            ['parentY']: parentY,
            ['children']: updateHeight(array, form.height)
        })
    }

    function validate() {
        let hasErrors = false;
        let errorObject = {}

        Object.entries(errors).forEach((value) => {
            if ((!form[value[0]] || Object.keys(form[value[0]])?.length === 0) && value[1]?.required) {
                hasErrors = true;
                errorObject = {...errorObject, [value[0]]: {...value[1], error: t('common.mandatory', {field: value[1]?.field})}}
            }
        })

        if (form.email !== "" && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(form.email) && form.parent.id === 1) {
            hasErrors = true;
            errorObject = {...errorObject, 'email': {error: t('plotPlans.plotPlanForm.errors.email')}}
        }

        if (form.parentNodeLevel >= 1 && !form.areaType?.id) {
            hasErrors = true;
            errorObject = {...errorObject, 'areaType': {error: t('common.mandatory', {field: t('plotPlans.plotPlanForm.areaType')})}}
        }
        
        errorObject = {...errorObject, 
            ['height']: {...errors['height'], error: isNaN(form.height) ? t('plotPlans.plotPlanForm.errors.height') : ''},
        }
        
        setErrors({...errors, ...errorObject})

        if (hasErrors) toast.error(t('plotPlans.plotPlanForm.errors.message'))
        return hasErrors;
    }

    function formatData() {
        let formData = new FormData();
        formData.append('name', form.name);
        formData.append('height', form.height);
        formData.append('parentId', form.parent.id);

        if (form.parent.id === 1) {
            if (form.category.id) {
                formData.append('categoryId', form.category.id);
            }
            if (form.email) {
                formData.append('email', form.email);
            }
        }
        if (form.parentNodeLevel >= 1 && form.areaType?.id) {
            formData.append('plotPlanAreaTypeId', form.areaType.id);
        }

        if (form.file !== '') {
            formData.append('image', form.file);
        }

        if (form.parentImg && !isNaN(form.parentX) && !isNaN(form.parentY)) {
            formData.append('parentX', form.parentX?.toLocaleString('es-ES'));
            formData.append('parentY', form.parentY?.toLocaleString('es-ES'));
        }

        return formData
    }

    async function submitForm() {
        if (validate()) return
        
        let formData = formatData();
        setLoadingForm(true)
        let toastInfo;
        let response = null;
        if(form.image){
            toastInfo = toast.info(t('files.messages.saveFile.process'), {autoClose: false})
        }
        if (form.id) {
            response = await plotPlanApi.update(form.id, formData);
        }
        else {
            response = await plotPlanApi.create(formData);
        }
        let result = check(response)

        setLoadingForm(false)

        if (result.status) {
            setDialog({
                title: t('plotPlans.plotPlanForm.plotPlanCreated.title'), 
                message: t('plotPlans.plotPlanForm.plotPlanCreated.message'),
                status: true,
                id: form.parent.id,
                handleClose: () => handleCloseDialog()
            })
            if (toastInfo) {
                toast.dismiss(toastInfo);
            }
        }
        else {
            toast.error(() => 
                <div>{t('plotPlans.plotPlanForm.plotPlanCreated.error')}<br/>
                    {result.errors}
                </div>
            );
            if (toastInfo) {
                toast.dismiss(toastInfo);
            }
        }
    }

    function handleCloseDialog() {
      setDialog({...dialog, status: false})
      navigate(Router.appPlotPlans, {state: {id: dialog.id}})
    }

    // Avoid enter key
    const avoidEnterKey = (e) => {
        if(e.key === 'Enter'){
            e.preventDefault();
        }
    }
    
    return (
        <Container open={props.open} mb={6}>
            <SectionPageTitle>
                <PageTitle title="" isbutton={true} navigate={Router.appPlotPlans} icon={<ArrowBack />} />
                <PageTitle title={form.id ? t('plotPlans.edit') : t('plotPlans.createNew')} icon={<Map/>}/>
            </SectionPageTitle>
            
            <Content>
                <PlotPlanForm 
                    plotPlanRef={ref} 
                    form={form} 
                    errors={errors} 
                    plotPlans={plotPlans}
                    categories={categories}
                    areaTypes={areaTypes}
                    handleChange={handleChangeForm} 
                    handleClickImage={handleClickImage} 
                    handleSubmit={submitForm}
                    loadingChildren={loadingChildren}
                    handleBack={() => navigate(Router.appPlotPlans)}
                    avoidEnterKey={avoidEnterKey}
                />
            </Content>

            <BackdropLoading open={loadingForm}/>
            <ShowDialog 
                openDialog={dialog.status} 
                dialog={dialog} 
                handleCloseDialog={dialog.handleClose}
            />
        </Container>
    )
}

