import { Auth } from "../utils/auth";
import { HealthCheckApi } from "./HealthCheckApi";
import Router from "../router";
import axios from "./AxiosConfig";

// Permissions
import ability from '../config/defineAbility.js'

export class WorkflowDataApi {
    healthCheck = null;
    token = null;
    user = null;

    constructor() {
        let auth = new Auth();
        this.token = auth.getToken();
        this.user = auth.getUser();
        this.healthCheck = new HealthCheckApi();
    }

    async workflowInstances(workflowId, startDate, endDate, includeCanceled, includeClosed) {
        if (ability.can("GetWorkflowData")) {
            return await axios.get(Router.apiWorkflowData, {
                params: {
                    workflowId: workflowId,
                    startDate: startDate,
                    endDate: endDate,
                    includeCanceled: includeCanceled,
                    includeClosed: includeClosed
                }
            })
            .then(async response => {
                return await this.healthCheck.checkApi(response)
            }).catch(async error => {
                return await this.healthCheck.checkApi(error)
            })
        }
        return {status: false};
    }

    async workflowInstancesPaginated({workflowId, startDate, endDate, includeCanceled, includeClosed, currentPage, pageSize, statusId, plantId, companyId, filter, onlyActive }) {
        if (ability.can("GetWorkflowData")) {
            return await axios.get(Router.apiWorkflowDataPaginated, {
                params: {
                    workflowId: workflowId,
                    startDate: startDate,
                    endDate: endDate,
                    includeCanceled: includeCanceled,
                    includeClosed: includeClosed,
                    currentPage: currentPage,
                    pageSize: pageSize,
                    statusId: statusId,
                    plantId: plantId,
                    companyId: companyId,
                    filter: filter,
                    onlyActive: onlyActive,
                },
                paramsSerializer: {indexes: null}
            })
            .then(async response => {
                return await this.healthCheck.checkApi(response)
            }).catch(async error => {
                return await this.healthCheck.checkApi(error)
            })
        }
        return {status: false};
    }

    async detailWorkflowInstance(id) {
        if (ability.can("GetWorkflowDataById")) {
            return await axios.get(Router.apiBaseUrl + Router.apiDetailWorkflowData.replace(':id', id), {})
            .then(async response => {
                return await this.healthCheck.checkApi(response)
            }).catch(async error => {
                return await this.healthCheck.checkApi(error)
            })
        }
        return {status: false};
    }

    async create(data) {
        if (ability.can("CreateWorkflowData")) {
            return await axios.post(Router.apiWorkflowDataCreate, data)
            .then(async response => {
                return await this.healthCheck.checkApi(response)
            }).catch(async error => {
                return await this.healthCheck.checkApi(error)
            })
        }
        return {status: false};
    }

    async notification(relations = false, minimal = false) {
        if (ability.can("GetNotifications")) {
            return await axios.get(Router.apiBaseUrl + Router.apiNotification, {
                params: {
                    relations,
                    minimal
                }
            })
            .then(async response => {
                return await this.healthCheck.checkApi(response)
            }).catch(async error => {
                return await this.healthCheck.checkApi(error)
            })
        }
        return {status: false};
    }

    async updateNotification(id) {
        if (ability.can("ReadNotifications")) {
            return await axios.put(Router.apiBaseUrl + Router.apiProcessNotification.replace(':id', id), {})
            .then(async response => {
                return await this.healthCheck.checkApi(response)
            }).catch(async error => {
                return await this.healthCheck.checkApi(error)
            })
        }
        return {status: false};
    }

    async deleteNotification(id) {
        if (ability.can("DeleteNotifications")) {
            return await axios.delete(Router.apiBaseUrl + Router.apiProcessNotification.replace(':id', id), {})
            .then(async response => {
                return await this.healthCheck.checkApi(response)
            }).catch(async error => {
                return await this.healthCheck.checkApi(error)
            })
        }
        return {status: false};
    }

    async getFormData(params) {
        if (ability.can("GetFormData")) {
            return await axios.get(Router.apiBaseUrl + Router.apiWorkflowDataGetFormData, {
                params: {
                    ...params
                }
            })
            .then(async response => {
                return await this.healthCheck.checkApi(response)
            }).catch(async error => {
                return await this.healthCheck.checkApi(error)
            })
        }
        return {status: false};
    }

    async saveFormData(data) {
        if (ability.can("SaveFormData")) {
            return await axios.post(Router.apiBaseUrl + Router.apiWorkflowDataSaveFormData, data, {
                headers: {
                    'Authorization': `Bearer ${this.token}`
                },
            })
            .then(async response => {
                return await this.healthCheck.checkApi(response)
            }).catch(async error => {
                return await this.healthCheck.checkApi(error)
            })
        }
        return {status: false};
    }

    async updateStatus(data) {
        if (ability.can("UpdateStatusWorkflowData")) {
            return await axios.post(Router.apiBaseUrl + Router.apiWorkflowDataUpdateStatus, data, {
                headers: {
                    'Authorization': `Bearer ${this.token}`
                },
            })
            .then(async response => {
                return await this.healthCheck.checkApi(response)
            }).catch(async error => {
                return await this.healthCheck.checkApi(error)
            })
        }
        return {status: false};
    }

    async approveForm(data) {
        if (ability.can("ApproveWorkflowData")) {
            data.user = this.user?.email
            return await axios.post(Router.apiBaseUrl + Router.apiWorkflowDataCheckForm, data)
            .then(async response => {
                return await this.healthCheck.checkApi(response)
            }).catch(async error => {
                return await this.healthCheck.checkApi(error)
            })
        }
        return {status: false};
    }

    async checkFormCredentials(data) {
        if (ability.can("ApproveWorkflowDataCredentials")) {
            data.user = this.user?.email
            return await axios.post(Router.apiBaseUrl + Router.apiWorkflowDataCheckFormCredentials, data)
            .then(async response => {
                return await this.healthCheck.checkApi(response)
            }).catch(async error => {
                return await this.healthCheck.checkApi(error)
            })
        }
        return {status: false};
    }

    async delete(data) {
        if (ability.can("DeleteWorkflowData")) {
            return await axios.delete(Router.apiWorkflowDataDelete, {data: data})
            .then(async response => {
                return await this.healthCheck.checkApi(response)
            }).catch(async error => {
                return await this.healthCheck.checkApi(error)
            })
        }
        return {status: false};
    }

    async upload(data) {
        return await axios.post(Router.apiBaseUrl + Router.apiWorkflowDataUploadAttachments, data)
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)
        })
    }

    async clone(data) {
        if (ability.can("CloneWorkflowData")) {
            return await axios.post(Router.apiBaseUrl + Router.apiWorkflowDataClone, data)
            .then(async response => {
                return await this.healthCheck.checkApi(response)
            }).catch(async error => {
                return await this.healthCheck.checkApi(error)
            })
        }
        return {status: false};
    }

    async getDetailNotification(id, minimal) {
        if (ability.can("GetNotificationDetail")) {
            return await axios.get(Router.apiBaseUrl + Router.apiDetailNotification.replace(':id', id), {
                params: {
                    minimal: minimal
                }
            })
            .then(async response => {
                return await this.healthCheck.checkApi(response)
            }).catch(async error => {
                return await this.healthCheck.checkApi(error)
            })
        }
        return {status: false};
    }

    async relations(workflowId) {
        return await axios.get(Router.apiWorkflowDataRelation, {
            params: {
                workflowId: workflowId
            }
        })
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)
        })
    }
    
    async download({workflowId, startDate, endDate, includeCanceled, includeClosed, statusId, plantId, filter}) {
      return await axios.get(Router.apiWorkflowDataDownload, {
        params: {
            workflowId: workflowId,
            startDate: startDate,
            endDate: endDate,
            includeCanceled: includeCanceled,
            includeClosed: includeClosed,
            statusId: statusId,
            plantId: plantId,
            filter: filter
        },
        paramsSerializer: {indexes: null},
        responseType: 'blob'
      })
      .then(async response => {
          return await this.healthCheck.checkApi(response)
      }).catch(async error => {
          let responseObj = await error?.response?.data?.text();
          let errorResponse = {response: JSON.parse(responseObj)}
          return await this.healthCheck.checkApi(errorResponse)
      })
  }
}