import { styled } from "@mui/material/styles";
import { useColors } from "../../../../Context/ColorsContext"
import { MediaQuerys } from "../../../../styles/GlobalStyle";

const { mobile, tablet, desktop } = MediaQuerys;


export const Container = styled('div')(() => ({
  display: 'flex',
  width: '100%',
  minWidth: '450px',
  height: '100%',
  flexDirection: 'column',
  [mobile]: {
    width: '400px',
    minWidth: '400px !important',
  },
  // [tablet]: {
  //   minWidth: '500px'
  // }
}))

export const Header = styled('div')(() => {
  const { colors } = useColors();
  return {
    display: 'flex',
    fontSize: '1.1rem',
    background: colors.white,
    color: colors.detailTextColor,
    width: '100%',
    height: 'fit-content',
    borderRadius:'10px 10px 0 0',
    padding:'16px',
    textTransform:'uppercase',
    fontWeight:600,
    [tablet]: {
      fontSize: '0.75rem',
    },
    [desktop]: {
      fontSize: '0.75rem'
    }
  }
})

export const Body = styled('div')(() => {
  const { colors } = useColors();
  return {
    display: 'flex',
    flexDirection:'column',
    flex:'1',
    width: '100%',
    background: colors.white,
  }
})

export const Footer = styled('div')(() => {
  const { colors } = useColors();
  return {
    display: 'flex',
    background: colors.white,
    width: '100%',
    height: 'fit-content',
    borderRadius:'0 0 10px 10px',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '8px 16px',
    margin: 'auto'
  }
})

export const SectionRight = styled('section')(() => ({
  display: 'flex',
}))

export const Spacer = styled('section')(() => ({
  padding: '0.5rem',
}))