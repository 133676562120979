import React, { useState, useEffect, useCallback } from "react";

// Services
import { WorkflowApi } from "./../../services/WorkflowApi";
// Global Styles
import {
  Container,
  ContainerBarSearch,
  ContainerButtonDownload,
  ContainersButtonsGroup,
} from "../styles";
// Styled Components
import { SectionPageTitle, Spacer, SectionPageLeft } from "./style";
// Moment JS
import moment from "moment";
import { BackdropLoading } from "../../components/BackdropLoading";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { PageTitle } from "../../components/PageTitle";
// Atoms Components
import { SearchBarLogs } from "../../components/atoms/searchBars/SearchBarLogs";
import { TableLogs } from "../../components/atoms/tables/TableLogs";
// Services
import { FormatDate } from "../../utils/formatDate";

// Utils
import { useTranslation } from "react-i18next";
import { ColorsContext } from "../../Context/ColorsContext";

//MUI Material
import { Button } from "@mui/material";

// Material Icons
import { CloudDownloadOutlined } from "@mui/icons-material";

//Custom Hook
import { useDownloadExcel } from "../../Hooks/useDownloadExcel";

export const LogsList = (props) => {
  // constant Service
  const logsApi = new WorkflowApi();

  const { i18n, t } = useTranslation();
  const { colors } = React.useContext(ColorsContext);
  const { format } = new FormatDate();

  const [arrayLogs, setArrayLogs] = useState([]);
  const [rows, setRows] = useState([]);
  const [finalDate, setFinalDate] = useState(
    moment(new Date()).endOf("week").format("YYYY-MM-DD")
  );
  const [initialDate, setInitialDate] = useState(
    moment(new Date()).startOf("week").format("YYYY-MM-DD")
  );
  const [loading, setLoading] = React.useState(false);

  //?? prepare item-table for download Excel
  const fieldConfig = {
    [t("system.logs.table.date")]: (item) =>
      format({ date: item.createdAt, format: true }).toString(),
    [t("system.logs.table.user")]: "username",
    [t("system.logs.table.module")]: "module",
    [t("system.logs.table.submodule")]: "submodule",
    [t("common.actions.title")]: "action",
  };

  //?? Custom Hook Download Excel
  const { handleDownloadExcelList } = useDownloadExcel(
    arrayLogs,
    fieldConfig,
    t("system.logs.title")
  );

  const Loadlogs = useCallback(async (data) => {
    let response = await logsApi.LoadLogs(data);
    if (response.status === 200) {
      let resp = response.data.data;
      setRows(resp);
      setArrayLogs(resp);
    }
  }, []);

  useEffect(() => {
    // Loadlogs(logsFilter);
    Loadlogs({
      initDate: initialDate,
      finDate: finalDate,
    });
  }, [i18n.language]);

  // Metodo Onsubmit Form filter
  function handleDatesFilter(e) {
    e.preventDefault();
    const data = {
      initDate: initialDate,
      finDate: finalDate,
    };
    Loadlogs(data);
  }

  // Method Clear Filter
  const handleClearDate = () => {
    const newFinalDate = moment(new Date()).endOf("week").format("YYYY-MM-DD");
    const newInitialDate = moment(new Date())
      .startOf("week")
      .format("YYYY-MM-DD");

    setFinalDate(newFinalDate);
    setInitialDate(newInitialDate);

    Loadlogs({
      initDate: newInitialDate,
      finDate: newFinalDate,
    });
  };

  return (
    <>
      <Container open={props.open}>
        <BackdropLoading open={loading} />

        <SectionPageTitle>
          <SectionPageLeft>
            <PageTitle
              title={t("system.logs.title")}
              icon={<AssignmentIcon />}
            />
            <ContainerBarSearch>
              <SearchBarLogs rows={rows} setArrayLogs={setArrayLogs} />
            </ContainerBarSearch>
          </SectionPageLeft>

          <ContainersButtonsGroup>
            <ContainerButtonDownload onlyItem={true}>
              <Button
                variant="contained"
                endIcon={<CloudDownloadOutlined />}
                sx={{
                  height: "100%",
                  width: "100%",
                  padding: "0 20px",
                  backgroundColor: colors.buttonActionColor,
                  color: colors.white,
                  "&:hover": {
                    backgroundColor: colors.buttonActionColor,
                    opacity: 0.9,
                    color: colors.white,
                  },
                }}
                aria-label="download"
                onClick={handleDownloadExcelList}
              >
                {t("common.download")}
              </Button>
            </ContainerButtonDownload>
          </ContainersButtonsGroup>
        </SectionPageTitle>

        <TableLogs
          rows={rows}
          setRows={setRows}
          arrayLogs={arrayLogs}
          setArrayLogs={setArrayLogs}
          initialDate={initialDate}
          setInitialDate={setInitialDate}
          finalDate={finalDate}
          setFinalDate={setFinalDate}
          handleDatesFilter={handleDatesFilter}
          handleClearDate={handleClearDate}
        />
      </Container>
    </>
  );
};
