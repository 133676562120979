import { DialogContent } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Button, Dialog, DialogActions, DialogContentText, Icon, Typography } from '@mui/material';
import { MediaQuerys } from "../../styles/GlobalStyle";

const { mobile, tablet } = MediaQuerys

export const CustomDialogContent = styled(DialogContent)(() => ({
    display: "flex",
    alignItems: "center",
    padding: '10px 20px 5px 20px',
}));

export const CustomTypography = styled(Typography)(() => ({
    fontSize: '1.125rem',
    fontWeight: 'bold',
    marginLeft: '8px',
    [tablet]: {
        fontSize: '1rem',
    }
}))

export const CustomDialogContentText = styled(DialogContentText)(() => ({
    fontSize: '1.120rem',
    [mobile]: {
        fontSize: '0.75rem',
    },
    [tablet]: {
        fontSize: '0.85rem',
    }
}))