import React, { useState } from 'react';

// Components
import { Tab } from '@mui/material';

// Custom components
import { SessionCheckConfigForm } from '../SessionCheckConfigForm';
import { PasswordPolicyForm } from '../PasswordPolicyForm';
import { AppearanceForm } from '../AppearanceForm';
import { TabPanel } from '../../components/atoms/tabPanel';
import { GeneralSettings } from '../../components/GeneralSettings';
import { PageTitle } from '../../components/PageTitle';

// Services

// Styles
import { Container, } from '../styles';
import { Content, CustomTabs, SectionPageTitle } from './styles';

// Icons
import { Settings as SettingsIcon } from '@mui/icons-material';

// Utils
import { useTranslation } from 'react-i18next'
import { ColorsContext } from "../../Context/ColorsContext"

// Permissions
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../Context/PermissionsContext';

export const Settings = (props) => {
    const { t } = useTranslation();
	const { colors } = React.useContext(ColorsContext);
	const ability = useAbility(AbilityContext);

	const [tab, setTab] = useState(0)

    function handleChangeTab(_, value) {
		setTab(value);
    }


	return (
		<Container open={props.open}>
			
			<SectionPageTitle>
				<PageTitle title={t('settings.title')} icon={<SettingsIcon/>}/>
			</SectionPageTitle>

			<Content>
				<CustomTabs
					value={tab}
					onChange={handleChangeTab}
					textColor="secondary"
					indicatorColor="secondary"
					aria-label="secondary tabs example"
					variant="scrollable"
					TabIndicatorProps={{style: {backgroundColor: colors.buttonActionColor, color: colors.buttonActionColor }}}
				>
				    {ability.can("DefaultWorkflow") &&
					    <Tab value={0} label={t('sidebar.system.settings')}/>
                    }
				    {ability.can("UpdatePasswordPolicy") &&
					    <Tab value={1} label={t('sidebar.system.passwordPolicy')}/>
                    }
				    {ability.can("UpdateInactivityTime") &&
                        <Tab value={2} label={t('sidebar.system.inactivityTimes')}/>
                    }
				    {ability.can("UpdateAppearance") &&
                        <Tab value={3} label={t('sidebar.system.appearance')}/>
                    }
				</CustomTabs>
				{ability.can("DefaultWorkflow") &&
                    <TabPanel value={tab} index={0}> 
                        <GeneralSettings/>
                    </TabPanel>
                }
				{ability.can("UpdatePasswordPolicy") &&
                    <TabPanel value={tab} index={1}> 
                        <PasswordPolicyForm/>
                    </TabPanel>
                }
                {ability.can("UpdateInactivityTime") &&
                    <TabPanel value={tab} index={2}> 
                        <SessionCheckConfigForm/>
                    </TabPanel>
                }
                {ability.can("UpdateAppearance") &&
                    <TabPanel value={tab} index={3}> 
                        <AppearanceForm/>
                    </TabPanel>
                }
			</Content>

		</Container >
	)
}
