import { List, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useColors } from "../../../../Context/ColorsContext"
import { MediaQuerys } from "../../../../styles/GlobalStyle";

const { mobile, tablet, desktop, desktoplarge } = MediaQuerys;

export const Container = styled('div')(() => ({
  display: 'flex',
  width: '100%',
  minWidth: '500px',
  height: '100%',
  flexDirection: 'column',
  [mobile]: {
    width: '380px',
    minWidth: '380px'
  }
}))

export const Header = styled('div')(() => {
  const { colors } = useColors();
  return {
    display: 'flex',
    background: colors.white,
    color: colors.detailTextColor,
    width: '100%',
    height: 'fit-content',
    borderRadius:'10px 10px 0 0',
    padding:'16px',
    textTransform:'uppercase',
    fontWeight:600,
    [desktop]: {
      fontSize: '0.75rem'
    }
  }
})

export const Body = styled('div')(() => {
  const { colors } = useColors();
  return {
    display: 'flex',
    flexDirection:'column',
    flex:'1',
    width: '100%',
    background: colors.white,
  }
})

export const Footer = styled('div')(() => {
  const { colors } = useColors();
  return {
    display: 'flex',
    background: colors.white,
    width: '100%',
    height: 'fit-content',
    borderRadius:'0 0 10px 10px',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '8px 16px',
    margin: 'auto'
  }
})

export const ContainerInfo = styled('div')(() => {
  const { colors } = useColors();
  return {
    display: 'flex',
    flexDirection:'column',
    background: colors.gray,
    width: '100%',
    height: 'fit-content',
    padding:'16px 24px',
  }
})

export const ContainerDescription = styled('div')(() => {
  const { colors } = useColors();
  return {
    display: 'flex',
    flexDirection:'column',
    background: colors.white,
    width: '100%',
    height: 'fit-content',
    borderBottom:'1px solid '+colors.grayFont,
    padding:'16px 24px',
  }
})

export const TitleDescription = styled('h6')(() => {
  const { colors } = useColors();
  return {
    color: colors.main,
    paddingBottom:'8px',
  }
})

export const TextDescription = styled('p')(() => {
  const { colors } = useColors();
  return {
    color: colors.grayFont,
  }
})

export const SectionLeft = styled('section')(() => ({
  display: 'flex',
}))

export const SectionRight = styled('section')(() => ({
  display: 'flex',
}))

export const FigureFooter = styled('figure')(() => {
  const { colors } = useColors();
  return {
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    color: colors.main,
    margin: 0,
    marginRight:'5px !important',
  }
})

export const Label = styled('label')(() => {
  const { colors } = useColors();
  return {
    color: colors.main,
    fontWeight:'500',
    paddingRight:'5px',
  }
})

export const TextFooter = styled('label')(() => ({
  fontWeight:'500',
}))

export const Spacer = styled('section')(() => ({
  padding: '0.5rem',
}))

export const SectionTopInfo = styled('section')(() => ({
  display:'flex',
  alignItems:'center',
  paddingBottom:'12px'
}))

export const SectionTopRightInfo = styled('section')(() => ({
}))

export const SectionBottomInfo = styled('section')(() => ({
  display:'flex',
  justifyContent:'space-between',
  alignItems:'center',
}))

export const SectionMidInfo = styled('section')(() => ({
  paddingBottom: '0.9rem'
}))

export const SectionBottomLeftInfo = styled('section')(() => ({
  display:'flex',
  width:'100%',
  lineHeight: '16px',
  paddingBottom: '0.2rem',
}))

export const SectionBottomRightInfo = styled('section')(() => ({
  width: '50%',
}))

export const Figure = styled('figure')(() => {
  const { colors } = useColors();
  return {
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    background: colors.purple,
    color: colors.white,
    height:'40px',
    width:'40px',
    minWidth:'30px',
    borderRadius:'50%',
    margin: 0,
    marginRight:'10px !important',
  }
})

export const TitleInfo = styled('p')(() => ({
  fontWeight:'500',
  marginBottom:'-5px',

}))

export const SubtitleInfo = styled('label')(() => ({
}))

export const Status = styled('div')(() => {
  const { colors } = useColors();
  return {
    color: colors.white,
    padding: '0.8rem',
    display:'flex'
  }
})

export const StatusText = styled('label')(() => ({
  fontSize: '1.2rem',
  marginLeft: '0.3rem',
  textAlign: 'left',
  justifyContent: 'center',
  alignContent: 'center', 
  alignItems: 'center'
}))

export const StatusIcon = styled('div')(() => ({
  marginLeft: '1rem'
}))

export const ListCustom = styled(List)(() => ({
  maxHeight: 300, 
  overflowY: "auto",
  margin: 0,
  padding: 0,
  listStyle: "none",
  height: "100%",
}))

export const AreaIcon = styled('span')(() => {
  const { colors } = useColors();
  return {
    background: colors.orange,
    borderRadius: '50%',
    height: '1.2rem',
    width: '1.2rem',
    lineHeight: '1.2rem',
    display: 'inline-block',
    textAlign: 'center',
    color: colors.white,
    fontSize: '0.9rem'
  }
})

export const Input = styled(TextField)(() => {
  const { colors } = useColors();
  return {
    width: '100%',
    '.MuiInputBase-root': {
        background: colors.white,
        borderRadius: 6,
        fontSize: 13
    }
  }
})