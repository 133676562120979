import React, { useEffect, useState } from 'react';

// Components
import { toast } from 'react-toastify';

// Custom components
import { BackdropLoading } from '../../../BackdropLoading';

// API
import { ContractDocumentApi } from '../../../../services/ContractDocumentApi';
import { PlantApi } from '../../../../services/PlantApi';
import { CompanyApi } from '../../../../services/CompanyApi';
import { PlantCategoryApi } from '../../../../services/PlantCategoryApi';

// Styles
import { Container, Header, Body, Footer, SectionRight, Spacer, } from './styles'
import { FormButton } from '../../../../pages/styles';

// Utils
import { CheckResponse } from '../../../../utils/checkResponse';
import { Contract } from '../../../molecules/forms/Contract';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { validateForm } from '../../../../utils/commonFunctions';
import { arrayMultipleData } from '../../../../utils/commonFunctions/returnArrayMultiple';

// Permissions
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../../../Context/PermissionsContext';

export const ContractModalCard = ({ data, handleSave, handleClose }) => {
    const contractDocumentApi = new ContractDocumentApi();
    const companyApi = new CompanyApi();
    const plantApi = new PlantApi();
    const plantCategoryApi = new PlantCategoryApi();
    const {check} = new CheckResponse();
    const { t } = useTranslation();
    const ability = useAbility(AbilityContext);

    const [plants, setPlans] = useState([])
    const [contractDocuments, setContractDocuments] = useState([])
    const [companyList, setCompanyList] = React.useState([]);
    const [categories, setCategories] = useState([]);
    const [form, setForm] = useState({
        contractDocument: '',
        code: '',
        // nit: '',
        // provider: '',
        object: '',
        validSince: '',
        validUntil: '',
        plants: [],
        companyId: 0
    })
    const [errors, setErrors] = useState({
        contractDocument: {error: '', field: t('contract.table.contractDocument'), required: false},
        code: {error: '', field: t('contract.table.code'), required: false},
        // nit: {error: '', field: t('contract.table.nit'), required: false},
        // provider: {error: '', field: t('contract.table.provider'), required: false},
        object: {error: '', field: t('contract.table.object'), required: false},
        validSince: {error: '', field: t('contract.table.validSince'), required: false},
        validUntil: {error: '', field: t('contract.table.validUntil'), required: false},
        plants: {error: '', field: t('contract.table.plants'), required: false},
        company: {error: '', field: t('contract.table.company'), required: true},
    })
    const [disabled, setDisabled] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        async function call() {
            setLoading(true)
            let listScope = await getPlants()
            setPlans(listScope)
            await getContractDocuments()
            await getPlantCategories();
            await loadCompanies();

            if (data?.id) {
                let provider = {
                    ...data.company,
                    provider: `${data.company?.nit ?? ""}-${data.company?.dv ?? ""}, ${data.company?.name ?? ""}`
                }
                setForm({...form,
                    id: data.id,
                    contractDocument: data.contractDocument,
                    code: data.code,
                    // nit: data.company.nit,
                    // provider: data.company.name,
                    object: data.object,
                    validSince: moment(data.validSince).format('YYYY-MM-DD'),
                    validUntil: moment(data.validUntil).format('YYYY-MM-DD'),
                    plants: arrayMultipleData(data.plants, listScope),
                    company: data.company !== null ? provider : data.company
                })
                
                if (moment(data.validUntil).isBefore(moment(new Date())) && !data.isActive) {
                    setDisabled(true)
                }
            }
            setLoading(false)
        }
        call()
    }, [])

    async function getPlants() {
        if (ability.can("PlantList")) {
            let response = await plantApi.list();
            let result = check(response)

            if (result.status) {
                return response.data
            }
            else {
                toast.error(() => <>{result.errors}</>)
            }
            return []
        }
        return []
    }

    async function getContractDocuments() {
        if (ability.can("GetContractDocument")) {
            let response = await contractDocumentApi.list();
            let result = check(response)
    
            if (result.status) {
                setContractDocuments(response.data)
            }
            else {
                toast.error(() => <>{result.errors}</>)
            }
        }
    }
  
    async function getPlantCategories() {
		const response = await plantCategoryApi.list();
		const result = check(response);
	
		if (result.status) {
			setCategories(response.data);
		}
		else {
			toast.error(() => 
				<div>{t('plotPlans.plotPlanForm.plotPlanCreated.error')}<br/>
					{result.errors}
				</div>
			);
		}
    }

    async function loadCompanies() {
        if (ability.can("AllCompanies")) {
            let response = await companyApi.list(true);
            let result = check(response)

            if (result.status) {
                let data = response.data?.map((item) => {
                    return {
                        ...item,
                        provider: `${item?.nit ?? ""}-${item?.dv ?? ""}, ${item?.name ?? ""}`
                    }
                })
                setCompanyList(data)
            }
            else {
                toast.error(() => <>{result.errors}</>)
            }
        }
    };

    function handleChange(event, name, type) {
        if (type === 'select') {
            setForm({...form, [name]: event})
        }
        else {
            setForm({...form, [name]: event.target.value})
        }
        setErrors({...errors, [name]: {...errors[name], error: ''}})
    }

    function checkForm() {
        let validate = validateForm(form, errors)
        if (validate.hasErrors) {
            setErrors(validate.errors)
            return
        }
        handleSave(form)
    }

    return (
        <Container>
            <Header>{data?.id ? t('contract.modal.edit') : t('contract.modal.create')}</Header>
            <Body>
                <Contract 
                    form={form}
                    dataForm={data}
                    errors={errors}
                    plants={plants}
                    documents={contractDocuments}
                    companyList={companyList}
                    categories={categories}
                    disabled={disabled}
                    handleChange={handleChange}
                />
            </Body>
            <Footer>
                <SectionRight>
                    <FormButton 
                        variant="outlined" 
                        type="close" 
                        onClick={() => handleClose(false)}
                    >
                        {t('common.cancel')}
                    </FormButton>
                    <Spacer/>
                    <FormButton
                        variant="contained"
                        onClick={checkForm}
                    >
                        {t('common.save')}
                    </FormButton>
                </SectionRight>
            </Footer>
            <BackdropLoading open={loading}/>
        </Container>
    )
}