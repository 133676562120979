import {styled} from "@mui/material/styles";
import { TextField } from "@mui/material";
import { BaseAutocomplete, MediaQuerys } from "../../styles/GlobalStyle";
const { mobile, desktop } = MediaQuerys;

export const CustomAutocomplete = styled(BaseAutocomplete)(({increaseHeight}) => ({
    width: '100%',
    borderRadius: 6,
    fontSize: 13,
    '.MuiInputBase-root': {
        paddingBottom: increaseHeight ? 80 : '',
    },
    '.MuiInputBase-input': {
        padding: 8
    },
    [mobile]: {
        '.MuiInputLabel-root': {
        fontSize: '0.75rem',
      },
    },
    [desktop]: {
        '.MuiFormLabel-root': {
            fontSize: '0.85rem'
        }
    }
}))

export const Input = styled(TextField)(() => ({
    width: '100%',
    '.MuiInputBase-root': {
        borderRadius: 6,
        fontSize: 13
    }
}))
