import { useTranslation } from 'react-i18next'

import BrowserNotSupportedIcon from '@mui/icons-material/BrowserNotSupported';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export const ComponentNoDataCharts = ({isFlow}) => {

    const { t } = useTranslation();

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            sx={{ width: '100%', padding: '3rem', backgroundColor: '#f1f1f1' }}
        >
            <BrowserNotSupportedIcon sx={{ fontSize: '5rem', color: '#888' }} fontSize="large" />
            <Typography variant="caption" align="center" color='#888' mt={1}>
                {t('common.notAvailableData', { dataType: isFlow ? 'flujo de trabajo' : 'gráfico' })}
            </Typography>
        </Box>
    )
}

