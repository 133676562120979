import styled from 'styled-components';
import Box from "@mui/material/Box";
import withColors from '../../../../styles/withColors';
import { MediaQuerys } from '../../../../styles/GlobalStyle';
import { useColors } from "../../../../Context/ColorsContext"

const { mobile, tablet, desktop } = MediaQuerys;

// const tabletStartWidth = 768;
// const mobile = `@media (max-width: ${tabletStartWidth}px)`;

const ContainerStyle = styled.div`
  width:100%;
  height:100%;
  display:flex;
  justify-content:center;
  align-items:center;
  padding-bottom:5em;
  /*
  background: ${({ colors }) => colors.red};
  */
  ${mobile} {
    flex-direction:column;
    height:max-content;
    padding:1em;
  }
`

const FormStyle = styled.div`
  display:flex;
  flex-direction:column;
  align-items:center;
  ${mobile} {
    width:100%;
    padding:0 1em;
  } 
`

const TitleStyle = styled.p`
  font-size:2.2rem;
  font-weight: bold;
  color:${({ colors }) => colors.blackFont};
  ${mobile} {
    font-size:1.8rem;
  }
`

const SectionRowStyle = styled.section`
  display:flex;
  width:100%;
  padding:.2em 0 .7em;
  align-items:center;
  text-align:left;
  justify-content:center;
  ${mobile} {
    flex-direction:column;
    align-items: center;
  }
`

const SectionInputStyle = styled.section`
  display:flex;
  flex-direction:column;
  width:350px;
  margin-left: ${props => (props.second ? '2em' : '')};
  ${mobile} {
    width:100%;
    margin-top: ${props => (props.bottom ? '1em' : '')};
    margin-left:0;
  }
`

const LabelStyle = styled.label`
  padding-bottom:.3em;
  padding-left:.3em;
  color:${({ colors }) => colors.blackFont};
  ${tablet} {
    font-size: '0.75rem !important';
  }
`

const ContainerInputStyle = styled.div`
  background: ${({ colors }) => colors.gray};
  border-bottom:2px solid ${({ colors }) => colors.gray};
  height:2em;
  padding:0 .5em;
  display:flex;
  align-items:center;
  border-radius:5px;
`

const FigureStyle = styled.figure`
  display:flex;
  justify-content:center;
  margin-left: ${props => (props.marginLeft ? ".5em" : "")};
  margin-top: ${props => (props.marginTop ? ".3em" : "")};
  margin-bottom: ${props => (props.marginBottom ? ".3em" : "")};
  margin-right: ${props => (props.marginRight ? "1em" : "")};
`

const InputStyle = styled.input`
  background: ${({ colors }) => colors.gray};
  width:100%;
  color:${({ colors }) => colors.blackFont};
  &::-webkit-inner-spin-button, ::-webkit-outer-spin-button  {
    -webkit-appearance: none; 
    margin: 0; 
  }
  -moz-appearance:textfield;
`

const SpanStyle = styled.span`
  width: 100%;
  text-align: center;
  color: ${({ colors }) => colors.red};
  padding-top:.3em;
  padding-left:.3em;
  height:1.5em;
  &.activate {
    visibility:visible;
    transition:all .3s ease;
  }
  &.closed {
    visibility:hidden;
    transition:all .3s ease-in-out;
  }
`

const SpanLinkStyle = styled.p`
  width: 100%;
  text-align: center;
  color: ${({ colors }) => colors.blackFont};
  text-decoration: underline;
  padding-top:.3em;
  padding-left:.3em;
  height:1.5em;
  &.activate {
    visibility:visible;
    transition:all .3s ease;
  }
  &.closed {
    visibility:hidden;
    transition:all .3s ease-in-out;
  }
`

const SectionBtnStyle = styled.section`
  text-align: center;
  padding:1em 0 2em;
  width:100%;
  display: ${props => (props.double ? 'block' : 'flex')};
  justify-content: center;
  ${mobile} {
    padding:1.5em 0 2em;
  }
`

const BtnSubmitStyle = ({ colors, disabled, ...props }) => (
  <button
    {...props}
    style={{
      color: colors.white,
      background: disabled ? colors.gray : colors.buttonActionColor,
      cursor: disabled ? 'not-allowed' : 'pointer',
      padding: '.5em 2em',
      borderRadius: '5px',
      display: 'inline-block',
      justifyContent: 'center',
      transition: 'all .3s ease-in-out',
      width: '50%',
      '&:hover': {
        transition: 'all .3s ease',
        opacity: '.8',
      },
      '&:active': {
        transition: 'all .3s ease',
        opacity: '1',
      },
    }}
  />
);

const BoxModalStyle = styled(Box)(() => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}))

const CustomLabel = styled('label')(() => {
  const { colors } = useColors();
  return {
    paddingBottom: '.3rem',
    paddingLeft: '.3rem',
    color: colors.blackFont,
    [desktop]: {
      fontSize: '1rem',
    }
  }
})

export const Subtitle = styled('span')(() => {
  const { colors } = useColors();
  return {
      color: colors.blackFont,
      fontSize: '1.2rem',
      paddingBottom: '2em',
      [mobile]: {
        paddingBottom: '2.5em',
      }
  }
})


export const Container = withColors(ContainerStyle);
export const Form = withColors(FormStyle);
export const Title = withColors(TitleStyle);
export const SectionRow = withColors(SectionRowStyle);
export const SectionInput = withColors(SectionInputStyle);
export const Label = withColors(LabelStyle);
export const ContainerInput = withColors(ContainerInputStyle);
export const Figure = withColors(FigureStyle);
export const Input = withColors(InputStyle);
export const Span = withColors(SpanStyle);
export const SpanLink = withColors(SpanLinkStyle);
export const SectionBtn = withColors(SectionBtnStyle);
export const BtnSubmit = withColors(BtnSubmitStyle);
export const BoxModal = withColors(BoxModalStyle);
export const LabelCustom = withColors(CustomLabel);