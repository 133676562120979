import React, { Fragment, useEffect } from 'react';
import { CircularProgress, Tooltip } from '@mui/material';
import Router from '../../router';
import {
    Area,
    ArrowLeft,
    ArrowsLeft,
    CardBackground,
    Container,
    GridCenter,
    IconArea,
    Title,
    ContainerBottom,
    ContainerGroup,
    ContainerLeft,
    ContainerRight,
    ContainerTypography,
    CustomCard,
    GroupImage,
} from './styles';

import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import { ColorsContext } from "../../Context/ColorsContext"
import { useTranslation } from 'react-i18next'

export const CardImage = ({ data, root, loading, onSelect, goBack, goBegin, isModal = false }) => {
    const { t } = useTranslation();
    const { colors } = React.useContext(ColorsContext);

    const cardRef = React.useRef(null);
    const imgRef = React.useRef(null);

    const [imageDimensions, setImageDimensions] = React.useState({ width: 0, height: 0 });

    async function addImageProcess(src) {
        return new Promise((resolve, reject) => {
            let img = new Image()
            img.onload = () => resolve(img)
            img.onerror = reject
            img.src = src
        })
    }

    const addPoints = (childNodes) => {

        return childNodes?.map((obj, index) => {
            if (obj.parentX !== null && obj.parentY !== null) {
                const coordX = (obj.parentX / imageDimensions.width) * 100
                const coordY = (obj.parentY / imageDimensions.height) * 100

                return (
                    <Area
                        key={"area-" + index}
                        onClick={() => onSelect(obj)}
                        left={coordX}
                        top={coordY}
                        zIndex={obj.height}
                    >
                        {obj.element &&
                            <Tooltip title={<span>{obj.name}<br></br>{t('plotPlans.height')}: {obj.height}m</span>}>
                                <IconArea>{obj.element}</IconArea>
                            </Tooltip>
                        }
                    </Area>
                )
            }
        })
    }

    useEffect(() => {
        const loadImage = async () => {
            if (!data.imgSource) return;
            try {
                if (data.imgSource !== null) {
                    const img = `${Router.apiBaseUrl}/${data.imgSource}`;
                    const res = await addImageProcess(img);
                    setImageDimensions({ width: res.width, height: res.height });
                }
            } catch (error) {
            }
        };

        loadImage();
    }, [data.childNodes, data.imgSource])

    return (
        <>
            <GroupImage id='Container-Img' modal={isModal}>
                {loading &&
                    <GridCenter container justifyContent="center" alignItems="center">
                        <CircularProgress size={60} />
                    </GridCenter>
                }
                <Container id="card-image" ref={cardRef} modal={isModal} isThereImg={data.imgSource}>
                    {!loading &&
                        <Fragment>
                            {data.imgSource
                                ?
                                <>
                                    <CardBackground
                                        component="img"
                                        image={`${Router.apiBaseUrl}/${data.imgSource}`}
                                        alt={data.name}
                                        ref={imgRef}
                                    />
                                    {addPoints(data.childNodes)}
                                </>
                                :
                                <GridCenter container direction="column">
                                    <ImageNotSupportedIcon sx={{ color: colors.grayFont, width: '70px', height: '70px' }} />
                                    <Title>{t('common.empty.noImage')}</Title>
                                </GridCenter>
                            }
                        </Fragment>
                    }
                </Container>
            </GroupImage>
            <CustomCard>
                <ContainerBottom>
                    <ContainerGroup>
                        <ContainerLeft>
                            <ArrowsLeft onClick={() => goBegin(data)} disabled={(data.id === root.id || data.isRoot || data.parentNode?.nodeLevel === undefined) ? 1 : 0} />
                        </ContainerLeft>
                        <ContainerRight>
                            <ArrowLeft onClick={() => goBack(data)} disabled={(data.id === root.id || data.isRoot || data.parentNode?.nodeLevel === undefined) ? 1 : 0} />
                        </ContainerRight>
                    </ContainerGroup>

                    <ContainerTypography>
                        <Title variant='body2'>
                            <span><strong>ID:</strong>{data.id},</span>
                            <span><strong>Path:</strong>{data.nodePath}</span>
                        </Title>
                    </ContainerTypography>
                </ContainerBottom>
            </CustomCard>
        </>
    )
}

