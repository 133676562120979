import React, { useEffect } from 'react';
import { RoleApi } from '../../../../services/RoleApi';
import { MediaQuerys } from '../../../../styles/GlobalStyle';

// Components
import { Button } from '@mui/material';
import { FormButton } from '../../../../pages/styles';

import { TransferList } from '../../../TransferList';

// Styles
import {
    Container,
    Header,
    Body,
    Footer,
    SectionRight,
    Spacer
} from './styles'

// Utils
import { ColorsContext } from "../../../../Context/ColorsContext"
import { useTranslation } from 'react-i18next'

// Permissions
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../../../Context/PermissionsContext';

export const RelationRoles = ({ roleId, title, loading, handleClose, handleChangeRelateRoles }) => {

    const { t } = useTranslation();
    const roleApi = new RoleApi();
    const { colors } = React.useContext(ColorsContext);
    const ability = useAbility(AbilityContext);
    const { mobile, tablet } = MediaQuerys;

    const permissionRef = React.useRef([])
    const dataRef = React.useRef(false)
    const [rows, setRows] = React.useState([])
    const [listRelations, setListRelations] = React.useState([])
    const [listPermissionsFilter, setListPermissionsFilter] = React.useState([])

    const [itemsFilter, setItemsFilter] = React.useState([])

    const handleChange = (value) => {
        permissionRef.current = value
    }

    const getListLeft = () => {
        return listRelations
            .filter((item) => {
                let tempItem = { id: item.id, name: item.name }
                let isFound = false
                rows.forEach((value) => {
                    let tempValue = { id: value.id, name: value.name }
                    if (JSON.stringify(tempValue) === JSON.stringify(tempItem)) {
                        isFound = true
                    }
                })

                return !isFound
            })
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((item) => ({ key: item.id, text: item.name }))
    }

    const getListRight = () => {
        return rows
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((item) => ({ key: item.id, text: item.name }))
    }

    useEffect(() => {
        if (dataRef.current === false) {

            async function loadRelationsList() {
                roleApi.list().then((response) => {
                    if (response?.status === 200) {
                        let resp = response.data.data
                        setListRelations(resp)
                    }
                })
            }

            async function handleRelations() {
                await roleApi.relation(roleId).then((response) => {
                    if (response?.status === 200) {
                        let resp = response.data.data
                        setRows(resp)
                        loadRelationsList()
                    }
                })
            }

            handleRelations()

            dataRef.current = true
        }
    }, [roleId, roleApi])

    React.useEffect(() => {
        setListPermissionsFilter(listRelations)
      }, [listRelations])

    return (
        <Container>
            <Header>{title}</Header>
            <Body>
                {listRelations.length > 0 && <TransferList
                    leftList={getListLeft()}
                    rightList={getListRight()}
                    onChange={handleChange}
                    titleLeft={t('system.permissions.toAssignRole')}
                    titleRight={t('system.permissions.assignedRole')}

                    listPermissions={listRelations}
                    listPermissionsFilter={listPermissionsFilter}
                    setListPermissionsFilter={setListPermissionsFilter} 
                    itemsFilter={itemsFilter} 
                    setItemsFilter={setItemsFilter}

                    placeholder={'relations'}
                />}
            </Body>
            <Footer>
                <SectionRight>
                    <FormButton
                        variant="outlined"
                        type='close'
                        onClick={() => handleClose()}
                        disabled={loading}
                    >
                        {t('common.cancel')}
                    </FormButton>
                    <Spacer />
                    {ability.can("UpdateRelationRoles") &&
                        <FormButton
                            onClick={() => handleChangeRelateRoles(roleId, permissionRef.current)}
                            disabled={loading}
                        >
                            {t('common.confirm')}
                        </FormButton>
                    }
                </SectionRight>
            </Footer>
        </Container>
    )
}