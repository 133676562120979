import React from 'react'
import Router from '../../router';

// Components
import { PasswordRecoveryFormContainer } from '../../components/molecules/forms/PasswordRecoveryForm/container'
import { LanguageSelect } from '../../components/atoms/languageSelect'
import { ColorsContext } from '../../Context/ColorsContext'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// Styles
import { LanguageContainer } from '../styles'


// Styles
import {
  Container,
  SectionLeft,
  SectionRight,
  ContainerForm,
} from './styles'

export const PasswordRecovery = () => {

    const { openBackDrop, contentRender, colors } = React.useContext(ColorsContext);

    return (
        !contentRender ? (
            <>
              <Backdrop
                sx={{ color:"#fff", zIndex: (theme) => theme.zIndex.drawer + 1000 }}
                open={openBackDrop}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            </>
        ) : (
            <Container>
                <SectionLeft imgUrl={`${Router.apiBaseUrl}${Router.appStaticAppearance}/bg_login.jpg`}>
                </SectionLeft>
                <SectionRight>
                    <LanguageContainer><LanguageSelect/></LanguageContainer>
                    <ContainerForm>
                        <PasswordRecoveryFormContainer />
                    </ContainerForm>
                </SectionRight>
            </Container>
        )
    )
}
