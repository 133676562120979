import React, { useEffect, useState } from 'react'

// Components
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { DynamicForm } from '../DynamicForm'
import { WorkPermitDetail } from '../WorkPermitDetail'
import { Grid } from '@mui/material'
import { BackdropLoading } from '../../components/BackdropLoading'

// Styles
import { Container } from './styles'

// Service
import Router from '../../router'
import { ExternalActionApi } from '../../services/ExternalActionApi'

// Utils
import { Auth } from '../../utils/auth'
import { CheckResponse } from '../../utils/checkResponse'
import { redirectToAction } from '../../utils/commonFunctions'

import { useTranslation } from 'react-i18next'

export const ExternalActions = ({ externalUserData }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const {isAuthenticated} = new Auth()
    const {check} = new CheckResponse()
    const externalActionApi = new ExternalActionApi
    const [type, setType] = useState("")
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (location.search) {
            let search = location.search.split('?hash=')[1]
            async function call() {
                searchHash(search)                
            }
            call()
        }
        else {
            navigate(Router.appLogin)
        }
    }, [])

    async function searchHash(search) {
        setLoading(true)
        let response = await externalActionApi.getNotification(search)
        let result = check(response)

        if (result.status) {
            externalUserData(response.data?.userEmail)
            await redirectAction(response.data)
            setLoading(false)
        }
        else {
            setLoading(false)
            toast.error(() => 
                <div>{t('workflow.externalUsers.errors.error')}<br/>
                    {result.errors}
                </div>
            );
            navigate(Router.appLogin)
        }
    }

    async function redirectAction(data) {
        let redir = await redirectToAction(data.workflowData, [data]);
        let row = redir.data?.state
        setData(row)

        if (isAuthenticated()) {
            if (!data?.isExternal) {
                navigate(redir.route, {state: row})
            }
            else {
                setType(redir.route?.split('/')[1])
            }
        }
        else {
            navigate(Router.appLogin, {state: {data: row, redirect: redir.route}})
        }
    }

    return (
        <Container container>
            <Grid item xs={1}></Grid>
            <Grid item xs={10}>
                {type === 'dynamic-form' &&
                    <DynamicForm data={data}/>
                }
                {type === 'workflow' &&
                    <WorkPermitDetail data={data} customExternalWidth='90vw'/>
                }
            </Grid>
            <Grid item xs={1}></Grid>
            <BackdropLoading open={loading}/>
        </Container>
    )
}
