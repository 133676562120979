import {styled} from "@mui/material/styles";
import { MenuItem } from '@mui/material';
import { MediaQuerys } from "../../../styles/GlobalStyle";

const { tablet, desktop } = MediaQuerys;

export const CustomMenuItem = styled(MenuItem)(() => ({
}))

export const CustomItem = styled('span')(({bold}) => ({
    paddingLeft: 6,
    fontWeight: bold ? 'bold' : 'normal',
    [desktop]: {
        fontSize: '0.75rem'
    }
}))

export const Image = styled('img')(() => ({
    width: '26px',
    [desktop]: {
        width: '1.5rem',
    },
    [tablet]: {
        width: '1.5rem',
    }

}))