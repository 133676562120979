import {styled} from "@mui/material/styles";
import { IconButton, TableCell } from "@mui/material";
import { useColors } from "../../../../Context/ColorsContext"
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { MediaQuerys } from "../../../../styles/GlobalStyle";
import TableBody from '@mui/material/TableBody';


const { mobile, tablet, desktop, desktoplarge } = MediaQuerys;


function setColor(title) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { colors } = useColors();
  let titleLower = title ? title.toLowerCase() : '';
  if (titleLower === 'aprobación pendiente') return colors.purple
  if (titleLower === 'ejecución plan de aislamiento') return colors.purple
  if (titleLower === 'ejecución prueba de gases') return colors.purple
  if (titleLower === 'en ejecución') return colors.green
  if (titleLower === 'cerrado') return colors.blue
}

export const CustomTableCell = styled(TableCell)(() => {
  const { colors } = useColors();
  return {
    color:colors.main,
    fontSize:'1rem',
    fontWeight:600,
    paddingTop:'10px !important',
    paddingBottom:'10px !important',
    alignItems:'center',
    padding: "0 16px",
    "&:hover": {
      color: colors.buttonActionColor,
      fontWeight: 700,
    },
    [desktop]: {
      fontSize: "0.75rem !important",
    },
  }
})

export const TableCellStatus = styled(TableCell)(() => ({
    display:'flex',
    alignItems: 'center',
    border: 'none'
}))
export const ContainerCell = styled('div')(() => ({
    display:'flex',
    alignItems:'center',
}))

export const Figure = styled('figure')(({title}) => ({
  display:'flex',
  justifyContent:'center',
  alignItems:'center',
  background: title,
  height:'30px',
  width:'30px',
  minWidth:'30px',
  borderRadius:'50%',
  marginRight:'10px !important',
}))

export const SectionInfo = styled('section')(() => ({
    display:'flex',
    flexDirection:'column'
}))

export const InfoCell = styled('div')(() => ({
}))

export const Label = styled('label')(() => ({
  fontSize:'14px',
  paddingRight:'5px',
  [desktop]: {
    fontSize:'0.75rem',
  },
  [tablet]: {
    fontSize: '10px'
  },
  [mobile]:{
    paddingRight:'0px',
  }
}))

export const InputDate = styled('input')(({theme}) => {
    const { colors } = useColors();
  return {
    marginRight:'20px',
    borderBottom:'1px solid '+colors.grayFont,
    borderRadius:'3px',
    fontFamily: theme.typography.fontFamily,
    padding:'5px 10px',
    width:'150px',
    [desktop]: {
      width:'130px',
    },
    [tablet]: {
      width: '100px',
      height: 20,
      marginRight:'5px',
    },
    [mobile]: {
      width: '80px',
      height: 20,
      padding: '5px 5px',
      marginRight:'10px',
  
    }
  }
})

export const FigureHead = styled('figure')(() => ({
  display:'flex'
}))

export const Cell = styled(TableCell)(() => ({
  border: 'none'
}))

export const IconAction = styled(IconButton)(() => {
   const { colors } = useColors();
  return {
    borderRadius: 20,
    pointerEvents: "pointer",
    background: colors.buttonActionColor,
    marginLeft: 5,
    "&:hover": {
      backgroundColor: colors.buttonActionColor,
      opacity: 0.9,
      color: colors.white,
    },
     [desktop]: {
      fontSize: "0.25rem",
      width: '28px',
        height: '28px',
        "&MuiSvgIcon-root": {
          width: '16px'
      }
    },
    [tablet]: {
      height: 28,
      width: 28,
    },
    [mobile]: {
      display: 'none',
    },
  };

})

export const CleanIcon = styled(CleaningServicesIcon)(() => {
  const { colors } = useColors();
  return {
    color: colors.white,
    fontSize: 18
  }
})

export const NewIcon = styled(AddCircleIcon)(() => {
   const { colors } = useColors();
  return {
    height: '20px',
    width: '20px',
    color: colors.white,
    // fontSize: 18,
    [desktop]: {
      height: '16px',
      width: '16px'
    }
  };
})

export const IconContainer = styled(IconButton)(() => {
  const { colors } = useColors();
  return {
    borderRadius: '10%',
    backgroundColor: colors.background,
    color: colors.buttonActionColor,
    "&:hover": {
      backgroundColor: colors.buttonActionColor,
      color: colors.white,
    }
  }
})

export const TableContent = styled(TableBody)(() => ({
  ".MuiTableCell-root": {
    fontSize: "1rem !important",
    [desktop]: {
      fontSize: "0.75rem !important",
    },
    [mobile]: {
      fontSize: "0.70rem !important",
    },
  },
  [tablet]: {
    '.MuiTablePagination-displayedRows': {
      fontSize: '0.75rem !important',
    },
    '.MuiTablePagination-selectLabel':{
      fontSize: '0.75rem!important',
    }
  },
}));

export const Forms = styled('form')(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'flex-start',
  // [tablet]: {
  //   justifyContent: 'space-between',
  // },
  [mobile]: {
    height: 'auto',
    marginTop: '5px',
    alignItems: 'flex-start',
    flexWrap: 'nowrap',
  }
}))


export const ContainerContent = styled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'row',
    gap: '15px',
    [mobile]: {
      justifyContent: 'flex-end'
      // flexDirection: 'column',
      // gap: '5px',
    }
  }
})


export const ContainerSearch = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems:'center',
  gap: '5px',
}))



export const ContainerGroup = styled('div')(() => ({
  display:'flex',
  flexDirection: 'row',
}))


export const ContainerInputsDate = styled('div')(() => ({
  display:'flex',
  flexDirection:'row',
  alignItems:'center',
  gap: '0.25rem',
}))

export const ContainerButonsEdit = styled('div')(() => {
  return {
    display:'flex',
    flexDirection:'row',
    justifyContent: 'center',
    gap: '0.25rem',
  }
})