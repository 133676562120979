import React, { useEffect, useState } from "react";

// Components
import { toast } from "react-toastify";

// Custom components
import { ShowDialog } from "../../components/ShowDialog";
import { StandardWorkflowFormCard } from "../../components/atoms/modalCards/StandardWorkflowFormCard";

// Service
import { WorkflowStatusApi } from "../../services/WorkflowStatusApi";
import { useNavigate } from "react-router-dom";

// Utils
import { validateForm } from '../../utils/commonFunctions';
import { CheckResponse } from "../../utils/checkResponse";
import { useTranslation } from "react-i18next";

export const StandardWorkflowContainer = ({ data, roles, executeOptions, workflowId, isAssignRole, loadData, handleCloseForm, isStandardFlow }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const workflowStatusApi = new WorkflowStatusApi();

	const { check } = new CheckResponse();

	const [form, setForm] = useState({
		title: '',
		subtitle: '',
		color: '#5e72e4',
		icon: '',
		maxTime: '',
		minTime: '',
    notificationEmail: false,
		workflowId: null,
		roles: [],
		rolesId: [],
		executeOption: '',
		executeOptionId: null
	});

	const [errors, setErrors] = useState({
		title: {error: "", field: t('standardStatus.table.title'), required: true}
	})

	const [dialog, setDialog] = useState({
		status: false,
		title: "",
		message: ""
	});

	useEffect(() => {
		setForm({...data})
		let selRoles = []
		if (data.roles?.length > 0) {
			data.roles.map((el) => {
				let objRoles = roles.filter((obj) => obj.id === el.id)
				selRoles.push(...objRoles)
			})
			setForm({...data, roles: selRoles})
		}

	}, [roles])

	function handleChange(e, name) {
		setErrors({...errors, [name]: {...errors[name], error: ''}})
		if (name === 'color') setForm({...form, [name]: e.hex});
    else if (name === 'notificationEmail') setForm({...form, [name]: e.target.checked});
    else if (name === 'roles') {
			setForm({...form, 
				roles: e 
			});
		}
    else if (name === 'executeOption') {
			setForm({...form, 
				executeOption: e
			});
		}
		else setForm({...form, [name]: e.target.value});
	}

	async function handleSave(redirect) {
		let data = {...form}
		if (isAssignRole) {
			data.rolesId = form.roles?.map((el) => el.id), 
            data.executeOptionId = form.executeOption?.id
			data.workflowId = workflowId
		}
		
		let validate = validateForm(data, errors)
		if (validate.hasErrors) {
			setErrors(validate.errors)
			return
		}

		let response = await workflowStatusApi.updateWorkflowStandard(data);
		let result = check(response);

    if (result.status) {
			setErrors({title: {error: "", field: t('standardStatus.table.title'), required: true}})
      await loadData();

      setDialog({
        status: true,
        title: t("standardStatus.messages.editWorkflow.title"),
        message: t("standardStatus.messages.editWorkflow.message"),
				redirect: redirect
      });
    } else {
      toast.error(() => (
        <div>
          {t("standardStatus.messages.editWorkflow.error")}
          <br />
          {result.errors}
        </div>
      ));
    }
  }

  function handleCloseDialog() {
		handleCloseForm()
    setDialog({ ...dialog, status: false });
		if (dialog.redirect) {
			navigate(dialog.redirect)

		}
  }

  return (
		<>
			<StandardWorkflowFormCard
				title={t("standardStatus.editWorkflow")}
				form={form}
				errors={errors}
				roles={roles}
				executeOptions={executeOptions}
				isAssignRole={isAssignRole}
				handleChange={handleChange}
				handleClose={handleCloseForm}
				handleSave={handleSave}
				isStandardFlow={isStandardFlow}
			/>
			
			<ShowDialog
        openDialog={dialog.status}
        dialog={dialog}
        handleCloseDialog={handleCloseDialog}
      />
		</>
  );
};
