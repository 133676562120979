import React, { useEffect } from 'react'

// Components
import { SelectContracts } from '../../../../../SelectContracts';

// Styles
import {
  ContainerInfo,
  ContainerInput,
} from '../../styles'

// Utils
import { useTranslation } from 'react-i18next'
import { Auth } from '../../../../../../utils/auth';

// Permissions
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../../../../../Context/PermissionsContext';


export const InternalRoleWithoutContract = ({
  data,
  handleChange,
  form,
  categories,
  plants,
  selectedPlants,
  errors,
  isSupervisor
}) => {
  const ability = useAbility(AbilityContext);
  const { t } = useTranslation();

  const user = React.useRef({});
  const { getUser } = new Auth();

  useEffect(() => {
    async function call() {
      user.current = getUser();
      getDisabledPlants();
    }
    call()
}, [])

  function getDisabledPlants() {

    if (isSupervisor('internalWithoutContract')) {
      const profile = JSON.parse(localStorage.getItem('profile'));
      if (profile?.ContractPlants !== null) {
        const uniquePlants = uniquePlantsArray(profile.ContractPlants)
        plants.map((el) => {
          el.disabled = ![...uniquePlants].some((plant) => plant.Id === el.id)
        })
      }
    }
  }

  const uniquePlantsArray = (data) => {
    const uniquePlants = new Set();

    data.forEach(contract => {
      const plantsJSON = contract.Plants;
      plantsJSON.forEach(plant => {
        uniquePlants.add(plant);
      });
    });

    return uniquePlants
  }

  return (
    <ContainerInfo>
      {ability.can("PlantList") && 
        <ContainerInput isFull>
          <SelectContracts
            name='plants'
            placeholder=""
            label={t('contract.table.sites')}
            onChange={e => handleChange(e, 'plants')}
            value={form.plants}
            categories={categories}
            selectedPlants={selectedPlants}
            keyValue="name"
            options={plants}
            width="100%"
            increaseHeight={true}
            multiple={true}
            disableCloseOnSelect={false}
            limitTags={22}
            hasError={errors?.plants?.hasError}
            messageError={errors?.plants?.message}
          />
        </ContainerInput>
      }
    </ContainerInfo>
  )
}
