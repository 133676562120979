import React from "react";

// Components
import { FormButton } from "../../../../pages/styles";
import {
  Grid,
} from "@mui/material";

// Styles
import {
  Container,
  Header,
  Body,
  Footer,
  ContainerInfo,
  SectionRight,
  Spacer,
  SectionTopInfo,
  SectionBottomInfo,
  Text,
  CustomTextField,
  CustomFormControl,
  CustomCheckbox,
} from "./styles";

// Utils
import { useTranslation } from 'react-i18next'

export const RoleModalCard = ({
  title,
  data,
  errors,
  loading,
  handleClose,
  handleChange,
  handleAccept,
}) => {
	const { t } = useTranslation();
  
  return (
    <Container>
      <Header>{title}</Header>
      <Body>
        <ContainerInfo>
          <SectionTopInfo>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <CustomTextField
                  label={t("system.permissions.name")}
                  value={data.name}
                  onChange={(e) => handleChange(e, "name")}
                  sx={{ width: "100%" }}
                  error={errors.name.hasError}
                  helperText={errors.name.message}
                  disabled={loading}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomTextField
                  label={t("system.permissions.table.description")}
                  value={data.description}
                  onChange={(e) => handleChange(e, "description")}
                  sx={{ width: "100%" }}
                  error={errors.description.hasError}
                  helperText={errors.description.message}
                  disabled={loading}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomFormControl label={t('system.permissions.table.isExternal')}
                  control={
                    <CustomCheckbox
                      checked={data.isExternal}
                      onChange={(e) => handleChange(e, "isExternal")}
                    />
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <CustomFormControl label={t('system.permissions.table.isRestricted')}
                  control={
                    <CustomCheckbox
                      checked={data.isRestricted}
                      onChange={(e) => handleChange(e, "isRestricted")}
                      disabled={data.isExternal}
                    />
                  }
                />
              </Grid>
            </Grid>
          </SectionTopInfo>
          {errors.general.hasError && (
            <SectionBottomInfo>
              <Text>{errors.general.message}</Text>
            </SectionBottomInfo>
          )}
        </ContainerInfo>
      </Body>
      <Footer>
        <SectionRight>
            <FormButton
              variant="outlined"
              onClick={() => handleClose()}
              disabled={loading}
              type="close"
            >
              {t('common.cancel')}
            </FormButton>
            <Spacer />
            <FormButton
              onClick={() => handleAccept()}
              disabled={loading}
            >
              {t('common.save')}
            </FormButton>
        </SectionRight>
      </Footer>
    </Container>
  );
};
