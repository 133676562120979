import { Auth } from "../utils/auth";
import { HealthCheckApi } from "./HealthCheckApi";
import Router from "../router";
import axios from "./AxiosConfig";

export class CompanyApi {
    healthCheck = null;
    token = null;

    constructor() {
        let auth = new Auth();
        this.token = auth.getToken();
        this.healthCheck = new HealthCheckApi();
    }

    async list(isEnabled) {
        return await axios.get(Router.apiBaseUrl + Router.apiCompany, {
            params: {
                isEnabled: isEnabled
            }
        })
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)
        })
    }

    async create(data) {
        return await axios.post(Router.apiBaseUrl + Router.apiCompany, data)
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)
        });
    }

    async update(id, data) {
        return await axios.put(Router.apiBaseUrl + Router.apiCompany + `/${id}`, data)
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)
        })
    }

    async delete(id) {
        return await axios.delete(Router.apiBaseUrl + Router.apiCompany + `/${id}`, {})
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)
        })
    }
    
}