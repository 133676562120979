import { useEffect, useState, useRef } from "react";
import { Grid, Tooltip, formLabelClasses } from "@mui/material";
import { ClearIconButton, SearchButton } from "../../../../pages/styles";
import { useLocation, useParams } from "react-router-dom";
// Multi Select

import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";

import {
  CleanIcon,
  CustomToolbar,
  IconAction,
  InputDate,
  Label,
  NewIcon,
} from "./styles";
import { CommentsDisabledOutlined, Filter } from "@mui/icons-material";

export function TableToolbar(props) {
  const {
    handleDatesFilter,
    handleCreateNew,
    handleClearDate,
    hideFilterDate,
    title,
    initialDate,
    finalDate,
    setInitialDate,
    setFinalDate,
    customAdd,
    elementAdd,
    rows,
    selectedTags,

    setFilteredRequest,
    listWorkFlows,

    filterStatus,
    standardSelects,
    listAllworkflows,

    setStandardSelects,
    setListAllWorkflows,

    loadWorkflowsFilters,
  } = props;

  const ITEM_HEIGHT = 50;
  const ITEM_PADDING_TOP = 8;

  const [selectedState, setSelectedState] = useState([]);
  const [selectedOpt, setSelectedOpt] = useState([]);
  const [arrayCombined, setArrayCombined] = useState([]);
  const [statusLoading, setStatusLoading] = useState(false);

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  // Method to unite the standard and default states

  const ArrayStatusCombined = (array1, array2) => {
    // if (selectedOptions.current.length > 0) {
    if (selectedTags.current.length > 0) {
      const newArrayStatus = array1
        .map((row) => ({ id: row.id, title: row.title }))
        .concat(array2.map((row) => ({ id: row.id, title: row.title })));

      setArrayCombined(
        newArrayStatus.sort((a, b) => a.title.localeCompare(b.title))
      );
    } else {
      setArrayCombined([]);
    }
  };

  // Method to filter per flows
  const filterByIdWorkFlow = (arraySelects, tableInfo) => {
    if (arraySelects.length > 0 && tableInfo.length > 0) {
      // Verificar si ambos arrays contienen elementos
      let titleArray = [];
      let arrayTable = [];

      for (let i = 0; i < arraySelects.length; i++) {
        titleArray.push(arraySelects[i].id);
      }

      for (let i = 0; i < tableInfo.length; i++) {
        if (titleArray.includes(tableInfo[i].workFlowId)) {
          arrayTable.push(tableInfo[i]);
        }
      }

      setFilteredRequest(arrayTable);
    } else {
      setFilteredRequest(rows);
    }
  };

  // Method to filter per states

  const filterByIdStatus = (arraySelects, tableInfo) => {
    if (arraySelects.length > 0) {
      let titleArray = [];
      let arrayTable = [];

      for (let i = 0; i < arraySelects.length; i++) {
        titleArray.push(arraySelects[i].id);
      }

      for (let i = 0; i < tableInfo.length; i++) {
        if (titleArray.includes(tableInfo[i].workflowStatus.id)) {
          arrayTable.push(tableInfo[i]);
        }
      }

      setFilteredRequest(arrayTable);
    } else {
      setFilteredRequest(rows);
    }
  };

  // const selectedOptions = useRef([]);

  const handleSelectChange = async (event) => {
    setSelectedOpt(event.target.value);
    // selectedOptions.current = event.target.value
    selectedTags.current = event.target.value;

    let arrTemp = [];

    // setStatusLoading(true)

    // for (const item of selectedOptions.current) {
    for (const item of selectedTags.current) {
      await loadWorkflowsFilters(item.id);
      arrTemp = arrTemp.concat(filterStatus.current);
    }
    ArrayStatusCombined(arrTemp, standardSelects);

    // if (selectedOptions.current.length > 0) {

    //   const newArrayStatus = arrTemp
    //     .map((row) => ({ id: row.id, title: row.title }))
    //     .concat(standardSelects.map((row) => ({ id: row.id, title: row.title })));

    //   setArrayCombined(newArrayStatus.sort((a, b) => a.title.localeCompare(b.title)));
    // } else {
    //   setArrayCombined([]);
    // }

    // setStatusLoading(false)
  };

  return (
    <CustomToolbar>
      <form onSubmit={handleDatesFilter}>
        <Grid container spacing={2}>
          {customAdd && (
            <Grid item xs={listWorkFlows !== undefined ? 4 : 2}>
              {elementAdd}
            </Grid>
          )}
          <Grid item xs={listWorkFlows !== undefined ? 4 : 2}>
            {handleCreateNew && (
              <Tooltip title={title}>
                <IconAction size="medium" onClick={handleCreateNew}>
                  <NewIcon />
                </IconAction>
              </Tooltip>
            )}
          </Grid>

          {!hideFilterDate && (
            <>
              <Grid
                item
                xs={listWorkFlows !== undefined ? 8 : 8}
                sx={{ textAlign: "right", justifyContent: "flex-end" }}
              >
                {listAllworkflows !== undefined && (
                  <>
                    {/* Filter workflows */}
                    <FormControl
                      sx={{ width: 150, height: 28, marginRight: "20px" }}
                    >
                      <InputLabel
                        id="demo-multiple-checkbox-label"
                        sx={{
                          fontSize: "0.8em",
                          position: "absolute",
                          top: "50%",
                          left: "40%",
                          transform: "translate(-50%, -50%)",
                        }}
                      >
                        Filtro de Flujos
                      </InputLabel>
                      <Select
                        sx={{ width: "150px", height: "30px" }}
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={selectedOpt}
                        onChange={(evt) => {
                          handleSelectChange(evt);
                          filterByIdWorkFlow(evt.target.value, rows);
                        }}
                        disabled={statusLoading}
                        renderValue={() => ""}
                        MenuProps={MenuProps}
                      >
                        {listWorkFlows.map((tag) => (
                          <MenuItem
                            key={tag.id}
                            value={tag}
                            sx={{ fontSize: "0.6em" }}
                            disabled={statusLoading}
                          >
                            <Checkbox
                              checked={selectedOpt.indexOf(tag) > -1}
                              sx={{ width: "30px", height: "30px" }}
                            />
                            <ListItemText primary={tag.name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {/* Filter Status */}

                    <FormControl
                      sx={{ width: 150, height: 28, marginRight: "20px" }}
                    >
                      <InputLabel
                        id="demo-multiple-checkbox-label"
                        sx={{
                          fontSize: "0.8em",
                          position: "absolute",
                          top: "50%",
                          left: "40%",
                          transform: "translate(-50%, -50%)",
                        }}
                      >
                        Filtro de Estado
                      </InputLabel>
                      <Select
                        sx={{ width: "150px", height: "30px" }}
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={selectedState}
                        onChange={(event) => {
                          setSelectedState(event.target.value);
                          filterByIdStatus(event.target.value, rows);
                        }}
                        disabled={statusLoading}
                        MenuProps={MenuProps}
                        renderValue={() => ""}
                      >
                        {arrayCombined.map((tag) => (
                          <MenuItem
                            key={tag.id}
                            value={tag}
                            sx={{ fontSize: "0.6em" }}
                            disabled={statusLoading}
                          >
                            <Checkbox
                              checked={selectedState.indexOf(tag) > -1}
                              sx={{ width: "30px", height: "30px" }}
                            />
                            <ListItemText primary={tag.title} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </>
                )}

                {/* Filter dates */}

                <Label>Fecha inicial:</Label>
                <InputDate
                  type="date"
                  value={initialDate}
                  min="2020-03-20"
                  max={finalDate}
                  onChange={(e) => setInitialDate(e.target.value)}
                />

                <Label>Fecha final:</Label>
                <InputDate
                  type="date"
                  value={finalDate}
                  min={initialDate}
                  max={finalDate}
                  onChange={(e) => setFinalDate(e.target.value)}
                />
                <SearchButton variant="contained" type="submit">
                  Buscar
                </SearchButton>
                <Tooltip title="Limpiar filtros">
                  <ClearIconButton size="small" onClick={handleClearDate}>
                    <CleanIcon />
                  </ClearIconButton>
                </Tooltip>
              </Grid>
            </>
          )}
        </Grid>
      </form>
    </CustomToolbar>
  );
}
