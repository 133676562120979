import * as React from "react";
import { useState, useEffect } from "react";
import { InputAdornment, ListSubheader, TextField, Tooltip } from "@mui/material";
// Multi Select
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import SearchIcon from "@mui/icons-material/Search";
import moment from "moment";

import { useTranslation } from "react-i18next";

import {
  CleanIcon,
  CustomToolbar,
  IconAction,
  InputDate,
  Label,
  NewIcon,
  CustomFormControl,
  ContainerSearch,
  ContainerInputsDate,
  ContainerGroup,
  ContainerGroupSelects,
  ContainerFiltros,
  CustomSelect,
  CustomInputLabel,
  CustomMenuItem,
  CustomBadge,
  ContainerDates,
  ContainerSwitch,
  LabelSwitch,
  CustomSwitch
} from "./styles";

import { Forms, ClearIconButton, SearchButton } from "../../../../pages/styles";
import { Search } from "@mui/icons-material";
import { FormatDate } from "../../../../utils/formatDate";

// Permissions
import { useAbility } from '@casl/react';
import { AbilityContext } from "../../../../Context/PermissionsContext";

export function TableToolbarMyWork(props) {
  const {
    standardsSelects,
    filterStatus,
    filterPlants,
    selectedTags,
    setSelectedTags,
    selectedPlants,
    setSelectedPlants,
    setFilteredRequest,
    rows,
		initialDate,
		finalDate,
    handleClearDate,
    workflow,
    setMaintainFilter,
    reloadFilters,
    hideDatesFilter,
    search,
    hideFlowBtn,

    canceledState,
    closedState,
    handleChangeSwitch,
    rowsPerPage,
    onChangePage,
    isCustom,
    callFilter,
    page

  } = props;

  const ITEM_HEIGHT = 50;
  const ITEM_PADDING_TOP = 8;
  const [arrayCombined, setArrayCombined] = React.useState([]);
  const [count, setCount] = useState(0);
  const [plantsCount, setPlantsCount] = useState(0);
  const { t } = useTranslation();
  const { format } = new FormatDate();
  const ability = useAbility(AbilityContext);

	const [searchPlantText, setSearchPlantText] = useState("");
	const [searchStatusText, setSearchStatusText] = useState("");

  const [canceledChecked, setCanceledChecked] = useState(false);
  const [closedChecked, setClosedChecked] = useState(false);


	const containsText = (text, search) => text?.toLowerCase().indexOf(search.toLowerCase()) > -1;

	const optionsPlants = React.useMemo(() => filterPlants?.filter((option) => containsText(option.name, searchPlantText)) || [],
	[filterPlants, searchPlantText]);
	
	const optionsStatus = React.useMemo(() => arrayCombined?.filter((option) => containsText(option.title, searchStatusText)) || [],
	[arrayCombined, searchStatusText]);

  //?? custom selects options
  const MenuPropsSelect = (needAuto) => {

    const MenuProps = {
			autoFocus: false,
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: needAuto ? 'auto' : 250,
          left: needAuto && 1500
        },
      },
    };

    return MenuProps;
  }

  // Method combined Array's
  const ArraysSelectsCombined = (array1, array2) => {
    const newArraySelects = array1
      .map((row) => ({ id: row.id, title: row.title }))
      .concat(array2.map((row) => ({ id: row.id, title: row.title })));

    setArrayCombined(newArraySelects);
  };

  const filterByTitle = (arraySelects, tableInfo) => {
    let titleArray = [];
    let arrayTable = [];

    for (let i = 0; i < arraySelects?.length; i++) {
      titleArray.push(arraySelects[i].id);
    }

    for (let i = 0; i < tableInfo.length; i++) {
      if (titleArray.includes(tableInfo[i].workflowStatus.id)) {
        arrayTable.push(tableInfo[i]);
      }
    }

    filterAll([...tableInfo], arraySelects, selectedPlants)
  };

  const filterByPlant = (arrayPlants, tableInfo) => {
    filterAll([...tableInfo], selectedTags, arrayPlants)
  };

  const filterAll = async (filteredTemp, arrayStates, arrayPlants, hasFilterDate, keepPage) => {
    let states = arrayStates?.map((obj) => obj.id)
    let plants = arrayPlants?.map((obj) => obj.id)

    if (states?.length > 0) {
      filteredTemp = filteredTemp.filter((el) => states.includes(el.workflowStatus?.id))
    }
    if (plants?.length > 0) {
      filteredTemp = filteredTemp.filter((el) => plants.includes(el.plant?.id))
    }
    if (search !== undefined) {
      filteredTemp = filteredTemp?.filter(e => {
        if (stringLower(e.code).includes(search?.toLowerCase())
          || stringLower(e.title).includes(search?.toLowerCase())
          || stringLower(e.workflowData?.workflowStatus?.title).includes(search?.toLowerCase())
          || stringLower(e.workflowData?.workflowStatus?.subtitle).includes(search?.toLowerCase())
          || stringLower(format(e?.updatedAt, false, false, false, true)).includes(search?.toLowerCase())
          || stringLower(e.plant?.name).includes(search?.toLowerCase())
        ) {
          return e
        }
      })
    }

    setFilteredRequest(filteredTemp);
    setMaintainFilter(filteredTemp);

		if (isCustom && !hasFilterDate) {
      // Validar que si hay algún filtro aplicado muestre el rowCount con el length de la
      // data filtrada, sino, mostrará el rowCount original
			let hasFilters = states?.length > 0 || plants?.length > 0 || search !== undefined;
			let size = hasFilters ? filteredTemp.length : null;
      let currentPage = keepPage ? page : 0
			onChangePage(currentPage, rowsPerPage, size, true)
		}
  }

  function stringLower(value) {
    return value?.toString().toLowerCase() || ""
  }

  const filterDates = async () => {
    if (initialDate !== undefined || finalDate !== undefined) {
      let result = await props.handleDatesFilter();
      filterAll(result, selectedTags, selectedPlants, true)
    }
  }

  const handleChange = (event) => {
    const newSelectedTags = event.target.value;
    setSelectedTags(newSelectedTags);

    const newCount = newSelectedTags.length >= 0 ? newSelectedTags.length : 0;
    setCount(newCount);

    filterByTitle(newSelectedTags, rows);
  }

  const handleChangePlants = (event) => {
    const newSelectedTags = event.target.value;
    setSelectedPlants(newSelectedTags);
    setPlantsCount(newSelectedTags.length || 0);
    filterByPlant(newSelectedTags, rows);
  }

  const handleChangeCanceledChecked = async() => {
    setCanceledChecked(!canceledChecked)
    canceledState.current = !canceledChecked
    await handleChangeCheckSwitch(!canceledChecked, closedChecked);
  }

  const handleChangeClosedChecked = async() => {
    setClosedChecked(!closedChecked)
    closedState.current = !closedChecked
    await handleChangeCheckSwitch(canceledChecked ,!closedChecked);
  }

  const handleChangeCheckSwitch = async(canceled, closed) => {
    let result = await handleChangeSwitch(canceled, closed);
    filterAll(result, selectedTags, selectedPlants)
  }

  const clearFilterSelects = () => {
    setSelectedTags([]);
    setSelectedPlants([]);
    setCount(0);
    setPlantsCount(0);
    setCanceledChecked(false);
    setClosedChecked(false);
  }

  React.useEffect(() => {
    ArraysSelectsCombined(filterStatus, standardsSelects);
  }, [filterStatus, standardsSelects]);

  React.useEffect(() => {
    clearFilterSelects();
  }, [workflow?.id]);

  React.useEffect(() => {
    if (selectedTags?.length > 0 || selectedPlants?.length > 0) {
      filterAll(rows, selectedTags, selectedPlants)
    }
  }, [reloadFilters]);

  React.useEffect(() => {
    filterAll(rows, selectedTags, selectedPlants)
  }, [search]);

  React.useEffect(() => {
    filterAll(rows, selectedTags, selectedPlants, false, true)
  }, [callFilter]);

  return (
    <CustomToolbar>
      <Forms
        onSubmit={e => {
          e.preventDefault()
          filterDates()
        }}
        isManagement={true}
      >

        {props.handleCreateWorkflow ?
          <Tooltip title={t("workflow.initFlow")}>
            <IconAction
              size="medium"
              aria-label="new"
              onClick={props.handleCreateWorkflow}
              sx={{ visibility: hideFlowBtn === false ? 'hidden' : 'visible' }}
            >
              <NewIcon />
            </IconAction>
          </Tooltip>
          :
          <div style={{ marginLeft: 5, width: 28 }}></div>
        }


        <ContainerFiltros>

          <ContainerGroupSelects>
            {canceledState &&
              <>
                {/* FILTER PER CANCELED STATE */}
                {(ability.can("WorkflowStatusList") || ability.can("WorkflowStatusStandardList")) &&
                  <ContainerSwitch>
                    <LabelSwitch>
                      {t("common.statusCanceledFilter")}
                    </LabelSwitch>
                    <CustomSwitch 
                      checked={canceledChecked}
                      id="filter-status-canceled"
                      onChange={handleChangeCanceledChecked}
                      size="small"
                    />
                  </ContainerSwitch>
                }
              </>
            }
            {closedState &&
              <>
                {/* FILTER PER CLOSED STATE */}
                {(ability.can("WorkflowStatusList") || ability.can("WorkflowStatusStandardList")) &&
                  <ContainerSwitch>
                    <LabelSwitch>
                      {t("common.statusClosedFilter")}
                    </LabelSwitch>
                    <CustomSwitch 
                      checked={closedChecked}
                      id="filter-status-closed" 
                      onChange={handleChangeClosedChecked}
                      size="small"
                    />
                  </ContainerSwitch>
                }
              </>
            }
          </ContainerGroupSelects>
          <ContainerGroupSelects>
            {/* FILTER PER PLANTS */}
            {ability.can("PlantList") &&
              <CustomFormControl>
                <CustomInputLabel
                  id="filter-plants"
                >
                  {t("common.plantsFilter")}
                </CustomInputLabel>
                <CustomBadge
                  badgeContent={plantsCount}
                  color="primary"
                  sx={{
                    color: 'white'
                  }}
                >
                  <CustomSelect
                    labelId="filter-plants"
                    id="filter-plants-select"
                    multiple
                    value={selectedPlants}
                    onChange={handleChangePlants}
										onClose={() => setSearchPlantText("")}
                    renderValue={() => ""}
                    MenuProps={MenuPropsSelect(false)}
                  >
										<ListSubheader>
											<TextField
												size="small"
												autoFocus
												placeholder={t("common.search")}
												fullWidth
												InputProps={{
													startAdornment: (
														<InputAdornment position="start">
															<Search />
														</InputAdornment>
													)
												}}
												onChange={(e) => setSearchPlantText(e.target.value)}
												onKeyDown={(e) => {
													if (e.key !== "Escape") {
														e.stopPropagation();
													}
												}}
											/>
										</ListSubheader>
                    {optionsPlants
                      .slice()
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map(
                        (tag) => (
                          (
                            <CustomMenuItem
                              key={tag.id}
                              value={tag}
                              sx={{ fontSize: "0.6em" }}
                            >
                              <Checkbox
                                checked={selectedPlants.indexOf(tag) > -1}
                                sx={{ width: "30px", height: "30px" }}
                              />
                              <ListItemText primary={tag.name} />
                            </CustomMenuItem>
                          )
                        )
                      )}
                  </CustomSelect>
                </CustomBadge>
              </CustomFormControl>
            }
            
            {/* FILTER PER STATUS */}
            {(ability.can("WorkflowStatusList") || ability.can("WorkflowStatusStandardList")) &&
              <CustomFormControl>
                <CustomInputLabel
                  id="demo-multiple-checkbox-label"
                >
                  {t("common.statusFilter")}
                </CustomInputLabel>
                <CustomBadge
                  badgeContent={count}
                  color="primary"
                  sx={{
                    color: 'white'
                  }}
                >
                  <CustomSelect
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={selectedTags}
                    onChange={handleChange}
										onClose={() => setSearchStatusText("")}
                    renderValue={() => ""}
                    MenuProps={MenuPropsSelect(true)}
                  >
										<ListSubheader>
											<TextField
												size="small"
												autoFocus
												placeholder={t("common.search")}
												fullWidth
												InputProps={{
													startAdornment: (
														<InputAdornment position="start">
															<Search />
														</InputAdornment>
													)
												}}
												onChange={(e) => setSearchStatusText(e.target.value)}
												onKeyDown={(e) => {
													if (e.key !== "Escape") {
														e.stopPropagation();
													}
												}}
											/>
										</ListSubheader>
                    {optionsStatus
                      .slice()
                      .sort((a, b) => a.title.localeCompare(b.title))
                      .map(
                        (tag) => (
                          (
                            <CustomMenuItem
                              key={tag.id}
                              value={tag}
                              sx={{ fontSize: "0.6em" }}
                            >
                              <Checkbox
                                checked={selectedTags.indexOf(tag) > -1}
                                sx={{ width: "30px", height: "30px" }}
                              />
                              <ListItemText primary={tag.title} />
                            </CustomMenuItem>
                          )
                        )
                      )}
                  </CustomSelect>
                </CustomBadge>
              </CustomFormControl>
            }

            {hideDatesFilter &&
              <Tooltip title={t("common.cleanFilters")}>
                <ClearIconButton
                  size="small"
                  aria-label="plot"
                  onClick={() => {
                    handleClearDate();
                    clearFilterSelects();
                  }}
                >
                  <CleanIcon />
                </ClearIconButton>
              </Tooltip>
            }

          </ContainerGroupSelects>

          {!hideDatesFilter &&
            <ContainerDates>

              <ContainerGroup>
                {/* Filter Dates */}
                <ContainerInputsDate>
                  <Label>{t("common.initDate")}:</Label>
                  <InputDate
                    type="date"
                    value={props.initialDate}
                    min="2020-03-20"
                    max={props.finalDate}
                    onChange={(e) => props.setInitialDate(e.target.value)}
                  />
                </ContainerInputsDate>

                <ContainerInputsDate>
                  <Label>{t("common.lastDate")}:</Label>
                  <InputDate
                    type="date"
                    value={props.finalDate}
                    min={props.initialDate}
                    max={moment(new Date()).format("YYYY-MM-DD")}
                    onChange={(e) => props.setFinalDate(e.target.value)}
                  />
                </ContainerInputsDate>
              </ContainerGroup>

              <ContainerSearch>
                <SearchButton variant="contained" type="submit" isMobile={true}>
                  {t("common.search")}
                </SearchButton>

                {/* Icono Search Mobile */}
                <Tooltip title={t("common.search")}>
                  <ClearIconButton isFirstClearButton={true} type="submit">
                    <SearchIcon
                      sx={{ height: "20px", width: "20px", color: "white" }}
                    />
                  </ClearIconButton>
                </Tooltip>

                <Tooltip title={t("common.cleanFilters")}>
                  <ClearIconButton
                    size="small"
                    aria-label="plot"
                    onClick={() => {
                      handleClearDate();
                      clearFilterSelects();
                    }}
                  >
                    <CleanIcon />
                  </ClearIconButton>
                </Tooltip>
              </ContainerSearch>

            </ContainerDates>
          }

        </ContainerFiltros>

      </Forms>
    </CustomToolbar>
  );
}
