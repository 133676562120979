/*
Codigo tomado del inicio de sesion de "schneider/portal de mantenimiento" para maquetar
*/

import React from 'react'
import Router from '../../router';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// Components
import { LoginFormContainer } from '../../components/molecules/forms/LoginForm/container'

// Styles
import {
  Container,
  SectionLeft,
  SectionRight,
  ContainerForm,
} from './styles'

import { useTranslation } from 'react-i18next'
import { LanguageSelect } from '../../components/atoms/languageSelect'
import { LanguageContainer } from '../styles'

import { ColorsContext } from '../../Context/ColorsContext'

export const Login = () => {
	const { t } = useTranslation();
  const { openBackDrop, contentRender } = React.useContext(ColorsContext);

  React.useEffect(() => {
    document.title = t('login.titleApp');
  }, [t])
  
  return (
    !contentRender ? (
      <>
        <Backdrop
          sx={{ color:"#fff", zIndex: (theme) => theme.zIndex.drawer + 1000 }}
          open={openBackDrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    ) : (
      <Container>
        <SectionLeft imgUrl={`${Router.apiBaseUrl}${Router.appStaticAppearance}/bg_login.jpg`}>
        </SectionLeft>
        <SectionRight>
          <LanguageContainer><LanguageSelect/></LanguageContainer>
          <ContainerForm>
            <LoginFormContainer />
          </ContainerForm>
        </SectionRight>
      </Container>
    )
  )
}
