import {styled} from "@mui/material/styles";
import { IconButton, Typography, TextField } from "@mui/material";
import { useColors } from "../../../Context/ColorsContext";
import { MediaQuerys } from '../../../styles/GlobalStyle';

const { mobile, tablet, desktop, desktoplarge } = MediaQuerys;

export const ContainerStyles = styled('div')(({ isBottom, buttonRef }) => {
  const defaultWidth = 300;

  const baseStyles = {
    position: 'absolute',
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    padding: '16px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    zIndex: 2,
    width: `${defaultWidth}px`,
    top: isBottom ? buttonRef.offsetTop + buttonRef.offsetHeight + 10 : buttonRef.offsetTop - 130,
    left: buttonRef.offsetLeft + buttonRef.offsetWidth / 2 - 20,
    '&::before': {
      content: '""',
      position: 'absolute',
      borderWidth: '10px',
      borderStyle: 'solid',
      borderColor: 'transparent transparent #fff transparent',
      transform: 'translateX(-50%) rotate(180deg)',
      bottom: '-20px', // Move the arrow down by 20px
    },
  };

  if (isBottom) {
    return {
      ...baseStyles,
      '&::before': {
        ...baseStyles['&::before'],
        bottom: 'auto',
        top: '-20px', // Move the arrow up by 20px
        transform: 'translateX(-50%)',
      },
    };
  }

  return baseStyles;
});

export const Text = styled(Typography)(() => {
  const { colors } = useColors();
  return {
      color: colors.grayFont,
      fontSize: '.8rem',
      fontWeight: 'bold',
      margin: 0,
      padding: 0,
      [desktop]: {
          fontSize: '0.95rem',
      },
      [tablet]: {
          fontSize: '0.85rem',
      }
  }
})

export const ContainerOpt = styled('div')(() => {
  return {
    display: 'flex', 
    flexDirection: 'row', 
    justifyContent: 'space-between', 
    alignItems: 'center'
  }
})

export const Input = styled(TextField)(() => {
  const { colors } = useColors();
  return {
    '.MuiInputBase-root': {
      background: colors.white,
      height: '30px'
    },
    fontSize: '1rem', 
    flex: 1,
    '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    }
  }
})

export const CustomIconButton = styled(IconButton)(() => {
  const { colors } = useColors();
  return {
    borderRadius: "10%",
    backgroundColor: colors.background,
    color: colors.buttonActionColor,
    padding: '5px',
    "&:hover": {
      backgroundColor: colors.background,
      opacity: 0.9,
      color: colors.buttonActionColor
    },
  };
});