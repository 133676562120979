import { useState, useEffect, useRef, useMemo } from 'react';
import { useMediaQuery } from "@mui/material";
import dayjs from "dayjs";

import { useTranslation } from 'react-i18next'
import { DataGraphics } from './dataGraphics';
import moment from "moment";
import ImgFilterClean from './graficoWP.png';

// Components MUI
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

// Components
import { PageTitle } from '../../components/PageTitle';
import { FiltersDeviations } from '../../components/atoms/FiltersDeviations';
import { BackdropLoading } from '../../components/BackdropLoading';
import { GrahpsDeviationsProcess } from '../../components/GraphicsDeviationsProcess';

// Class
import { PlantApi } from '../../services/PlantApi';
import { DashboardReportApi } from '../../services/DashboardReportApi';

// Icons
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { CardGraphic } from "../../components/CardGraphic";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';


// Styles
import { Container } from '../styles';
import { ContainerTitle, ContainerPageTitle, ContainerFilters, ContainerTopGrahps, ContainerBottomGrahps, ContainerGraphics, ContainerTreeMap, ContainerOptionsGraphics, OptionTitle, OptionColor, ContainerOption, ContainerValidate, CustomImg } from './style';
import { CardBackground } from '../../components/CardImage/styles';
import { GrahpsDeviationsCompany } from '../../components/GraphicsDeviationsCompany';


export const DeviationsReports = (props) => {
  const { t } = useTranslation();
  const dashboardApi = new DashboardReportApi();

  const chartRef = useRef(null);
  const containerViabilRef = useRef(null);
  const containerExcavaRef = useRef(null);
  const containerSasRef = useRef(null);
  const containerSaesRef = useRef(null);
  const chartContextsRef = useRef([]);
  // select presidency
  const [filterPresidency, setFilterPresidency] = useState([]);
  // select core
  const [filterCore, setFilterCore] = useState([]);
  // select sites
  const [filterSites, setFilterSites] = useState([]);
  const [filterCompany, setFilterCompany] = useState([]);
  const [filterContracts, setFilterContracts] = useState([]);
  const [open, setOpen] = useState(false);
  const [dataBarGraphics, setDataBarGraphics] = useState([]);
  const [dataBarGrapsCompany, setDataBarGrapsCompany] = useState([]);
  const [dataListDeviations, setDataListDeviations] = useState([]);
  const [calcDataGrahps, setCalcDataGraps] = useState([]);
  const [allSeriesColors, setAllSeriesColors] = useState([]);

  const [switchCompanies, setSwitchCompanies] = useState(false);
  const [dataDonutGraph, setDataDonutGraph] = useState([]);
  const [searchVicePresidText, setSearchVicePresidText] = useState('');
  const [searchCoreText, setSearchCoreText] = useState('');
  const [searchSiteText, setSearchSiteText] = useState('');
  const [searchCompanyText, setSearchCompanyText] = useState('');
  const [searchContractText, setSearchContractText] = useState('');
  const [finalDate, setFinalDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [initialDate, setInitialDate] = useState(moment(finalDate).subtract(1, "months").format("YYYY-MM-DD"));
  const [errorsDate, setErrorsDate] = useState({ startDate: false, endDate: false });
  const [filters, setFilters] = useState({
    vicePresidency: [],
    core: [],
    site: [],
    company: [],
    contracts: [],
    endDate: null,
    startDate: null,
  });

  const containsText = (text, search) => text?.toLowerCase().indexOf(search.toLowerCase()) > -1;

  // Options Select Sites
  const optionsSites = useMemo(() => filterSites?.filter((option) => containsText(option.name, searchSiteText)) || [],
    [filterSites, searchSiteText]);
  // Options Select Presidency
  const optionsPresidency = useMemo(() => filterPresidency?.filter((option) => containsText(option.name, searchVicePresidText)) || [],
    [filterPresidency, searchVicePresidText]);
  // Options Select Troncal
  const optionsCore = useMemo(() => filterCore?.filter((option) => containsText(option.name, searchCoreText)) || [],
    [filterCore, searchCoreText]);
  // Options Select Company
  const optionsCompany = useMemo(() => filterCompany?.filter((option) => containsText(option.name, searchCompanyText)) || [],
    [filterCompany, searchCompanyText]);
  // Options Select Contract
  const optionsContract = useMemo(() => filterContracts?.filter((option) => containsText(option.code, searchContractText)) || [],
    [filterContracts, searchContractText]);


  const areAllItemsSelected = (filters, options, key, category = null) => {
    const filteredOptions = category ? options.filter(item => item.category.name === category) : options;
    return filters[key].length > 0 && filteredOptions.length > 0 && filters[key].length === filteredOptions.length;
  };

  const isAllSelected = areAllItemsSelected(filters, optionsSites, 'site');
  const isAllSystemsSelected = areAllItemsSelected(filters, optionsSites, 'site', 'Sistema');
  const isAllStationsSelected = areAllItemsSelected(filters, optionsSites, 'site', 'Estación');
  const isAllCoresSelected = areAllItemsSelected(filters, optionsCore, 'core');

  const isAllPresidencySelected = filters.vicePresidency.length === optionsPresidency.length;
  const isAllCompanySelected = filters.company.length === optionsCompany.length;
  const isAllContractSelected = filters.contracts.length === optionsContract.length;

  useEffect(() => {
    getPlantManagementReport();
    getPlantManagementCompany();
  }, [])

  const sendReportManagement = async (integrationKey, params) => {
    const response = await dashboardApi.sendManagementReport(params);
    if (response.status === 200) {
      return { integrationKey, data: response.data };
    } else {
      throw new Error('Failed to fetch data');
    }
  };

  const sendReportCompany = async (integrationKey, params) => {
    const response = await dashboardApi.sendCompanyReport(params);
    if (response.status === 200) {
      return { integrationKey, data: response.data };
    } else {
      throw new Error('Failed to fetch data');
    }
  };

  const sendListDeviations = async (params) => {
    const response = await dashboardApi.sendListDeviation(params);
    if (response.status === 200) {
      const transformedData = response.data.map(item => ({
        ...item,
        data: item.data.map(({ name, value, ...rest }) => ({
          x: name,
          y: value,
          ...rest
        }))
      }));
      setDataListDeviations(transformedData);
    } else {
      throw new Error('Failed to fetch data');
    }
  }

  const handleSendFilters = async (e) => {
    e.preventDefault();
    setOpen(true);

    const { startDate, endDate, vicePresidency, core, site, company, contracts } = filters;

    const newErrors = {
      startDate: !startDate,
      endDate: !endDate,
    };
    setErrorsDate(newErrors);

    if (!startDate || !endDate) {
      setOpen(false);
      return;
    }

    const prepareFilters = (additionalFilters = {}) => ({
      initialDate: startDate,
      finalDate: endDate,
      managementIds: vicePresidency.map(el => el.id),
      trunkIds: core.map(el => el.id),
      plantIds: site.map(el => el.id),
      ...additionalFilters
    });

    const integrationKeys = [
      'CJ54JOC6', // Viabilización de actividades
      'VUJBZIKW', // SAS
      'HUU8XTO8', // SAES
      '7V3B11QA', // Excavación
    ];

    try {
      await sendListDeviations(prepareFilters({ companyIds: company.map(el => el.id), contractIds: contracts.map(el => el.id) }));

      const dataPromises = integrationKeys.map(key => {
        const filtersToUse = switchCompanies
          ? prepareFilters({ companyIds: company.map(el => el.id), contractIds: contracts.map(el => el.id), integrationKey: key })
          : prepareFilters({ integrationKey: key });

        return switchCompanies ? sendReportCompany(key, filtersToUse) : sendReportManagement(key, filtersToUse);
      });

      const results = await Promise.all(dataPromises);

      if (switchCompanies) {
        const organizedDataDonut = organizeDataDonutCompany(processDataDonutCompany(results));
        setDataDonutGraph(organizedDataDonut);
        setDataBarGrapsCompany(results);
      } else {
        const organizedDataProcess = organizeDataProcess(results);
        setDataDonutGraph(organizedDataProcess);
        transformData(results);
        setDataBarGraphics(results);
      }

    } catch (error) {
      console.error('Error fetching data', error);
    } finally {
      setOpen(false);
    }
  };




  const getPlantManagementReport = async () => {
    const response = await dashboardApi.getPlantManagementReport();
    if (response.status === 200) {
      setFilterPresidency(response.data);
    }
  }

  const getPlantManagementCompany = async () => {
    const response = await dashboardApi.getPlantManagementCompany();
    if (response.status === 200) {
      setFilterCompany(response.data);
    }
  }

  // Auxiliar functions for handleChangeFilters - Childrens
  const handleDateChange = (date) => {
    const formattedDate = moment(new Date(date));
    return formattedDate.isValid() ? formattedDate.format("YYYY-MM-DD") : null;
  };

  // const handleCompanyChange = (value, optionsCompany) => {
  //   if (value[value.length - 1] === 'allCompany') {
  //     return { company: isAllCompanySelected ? [] : optionsCompany };
  //   }
  //   return { company: value };
  // }

  const handleCompanyChange = (value, optionsCompany) => {
    const isAllSelected = value[value.length - 1] === 'allCompany';
    let allContracts = [];

    if (isAllSelected) {
      allContracts = isAllCompanySelected ? [] : optionsCompany.flatMap(el => el.contracts);
    } else {
      allContracts = value.flatMap(item => item.contracts || []);
    }
    setFilterContracts(allContracts);

    const updateContracts = filters.contracts?.filter(el => allContracts.some(item => item.id === el.id))

    return {
      company: isAllSelected ? (isAllCompanySelected ? [] : optionsCompany) : value,
      contracts: updateContracts,
    }
  }


  const handleContractChange = (value, optionsContract) => {
    if (value[value.length - 1] === 'allContracts') {
      return { contracts: isAllContractSelected ? [] : optionsContract };
    }
    return { contracts: value };
  }

  const handleSiteChange = (value, optionsSites) => {
    if (value[value.length - 1] === 'selectAll') {
      return { site: isAllSelected ? [] : optionsSites };
    } else if (value[value.length - 1] === 'AllSystems') {
      const systemSites = filterSites.filter(site => site.category.name === 'Sistema');
      return { site: isAllSystemsSelected ? [] : systemSites };
    } else if (value[value.length - 1] === 'AllStations') {
      const stationSites = filterSites.filter(station => station.category.name === 'Estación');
      return { site: isAllStationsSelected ? [] : stationSites };
    }
    return { site: value };
  };

  // Función actualizada para handleVicePresidencyChange
  const handleVicePresidencyChange = (value, optionsPresidency) => {
    const isAllSelected = value[value.length - 1] === 'allPresidency';
    let allPlantTrunks = [];

    if (isAllSelected) {
      allPlantTrunks = isAllPresidencySelected ? [] : optionsPresidency.flatMap(el => el.plantTrunks);
    } else {
      allPlantTrunks = value.flatMap(item => item.plantTrunks || []);
    }

    setFilterCore(allPlantTrunks);

    const selectedTrunksIds = allPlantTrunks.map(trunk => trunk.id);
    const filteredPlants = filterSites.filter(plant => selectedTrunksIds.includes(plant.plantTrunk.id));

    setFilterSites(filteredPlants);

    // Limpiar filtros troncales y plantas en caso de que se desmarquen vicepresidencias
    const updatedCore = filters.core?.filter(core => allPlantTrunks.some(trunk => trunk.id === core.id));
    const updatedSites = filters.site?.filter(site => filteredPlants.some(plant => plant.id === site.id));

    return {
      vicePresidency: isAllSelected ? (isAllPresidencySelected ? [] : optionsPresidency) : value,
      core: updatedCore,
      site: updatedSites,
    };
  };


  const handleCoreChange = (value, optionsCore) => {
    const isAllSelected = value[value.length - 1] === 'AllCore';
    const allPlants = filterPresidency.flatMap(item => item.plants);
    let arrPlantsSelected = [];

    if (isAllSelected) {
      arrPlantsSelected = isAllCoresSelected ? [] : allPlants.filter(plant =>
        optionsCore?.some(troncal => troncal.id === plant.plantTrunk.id)
      )
    } else {
      arrPlantsSelected = allPlants.filter(plant =>
        value?.some(troncal => troncal.id === plant.plantTrunk.id)
      )
    }

    setFilterSites(arrPlantsSelected);

    // limpiar filtro plantas en caso de que se desmarquen las opciones en troncales
    const updatedSites = filters.site?.filter(site =>
      arrPlantsSelected.some(el => el.plantTrunk.id === site.plantTrunk.id)
    )

    return {
      core: isAllSelected ? (isAllCoresSelected ? [] : optionsCore) : value,
      site: updatedSites,
    }
  };

  const handleSwitchCompanies = (event) => {
    setOpen(true);
    let cleanValues = {
      vicePresidency: [],
      core: [],
      site: [],
      company: [],
      contracts: [],
      startDate: null,
      endDate: null,
    };
    setErrorsDate({ startDate: false, endDate: false });
    setAllSeriesColors([]);
    setDataBarGraphics([]);
    setFilterCore([]);
    setFilterSites([]);
    setFilterContracts([]);
    setDataBarGrapsCompany([]);
    setFilters(cleanValues);
    setSwitchCompanies(event);
    setOpen(false);
  };

  // HandleChange filters - Parent
  const handleChangeFilters = (e, name, type) => {
    let value;

    if (type === "checkbox") {
      value = e.target.checked;
      handleSwitchCompanies(value);
      return
    } else if (type === "date") {
      value = handleDateChange(e);

      if (name === "endDate" && filters.startDate && dayjs(value).isBefore(filters.startDate)) {
        // Si la fecha "hasta" es menor que la fecha "desde", limpiar el valor de "endDate"
        setFilters(prevFilters => ({ ...prevFilters, endDate: null }));
        setErrorsDate(prev => ({ ...prev, endDate: true }));
      } else if (name === "startDate" && filters.endDate && dayjs(filters.endDate).isBefore(value)) {
        // Si la fecha "hasta" existente es menor que la nueva "startDate", limpiar "endDate"
        setFilters(prevFilters => ({ ...prevFilters, startDate: value, endDate: null }));
        setErrorsDate(prev => ({ ...prev, endDate: true }));
      } else {
        // Si la validación pasa, actualizar el filtro y limpiar el error
        setFilters(prevFilters => ({ ...prevFilters, [name]: value }));
        setErrorsDate(prev => ({ ...prev, [name]: false }));
      }
    } else {
      value = e?.target?.value;

      let newFilters;
      switch (name) {
        case 'site':
          newFilters = handleSiteChange(value, optionsSites);
          break;
        case 'vicePresidency':
          newFilters = handleVicePresidencyChange(value, optionsPresidency);
          break;
        case 'core':
          newFilters = handleCoreChange(value, optionsCore);
          break;
        case 'company':
          newFilters = handleCompanyChange(value, optionsCompany);
          break;
        case 'contracts':
          newFilters = handleContractChange(value, optionsContract);
          break;
        default:
          newFilters = { [name]: value };
      }

      setFilters(prevFilters => ({ ...prevFilters, ...newFilters }));
      return;
    }

    setFilters(prevFilters => ({ ...prevFilters, [name]: value }));
  };

  // Clear all filters
  const handleClearFilters = () => {
    let clean = {
      vicePresidency: [],
      core: [],
      site: [],
      company: [],
      contracts: [],
      startDate: null,
      endDate: null,
    }
    setErrorsDate({ startDate: false, endDate: false });
    setAllSeriesColors([]);
    setDataBarGraphics([]);
    setDataListDeviations([]);
    setDataBarGrapsCompany([]);
    setFilterCore([]);
    setFilterSites([]);
    setFilterContracts([]);
    setFinalDate('');
    setInitialDate('');
    setFilters(clean);
  }

  const processDataDonutCompany = (data) => {
    return data.map(entry => {
      // se suman los valores en los arrays de la key "data"
      const totalSum = entry.data.reduce((acc, item) => {
        const sumSeries = item.series.reduce((sum, seriesItem) => {
          return sum + seriesItem.data.reduce((sumData, num) => sumData + num, 0);
        }, 0);
        return acc + sumSeries;
      }, 0);

      return {
        integrationKey: entry.integrationKey,
        data: totalSum
      };
    });
  }

  const organizeDataDonutCompany = (dataObj) => {
    // integrations keys according to the workflow
    const categoryMap = {
      "CJ54JOC6": "Viabilizacion de actividades",
      "7V3B11QA": "Excavación",
      "VUJBZIKW": "SAS",
      "HUU8XTO8": "SAES"
    };

    const categories = [];
    const data = [];

    dataObj.forEach(item => {
      const category = categoryMap[item.integrationKey];
      if (category) {
        categories.push(category);
        data.push(item.data);
      }
    });

    return {
      categories: categories,
      data: data
    };
  }

  const organizeDataProcess = (jsonData) => {
    // integrations keys according to the workflow
    const categoryMap = {
      "CJ54JOC6": "Viabilizacion de actividades",
      "7V3B11QA": "Excavación",
      "VUJBZIKW": "SAS",
      "HUU8XTO8": "SAES"
    };

    const categorias = [];
    const data = [];

    jsonData.forEach(item => {
      const category = categoryMap[item.integrationKey];
      if (category) {
        categorias.push(category);

        // se suman los valores en los arrays de la key "data"
        const totalSum = item.data.series.reduce((acc, seriesItem) => {
          return acc + seriesItem.data.reduce((sum, num) => sum + num, 0);
        }, 0);

        data.push(totalSum);
      }
    });

    return {
      categories: categorias,
      data: data
    };
  }

  function transformData(inputArray) {
    const data = inputArray.map(item => {
      return {
        key: item.integrationKey,
        categories: item.data.categories.length,
        seriesQuantity: item.data.series.length,
      };
    });

    setCalcDataGraps(data);
  }

  function calculateChartWidth(key, data) {
    // Encontrar el objeto en los datos que coincida con la key
    const chartData = data.find(item => item.key === key);

    if (!chartData) {
      throw new Error(`No se encontró ningún gráfico con la key: ${key}`);
    }

    const { categories, seriesQuantity } = chartData;

    // Calcular el ancho por categoría basado en la cantidad de columnas
    let widthPerCategory;

    if (seriesQuantity >= 3) {
      widthPerCategory = 150; // Ancho para 3 o más columnas
    } else if (seriesQuantity === 2 || seriesQuantity === 1) {
      widthPerCategory = 120; // Ancho para 2 o 1 columna
    }

    // Calcular el ancho total del gráfico
    const totalWidth = categories * widthPerCategory;

    return totalWidth;
  }

  const handleSeriesUpdate = () => {
    const newSeriesColors = {};

    // Procesar todos los chartContexts almacenados en la referencia
    chartContextsRef.current.forEach(chartContext => {
      const seriesColors = chartContext.w.globals.initialSeries.map((currentItem) => {
        const color = chartContext.w.config.colors[chartContext.w.globals.seriesNames.indexOf(currentItem.name)];
        return {
          nameSerie: currentItem.name,
          color: color
        };
      });

      // se acomulan las series en el objeto sin duplicados
      seriesColors.forEach(sc => {
        newSeriesColors[sc.nameSerie] = sc.color;
      });
    });

    // se actualiza el estado con las nuevas series y colores
    setAllSeriesColors(newSeriesColors);

    // limpiar el array para la próxima actualización
    chartContextsRef.current = [];
  };


  const optionsGraphics = (key) => {

    const graphicData = dataBarGraphics?.find(item => item.integrationKey === key);
    const uniqueSeriesNames = new Set();

    dataBarGraphics.forEach(item => {
      // Acceder al array 'series' dentro de 'data'
      item.data.series.forEach(seriesItem => {
        uniqueSeriesNames.add(seriesItem.name);
      });
    });

    const uniqueNamesArray = Array.from(uniqueSeriesNames);

    const colorList = [
      '#10476b', '#0778a6', '#b6b310', '#145f89', '#105b73', '#0b4b7a', '#0d5b8c',
      '#125f83', '#095f76', '#066d8a', '#1c6b96', '#1173a0', '#138fb4', '#11a0a0',
      '#0c8a9d', '#0d7b88', '#92a611', '#a9b211', '#838e13', '#6b7b14'
    ]

    const colors = {}
    uniqueNamesArray.forEach((name, index) => {
      // Asignar un color usando el índice, repitiendo colores si es necesario
      colors[name] = colorList[index % colorList.length];
    });

    const seriesWithColors = graphicData.data.series.map(serie => ({
      ...serie,
      color: colors[serie.name] || "#000000"
    }));

    if (!graphicData) {
      return { options: {}, series: [] };
    }

    const titleMap = {
      'CJ54JOC6': 'Viabilización de actividades',
      'VUJBZIKW': 'SAS',
      'HUU8XTO8': 'SAES',
      '7V3B11QA': 'Excavación'
    };

    let options = {
      series: seriesWithColors,
      chart: {
        type: 'bar',
        height: 350,
        stacked: false,
        toolbar: {
          show: false,
        },
        events: {
          mounted: function (chartContext) {
            chartContextsRef.current.push(chartContext);
            if (chartContextsRef.current.length === 4) {
              // Procesar solo cuando todos los gráficos han montado
              handleSeriesUpdate();
            }
          },
          updated: function (chartContext) {
            chartContextsRef.current.push(chartContext);
            if (chartContextsRef.current.length === 4) {
              // Procesar solo cuando todos los gráficos han actualizado
              handleSeriesUpdate();
            }
          }
        }
      },
      stroke: {
        // width: 1,
        // colors: ['#fff']
      },
      dataLabels: {
        position: 'top',
        enabled: false,
        formatter: (value, { seriesIndex, dataPointIndex, w }) => {
          let currentGroup = w.globals.initialSeries[seriesIndex].group;
          let groupSeries = w.globals.initialSeries.filter(s => s.group === currentGroup);
          let sum = groupSeries.reduce((acc, curr) => acc + curr.data[dataPointIndex], 0);

          let currentIndex = groupSeries.findIndex(s => s.name === w.globals.initialSeries[seriesIndex].name);
          if (currentIndex === groupSeries.length - 1) {
            // Asigna un offsetY basado en la suma o alguna otra métrica
            return (sum / 1000);
          } else {
            return '';
          }
        },
        align: 'center',
        style: {
          fontSize: '14px',
          fontWeight: 'bold',
          colors: ['black']
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          minBarHeight: 5,
          // borderRadius: 10,
          dataLabels: {
            total: {
              enabled: false,
              style: {
                fontSize: '13px',
                fontWeight: 900
              }
            }
          }
        },
      },
      xaxis: {
        labels: {
          rotate: 0,
          formatter: function (value) {
            if (typeof value === 'string') {
              const words = value.split(' ');

              return words;
            }
            return value;
          },
        },
        categories: graphicData.data.categories
      },
      fill: {
        opacity: 1
      },
      colors: seriesWithColors.map(serie => serie.color),
      yaxis: {
        min: 0,  // Asegura que el eje Y comience en 0
        forceNiceScale: true,  // Forza una escala "amigable" para que los valores de 0 sean visibles
        labels: {
          formatter: function (value) {
            return value.toFixed(0);  // Formatea los valores del eje Y
          }
        }
      },
      tooltip: {
        custom: function ({ seriesIndex, dataPointIndex, w }) {
          if (!switchCompanies) {
            let currentItem = w.globals.initialSeries[seriesIndex]
            let color = w.config.colors[w.globals.seriesNames.indexOf(currentItem.name)];

            let prepareTooltip = `<div class="apexcharts-tooltip-title" style="font-size: 14px; font-weight: bold; margin-bottom: 4px; border-bottom: 1px solid #e3e3e3; padding-bottom: 4px;">${currentItem.name}</div>`;
            prepareTooltip += `<div style="display: flex; align-items: center; margin: 5px 5px;">
                                    <div style="width: 12px; height: 12px; border-radius: 50%; background-color: ${color}; margin-right: 5px;"></div>
                                    <span style="font-size: 12px;">${currentItem.name}: <span style="font-weight: bold;">${currentItem.data[dataPointIndex]}</span></span>
                                  </div>`;

            return prepareTooltip;
          }
        }
      },
      title: {
        text: titleMap[key] || '',
        align: 'left',
        margin: 10,
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: '14px',
          fontWeight: 'bold',
          color: 'black'
        },
      },
      legend: {
        show: false,
      },
      responsive: [
        {
          breakpoint: 1440,
          options: {
            xaxis: {
              labels: {
                style: {
                  fontSize: '10px'
                }
              }
            }
          }
        }
      ]
    };

    return { options, series: seriesWithColors };
  }

  let optionsDonut = {
    series: dataDonutGraph.data,
    labels: dataDonutGraph.categories,
    colors: [
      '#10476b', '#0778a6', '#b6b310', '#145f89',
    ],
    chart: {
      height: '100%',
      type: 'donut',
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      position: 'bottom',
      offsetY: 0,
    },
    title: {
      text: 'Totalizado por categoría',
      align: 'center',
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              // label: 'Total',
              formatter: function (w) {
                return w.globals.seriesTotals.reduce((a, b) => a + b, 0);
              }
            }
          }
        }
      }
    }
  };


  const optionsGraphicDeviation = (type) => {
    const graphicData = dataListDeviations?.find(item => item.typeData === type);

    if (!graphicData) {
      return { options: {}, series: [] };
    }

    // Detectar si la pantalla es de 1440px de ancho
    const isSmallScreen = window.innerWidth <= 1440;

    let options = {
      series: [
        {
          data: graphicData.data.map(item => ({
            ...item,
            x: item.x.length > 20 ? item.x.substring(0, 17) + '...' : item.x // Truncar el label
          })),
        }
      ],
      xaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        labels: {
          show: false
        }
      },
      grid: {
        show: false,
        padding: {
          top: -20,
          right: 0,
          bottom: 0,
          left: 0
        }
      },
      colors: ['#0b4b7a'],
      legend: {
        show: false
      },
      chart: {
        type: 'treemap', // Asegúrate de que el tipo sea treemap
        height: '100% !important',
        toolbar: {
          show: true,
          tools: {
            download: false,
          }
        },
      },
      tooltip: {
        followCursor: true,
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          let currentItem = graphicData.data[dataPointIndex]; // Usar los datos originales para el tooltip
          let prepareTooltip = `<div class="apexcharts-tooltip-title" style="font-size: 10px; font-weight: bold; margin-bottom: 4px; border-bottom: 1px solid #e3e3e3; padding-bottom: 4px;">${currentItem.x}</div>`;
          prepareTooltip += `<div style="display: flex; align-items: center; margin: 5px 5px;">
                                <span style="font-size: 12px;">Total: <span style="font-weight: bold;">${currentItem.amount}</span></span>
                              </div>`;
          return prepareTooltip;
        }
      },
      title: {
        text: graphicData.typeData === 'up' ? 'Top 5 con más desviaciones' : 'Top 5 con menos desviaciones',
        style: {
          fontSize: isSmallScreen ? '10px' : '14px'
        }
      }
    };

    return { options, series: options?.series };
  }



  return (
    <Container open={props.open} mb={6}>
      <ContainerTitle>
        <ContainerPageTitle>
          <PageTitle title={t('dashboard.titleDeviations')} icon={<QueryStatsIcon />} />
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>{t('common.process')}</Typography>
            <Switch size='small' checked={switchCompanies} onChange={e => handleChangeFilters(e, null, 'checkbox')} />
            <Typography>{t('common.company')}</Typography>
          </Stack>
        </ContainerPageTitle>
      </ContainerTitle>

      <FiltersDeviations
        switchCompanies={switchCompanies}
        filters={filters}
        initialDate={initialDate}
        finalDate={finalDate}
        handleChangeFilters={handleChangeFilters}
        handleClearFilters={handleClearFilters}
        setSearchVicePresidText={setSearchVicePresidText}
        setSearchCoreText={setSearchCoreText}
        setSearchSiteText={setSearchSiteText}
        optionsPresidency={optionsPresidency}
        optionsSites={optionsSites}
        optionsCore={optionsCore}
        isAllSelected={isAllSelected}
        isAllCoresSelected={isAllCoresSelected}
        isAllPresidencySelected={isAllPresidencySelected}
        isAllSystemsSelected={isAllSystemsSelected}
        isAllStationsSelected={isAllStationsSelected}
        isAllCompanySelected={isAllCompanySelected}
        isAllContractSelected={isAllContractSelected}
        optionsCompany={optionsCompany}
        optionsContract={optionsContract}
        setSearchCompanyText={setSearchCompanyText}
        setSearchContractText={setSearchContractText}
        filterCore={filterCore}
        filterSites={filterSites}
        filterContracts={filterContracts}
        handleSendFilters={handleSendFilters}
        errorsDate={errorsDate}
      />

      <ContainerOptionsGraphics>
        <>
          {Object.entries(allSeriesColors).map(([nameSerie, color], index) => (
            <ContainerOption key={index}>
              <OptionColor customColor={color} />
              <OptionTitle>
                {nameSerie}
              </OptionTitle>
            </ContainerOption>
          ))}
        </>
      </ContainerOptionsGraphics>

      {
        Object.values(filters).some(
          value => (Array.isArray(value) ? value.length > 0 : value !== null)
        ) && (dataBarGraphics.length > 0 || dataBarGrapsCompany.length > 0) ? (
          <ContainerFilters>
            {switchCompanies ? (
              <GrahpsDeviationsCompany
                dataBarGrapsCompany={dataBarGrapsCompany}
                chartRef={chartRef}
                optionsDonut={optionsDonut}
                optionsGraphicDeviation={optionsGraphicDeviation}
                chartContextsRef={chartContextsRef}
                handleSeriesUpdate={handleSeriesUpdate}
              />
            ) : (
              <GrahpsDeviationsProcess
                containerViabilRef={containerViabilRef}
                containerExcavaRef={containerExcavaRef}
                containerSasRef={containerSasRef}
                containerSaesRef={containerSaesRef}
                chartRef={chartRef}
                optionsGraphics={optionsGraphics}
                calculateChartWidth={calculateChartWidth}
                calcDataGrahps={calcDataGrahps}

                optionsDonut={optionsDonut}
                optionsGraphicDeviation={optionsGraphicDeviation}
              />
            )}
          </ContainerFilters>
        ) : (
          <ContainerValidate>
            <Typography variant='h4' fontWeight='bold' textAlign='center'>{t("common.clearFilters")}</Typography>
            <CustomImg src={ImgFilterClean} alt='img' />
          </ContainerValidate>
        )
      }
      <BackdropLoading open={open} />
    </Container>
  )
}
