
import React from 'react'

// Components
import { Grid, Tooltip } from '@mui/material'

// Styles
import {
  Container,
  ContainerContent,
  PdfUploadIcon,
  PdfDownloadIcon
} from './styles'

// Utils
import { useTranslation } from 'react-i18next'

// Permissions
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../../../Context/PermissionsContext';

export const CharacteristicsForm = ({
  openAttachments,
  hideFiles,
  generatePdf
}) => {
  const { t } = useTranslation();
  const ability = useAbility(AbilityContext);

  return (
    <Container>
      <ContainerContent>
        <Grid item xs={10}></Grid>
        {!hideFiles &&
          <Grid item xs={2}>
            <Grid container display={'flex'} flexDirection={'row'} justifyContent={'end'}>
              {(ability.can("GetWorkflowDataFile") || ability.can("UploadWorkflowDataFile")) &&
                <Tooltip title={t('forms.characteristics.loadFiles')}>
                    <PdfUploadIcon id="pdf-button" onClick={openAttachments} fontSize={'50px'}>upload_file</PdfUploadIcon>
                </Tooltip>
              }
              <Tooltip title={t("common.downloadPdf")}>
                <PdfDownloadIcon onClick={generatePdf} fontSize={'50px'}/>
              </Tooltip>
            </Grid>
          </Grid>
        }
      </ContainerContent>
    </Container>
  )
}
