import React, { Fragment } from 'react'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'

import { ExpandMore } from '@mui/icons-material';

import { CustomTypography, Content, ContainerText } from './styles';

import { WorkflowDataRelationDetail } from '../WorkflowDataRelationDetail';

export const WorkflowDataRelation = ({data = []}) => {

    return (
        <Fragment>
            {data.map((obj, index) => {
                return (
                    <Accordion
                        key={obj.id}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Content>
                                <ContainerText>
                                    <CustomTypography mr={2}>{obj?.name ?? ""}</CustomTypography>
                                </ContainerText>
                            </Content>

                        </AccordionSummary>
                        <AccordionDetails id='Acordeon Details'>
                            {obj.relations.map((item, index) => {
                                const isLastItem = index === obj.relations.length - 1;
                                return (
                                    <WorkflowDataRelationDetail key={item.id} data={item} workflowId={obj.id} isLastItem={isLastItem}/>
                                )
                            })}
                        </AccordionDetails>
                    </Accordion>
                )
            })}
        </Fragment>
    )
}