import * as React from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";

// Utils
import { FormatDate } from "../../../../utils/formatDate";
import { useTranslation } from 'react-i18next'

export const SearchBarLogs = ({ setArrayLogs, rows }) => {

    const { t } = useTranslation();
    const [search, setSearch] = React.useState('')
    const {format} = new FormatDate();

    function handleSearch(e) {
        setSearch(e)
        filterSearch(e)
    }

    const filterSearch = (data) => {
        let searchResults = rows.filter(e => {
            if (e.action?.toString().toLowerCase().includes(data.toLowerCase())
                || e.username?.toString().toLowerCase().includes(data.toLowerCase())
                || e.module?.toString().toLowerCase().includes(data.toLowerCase())
                || e.submodule?.toString().toLowerCase().includes(data.toLowerCase())
                || format(e.createdAt, true, true).toString().toLowerCase().includes(data.toLowerCase())
            ) {
                return e
            }
        })
        setArrayLogs(searchResults)
    }

    React.useEffect(() => {
        setArrayLogs(rows);
        handleSearch(search);
    }, [rows])


  return (
    <Paper
      component="form"
      sx={{
        paddingLeft: "10px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: '100%',
        height: '100%',
      }}
    >
      <InputBase
        type="text"
        name="search"
        value={search}
        onChange={(e) => handleSearch(e.target.value)}
        autoComplete="off"
        sx={{ display: "flex", flex: 1, ml: 1 }}
        placeholder={t('system.logs.searchBar')}
        inputProps={{ "aria-label": "search google maps" }}
      />

      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <IconButton type="button" sx={{ p: "10px" }} aria-label="search" disabled>
        <SearchIcon />
      </IconButton>
    </Paper>
  );
};
