import { Auth } from "../utils/auth";
import { HealthCheckApi } from "./HealthCheckApi";
import Router from "../router";
import axios from "./AxiosConfig";

// Permissions
import ability from '../config/defineAbility.js';

export class WorkflowDataFileApi {
    healthCheck = null;
    token = null;
    user = null;

    constructor() {
        let auth = new Auth();
        this.token = auth.getToken();
        this.user = auth.getUser();
        this.healthCheck = new HealthCheckApi();
    }

    async upload(data) {
        if (ability.can("UploadWorkflowDataFile")) {
            return await axios.post(Router.apiBaseUrl + Router.apiWorkflowDataFileUploadFiles, data)
            .then(async response => {
                return await this.healthCheck.checkApi(response)
            }).catch(async error => {
                return await this.healthCheck.checkApi(error)
            })
        }
        return {status: false};
    }

    async getFile(id) {
        if (ability.can("GetWorkflowDataFile")) {
            return await axios.get(Router.apiBaseUrl + Router.apiWorkflowDataFileDetail.replace(':id', id), {})
            .then(async response => {
                return await this.healthCheck.checkApi(response)
            }).catch(async error => {
                return await this.healthCheck.checkApi(error)
            })
        }
        return {status: false};
    }

    async delete(data) {
        if (ability.can("DeleteWorkflowDataFile")) {
            return await axios.delete(Router.apiBaseUrl + Router.apiWorkflowDataFile, {data: data}, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(async response => {
                return await this.healthCheck.checkApi(response)
            }).catch(async error => {
                return await this.healthCheck.checkApi(error)
            })
        }
        return {status: false};
    }
}