import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import { Link, useNavigate } from 'react-router-dom';
import Router from '../../../../router';
//Utils
import { ColorsContext, useColors } from "../../../../Context/ColorsContext"
import { FormatDate } from '../../../../utils/formatDate';
import { MediaQuerys } from '../../../../styles/GlobalStyle';

//Hooks
import { useSortByColumn } from '../../../../Hooks/useSortByColumn';

import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import LocationSearchingOutlinedIcon from '@mui/icons-material/LocationSearchingOutlined';
import LocationDisabledOutlinedIcon from '@mui/icons-material/LocationDisabledOutlined';
import { Grid, Tooltip } from '@mui/material';
import { LoadingProgress } from '../../../LoadingProgress';
import { getIcon } from '../../../../utils/iconConfig';
import { Delete, Edit } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { StickyColumn, CustomCell } from '../../../../styles/GlobalStyle';

import moment from "moment";
import 'moment/locale/es';

import {
  CustomTableCell,
  FigureHead,
  Cell,
  IconAction,
  NewIcon,
  IconContainer,
  TableContent,
  Forms,
  ContainerButonsEdit
} from './styles'
import { arrayToString } from '../../../../utils/commonFunctions/convert';

// Permissions
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../../../Context/PermissionsContext';

function getCheck(props) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { colors } = useColors()
  if (props === true) return <CheckCircleOutlineOutlinedIcon sx={{ color: colors.grayFont }} fontSize="medium" />
}

function getUrlPlot(props) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { colors } = useColors()
  if (props === '') return <LocationDisabledOutlinedIcon fontSize="inherit" sx={{ color: colors.white }} />
  if (props != '') return <LocationSearchingOutlinedIcon fontSize="inherit" sx={{ color: colors.white }} />
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const { t } = useTranslation();
  const { colors } = React.useContext(ColorsContext);
  const ability = useAbility(AbilityContext);
  const [headCells, setHeadCells] = React.useState([]);

  React.useEffect(() => {
    returnHeaders();
  }, [])

  const returnHeaders = () => {
    let heads = [
      {
        id: 'status_id',
        center: false,
        disablePadding: false,
        sortArrow: true,
        label: 'workflowManagement.flowState.table.statusCode',
        customSort: 'statusId',
        sticky: true,
        direction: 'left',
      },
      {
        id: 'title',
        center: false,
        disablePadding: true,
        sortArrow: true,
        label: 'workflowManagement.flowState.table.title'
      },
      {
        id: 'icon',
        center: false,
        disablePadding: true,
        sortArrow: true,
        label: 'workflowManagement.flowState.table.icon',
        customSort: 'icon',
      },
      {
        id: 'type',
        center: false,
        disablePadding: true,
        sortArrow: true,
        label: 'workflowManagement.flowState.table.statusType',
        customSort: 'type',
      },
      {
        id: 'role',
        center: false,
        disablePadding: true,
        sortArrow: false,
        label: 'workflowManagement.flowState.table.responsibleRole'
      },
      {
        id: 'executeOption',
        center: false,
        disablePadding: true,
        sortArrow: true,
        label: 'workflowManagement.flowState.table.roleExecute',
        customSort: 'executeOption',
      },
      {
        id: 'lastUpdated',
        center: false,
        disablePadding: true,
        sortArrow: true,
        label: 'workflowManagement.flowState.table.lastUpdate',
        customSort: 'lastUpdate',
      }
    ];

    if (ability.can("UpdateWorkflowStatus") || ability.can("DeleteWorkflowStatus")) {
      heads.push(addActionsRow())
    }

    setHeadCells(heads);
  }

  function addActionsRow() {
    return {
      id: 'actions',
      center: false,
      disablePadding: true,
      sortArrow: false,
      label: 'common.actions.title',
      sticky: true,
      direction: 'right',
    }
  }

  const createSortHandler = (property, customSort) => (event) => {
    onRequestSort(event, property, customSort);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <CustomCell
            key={headCell.id}
            align={headCell.center ? 'center' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            dataHead={headCell}
          >
            {headCell.sortArrow == true &&
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id, headCell.customSort)}
              >
                {t(headCell.label) || headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span">
                    {order === 'desc' ? '' : ''}
                  </Box>
                ) : null}
              </TableSortLabel>
            }
            {headCell.sortArrow == false &&
              <>
                <Box component="span">
                  {t(headCell.label) || headCell.label}
                </Box>
              </>
            }
          </CustomCell>
        ))}
      </TableRow>
    </TableHead >
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

function EnhancedTableToolbar(props) {
  const { t } = useTranslation();
  const { colors } = React.useContext(ColorsContext);
  const ability = useAbility(AbilityContext);
  const { mobile } = MediaQuerys;

  return (
    <Toolbar
      sx={{
        background: colors.gray,
        borderRadius: '5px 5px 0 0',
        minHeight: '60px !important',
        marginTop: '16px',
        [mobile]: { display: 'none' }
      }}
    >
      <Forms onSubmit={props.handleDatesFilter}>
        {ability.can("CreateWorkflowStatus") &&
          <Tooltip title={t('workflowManagement.flowState.table.createFlowState')}>
            <IconAction
              size="medium"
              aria-label="new"
              onClick={props.handleCreate}
            >
              <NewIcon />
            </IconAction>
          </Tooltip>
        }
      </Forms>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
};


export default function TableWorkflowStatus({
  filteredRequest,
  rows,
  loading,
  initialDate,
  setInitialDate,
  setFinalDate,
  finalDate,
  handleDatesFilter,
  handleClearDate,
  handleCreate,
  handleDelete
}) {

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { format } = new FormatDate();
  const { colors } = React.useContext(ColorsContext);
  const ability = useAbility(AbilityContext);

  const { order, orderBy, stableSort, handleRequestSort, getComparator } = useSortByColumn('lastUpdated');

  const [page, setPage] = React.useState(0);
  const [dense,] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  function checkUpdatePermissions(row) {
    if (row.isStandard) {
      return ability.can("UpdateWorkflowStatusStandard")
    }
    else {
      return ability.can("UpdateWorkflowStatus")
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;


  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar
          initialDate={initialDate}
          finalDate={finalDate}
          setInitialDate={setInitialDate}
          setFinalDate={setFinalDate}
          handleDatesFilter={handleDatesFilter}
          handleClearDate={handleClearDate}
          handleCreate={handleCreate}
        />
        {loading && <LoadingProgress />}
        {!loading &&
          <React.Fragment>
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
              >
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                <TableContent>
                  {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                      rows.sort(getComparator(order, orderBy)).slice() */}
                  {stableSort(filteredRequest, getComparator())
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {

                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                          sx={{ background: row.isStandard ? '#F5F5F5' : '' }}
                        >
                          <StickyColumn 
                            direction='left'
                            sx={{ background: row.isStandard ? '#F5F5F5' : colors.white }}
                          >
                            {row.statusId}
                          </StickyColumn>
                          <Cell>
                            {row.title}
                          </Cell>
                          <Cell>{getIcon(row) || ''}</Cell>
                          <Cell>{row.typeDescription}</Cell>
                          <Cell>{arrayToString(row.roles, 'name', true)}</Cell>
                          <Cell>{row.executeOption?.name}</Cell>
                          <Cell>
                            {format({ date: row?.lastUpdate, format: true })}
                          </Cell>
                          <StickyColumn 
                            direction='right'
                            sx={{ background: row.isStandard ? '#F5F5F5' : colors.white }}
                          >
                            <ContainerButonsEdit>
                              {checkUpdatePermissions(row) &&
                                <Grid item>
                                  {/* boton editar  */}
                                  <Tooltip title={t('workflowManagement.flowState.table.editStatus')}>
                                    <Link to={Router.appWorkflowStatusDetail.replace(':id', row.workflowId || row.idDetail)} state={{ row: row }}>
                                      <IconContainer
                                        size="small"
                                        aria-label="detail"
                                      >
                                        <Edit />
                                      </IconContainer>
                                    </Link>
                                  </Tooltip>
                                </Grid>
                              }
                              {ability.can("DeleteWorkflowStatus") &&
                                <Grid item>
                                  {/* boton eliminar  */}
                                  <Tooltip title={t('workflowManagement.flowState.table.deleteStatus')}>
                                    <IconContainer
                                      size="small"
                                      aria-label="delete"
                                      onClick={() => handleDelete(row)}
                                      disabled={row.isStandard ? true : false}
                                    >
                                      <Delete />
                                    </IconContainer>
                                  </Tooltip>
                                </Grid>
                              }
                            </ContainerButonsEdit>
                          </StickyColumn>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableContent>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={t('common.itemsPerPage')}
              labelDisplayedRows={
                ({ from, to, count }) => {
                  return '' + from + '-' + to + ` ${t('common.of')} ` + count
                }
              }
              sx={{
                borderTop: '1px solid ' + colors.gray,
              }}
            />
          </React.Fragment>
        }
      </Paper>
      {/*
        <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Dense padding"
        />
        */}
    </Box>
  );
}