import React, { useEffect, useState } from 'react';

// Custom components
import { PageTitle } from '../../components/PageTitle';
import { FormViewer } from '../../components/FormViewer';

// Styles
import { Container, } from '../styles';
import { FormContainer, SectionPageTitle, Text, Title } from './styles';

// API
import { FormApi } from '../../services/FormApi';

// Router
import { useLocation } from 'react-router-dom'
import Router from '../../router';

// Icons
import { ViewList, ArrowBack } from '@mui/icons-material';
import { Grid } from '@mui/material';

// Utils
import { useTranslation } from 'react-i18next'

export const FormManagementDetail = (props) => {
    const { t } = useTranslation();
    const formApi = new FormApi();
    const location = useLocation();
	const [row, setRow] = useState({id: null, name: ''})
	const [form, setForm] = useState({structure: []})
	const [loading, setLoading] = useState(false)

    useEffect(() => {
		let row = location?.state?.row;

		if (row?.id) {
			setRow(row)
	
			async function call() {
				setLoading(true)
				const response = await formApi.detail(row.id);
				if (response.status === 200) {
					setForm(response.data)
				}
				setLoading(false)
			}
			call()
		}
    }, [props.isDemo])

    return (
        <Container open={props.open} mb={6}>
			<SectionPageTitle>
				<PageTitle title="" isbutton={true} navigate={Router.appFormManagement} icon={<ArrowBack />} />
				<PageTitle title={t('forms.formManagement.formDetail') + ' ' + row?.id} icon={<ViewList/>} />
			</SectionPageTitle>
			{!loading && 
				<FormContainer>
					<Grid container mb={3}>
						<Grid item xs={4}>
							<Title>{t('forms.formManagement.formName')}: </Title> <Text>{form.name}</Text>
						</Grid>
						<Grid item xs={4}>
							<Title>{t('forms.formManagement.workflow')}: </Title><Text>{form.workflow?.name}</Text>
						</Grid>
						<Grid item xs={4}>
							<Title>{t('forms.formManagement.integrationKey')}: </Title><Text>{form.integrationKey}</Text>
						</Grid>
					</Grid>
					<FormViewer 
                        form={form.structure?.structure} 
                        isDetail={1} 
                        isEditable={1} 
                        hideFiles={true}
                    />
				</FormContainer>
			}
		</Container>
	)
}
