
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next'
import { CardGraphic } from '../CardGraphic';

import BrowserNotSupportedIcon from '@mui/icons-material/BrowserNotSupported';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { ComponentNoDataCharts } from '../../components/NoDataGraphic/index';

import { ContainerTopGrahps, ContainerGraphics, ContainerBottomGrahps, ContainerTreeMap, ContentGraphics, CustomTitleGraphics } from './style';

export const GrahpsDeviationsCompany = ({
    dataBarGrapsCompany,
    chartRef,
    optionsDonut,
    optionsGraphicDeviation,
    handleSeriesUpdate,
    chartContextsRef,
}) => {

    const { t } = useTranslation();
    const optionsGraphicsCompany = (key) => {
        const graphicData = dataBarGrapsCompany?.find(item => item.integrationKey === key);

        const colorList = [
            '#10476b', '#0778a6', '#b6b310', '#145f89', '#105b73', '#0b4b7a', '#0d5b8c',
            '#125f83', '#095f76', '#066d8a', '#1c6b96', '#1173a0', '#138fb4', '#11a0a0',
            '#0c8a9d', '#0d7b88', '#92a611', '#a9b211', '#838e13', '#6b7b14'
        ]

        const colors = {};

        // Obtener todos los nombres únicos de las series
        const uniqueSeriesNames = Array.from(new Set(dataBarGrapsCompany.flatMap(item => item.data.flatMap(info => info.series.map(serie => serie.name)))));

        uniqueSeriesNames.forEach((name, index) => {
            colors[name] = colorList[index % colorList.length];
        });

        return graphicData?.data.map((info, index) => {
            // Asignar colores a las series basados en el nombre de la sucursal
            const seriesWithColors = info.series.map(serie => ({
                ...serie,
                color: colors[serie.name] || "#000000"  // Color por defecto si no está en el objeto colors
            }));


            return {
                options: {
                    colors: seriesWithColors.map(serie => serie.color),
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '30%',
                            borderRadius: 4
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ["transparent"]
                    },
                    legend: {
                        show: false,
                        position: 'top'
                    },
                    xaxis: {
                        categories: info.categories,
                        group: {
                            style: {
                                fontSize: '10px',
                                fontWeight: 700,
                                border: 0
                            },
                            groups: [
                                { title: info.name, cols: info.categories.length }
                            ]
                        },
                        labels: {
                            rotate: 0,
                            formatter: function (value) {
                                if (typeof value === 'string') {
                                    const words = value.split(' ');

                                    return words;
                                }
                                return value;
                            },
                        },
                    },
                    fill: {
                        opacity: 1
                    },
                    tooltip: {
                        custom: function ({ seriesIndex, dataPointIndex, w }) {
                            let currentCategory = w.config.xaxis.categories[dataPointIndex];
                            let tooltipText = `<div class="apexcharts-tooltip-title" style="font-size: 14px; font-weight: bold; margin-bottom: 4px; border-bottom: 1px solid #e3e3e3; padding-bottom: 4px;">${currentCategory}</div>`;
                            tooltipText += `<div style="max-height: 200px; overflow-y: auto;">`;
                            w.globals.initialSeries.forEach((series, index) => {
                                let color = w.config.colors[index];
                                let value = series.data[dataPointIndex];
                                tooltipText += `<div style="display: flex; align-items: center; margin: 5px 5px;">
                                                    <div style="width: 12px; height: 12px; border-radius: 50%; background-color: ${color}; margin-right: 5px;"></div>
                                                    <span style="font-size: 12px;">${series.name}: <span style="font-weight: bold;">${value}</span></span>
                                                </div>`;
                            });
                            tooltipText += `</div>`;
                            return tooltipText;
                        },
                        fixed: {
                            enabled: true,
                            position: 'topLeft',
                            offsetX: index === 0 ? 150 : index === graphicData.data.length - 1 ? -100 : 0,
                            offsetY: 0,
                        },
                    },
                    chart: {
                        stacked: true,
                        height: '100%',
                        toolbar: {
                            show: false
                        },
                        events: {
                            mounted: function (chartContext) {
                                const totalData = dataBarGrapsCompany.reduce((acc, crr) => {
                                    return acc + crr.data.length
                                }, 0);
                                
                                chartContextsRef.current.push(chartContext);
                                if (chartContextsRef.current.length === totalData) {
                                    // Procesar solo cuando todos los gráficos han montado
                                    handleSeriesUpdate();
                                }
                            },
                            updated: function (chartContext) {
                                const totalData = dataBarGrapsCompany.reduce((acc, crr) => {
                                    return acc + crr.data.length
                                }, 0);

                                chartContextsRef.current.push(chartContext);
                                if (chartContextsRef.current.length === totalData) {
                                    // Procesar solo cuando todos los gráficos han actualizado
                                    handleSeriesUpdate();
                                }
                            }
                        }
                    },
                    series: seriesWithColors
                }
            };
        });
    };


    const renderGraphics = (key) => {
        const chartConfigurations = optionsGraphicsCompany(key);

        if (chartConfigurations.length === 0) {
            return (
                <ComponentNoDataCharts isFlow={true} />
            );
        }

        return chartConfigurations?.map((config, index) => (
            <CardGraphic
                key={config.options.xaxis.group.groups[0].title || index}
                width='350'
                title={config.options.xaxis.group.groups[0].title}
                type="bar"
                options={config.options}
                series={config.options.series}
                chartRef={chartRef}
            />
        ));
    };


    return (
        <>
            <ContainerTopGrahps>
                <ContentGraphics width='50%'>
                    <CustomTitleGraphics variant='h6'>Viabilización de actividades</CustomTitleGraphics>
                    <ContainerGraphics>
                        {renderGraphics('CJ54JOC6')}
                    </ContainerGraphics>
                </ContentGraphics>

                <ContentGraphics width='50%'>
                    <CustomTitleGraphics variant='h6'>Excavación</CustomTitleGraphics>
                    <ContainerGraphics>
                        {renderGraphics('7V3B11QA')}
                    </ContainerGraphics>
                </ContentGraphics>


            </ContainerTopGrahps>

            <ContainerBottomGrahps>
                <div style={{ width: '50%', display: 'flex', flexDirection: 'row', gap: '0.80rem' }}>
                    <ContentGraphics width='50%'>
                        <CustomTitleGraphics variant='h6'>SAS</CustomTitleGraphics>
                        <ContainerGraphics>
                            {renderGraphics('VUJBZIKW')}
                        </ContainerGraphics>
                    </ContentGraphics>

                    <ContentGraphics width='50%'>
                        <CustomTitleGraphics variant='h6'>SAES</CustomTitleGraphics>
                        <ContainerGraphics>
                            {renderGraphics('HUU8XTO8')}
                        </ContainerGraphics>
                    </ContentGraphics>
                </div>

                <div style={{ width: '50%', display: 'flex', flexDirection: 'row', gap: '0.80rem' }}>
                    <ContentGraphics width='50%'>
                        {optionsDonut.series.every(el => el !== 0) ? (
                            <CardGraphic
                                type="donut"
                                options={optionsDonut}
                                series={optionsDonut.series}
                                chartRef={chartRef}
                            />
                        ) : (
                            <ComponentNoDataCharts isFlow={false} />
                        )}
                    </ContentGraphics>


                    <ContainerTreeMap>
                        <ContentGraphics width='100%' height='50%'>
                            {optionsGraphicDeviation('up').series.length !== 0 ? (
                                <CardGraphic
                                    type="treemap"
                                    {...optionsGraphicDeviation('up')}
                                    chartRef={chartRef}
                                    height='100%'
                                />
                            ) : (
                                <ComponentNoDataCharts isFlow={false} />
                            )

                            }
                        </ContentGraphics>

                        <ContentGraphics width='100%' height='50%'>
                            {optionsGraphicDeviation('down').series.length !== 0 ? (
                                <CardGraphic
                                    type="treemap"
                                    {...optionsGraphicDeviation('down')}
                                    chartRef={chartRef}
                                    height='100%'
                                />
                            ) : (
                                <ComponentNoDataCharts isFlow={false} />
                            )

                            }
                        </ContentGraphics>
                    </ContainerTreeMap>
                </div>
            </ContainerBottomGrahps>
        </>
    )
}
