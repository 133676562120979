import { SecurityApi } from '../services/SecurityApi';

export class Auth {

    authenticate(token) {
        localStorage.setItem('token', token)
    }

    isAuthenticated() {
        let token = localStorage.getItem('token')
        if (token === null || token === undefined) return false

        const decodedJwt = parseJwt(token)
        if (decodedJwt === null || (decodedJwt.exp * 1000 < Date.now())) {
          localStorage.clear()
          return false
        }

        return true

        // return token !== null && token !== undefined
    }

    saveProfile(profile, strings) {
        profile.string = strings;
        localStorage.setItem('profile', JSON.stringify(profile) || '')
    }

    getToken() {
        return localStorage.getItem('token')
    }

    async logout() {
      const securityApi = new SecurityApi();

      localStorage.removeItem('token')
      localStorage.removeItem('profile')
      localStorage.removeItem('workflowStatus')
      localStorage.removeItem('workflowData')
      localStorage.removeItem('permissions')
      await securityApi.logout()
    }

    getUser() {
        let token = localStorage.getItem('token')
        if (token === null) return null

        const decodeJwt = parseJwt(token)

        let jsonResponse = JSON.parse(decodeJwt.user) || null

        if (jsonResponse == null) return jsonResponse

        const camelCaseData = toCamelCaseKeys(jsonResponse);
        
        return camelCaseData
    }

}
  
function parseJwt (token) {
  try{
    let base64Url = token.split('.')[1]
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    let jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join('')
    )
    // return JSON.parse(JSON.parse(jsonPayload).user)
    return JSON.parse(jsonPayload)
  } catch(error) {
    return null
  }
}

function toCamelCaseKeys(obj) {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }
  
  if (Array.isArray(obj)) {
    return obj.map(item => toCamelCaseKeys(item));
  }

  const camelCaseObj = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const camelCaseKey = key.charAt(0).toLowerCase() + key.slice(1);
      camelCaseObj[camelCaseKey] = toCamelCaseKeys(obj[key]);
    }
  }

  return camelCaseObj;
}