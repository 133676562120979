import styled from 'styled-components';
import { Typography } from "@mui/material";
import { useColors } from "../../Context/ColorsContext"

const tabletStartWidth = 768;
const mobile = `@media (max-width: ${tabletStartWidth}px)`

export const SectionPageTitle = styled('section')(() => ({
    display:'flex',
    width:'100%',
}))

export const TextSwitch = styled(Typography)(() => {
    const { colors } = useColors();    
    return {
        color: colors.main,
        fontWeight: 600, 
        fontSize: 18,
    }
})
