import React, { useState, useEffect } from "react";

const InactivityDetector = ({ onInactivity, time = 10000000 }) => {
  const [lastActivity, setLastActivity] = useState(Date.now());

  useEffect(() => {
    const handleMouseMove = () => setLastActivity(Date.now());
    const handleClick = () => setLastActivity(Date.now());
    const handleKeyPress = () => setLastActivity(Date.now());

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("click", handleClick);
    window.addEventListener("keypress", handleKeyPress);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("click", handleClick);
      window.removeEventListener("keypress", handleKeyPress);
    };
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (Date.now() - lastActivity >= time) {
        onInactivity();
      }
    }, time);

    return () => {
      clearTimeout(timeout);
    };
  }, [lastActivity, time, onInactivity]);

  return null;
};

export default InactivityDetector;
