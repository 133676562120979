import React, { useEffect, useState } from "react";

// Components
import { Button, Modal } from "@mui/material";
import { toast } from "react-toastify";
import moment from "moment";
import { useLocation } from "react-router-dom";

// Custom components
import { PageTitle } from "../../components/PageTitle";
import TableModel from "../../components/atoms/tables/TableModel";
import { BackdropLoading } from "../../components/BackdropLoading";
import { ShowDialog } from "../../components/ShowDialog";
import { SearchBar } from "../../components/atoms/searchBars/SearchBar";

// Containers
import { StandardWorkflowContainer } from "../../containers/StandardWorkflowContainer";

// Service
import { WorkflowStatusApi } from "../../services/WorkflowStatusApi";

//Custom Hook
import { useDownloadExcel } from "../../Hooks/useDownloadExcel";

// Styles
import {
  BoxModal,
  Container,
  ContainerBarSearch,
  ContainerButtonDownload,
  SectionPageTitle,
  SectionPageLeft,
  ContainersButtonsGroup,
} from "../styles";

// Material Icons
import { CloudDownloadOutlined, VideoSettings } from "@mui/icons-material";

// Utils
import { FormatDate } from "../../utils/formatDate";
import { getIcon } from "../../utils/iconConfig";
import { useTranslation } from "react-i18next";
import { ColorsContext } from "../../Context/ColorsContext"

// Permissions
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../Context/PermissionsContext'

export const StandardWorkflowManagement = (props) => {
  const { i18n, t } = useTranslation();
  const workflowStatusApi = new WorkflowStatusApi();
  const { colors } = React.useContext(ColorsContext);
  const ability = useAbility(AbilityContext);

	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const { format } = new FormatDate();
	const [filteredRequest, setFilteredRequest] = useState([])
	const [form, setForm] = useState({});
  const [headers, setHeaders] = useState([]);

  const [dialog, setDialog] = useState({
    status: false,
    title: "",
    message: ""
  });

  const fieldConfig = {
    [t("standardStatus.table.number")]: "id",
    [t("standardStatus.table.statusId")]: "statusId",
    [t("standardStatus.table.title")]: "title",
    [t("standardStatus.table.subtitle")]: "subtitle",
    [t("standardStatus.table.description")]: "description",
    [t("standardStatus.table.updatedAt")]: (item) =>
      format({ date: item.updatedAt, format: true }).toString(),
  };

  const { handleDownloadExcelList } = useDownloadExcel(filteredRequest, fieldConfig, t("standardStatus.title"));

  const [openForm, setOpenForm] = React.useState(false);

  useEffect(() => {
    returnHeaders();

    async function call() {
      await loadData();
    }
    call();
  }, [i18n.language]);

  async function loadData() {
		setLoading(true)
    let response = await workflowStatusApi.listFilterStandard();
		setLoading(false)
    if (response.status === 200) {
      setData(response.data);
      return response.data;
    }
  }

  async function handleEdit(row) {
    setForm({
      ...form,
      id: row.id,
      title: row.title || "",
      subtitle: row.subtitle || "",
      color: row.color || "",
      icon: row.icon || "",
      maxTime: row.maxTime || "",
		  minTime: row.minTime || "",
      notificationEmail: row.notificationEmail || false
    });
    setOpenForm(true);
  }

  function handleCloseForm() {
    setOpenForm(false);
  }

  function handleCloseDialog() {
    setDialog({ ...dialog, status: false });
  }

  const returnHeaders = () => {
    let heads = [
      {
        id: "statusId",
        label: "standardStatus.table.statusId",
        center: false,
        disablePadding: true,
        sortArrow: true,
        isRender: true,
        sticky: true,
        direction: 'left',
      },
      {
        id: "title",
        label: "standardStatus.table.title",
        center: false,
        disablePadding: true,
        sortArrow: true,
        isRender: true,
      },
      {
        id: "description",
        label: "standardStatus.table.description",
        center: false,
        disablePadding: true,
        sortArrow: true,
        isRender: true,
      },
      {
        id: "icon",
        label: "standardStatus.table.icon",
        center: false,
        disablePadding: true,
        sortArrow: true,
        format: (value) => getIcon(value),
      },
      {
        id: "updatedAt",
        label: "standardStatus.table.updatedAt",
        center: false,
        disablePadding: true,
        sortArrow: true,
        format: (value) => format({ date: value.updatedAt, format: true }), 
      }
    ];

    let newActions = [];
    if (ability.can("UpdateWorkflowStatusStandard")) {
      newActions.push('edit');
    }

    if (newActions.length > 0) {
      heads.push(addActionsRow(newActions))
    }

    setHeaders(heads);
  }

  function addActionsRow(actions) {
    return {
      id: "actions",
      label: "common.actions.title",
      center: false,
      disablePadding: false,
      sortArrow: false,
      type: "actions",
      sticky: true,
      direction: 'right',
      keys: actions
    }
  }

  return (
    <Container open={props.open}>
      <SectionPageTitle>
        <SectionPageLeft>
          <PageTitle
            title={t("standardStatus.title")}
            icon={<VideoSettings />}
          />
          <ContainerBarSearch>
            <SearchBar
              rows={data}
              setFilteredRequest={setFilteredRequest}
              filterText={t("common.searchBars.standardStatus")}
              filterKeys={[
                "id",
                "statusId",
                "title",
                "subtitle",
                "description"
              ]}
            />
          </ContainerBarSearch>
        </SectionPageLeft>


        <ContainersButtonsGroup>
          <ContainerButtonDownload>
            <Button
              variant="contained"
              endIcon={<CloudDownloadOutlined />}
              sx={{
                height: "100%",
                width: "100%",
                padding: "0 20px",
                backgroundColor: colors.buttonActionColor,
                color: colors.white,
                "&:hover": {
                  backgroundColor: colors.buttonActionColor,
                  opacity: 0.9,
                  color: colors.white
                }
              }}
              aria-label="download"
              onClick={handleDownloadExcelList}
            >
              {t("common.download")}
            </Button>
          </ContainerButtonDownload>
        </ContainersButtonsGroup>
      </SectionPageTitle>

      <TableModel
        headers={headers}
        filteredRequest={filteredRequest}
        setFilteredRequest={setFilteredRequest}
        rows={data}
        handleEdit={handleEdit}
        hideFilterDate={true}
      />

      <Modal open={openForm}>
        <BoxModal>
					<StandardWorkflowContainer
						data={form}
						roles={[]}
						loadData={loadData}
						handleCloseForm={handleCloseForm}
            isStandardFlow={true}
					/>
        </BoxModal>
      </Modal>

      <BackdropLoading open={loading} />
      <ShowDialog
        openDialog={dialog.status}
        dialog={dialog}
        handleCloseDialog={handleCloseDialog}
      />
    </Container>
  );
};
