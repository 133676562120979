import {styled} from "@mui/material/styles";
import { Box } from '@mui/material';

export const Container = styled(Box)(({full}) => ({
    width: '100%',
    height: '100%',
    justifyContent:'center',
    alignItems:'center',
    textAlign: 'center',
    margin: full ? '' : '40px 0px',
}))