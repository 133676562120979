import React, { useContext, useEffect, useState } from 'react'

// Components
import { Accordion, AccordionDetails, AccordionSummary, Grid, Tooltip, Typography } from '@mui/material';

// Custom components
import { BackdropLoading } from '../BackdropLoading';
import { PlotPlan } from '../../pages/PlotPlan';
import { ChildrenDialog } from '../ChildrenDialog';

// Services
import { PlotPlanApi } from '../../services/PlotPlanApi';
import Router from '../../router';
import { useNavigate } from 'react-router-dom';

// Styles
import { EmptyContainer } from '../../pages/styles';

// Icons
import { ExpandMore, VisibilityOutlined } from '@mui/icons-material';

// Utils
import { useTranslation } from 'react-i18next'
import { ColorsContext } from '../../Context/ColorsContext';

export const PlotPlanView = ({ data, openDialog, handleCloseDialog }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { colors } = useContext(ColorsContext);
    const plotPlanApi = new PlotPlanApi();

    const [plotPlans, setPlotPlans] = useState([])
    const [expanded, setExpanded] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setPlotPlans([])
        let plotPlanIds = []
        if (data.plotPlans?.length > 0) plotPlanIds = data.plotPlans
        else if (data.plotPlan?.id) plotPlanIds = [data.plotPlan]
        else if (data.workflowData?.plotPlans?.length > 0) plotPlanIds = data.workflowData.plotPlans
        else if (data.workflowData?.plotPlan?.id) plotPlanIds = [data.workflowData.plotPlan]
        
        if (plotPlanIds?.length === 0 || !data?.id) return
        async function call() {
            let plotPlans = []
            setLoading(true)
            await Promise.all(
                plotPlanIds?.map(async (obj) => {
                    let response = await plotPlanApi.getById(obj.id)
                    if (response.status === 200) {
                        plotPlans.push(response.data)
                    }
                })
            )
            setPlotPlans(plotPlans)
            setLoading(false)
            if (plotPlans?.length > 0) setExpanded('panel1')
        }
        call()
    }, [data])
    
    const handleChange = (panel, id) => async(event, isExpanded) => {
        if (event.target.id?.includes('eye-button')) {
            navigate(Router.appPlotPlans, { state: { id: id } })
        }
        else {
            setExpanded(isExpanded ? panel : false);
        }
    };
    
    return (
        <>
            <ChildrenDialog 
                openDialog={openDialog} 
                handleCloseDialog={handleCloseDialog}
                title={t('plotPlans.modal.title', {name: data.code})}
            >
                {plotPlans?.length > 0 ?
                <>
                    {plotPlans.map((obj, index) => (
                        <Accordion 
                            key={obj.id} 
                            expanded={expanded === `panel${index+1}`} 
                            onChange={handleChange(`panel${index+1}`, obj.id)}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"                
                            >
                                <Grid container alignItems="center">
                                    <Grid item xs="auto" id="eye-button-grid" sx={{ background: 'white' }}>
                                        <Tooltip title={t('plotPlans.goTo')} id="eye-button-tooltip">
                                            <span id="eye-button-div">
                                                <VisibilityOutlined 
                                                    id="eye-button" 
                                                    sx={{ 
                                                        color: colors.blue, 
                                                        background: colors.white, 
                                                        '&:hover': {opacity: .8} 
                                                    }}
                                                />
                                            </span>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs="auto">
                                        <Typography pl={2} mr={2}>{obj.name}</Typography>
                                    </Grid>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                                <PlotPlan id={obj.id} plotPlansChildren={plotPlans} isModal={true}/>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </>
                :
                    <EmptyContainer>{t('common.empty.noData')}</EmptyContainer>
                }
                <BackdropLoading open={loading}/>
            </ChildrenDialog>
        </>
    )
}