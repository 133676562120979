import React, { useEffect, useState } from "react";

// Components
import moment from "moment";
import { Modal, Tooltip } from "@mui/material";
import { toast } from "react-toastify";

// Custom components
import { PageTitle } from "../../components/PageTitle";
import { SearchBar } from "../../components/atoms/searchBars/SearchBar";
import TableModel from "../../components/atoms/tables/TableModel";
import { ShowDialog } from "../../components/ShowDialog";
import { BackdropLoading } from "../../components/BackdropLoading";
import { CompanyModalCard } from "../../components/atoms/modalCards/CompanyModalCard";

// Services
import { CompanyApi } from "../../services/CompanyApi";

// Styles
import { BoxModal, Container, ContainerBarSearch, ContainerButtonDownload, ContainersButtonsGroup, SectionPageLeft, SectionPageTitle } from "../styles";
import { ContainerButtonCreate, DownloadButton } from "./styles";

// Icons
import { CloudDownloadOutlined, Business } from "@mui/icons-material";

// Utils
import { useTranslation } from 'react-i18next'
import { CheckResponse } from "../../utils/checkResponse";

// Hooks
import { useDownloadExcel } from "../../Hooks/useDownloadExcel";

// Permissions
import { useAbility } from '@casl/react';
import { AbilityContext } from "../../Context/PermissionsContext";

export const Company = (props) => {
  const { t } = useTranslation();
  const ability = useAbility(AbilityContext);

  const companyApi = new CompanyApi();
  const { check } = new CheckResponse();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [filteredRequest, setFilteredRequest] = useState([]);
  const [finalDate, setFinalDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [initialDate, setInitialDate] = useState(
    moment(finalDate).subtract(1, "months").format("YYYY-MM-DD")
  );
  const [dialog, setDialog] = useState({
    status: false,
    title: "",
    message: "",
    type: null,
  });

  const [openCompanyModal, setOpenCompanyModal] = useState(false);
  const [detail, setDetail] = useState({});
  
  const [headers, setHeaders] = useState([]);

  useEffect(() => {
    returnHeaders();
    
    async function call() {
      await loadData();
    }
    call();
  }, []);

  const fieldsConfig = {
    [t('company.table.id')]: 'id',
    [t('company.table.nit')]: 'nit',
    [t('company.table.dv')]: 'dv',
    [t('company.table.name')]: 'name',
    [t('company.table.isActive')]: (item) => item.isActive ? t('common.yes') : t('common.no'),
  };

  const { handleDownloadExcelList } = useDownloadExcel(filteredRequest, fieldsConfig, t('company.title'));

  const returnHeaders = () => {
    let heads = [
      {
        id: "nit",
        label: "company.table.nit",
        isRender: true,
        center: false,
        sortArrow: true,
        sticky: true,
        direction: 'left',
      },
      {
        id: "dv",
        label: "company.table.dv",
        isRender: true,
        center: false,
        sortArrow: true
      },
      {
        id: "name",
        label: "company.table.name",
        isRender: true,
        center: false,
        sortArrow: true
      },
      {
        id: "isActive",
        label: "company.table.isActive",
        center: false,
        sortArrow: true,
        format: (value) => value.isActive ? t('common.yes') : t('common.no'),
        customSort: "isActive ? 'Si' : 'No'",
      },
    ];

    let newActions = [];
    if (ability.can("UpdateCompanies")) {
      newActions.push('edit');
    }
    if (ability.can("DeleteCompanies")) {
      newActions.push('delete');
    }

    if (newActions.length > 0) {
      heads.push(addActionsRow(newActions))
    }

    setHeaders(heads);
  }

  function addActionsRow(actions) {
    return {
      id: "actions",
      label: "common.actions.title",
      type: "actions",
      center: false,
      sortArrow: false,
      sticky: true,
      direction: 'right',
      keys: actions,
    }
  }

  async function loadData() {
    setLoading(true);
    let response = await companyApi.list();
    if (response.status === 200) {
      setData(response.data);
    }
    setLoading(false);
  }

  function handleEdit(row) {
    setOpenCompanyModal(true)
    setDetail(row)
  }

  function handleCreate() {
    setOpenCompanyModal(true)
    setDetail({})
  }

  async function handleSaveEdit(data) {
    let formData = { ...data }

    setLoading(true);
    let response = null
    if (formData.id) response = await companyApi.update(formData.id, formData);
    else response = await companyApi.create(formData);

    let result = check(response)

    if (result.status) {
      setOpenCompanyModal(false);
      setDialog({
        status: true,
        title: t('company.messages.save.title'),
        message: t('company.messages.save.message'),
        type: null,
      });
      await loadData();
    }
    else {
      toast.error(() =>
        <div>{t('company.messages.save.error')}<br />
          {result.errors}
        </div>
      );
    }
    setLoading(false);
  }

  async function handleDelete() {
    if (!dialog.row.id) {
      return
    }

    setLoading(true);
    let response = await companyApi.delete(dialog.row.id);
    let result = check(response)

    if (result.status) {
      handleCloseModalDelete();
      setDialog({
        status: true,
        title: t('company.messages.delete.title'),
        message: t('company.messages.delete.message'),
        type: null,
      });
      await loadData();
    }
    else {
      toast.error(() =>
        <div>{t('company.messages.delete.error')}<br />
          {result.errors}
        </div>
      );
    }
    setLoading(false);
  }

  async function handleOpenModalDelete(row) {
    setDialog({
      status: true,
      title: t('company.messages.delete.confirm'),
      message: t('company.messages.delete.confirmMessage', { company: row.name }),
      type: "confirmation",
      row: row
    });
  }

  async function handleCloseModalDelete(confirmDelete) {
    if (confirmDelete) {
      setDialog({ ...dialog, status: false });
      await handleDelete()
    } else {
      setDialog({ status: false });
    }
  }

  return (
    <Container open={props.open}>
      <SectionPageTitle>
        <SectionPageLeft>
          <PageTitle title={t('company.title')} icon={<Business />} />
          <ContainerBarSearch>
            <SearchBar
              rows={data}
              setFilteredRequest={setFilteredRequest}
              filterText={t('common.searchBars.company')}
              filterKeys={['id', 'nit', 'name']}
            />
          </ContainerBarSearch>
        </SectionPageLeft>

        <ContainersButtonsGroup twoContainers={true}>
          <ContainerButtonDownload>
            <DownloadButton
              variant="contained"
              endIcon={<CloudDownloadOutlined />}
              aria-label="download"
              onClick={handleDownloadExcelList}
            >
              {t('common.download')}
            </DownloadButton>
          </ContainerButtonDownload>

          {ability.can('CreateCompanies') &&
            <ContainerButtonCreate>
              <DownloadButton
                variant="contained"
                endIcon={<Business />}
                aria-label="download"
                onClick={handleCreate}
              >
                {t('company.modal.create')}
              </DownloadButton>
            </ContainerButtonCreate>
          }

        </ContainersButtonsGroup>
      </SectionPageTitle>

      <TableModel
        headers={headers}
        filteredRequest={filteredRequest}
        rows={data}
        // Manage date
        initialDate={initialDate}
        setInitialDate={setInitialDate}
        finalDate={finalDate}
        setFinalDate={setFinalDate}
        // Handle edit, delete, process
        handleEdit={handleEdit}
        handleDelete={handleOpenModalDelete}
        hideFilterDate={true}
      />

      <ShowDialog
        openDialog={dialog.status}
        dialog={dialog}
        handleCloseDialog={handleCloseModalDelete}
      />

      <Modal open={openCompanyModal}>
        <BoxModal>
          <CompanyModalCard
            data={detail}
            handleSave={handleSaveEdit}
            handleClose={() => setOpenCompanyModal(false)}
          />
        </BoxModal>
      </Modal>

      <BackdropLoading open={loading} />

    </Container>
  );
};
