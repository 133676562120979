import React, { Fragment } from 'react';

// Components
import { Grid, Tooltip } from '@mui/material';
import { SelectInput } from '../../SelectInput';
import { SelectAll } from '../../SelectAll';

// Styles
import { Delete } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

// Icons
import { CustomGrid, RemoveIconButton, StickyHeader } from './styles';
import { FormButton } from '../../../pages/styles';

// Utils
import { ColorsContext } from '../../../Context/ColorsContext';
import { useTranslation } from 'react-i18next'
import { AttachmentTechnicalSupervisors } from '../../AttachmentTechnicalSupervisors';
import { Auth } from "../../../utils/auth";

// Permissions
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../../Context/PermissionsContext';

export const ContractWorkersAssign = ({
    data,
    users,
    plantList,
    handleChange,
    clickAdd,
    clickRemove,
    loading,
    titleWorker = '',
    isTechnicalSupervisor = false,
    handleFilesDrop,
    deleteAttach,
    downloadFile
}) => {

    const theme = useTheme();
    const { t } = useTranslation();
    const { colors } = React.useContext(ColorsContext);
    const ability = useAbility(AbilityContext);

    const [user, setUser] = React.useState(null);
    const { getUser } = new Auth();

    function returnSelectedData(arraySelected, list) {
		// Logic for MUI Autocomplete component bug
		// Data of autocomplete multiple must be taken directly from options array
        let setlData = []
        if (!list) return []
            arraySelected?.map((el) => {
                let objData = list?.filter((obj) => obj.id === el.id)
                setlData.push(...objData)
            })
        return setlData
    }

    function checkDisabled(row, disabledForce = false) {
        if (!isTechnicalSupervisor) return false
        
        // Si puede crear contratos y no puede subir adjuntos, entonces puede modificar supervisores
        if (ability.can("CreateContract") && !ability.can("UploadAttachmentSupervisor")) return false || disabledForce

        if ((row?.username?.toString().toLowerCase() === user.username?.toString().toLowerCase()) && !disabledForce) return true
        else if (row?.username?.toString().toLowerCase() !== user.username?.toString().toLowerCase()) return true
    }

    function onFilesDrop(newFile, idx) {
        handleFilesDrop(newFile, idx)
    }

    function onFilesDelete(file, supIdx) {
        deleteAttach(file, supIdx)
    }

    React.useEffect(() => {
        if (user === null) {
          setUser(getUser());
        }
    }, [user, getUser]);

    return (
        <>
            <CustomGrid container sx={{ maxHeight: '250px', overflowY: 'auto' }}>
                <StickyHeader item xs={isTechnicalSupervisor ? 3 : 4}>{titleWorker || t('contract.table.contractor')}</StickyHeader>
                <StickyHeader item xs={isTechnicalSupervisor ? 5 : 7}>{t('contract.table.scope')}</StickyHeader>
                {isTechnicalSupervisor && <StickyHeader item xs={3}>{t('contract.table.attachments')}</StickyHeader>}
                <StickyHeader item xs={1}>{t('common.actions.delete')}</StickyHeader>
                {data.map((row, index) => (
                    <Fragment key={`contractor-${index}`}>
                        <Grid item xs={isTechnicalSupervisor ? 3 : 4}>
                            <SelectInput
                                placeholder={titleWorker || t('contract.table.contractor')}
                                onChange={(value) => handleChange('contractors', value, index)}
                                value={row}
                                options={users}
                                keyValue="contractor"
                                sx={{ width: '25%' }}
                                error={row.errorEmptyContractors}
                                disabled={loading || checkDisabled(row)}
                            />
                        </Grid>
                        <Grid item xs={isTechnicalSupervisor ? 5 : 7}>
                            <SelectAll
                                placeholder={t('contract.table.plants')}
                                onChange={(value) => handleChange('plants', value, index)}
                                value={returnSelectedData(row.plants, plantList)}
                                options={plantList || []}
                                keyValue="name"
                                sx={{ width: '25%' }}
                                multiple={true}
                                hasError={!(!row.hasEmptyPlants)}
                                messageError={row.errorEmptyPlants}
                                disabled={loading || checkDisabled(row)}
                            />
                        </Grid>
                        {isTechnicalSupervisor && 
                            <Grid item xs={3}>
                                <AttachmentTechnicalSupervisors files={row?.supervisorAttachments || []} disabled={checkDisabled(row, true)} onFilesDrop={(e) => onFilesDrop(e, index)} deleteAttach={(e) => onFilesDelete(e, index)} downloadFile={downloadFile} />
                            </Grid>
                        }
                        <Grid item xs={1}>
                            <Grid container display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'} sx={{ height: '100%' }}>
                                <Grid item xs={12} sx={{ display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center' }}>
                                    <Tooltip title={t('contract.remove')}>
                                        <RemoveIconButton
                                            size="large"
                                            onClick={() => clickRemove(index)}
                                            disabled={loading || checkDisabled(row)}
                                        >
                                            <Delete fontSize='34px'/>
                                        </RemoveIconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Fragment>
                ))}
            </CustomGrid>

            {/* Si puede crear contrato y no puede subir archivo, entonces puede agregar los supervisores */}
            {((isTechnicalSupervisor && ability.can("CreateContract") && !ability.can("UploadAttachmentSupervisor")) || !isTechnicalSupervisor) && 
                <Grid container mt={2}>
                    <FormButton variant="contained" onClick={clickAdd} disabled={loading}>
                        {t('contract.addAnother')}
                    </FormButton>
                </Grid>
            }
        </>
    )
}