import {styled} from "@mui/material/styles";
import { CardContent, Grid, Typography } from '@mui/material';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { useColors } from "../../../Context/ColorsContext"
import { MediaQuerys } from "../../../styles/GlobalStyle";

const { mobile, tablet, desktop } = MediaQuerys

export const Title = styled(Typography)(() => {
    const { colors } = useColors();
    return {
        color: colors.grayFont,
        fontSize: 12,
        lineHeight: 1.2,
        [desktop]: {
            fontSize: '0.80rem'
        }
    }
})

export const Content = styled(CardContent)(({isdetail, isSelected}) => {
    const { colors } = useColors();
    return {
        padding: '10px 10px 6px 15px',
        paddingBottom: '6px !important',
        cursor: isdetail ? 'default' : 'pointer',
        backgroundColor: isSelected ? colors.gray : 'inherit',
        '&:hover': {
            background: isdetail ? 'none' : colors.gray
        }
    }
})

export const IconArea = styled('div')(() => {
    const { colors } = useColors();
    return {
        background: colors.orange,
        color: colors.white,
        width: 26,
        height: 26,
        fontSize: 16,
        fontWeight: 'bold',
        borderRadius: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
})

export const ContentTitle = styled(Grid)(() => ({
    padding: '4px 4px 4px 15px'
}))

export const ListAlt = styled(ListAltIcon)(() => {
    const { colors } = useColors();
    return {
        color: colors.grayFont,
        fontsize: 14
    }
})