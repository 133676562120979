import React, { useEffect, useState } from "react";

// Components
import moment from "moment";
import { Modal, Tooltip } from "@mui/material";
import { toast } from "react-toastify";

// Custom components
import { PageTitle } from "../../components/PageTitle";
import { SearchBar } from "../../components/atoms/searchBars/SearchBar";
import TableModel from "../../components/atoms/tables/TableModel";
import { ShowDialog } from "../../components/ShowDialog";
import { BackdropLoading } from "../../components/BackdropLoading";
import { ContractModalCard } from "../../components/atoms/modalCards/ContractModalCard";
import { ContractorModalCard } from "../../components/atoms/modalCards/ContractorModalCard";

// Services
import { ContractApi } from "../../services/ContractApi";

// Styles
import { BoxModal, Container, ContainerBarSearch, ContainerButtonDownload, ContainersButtonsGroup, CustomIconButton, SectionPageLeft, SectionPageTitle } from "../styles";
import { ContainerButtonCreate, DownloadButton } from "./styles";

// Icons
import { CloudDownloadOutlined, Description, Engineering, AssignmentInd, Edit, Delete } from "@mui/icons-material";

// Utils
import { useTranslation } from 'react-i18next'
import { FormatDate } from "../../utils/formatDate";
import { CheckResponse } from "../../utils/checkResponse";
import { validateFiles } from "../../utils/commonFunctions";

// Hooks
import { useDownloadExcel } from "../../Hooks/useDownloadExcel";
import { TechnicalSupervisorModalCard } from "../../components/atoms/modalCards/TechnicalSupervisorModalCard";

// Permissions
import { useAbility } from '@casl/react';
import { AbilityContext } from "../../Context/PermissionsContext";

export const assignContractor = (row, title, handleAssignUser) => (
  <Tooltip
    title={title}
  >
    <span>
      <CustomIconButton
        size="small"
        onClick={() => handleAssignUser(row)}
        disabled={row.technicalSupervisors?.length === 0 || row.isActive === false}
      >
        <Engineering />
      </CustomIconButton>
    </span>
  </Tooltip>
)

export const assignTechnicalSupervisor = (row, title, handleAssignTechnicalSupervisor) => (
  <Tooltip
    title={title}
  >
    <span>
      <CustomIconButton
        size="small"
        onClick={() => handleAssignTechnicalSupervisor(row)}
        disabled={row.isActive === false}
      >
        <AssignmentInd />
      </CustomIconButton>
    </span>
  </Tooltip>
)

export const Contract = (props) => {
  const { t } = useTranslation();
  const ability = useAbility(AbilityContext);

  const contractApi = new ContractApi();
  const { format } = new FormatDate();
  const { check } = new CheckResponse();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [filteredRequest, setFilteredRequest] = useState([]);
  const [finalDate, setFinalDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [initialDate, setInitialDate] = useState(
    moment(finalDate).subtract(1, "months").format("YYYY-MM-DD")
  );
  const [datesForm, setDatesForm] = useState({
    initialDate: { initialDate },
    finalDate: { finalDate },
  });
  const [dialog, setDialog] = useState({
    status: false,
    title: "",
    message: "",
    type: null,
  });

  const [openContractModal, setOpenContractModal] = useState(false);
  const [openContractorModal, setOpenContractorModal] = useState(false);
  const [openTechnicalSupervisorModal, setOpenTechnicalSupervisorModal] = useState(false);
  const [detail, setDetail] = useState({});

  const [headers, setHeaders] = useState([]);
  const strings = JSON.parse(localStorage.getItem('profile')).string;

  useEffect(() => {
    returnHeaders();

    async function call() {
      await loadDataDates();
    }
    call();
  }, []);

  const fieldsConfig = {
    [t('contract.table.id')]: 'id',
    [t('contract.table.code')]: 'code',
    [t('contract.table.contractDocument')]: (item) => item.contractDocument?.name,
    [t('contract.table.nit')]: (item) => item.company?.nit,
    [t('contract.table.company')]: (item) => item.company?.name,
    [t('contract.table.contractor')]: (item) => item.contractor?.name,
    [t('contract.table.validity')]: (item) => `${format({ date: item.validSince, formatWithoutHour: true })} - ${format({ date: item.validUntil, formatWithoutHour: true })}`,
    [t('contract.table.isActive')]: (item) => item.isActive ? t('common.yes') : t('common.no'),
    [t('contract.table.updatedAt')]: (item) => format({ date: item.updatedAt, format: true }).toString(),
  };

  const { handleDownloadExcelList } = useDownloadExcel(filteredRequest, fieldsConfig, t('contract.title'));

  const returnHeaders = () => {
    let heads = [
      {
        id: "code",
        label: "contract.table.code",
        isRender: true,
        center: false,
        sortArrow: true,
        sticky: true,
        direction: 'left',
      },
      {
        id: "contractDocument",
        label: "contract.table.contractDocument",
        format: (value) => value.contractDocument?.name,
        center: false,
        sortArrow: true
      },
      {
        id: "ContactNit",
        label: "contract.table.nit",
        format: (value) => value.company?.nit,
        center: false,
        sortArrow: true
      },
      {
        id: "provider",
        label: "contract.table.company",
        format: (value) => value.company?.name,
        center: false,
        sortArrow: true
      },
      {
        id: "technicalSupervisor",
        label: "contract.table.technicalSupervisor",
        type: "technicalSupervisors",
        center: false,
        sortArrow: true,
        customSort: "technicalSupervisor?.name",
      },
      {
        id: "contractor",
        label: "contract.table.contractor",
        type: "contractors",
        center: true,
        sortArrow: true,
      },
      {
        id: "validity",
        label: "contract.table.validity",
        format: (value) => format({ date: value.validUntil, formatWithoutHour: true }),
        center: false,
        sortArrow: true,
        customSort: "validSince",
        needTooltip: (value) => validityTooltip(value.validSince, value.validUntil)
      },
      {
        id: "isActive",
        label: "contract.table.isActive",
        center: false,
        sortArrow: true,
        format: (value) => value.isActive ? t('common.yes') : t('common.no'),
        customSort: "isActive ? 'Si' : 'No'",
      },
      {
        id: "updatedAt",
        label: "contract.table.updatedAt",
        format: (value) => format({ date: value.updatedAt, format: true }),
        center: false,
        sortArrow: true
      }
    ];

    let newActions = [];
    if (ability.can("UpdateContract")) {
      newActions.push('edit');
    }
    if (ability.can("GetTechnicalSupervisors")) {
      newActions.push('custom');
    }
    if (ability.can("GetContractorsContract") || ability.can("GetContractorExecutors")) {
      newActions.push('custom2');
    }
    if (ability.can("DeleteContract")) {
      newActions.push('delete');
    }

    if (newActions.length > 0) {
      heads.push(addActionsRow(newActions))
    }

    setHeaders(heads);
  }

  function addActionsRow(actions) {
    return {
      id: "actions",
      label: "common.actions.title",
      type: "actions",
      center: false,
      sortArrow: false,
      actionsFormat: (value, key) => {
        if (key === "custom") return assignTechnicalSupervisor(value, strings?.contractsTechnicalSupervisor || "", handleAssignTechnicalSupervisor)
        else if (key === "custom2") return assignContractor(value, t("contract.assignContractor"), handleAssignUser)
      },
      sticky: true,
      direction: 'right',
      keys: actions
    }
  }

  // data SpeedDial for dimension mobile
  const addDataSpeedDial = (row) => {
    const options = [
      {
        name: 'edit',
        tooltip: t("common.actions.edit"),
        onClick: () => handleEdit(row),
        icon: <Edit />,
      },
      {
        name: 'supervisors',
        tooltip: t("contract.assignTechnicalSupervisor"),
        onClick: () => handleAssignTechnicalSupervisor(row),
        icon: <Engineering />,
        show: ability.can("GetTechnicalSupervisors"),
        disabled: row.technicalSupervisors?.length === 0 || row.isActive === false
      },
      {
        name: 'managers',
        tooltip: t("contract.assignContractor"),
        onClick: () => handleAssignUser(row),
        icon: <AssignmentInd />,
        show: ability.can("GetContractorsContract") || ability.can("GetContractorExecutors"),
        disabled: row.isActive === false,
      },
      {
        name: 'delete',
        tooltip: t("common.actions.cancel"),
        onClick: () => handleOpenModalDelete(row),
        icon: <Delete />,
        show: ability.can("DeleteContract"),
        disabled: row.disabledDelete !== undefined
          ? row.disabledDelete
          : false,
      }
    ];

    return options
  };

  async function loadData() {
    setLoading(true);
    let response = await contractApi.list();
    if (response.status === 200) {
      setData(response.data);
    }
    setLoading(false);
    return response.data;
  }

  async function handleDatesFilter(e) {
    e?.preventDefault();
    let result = await loadDataDates();
    e?.target.reset();
    return result
  }

  async function loadDataDates(date1, date2) {
    let startDate = date1 || initialDate
    let endDate = date2 || finalDate

    setLoading(true);
    setDatesForm({ ...datesForm, startDate, endDate });

    let response = await loadData();
    let newData = response?.map((item) => {
      return {
        ...item,
        company: { ...item.company, provider: `${item.company?.nit ?? ""}-${item.company?.dv ?? ""}, ${item.company?.name ?? ""}` }
      }
    });
    // let newData = response
    setData(newData);
    setLoading(false);
    return newData
  }

  async function handleClearDate() {
    let endDate = moment(new Date()).format("YYYY-MM-DD")
    let startDate = moment(endDate).subtract(1, "months").format("YYYY-MM-DD")
    setFinalDate(endDate);
    setInitialDate(startDate);

    await loadDataDates(startDate, endDate);
  }

  function handleEdit(row) {
    setOpenContractModal(true)
    setDetail(row)
  }

  function handleCreate() {
    setOpenContractModal(true)
    setDetail({})
  }

  async function handleSaveEdit(data) {
    if (!validateContract(data.code)) {
      toast.error(t('contract.messages.save.invalidContract'));
      return
    }
    let formData = {
      ...data,
      contractDocumentId: data.contractDocument.id,
      plantsId: data.plants.map((el) => el.id),
      companyId: data.company.id
    }

    setLoading(true);
    let response = null
    if (formData.id) response = await contractApi.update(formData.id, formData);
    else response = await contractApi.create(formData);

    let result = check(response)

    if (result.status) {
      setOpenContractModal(false);
      setDialog({
        status: true,
        title: t('contract.messages.save.title'),
        message: t('contract.messages.save.message'),
        type: null,
      });
      await loadDataDates();
    }
    else {
      toast.error(() =>
        <div>{t('contract.messages.save.error')}<br />
          {result.errors}
        </div>
      );
    }
    setLoading(false);
  }

  function validateContract(contract) {
    return (contract?.length !== 10 || isNaN(contract)) ? false : true
  }

  async function handleSaveContractor(data) {

    let formData = []
    let contractId = data.id;

    data.contractors.forEach((item) => {
      formData.push({
        contractId,
        contractorId: item.id,
        plantsId: item.plants.map((el) => el.id)
      })
    })

    setLoading(true)
    const response = await contractApi.updateContractor(contractId, formData);
    let result = check(response)

    if (result.status) {
      setOpenContractorModal(false);
      setDialog({
        status: true,
        title: t('contract.messages.saveContractor.title'),
        message: t('contract.messages.saveContractor.message'),
        type: null,
      });
      await loadDataDates();
    }
    else {
      toast.error(() =>
        <div>{t('contract.messages.saveContractor.error')}<br />
          {result.errors}
        </div>
      );
    }
    setLoading(false);
  }

  async function handleSaveTechnicalSupervisor(data) {
    let arrFiles = []
    let formData = []
    let formFilesData = new FormData()
    let contractId = data.id;

    data.technicalSupervisors.forEach((item) => {
      // Generamos el objeto de registro/actualización de supervisores
      formData.push({
        contractId,
        contractorId: item.id,
        plantsId: item.plants.map((el) => el.id)
      })

      // Generamos el listado de adjuntos a registrar
      item.supervisorAttachments.forEach((attachment) => {
        if (attachment instanceof File) {
          arrFiles.push(arrFiles)
          formFilesData.append("files", attachment)
        }
      })
    })

    setLoading(true)
    let response = null;

    if (arrFiles.length > 0) {
      let arrayFiles = [];
      data.technicalSupervisors?.map((el) => {
        el.supervisorAttachments?.map((el2) => {
          if (el2.path) arrayFiles.push(el2)
        })
      })
      
      let resultCheck = await validateFiles(arrayFiles)
      if (!resultCheck) {
        setLoading(false)
        return
      }
      response = await contractApi.uploadFilesTechnicalSupervisor(contractId, formFilesData);
    }
    else {
      response = await contractApi.updateTechnicalSupervisor(contractId, formData);
    }

    let result = check(response)

    if (result.status) {
      setOpenTechnicalSupervisorModal(false);
      setDialog({
        status: true,
        title: t('contract.messages.saveTechnicalSupervisor.title'),
        message: t('contract.messages.saveTechnicalSupervisor.message'),
        type: null,
      });
      await loadDataDates();
    }
    else {
      toast.error(() =>
        <div>{t('contract.messages.saveTechnicalSupervisor.error')}<br />
          {result.errors}
        </div>
      );
    }
    setLoading(false);
  }

  async function handleDelete() {
    if (!dialog.row.id) {
      return
    }

    setLoading(true);
    let response = await contractApi.delete(dialog.row.id);
    let result = check(response)

    if (result.status) {
      handleCloseModalDelete();
      setDialog({
        status: true,
        title: t('contract.messages.delete.title'),
        message: t('contract.messages.delete.message'),
        type: null,
      });
      await loadDataDates();
    }
    else {
      toast.error(() =>
        <div>{t('contract.messages.delete.error')}<br />
          {result.errors}
        </div>
      );
    }
    setLoading(false);
  }

  async function handleAssignUser(row) {
    setDetail(row)
    setOpenContractorModal(true)
  }

  async function handleAssignTechnicalSupervisor(row) {
    setDetail(row)
    setOpenTechnicalSupervisorModal(true)
  }


  async function handleOpenModalDelete(row) {
    setDialog({
      status: true,
      title: t('contract.messages.delete.confirm'),
      message: t('contract.messages.delete.confirmMessage', { contract: row.code }),
      type: "confirmation",
      row: row
    });
  }

  async function handleCloseModalDelete(confirmDelete) {
    if (confirmDelete) {
      setDialog({ ...dialog, status: false });
      await handleDelete()
    } else {
      setDialog({ status: false });
    }
  }

  const validityTooltip = (fromDate, toDate) => {
    let validity = '';

    try {
      if (fromDate && toDate !== null) {

        validity = `${t('common.from')} ${format({ date: fromDate, formatWithoutHour: true })}
            ${t('common.to')} ${format({ date: toDate, formatWithoutHour: true })} 
          `
      }
    } catch (error) {
      console.log('ERROR', error)
    }

    return validity
  }

  return (
    <Container open={props.open}>
      <SectionPageTitle>
        <SectionPageLeft>
          <PageTitle title={t('contract.title')} icon={<Description />} />
          <ContainerBarSearch>
            <SearchBar
              rows={data}
              setFilteredRequest={setFilteredRequest}
              filterText={t('common.searchBars.contract')}
              filterKeys={['id', 'code', 'e.contractDocument?.name', 'e.company?.nit', 'e.company?.name', 'e.contractor?.name', 'e.contractor?.lastName', 'updatedAt']}
            />
          </ContainerBarSearch>
        </SectionPageLeft>

        <ContainersButtonsGroup twoContainers={true}>
          <ContainerButtonDownload>
            <DownloadButton
              variant="contained"
              endIcon={<CloudDownloadOutlined />}
              aria-label="download"
              onClick={handleDownloadExcelList}
            >
              {t('common.download')}
            </DownloadButton>
          </ContainerButtonDownload>

          {ability.can('CreateContract') &&
            <ContainerButtonCreate>
              <DownloadButton
                variant="contained"
                endIcon={<Description />}
                aria-label="download"
                onClick={handleCreate}
              >
                {t('contract.modal.create')}
              </DownloadButton>
            </ContainerButtonCreate>
          }

        </ContainersButtonsGroup>
      </SectionPageTitle>

      <TableModel
        headers={headers}
        filteredRequest={filteredRequest}
        rows={data}
        // Manage date
        initialDate={initialDate}
        setInitialDate={setInitialDate}
        finalDate={finalDate}
        setFinalDate={setFinalDate}
        handleDatesFilter={handleDatesFilter}
        handleClearDate={handleClearDate}
        // Handle edit, delete, process
        handleEdit={handleEdit}
        handleDelete={handleOpenModalDelete}
        validityTooltip={validityTooltip}
        hideFilterDate={true}
        addDataSpeedDial={addDataSpeedDial}
        needSpeedDial={true}
      />

      <ShowDialog
        openDialog={dialog.status}
        dialog={dialog}
        handleCloseDialog={handleCloseModalDelete}
      />

      <Modal open={openContractModal}>
        <BoxModal>
          <ContractModalCard
            data={detail}
            handleSave={handleSaveEdit}
            handleClose={() => setOpenContractModal(false)}
          />
        </BoxModal>
      </Modal>

      <Modal open={openContractorModal}>
        <BoxModal>
          <ContractorModalCard
            data={detail}
            handleSave={handleSaveContractor}
            handleClose={() => setOpenContractorModal(false)}
          />
        </BoxModal>
      </Modal>

      <Modal open={openTechnicalSupervisorModal}>
        <BoxModal>
          <TechnicalSupervisorModalCard
            data={detail}
            handleSave={handleSaveTechnicalSupervisor}
            handleClose={() => setOpenTechnicalSupervisorModal(false)}
            titleHeader={strings}
          />
        </BoxModal>
      </Modal>

      <BackdropLoading open={loading} />

    </Container>
  );
};
