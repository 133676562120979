import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Auth } from "./utils/auth";
import { GlobalStyle } from './styles/GlobalStyle';
import { ToastContainer } from "react-toastify";
import Router from "./router";

import { PrivateRoute } from "./components/PrivateRoute";
import { PublicLayout } from "./components/PublicLayout";
import { PlotPlan } from "./pages/PlotPlan";
import { WorkPermitDetail } from "./pages/WorkPermitDetail";
import { Login } from "./pages/Login";
import { DynamicForm } from "./pages/DynamicForm";
import { Workflow } from "./pages/Workflow";
import { WorkflowManagement } from "./pages/WorkflowManagement";
import { WorkflowStatus } from "./pages/WorkflowStatus";
import { WorkflowManagementDetail } from "./pages/WorkflowManagementDetail";
import { WorkflowStatusDetail } from "./pages/WorkflowStatusDetail";
import { Maintenance } from "./pages/Maintenance";
import { Forbidden } from "./pages/Forbidden";
import { FormManagement } from "./pages/FormManagement";
import { FormManagementV2 } from "./pages/FormManagementV2";
import { FormManagementDetail } from "./pages/FormManagementDetail";
import { FormManagementDetailV2 } from "./pages/FormManagementDetailV2";
import { UserList } from "./pages/UserList";
import { PlotPlanCreate } from "./pages/PlotPlanCreate";
import { PermissionList } from "./pages/PermissionList";
import { PasswordPolicyForm } from "./pages/PasswordPolicyForm";
import { SessionCheckConfigForm } from "./pages/SessionCheckConfigForm";
import { Lists } from "./pages/Lists";
import { ListDetail } from "./pages/ListDetail";
import { MyWork } from "./pages/MyWork";
import { PasswordRecovery } from "./pages/PasswordRecovery";
import { PasswordReset } from "./pages/PasswordReset";
import { DashboardReport } from "./pages/DashboardReport";
import { LogsList } from "./pages/LogsList";
import { Settings } from "./pages/Settings";
import { CreateFormioForm } from "./pages/CreateFormioForm";
import { StandardWorkflowManagement } from "./pages/StandardWorkflowManagement";
import { AppearanceForm } from "./pages/AppearanceForm";

import { ColorsProvider } from './Context/ColorsContext';
import { Contract } from "./pages/Contract";
import { Company } from "./pages/Company";
import { CompanyWorker } from "./pages/CompanyWorker";

// User permissions
import { PermissionsProvider } from "./Context/PermissionsContext";
import { ExternalActions } from "./pages/ExternalActions";
import { HomeSigninAzure } from "./pages/HomeSigninAzure";
import { DeviationsReports } from "./pages/DeviationsReport";

export default function App() {

	const { isAuthenticated } = new Auth();

	function changeFavicon(url) {
		let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
		link.type = 'image/x-icon';
		link.rel = 'shortcut icon';
		link.href = url;
		document.getElementsByTagName('head')[0].appendChild(link);
	}

	useEffect(() => {
		changeFavicon(`${Router.apiBaseUrl}${Router.appStaticAppearance}/favicon.ico`)
	}, [])

	return (
        <PermissionsProvider>
            <ColorsProvider>
                <GlobalStyle />
                <ToastContainer theme="colored" position="top-center" autoClose={3000} />
                <BrowserRouter>
                    <Routes>
                        <Route path={Router.appLogin}
                            element={
                                isAuthenticated() ? <Navigate to={Router.appMyWork} replace /> : <Login />
                            }
                        />
                        <Route path={Router.appSigninAzure}
                            element={
                                <HomeSigninAzure />
                            }
                        />
                        {[Router.appMyWork, "/"].map(path =>
                            <Route key={path} path={path}
                                element={
                                    <PrivateRoute permissionName="GetNotifications">
                                        <MyWork />
                                    </PrivateRoute>
                                }
                            />
                        )}
                        <Route path={Router.appDashboardReport}
                            element={
                                <PrivateRoute permissionName="GetDashboard">
                                    <DashboardReport />
                                </PrivateRoute>
                            }
                        />
                        <Route path={Router.appDeviationsReport}
                            element={
                                <PrivateRoute permissionName="GetDashboard">
                                    <DeviationsReports/>
                                </PrivateRoute>
                            }
                        />
                        <Route path={Router.appWorkflow}
                            element={
                                <PrivateRoute permissionName={["ability.can('GetWorkflowData') && ability.can('WorkflowList')"]}>
                                    <Workflow />
                                </PrivateRoute>
                            }
                        />
                        <Route path={Router.appWorkflowDetail}
                            element={
                                <PrivateRoute permissionName="GetWorkflowDataById">
                                    <WorkPermitDetail />
                                </PrivateRoute>
                            }
                        />
                        <Route path={Router.appPlotPlans}
                            element={
                                <PrivateRoute permissionName={["ability.can('PlotPlanList') && ability.can('GetPlotPlan')"]}>
                                    <PlotPlan />
                                </PrivateRoute>
                            }
                        />
                        <Route path={Router.appCreatePlotPlans}
                            element={
                                <PrivateRoute permissionName="CreatePlotPlan">
                                    <PlotPlanCreate />
                                </PrivateRoute>
                            }
                        />
                        <Route path={Router.appDynamicForm}
                            element={
                                <PrivateRoute permissionName="GetFormData">
                                    <DynamicForm />
                                </PrivateRoute>
                            }
                        />
                        <Route path={Router.appWorkflowManagement}
                            element={
                                <PrivateRoute permissionName="WorkflowList">
                                    <WorkflowManagement />
                                </PrivateRoute>
                            }
                        />
                        <Route path={Router.appStandardWorkflowManagement}
                            element={
                                <PrivateRoute permissionName="WorkflowStatusStandardList">
                                    <StandardWorkflowManagement />
                                </PrivateRoute>
                            }
                        />
                        <Route path={Router.appWorkflowStatus}
                            element={
                                <PrivateRoute permissionName={["ability.can('WorkflowStatusList') || ability.can('WorkflowStatusStandardList')"]}>
                                    <WorkflowStatus />
                                </PrivateRoute>
                            }
                        />
                        <Route path={Router.appWorkflowManagementDetail}
                            element={
                                <PrivateRoute permissionName="UpdateWorkflow">
                                    <WorkflowManagementDetail />
                                </PrivateRoute>
                            }
                        />
                        <Route path={Router.appWorkflowStatusDetail}
                            element={
                                <PrivateRoute permissionName="UpdateWorkflowStatus">
                                    <WorkflowStatusDetail />
                                </PrivateRoute>
                            }
                        />
                        <Route path={Router.appCreateFormTwo}
                            element={
                                <PrivateRoute permissionName="CreateForm">
                                    <CreateFormioForm />
                                </PrivateRoute>
                            }
                        />
                        <Route path={Router.appFormManagement}
                            element={
                                <PrivateRoute permissionName="GetForms">
                                    <FormManagement />
                                </PrivateRoute>
                            }
                        />
                        <Route path={Router.appFormManagementV2}
                            element={
                                <PrivateRoute permissionName="GetForms">
                                    <FormManagementV2 />
                                </PrivateRoute>
                            }
                        />
                        <Route path={Router.appFormManagementDetail}
                            element={
                                <PrivateRoute permissionName="GetForms">
                                    <FormManagementDetail />
                                </PrivateRoute>
                            }
                        />
                        <Route path={Router.appFormManagementDetailV2}
                            element={
                                <PrivateRoute permissionName="GetForms">
                                    <FormManagementDetailV2 />
                                </PrivateRoute>
                            }
                        />
                        <Route path={Router.appUsers}
                            element={
                                <PrivateRoute permissionName="UserList">
                                    <UserList />
                                </PrivateRoute>
                            }
                        />
                        <Route path={Router.applogs}
                            element={
                                <PrivateRoute permissionName="LogList">
                                    <LogsList />
                                </PrivateRoute>
                            }
                        />
                        <Route path={Router.appPermissions}
                            element={
                                <PrivateRoute permissionName="RoleList">
                                    <PermissionList />
                                </PrivateRoute>
                            }
                        />
                        <Route path={Router.appPasswordPolicy}
                            element={
                                <PrivateRoute permissionName="PasswordPolicy">
                                    <PasswordPolicyForm />
                                </PrivateRoute>
                            }
                        />
                        <Route path={Router.appAppearance}
                            element={
                                <PrivateRoute permissionName="All">
                                    <AppearanceForm />
                                </PrivateRoute>
                            }
                        />
                        <Route path={Router.appInactivityTime}
                            element={
                                <PrivateRoute permissionName="All">
                                    <SessionCheckConfigForm />
                                </PrivateRoute>
                            }
                        />
                        <Route path={Router.appLists}
                            element={
                                <PrivateRoute permissionName="ListList">
                                    <Lists />
                                </PrivateRoute>
                            }
                        />
                        <Route path={Router.appSettings}
                            element={
                                <PrivateRoute permissionName={["ability.can('WorkflowList') || ability.can('PasswordPolicy') || ability.can('GetAppearanceConfig') || ability.can('InactivityTimeConfig')"]}>
                                    <Settings />
                                </PrivateRoute>
                            }
                        />
                        <Route path={Router.appContract}
                            element={
                                <PrivateRoute permissionName="ContractList">
                                    <Contract />
                                </PrivateRoute>
                            }
                        />
                        <Route path={Router.appListDetail}
                            element={
                                <PrivateRoute permissionName={["ability.can('CreateList') || ability.can('UpdateList')"]}>
                                    <ListDetail />
                                </PrivateRoute>
                            }
                        />
                        <Route path={Router.appCompanies}
                            element={
                                <PrivateRoute permissionName="AllCompanies">
                                    <Company />
                                </PrivateRoute>
                            }
                        />
                        <Route path={Router.appCompanyWorker}
                            element={
                                <PrivateRoute permissionName="GetWorkers">
                                    <CompanyWorker />
                                </PrivateRoute>
                            }
                        />
                        <Route path={Router.appExternalActions}
                            element={
                                <PublicLayout>
                                    <ExternalActions />
                                </PublicLayout>
                            }
                        />
                        <Route path={Router.appPasswordRecovery}
                            element={
                                <PasswordRecovery />
                            }
                        />
                        <Route path={Router.appPasswordReset}
                            element={
                                <PasswordReset />
                            }
                        />
                        <Route path={Router.appMaintenance}
                            element={
                                <Maintenance />
                            }
                        />
                        <Route path={Router.appForbidden}
                            element={
                                <Forbidden />
                            }
                        />
                    </Routes>
                </BrowserRouter>
            </ColorsProvider>
        </PermissionsProvider>
	);
}
