import React from 'react';
import { Card, Divider, Grid, Tooltip } from '@mui/material';
import { ArrowLeft, Content, ContentTitle, GridContainer, IconArea, Title, CustomCard } from './styles';
import { StatusCardArea } from '../statusCardArea';
import { getIcon } from '../../../utils/iconConfig';
import { WorkPermitCardArea } from '../workPermitCardArea';
import { useTranslation } from 'react-i18next'

export const StatusCardAreaTitle = ({data, handleBack, onClickCode}) => {
    const { t } = useTranslation();

    return (
        <>
            <Card>
                <ContentTitle container alignItems="center" justifyContent="center">
                    <Grid item xs={2}>
                        <IconArea>{data.title?.element}</IconArea>
                    </Grid>
                    <Grid item xs={8}>
                        <Title>{data.title?.name}</Title>
                    </Grid>
                    <Grid item xs={2}>
                        <GridContainer container spacing={0} justifyContent="left" alignItems="center">
                            <Grid item xs={1}>
                                <Tooltip title={t('plotPlans.goBack')}>
                                    <ArrowLeft onClick={handleBack}/>
                                </Tooltip>
                            </Grid>
                        </GridContainer>
                    </Grid>
                </ContentTitle>
                <Divider/>
            </Card>
            {data.detail.map((row, index) => (
                <Card key={"title-status-" + index} sx={{ marginTop: 2 }}>
                    <ContentTitle container alignItems="center" justifyContent="center">
                        <Grid item xs={2}>
                            {getIcon(row.status, 15, 11)}
                        </Grid>
                        <Grid item xs={10}>
                            <Title>{row.status?.title}</Title>
                        </Grid>
                    </ContentTitle>
                    <Divider/>
                    {row.workflowDatas?.length > 0 ? 
                        <StatusCardArea data={row.workflowDatas} onClick={onClickCode}/>
                    :
                        <Content>
                            <Title sx={{ textAlign: 'center', margin: 'auto' }}>{t('common.empty.noData')}</Title>
                        </Content>
                    }
                </Card>
            ))}
        </>
    )
}

