import { Checkbox, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useColors } from "../../Context/ColorsContext"
import { Button, Card, CardHeader, Grid, Divider, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { ColuumnCenter } from './styles';
import { MediaQuerys } from "../../styles/GlobalStyle";
import { Placeholder } from "semantic-ui-react";


const { mobile, tablet, desktop, desktoplarge } = MediaQuerys;

export const CustomCheckbox = styled(Checkbox)(() => {
  const { colors } = useColors();
  return {
    color: colors.grayGraphic,
    '&.Mui-checked': {
      color: colors.buttonActionColor
    },
    '&.MuiCheckbox-indeterminate': {
      color: colors.buttonActionColor
    },
    [mobile]: {
      '.MuiListItemIcon-root': {
        minWidth: '30px !important',
      }
    }
  }
})

export const SectionBottomInfo = styled('section')(() => ({
  display:'flex',
  justifyContent:'center',
  alignItems:'center',
  width: '100%'
}))

export const Text = styled(Typography)(() => {
  const { colors } = useColors();
  return {
    color: colors.red,
    fontSize: 16,
    fontWeight: 'bold',
    margin: 0,
    padding: 0,
  }
})

export const ContainerCustomButton = styled('div')(() => {
  return {
    [mobile]: {
      '& .MuiButtonBase-root': {
        minWidth: 'auto',
      }
    }
  }
})

export const CustomListItemText = styled(ListItemText)(() => {
  return {
    [desktop]: {
      '.MuiTypography-root': { fontSize: '0.75rem' },
    }
  }
})

export const CustomList = styled(List)(() => {
  const { colors } = useColors();
  return {
    width: '300px',
    height: '330px',
    backgroundColor: colors.backgroundColor,
    overflow: 'auto',
    [mobile]: {
      width: 'auto',
    }
  }
})

export const ContainerGroupButtons = styled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }
})

export const ContainerContent = styled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    [mobile]: {
      minWidth: '400px !important',
    }
  }
})

export const ColumnLeft = styled('div')(() => {
  return {
    width: '45%',
  }
})

export const ColumnCenter = styled('div')(() => {
  return {
    width: '10%'
  }
})

export const ColumnRight = styled('div')(() => {
  return {
    width: '45%'
  }
})
