import {styled} from "@mui/material/styles";
import { CardContent, Grid, Typography } from "@mui/material";
import { useColors } from "../../Context/ColorsContext"
import { KeyboardArrowLeft, KeyboardDoubleArrowLeft } from "@mui/icons-material";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { TreeItem } from '@mui/x-tree-view';
import { MediaQuerys } from "../../styles/GlobalStyle";

const { mobile, tablet, desktop } = MediaQuerys;

export const Content = styled(CardContent)(() => ({
    padding: '10px !important'
}))

export const GridCard = styled(Grid)(() => {
  const { colors } = useColors(); 
  return {
      background: colors.white,
      padding: 20,
      borderRadius: '4px',
      width: '100%',
      height: '100%',
      transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
  }
})

export const GridContainer = styled(Grid)(() => {
  const { colors } = useColors(); 
  return {
      padding: '4px 10px 0px 10px',
      color: colors.grayFont
  }
})

export const ArrowLeft = styled(KeyboardArrowLeft)(({disabled}) => {
  const { colors } = useColors(); 
  return {
      color: colors.white,
      background: disabled ? colors.grayFont : colors.main,
      borderRadius: 20,
      cursor: disabled ? 'default' : 'pointer'
  }
})

export const ArrowsLeft = styled(KeyboardDoubleArrowLeft)(({disabled}) => {
  const { colors } = useColors(); 
  return {
      color: colors.white,
      background: disabled ? colors.grayFont : colors.main,
      borderRadius: 20,
      cursor: disabled ? 'default' : 'pointer'
  }
})

export const Ul = styled('ul')(() => ({
    listStyleType: 'circle !important'
}))


export const Li = styled('li')(() => ({
}))

export const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      fontSize: theme.typography.pxToRem(12),
    },
  }));

  export const Title = styled(Typography)(() => ({
    fontWeight: 'bold',
    textAlign: 'center',
    [desktop]: {
      fontSize: '0.90rem',
    }
  }))

export const TreeItemText = styled(Typography)(() => ({
    fontSize: 14,
    paddingLeft: 14,
    cursor: 'default'
}))

export const StyledTreeItem = styled((props) => (
  <TreeItem {...props} />
))(({ theme }) => ({
  [`& .MuiTreeItem-iconContainer`]: {
    '& .close': {
      opacity: 0.3,
    },
  },
  [`& .MuiTreeItem-group`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: '1px dashed #ccc',
  },
}));

export const ContainerTitle = styled('div')(() => {
  return {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
  }
})

export const ContainerActions = styled('div')(() => {
  return {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '10px 0px'
  }
})

export const ContainerLeft = styled('div')(() => {
  return {
    width: '40%',
    [mobile]: {
      width: '60%',
    },
    [tablet]: {
      width: '50%',
    }
  }
})

export const ContainerRight = styled('div')(() => {
  return {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '30%',
  }
})

export const ContainerGraphics = styled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',

  }
})

export const ContainerCard = styled('div')(() => {
  return {
    width: '50%',
    [mobile]: {
      width: '100%',
    }
  }
})

export const ContainerGroup = styled('div')(() => {
  return {
    display: 'flex',
    width: '100%',
    gap: '1rem',
    flexDirection: 'row',
    [mobile]: {
      flexDirection: 'column',
    }
  }
})

export const ContainerContent = styled('div')(() => {
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'stretch',
  }
})

export const ContainerButtonDownload = styled("div")(() => ({
  width: 140,
  height: 40,
  [desktop]: {
    width: "120px",
    height: "30px",
    "& button": {
      fontSize: "0.65rem",
    },
  },
  [tablet]: {
    width: "100px",
    height: 28,
    "& button": {
      fontSize: "7px",
    },
  },
}));