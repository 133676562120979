import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

// Custom components
import { PageTitle } from "../../components/PageTitle";
import { SearchBarForms } from "../../components/atoms/searchBars/SearchBarForms";
import TableModel from "../../components/atoms/tables/TableModel";
import { ShowDialog } from "../../components/ShowDialog";
import { BackdropLoading } from "../../components/BackdropLoading";
import { Tooltip, Button } from "@mui/material";

// Styles
import {
  Container,
  ContainerBarSearch,
  ContainerButtonDownload,
  SectionPageLeft,
  SectionPageTitle,
} from "../styles";

import { ContainersButtonsGroup, NewIcon, IconAction } from "./styles";
import { ColorsContext } from "../../Context/ColorsContext";

// API
import { FormApi } from "../../services/FormApi";

// Router
import { useNavigate, useLocation } from "react-router-dom";
import Router from "../../router";

// Icons
import { ViewComfy, CloudDownloadOutlined } from "@mui/icons-material";

// Utils
import { CheckResponse } from "../../utils/checkResponse";
import { FormatDate } from "../../utils/formatDate";
import { useTranslation } from "react-i18next";

//Hooks
import { useDownloadExcel } from "../../Hooks/useDownloadExcel";

// Permissions
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../Context/PermissionsContext'

export const FormManagementV2 = (props) => {
  const { t } = useTranslation();
	const { colors } = React.useContext(ColorsContext);
  const ability = useAbility(AbilityContext);

  const location = useLocation();
  const formApi = new FormApi();
	const { check } = new CheckResponse();
	const { format } = new FormatDate();
  const isFormV2 = location.pathname === "/form-management-v2"

  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [filteredRequest, setFilteredRequest] = useState([]);
	const [dialog, setDialog] = useState({
		status: false,
		title: '',
		message: '',
		type: null
  });
  
  const [headers, setHeaders] = useState([]);

  //?? Prepare fields to download Excel
	const fieldsConfig = {
		[t('forms.formManagement.number')]: 'id',
		[t('forms.formManagement.formName')]: 'name',
		[t('forms.formManagement.workflow')]: (item) => item?.workflow?.name,
		[t('forms.formManagement.integrationKey')]: 'integrationKey',
		[t('forms.formManagement.lastUpdate')]: (item) => format({ date: item?.updatedAt, format: true }).toString()
	}

  //?? Custom Hook
  const { handleDownloadExcelList } = useDownloadExcel(
    filteredRequest,
    fieldsConfig,
    t("sidebar.formsv2")
  );

  useEffect(() => {
    returnHeaders();

    async function call() {
			await loadData();
    }
    call();
  }, [props.isDemo]);

  const returnHeaders = () => {
    let heads = [
      {
        id: 'id',
        label: 'forms.formManagement.number',
        center: false,
        disablePadding:true,
        sortArrow: true,
        isRender: true,
        link: true,
        sticky: true,
        direction: 'left',
      },
      {
        id: 'name',
        label: 'forms.formManagement.formName',
        center: false,
        disablePadding:true,
        sortArrow: true,
        isRender: true,
      },
      {
        id: 'workflow',
        label: 'forms.formManagement.workflow',
        center: false,
        disablePadding: true,
        sortArrow: true,
        type: 'object',
        keys: ['icon', 'name'],
        customSort: 'workflow.name',
      },
      {
        id: 'integrationKey',
        label: 'forms.formManagement.integrationKey',
        center: false,
        disablePadding:true,
        sortArrow: true,
        isRender: true,
      },
      {
        id: 'updatedAt',
        label: 'forms.formManagement.lastUpdate',
        center: false,
        disablePadding:true,
        sortArrow: true,
        type: 'date',
      }
    ];

    let newActions = [];
    if (ability.can("UpdateForm")) {
      newActions.push('edit');
    }
    if (ability.can("DeleteForm")) {
      newActions.push('delete');
    }

    if (newActions.length > 0) {
      heads.push(addActionsRow(newActions))
    }

    setHeaders(heads);
  }

  function addActionsRow(actions) {
    return {
      id: 'actions',
      label: 'common.actions.title',
      center: false,
      disablePadding: false,
      sortArrow: false,
      type: 'actions',
      sticky: true,
      direction: 'right',
      keys: actions
    }
  }

  async function loadData() {
		setLoading(true);
    let response = await formApi.list(null, null, "form_io");
    if (response.status === 200) {
      setData(response.data);
    }
		setLoading(false);
		return response.data || [];
  }

  async function handleCreateNew() {
    navigate(Router.appCreateFormTwo);
  }

  async function handleEdit(row) {
    navigate(Router.appCreateFormTwo, { state: { row: row } });
  }

  async function handleDelete(id) {
    setLoading(true);
    let response = await formApi.delete(id);
    let result = check(response);

    if (result.status) {
      setDialog({
        status: true,
        title: t("forms.formManagement.messages.deletedForm.title"),
        message: t("forms.formManagement.messages.deletedForm.message"),
        type: null,
      });
			await loadData();
    } else {
      toast.error(() => (
        <div>
          {t("forms.formManagement.messages.deletedForm.error")}
          <br />
          {result.errors}
        </div>
      ));
    }

    setLoading(false);
  }

  async function handleOpenDialog(row) {
    setDialog({
      status: true,
      title: t("forms.formManagement.messages.deletedForm.confirm"),
      message: t("forms.formManagement.messages.deletedForm.confirmMessage", {
        form: row.name,
      }),
      type: "confirmation",
      id: row.id,
    });
  }

  async function handleCloseDialog(value) {
    if (value) {
      setDialog({ ...dialog, status: false });
      await handleDelete(dialog.id);
    } else {
      setDialog({ status: false });
    }
  }

  return (
    <Container open={props.open}>
      <SectionPageTitle>
        <SectionPageLeft>
          <PageTitle
            title={t("forms.formManagement.title")}
            icon={<ViewComfy />}
          />
          <ContainerBarSearch>
            <SearchBarForms
              rows={data}
              setFilteredRequest={setFilteredRequest}
            />
          </ContainerBarSearch>
        </SectionPageLeft>

        <ContainersButtonsGroup>
          
          {handleCreateNew && (
            <Tooltip title={t("forms.formManagement.createNewForm")}>
              <IconAction size="medium" onClick={handleCreateNew}>
                <NewIcon />
              </IconAction>
            </Tooltip>
          )}

          <ContainerButtonDownload>
            <Button
              variant="contained"
              endIcon={<CloudDownloadOutlined />}
              sx={{
                height: "100%",
                width: "100%",
                padding: "0 20px",
                backgroundColor: colors.buttonActionColor,
                color: colors.white,
                "&:hover": {
                  backgroundColor: colors.buttonActionColor,
                  opacity: 0.9,
                  color: colors.white
                } 
              }}
              aria-label="download"
              onClick={handleDownloadExcelList}
            >
              {t("common.download")}
            </Button>
          </ContainerButtonDownload>

        </ContainersButtonsGroup>
      </SectionPageTitle>

      <TableModel
        headers={headers}
        filteredRequest={filteredRequest}
        rows={data}
        // Handle create, edit, delete
        handleCreateNew={ability.can("CreateForm") ? handleCreateNew : null}
        labelCreateNew={t("forms.formManagement.createNewForm")}
        handleEdit={handleEdit}
        handleDelete={handleOpenDialog}
        isFormV2={isFormV2}
        isList={true}
      />

      <BackdropLoading open={loading} />
      <ShowDialog
        openDialog={dialog.status}
        dialog={dialog}
        handleCloseDialog={handleCloseDialog}
      />
    </Container>
  );
};
