import React, { useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import { RoleApi } from '../../services/RoleApi';
import { PermissionApi } from '../../services/PermissionApi';

import { Button, Modal } from '@mui/material';
import { CloudDownloadOutlined } from '@mui/icons-material';

import { PageTitle } from '../../components/PageTitle';
import TableRoles from '../../components/atoms/tables/TableRoles';
import { BackdropLoading } from '../../components/BackdropLoading';
import { ShowDialog } from '../../components/ShowDialog';
import { RoleModalCard } from '../../components/atoms/modalCards/RoleModalCard';
import { ListPermissions } from '../../components/atoms/modalCards/ListPermissions'
import { ChangePermissions } from '../../components/atoms/modalCards/ChangePermissions'
import { SearchBarRoles } from '../../components/atoms/searchBars/SearchBarRoles';
import { ColorsContext } from "../../Context/ColorsContext"  
import SecurityIcon from '@mui/icons-material/Security';

//icons
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import PeopleIcon from '@mui/icons-material/People';

import { 
    Container,
    SearchButton,
    ContainerBarSearch,
    ContainerButtonDownload,
    SectionPageLeft,
    SectionPageTitle,
    ContainersButtonsGroup,
 } from '../styles';

import {
  Spacer,
  BoxModal,
  ContainerButtonCreate,
} from './styles';

import { useTranslation } from 'react-i18next'
//?? Utils
import { FormatDate } from '../../utils/formatDate'
//?? Custom Hook
import { useDownloadExcel } from '../../Hooks/useDownloadExcel';
import { RelationRoles } from '../../components/atoms/modalCards/RelationRoles';

// Permissions
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../Context/PermissionsContext'

export const PermissionList = (props) => {
    const { t } = useTranslation();
    const { colors } = React.useContext(ColorsContext);
    const ability = useAbility(AbilityContext);

    const roleApi = new RoleApi();
    const permissionApi = new PermissionApi();

    const { format } = new FormatDate();

    const refData = React.useRef(false)
    const actionRef = React.useRef('CREATE')
    const roleIdRef = React.useRef(0)
    const [data, setData] = React.useState([]);
    const [filteredRequest, setFilteredRequest] = React.useState([])
    const [title, setTitle] = React.useState(t('system.permissions.role'))

    const [loading, setLoading] = React.useState(false);
    const [dialog, setDialog] = React.useState({
        title: '',
        message: '',
        status: false
    });

    const [openRoleForm, setOpenRoleForm] = React.useState(false)
    const [openListForm, setOpenListForm] = React.useState(false)
    const [openChangePermissionForm, setOpenChangePermissionForm] = React.useState(false)
    const [openRelateRoleForm, setOpenRelateRoleForm] = React.useState(false)

    const handleCloseModalList = () => setOpenListForm(false);
    const handleCloseModalChangePermission = () => setOpenChangePermissionForm(false);
    const handleCloseModalRelateRole = () => setOpenRelateRoleForm(false);

    const [form, setForm] = React.useState({
        id: 0,
        name: '',
        description: '',
        isActive: false,
        isExternal: false,
        isRestricted: false
    })

    const [errors, setErrors] = React.useState({
        name: { hasError: false, message: '' },
        description: { hasError: false, message: '' },
        general: { hasError: false, message: '' }
    })

    //?? prepare fields-table for download Excel
    const fieldConfig = {
        [t('system.permissions.table.id')]: 'id',
        [t('system.permissions.table.names')]: 'name',
        [t('system.permissions.table.description')]: 'description',
        [t('system.permissions.table.status')]: 'isEnabledStr',
        [t('system.permissions.table.createdDate')]: (item) => format({ date: item.createdAt, format: true }).toString(),
    };

    //?? Custom Hook Download Excel 
    const { handleDownloadExcelList } = useDownloadExcel(filteredRequest, fieldConfig, t('system.permissions.title'));

      // data SpeedDial for dimension mobile
  const addDataSpeedDial = (row) => {
    const options = [
      {
        name: 'permissions',
        tooltip: t("system.permissions.table.assignedPermissions"),
        onClick: () => selectedRole(row, "VIEW"),
        icon: <VisibilityIcon />,
      },
      {
        name: 'edit',
        tooltip: t("system.permissions.table.editRole"),
        onClick: () => selectedRole(row, "UPDATE"),
        icon: <EditIcon />,
        show: ability.can("UpdateRole"),
        disabled: !row.isActive
      },
      {
        name: 'deactivate',
        tooltip: t("system.permissions.table.inactivateRole"),
        onClick: () => selectedRole(row, "DEACTIVATE"),
        icon: <DeleteIcon />,
        show: ability.can("ToggleStateRole"),
        disabled: !row.isActive,
      },
      {
        name: 'activate',
        tooltip: t("system.permissions.table.activateRole"),
        onClick: () => selectedRole(row, "ACTIVATE"),
        icon:  <CheckIcon />,
        show: ability.can("ToggleStateRole"),
        disabled: !row.isActive ? false : true,
      },
      {
        name: 'assign',
        tooltip: t("system.permissions.table.assignPermissions"),
        onClick: () => selectedRole(row, "ASSIGN"),
        icon: <LockOpenIcon />,
        show: ability.can("PermissionsList") && ability.can("FindPermissionsByRole"),
        disabled: row.disabledDelete !== undefined
          ? row.disabledDelete
          : false,
      },
      {
        name: 'relate',
        tooltip: t("system.permissions.table.relatePermissions"),
        onClick: () => selectedRole(row, "RELATE"),
        icon: <PeopleIcon />,
        show: ability.can("GetRelationRoles"),
        disabled: row.disabledDelete !== undefined
          ? row.disabledDelete
          : false,
      }
    ];

    return options
  };

    const handleChange = (event, key) => {
        let e = event.target
        let value = e.type === 'checkbox' ? e.checked : e.value
        
        if (key === 'isExternal' && value) {
            setForm({ ...form, isExternal: true, isRestricted: true })
        }
        else {
            setForm({ ...form, [`${key}`]: value })
        }
    }

    const handleOpen = () => {
        actionRef.current = 'CREATE'
        resetForm()
        resetErrors()
        setOpenRoleForm(true)
    }
    
    const handleCloseModal = () => {
        resetErrors()
        setOpenRoleForm(false)
    }

    const handleToggle = () => {
        resetErrors()
        setLoading(true)
        const data = {
            ...form,
            isActive: !form.isActive
        }
        roleApi.toggleState(data.id, data).then((resp) => {
            if (resp?.status === 204) {
                resetForm()
                getRoles()
            } else {
                toast.error(() => 
                    <div>{t('system.permissions.messages.updatedData.error')}<br/>
                        {resp?.data?.description || resp?.data?.data?.description || resp?.response?.data?.description || resp?.response?.data?.data?.description || t('system.errorSaving')}
                    </div>
                );
            }
            setLoading(false)
        })
    }

    const handleEdit = () => {
        setOpenRoleForm(true)
    }

    const handleView = () => {
        setTitle(t('system.permissions.permissionsList'))
        setOpenListForm(true)
    }

    const handleAssign = () => {
        setOpenChangePermissionForm(true)
    }
    
    const handleRelate = () => {
        setOpenRelateRoleForm(true)
    }

    const validateForm = () => {
        let listErrors = { ...errors }
        let hasError = false
    
        if (form.name === '') {
          listErrors.name = { hasError: true, message: t('system.validations.mandatory') }
          hasError = true
        }
        if (form.description === '') {
          listErrors.description = { hasError: true, message: t('system.validations.mandatory') }
          hasError = true
        }
    
        setErrors(listErrors)
    
        return hasError
      }

    function handleCloseDialog(result) {
        setDialog({...dialog, status: false})

        if (result) {
            if (actionRef.current === 'CREATE' || actionRef.current === 'UPDATE') handleAccept()
            if (actionRef.current === 'DEACTIVATE' || actionRef.current === 'ACTIVATE') handleToggle()
        }
    }

    function handleChangePermissions(id, data) {
        permissionApi.updatePermissions(id, data).then((resp) => {
            setLoading(false)
            if (resp?.status === 204) {
                resetErrors()
                setOpenChangePermissionForm(false)
                setDialog({
                    title: t('system.permissions.messages.updatedData.title'), 
                    message: t('system.permissions.messages.updatedData.message'),
                    status: true
                })
            } else {
                toast.error(() => 
                    <div>{t('system.permissions.messages.updatedData.error')}<br/>
                        {resp?.data?.description || resp?.data?.data?.description || resp?.response?.data?.description || resp?.response?.data?.data?.description || t('system.errorSaving')}
                    </div>
                );
            }
        })
    }

    function handleChangeRelateRoles(id, data) {
        roleApi.updateRelation(id, data).then((resp) => {
            setLoading(false)
            if (resp?.status === 204) {
                resetErrors()
                setOpenRelateRoleForm(false)
                setDialog({
                    title: t('system.permissions.messages.updatedData.title'), 
                    message: t('system.permissions.messages.updatedData.message'),
                    status: true
                })
            } else {
                toast.error(() => 
                    <div>{t('system.permissions.messages.updatedData.error')}<br/>
                        {resp?.data?.description || resp?.data?.data?.description || resp?.response?.data?.description || resp?.response?.data?.data?.description || t('system.errorSaving')}
                    </div>
                );
            }
        })
    }

    const handleAccept = () => {
        if (!validateForm()) {
            setLoading(true)
            if (actionRef.current === 'CREATE') {
                roleApi.create(form).then((resp) => {
                    setLoading(false)
                    if(resp?.status === 200) {
                        getRoles()
                        resetForm()
                        handleCloseModal()
                        setDialog({
                            title: t('system.permissions.messages.saveData.title'), 
                            message: t('system.permissions.messages.saveData.message'),
                            status: true
                        })
                    } else {
                        toast.error(() => 
                            <div>{t('system.permissions.messages.saveData.error')}<br/>
                                {resp?.data?.description || resp?.data?.data?.description || resp?.response?.data?.description || resp?.response?.data?.data?.description || t('system.errorSaving')}
                            </div>
                        );
                    }
                })
            } else {
                roleApi.update(form.id, form).then((resp) => {
                    setLoading(false)
                    if (resp?.status === 204) {
                        getRoles()
                        resetForm()
                        handleCloseModal()
                        setDialog({
                            title: t('system.permissions.messages.updatedData.title'), 
                            message: t('system.permissions.messages.updatedData.message'),
                            status: true
                        })
                    } else {
                        toast.error(() => 
                            <div>{t('system.permissions.messages.updatedData.error')}<br/>
                                {resp?.data?.description || resp?.data?.data?.description || resp?.response?.data?.description || resp?.response?.data?.data?.description || t('system.errorSaving')}
                            </div>
                        );
                    }
                })
            }
        }
    }

    const resetForm = () => {
        setForm({
            id: 0,
            name: '',
            description: '',
            isActive: false,
            isExternal: false,
            isRestricted: false
        })
    }

    const resetErrors = () => {
        setErrors({
            name: { hasError: false, message: '' },
            description: { hasError: false, message: '' },
            general: { hasError: false, message: '' }
        })
    }

    const selectedRole = (item, type) => {
        actionRef.current = type
        roleIdRef.current = item.id
        setForm({
            ...form,
            id: item.id,
            name: item.name,
            description: item.description,
            isActive: item.isActive,
            isExternal: item.isExternal || false,
            isRestricted: item.isRestricted || false,
        })

        if (actionRef.current === 'UPDATE') handleEdit()
        if (actionRef.current === 'DEACTIVATE' || actionRef.current === 'ACTIVATE') handleToggleRole(actionRef.current, item.name)
        if (actionRef.current === 'VIEW') handleView()
        if (actionRef.current === 'ASSIGN') handleAssign()
        if (actionRef.current === 'RELATE') handleRelate()
    }

    function handleToggleRole(state, roleName) {
        setDialog({
            status: true,
            title: t('system.permissions.role'),
            message: t('system.permissions.confirmChangeRole', { state: state === 'DEACTIVATE' ? 'system.permissions.disable' : 'system.permissions.enable', role: roleName}),
            type: 'confirmation'
        })
    }

    const getRoles = useCallback(async() => {
        let response = await roleApi.list();
        if (response.status === 200) {
            let resp = response.data.data;
            setFilteredRequest(resp);
            setData(resp)
        }
    }, [roleApi])

    useEffect(() => {
        if (refData.current === false) {
          getRoles()
          refData.current = true
        }
    }, [getRoles])

    return (
        <>
            <Container open={props.open}>
                <BackdropLoading open={loading}/>
                <ShowDialog openDialog={dialog.status} dialog={dialog} handleCloseDialog={handleCloseDialog}/>

                <SectionPageTitle id="SectionPage">
                    <SectionPageLeft>
                        <PageTitle title={t('system.permissions.title')} icon={<SecurityIcon />} />
                        <ContainerBarSearch>
                            <SearchBarRoles rows={data} setFilteredRequest={setFilteredRequest} />
                        </ContainerBarSearch>
                    </SectionPageLeft>
                    
                    <ContainersButtonsGroup twoContainers={true} >
                        <ContainerButtonDownload id="ContainerDownload">
                            <Button
                                variant="contained"
                                endIcon={<CloudDownloadOutlined />}
                                sx={{ 
                                    height: '100%', width:'100%',  padding: '0 20px', marginRight: '20px', 
                                    backgroundColor: colors.buttonActionColor,
                                    color: colors.white,
                                    "&:hover": {
                                        backgroundColor: colors.buttonActionColor,
                                        opacity: 0.9,
                                        color: colors.white
                                    }
                                }}
                                aria-label="download"
                                onClick={handleDownloadExcelList}
                                >
                                {t('common.download')}
                            </Button>
                        </ContainerButtonDownload>

                        {ability.can("CreateRole") &&
                            <ContainerButtonCreate id="ContainerButtonCreate">
                                <SearchButton
                                    variant="contained"
                                    endIcon={<AddCircleIcon/>}
                                    sx={{ height: '100%', width: '100%' , padding: '0 20px' }}
                                    aria-label="add-user"
                                    onClick={handleOpen}
                                    permissions={true}
                                >
                                    {t('system.permissions.createRol')}
                                </SearchButton>
                            </ContainerButtonCreate>
                        }
                    </ContainersButtonsGroup>
                </SectionPageTitle>

                <TableRoles
                    filteredRequest={filteredRequest}
                    rows={data}
                    selectedRole={selectedRole}
                    addDataSpeedDial={addDataSpeedDial}
                />
            </Container>
            <Modal open={openRoleForm}>
                <BoxModal>
                    <RoleModalCard 
                        title={actionRef.current === 'CREATE' ? t('system.permissions.roleCreation') : t('system.permissions.roleUpdate')}
                        loading={loading}
                        data={form}
                        errors={errors}
                        handleClose={handleCloseModal}
                        handleChange={handleChange}
                        handleAccept={handleAccept}
                    />
                </BoxModal>
            </Modal>
            <Modal open={openListForm}>
                <BoxModal>
                    <ListPermissions
                        roleId={roleIdRef.current}
                        title={title}
                        handleClose={handleCloseModalList}
                    />
                </BoxModal>
            </Modal>
            <Modal open={openChangePermissionForm}>
                <BoxModal>
                    <ChangePermissions
                        roleId={roleIdRef.current}
                        title={`${t('system.permissions.editRolePermissions')} ${form.name.toUpperCase()}`}
                        loading={loading}
                        handleClose={handleCloseModalChangePermission}
                        handleChangePermissions={handleChangePermissions}
                    />
                </BoxModal>
            </Modal>
            <Modal open={openRelateRoleForm}>
                <BoxModal>
                    <RelationRoles
                        roleId={roleIdRef.current}
                        title={`${t('system.permissions.editRelationRole')} ${form.name.toUpperCase()}`}
                        loading={loading}
                        handleClose={handleCloseModalRelateRole}
                        handleChangeRelateRoles={handleChangeRelateRoles}
                    />
                </BoxModal>
            </Modal>
        </>
    )
}