import { CssBaseline, ThemeProvider } from "@mui/material";
import React from "react";
import Footer from "../../layouts/Footer";
import { LayoutContainer } from "../../layouts/Layout/styles";
import Navbar from "../../layouts/Navbar";
import { theme } from "../Global/style";
import {DrawerHeader, Container, Content} from './styles'

export const PublicLayout = ({children}) => {
    const [open, setOpen] = React.useState(true)
    const [userEmail, setUserEmail] = React.useState(null)

    const handleOpen = () => {
        setOpen(!open)
    };

    const externalUserData = (data) => {
        if (data) setUserEmail(data)
    }

    return (
        <LayoutContainer isOpen={open}>
            <ThemeProvider theme={theme}>
                <Content container>
                    <CssBaseline/>
                    <Navbar handleOpen={handleOpen} isExternal={true} userEmail={userEmail}/>
                    <Container isOpen={open} component="main">
                        <DrawerHeader/>
                        {React.Children.map(children, child => {
                            return React.cloneElement(child, { externalUserData })
                        })}
                    </Container>
                    <Footer open={open}/>
                </Content>
            </ThemeProvider>
        </LayoutContainer>
    )
}
