import React, { useState, Fragment, useEffect } from 'react';
import moment from 'moment'
// Components
import { Grid, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

// Custom components
import { TableToolbar } from './TableToolbar';
import { TableHeader } from './TableHeader';

// Components
import { LoadingProgress } from '../../../LoadingProgress';

// Styles
import {
	Cell,
	CustomIconButton,
	CustomLink,
	IconArea,
	TableContent,
	ContainerStatus,
	ContentCell,
	ContainerGroup,
	CustomCell,
	CustomTableContainer,
	CustomTablePagination
} from './styles'

// API

// Router
import { useParams } from 'react-router-dom';

// Icons
import { ContentCopy, Delete, Edit, SlowMotionVideo, Visibility } from '@mui/icons-material';

// Servicess


//Utils
import { useColors } from "../../../../Context/ColorsContext"
import { FormatDate } from '../../../../utils/formatDate';
import { getIcon } from '../../../../utils/iconConfig';
import { useTranslation } from 'react-i18next';

function descendingComparator(a, b, orderBy, customSort) {
	let cond1 = b[orderBy] < a[orderBy]
	let cond2 = b[orderBy] > a[orderBy]

	if (customSort) {
		cond1 = eval(`b.${customSort}`) < eval(`a.${customSort}`)
		cond2 = eval(`b.${customSort}`) > eval(`a.${customSort}`)
		if (eval(`a.${customSort}`) === null) return 1
		if (eval(`b.${customSort}`) === null) return -1
	}

	if (cond1) return -1;
	if (cond2) return 1;
	return 0;
}

function getComparator(order, orderBy, customSort) {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy, customSort)
		: (a, b) => -descendingComparator(a, b, orderBy, customSort);
}

function stableSort(array, comparator, preventOrderBy) {
	if (preventOrderBy) return array;
	const stabilizedThis = array?.map((el, index) => [el, index]);
	stabilizedThis?.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis?.map((el) => el[0]);
}

export default function TableModelScroll({
	headers,
	filteredRequest,
	initialDate,
	setInitialDate,
	setFinalDate,
	finalDate,
	handleDatesFilter,
	handleClearDate,
	handleCreateNew,
	labelCreateNew,
	handleDetail,
	handleEdit,
	handleProcess,
	handleDuplicate,
	handleDelete,
	hideFilterDate,
	hideToolbar,
	preventOrderBy,
	customAdd,
	elementAdd,
	custom,
	defaultSort,
	defaultRowsPerPage,
	rows,
	selectedTags,

	setFilteredRequest,
	listWorkFlows,

	filterStatus,
	standardSelects,
	listAllworkflows,

	setStandardSelects,
	setListAllWorkflows,

	loadWorkflowsFilters,

	loadingData,
	validityTooltip
}) {

	const { format } = new FormatDate();
	const params = useParams();
	const { t } = useTranslation();

	const { colors } = useColors();


	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('id');
	const [customSort, setCustomSort] = React.useState("");
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage || 10);

	React.useEffect(() => {
		if (defaultSort) {
			handleRequestSort(null, defaultSort, null, 'desc')
		}
	}, [defaultSort]);

	const handleRequestSort = (event, property, property2, defaultOrder) => {
		const isAsc = orderBy === property && order === "asc";
		if (defaultOrder) {
			setOrder(defaultOrder);
		}
		else {
			setOrder(isAsc ? "desc" : "asc");
		}
		setOrderBy(property);
		setCustomSort(property2);
	};

	const handleChangePage = (_, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const getInstanceTime = (dataTime, type = 'activeTime') => {
		if (dataTime !== null) {

			let tempTime = moment.duration(dataTime.activeTime);

			if (type !== 'activeTime') tempTime = moment.duration(dataTime.suspendedTime);

			const hoursVal = tempTime.hours()
			const minutesVal = tempTime.minutes()
			const secondsVal = tempTime.seconds()

			let textTime = hoursVal > 0 ? `${hoursVal} h` : ''
			textTime += ' ' + (minutesVal > 0 ? `${minutesVal} min` : `${secondsVal} seg`)

			return (<span>{textTime}</span>)
		}

		return ''
	}

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

	return (
		<div sx={{ width: '100%' }}>
			<Paper sx={{ width: '100%', mb: 2 }}>
				{!hideToolbar &&
					<TableToolbar
						filterStatus={filterStatus}
						standardSelects={standardSelects}
						listAllworkflows={listAllworkflows}

						setStandardSelects={setStandardSelects}
						setListAllWorkflows={setListAllWorkflows}

						loadWorkflowsFilters={loadWorkflowsFilters}

						selectedTags={selectedTags}

						setFilteredRequest={setFilteredRequest}
						rows={rows}
						initialDate={initialDate}
						finalDate={finalDate}
						setInitialDate={setInitialDate}
						setFinalDate={setFinalDate}
						handleDatesFilter={handleDatesFilter}
						handleClearDate={handleClearDate}
						handleCreateNew={handleCreateNew}
						title={labelCreateNew}
						hideFilterDate={hideFilterDate}
						customAdd={customAdd}
						elementAdd={elementAdd}
						listWorkFlows={listWorkFlows}
					/>
				}

				<Box sx={{ width: '100%', overflowX: 'auto' }}>
					<CustomTableContainer>
						<Table
							sx={{ minWidth: 750 }}
							aria-labelledby="tableTitle"
							size="small"
						>
							<TableHeader
								order={order}
								orderBy={orderBy}
								onRequestSort={handleRequestSort}
								headers={headers}
							/>
							{loadingData &&
								<TableRow style={{ height: 33 * emptyRows, margin: '0 auto' }}>
									<TableCell colSpan={12}>
										<LoadingProgress />
									</TableCell>
								</TableRow>
							}
							{!loadingData && <TableContent>
								{stableSort(filteredRequest, getComparator(order, orderBy, customSort), preventOrderBy)
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((row, index) => {

										return (
											<TableRow key={row.id} hover role="checkbox" tabIndex={-1}>
												{headers.map((value) => (
													<Cell key={"head-" + value.id}>
														{value.isRender ?
															<>
																{value.id2 ?
																	<ContentCell>{row[value.id2][value.id]}</ContentCell>
																	:
																	<ContentCell>{row[value.id]}</ContentCell>
																}
															</>
															:
															<Fragment>
																{(value.type === 'activeTime' || value.type === 'suspendedTime') &&
																	<Fragment>{getInstanceTime(row?.instanceTime, value.type)}</Fragment>
																}
																{value.type === 'date' &&
																	<Fragment>{format({ date: row[value.id], format: true })}</Fragment>
																}
																{value.type === 'shortDate' &&
																	<Fragment>{format({ date: row[value.id], format: true })}</Fragment>
																}
																{value.type === 'object-icon' &&
																	<Grid container alignItems='center'>
																		{value.keys?.map((key) => (
																			<Grid key={'object-' + key} item xs={key === 'icon' ? 2 : 6}>
																				<ContainerGroup>
																					<div>
																						{row.element !== '' && <IconArea>{row.element}</IconArea>}
																					</div>
																					<div>
																						{row[value.id]?.[key]}
																					</div>
																				</ContainerGroup>
																			</Grid>
																		))}
																	</Grid>
																}
																{value.type === 'object' &&
																	<CustomCell>
																		{value.keys?.map((key) => (
																			<div
																				key={'object-' + key} item xs={key === 'icon' ? 2 : 6}>
																				{key === 'icon'
																					? getIcon(row[value.id])
																					: row[value.id]?.[key]
																				}
																			</div>
																		))}
																	</CustomCell>
																}
																{value.type === 'detail' &&
																	<Tooltip title={t('common.actions.detail')}>
																		<CustomIconButton size="small" onClick={() => handleDetail(row)}>
																			<Visibility />
																		</CustomIconButton>
																	</Tooltip>
																}
																{value.type === 'custom' &&
																	<ContainerStatus>{custom(row?.workflowStatus)}</ContainerStatus>
																}
																{value.type === 'link' &&
																	<CustomLink onClick={() => value.onClick(row)}>{row.linkName || row.code}</CustomLink>
																}
																{value.type === 'iconButton' &&
																	<CustomIconButton onClick={() => value.onClick(row)}>
																		{value.icon}
																	</CustomIconButton>
																}
																{value.type === 'validity' &&
																	<Tooltip title={validityTooltip(row[value.id], row[value.id2])}>
																		<div>{format({ date: row[value.id], formatWithoutHour: true })}</div>
																	</Tooltip>
																}
																{value.type === 'actions' &&
																	<Grid container alignItems="center" spacing={2}>
																		{value.keys?.map((key) => (
																			<Grid key={'actions-' + key} item>
																				{key === 'edit' &&
																					<Tooltip title={t('common.actions.edit')}>
																						<CustomIconButton size="small" onClick={() => handleEdit(row)}>
																							<Edit />
																						</CustomIconButton>
																					</Tooltip>
																				}
																				{key === 'process' &&
																					<Tooltip title={t('common.actions.process')}>
																						<CustomIconButton size="small" onClick={() => handleProcess(row)}>
																							<SlowMotionVideo />
																						</CustomIconButton>
																					</Tooltip>
																				}
																				{key === 'duplicate' &&
																					<Tooltip title={t('common.actions.duplicate')}>
																						<CustomIconButton size="small" onClick={() => handleDuplicate(row)}>
																							<ContentCopy />
																						</CustomIconButton>
																					</Tooltip>
																				}
																				{key === 'delete' &&
																					<Tooltip title={t('common.actions.cancel')}>
																						<span>
																							<CustomIconButton size="small" onClick={() => handleDelete(row)}
																								disabled={row.disabledDelete !== undefined ? row.disabledDelete : false}
																							>
																								<Delete />
																							</CustomIconButton>
																						</span>
																					</Tooltip>
																				}
																			</Grid>
																		))}
																	</Grid>
																}
															</Fragment>
														}
													</Cell>
												))}
											</TableRow>
										);
									})}
								{emptyRows > 0 && (
									<TableRow style={{ height: 33 * emptyRows }}>
										<TableCell colSpan={6} />
									</TableRow>
								)}
							</TableContent>}
						</Table>
					</CustomTableContainer>

					<CustomTablePagination
						rowsPerPageOptions={[5, 10, 25]}
						component="div"
						count={rows.length}
						rowsPerPage={rowsPerPage}
						page={page}
						showFirstButton
						showLastButton
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						labelRowsPerPage={t('common.itemsPerPage')}
						labelDisplayedRows={
							({ from, to, count }) => { return '' + from + '-' + to + ` ${t('common.of')} ` + count }
						}
					/>
				</Box>
			</Paper>
		</div>
	);
}

