import React, { useEffect, useState } from 'react';

// Components
import { CharacteristicsForm } from '../molecules/forms/CharacteristicsForm';
import { Grid } from '@mui/material';
import moment from 'moment';
import { BackdropLoading } from '../BackdropLoading';
import { FormIo } from '../molecules/forms/FormIo';

import { Container } from './Style';
// Router
import { PlotPlanApi } from '../../services/PlotPlanApi';

// Styles
import 'semantic-ui-css/semantic.min.css'
import 'react-toastify/dist/ReactToastify.css';

import './Style.css';

// Utils
import { useTranslation } from 'react-i18next';

export const FormViewer = (props) => {
  const { t } = useTranslation();
  const plotPlanApi = new PlotPlanApi();
  const [data, setData] = useState({});
  const [characteristics, setCharacteristics] = useState({
    plotPlans: []
  });
  const [plotPlans, setPlotPlans] = useState([]);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [hideFiles, setHideFiles] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true)

    setHideFiles(props.hideFiles || false)
    if (props.isDetail) {
      if (props.isEditable) setIsReadOnly(false)
      
      let charac = props.characteristics;
      if (Object.keys(charac || {})?.length > 0) {
        if (charac?.validityFrom) charac.validityFrom = moment(charac.validityFrom).format("YYYY-MM-DD")
        if (charac?.validityTo) charac.validityTo = moment(charac.validityTo).format("YYYY-MM-DD")
        
        if ((charac?.hasPlotPlan) && (charac?.plotPlans?.length > 0 || charac?.plotPlan?.id)) {
          async function call() {
            let plotPlans = charac.plotPlan?.id ? [charac.plotPlan] : charac.plotPlans
            await getPlotPlans(charac, plotPlans);
          }
          setLoading(false)
          call()
        }
        else {
          setCharacteristics(charac)
        }
      }
			setLoading(false)
    }
		else {
			setLoading(false)
		}
  }, [])

  async function getPlotPlans(charac, arrayPlotPlans) {
    let response = await plotPlanApi.list();

    if (response.status === 200) {
      setPlotPlans(response.data)

      let selectedPlans = arrayPlotPlans?.map((obj) => obj.id)
      let plans = response.data.filter((el) => selectedPlans.includes(el.id))

      // If the plot plan doesn't exist in the plot plans associated to the plant then assign the plot plans of the workflow data
      if ((selectedPlans?.length !== plans?.length) && props.formData?.plotPlans?.length > 0) {
        plans = props.formData.plotPlans
      } 

      // If the plot plans exist
      if (plans?.length > 0) {
        setCharacteristics({...charac, 'plotPlans': plans})
      }
    }
  }

  return (
    <>
      <Container>
        {/* <Grid item xs={12}>
          {!loading &&
            <CharacteristicsForm
              form={characteristics}
              plotPlans={plotPlans}
              disabled={true}
              hideFiles={hideFiles}
            />
          }
        </Grid> */}
        <Grid item xs={12}>
					{!loading ?
						<div ref={props.formRef}>
							<FormIo 
								form={props.form}
								data={props.data}
								setData={setData}
								readOnly={isReadOnly}
                formInstance={props.currentFormRef}
                flag={true}
							/>
						</div>
						:
						null
					}
        </Grid>

				<BackdropLoading open={loading}/>
      </Container>
    </>
  )
}
