import React from 'react';
import { CardMedia, Grid, Typography } from '@mui/material';
import { 
    GridCenter, 
    Content, 
    GpsIcon, 
    IconArea, 
    CardContainer,
    Title,
    ContainerGroup ,
    ContainerGroupRight,
    ContainerGroupLeft,
    CustomTyphography,
    CustomImageNotSupportedIcon,
    ContentCard,
    CustomCardMedia,
    CustomLabelCardMedia
 } from './styles';
import Router from '../../router';

import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import { ColorsContext } from "../../Context/ColorsContext"
import { useTranslation } from 'react-i18next'

export const CardContent = ({data, onSelect}) => {
    const { t } = useTranslation();
    const { colors } = React.useContext(ColorsContext);

    return (
        <>
            {data.map((row, index) => (
                <CardContainer key={`image-${index}`} onClick={() => onSelect(row)}>
                    <ContentCard>
                        <CustomLabelCardMedia>
                            <span><strong>ID:</strong>{row.id}</span>
                        </CustomLabelCardMedia>
                        {row.imgSource 
                            ?
                                <CustomCardMedia
                                    component="img"
                                    image={`${Router.apiBaseUrl}/${row.imgSource}`}
                                    alt={row.name}
                                />
                            :
                                <GridCenter container direction="column">
                                    <CustomImageNotSupportedIcon/>
                                    <Title>{t('common.empty.noImage')}</Title>
                                </GridCenter>
                        }
                    </ContentCard>
                    <Content selected={row.selected}>
                        <ContainerGroup>
                            <ContainerGroupLeft>
                                    {row.element &&
                                        <div>
                                            <IconArea>{row.element}</IconArea>
                                        </div>
                                    }
                                    <div>
                                        <CustomTyphography>{row.name}</CustomTyphography>
                                    </div>
                            </ContainerGroupLeft>

                            <ContainerGroupRight>
                                <div>
                                    <GpsIcon/>
                                </div>
                                <div>
                                    <CustomTyphography>{row.childNodesCount || 0}</CustomTyphography>
                                </div>
                            </ContainerGroupRight>
                        </ContainerGroup>
                    </Content>
                </CardContainer>
            ))}            
        </>
    )
}

