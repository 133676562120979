import { IconButton, Typography } from "@mui/material";
import { styled } from "@mui/material/styles"
import { useColors } from "../../../../Context/ColorsContext";
import { MediaQuerys } from "../../../../styles/GlobalStyle";

const { tablet, desktop } = MediaQuerys;

export const CustomIconButton = styled(IconButton)(() => {
  const { colors } = useColors();
  return {
    borderRadius: 0,
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
    height: '100%',
    margin: 0,
    pointerEvents: 'pointer',
    background: colors.buttonActionColor,
    color: colors.white,
    "&:hover": {
      backgroundColor: colors.buttonActionColor,
      opacity: 0.9,
      color: colors.white,
    }
  }
})

export const ButtonText = styled(Typography)(() => {
  return {
    fontSize: '0.875rem',
    [desktop]: {
      fontSize: "0.65rem",
    },
    [tablet]: {
      fontSize: "7px",
    },
  }
})