import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from '@mui/material/Modal';

import { SecurityApi } from '../../../../services/SecurityApi';
import { PermissionApi } from '../../../../services/PermissionApi';

// Components
import { LoginForm } from '.'
import { ChangePassword } from '../../../atoms/modalCards/ChangePassword'

// Utils
import { Auth } from '../../../../utils/auth';
import Router from '../../../../router';
import { useTranslation } from 'react-i18next'

import { BoxModal, } from './styles';
import defineAbility from '../../../../config/defineAbility';

export const LoginFormContainer = () => {
	const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { authenticate, saveProfile } = new Auth();
  const securityApi = new SecurityApi();
  const permissionApi = new PermissionApi();

  const recaptchaRef = React.useRef();

  const [form, setForm] = React.useState({ username: '', password: '', reCaptchaToken: null })
  const [openChangePasswordForm, setOpenChangePasswordForm] = React.useState(false)
  const [messageError, setMessageError] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false)

  const handleChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value })
  }

  const handleSubmit = async() => {
    setMessageError('')
    setIsLoading(true)
    securityApi.login(form).then(async(resp) => {
      setIsLoading(false)
      if (resp?.status === 200) {
        let token = resp.data.data.accessToken
        let profile = resp.data.data.profile
        let strings = resp.data.data.strings
        authenticate(token)
        saveProfile(profile, strings)

        await callPermissionsRole(resp.data.data.profile.Role.Id);

        if (location?.state?.redirect) {
            navigate(location?.state?.redirect, {state: location?.state?.data})
            return
        }
        navigate(Router.appMyWork)
      } else if (resp?.response?.status === 400) {
        setMessageError(resp?.response.data.description || resp?.response.data.data.description || '')
        if (resp?.response.data.code === 'expired' || resp?.response.data.data.code === 'expired') {
          setMessageError(t('password.validations.updatePassword'))
          setForm({ ...form, password: '' })
          setOpenChangePasswordForm(true)
        }
        resetCaptcha()
      } else {
        setMessageError(t('password.validations.invalidCredentials'))
        resetCaptcha()
      }
    })
  }

  async function callPermissionsRole(id) {
    const response = await permissionApi.list(id);
    if (response?.status === 200) {
      let resp = response.data.data
      let permissions = []
      permissions = resp?.map((el) => {return {action: el.name}})
      permissions.push({action: "All"})
      localStorage.setItem("permissions", JSON.stringify(permissions))
      defineAbility.update(permissions)
    }
  }

  const handleCloseChangePassword = () => {
		setOpenChangePasswordForm(false)
    setMessageError('')
	}

  const resetCaptcha = () => {
    recaptchaRef.current?.reset();
    setForm({ ...form, reCaptchaToken: null })
  }

  return (
    <>
      <LoginForm
        form={form}
        setForm={setForm}
        messageError={messageError}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        isLoading={isLoading}
        recaptchaRef={recaptchaRef}
        setMessageError={setMessageError}
      />
      <Modal open={openChangePasswordForm}>
				<BoxModal>
					<ChangePassword 
						username={form.username}
						handleClose={handleCloseChangePassword}
					/>
				</BoxModal>
			</Modal>
    </>
  )
}
