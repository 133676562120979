import React, { useCallback, useEffect, useState } from 'react';

// Components
import { toast } from 'react-toastify';

// Custom components
import { ContractScope } from '../../components/molecules/forms/ContractScope';

// API
import { ContractApi } from '../../services/ContractApi';

// Styles

// Utils
import { useTranslation } from 'react-i18next';
import { CheckResponse } from '../../utils/checkResponse';
import { Grid } from '@mui/material';
import { Auth } from '../../utils/auth';

export const ContractScopeContainer = ({ 
    form, setForm, companyContracts, errors, handleChangeStepTwo, disabled, isSupervisor
}) => {

    const contractApi = new ContractApi();
    const { check } = new CheckResponse();
    const { getUser } = new Auth();
    const { t } = useTranslation();

    const user = React.useRef({});
    const [plantList, setPlantList] = useState([])
    const [contractList, setContractList] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        async function call() {
            setLoading(true)
            user.current = getUser();
            await Promise.all(
                form.contractPlantsAssigned.map(async(el) => {
                    if (isSupervisor()) {
                        let contract = await getDisabledPlants(el.contract)
                        el.plants = contract.plants
                    }
                })
            )
            await getContracts()
            setLoading(false)
        }
        call()
    }, [companyContracts])

    async function getContracts() {
        let data = []
        if (companyContracts !== undefined) {
            data = companyContracts
        }
        else {
            let response = await contractApi.list(1)
            let result = check(response)
    
            if (result.status) {
                data = response.data
            }
            else {
                toast.error(() => <>{result.errors}</>)
            }
        }
        
        let array = [];

        if (form.contractPlantsAssigned[0]?.contract?.id) {
            array = form.contractPlantsAssigned
        }
        else {
            array = [...emptyRow()]
        }

        setForm({...form, contractPlantsAssigned: array})
        checkAvailableContracts(array)
        setPlantList(data.plants)
        setContractList(data)
    }

    const emptyRow = () => {
		return [
			{
                contract: {},
                plants: []
			}
		]
	}

    function handleAddRow() {
		let array = form.contractPlantsAssigned
        array = checkItems(array)

        const hasEmptyPlants = array.some(item => item.hasEmptyPlants);
        const hasEmptyContract = array.some(item => item.hasEmptyContract);

		if (!hasEmptyPlants && !hasEmptyContract) {
            array.push(...emptyRow())
        }

        setForm({...form, contractPlantsAssigned: array})
	}

    function handleRemoveRow(index) {
		let array = form.contractPlantsAssigned
		array.splice(index, 1)

		if (array.length === 0) {
			array = emptyRow()
		}

        setForm({...form, contractPlantsAssigned: array})
		checkAvailableContracts(array)
	}

    async function handleChange(name, value, idx) {
        if (name === 'contract' && isSupervisor()) {
            value = await getDisabledPlants(value)
        }
        let array = await handleChangeStepTwo(value, name, idx)
        checkAvailableContracts(array)
    }

    async function getDisabledPlants(value) {
        let response = await loadTechnicalSupervisorContracts(value.id);
        let supervisorPlants = response?.technicalSupervisors?.find((el) => el.email === user.current.email)?.plants || []
        if (value.plants?.length > 0) {
            value.plants.map((el) => {
                el.disabled = !supervisorPlants.some((plant) => plant.id === el.id)
            })
        }
        return value
    }

    const loadTechnicalSupervisorContracts = useCallback(async (id) => {   
        let response = await contractApi.getSupervisorData(id);
        if (response.status === 200) {
            return response.data;
        }
        return []
    }, [contractApi]);

    function checkAvailableContracts(items) {
		let contracts = [...contractList]
        let arrayIds = []
        arrayIds = items?.map((row) => row.contract?.id)

        contracts.map((obj) => {
            if (arrayIds.includes(obj.id)) obj.isDisabled = true
            else obj.isDisabled = false
        })

        setContractList(contracts)
	}

    function checkItems(array) {
        return array?.map(item => ({
            ...item,
            hasEmptyPlants: item.selectedPlants?.length === 0,
            errorEmptyPlants: item.selectedPlants?.length === 0 ? t("system.validations.mandatory") : '',
            hasEmptyContract: !item.contract?.id,
            errorEmptyContract: !item.contract?.id ? t("system.validations.mandatory") : ''
        }))
    }

    return (
        <Grid container spacing={0}>
            <Grid item xs={12}>
                <ContractScope
                    form={form}
                    data={form.contractPlantsAssigned}
                    errors={errors}
                    handleChange={handleChange}
                    contractList={contractList}
                    handleAddRow={handleAddRow}
                    handleRemoveRow={handleRemoveRow}
                    disabled={disabled || loading}
                />
            </Grid>
        </Grid>
    )
}