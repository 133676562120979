import { styled } from "@mui/material/styles";
import { Button, Card, CardContent, CircularProgress, Grid, IconButton, Typography, Tooltip } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useColors } from "../../Context/ColorsContext";
import { MediaQuerys } from "../../styles/GlobalStyle";

const { mobile, tablet, desktop } = MediaQuerys;

export const Content = styled(CardContent)(() => ({
    padding: '10px !important'
}))


export const CardDetail = styled(Card)(() => ({
    width: '100%',
    height: '350px',
    flex: 1,
    overflowY: 'auto',
    marginBottom: '10px',
    [mobile]: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'column',
        height: 'fit-content',
        padding: '10px'     
    }
}))

export const Loading = styled(CircularProgress)(() => ({
    margin: '0 auto',
    position: 'absolute'
}))

export const Title = styled(Typography)(({ isGraphic }) => ({
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: isGraphic && '1rem !important',
    [desktop]: {
        fontSize: '0.85rem'
    },
    [mobile]: {
        marginTop: isGraphic && '1rem',
    }
}))

export const CustomIconButton = styled(IconButton)(() => {
    const { colors } = useColors();
    return {
        width: '100%',
        height: '100%',
        borderRadius: '10%',
        backgroundColor: colors.background,
        color: colors.buttonActionColor,
        "&:hover": {
            backgroundColor: colors.buttonActionColor,
            opacity: 0.9,
            color: colors.background,
        },
        ":disabled": {
            backgroundColor: colors.gray,
            color: colors.background,
        }
    }
})

export const CustomButton = styled(Button)(({ isDownload, isMobile }) => {
    const { colors } = useColors();
    return {
        background: colors.buttonActionColor,
        color: colors.white,
        textTransform: 'capitalize',
        height: '100%',
        width: '100%',
        fontSize: 13,
        paddingTop: isDownload && '5px',
        paddingBottom: isDownload && '5px',
        "&:hover": {
            backgroundColor: colors.buttonActionColor,
            opacity: 0.9,
            color: colors.white,
        },
        [desktop]: {
            height: isDownload && '30px',
            fontSize: '0.75rem',
        },
        [mobile]: {
            display: isDownload ? 'none' : (isMobile ? 'flex' : null),
        }
    }
})

export const ButtonContainer = styled(Grid)(() => ({
    direction: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 10
}))


export const ContainerTitle = styled('div')(() => {
    return {
        width: '100%',
        height: 'auto',
        display: 'flex',
        justifyContent: 'flex-start',
    }
})

export const ContainerContent = styled('div')(() => {
    return {
        display: 'flex',
        flexDirection: 'row',
        gap: '0.50rem',
        alignItems: 'center',
        marginTop: '5px',
        marginBottom: '10px',
        width: '100%',
        [mobile]: {
            width: '60%',
            marginTop: '0px',
            marginBottom: '0px'
        }
    }
})

export const ContainerButtoms = styled('div')(({ isCreate, isEditAndDelete }) => {
    return {
        height: isCreate ? 34 : (isEditAndDelete && 30),
        width: isCreate ? '70%' : (isEditAndDelete ? '50%' : '0%'),
        [mobile]: {
            display: isCreate && 'none',
            width: isEditAndDelete && '50%'
        }
    }
})

export const ContainerGroup = styled('div')(({ isMiddle }) => {
    return {
        display: isMiddle ? 'none' : 'flex',
        flexDirection: 'row',
        width: '30%',
        [mobile]: {
            display: 'flex',
            width: isMiddle ? '65%' : '30%',
            gap: isMiddle ? '0.50rem' : '0.30rem',
            marginleft: isMiddle ? '0px' : '5px',
        }
    }
})

export const ContainerGroupBtns = styled('div')(() => {
    return {
        display: 'flex',
        flexDirection: 'column',
        gap: '0.50rem',
        alignItems: 'center',
        marginTop: '5px',
        marginBottom: '10px',
        width: '100%',
        [mobile]: {
            gap: '0.35rem',
            marginTop: '10px',
            marginBottom: '0px',
            flexDirection: 'row-reverse',

        }
    }
})

export const ContainerPage = styled('div')(() => {
    return {
        display: 'flex',
        flexDirection: 'row',
        [mobile]: {
            flexDirection: 'column',
        }
    }
})

export const ContainerLeft = styled('div')(({ height }) => {
    return {
        display: 'flex',
        height: '1006px',
        flexDirection: 'column',
        width: '20%',
        padding: '5px',
        [tablet]: {
            width: '25%',
            height: '870px'
        },
        [mobile]: {
            width: '100%',
            height: 'auto',
        }
    }
})

export const ContainerMid = styled('div')(() => {
    return {
        display: 'flex',
        width: '60%',
        padding: '5px',
        [tablet]: {
            width: '50%',
        },
        [mobile]: {
            width: '100%',
        }
    }
})

export const ContainerGroupMid = styled('div')(() => {
    return {
        display: 'flex',
        gap: '1rem',
        flexDirection: 'column',
        width: '100%',

    }
})

export const ContainerRight = styled('div')(() => {
    return {
        display: 'flex',
        flexDirection: 'column',
        width: '20%',
        padding: '5px',
        [tablet]: {
            width: '25%'
        },
        [mobile]: {
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
        }
    }
})

export const ContainerGroupLeft = styled('div')(() => {
    return {
        display: 'flex',
        flexDirection: 'column',
    }
})

export const ContainerTop = styled('div')(() => {
    return {
        width: '100%',
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            width: 5,
            height: 5
        },
        [mobile]: {
            display: 'none',
        }
    }
})

export const ContainerBottom = styled('div')(() => {
    return {
        width: '100%',
        [mobile]: {
            display: 'none',
        }
    }
})

export const IconAction = styled(IconButton)(() => {
    const { colors } = useColors();
    return {
        display: 'none',
        borderRadius: 20,
        pointerEvents: "pointer",
        background: colors.buttonActionColor,
        "&:hover": {
            backgroundColor: colors.buttonActionColor,
            opacity: 0.9,
            color: colors.white,
        },
        [desktop]: {
            fontSize: "0.25rem",
            width: '28px',
            height: '28px',
            "&MuiSvgIcon-root": {
                width: '16px'
            }
        },
        [tablet]: {
            height: 28,
            width: 28,
        },
        [mobile]: {
            display: "flex",
        },
    };
});

export const NewIcon = styled(AddCircleIcon)(() => {
    const { colors } = useColors();
    return {
        height: '20px',
        width: '20px',
        color: colors.white,
        [desktop]: {
            height: '16px',
            width: '16px'
        }
    };
});

export const ContainerDownload = styled('div')(() => {
    return {
        display: 'none',
        [mobile]: {
            display: 'flex',
            height: '30px',
            width: '75%'
        }
    }
})

export const CustomToolTip = styled(Tooltip)(() => {
    return {
        display: 'none',
        justifyContent: 'center',
        alignItems: 'center',
        width: '35%',
        [mobile]: {
            display: 'flex',
            width: '20%'
        }
    }
})

export const ContainerPLotplanMobile = styled('div')(() => {
    return {
        display: 'none',
        width: '100%',
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            width: 5,
            height: 5,
        },
        [mobile]: {
            display: 'flex',
            flexDirection: 'column',
            marginTop: '1rem',
        }
    }
})

export const ContainerTablePLotsMobile = styled('div')(() => {
    return {
        display: 'none',
        [mobile]: {
            display: 'flex',
            width: '100%',
            marginTop: '1rem',
            overflow: 'auto',
            '&::-webkit-scrollbar': {
                width: 5,
                height: 5,
            },
        }
    }
})


export const ContainerPlotPlans = styled('div')(() => {
    return {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '0.50rem',
        [mobile]: {
            flexDirection: 'row',
        }
    }
})

export const ContainerTypesArea = styled('div')(() => {
    return {
        display: 'flex',
        flexDirection: 'row',
        gap: '0.20rem',
        width: '100%',
    }
})

export const ContainerMultiSelect = styled('div')(() => {
    return {
        width: '85%',
    }
})

export const ContainerSearchTypesArea = styled('div')(() => {
    const { colors } = useColors();
    return {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '15%',
        borderRadius: '10%',
        backgroundColor: colors.buttonActionColor,
        '& .MuiSvgIcon-root': {
            color: colors.white,
        },
        "&:hover": {
            backgroundColor: colors.buttonActionColor,
            opacity: 0.9,
            '& .MuiSvgIcon-root': {
                color: colors.white,
            },
        },
    }
})


