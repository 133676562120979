import React, { Fragment } from 'react';

// Components
import Dropzone from 'react-dropzone'
import { Grid, IconButton, Tooltip } from '@mui/material';
import { toast } from 'react-toastify';

// Icons
import { Cancel, Download } from '@mui/icons-material';

// Styles
import { Container, CustomGrid, SectionFiles, CustomSpan, CustomTypography } from './styles';

// Utils
import { ColorsContext } from "../../Context/ColorsContext"
import { useTranslation } from 'react-i18next'

export const AttachmentTechnicalSupervisors = ({ disabled, files, onFilesDrop, deleteAttach, downloadFile }) => {

    const { t } = useTranslation();
    const { colors } = React.useContext(ColorsContext);
    const ACCEPTED_FILES = {
        'image/jpeg': [], 
        'image/png': [],
        'application/pdf': [],
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': []
    }

    function onDrop(droppedFiles) {
        onFilesDrop(droppedFiles);
    };

    function onDropRejected() {
        toast.error(t('files.messages.errorFile.invalidFormat'))
    };

    return (
        <Container>
            <Dropzone onDrop={onDrop} onDropRejected={onDropRejected} disabled={disabled} accept={ACCEPTED_FILES}>
                {({getRootProps, getInputProps}) => (
                    <section className="container" style={{ padding: 0 }}>
                        <SectionFiles {...getRootProps({className: 'dropzone'})} disabled={disabled}>
                            <input {...getInputProps()} />
                            <CustomSpan>{t('files.filesDropDownMinimal')}</CustomSpan>
                        </SectionFiles>
                        {files?.length > 0 && 
                            <aside>
                                <CustomGrid container sx={{ maxHeight: '100px', overflowY: 'auto' }}>
                                    {files.map((file, index) => (
                                        <Fragment key={`attachment-${index}`}>
                                            <Grid container spacing={1} alignItems={'center'} style={{ borderBottom: '1px dashed #ccc', padding: '2px 0'}}>
                                                {!file.oldFile && 
                                                    <Grid item xs={1.5}>    
                                                        <Tooltip title={t('files.remove')}>
                                                            <span>
                                                                <IconButton onClick={() => deleteAttach(index)} disabled={disabled}>
                                                                    <Cancel sx={{ color: colors.red }}/>
                                                                </IconButton>
                                                            </span>
                                                        </Tooltip>
                                                    </Grid>
                                                }
                                                <Grid item xs={file.oldFile ? 9.5 : 8}>
                                                    <div style={{ wordWrap: 'break-word', paddingLeft: '5px' }}>
                                                        <CustomTypography>{file.route?.toString().split('?')[0].split(/[\/\\]/).pop() || file.name}</CustomTypography>
                                                    </div>
                                                </Grid>
                                                {file.oldFile && 
                                                    <Grid item xs={1.5}>
                                                        <Tooltip title={t('common.download')}>
                                                            <IconButton onClick={() => downloadFile(file)}>
                                                                <Download sx={{ color: colors.buttonActionColor }}/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                }
                                            </Grid>
                                        </Fragment>
                                    ))}
                                </CustomGrid>
                            </aside>
                        }
                    </section>
                )}
            </Dropzone>
        </Container>
    )
}