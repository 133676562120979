import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

import { Modal, ButtonGroup, Tooltip, Menu, MenuItem } from "@mui/material";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { useColors } from "../../Context/ColorsContext"
import { MediaQuerys } from "../../styles/GlobalStyle";

const { mobile, tablet, desktop, desktoplarge } = MediaQuerys;

export const DownloadButton = styled(Button)(({ bRadius }) => {
    const { colors } = useColors();
    return {
        height: '100%',
        width: '100%',
        padding: "0 20px",
        backgroundColor: colors.buttonActionColor,
        color: colors.white,
		borderRadius: bRadius || null,
        "&:hover": {
            backgroundColor: colors.buttonActionColor,
            opacity: 0.9,
            color: colors.white
        }
    }
})

export const TemplateButton = styled(Button)(({ bRadius }) => {
    const { colors } = useColors();
    return {
        height: '100%',
        width: '100%',
        padding: "0 20px",
        backgroundColor: colors.buttonActionColor,
        color: colors.white,
		borderRadius: bRadius || null,
        "&:hover": {
            backgroundColor: colors.buttonActionColor,
            opacity: 0.9,
            color: colors.white
        }
    }
})

export const ContainerButtonCreate = styled('div')(() => ({
	width: 170,
	height: 40,
	[desktop]: {
		width: "120px",
		height: "30px",
		"& button": {
			padding: "14px 12px",
			fontSize: "0.55rem",
		},
	},
	[tablet]: {
		width: "110px",
		height: 20,
		"& button": {
			fontSize: "7px",
		},
	},
}))

export const ContainerButtonSmall = styled('div')(() => ({
	width: 50,
	height: 40,
	[desktop]: {
		height: "30px",
		"& button": {
			padding: "14px 12px",
			fontSize: "0.55rem",
		},
	},
	[tablet]: {
		height: 20,
		"& button": {
			fontSize: "7px",
		},
	},
}))

  export const CustomMenuItem = styled(MenuItem)(() => {
	return {
	  [desktop]: {
		"& .MuiTouchRipple-root": {
      	fontSize: "0.75rem",
    	},
	  },
	};
  });

  export const CustomBtn = styled(Button)(() => {
	const { colors } = useColors();
	return {
        backgroundColor: colors.buttonActionColor,
		height: 40,
		[desktop]: {
			width: "85px",
			height: "30px",
			fontSize: "0.65rem",
		  },
		  [tablet]: {
			width: "100px",
			height: 28,
			fontSize: "7px",
		},
		"&:hover": {
            backgroundColor: colors.buttonActionColor,
            opacity: 0.9,
            color: colors.white
        }
	}
})


export const ButtonsGroup = styled('div')(() => {
	return {
		display: 'flex',
		flexDirection: 'row',
		gap: '0.7rem'
	}
})
