import { Auth } from "../utils/auth";
import { HealthCheckApi } from "./HealthCheckApi";
import Router from "../router";
import axios from "./AxiosConfig";

// Permissions
import ability from '../config/defineAbility.js';

export class PlotPlanApi {
    healthCheck = null;
    token = null;

    constructor() {
        let auth = new Auth();
        this.token = auth.getToken();
        this.healthCheck = new HealthCheckApi();
    }

    async list() {
        if (ability.can("PlotPlanList")) {
            return await axios.get(Router.apiBaseUrl + Router.apiPlotPlan, {})
            .then(async response => {
                return await this.healthCheck.checkApi(response)
            }).catch(async error => {
                return await this.healthCheck.checkApi(error)
            });
        }
        return {status: false};
    }

    async getById(id, areaTypeId) {
        if (ability.can("GetPlotPlan")) {
            return await axios.get(Router.apiBaseUrl + Router.apiPlotPlanDetail.replace(':id', id), {
                params: {
                    areaTypeId: areaTypeId,
                },
                paramsSerializer: {indexes: null}
            })
            .then(async response => {
                return await this.healthCheck.checkApi(response)
            }).catch(async error => {
                return await this.healthCheck.checkApi(error)
            });
        }
        return {status: false};
    }

    async create(data) {
        if (ability.can("CreatePlotPlan")) {
            return await axios.post(Router.apiBaseUrl + Router.apiPlotPlan, data)
            .then(async response => {
                return await this.healthCheck.checkApi(response)
            }).catch(async error => {
                return await this.healthCheck.checkApi(error)
            });
        }
        return {status: false};
    }

    async update(id, data) {
        if (ability.can("UpdatePlotPlan")) {
            return await axios.put(Router.apiBaseUrl + Router.apiPlotPlanUpdate.replace(':id', id), data)
            .then(async response => {
                return await this.healthCheck.checkApi(response)
            }).catch(async error => {
                return await this.healthCheck.checkApi(error)
            });
        }
        return {status: false};
    }

    async delete(id) {
        if (ability.can("DeletePlotPlan")) {
            return await axios.delete(Router.apiBaseUrl + Router.apiPlotPlanDelete.replace(':id', id))
            .then(async response => {
                return await this.healthCheck.checkApi(response)
            }).catch(async error => {
                return await this.healthCheck.checkApi(error)
            });
        }
        return {status: false};
    }

    async getHistorical({ plotPlanId, workflowId, statusId, areaTypeId }) {
        if (ability.can("HistoricPlotPlan")) {
            return await axios.get(Router.apiBaseUrl + Router.apiPlotPlanHistorical, {
                params: {
                    plotPlanId: plotPlanId,
                    workflowId: workflowId,
                    statusId: statusId,
                    areaTypeId: areaTypeId,
                },
                paramsSerializer: {indexes: null}
            })
            .then(async response => {
                return await this.healthCheck.checkApi(response)
            }).catch(async error => {
                return await this.healthCheck.checkApi(error)
            });
        }
        return {status: false};
    }

    async getByWorkflow(plotPlanId, workflowId) {
        return await axios.get(Router.apiBaseUrl + Router.apiPlotPlanByWorkflow, {
            params: {
                plotPlanId: plotPlanId,
                workflowId: workflowId
            }
        })
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)
        });
    }

    async getAreaType() {
        return await axios.get(Router.apiBaseUrl + Router.apiPlotPlanAreaType, {})
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)
        });
        return {status: false};
    }
}