import { TextField } from "@mui/material";
import { FormControlLabel } from '@mui/material';
import { useColors } from "../../Context/ColorsContext"
import { styled } from "@mui/material/styles";
import { CardContent, Grid, Typography } from '@mui/material';
import { BaseAutocomplete, MediaQuerys } from "../../styles/GlobalStyle";

const { mobile, tablet, desktop, desktoplarge } = MediaQuerys;

export const SectionPageTitle = styled("section")(() => ({
  display: "flex",
  width: "100%",
}));

export const Spacer = styled("div")(() => ({
  display: "flex",
  flex: 1,
}));

export const SelectInput = styled(BaseAutocomplete)(() => {
  const { colors } = useColors();
  return {
    width: '100%',
    background: colors.white,
    borderRadius: 6,
    fontSize: 13,
    '.MuiInputBase-input': {
      padding: 8
    },
  }
})

export const Input = styled(TextField)(({ }) => {
  const { colors } = useColors();
  return {
    width: '100%',
    height: '100%',
    fontSize: '0.75rem',
    '.MuiInputBase-root': {
      background: colors.white,
      borderRadius: 6,
      fontSize: 13
    },
    [desktop]: {
      '.MuiInputBase-root': {
        background: colors.white,
        borderRadius: 6,
        fontSize: '0.85rem'
      },
      '.MuiInputLabel-root': {
        fontSize: '0.85rem',
      },
    }
  }
})

export const Content = styled(CardContent)(() => ({
  padding: '10px 10px 6px 10px'
}))

export const ContainerInputs = styled('div')(() => {
  return {
    display: 'flex',
    gap: '1rem',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [mobile]: {
      flexDirection: 'column',
      padding: '10px 0px'
    }
  }
})

export const ContainerInputLargeFirts = styled('div')(() => {
  return {
    width: '70%',
  }
})

export const ContainerInputSmallFirts = styled('div')(() => {
  return {
    width: '30%',
  }
})

export const ContainerInputSecond = styled('div')(() => {
  return {
    width: '40%',
  }
})

export const ContainerInputMedium = styled('div')(() => {
  return {
    width: '20%',
  }

})

export const Form = styled('form')(() => {
  return {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  }
})


export const ContainerChecks = styled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: '15px',
    gap: '1rem'
  }
})

export const CustomControlLabel = styled(FormControlLabel)(() => {
  return {
    [desktop]: {
      '& .MuiTypography-root': {
        fontSize: '0.85rem',
      }
    }
  }
})

export const ContainerGroup = styled('div')(({ isFirst }) => {
  return {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    width: isFirst ? '40%' : '60%',
    [mobile]: {
      width: '100%',
    }
  }
})

export const ContainerSelectInput = styled('div')(() => {
  return {
    width: '180px',
  }
})