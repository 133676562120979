import { TextField, CardContent, IconButton } from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';

import { useColors } from "../../Context/ColorsContext"
import { styled } from "@mui/material/styles";
import { MediaQuerys } from "../../styles/GlobalStyle";

const { mobile, tablet, desktop, desktoplarge } = MediaQuerys;

export const SectionPageTitle = styled("section")(() => ({
  display: "flex",
  width: "100%",
}));

export const Spacer = styled("div")(() => ({
  display: "flex",
  flex: 1,
}));

export const Input = styled(TextField)(() => {
  const { colors } = useColors();  
  return {
    width: '100%',
    '.MuiInputBase-root': {
        background: colors.white,
        borderRadius: 6,
        fontSize: 13,
        [mobile]: {
          fontSize: 12
        }
    }
  }
})

export const Content = styled(CardContent)(() => ({
  padding: '10px 10px 6px 15px'
}))


export const ContainerContent = styled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  }
})


export const ContainerInput = styled('div')(({ items }) => {
  return {
    width: items ? 400 : 200,
    height: 50,
  }
})

export const IconAction = styled(IconButton)(({ idListDetail }) => {
  const { colors } = useColors();
  return {
    borderRadius: 20,
    pointerEvents: "pointer",
    background: colors.buttonActionColor,
    // marginLeft: 5,
    "&:hover": {
      backgroundColor: colors.buttonActionColor,
      opacity: 0.9,
      color: colors.white,
    },
    [desktop]: {
      fontSize: "0.25rem",
      width: '28px',
      height: '28px',
      "&MuiSvgIcon-root": {
        width: '16px'
      }
    },
    [tablet]: {
      height: 28,
      width: 28,
    },
    // [mobile]: {
    //   display: idListDetail ? 'flex' : 'none',
    // },
  };
});

export const NewIcon = styled(AddCircleIcon)(() => {
  const { colors } = useColors();
  return {
    height: '20px',
    width: '20px',
    color: colors.white,
    // fontSize: 18,
    [desktop]: {
      height: '16px',
      width: '16px'
    }
  };
});

export const ContainerHeader = styled('div')(() => {
  return {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap:'1rem',
    alignItems: 'flex-start',
    justifyContent: 'center',
  }
})

export const ContainerGroup = styled('div')(() => {
  return {
    display: 'flex',
    gap: '5px',
    justifyContent: 'flex-start',
    alignItems: 'center',
  }
})