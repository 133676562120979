import React from 'react';
import { CircularProgress } from '@mui/material';
import { Container } from './styles';

export const LoadingProgress = ({full}) => {

    return (
        <Container full={full}>
            <CircularProgress size={full ? 100 : 60} sx={{ marginBottom: full ? '' : '40px' }}/>
        </Container>
    )
}

