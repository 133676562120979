import FileSaver from "file-saver";
import { utils, write } from "xlsx";

export const ExportExcel = (props) => {
  const { apiData, fileName, isBlob } = props;

  const fileExtension = ".xlsx";
  
  if (isBlob) {
    FileSaver.saveAs(apiData, fileName + fileExtension);
  }
  else {
    if (apiData?.length > 0) {
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  
      const ws = utils.json_to_sheet(apiData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    }
  }
};