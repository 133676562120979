import React from 'react'

import { Grid, TextField, FormControlLabel,  } from '@mui/material';

// Styles
import {
    Text,
    TextSwitch,
    CustomSwitch,
    Container,
    ContainerGroupOne,
    ContainerGroupTwo,
    CustomTextField
} from './styles'
import { SearchButton } from '../../pages/styles';

import { useTranslation } from 'react-i18next'

// Permissions
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../Context/PermissionsContext';

export const PasswordPolicy = ({ data, errors, loading, handleChange, handleState, handlePasswordSecurity, handlePasswordUsername, handlePasswordDictionary, handlePasswordPolicy }) => {
    const { t } = useTranslation();
    const ability = useAbility(AbilityContext);

    return (
        <Container>
            <ContainerGroupOne>
                <Grid item xs={12}>
                    <FormControlLabel
                        sx={{ width: '100%', height: '100%' }}
                        disabled={loading}
                        control={
                            <CustomSwitch
                                checked={data.isActive}
                                onChange={() => handleState(!data.isActive)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                        label={
                            <TextSwitch enabled={data.isActive}>{data.isActive ? t('system.passwordPolicy.active') : t('system.passwordPolicy.inactive')}</TextSwitch>
                        } />
                </Grid>
            </ContainerGroupOne>

            <ContainerGroupTwo>
                <CustomTextField
                    label={t('system.passwordPolicy.minimumDays')}
                    type={'number'}
                    value={data.minimumDay}
                    onChange={e => handleChange(e, 'minimumDay')}
                    onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3)
                    }}
                    disabled={loading || !data.isActive}
                    error={errors.minimumDay.hasError}
                    helperText={errors.minimumDay.message}
                />

                <CustomTextField
                    label={t('system.passwordPolicy.maximumDays')}
                    type={'number'}
                    value={data.maximumDay}
                    onChange={e => handleChange(e, 'maximumDay')}
                    onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3)
                    }}
                    disabled={loading || !data.isActive}
                    error={errors.maximumDay.hasError}
                    helperText={errors.maximumDay.message}
                />

                <CustomTextField
                    label={t('system.passwordPolicy.passwordCharacterLimits')}
                    type={'number'}
                    value={data.passwordCharacterLimit}
                    onChange={e => handleChange(e, 'passwordCharacterLimit')}
                    onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3)
                    }}
                    disabled={loading || !data.isActive}
                    error={errors.passwordCharacterLimit.hasError}
                    helperText={errors.passwordCharacterLimit.message}
                />

                <CustomTextField
                    label={t('system.passwordPolicy.passwordHistory')}
                    type={'number'}
                    value={data.passwordHistory}
                    onChange={e => handleChange(e, 'passwordHistory')}
                    onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3)
                    }}
                    disabled={loading || !data.isActive}
                    error={errors.passwordHistory.hasError}
                    helperText={errors.passwordHistory.message}
                />
            </ContainerGroupTwo>

            <ContainerGroupTwo>
                <FormControlLabel
                    sx={{ width: '40%', height: '100%' }}
                    disabled={loading || !data.isActive}
                    control={
                        <CustomSwitch
                            checked={data.passwordUsername}
                            onChange={() => handlePasswordUsername(!data.passwordUsername)}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    }
                    label={
                        <TextSwitch enabled={data.passwordUsername}>
                            {data.passwordUsername
                                ? t('system.passwordPolicy.passwordUsernameNoApply')
                                : t('system.passwordPolicy.passwordUsernameApply')
                            }
                        </TextSwitch>
                    } 
                />
            </ContainerGroupTwo>

            <ContainerGroupTwo>
                
                <FormControlLabel
                    sx={{ width: '40%', height: '100%' }}
                    disabled={loading || !data.isActive}
                    control={
                        <CustomSwitch
                            checked={data.passwordDictionary}
                            onChange={() => handlePasswordDictionary(!data.passwordDictionary)}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    }
                    label={
                        <TextSwitch enabled={data.passwordDictionary}>
                            {data.passwordDictionary
                                ? t('system.passwordPolicy.passwordDictionaryApply')
                                : t('system.passwordPolicy.passwordDictionaryNoApply')
                            }
                        </TextSwitch>
                    } 
                />
                
                <CustomTextField
                    label={t('system.passwordPolicy.dictionaryPath')}
                    value={data.dictionaryPath}
                    onChange={e => handleChange(e, 'dictionaryPath')}
                    disabled={loading || !data.passwordDictionary || !data.isActive}
                    error={errors.dictionaryPath.hasError}
                    helperText={errors.dictionaryPath.message}
                />
            </ContainerGroupTwo>
            
            <ContainerGroupTwo>

                <FormControlLabel
                    sx={{ width: '40%', height: '100%' }}
                    disabled={loading || !data.isActive}
                    control={
                        <CustomSwitch
                            checked={data.passwordSecurity}
                            onChange={() => handlePasswordSecurity(!data.passwordSecurity)}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    }
                    label={
                        <TextSwitch enabled={data.passwordSecurity}>
                            {data.passwordSecurity
                                ? t('system.passwordPolicy.applyRule')
                                : t('system.passwordPolicy.noApplyRule')
                            }
                        </TextSwitch>
                    } />

                <CustomTextField
                    label={t('system.passwordPolicy.securityRule')}
                    value={data.passwordRule}
                    onChange={e => handleChange(e, 'passwordRule')}
                    disabled={loading || !data.passwordSecurity || !data.isActive}
                    error={errors.passwordRule.hasError}
                    helperText={errors.passwordRule.message}
                />

            </ContainerGroupTwo>

            <ContainerGroupTwo>
                <CustomTextField
                    multiline
                    fullWidth
                    rows={4}
                    label={t('system.passwordPolicy.validationMessage')}
                    value={data.passwordComments}
                    onChange={e => handleChange(e, 'passwordComments')}
                    disabled={loading || !data.passwordSecurity || !data.isActive}
                    error={errors.passwordComments.hasError}
                    helperText={errors.passwordComments.message}
                />

            </ContainerGroupTwo>

            {ability.can("UpdatePasswordPolicy") &&
                <ContainerGroupOne isDown={true}>
                    <SearchButton
                        isStandard={true}
                        variant="contained"
                        sx={{ height: 30, padding: '0 20px', border: 'none' }}
                        onClick={() => handlePasswordPolicy()}
                        disabled={loading}
                    >
                        {t('common.save')}
                    </SearchButton>
                </ContainerGroupOne>
            }
            {errors.general.hasError &&
                <Grid item xs={8}>
                    <Text>{errors.general.message}</Text>
                </Grid>
            }
        </Container>
    )
}