import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

// Utils
import { FormatDate } from '../../../../utils/formatDate';
import { useTranslation } from 'react-i18next'

export const SearchBar = ({
    setFilteredRequest,
    rows,
    filterText,
    filterKeys
}) => {

    const { t } = useTranslation();
    const [search, setSearch] = React.useState('')
    const {format} = new FormatDate();

    function handleSearch(e) {
        setSearch(e)
        filterSearch(e)
    }
    
    const filterSearch = (data) => {
        let searchResults = []
        rows.filter(e => {
            filterKeys.map(obj => {
                let value = e[obj]

                // Check if the element to search is inside an object
                if (obj.indexOf('.') > -1) {
                    value = eval(obj)
                }
                // Transforms the date to search in long format
                if ((obj === 'updatedAt' || obj === 'createdAt' || obj === 'lastUpdate') && e[obj]) {
                    value = format(e[obj], true, true)
                }
                
                if (toLower(value, data)) {
                    if (!searchResults.some((el) => el.id === e.id)) searchResults.push(e)
                    return e
                }
            })
        })

        setFilteredRequest(searchResults)
    }
	
	const toLower = (value, data) => {
        return value?.toString().toLowerCase().includes(data.toLowerCase())
    }

    React.useEffect(() => {
        setFilteredRequest(rows);
        handleSearch(search);
    }, [rows])

    return (
        <Paper
            component="form"
            sx={{ paddingLeft: '10px', display: 'flex', alignItems: 'center', width: '100%', height: '100%' }}
        >
            <InputBase
                type='text'
                name='search'
                value={search}
                onChange={e => handleSearch(e.target.value)}
                autoComplete='off'
                sx={{ display: 'flex', flex: 1, ml: 1, }}
                placeholder={filterText || t('common.searchBars.workflows')}
            />
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search" disabled>
                <SearchIcon />
            </IconButton>
        </Paper>
    );
}