import Router from "../router";
import axios from "./AxiosConfig";
import { Auth } from "../utils/auth";
import { t } from 'i18next';

export class HealthCheckApi {

    async check() {
        return await axios.get(Router.apiBaseUrl + Router.apiHealthCheck, {})
        .then(response => {
            return response
        }).catch(error => {
            return error.response
        })
    }

    async checkApi(data) {
        
        const { logout } = new Auth();

        if (data?.status === 500 || data?.status === 404 || !data.status) {
            const resp = await this.check();
            if (!window.location.href.includes(Router.appMaintenance) && (resp?.status === 400 || resp?.status === 404 || resp?.status === 500 || !resp)) {
                window.location.href = Router.appMaintenance
                return
            } else if (data?.response?.status === 401) {
                logout()
                window.location.href = Router.appLogin
                return
            } else if (data?.response?.status === 403) {
                const message = data?.response?.data ?? t('common.empty.notFound')
                const hash = window.btoa(
                    unescape(encodeURIComponent(message))
                )
                window.location.href = `${Router.appForbidden}?name=${hash}`
                return
            }
        }
        return data;
    }
}