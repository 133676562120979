import React, { useEffect } from 'react';
import { PermissionApi } from '../../../../services/PermissionApi';
import { MediaQuerys } from '../../../../styles/GlobalStyle';

// Components
import { Button } from '@mui/material';
import { FormButton } from '../../../../pages/styles';

import { TransferList } from '../../../TransferList';

// Styles
import {
    Container,
    Header,
    Body,
    Footer,
    SectionRight,
    Spacer
} from './styles'

// Utils
import { ColorsContext } from "../../../../Context/ColorsContext"
import { SearchButton } from '../../../../pages/styles';
import { useTranslation } from 'react-i18next'

// Permissions
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../../../Context/PermissionsContext';

export const ChangePermissions = ({ roleId, title, loading, handleClose, handleChangePermissions }) => {

    const { t } = useTranslation();
    const permissionApi = new PermissionApi();
    const { colors } = React.useContext(ColorsContext);
    const ability = useAbility(AbilityContext);
    const { mobile, tablet } = MediaQuerys;

    const permissionRef = React.useRef([])
    const dataRef = React.useRef(false)
    const [rows, setRows] = React.useState([])
    const [rowsFilter, setRowsFilter] = React.useState([])
    const [listPermissions, setListPermissions] = React.useState([])
    const [listPermissionsFilter, setListPermissionsFilter] = React.useState([])

    const [itemsFilter, setItemsFilter] = React.useState([])


    const handleChange = (value) => {
        permissionRef.current = value
    }

    const getListLeft = () => {
        return listPermissions
            .filter((item) => {
                let isFound = false
                rows.forEach((value) => {
                    if (JSON.stringify(value) === JSON.stringify(item)) {
                        isFound = true
                    }
                })

                return !isFound
            })
            .sort((a, b) => a.description.localeCompare(b.description))
            .map((item) => ({ key: item.id, text: item.description }))
    }

    const getListRight = () => {
        return rows
            .sort((a, b) => a.description.localeCompare(b.description))
            .map((item) => ({ key: item.id, text: item.description }))
    }

    useEffect(() => {
        if (dataRef.current === false) {

            async function loadPermissionsList() {
                permissionApi.all().then((response) => {
                    if (response?.status === 200) {
                        let resp = response.data.data
                        setListPermissions(resp)
                    }
                })
            }

            async function handlePermissions() {
                await permissionApi.list(roleId).then((response) => {
                    if (response?.status === 200) {
                        let resp = response.data.data
                        if(resp.length>0){
                            setRows(resp)
                        }
                        loadPermissionsList()
                    }
                })
            }

            handlePermissions()

            dataRef.current = true
        }
    }, [roleId, permissionApi])


    React.useEffect(() => {
        if(rows.length>0){  
            setRowsFilter(rows)
        }
      }, [rows])

    React.useEffect(() => {
        setListPermissionsFilter(listPermissions)
      }, [listPermissions])



    return (
        <Container>
            <Header>{title}</Header>
            <Body>
                {listPermissions.length > 0 && <TransferList
                    leftList={getListLeft()}
                    rightList={getListRight()}
                    onChange={handleChange}
                    listPermissions={listPermissions}
                    listPermissionsFilter={listPermissionsFilter}
                    setListPermissionsFilter={setListPermissionsFilter} 
                    itemsFilter={itemsFilter} 
                    setItemsFilter={setItemsFilter}
                    placeholder={'changePermissions'}
                />}
            </Body>
            <Footer>
                <SectionRight>
                    <FormButton
                        variant="outlined"
                        type='close'
                        onClick={() => handleClose()}
                        disabled={loading}
                    >
                        {t('common.cancel')}
                    </FormButton>
                    <Spacer />
                    {ability.can("UpdatePermissions") &&
                        <FormButton
                            onClick={() => handleChangePermissions(roleId, permissionRef.current)}
                            disabled={loading}
                        >
                            {t('common.confirm')}
                        </FormButton>
                    }
                </SectionRight>
            </Footer>
        </Container>
    )
}