import { styled } from "@mui/material/styles";
import { Autocomplete, Button, IconButton, TableCell, TableContainer, TextField } from "@mui/material";
import { useColors } from "../../../../Context/ColorsContext";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import TableBody from "@mui/material/TableBody";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Badge from "@mui/material/Badge";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";

import { MediaQuerys } from "../../../../styles/GlobalStyle";
import { Check } from "@mui/icons-material";
import { red } from "@mui/material/colors";

import Switch from '@mui/material/Switch';


const { mobile, tablet, desktop } = MediaQuerys;

function setColor(title) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { colors } = useColors();
  let titleLower = title ? title.toLowerCase() : "";
  if (titleLower === "aprobación pendiente") return colors.purple;
  if (titleLower === "ejecución plan de aislamiento") return colors.purple;
  if (titleLower === "ejecución prueba de gases") return colors.purple;
  if (titleLower === "en ejecución") return colors.green;
  if (titleLower === "cerrado") return colors.blue;
}

export const CustomTableCell = styled(TableCell)(() => {
  const { colors } = useColors();
  return {
    color: colors.buttonActionColor,
    fontSize: "1rem",
    fontWeight: 600,
    paddingTop: "10px !important",
    paddingBottom: "10px !important",
    alignItems: "center",
    [desktop]: {
      fontSize: "0.75rem !important",
    },
  };
});

export const TableCellStatus = styled(TableCell)(() => ({
  display: "flex",
  alignItems: "flex-start",
  border: "none",
}));
export const ContainerCell = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
}));

export const Figure = styled("figure")(({ title }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: title,
  height: "30px",
  width: "30px",
  minWidth: "30px",
  borderRadius: "50%",
  marginRight: "10px !important",
}));

export const SectionInfo = styled("section")(() => ({
  display: "flex",
  flexDirection: "column"
}));

export const InfoCell = styled("div")(() => ({}));

export const Label = styled("label")(() => ({
  fontSize: "14px",
  paddingRight: "5px",
  [desktop]: {
    fontSize: "0.75rem !important",
  },
  [tablet]: {
    fontSize: "10px !important",
  },
  [mobile]: {
    paddingRight: "0px",
    fontSize: "0.70rem !important",
  },
}));

export const InputDate = styled("input")(({ theme }) => {
  const { colors } = useColors();
  return {
    // marginRight: "20px",
    borderBottom: "1px solid " + colors.grayFont,
    borderRadius: "3px",
    fontFamily: theme.typography.fontFamily,
    padding: "5px 10px",
    width: "150px",
    [desktop]: {
      width: "130px",
    },
    [tablet]: {
      width: "100px",
      height: 20,
    },
    [mobile]: {
      width: "80px",
      height: 20,
      padding: "5px 5px",
      // marginRight: "10px",
    },
  };
});

export const FigureHead = styled("figure")(() => ({
  display: "flex",
}));

export const Cell = styled(TableCell)(() => ({
  border: "none",
}));

export const IconAction = styled(IconButton)(() => {
  const { colors } = useColors();
  return {
    // visibility: !roleMatch && 'hidden',
    borderRadius: 20,
    pointerEvents: "pointer",
    background: colors.buttonActionColor,
    color: colors.white,
    marginLeft: 5,
    "&:hover": {
      backgroundColor: colors.buttonActionColor,
      opacity: 0.9,
      color: colors.white,
    },
    [desktop]: {
      fontSize: "0.25rem",
      width: '28px',
      height: '28px',
      "&MuiSvgIcon-root": {
        width: '16px'
      }
    },
    [tablet]: {
      height: 28,
      width: 28,
    },
    [mobile]: {
      display: "none",
    },
  };
});

export const CleanIcon = styled(CleaningServicesIcon)(() => {
  const { colors } = useColors();
  return {
    color: colors.white,
    fontSize: 18,
    [tablet]: {
      width: 17,
      height: 17,
      padding: '1px',
      '.MuiSvgIcon-root': {
        width: 16,
        height: 16,
      }
    },
    [desktop]: {
      width: '16px',
      height: '16px',
    }
  };
});

export const NewIcon = styled(AddCircleIcon)(() => {
  const { colors } = useColors();
  return {
    height: '20px',
    width: '20px',
    color: colors.white,
    // fontSize: 18,
    [desktop]: {
      height: '16px',
      width: '16px'
    }
  };
});

export const CustomIconButton = styled(IconButton)(() => {
  const { colors } = useColors();
  return {
    borderRadius: "10%",
    backgroundColor: colors.background,
    color: colors.buttonActionColor,
    "&:hover": {
      backgroundColor: colors.buttonActionColor,
      opacity: 0.9,
      color: colors.background,
    },
  };
});

export const TableContent = styled(TableBody)(() => ({
  ".MuiTableCell-root": {
    fontSize: "1rem !important",
    [desktop]: {
      fontSize: "0.75rem !important",
    },
    [mobile]: {
      fontSize: "0.70rem !important",
    },
  },
  [tablet]: {
    ".MuiTablePagination-displayedRows": {
      fontSize: "0.75rem !important",
    },
    ".MuiTablePagination-selectLabel": {
      fontSize: "0.75rem!important",
    },
  },
}));

export const CustomFormControl = styled(FormControl)(() => {
  return {
    width: "150px",
    [desktop]: {
      width: "110px",
      "& .MuiFormLabel-root": {
        fontSize: "0.75rem",
      },
    },
    // [tablet]: {
    //   width: "100px",
    //   height: 20,
    // },
    // [mobile]: {
    //   width: "80px",
    //   height: 20,
    // },
  };
});

export const CustomInputLabel = styled(InputLabel)(() => {
  return {
    fontSize: "0.8em",
    position: "absolute",
    top: "50%",
    left: "40%",
    transform: "translate(-50%, -50%)",
    [tablet]: {
      fontSize: "0.75rem",
    }
  };
});

export const CustomBadge = styled(Badge)(() => {
  return {
    color: "white",
    [desktop]: {
      "& .MuiBadge-badge": {
        minWidth: "15px !important",
        width: "15px !important",
        height: "15px !important",
      },
      fontSize: "0.75rem",
    },
  };
});

export const CustomSelect = styled(Select)(() => {
  return {
    width: "150px",
    height: "32px",
    [desktop]: {
      width: "110px",
      // height: 26,
    },
    [tablet]: {
      width: "120px",
      height: 26,
    },
    // [mobile]: {
    //   width: "80px",
    //   height: 20,
    // },
  };
});

export const CustomMenuItem = styled(MenuItem)(() => {
  return {
    [desktop]: {
      "& .MuiTypography-root": {
        fontSize: "0.75rem",
      },
    },
  };
});

//? Custom items date

export const ContainerInputsDate = styled("div")(() => ({
  '& .css-4jnixx-MuiStack-root>.MuiTextField-root': {
    minWidth: "80px",
  },
  '& .MuiFormLabel-root': {
    fontSize: "0.8rem",
    [desktop]: {
      fontSize: "0.75rem"
    }
  },
  '& .MuiInputBase-root': {
    fontSize: "0.8rem",
    overflow: "hidden",
    paddingTop: '0px',
    [desktop]: {
      fontSize: "0.75rem",
    },
  },
  '& .MuiStack-root': {
    paddingTop: '0px !important',
    overflow: 'visible !important',
  },
  '& .MuiTextField-root': {
    minWidth: '120px !important',
    width: '150px !important',
    [desktop]: {
      width: '120px',
    }
  },
}));

export const ContainerGroup = styled("div")(({ needMagin }) => {
  return {
    display: "flex",
    alignItems: "center",
    gap: "0.50rem",
    flexDirection: "row",
    marginLeft: needMagin && 5,
  };
});

export const ContainerSearch = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  gap: "2px",
  alignItems: "center",
}));

export const CustomToolbar = styled(Toolbar)(() => {
  const { colors } = useColors();
  return {
    background: colors.gray,
    borderRadius: "5px 5px 0 0",
    minHeight: "60px !important",
    marginTop: "16px",
    paddingTop: "5px",
    paddingBottom: "5px",
  };
});

export const ContainerGroupSelects = styled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'row',
    gap: '5px',
    [mobile]: {
      justifyContent: 'space-evenly',
    }
  }
})

export const ContainerFiltros = styled('div')(() => {
  return {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '1rem',
    [desktop]: {
      display: 'contents',
      gap: '0.8rem',
    },
    [tablet]: {
      display: 'none'
    },
    [mobile]: {
      flexDirection: 'column',
      gap: '0.65rem',
    },
  }
})

export const ContainerDates = styled('div')(() => {
  return {
    display: "flex",
    flexDirection: 'row',
    gap: '1rem',
  }
})

export const ContainerPrueba = styled('div')(() => {
  return {
    [mobile]: {
      width: '100%',
    }
  }
})

export const ContainerActions = styled('div')(() => {
  return {
    display: "flex",
    flexDirection: 'row',
    gap: '0.35rem',
    [mobile]: {
      display: 'none',
    }
  }
})

export const ContentCell = styled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    width: 'max-content'
  }
})

export const CustomAutocomplete = styled(Autocomplete)(() => ({
  width: '100%',
  borderRadius: 6,
  fontSize: 13,
  '.MuiInputBase-root': {
    paddingTop: "3px !important",
    paddingBottom: "3px !important",
    maxWidth: '57px !important',
    minWidth: '57px !important',
  },
  [mobile]: {
    '.MuiInputLabel-root': {
      fontSize: '0.75rem',
    },
  },
  [desktop]: {
    '.MuiFormLabel-root': {
      fontSize: '0.85rem'
    }
  }
}))

export const CustomTextField = styled(TextField)(() => {
  return {
    padding: 0,
    margin: 0,
    '.MuiInputBase-root .MuiInputAdornment-root': {
      position: 'relative',
      bottom: '-12px !important'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.4)',
      },
    },
    "& .MuiInputBase-root.Mui-disabled": {
      "& > fieldset": {
        borderColor: "rgba(0, 0, 0, 0.15)"
      }
    }
  }
})

export const CustomCheckIcon = styled(Check)(({ disabled }) => {
  const { colors } = useColors();
  return {
    color: disabled ? 'rgba(0, 0, 0, 0.15)' : colors.buttonActionColor,
    padding: 0,
    margin: 0
  }
})


//Custom Switch

export const ContainerSwitch = styled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    width: 'max-content',
    textAlign: 'center',
    height: '30px',
    marginRight: '5px',
    [mobile]: {
      height: '35px',
    },
  }
})
export const LabelSwitch = styled('label')(() => {
  return {
    fontSize: '12px',
    marginBottom: '-5px',
    marginTop: '-3px',
    [desktop]: {
      fontSize: '0.8rem',
    },
    [mobile]: {
      marginTop: '0',
      marginBottom: '-5px',
    },
  }
})

export const CustomSwitch = styled(Switch)(() => {
  const { colors } = useColors();
  return {
    '& .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
      backgroundColor: colors.buttonActionColor || '#64b5f6'
    },
    '& .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked': {
      color: colors.buttonActionColor || '#2196f3'
    }
  }
})

export const CustomBtnFilter = styled(Button)(() => {
  const { colors } = useColors();
  return {
    display: 'none',
    height: 28,
    padding: '0 30px',
    color: colors.white,
    backgroundColor: colors.buttonActionColor,
    "&:hover": {
      backgroundColor: colors.buttonActionColor,
      opacity: 0.9,
      color: colors.white,
    },
    [tablet]: {
      display: 'flex',
      height: '28px',
      fontSize: '0.75rem',
    },
    [mobile]: {
      height: '25px',
    },
  }
})

export const CustomTableContainer = styled(TableContainer)(({ isCustom }) => {
  return {
    height: isCustom ? 'calc(100vh - 360px)' : 'calc(100vh - 430px)',
  }
})

export const ContainerDiv = styled('div')(({ myWork }) => {
  return {
    display: 'flex',
    justifyContent: myWork && 'center',
    alignItems: myWork && 'center',
    flexDirection: 'row',
    width: 'inherit'
  }
})

export const ContainerItem = styled('div')(({ show }) => {
  return {
    display: show === false ? 'none' : null,
    padding: '5px',
    width: 'max-content',
  }
})

