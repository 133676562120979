import { styled } from "@mui/material/styles";
import { MediaQuerys } from "../../../styles/GlobalStyle";

const { mobile, tablet, desktop } = MediaQuerys;

export const ContainerCell = styled('div')(() => ({
    display: 'flex',
    gap: '0.75rem',
    alignItems: 'center',
}))

export const SectionInfo = styled('section')(() => ({
    display: 'flex',
    width: 'max-content',
    flexDirection: 'column'
}))

export const InfoCell = styled('div')(() => ({
    [desktop]: {
        fontSize: '0.85rem',
    },
    [tablet]: {
        fontSize: '0.75rem !important'
    }
}))
