import { createTheme } from '@mui/material'
import { useColors } from "../../Context/ColorsContext"

export const theme = createTheme(() => {
    const { colors } = useColors();
    return {
      breakpoints: {
        values: {
          xs: 0,
          sm: 425,
          md: 769,
          lg: 1440,
          xl: 1536,
        },
      },
      palette: {
            primary: {
                main: colors.main,
            },
        },
        typography: {
            fontFamily: [
                'Montserrat',
                'sans-serif',
                'Material Icons'
            ].join(','),
        },
        components: {
            MuiFormLabel: {
                styleOverrides: {
                    asterisk: {
                        color: colors.red,
                        "&$error": {
                            color: colors.red,
                        },
                    }
                },
            },
        },
    }
})
