import { Auth } from "../utils/auth";
import { HealthCheckApi } from "./HealthCheckApi";
import Router from "../router";
import axios from "./AxiosConfig";

export class FormApi {
    healthCheck = null;
    token = null;

    constructor() {
        let auth = new Auth();
        this.token = auth.getToken();
        this.healthCheck = new HealthCheckApi();
    }

    async list(startDate, endDate, renderer = "form_builder") {
        return await axios.get(Router.apiBaseUrl + Router.apiForm, {
            params: {
                startDate: startDate,
                endDate: endDate,
                renderer
            }
        })
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)
        })
    }

    async detail(id) {
        return await axios.get(Router.apiBaseUrl + Router.apiFormDetail.replace(':id', id), {})
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)
        })
    }

    async create(data) {
        return await axios.post(Router.apiBaseUrl + Router.apiForm, data)
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)
        });
    }

    async update(id, data) {
        return await axios.put(Router.apiBaseUrl + Router.apiFormDetail.replace(':id', id), data)
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)
        })
    }

    async delete(id) {
        return await axios.delete(Router.apiBaseUrl + Router.apiFormDetail.replace(':id', id), {})
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)
        })
    }
}