import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Tabs from '@mui/material/Tabs';
import { useColors } from "../../Context/ColorsContext"
import { MediaQuerys } from "../../styles/GlobalStyle";

const { mobile, tablet, desktop } = MediaQuerys;

export const AppBar = styled(MuiAppBar)(({ theme }) => {
  const { colors } = useColors();

  return {
    background: colors.navbarColor,
    color: colors.white,
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: 'rgb(61 71 82 / 20%) 0px 4px 8px',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  }
});

export const TextUser = styled(Typography)(({theme}) => {
  const { colors } = useColors();
  return {
    flexGrow: 1,
    fontSize: 16,
    color: colors.white,
    margin: '0 5px',
    fontWeight: 'bold',
    [desktop]: {
      fontSize: "0.85rem",
    },
  }
});

export const BoxModal = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
}));

export const ContainerContent = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between !important",
  alignItems: "center",
  height: "64px",
  padding: "0px 24px",
}));

export const TitleNavbar = styled("span")(() => ({
  display: "flex",
  fontSize: "18px",
}));

export const ContainerMenu = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "2rem",
  justifyContent: "space-between",
  "& img": {
    width: "auto",
    height: "40px"
  },
  [desktop]: {
    "& .MuiTypography-root": {
      fontSize: "1rem",
    },
  },
  [mobile]: {
    "& .MuiTypography-root": {
      display: "none",
      gap: '1rem'
    },
  },
}));

export const ContainerNotification = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
}));

export const ContainerIcons = styled("div")(() => ({
  // width: 'auto',
  // height: 'auto',
  [desktop]: {
    width: "30px",
    height: "30px",
  },
  [mobile]: {
    with: "20px",
    height: "20px",
  },
}));

// export const CustomBadge = styled(Badge)(() => ({
//     // fontSize: "16px",
// }))

export const SelectMenu = styled(Menu)(() => ({
  '& .MuiList-root': {
    width: "400px",
  },
  [mobile]: {
    position: "absolute",

  }
}));

export const ItemMenu = styled(MenuItem)(() => ({
  [desktop]: {
    fontSize: "0.75rem ",
  },
}));

export const ItemMenuDefault = styled(MenuItem)(() => ({
  paddingTop: "0",
  '&:nth-child(3)': {
    borderBottom: '1px solid lightgray'
  },
  fontSize: "0.84rem",
  cursor: "Default",
  pointerEvents: "none",
  [mobile]: {
    fontSize: "0.75rem",
  },
}));

export const ItemNotification = styled(MenuItem)((props) => {
  return {
    fontWeight: `${props.normal ? 'normal' : "bold"}`,
    borderBottom: `${props.normal ? 'none' : "1px solid lightgray"}`,
    fontSize: "1rem ",
    cursor: "default",
    pointerEvents: "none",
    minHeight: 0,
    [desktop]: {
      fontSize: "0.75rem",
    }
  }
})

export const MenuNotification = styled(MenuItem)(({isread}) => {
  const { colors } = useColors();
  return {
    minWidth: '400px',
    background: isread === 'false' ? colors.notification : colors.white,
    ':not(:last-child)': {
      borderBottom: `1px solid ${colors.gray}`,
    }
  }
})

export const CustomTabs = styled(Tabs)(() => {
  // eslint-disable-next-line no-use-before-define
  const { colors } = useColors();
  return {
    minHeight: '30px !important',
    '&.MuiButtonBase-root-MuiTab-root .Mui-selected': {
      color: `${colors.buttonActionColor} !important`
    },
    '.Mui-selected': {
      color: `${colors.buttonActionColor} !important`
    },
    '& .MuiButtonBase-root': {
      fontSize: '0.80rem !important',
      minHeight: '30px !important',
    },
    '& .MuiTabs-indicator': {
      display: 'none'
    },
    [desktop]: {
      '& .MuiButtonBase-root': {
        fontSize: '0.75rem !important'
      },
    }
  }
});

export const CustomBadge = styled(Badge)(() => {
  const { colors } = useColors();
  return {
    marginTop: 10,
    '.MuiBadge-badge': {
      backgroundColor: colors.navbarColor,
      top: 6
    }
  }
});