import { DialogContent, IconButton, Typography } from "@mui/material";
import { styled } from "@mui/material/styles"
import { useColors } from "../../Context/ColorsContext"
import { MediaQuerys } from "../../styles/GlobalStyle";
const { mobile, tablet, desktop } = MediaQuerys;

export const CustomDialogContent = styled(DialogContent)(() => {
    const { colors } = useColors();
    return {
        displays: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        background: colors.white,
        [mobile]: {
            '&::-webkit-scrollbar': {
                width: 5,
                height: 5
            },
            overflowY: 'hidden',
        }
    }
});

export const Title = styled(Typography)(() => {
    const { colors } = useColors();
    return {
        fontSize: '1rem',
        fontWeight: 'bold',
        color: colors.green,
        [desktop]: {
            fontSize: '0.75rem'
        }
    }
});

export const CustomIconButton = styled(IconButton)(() => {
    const { colors } = useColors();
    return {
        borderRadius: '50%',
        marginLeft: 10,
        backgroundColor: colors.background,
        color: colors.buttonActionColor,
        "&:hover": {
            backgroundColor: colors.buttonActionColor,
            opacity: 0.9,
            color: colors.background,
        }
    }
})