import React, { useEffect, useState } from 'react';

// Components
import { Grid } from '@mui/material';
import { SelectInput } from '../SelectInput';

// Services
import { WorkflowApi } from '../../services/WorkflowApi';

// Styles

// Icons

// Utils
import { useTranslation } from 'react-i18next'
import { CheckResponse } from '../../utils/checkResponse';
import { SearchButton } from '../../pages/styles';
import { toast } from 'react-toastify';

// Permissions
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../Context/PermissionsContext';

export const GeneralSettings = (props) => {

    const workflowApi = new WorkflowApi();
    const {check} = new CheckResponse();
    const { t } = useTranslation();
    const ability = useAbility(AbilityContext);

    const [workflowList, setWorkflowList] = useState([])
    const [selectedWorkflow, setWelectedWorkflow] = useState({})

    useEffect(() => {
        async function call() {
            await callWorkflowApi()
        }
        call()
    }, [])

    async function callWorkflowApi() {
        let response = await workflowApi.list();
        let result = check(response);

        if (result.status) {
            let defaultWorkflow = response.data.find((el) => el.default)
            if (defaultWorkflow?.id) setWelectedWorkflow(defaultWorkflow)
            else setWelectedWorkflow(response.data.find((el) => el.id === 1))
            setWorkflowList(response.data)
        }
    }

    async function save() {
        if (!selectedWorkflow.id) {
            toast.error(t('settings.validations.mandatoryFlow'))
            return
        }

        let response = await workflowApi.workflowDefault(selectedWorkflow.id);
        let result = check(response);

        if (result.status) {
            toast.success(t('settings.messages.saveWorkflow.title'))
        }
        else {
            toast.error(() => 
                <div>{t('settings.messages.saveWorkflow.error')}<br/>
                    {result.errors}
                </div>
            );
        }  
    }

    return (
        <Grid container spacing={2} justifyItems={'center'} alignItems={'center'}>
            <Grid item xs={12}>
                <SelectInput 
                    label={t('settings.defaultFlow')}
                    name='workflow'
                    onChange={e => setWelectedWorkflow(e)}
                    value={selectedWorkflow} 
                    keyValue="name"
                    options={workflowList}
                    size="small"
                    width="60%"
                />
            </Grid>
            {ability.can("DefaultWorkflow") &&
                <Grid item xs={12} container direction='row' justifyContent="flex-end" >
                    <SearchButton
                        isStandard={true}
                        variant="contained"
                        sx={{ height: 30, padding: '0 20px', border: 'none' }}
                        onClick={save}
                    >
                        {t('common.save')}
                    </SearchButton>
                </Grid>
            }
        </Grid>
    )
}
