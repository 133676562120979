import * as React from "react";
import Modal from "@mui/material/Modal";

import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';

import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';

import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { AccountCircle, Notifications } from "@mui/icons-material";
import Menu from "@mui/material/Menu";
import { Auth } from "../../utils/auth";
import { Badge } from "@mui/material";
import { WorkflowDataApi } from "../../services/WorkflowDataApi";
import { useLocation, useNavigate } from "react-router-dom";
import { ChangePassword } from "../../components/atoms/modalCards/ChangePassword";
import { HubConnectionBuilder } from "@microsoft/signalr";
import Router from "../../router";
import { BackdropLoading } from "../../components/BackdropLoading";
import { useMsal } from "@azure/msal-react";

import { ColorsContext } from '../../Context/ColorsContext';

// Utils
import { redirectToAction, returnInstanceNotifications } from "../../utils/commonFunctions";
import { useTranslation } from "react-i18next";

import { LanguageSelect } from "../../components/atoms/languageSelect";
import { Notification } from "../../components/atoms/notification";

import {
  ContainerMenu,
  ContainerNotification,
  ContainerContent,
  SelectMenu,
  ItemMenu,
  ItemMenuDefault,
  ItemNotification,
  AppBar,
  TextUser,
  BoxModal,
  MenuNotification,
  CustomTabs,
} from "./styles";
import { HealthCheckApi } from "../../services/HealthCheckApi";
import { InstanceNotificationsModal } from "../../components/atoms/modalCards/InstanceNotificationsModal";

// Permissions
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../Context/PermissionsContext'

export default function Navbar({
  handleOpen,
  isExternal,
  userEmail,
  reloadWorkflows
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const { getUser, logout, getToken } = new Auth();
  const { instance } = useMsal();

  const { colors, flagNotification } = React.useContext(ColorsContext);
  const ability = useAbility(AbilityContext);

  const [user, setUser] = React.useState(null);
  const [tab, setTab] = React.useState('0');

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorNoti, setAnchorNoti] = React.useState(null);

  const [notifications, setNotifications] = React.useState([]);
  const [notificationsAlert, setNotificationsAlert] = React.useState();
  const [newNotifications, setNewNotifications] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [notificationsModal, setNotificationsModal] = React.useState({
    status: false,
    notifications: [],
    instance: {}
  });

  const hasAlerts = notificationsAlert?.length > 0;
  const hasNormals = notifications?.length > 0;

  const [openChangePasswordForm, setOpenChangePasswordForm] = React.useState(false);

  const { t } = useTranslation();

  const healthCheckApi = new HealthCheckApi();

  const workflowDataApi = new WorkflowDataApi();

  React.useEffect(() => {
    if (!isExternal) {

      // SignalR
      // establece un canal de comunicacion con el servidor
      // se debe usar el token de autorizacion
      // si llega una señal (un mensaje con el correo del usuario)
      // si corresponde al usuario logueado, consultar las notificaciones
      // HUB_NAME: el nombre del canal, debe coincidir con el backend
      // HUB_URL: ruta del canal, debe coincidir con el backend
      const HUB_NAME = "ReceiveMessage";
      const HUB_URL = "/api/workpermit/hub/message";
      const connection = new HubConnectionBuilder()
        .withUrl(Router.apiBaseUrl + HUB_URL, {
          accessTokenFactory: () => {
            return "Bearer " + getToken();
          },
        })
        .withAutomaticReconnect()
        .build();

      connection.headers = {
        Authorization: "Bearer " + getToken(),
      };

      connection
        .start()
        .then(() => {})
        .catch(async(err) => {
          if (err.message.includes(`Status code '401'`)) {
            // error({response: {status: 401}})
            await healthCheckApi.checkApi({response: {status: 401}})
          }
        });

      connection.on(HUB_NAME, async (mensaje) => {
        // console.log("Mensaje recibido:", mensaje);
        const user = getUser();
        if (mensaje === user.email) {
          await getNotifications();
        }
      });

      // Cuando el componente se desmonte, cierra la conexión
      return () => {
        connection.stop();
      };
    }
  }, []);

  React.useEffect(() => {
    if(!isExternal){
      async function call() {
        await getNotifications();
      }
      call()
    }
  },[flagNotification])

  React.useEffect(() => {
    if (user === null) {
      setUser(getUser());
    }
  }, [user, getUser]);

  React.useEffect(() => {
    if (hasAlerts) {
      setTab('0'); // Selecciona "Alertas" si existen
    } else if (hasNormals) {
      setTab('1'); // Selecciona "Normales" si solo existen normales
    }
  }, [hasAlerts, hasNormals]);

  async function getNotifications() {
    let response = await workflowDataApi.notification(false, true);
    if (response.status === 200) {
      let notificationAlert = response.data.filter(el => el.isNotified === true);
      let notificationNormal = response.data.filter(el => el.isNotified === false);
      setNotificationsAlert(notificationAlert);
      setNotifications(notificationNormal);
      reloadWorkflows(response.data)
      let count = 0;
      response.data?.map((obj) => {
        if (!obj.isRead) count += 1;
      });
      setNewNotifications(count);
    }
  }

  async function readNotification(id) {
    await workflowDataApi.updateNotification(id);
  }

  async function deleteNotification(id) {
    await workflowDataApi.deleteNotification(id);
  }

  const openNotification = async (row) => {
    setLoading(true);
    if (
      row.workflowStatus.statusId === 10002 ||
      row.workflowStatus.statusId === 10003
    ) {
      await deleteNotification(row.id);
    }

    handleCloseNotification();
    await readNotification(row.id);
    await getNotifications();

    let notifications = await returnInstanceNotifications(row, true);
    setLoading(false);
    if (notifications?.length > 1) {
      setNotificationsModal({status: true, notifications: notifications, instance: row})
    }
    else {
      await redirectToAction(row.workflowData, notifications, navigate, location)
    }
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleCloseNotifications() {
    setNotificationsModal({ status: false, notifications: [], instance: {} });
  }

  const showNotification = (event) => {
    setAnchorNoti(event.currentTarget);
  };

  const handleCloseNotification = () => {
    setAnchorNoti(null);
  };

  const handleLogout = async () => {
    // clearTimeout(timer);
    await logout();

    let typeLogin = localStorage.getItem('typeLog')
    if (typeLogin !== null && typeLogin !== undefined && typeLogin === 'tenant')
    {
      handleLogoutAD()
    }
    else {
      window.location.reload();
    }
  };

  const handleLogoutAD = () => {
    localStorage.removeItem('typeLog')

    instance.logoutRedirect({
      postLogoutRedirectUri: Router.appLogin,
    });
  }

  const handlePassword = () => {
    setOpenChangePasswordForm(true);
  };

  const handleCloseChangePassword = () => {
    setOpenChangePasswordForm(false);
  };

  const showUsername = (user) => {
    if (typeof user === 'string') return user.split('@')[0]
    return (user?.email ?? "").split('@')[0]
  }

  const handleChangeTab = (value, newValue) => {
    setTab(newValue);
  };

  return (
    <>
      <AppBar position="fixed">
        <ContainerContent>
          <ContainerMenu>
            {!isExternal && (
              <IconButton
                color="inherit"
                aria-label="menu"
                onClick={handleOpen}
              >
                <MenuIcon />
              </IconButton>
            )}
            {(colors?.iconUrl !== null && colors?.iconUrl !== "" && colors?.iconUrl !== undefined) &&
              <img src={`${Router.apiBaseUrl}/${colors.iconUrl.toString()}`} alt='logo'/>
            }
            <Typography component="div" sx={{ flexGrow: 1, fontSize: 18 }}>
              {colors?.title !== null && colors?.title !== undefined && colors?.title !== "" ? colors?.title : t("navbar.title")}
            </Typography>
          </ContainerMenu>

          <ContainerNotification>
            <LanguageSelect />
            {(!isExternal && ability.can("GetNotifications")) && (
              <IconButton
                size="large"
                aria-label="show notifications"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                onClick={showNotification}
              >
                <Badge badgeContent={newNotifications} color="error">
                  <Notifications />
                </Badge>
              </IconButton>
            )}
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={!isExternal ? handleMenu : null}
              color="inherit"
              style={{
                borderRadius: 0,
              }}
            >
              <AccountCircle />
              {/* <TextUser>{user?.Username || userEmail}</TextUser> */}
              <TextUser>{showUsername(user?.username || userEmail)}</TextUser>
            </IconButton>

            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <ItemMenuDefault>
                {`${user?.name ?? ""} ${user?.lastName ?? ""}`}
              </ItemMenuDefault>
              <ItemMenuDefault>{`${user?.email}`}</ItemMenuDefault>
              <ItemMenuDefault>{`${user?.role.name}`}</ItemMenuDefault>

              <ItemMenu onClick={handlePassword}>
                {t("navbar.changePassword")}
              </ItemMenu>
              <ItemMenu onClick={handleLogout}>{t("navbar.logout")}</ItemMenu>
            </Menu>

            {/* Menú Notifications */}

            {ability.can("GetNotifications") &&
              <SelectMenu
                id="menu-appbar"
                anchorEl={anchorNoti}
                anchorOrigin={{ vertical: "top", horizontal: "left" }}
                keepMounted
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                open={Boolean(anchorNoti)}
                onClose={handleCloseNotification}
                sx={{ top: '40px' }}
              >
                <ItemNotification>
                  {t("navbar.notifications.title")}
                </ItemNotification>

                {hasAlerts || hasNormals ? (
                  <TabContext value={tab}>
                    <Box sx={{
                      borderBottom: 1,
                      borderColor: 'divider'
                    }}>
                      <CustomTabs
                        value={tab}
                        onChange={handleChangeTab}
                        textColor="secondary"
                        indicatorColor="secondary"
                        aria-label="secondary tabs example"
                        TabIndicatorProps={{
                          backgroundColor: colors.buttonActionColor,
                          color: colors.buttonActionColor,
                        }}
                      >
                        {hasAlerts && <Tab label={t("navbar.notifications.tabDeviations")} value='0' />}
                        {hasNormals && <Tab label={t("navbar.notifications.tabPending")} value='1' />}
                      </CustomTabs>
                    </Box>

                    {hasAlerts && (
                      <TabPanel value='0' sx={{ padding: 0 }}>
                        {notificationsAlert.map((row) => (
                          <MenuNotification
                            key={row.id}
                            onClick={() => openNotification(row)}
                            isread={row.isRead.toString()}
                          >
                            <Notification data={row} />
                          </MenuNotification>
                        ))}
                      </TabPanel>
                    )}

                    {hasNormals && (
                      <TabPanel value='1' sx={{ padding: 0 }}>
                        {notifications.map((row) => (
                          <MenuNotification
                            key={row.id}
                            onClick={() => openNotification(row)}
                            isread={row.isRead.toString()}
                          >
                            <Notification data={row} />
                          </MenuNotification>
                        ))}
                      </TabPanel>
                    )}
                  </TabContext>
                ) : (
                  <Box
                    sx={{
                      padding: '16px',
                      textAlign: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '150px'
                    }}
                  >
                    <NotificationsOffIcon sx={{ fontSize: 40, color: 'gray', marginBottom: '8px' }} />
                    <Typography variant="body1" sx={{ color: 'gray' }}>
                      {t("navbar.notifications.noPending")}
                    </Typography>
                  </Box>
                )}
              </SelectMenu>
            }
          </ContainerNotification>
        </ContainerContent>
      </AppBar>
      <BackdropLoading open={loading}/>
      <Modal open={openChangePasswordForm}>
        <BoxModal>
          <ChangePassword
            username={user?.username}
            handleClose={handleCloseChangePassword}
          />
        </BoxModal>
      </Modal>

      <Modal open={notificationsModal.status}>
        <BoxModal>
          <InstanceNotificationsModal
            notifications={notificationsModal.notifications}
            instance={notificationsModal.instance}
            handleClose={handleCloseNotifications}
          />
        </BoxModal>
      </Modal>
    </>
  );
}
