import React, { useEffect, useState } from 'react';

// Components
import { toast } from 'react-toastify';

// Custom components
import { CardGraphic } from '../../components/CardGraphic';

// Utils
import { CheckResponse } from '../../utils/checkResponse';
import { ColorsContext } from "../../Context/ColorsContext"
import { useTranslation } from 'react-i18next'

// Styles

// API
import { DashboardReportApi } from '../../services/DashboardReportApi';

import { MediaQuerys } from '../../styles/GlobalStyle';

const { desktop } = MediaQuerys

export const PlotPlanGraphic = ({ plotPlan, workflow, typeAreas }) => {
    const dashboardReportApi = new DashboardReportApi();
    const {check} = new CheckResponse()
    const { t } = useTranslation();
    const { colors } = React.useContext(ColorsContext);

    // Graphic
    const [graphicOptions, setGraphicOptions] = useState({});
    const [graphicSeries, setGraphicSeries] = useState([]);

    async function loadOpenStates(data) {
        const result = data.filter(obj => obj.status.id !== -2 && obj.status.id !== -3);
        return result
    }

    useEffect(() => {
        if (!plotPlan?.id) {
            let opts = emptyGraphic([t('plotPlans.selectFlow')])
            setGraphicOptions(opts)
            setGraphicSeries([1])
        }
        else {
            async function call() {
                let response = await getByStatus(plotPlan.id, workflow.id, typeAreas)
                let result = await loadOpenStates(response)
                getGraphicData(result, workflow)
            }
            call()
        }
    }, [plotPlan, workflow])

    function emptyGraphic(text) {
        let opts = options([''])
        return {...opts,
            colors: ['#ccc'],
            plotOptions: {
                pie: {
                    donut: {
                        background: 'transparent',
                        labels: {
                            show: true,
                            total: {
                                show: true,
                                label: text,
                                fontSize: desktop ? '0.85rem' : '14px' ,
                                formatter: () => ''
                            }
                        }
                    }
                }
            },
            dataLabels: {
                enabled: false,
            },
            tooltip: {
                enabled: false,
            }                
        }
    }

    async function getByStatus(plotPlanId, workflowId, areaTypeId) {
        let response = await dashboardReportApi.getByStatus({plotPlanId: plotPlanId, workflowId: workflowId, areaTypeId: areaTypeId})
        let result = check(response)

        if (result.status) {
            return response.data
        }
        else {
            toast.error(() => <>{result.errors}</>)
        }
    }

    async function getGraphicData(response, workflow) {
        let grouped = {};
        response.forEach(item => {
            if (!grouped[item.status.title]) {
                grouped[item.status.title] = item.count;
            } else {
                grouped[item.status.title] += item.count;
            }
        });
        
        const entries = Object.entries(grouped);
        let labels = entries.map(entry => entry[0]);
        let series = entries.map(entry => entry[1]);
        let isEmpty = labels.length === 0
        
        if (isEmpty) {
            let opts = emptyGraphic(t('plotPlans.empty1'))
            setGraphicOptions(opts)
            setGraphicSeries([1])
        }
        else {
            setGraphicOptions(options(labels, workflow.name))
            setGraphicSeries(series)
        }
    }

    const options = (labels, type) => {
        let opts = {
            labels: labels,
            legend: {
                show: true,
                position: 'bottom',
            },
            colors: [colors.main, colors.sectionTitleColor, colors.orange, colors.red, colors.purple, colors.green, colors.blue],
            dataLabels: {
                enabled: true,
                textAnchor: 'start',
                style: {
                    colors: ['#fff']
                },
                formatter: function (_, opt) {
                    let value = opt.w.globals.series[opt.seriesIndex]
                    return [value]
                },
                offsetX: 0,
                dropShadow: {
                    enabled: true
                }
            },
            plotOptions: {
                pie: {
                    donut: {
                        labels: {
                            show: true,
                            total: {
                                show: true,
                                label: type || '',
                                fontFamily: 'Montserrat, sans-serif',
                                color: 'black',
                                formatter: function (w) {
                                    return w.globals.seriesTotals.reduce((a, b) => {
                                        return a + b
                                    }, 0)
                                }
                            }
                        }
                    }
                }
            },
            chart: {
                fontFamily: 'Montserrat, sans-serif',
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        } 
        
        return opts
    }

    return (
        <CardGraphic
            type="donut" 
            series={graphicSeries} 
            options={graphicOptions}
        />
    )
}

