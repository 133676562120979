import styled from 'styled-components';
import withColors from '../../styles/withColors'

const tabletStartWidth = 768;
const mobile = `@media (max-width: ${tabletStartWidth}px)`

const ContainerStyle = styled.div`
  width:100%;
  height:100%;
  display:flex;
  overflow: hidden;
  /*
  background: ${({ colors }) => colors.red};
  */
  ${mobile} {
    flex-direction:column-reverse;
    height:100vh;
    width:100vw;
  }
`

const SectionLeftStyle = styled.section`
  width:50%;
  height:100%;
  display:flex;
  align-items:flex-end;
  justify-content:center;
  background: url(${(props) => encodeURI(props.imgUrl)});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  ${mobile} {
    width:100%;
    height:fit-content;
    background:${({ colors }) => colors.white};
  }
`

const SectionRightStyle = styled.section`
  width:50%;
  height:100%;
  display:flex;
  flex-direction:column;
  align-items:center;
  background:${({ colors }) => colors.white};
  ${mobile} {
    width:100%;
  }
`

const HeaderStyle = styled.div`
  width:100%;
  height:7%;
  display:flex;
  align-items:center;
  justify-content:flex-end;
  background: ${({ colors }) => colors.white};
  padding: 0 2em;
  ${mobile} {
    justify-content:flex-end;
    padding: 0 1em;
    height:10%;
  } 
`

const TitleHeaderStyle = styled.h4`
  color: ${({ colors }) => colors.main};
  ${mobile} {
    font-size:.7em;
  } 
`

export const ContainerForm = styled.div`
  width:100%;
  display:flex;
  flex:1;
  ${mobile} {
    align-items:center;
    padding-bottom:2em;
  } 
`

const ContainerLogoStyle = styled.div`
  height:100px;
  width:350px;
  display:flex;
  align-items:center;
  background:${({ colors }) => colors.green};
  ${mobile} {
    height:70px;
    width:300px;
  } 
`

export const Figure = styled.figure`
  display:flex;
  width:100%;
  justify-content:center;  
`

export const Icon = styled.img`
  height:50px;
  ${mobile} {
    height:40px;
  } 
`

export const Container = withColors(ContainerStyle);
export const SectionLeft = withColors(SectionLeftStyle);
export const SectionRight = withColors(SectionRightStyle);
export const Header = withColors(HeaderStyle);
export const TitleHeader = withColors(TitleHeaderStyle);
export const ContainerLogo = withColors(ContainerLogoStyle);