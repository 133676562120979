import { styled } from "@mui/material/styles";
import { IconButton, TableCell, Toolbar } from "@mui/material";
import TablePagination from '@mui/material/TablePagination';
import { useColors } from "../../../../Context/ColorsContext"
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { MediaQuerys } from "../../../../styles/GlobalStyle";

const { mobile, tablet, desktop } = MediaQuerys;

export const CustomTableCell = styled(TableCell)(() => {
  const { colors } = useColors();
  return {
    color: colors.buttonActionColor,
    fontSize: '1rem',
    fontWeight: 600,
    paddingTop: '10px !important',
    paddingBottom: '10px !important',
    alignItems: 'center',
    padding: '0 16px',
    "&:hover": {
      color: colors.buttonActionColor
    },
    [desktop]: {
      fontSize: '0.75rem !important',
    }
  }
})

export const TableCellStatus = styled(TableCell)(() => ({
  display: 'flex',
  alignItems: 'center',
  border: 'none'
}))

export const ContainerCell = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}))

export const Figure = styled('figure')(({ title }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: title,
  height: '30px',
  width: '30px',
  minWidth: '30px',
  borderRadius: '50%',
  marginRight: '10px !important',
}))

export const SectionInfo = styled('section')(() => ({
  display: 'flex',
  flexDirection: 'column'
}))

export const InfoCell = styled('div')(() => ({
}))

export const Label = styled('label')(() => ({
  fontSize: '14px',
  paddingRight: '5px'
}))

export const InputDate = styled('input')(({ theme }) => {
  const { colors } = useColors();
  return {
    marginRight: '20px',
    borderBottom: '1px solid ' + colors.grayFont,
    borderRadius: '3px',
    fontFamily: theme.typography.fontFamily,
    padding: '5px 10px',
    width: '150px',
  }
})

export const FigureHead = styled('figure')(() => ({
  display: 'flex',
}))

export const Cell = styled(TableCell)(() => ({
  border: 'none',
}))

export const IconAction = styled(IconButton)(() => {
  const { colors } = useColors();
  return {
    borderRadius: 20,
    pointerEvents: 'pointer',
    background: colors.main,
    marginLeft: 5,
    color: colors.white,
    "&:hover": {
      backgroundColor: colors.main,
      opacity: 0.9,
      color: colors.white,
    }
  }
})

export const CleanIcon = styled(CleaningServicesIcon)(() => {
  const { colors } = useColors();
  return {
    color: colors.whitedetailTextColor,
    fontSize: 18
  }
})

export const NewIcon = styled(AddCircleIcon)(() => {
  const { colors } = useColors();
  return {
    color: colors.white,
    fontSize: 18
  }
})

export const CustomIconButton = styled(IconButton)(() => {
  const { colors } = useColors();
  return {
    borderRadius: '10%',
    backgroundColor: colors.background,
    color: colors.buttonActionColor,
    "&:hover": {
      backgroundColor: colors.buttonActionColor,
      opacity: 0.9,
      color: colors.background,
    }
  }
})

export const CustomToolbar = styled(Toolbar)(() => {
  const { colors } = useColors();
  return {
    background: colors.gray,
    borderRadius: '5px 5px 0 0',
    minHeight: '60px !important',
    marginTop: '16px',
    display: 'block'
  }
})

export const CustomLink = styled('span')(() => {
  const { colors } = useColors();
  return {
    display: 'flex',
    width: 'max-content',
    maxWidth: '100%',
    flexBasis: 'fit-content',
    cursor: 'pointer',
    color: colors.buttonActionColor
  }
})

export const IconArea = styled('div')(() => {
  const { colors } = useColors();
  return {
    marginLeft: 2,
    width: 15,
    height: 15,
    fontSize: 11,
    fontWeight: 'bold',
    color: colors.white,
    background: colors.orange,
    borderRadius: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
})

export const TableContent = styled(TableBody)(() => ({
  ".MuiTableCell-root": {
    fontSize: "1rem !important",
    [desktop]: {
      fontSize: "0.75rem !important",
    },
    [mobile]: {
      fontSize: "0.70rem !important",
    },
  },
  [tablet]: {
    '.MuiTablePagination-displayedRows': {
      fontSize: '0.75rem !important',
    },
    '.MuiTablePagination-selectLabel': {
      fontSize: '0.75rem!important',
    }
  },
}));

export const ContainerStatus = styled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.25rem'
  }
})


export const ContentCell = styled('div')(() => {
  return {
    display: 'flex',
    flexBasis: 'fit-content',
    maxWidth: '100%',
    width: 'max-content',
  }
})

export const ContainerGroup = styled('div')(() => {
  return {
    display: 'flex',
    gap: '0.75rem',
    width: 'max-content',
    flexDirection: 'row',
  }
})

export const CustomCell = styled('div')(() => {
  return {
    display: 'flex',
    width: 'max-content',
    maxWidth: '100%',
    flexBasis: 'fit-content'
  }
})

export const CustomTableContainer = styled(TableContainer)(() => {
  return {
    height: '400px',
    '& .MuiTableContainer-root': {
      maxWidth: '2px' 
    },
       '&::-webkit-scrollbar': {
        width: 5,
        height: 5
    },
  }
})

export const CustomTablePagination = styled(TablePagination)(() => {
  const { colors } = useColors();
  return {
    borderTop: '1px solid ' + colors.gray,
    '&::-webkit-scrollbar': {
      width: 5,
      height: 5
  },
  }
})