import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next'
import { DashboardReportApi } from '../../services/DashboardReportApi';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { ChevronRight, ExpandMore } from '@mui/icons-material';
import { StyledTreeItem } from './styles';

function NestedApiCall({selected, plotPlans, setSelected}) {
    const { t } = useTranslation();
    const dashboardReportApi = new DashboardReportApi();
    const [data, setData] = useState([]);

    const [showNested, setShowNested] = useState({});
    const [clickedNodes, setClickedNodes] = useState([]);

    const toggleNested = async(name) => {
        setShowNested({ ...showNested, [name]: !showNested[name] });
    };

    useEffect(() => {
        setData(plotPlans);
    }, []);

    const fetchChildren = async (parentId) => {
        let response = await dashboardReportApi.getByPlotPlan(parentId, selected.workflow, selected.created, selected.status);
        return response.data;
    };

    const handleNodeClick = async (node) => {
        saveNodes(node?.plotPlan?.id)
        if (node?.children?.length > 0 || node?.plotPlan?.id < 1) {
            return;
        }
        toggleNested(node?.plotPlan?.name)
        const children = await fetchChildren(node?.plotPlan?.id);
        const newData = findChildren(data, node?.plotPlan?.id, children)
        setData(newData);
    };

    const saveNodes = (id) => {
        let array = [...clickedNodes]
        let index = array.findIndex(obj => {return obj.node === id})

        let selectedNode = null
        if (index === -1) {
            selectedNode = true
            array.push({node: id, selected: selectedNode})
        }
        else {
            selectedNode = !array[index]?.selected
            array[index] = {node: id, selected: !array[index]?.selected}
        }
        
        setClickedNodes(array)

        let plotPlan = selectedNode ? id : null
        setSelected({...selected, plotPlan: plotPlan})
    }

    const findChildren = (data, id, children) => {
        const array = data.map(obj => {
            if (obj.plotPlan?.id === id) {
                return {
                    ...obj,
                    children: children
                }
            } else if (obj.children) {
                return {
                    ...obj,
                    children: findChildren(obj.children, id, children)
                }
            }
            return obj
        })
    
        return array
    }

    const renderNode = (node) => {
        return (
            <>
                <StyledTreeItem
                    key={'node-' + node?.plotPlan?.id}
                    nodeId={'node' + node?.plotPlan?.id} 
                    label={`${node?.plotPlan?.name} | ${node?.count}`}
                    onClick={() => handleNodeClick(node) }
                >
                    {renderTree(node?.children || [])}
                </StyledTreeItem>
            </>
        );
    };

    const renderTree = (treeData) => {
        return (
            <TreeView
                aria-label="file system navigator"
                defaultCollapseIcon={<ExpandMore />}
                defaultExpandIcon={<ChevronRight />}
            >
                {treeData.map(renderNode)}
            </TreeView>
        );
    };

    return (
        <div>
            {data.length > 0 
                ? renderTree(data) 
                : <div style={{ marginTop: 40 }}> {t('common.empty.noData')} </div>
            }
        </div>
    );
}

export default NestedApiCall;
