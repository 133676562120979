import { styled as muiStyled } from "@mui/material/styles";
import { Grid, Box } from "@mui/material";
// Utils
import { useColors } from "../../Context/ColorsContext"
//Global Styled
import { MediaQuerys } from "../../styles/GlobalStyle";

const drawerWidth = 250;
const { mobile, tablet, desktop } = MediaQuerys;

export const BoxContainer = muiStyled(Box)(({ open, theme }) => {
  const { colors } = useColors();

  return {
    backgroundColor: colors.footerColor,
    color: colors.white,
    minHeight: 50,
    width: "100%",
    position: "absolute",
    zIndex: 500,
    left: 0,
    bottom: 0,
    right: 0,
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 1%), 0px 1px 1px 0px rgb(0 0 0 / 1%), 0px 1px 3px 0px rgb(0 0 0 / 12%);",
    [mobile]: {
      width: "100%",
      position: "fixed",
    },
    [tablet]: {
      width: "100%",
      position: "fixed",
    }
  }
});

export const FooterContainer = muiStyled(Grid)(({ theme, open }) => ({
	[theme.breakpoints.up('md')]: {
		width: `calc(100vw + ${drawerWidth}px)`,
	},
  paddingTop: 10,
  margin: 'auto',
  display: 'flex',
  direction: "row",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  // [mobile]: {
  //   width: "100%",
  // },
  // [tablet]: {
  //   width: "100%",
  // }
}));

export const ImageContainer = muiStyled("img")(() => ({
  width: 15,
}));
