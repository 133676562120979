import {styled} from "@mui/material/styles";
import { Card, CardContent, Grid, Typography, CardMedia } from "@mui/material";
import GpsNotFixedIcon from '@mui/icons-material/GpsNotFixed';
import { useColors } from "../../Context/ColorsContext";
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import { MediaQuerys } from "../../styles/GlobalStyle";

const { mobile, tablet, desktop } = MediaQuerys;

export const Content = styled(CardContent)(({selected}) => {
    const { colors } = useColors();
    return {
        padding: '10px 5px 2px 5px !important',
        background: selected ? colors.main : colors.white,
        color: selected ? colors.white : colors.grayFont,
        [mobile]: {
            width: '100%',
              '&::-webkit-scrollbar': {
            width: 5,
            height: '5px !important'
    },
        }
    }
})

export const GpsIcon = styled(GpsNotFixedIcon)(() => {
    const { colors } = useColors();
    return {
        color: colors.white,
        background: colors.main,
        borderRadius: 20,
        padding: 1,
        width: 20,
        height: 20,
        [desktop]: {
            width: 15,
            height: 15
        }
    }
})

export const GridCenter = styled(Grid)(() => {
    const { colors } = useColors();
    return {
        textAlign: 'center',
        height: '130px',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'center',
        background: colors.white
    }
})

export const IconArea = styled('div')(() => {
    const { colors } = useColors();
    return {
        width: 20,
        height: 20,
        fontSize: 11,
        fontWeight: 'bold',
        color: colors.white,
        background: colors.orange,
        borderRadius: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 'auto',
        [desktop]: {
            height: 15,
            width: 15
        }
    }
})

export const CardContainer = styled(Card)(({ index }) => ({
    cursor: 'pointer',
    // marginBottom: 10,
    // marginTop: index.length > 0 ? 2 : 0,
    borderRadius: '5px',
    overflow: 'visible',
    width: 'auto',
    marginBottom: 0,
      '&::-webkit-scrollbar': {
    width: 5,
    height: 5
},
    // [mobile]: {
    // }
}))


export const Title = styled(Typography)(() => {
    return {
        [desktop]: {
            fontSize: '0.80rem'
        }
    }
})

export const ContainerGroup = styled('div')(() => {
    return {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    }
})

export const ContainerGroupRight = styled('div')(() => {
    return {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        gap: '0.75rem'
    }
})

export const ContainerGroupLeft = styled('div')(() => {
    return {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        gap: '0.75rem'
    }
})

export const CustomTyphography = styled(Typography)(() => {
    return {
        [desktop]: {
            fontSize: '0.65rem',
        }
    }
})

export const CustomImageNotSupportedIcon = styled(ImageNotSupportedIcon)(() => {
    const { colors } = useColors();
    return {
        color: colors.grayFont, 
        width: '50px', 
        height: '50px',
        [desktop]: {
            width: '40px',
            height: '40px',
        } 
    }
})

export const CustomCardMedia = styled(CardMedia)(() => {
    return {
        height: 130,
        [mobile]: {
            width: '250px'
        }

    }
})

export const ContentCard = styled('div')(() => {
    return {
        position: 'relative'
    }
})

export const CustomLabelCardMedia = styled('div')(() => {
    return {
        position: 'absolute',
        top: '0px',
        left: '0px',
        background: '#fff',
        padding: '5px',
        fontSize: '1rem'
    }
})