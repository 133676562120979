import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';


import {
  CustomTableCell,
  FigureHead,
  TableContent
} from './styles'

import { ColorsContext } from "../../../../Context/ColorsContext"
import { FormatDate } from '../../../../utils/formatDate';

// Utils
import { useTranslation } from 'react-i18next'

//hooks
import { useSortByColumn } from '../../../../Hooks/useSortByColumn';

const headCells = [
  {
    id: 'id',
    center: false,
    disablePadding: true,
    sortArrow: true,
    label: 'system.permissions.table.id',
  },
  {
    id: 'description',
    center: false,
    disablePadding: true,
    sortArrow: true,
    label: 'system.permissions.table.description',
  },
  {
    id: 'created_at',
    center: false,
    disablePadding: true,
    sortArrow: true,
    label: 'system.permissions.table.createdDate',
    customSort: 'createdAt',
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
	const { t } = useTranslation();
  const { colors } = React.useContext(ColorsContext);

  const createSortHandler = (property, customSort) => (event) => {
    onRequestSort(event, property, customSort);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <CustomTableCell
            key={headCell.id}
            align={headCell.center ? 'center' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              padding: '0 16px',
              color: colors.buttonActionColor,
              "&:hover": {
                color: colors.buttonActionColor,
                opacity: 0.9
              },
            }}
          >
            {headCell.sortArrow == true &&
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id, headCell.customSort)}
              >
                {t(headCell.label) || headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span">
                  </Box>
                ) : null}
              </TableSortLabel>
            }
            {headCell.sortArrow == false &&
              <>
                <Box component="span">
                  {t(headCell.label) || headCell.label}
                </Box>
              </>
            }
          </CustomTableCell>
        ))}
      </TableRow>
    </TableHead >
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default function TablePermissions({
  filteredRequest,
  rows
}) {

  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const {format} = new FormatDate();
	const { t } = useTranslation();
  const { colors } = React.useContext(ColorsContext);

  const { order, orderBy, stableSort, getComparator, handleRequestSort } = useSortByColumn('createdAt');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />

            <TableContent>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                  rows.sort(getComparator(order, orderBy)).slice() */}
                {stableSort(filteredRequest, getComparator())
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {

                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={row.id}
                      >
                        <TableCell component="th" scope="row" sx={{ border: "none", width: 'auto !important' }}>
                          {row.id}
                        </TableCell>
                        <TableCell sx={{ border: "none", width: 'auto !important' }}>{row.description}</TableCell>
                        <TableCell sx={{ border: "none", width: 'auto !important' }}>{format({ date: row.createdAt, format: true }).toString().toLowerCase()}</TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
            </TableContent>

          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t('common.itemsPerPage')}
          labelDisplayedRows={
            ({ from, to, count }) => {
              return '' + from + '-' + to + ` ${t('common.of')} ` + count
            }
          }
          sx={{
            borderTop: '1px solid ' + colors.gray,
          }}
        />
      </Paper>
    </Box>
  );
}
