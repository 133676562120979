import { Grid, Button, TextField } from "@mui/material";
import { styled } from "@mui/material/styles"
import { useColors } from "../../Context/ColorsContext"
import { MediaQuerys } from "../../styles/GlobalStyle";

const { mobile, tablet, desktop } = MediaQuerys;

export const CustomGrid = styled(Grid)(() => ({
  '&.MuiGrid-item': {
    paddingTop: 0
  },
}));

export const ActionButton = styled(Button)(() => {
  const { colors } = useColors(); 
  return {
    height: 36,
    width: '20%',
    margin: '15px 0',
    fontSize: 14,
    fontWeight: 600,
    marginRight: 5,
    borderRadius: '4px !important',
    border: 'none !important',
    color: `${colors.white} !important`,
    background: `${colors.buttonActionColor} !important`,
    opacity: 0.6,
    [desktop]: {
      fontSize: '0.75rem',
      height: 30,
    },
    [tablet]: {
      fontSize: '0.65rem',
    },
    [mobile]: {
      fontSize: '0.55rem',
    }
  }
})

export const PreviewButton = styled(Button)(() => {
  const { colors } = useColors(); 
  return {
    height: 36,
    width: '20%',
    margin: '15px 0',
    fontSize: 14,
    fontWeight: 600,
    marginRight: 5,
    borderRadius: '4px !important',
    border: 'none !important',
    color: `${colors.white} !important`,
    background: `${colors.buttonActionColor} !important`,
    [desktop]: {
      fontSize: '0.75rem',
      height: 30,
    },
    [tablet]: {
      fontSize: '0.65rem',
    },
    [mobile]: {
      fontSize: '0.55rem',
    }
  }
})

export const SaveButton = styled(Button)(() => {
  const { colors } = useColors(); 
  return {
    height: 36,
    width: '100%',
    margin: '15px 0',
    fontSize: 14,
    fontWeight: 'bold',
    paddingLeft: '10px !important',
    paddingRight: '10px !important',
    borderRadius: '4px !important',
    float: 'right !important',
    background: `${colors.mainGreen} !important`,
    color: `${colors.white} !important`,
    textTransform: 'uppercase !important',
    [desktop]: {
      fontSize: '0.75rem',
      height: 30,
    },
    [tablet]: {
      fontSize: '0.65rem',
    },
    [mobile]: {
      fontSize: '0.55rem'
    }
}
})


export const ContainerGroup = styled('div')(() => {
  return {
    display: 'flex',
    gap: '0.75rem',
    flexDirection: 'row',
    width: '45%',
    [tablet]: {
      width: '60%',
    },
    [mobile]: {
      width: '100%',
    }
  }
})

export const ContainerInput = styled('div')(() => {
  return {
    width: '50%'
  }
})


export const CustomTextField = styled(TextField)(() => {
  return {
    width: '100%',
    '.MuiInputLabel-root': {
      fontSize: '0.85rem',
    },
  }
})

export const SectionSelects = styled('section')(() => {
  return {
    displays: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    [mobile]: {
      justifyContent: 'space-between',
    }
  }
})

export const ContainerContent = styled('div')(() => {
  return {
    displays: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    width: '100%',
  }
})


export const ContainerSettings = styled('div')(() => {
  return {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  }
})

export const SectionBtnRight = styled('div')(() => {
  return {
    displays: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '20%',
  }
})

export const SectionBtnLeft =styled('div')(() => {
  return {
    display: 'flex',
    gap: '0.75rem',
    alignItems: 'center',
    width: '80%',
    [mobile]: {
      gap: '0.25rem',
    }
  }
})

export const ContainerForm = styled('div')(() => {
  return {
    display: 'flex',
    marginTop: '10px',
    flexDirection: 'column',
    width: '100%',
  }
})
