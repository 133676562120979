import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

// Utils
import { useTranslation } from 'react-i18next'

export const SearchBarWorkflows = ({
    setFilteredRequest,
    rows,
    filterText,
    maintainFilter,
    clearFilter,
    setSearchText
}) => {

    const { t } = useTranslation();
    const [search, setSearch] = React.useState('');

    React.useEffect(() => {
        setSearch('')
    }, [clearFilter])

    function handleSearch(e) {
        setSearch(e)
        setSearchText(e)
    }


    return (
        <Paper
            component="form"
            sx={{ paddingLeft: '10px', display: 'flex', alignItems: 'center', width: '100%', height: '100%' }}
        >
            <InputBase
                type='text'
                name='search'
                value={search}
                onChange={e => handleSearch(e.target.value)}
                autoComplete='off'

                sx={{ display: 'flex', flex: 1, ml: 1, }}
                placeholder={filterText || t('common.searchBars.workflows')}
                inputProps={{ 'aria-label': 'search google maps' }}
            />
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search" disabled>
                <SearchIcon />
            </IconButton>
        </Paper>
    );
}