import { t } from 'i18next';

export class CheckResponse {

    check(response) {
        if (response === null || response === undefined || response?.status === false) {
            return {status: false, errors: []};
        }

        let responseStatus = response?.status !== undefined ? response?.status : response?.response?.status;
        if (responseStatus >= 200 && responseStatus <= 299) {
            return {status: true};
        }
        else {
            let data = response?.response?.data || response?.response
            let array = []
            if (data?.status === 401) {
                array.push(t('password.validations.invalidCredentials'))
                return {status: false, errors: returnArray(array)};
            }
            if (data?.status === 400) {
                Object.entries(data.errors).forEach((value) => {
                    array.push(t('common.mandatory', {field: dictionary(value[0])}))
                })
                
                return {status: false, errors: returnArray(array)};
            }
            if (data?.status === 404) {
                array.push(t('common.empty.notFound'))
                return {status: false, errors: returnArray(array)};
            }
            if (data?.status === 415) {
                array.push(t('common.invalidFile'))
                return {status: false, errors: returnArray(array)};
            }

            if (Array.isArray(data)) {
                data?.map(obj => {
                    array.push(obj.errorMessage)
                })
            }
            else {
                if (data !== undefined && data !== null && Object.keys(data)?.length > 0) {
                    Object.entries(data).forEach((value) => {
                        if (typeof value?.[1] === 'string' && value?.[0] === 'description') {
                            array.push([value[1]])
                        }
                        if (typeof value?.[1] !== 'string') {
                            array.push(...value[1])
                        }
                    })
                }
            }
            
            return {status: false, errors: returnArray(array)};
        }
    }
}

function dictionary(value) {
    let values = {
        Name: "Nombre",
        Icon: "Icono",
        queueReceive: "Cola recibido",
        queueSend: "Cola de envío"
    }
    return values[value] || ""
}

function returnArray(array) {
    if (array?.length === 0) return false
    return <ul style={{ listStyle: 'circle', marginLeft: 16 }}>
        {array?.map(row => (
            <li key={row}><p>{row}</p></li>
        ))}
    </ul>
}
