import { styled } from "@mui/material/styles";
import { useColors } from "../../Context/ColorsContext"
import { Typography } from "@mui/material";

export const SectionPageTitle = styled("section")(() => ({
  display: "flex",
  width: "100%",
}));

export const FormContainer = styled('div')(() => {
  const { colors } = useColors();  
  return {
    background: colors.white,
    padding: 20,
    borderRadius: '4px',
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
  }
});

export const Title = styled(Typography)(() => ({
  fontWeight: 'bold',
  fontSize: 14
}));

export const Text = styled(Typography)(() => ({
  fontSize: 14
}));