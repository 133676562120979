import { Auth } from "../utils/auth";
import { HealthCheckApi } from "./HealthCheckApi";
import Router from "../router";
import axios from "./AxiosConfig";

export class PlantApi {
    healthCheck = null;
    token = null;

    constructor() {
        let auth = new Auth();
        this.token = auth.getToken();
        this.healthCheck = new HealthCheckApi();
    }

    async list() {
        return await axios.get(Router.apiBaseUrl + Router.apiPlant, {})
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)
        });
    }

    async getByUser() {
        return await axios.get(Router.apiBaseUrl + Router.apiPlantsByUser, {})
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)
        });
    }
}