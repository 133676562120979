import React, { Fragment, useEffect } from 'react';

// Components
import { Collapse, IconButton, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import List from '@mui/material/List';

// Styles
import { CustomDrawer, CustomIcon, DrawerHeader, Item, ItemIcon, ItemText } from './styles';

// API
import { NavLink, useLocation } from 'react-router-dom';
import Router from '../../router'

// Icons
import {
    ManageHistory,
    ViewComfy,
    AccountTree,
    ExpandLess,
    ExpandMore,
    ChevronLeft,
    Map,
    Tune,
    People,
    Security,
    List as ListIcon,
    PendingActions,
    Assessment,
    Settings,
    VideoSettings,
    Description,
    Business,
    RecentActors,
} from '@mui/icons-material';

import QueryStatsIcon from '@mui/icons-material/QueryStats';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

import AssignmentIcon from '@mui/icons-material/Assignment';

//Services
import { WorkflowApi } from '../../services/WorkflowApi';

// Utils
import { useTranslation } from 'react-i18next'
import { CheckResponse } from '../../utils/checkResponse';

// Permissions
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../Context/PermissionsContext'

export default function Sidebar({open, onClose, setOpen}) {
    const ability = useAbility(AbilityContext);
    const theme = useTheme();
    const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

    const { t } = useTranslation();
    const {check} = new CheckResponse();
	const location = useLocation();
	const workflowApi = new WorkflowApi();

	const [routes, setRoutes] = React.useState([])
	const [openItem, setOpenItem] = React.useState(true);
	const [to,] = React.useState("");

	const LinkBehavior = React.forwardRef(
		(props, ref) => {
			return <NavLink ref={ref} to={""} {...props}/>
		}
	)

	const fixedRoutes = [
		{
			icon: <ManageAccountsIcon style={{fontSize: 24}}/>,
			text: 'administration',
			nested: true,
			parent: 'parent2',
			to: to,
            permissionName: "ability.can('SignUp') || ability.can('UpdateUser') || ability.can('ToggleStateUser') || ability.can('CreateCompanies') || ability.can('UpdateCompanies') || ability.can('DeleteCompanies') || ability.can('CreateWorkers') || ability.can('UpdateWorkers') || ability.can('UploadFileWorkers') || ability.can('DeleteWorkers') || ability.can('CreateContract') || ability.can('UpdateContract') || ability.can('UpdateContractor') || ability.can('UpdateTechnicalSupervisor') || ability.can('UploadAttachmentSupervisor') || ability.can('UpdateContractor') || ability.can('DeleteContract')",
            customPermission: true,
			children: [
				{
					icon: <People style={{fontSize: 24}}/>,
					text: 'system.users',
					to: Router.appUsers,
                    permissionName: "UserList"
				},
				{
					icon: <Business style={{fontSize: 24}}/>,
					text: 'system.companies',
					to: Router.appCompanies,
                    permissionName: "AllCompanies"
				},
				{
					icon: <RecentActors style={{fontSize: 24}}/>,
					text: 'system.companyWorkers',
					to: Router.appCompanyWorker,
                    permissionName: "GetWorkers"
				},
				{
					icon: <Description style={{fontSize: 24}}/>,
					text: 'system.contract',
					to: Router.appContract,
                    permissionName: "ContractList"
				},
			]
		},		
	];

	const identityRoutes = [
		{
			icon: <Tune style={{fontSize: 24}}/>,
			text: 'system.title',
			nested: true,
			parent: 'parent3',
			to: to,
            permissionName: "ability.can('CreateRole') || ability.can('UpdateRole') || ability.can('CreateForm') || ability.can('UpdateForm') || ability.can('DeleteForm') || ability.can('UpdateWorkflowStatusStandard') || ability.can('CreateWorkflow') || ability.can('UpdateWorkflow') || ability.can('UpdatePasswordPolicy') || ability.can('LogList') || ability.can('CreateList') || ability.can('UpdateList') || ability.can('UpdateInactivityTime')",
			customPermission: true,
            children: [
				{
					icon: <Security style={{fontSize: 24}}/>,
					text: 'system.rolesAndPermissions',
					to: Router.appPermissions,
                    permissionName: "CreateRole"
				},
				{
					icon: <ManageHistory style={{fontSize: 24}}/>,
					text: 'workflowManagement',
					to: Router.appWorkflowManagement,
                    permissionName: "CreateWorkflow"
				},
				{
					icon: <ViewComfy style={{fontSize: 24}}/>,
					text: 'formsv2',
					to: Router.appFormManagementV2,
                    permissionName: "CreateForm"
				},
				{
					icon: <VideoSettings style={{fontSize: 24}}/>,
					text: 'standardStatusManagement',
					to: Router.appStandardWorkflowManagement,
                    permissionName: "UpdateWorkflowStatusStandard"
				},
				{
					icon: <Settings style={{fontSize: 24}}/>,
					text: 'system.settings',
					to: Router.appSettings,
                    permissionName: "ability.can('CreateWorkflow') || ability.can('UpdateWorkflow') || ability.can('UpdatePasswordPolicy') || ability.can('UpdateInactivityTime') || ability.can('UpdateAppearance')",
                    customPermission: true,
				},
				{	icon: <AssignmentIcon style={{fontSize: 24}}/>,
					text: 'system.logs',
					to: Router.applogs,
                    permissionName: "LogList"
				},
				{
					icon: <ListIcon style={{fontSize: 24}}/>,
					text: 'lists',
					to: Router.appLists,
                    permissionName: "CreateList"
				},
			]
		},
	]

	useEffect(() => {
        loadWorkflows();
        setRoutes([]);

	}, [])

	async function loadWorkflows(params) {
		let routesAux = [];
		let response = await workflowApi.listAllParentList(params);
		let result = check(response);
	
		if (result.status) {
			response.data.sort((a,b) => a.order > b.order);
			response.data.map(obj => {
				let el = {
					icon: <CustomIcon baseClassName="material-icons-outlined">{obj.icon || ''}</CustomIcon>,
					text: obj.name,
					to: obj.children === null && Router.appWorkflow.replace(':id', obj.id),
                    isStatic: true,
					nested: obj.nested || null,
					parent: obj.parent || null,
                    permissionName: "WorkflowList",
					children: obj.children || [],
				};
				routesAux.push(el);
			});
		};

		let parent = [
			{
				icon: <PendingActions style={{fontSize: 24}}/>,
				text: 'myWork',
				to: Router.appMyWork,
				permissionName: "GetNotifications"
			},
			{
				icon: <AccountTree style={{fontSize: 24}}/>,
				text: 'workflow.title',
				nested: true,
				to: to,
				parent: 'parent1',
				children: routesAux,
				permissionName: "WorkflowList"
			},
			{
				icon: <Map style={{fontSize: 24}}/>,
				text: 'plotPlans',
				to: Router.appPlotPlans,
				permissionName: "PlotPlanList"
			},
			{
				icon: <Assessment style={{fontSize: 24}}/>,
				text: 'dashboard.title',
				nested: true,
				to: to,
				parent: 'parent4',
				permissionName: "GetDashboard",
				children: [
					{
						icon: <DonutLargeIcon style={{fontSize: 24}}/>,
						text: 'dashboard.general',
						to: Router.appDashboardReport,
                    	permissionName: "GetDashboard"
					},
					{
						icon: <QueryStatsIcon style={{fontSize: 24}}/>,
						text: 'dashboard.deviations',
						to: Router.appDeviationsReport,
                    	permissionName: "GetDashboard"
					}
				],
			},
   
		]
		
		setRoutes(parent.concat(fixedRoutes.concat(identityRoutes)));
	};

	function handleOpen(item) {
		setOpenItem({...openItem, [item.parent]: !openItem[item.parent]})
	}

  	return (
		<CustomDrawer
            open={open} 
            variant={isMdUp ? "permanent" : "temporary"} 
            classes={{paper: {width: 250}}} 
            anchor="left"
            onClose={() => setOpen(false)}
        >
			<DrawerHeader>
				<IconButton onClick={onClose}>
					<ChevronLeft fontSize="large" style={{color: '#ccc'}}/>
				</IconButton>
			</DrawerHeader>
			<List style={{marginTop: 25}}>
				{routes.map((item, index) => (
                    <>
                    {(item.customPermission ? eval(item.permissionName) : ability.can(item.permissionName)) &&
                        <Fragment key={"item-" + index}>
                            <Item component={item.to ? LinkBehavior : null} to={item.to} 
                                onClick={() => item.nested ? handleOpen(item) : null}
                                selected={item.to === location.pathname}
                            >
                                <Tooltip title={item.isStatic ? item.text : t(`sidebar.${item.text}`)}>
                                    <ItemIcon>{item.icon}</ItemIcon>
                                </Tooltip>
                                {open && <ItemText primary={item.isStatic ? item.text : t(`sidebar.${item.text}`)}/>}
                                
                                {item.nested &&
                                    <Fragment>
                                        {openItem[item.parent] ? <ExpandLess /> : <ExpandMore />}
                                    </Fragment>
                                }
                            </Item>
                            {item.nested &&
                                <Collapse in={openItem[item.parent]} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {item.children?.map((child) => (
                                            <>
												{(child.customPermission ? eval(child.permissionName) : ability.can(child.permissionName)) &&
													<Item key={child.to + "-child"} 
														component={LinkBehavior} to={child.to}
														onClick={() => item.nested ? handleOpen(child) : null}
														selected={
															child.to === location.pathname || 
															child.to === `${location.pathname?.split('/', 3)?.join('/')}`
														}
														sx={{ pl: 2 }}
													>
														<Tooltip title={child.isStatic ? child.text : t(`sidebar.${child.text}`)}>
															<ItemIcon>{child.icon}</ItemIcon>
														</Tooltip>
														{open && <ItemText primary={child.isStatic ? child.text : t(`sidebar.${child.text}`)}/>}

														{child.nested &&
                                    						<Fragment>
                                        						{openItem[child.parent] ? <ExpandLess /> : <ExpandMore />}
                                    						</Fragment>
                                						}
													</Item>
												}
												{child.nested &&
													<Collapse in={openItem[child.parent]} timeout='auto' unmountOnExit>
														<List component='div' disablePadding>
															{child.children?.map((el, index) => (
																<Item key={`${index}-child`}
																	component={LinkBehavior} to={Router.appWorkflow.replace(':id', el.id)}
																	selected={
																		Router.appWorkflow.replace(':id', el.id) === location.pathname || 
																		Router.appWorkflow.replace(':id', el.id) === `${location.pathname?.split('/', 3)?.join('/')}`
																	}
																	sx={{ pl: 4, gap: '0.5rem' }}
																>
																	<Tooltip title={el.name}>
																		<CustomIcon baseClassName="material-icons-outlined">{el.icon}</CustomIcon>
																	</Tooltip>
																	{open && <ItemText primary={el.name}/>}

																</Item>
															))}
														</List>

													</Collapse>
												}
											</>

                                        ))}
                                    </List>                                
                                </Collapse>
                            }
                        </Fragment>
                    }
                    </>
				))}
			</List>
		</CustomDrawer>
    );
}
