import React from 'react';
import { Box, Checkbox, Chip, Divider, FormControl, FormControlLabel, Paper } from '@mui/material';
import { Input, CustomAutocomplete } from './styles';

function CheckElements(props) {
    const { paperProps, children, categories, handleSelectAllLines, handleSelectAllStations, selectAllLines, selectAllStations, options } = props;

    const isAnyOptionDisabled = options.some((opt) => opt.disabled);

    return (
        <Paper {...paperProps}>
            {categories.map((el) => (
                <Box
                    onMouseDown={(e) => e.preventDefault()}
                    pl={1.5}
                    py={0.5}
                    key={el.id}
                >
                    <FormControlLabel
                        label={el.selectText}
                        onClick={(e) => {
                            e.preventDefault();
                            if (!isAnyOptionDisabled) {
                                if (el.name === 'Sistema') handleSelectAllLines();
                                else handleSelectAllStations();
                            }
                        }}
                        control={
                            <Checkbox checked={el.name === 'Sistema' ? selectAllLines : selectAllStations}/>
                        }
                        disabled={isAnyOptionDisabled}
                    />
                </Box>
            ))}
            <Divider />
            {children}
        </Paper>
    )
}

export const SelectContracts = ({
    name, label, placeholder, onChange, value, options, categories, keyValue, hasError, messageError,
    disabled=false, size, width, selectedPlants, increaseHeight, disableCloseOnSelect, limitTags
}) => {
    
    const [selectAllLines, setSelectAllLines] = React.useState(false);
    const [selectAllStations, setSelectAllStations] = React.useState(false);

    React.useEffect(() => {
        setSelectAllLines(checkSelectedElements(selectedPlants, handleCheck(true, 'Sistema')) || false)
        setSelectAllStations(checkSelectedElements(selectedPlants, handleCheck(true, 'Estación')) || false)
    }, [options])

    function checkSelectedElements(baseArray, array) {
        if (baseArray?.length === 0 || array.length === 0) return false
        return array.every(element => baseArray?.some(item => item.name === element.name));
    }

    const handleCheck = (marked, type) => {
        if (marked) return options?.filter((el) => el.category?.name === type);
        else return value.filter((el) => el.category?.name !== type);
    }

    function removeDuplicates(arraySelected) {
        let array = [...arraySelected, ...value]
        let uniqueValues = []
        return array.filter((el) => {
            const isDuplicate = uniqueValues.map((el) => el.name).includes(el.name)
            if (!isDuplicate) {
                uniqueValues.push(el)
                return true
            }
            return false
        })
    }

    const handleSelectAllLines = () => {
        let newArray = removeDuplicates(handleCheck(true, "Sistema"))
        setSelectAllLines((prev) => {
            if (!prev) onChange(newArray, name);
            else onChange(handleCheck(false, "Sistema"), name);
            return !prev;
        });
    };

    const handleSelectAllStations = () => {
        let newArray = removeDuplicates(handleCheck(true, "Estación"))
        setSelectAllStations((prev) => {
            if (!prev) onChange(newArray, name);
            else onChange(handleCheck(false, "Estación"), name);
            return !prev;
        });
    };

    return (
        <FormControl fullWidth>
            <CustomAutocomplete
                id="combo-box-demo"
                disabled={disabled}
                disableClearable
                disableCloseOnSelect={disableCloseOnSelect || true}
                defaultValue={value}
                value={value || null}
                options={options}
                getOptionLabel={(option) => keyValue ? option?.[keyValue] || "" : option}
                getOptionDisabled={(opt) => opt?.disabled ?? false}
                sx={{ width: width || '100%' }}
                multiple={true}
                increaseHeight={increaseHeight}
                limitTags={limitTags ? limitTags : -1}
                onChange={(_, value, reason) => {
                    if (reason === "clear" || reason === "removeOption") {
                        if (!checkSelectedElements(value, handleCheck(true, 'Estación'))) setSelectAllStations(false)
                        if (!checkSelectedElements(value, handleCheck(true, 'Sistema'))) setSelectAllLines(false)
                    }
                    if (reason === "selectOption") {
                        if (checkSelectedElements(value, handleCheck(true, 'Estación'))) setSelectAllStations(true)
                        if (checkSelectedElements(value, handleCheck(true, 'Sistema'))) setSelectAllLines(true)
                    }
            
                    if (onChange !== undefined) onChange(value, name);
                }}
                renderInput={
                    (params) => <Input {...params} 
                        label={label} 
                        placeholder={placeholder}
                        error={hasError} 
                        helperText={messageError} 
                        size={size || "medium"}
                    />
                }
                ListboxProps={{ style: { maxHeight: '200px' }}}
                renderTags={(value, getTagProps) => {
                    const numTags = value.length;
                    let maxSize = limitTags ? limitTags : numTags;
                    return (
                        <>
                            {value.slice(0, maxSize).map((option, index) => (
                                <Chip {...getTagProps({ index })} key={index} label={option.name} 
                                disabled={option?.disabled ?? false}/>
                            ))}
                
                            {numTags > limitTags && ` +${numTags - limitTags}`}
                        </>
                    );
                }}
                slotProps={{
                    paper: {
                        categories: categories,
                        handleSelectAllLines: handleSelectAllLines,
                        handleSelectAllStations: handleSelectAllStations,
                        selectAllLines: selectAllLines,
                        selectAllStations: selectAllStations,
                        options: options
                    }
                }}
                PaperComponent={CheckElements}
            />
        </FormControl>
    )
}

