import React, { useState, useRef } from 'react'
import { Link } from 'react-router-dom';
import Recaptcha from 'react-google-recaptcha';
import Router from '../../../../router';
import { Button } from '@mui/material';

import { SecurityApi } from '../../../../services/SecurityApi';
import { useMsal } from "@azure/msal-react";

import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { loginRequest } from "../../../../utils/msal/authConfig"


// Styles
import {
  Container,
  Form,
  Title,
  Subtitle,
  SectionRow,
  SectionInput,
  ContainerInput,
  Input,
  Span,
  SpanLink,
  SectionBtn,
  LabelCustom
} from './styles'

import { useTranslation } from 'react-i18next'
import { ColorsContext } from "../../../../Context/ColorsContext"

export const LoginForm = ({
  form,
  setForm,
  messageError,
  handleChange,
  handleSubmit,
  isLoading,
  recaptchaRef,
  setMessageError
}) => {

  const { instance } = useMsal();
  const { t } = useTranslation();
  const { colors } = React.useContext(ColorsContext);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showFormPassword, setShowFormPassword] = React.useState(false);
  const securityApi = new SecurityApi();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
    event.persist();
  
    setTimeout(() => {
      handleClickShowPassword();
    }, 0);
  };

  const handleRecaptchaVerify = (response) => {
    setForm({ ...form, reCaptchaToken: response })
  };

  const getLoginIcon = () => {

    if (colors?.loginIconUrl === null || colors?.loginIconUrl === undefined || colors?.loginIconUrl === "")
    {
      return (
        <img src='cenit-logo.png' width={300} alt='logo' />
      )
    }

    return (colors?.loginIconUrl !== null && colors?.loginIconUrl !== "" && colors?.loginIconUrl !== undefined) ? 
      <img src={`${Router.apiBaseUrl}/${colors.loginIconUrl.toString()}`} width={300} alt='logo' />
      : null
  }

  
  const checkLogin = async() => {
    if (form.username === null || form.username === undefined || form.username === "") {
      setMessageError(t('password.validations.mandatory'))
      return;
    }
    securityApi.checkLogin(form).then(async(resp) => {
      if (resp?.status=== 200) {
        let isAdUser = resp?.data?.data?.isADUser ?? false
        setMessageError('')
        if (isAdUser)
        {
          localStorage.setItem("userLogin", form.username)
          loginRequest.redirectStartPage = Router.appSigninAzure
          instance.loginRedirect(loginRequest).catch(e => {
            // console.log(e);
          });
        }
        else {
          setShowFormPassword(true)
        }
      } 
      else {
        setMessageError(resp?.response?.data?.description || resp?.response?.data?.data?.description || '')
      }
    })
  }

  return (
    <Container>
      <Form>
        {getLoginIcon()}
        <Title>{(colors?.title !== null && colors?.title !== "" && colors?.title !== undefined ? colors.title : t('login.titleApp'))}</Title>
        <Subtitle>{t('login.title')}</Subtitle>
        {!showFormPassword && 
          <SectionRow>
            <SectionInput>
              <LabelCustom>{t('login.user')}:</LabelCustom>
              <ContainerInput>
                <Input
                  name='username'
                  onChange={(e) => handleChange(e, 'username')}
                  required
                />
              </ContainerInput>
            </SectionInput>
          </SectionRow>
        }
        {showFormPassword && 
          <SectionRow>
            <SectionInput bottom>
              <LabelCustom>{t('login.password')}:</LabelCustom>
              <ContainerInput>
                <Input
                  name='password'
                  onChange={(e) => handleChange(e, 'password')}
                  required
                  type={showPassword ? 'text' : 'password'}
                  />
                <InputAdornment>
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              </ContainerInput>
            </SectionInput>
          </SectionRow>
        }
        {process.env.REACT_APP_CAPTCHA !== undefined && showFormPassword &&
          <SectionRow>
            <div style={{ margin: '0 auto' }}>
              <Recaptcha ref={recaptchaRef} sitekey={process.env.REACT_APP_CAPTCHA} onChange={handleRecaptchaVerify} />
            </div>
          </SectionRow>
        }
        <SectionRow>
          <SectionInput>
            <Span>{messageError}</Span>
          </SectionInput>
        </SectionRow>
        {!showFormPassword && 
          <SectionBtn>
            <Button
              variant="contained"
              sx={{
                color: colors.white,
                backgroundColor: colors.buttonActionColor,
                cursor: 'pointer',
                padding: '.5em 2em',
                borderRadius: '5px',
                display: 'inline-block',
                justifyContent: 'center',
                transition: 'all .3s ease-in-out',
                width: '50%',
                "&.MuiButton-outlined.Mui-disabled": {
                  background: colors.grayGraphic,
                  cursor: 'not-allowed',
                },
                "&:hover": {
                  backgroundColor: colors.buttonActionColor,
                  opacity: 0.8,
                  color: colors.white,
                  transition: 'all .3s ease',
                },
                '&:active': {
                  transition: 'all .3s ease',
                  opacity: '1',
                },
              }}
              onClick={() => checkLogin()}
              disabled={isLoading}
            >
              {t('login.stepper.next')}
            </Button>
          </SectionBtn>
        }
        {showFormPassword && 
          <SectionBtn double>
            <div>
              <Button
                variant="contained"
                sx={{
                  color: colors.white,
                  backgroundColor: colors.buttonActionColor,
                  cursor: 'pointer',
                  padding: '.5em 2em',
                  borderRadius: '5px',
                  display: 'inline-block',
                  justifyContent: 'center',
                  transition: 'all .3s ease-in-out',
                  margin: '2px 0',
                  width: '40%',
                  "&.MuiButton-outlined.Mui-disabled": {
                    background: colors.grayGraphic,
                    cursor: 'not-allowed',
                  },
                  "&:hover": {
                    backgroundColor: colors.buttonActionColor,
                    opacity: 0.8,
                    color: colors.white,
                    transition: 'all .3s ease',
                  },
                  '&:active': {
                    transition: 'all .3s ease',
                    opacity: '1',
                  },
                }}
                onClick={() => handleSubmit()}
                disabled={isLoading || form.reCaptchaToken === null}
              >
                {t('login.login')}
              </Button>
            </div>
            <div>
              <Button
                variant="contained"
                sx={{
                  color: colors.buttonActionColor,
                  backgroundColor: colors.white,
                  border: `1px solid ${colors.buttonActionColor}`,
                  cursor: 'pointer',
                  padding: '.5em 2em',
                  borderRadius: '5px',
                  display: 'inline-block',
                  justifyContent: 'center',
                  transition: 'all .3s ease-in-out',
                  margin: '2px 0',
                  width: '40%',
                  "&.MuiButton-outlined.Mui-disabled": {
                    background: colors.grayGraphic,
                    cursor: 'not-allowed',
                  },
                  "&:hover": {
                    backgroundColor: colors.white,
                    opacity: 0.8,
                    color: colors.buttonActionColor,
                    transition: 'all .3s ease',
                  },
                  '&:active': {
                    transition: 'all .3s ease',
                    opacity: '1',
                  },
                }}
                onClick={() => setShowFormPassword(false)}
                disabled={isLoading}
              >
                {t('login.stepper.back')}
              </Button>
            </div>
          </SectionBtn>
        }
        {!showFormPassword && 
          <SectionRow>
            <Link to={Router.appPasswordRecovery} state={{ user: form.username }}>
              <SpanLink>{t('login.forgotPassword')}</SpanLink>
            </Link>
          </SectionRow>
        }
      </Form>
    </Container>
  )
}
