import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import { useColors } from "../../Context/ColorsContext"
import { MediaQuerys } from "../../styles/GlobalStyle";

const { mobile, tablet, desktop, desktoplarge } = MediaQuerys;

export const DownloadButton = styled(Button)(() => {
    const { colors } = useColors();
    return {
        height: '100%',
        width: '100%',
        padding: "0 20px",
        backgroundColor: colors.buttonActionColor,
        color: colors.white,
        "&:hover": {
            backgroundColor: colors.buttonActionColor,
            opacity: 0.9,
            color: colors.white
        }
    }
})

export const ContainerButtonCreate = styled('div')(() => ({
	width: 170,
	height: 40,
	[desktop]: {
		width: "120px",
		height: "30px",
		"& button": {
			padding: "14px 12px",
			fontSize: "0.55rem",
		},
	},
	[tablet]: {
		width: "110px",
		height: 20,
		"& button": {
			fontSize: "7px",
		},
	},
}))