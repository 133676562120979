import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

// Utils
import { FormatDate } from '../../../../utils/formatDate';
import { useTranslation } from 'react-i18next'

export const SearchBarRoles = ({
    setFilteredRequest,
    rows,
}) => {

    const { t } = useTranslation();
    const [search, setSearch] = React.useState('')
    const {format} = new FormatDate();

    function handleSearch(e) {
        setSearch(e)
        filterSearch(e)
    }

    const filterSearch = (data) => {
        let searchResults = rows.filter(e => {
            e.isEnabledStr = e.isActive ? t('system.active') : t('system.inactive');
            if (e.id.toString().toLowerCase().includes(data.toLowerCase())
                || e.name?.toString().toLowerCase().includes(data.toLowerCase())
                || e.description?.toString().toLowerCase().includes(data.toLowerCase())
                || e.createdAt?.toString().toLowerCase().includes(data.toLowerCase())
                || e.isEnabledStr.toString().toLowerCase().includes(data.toLowerCase())
            ) {
                return e
            }
        })
        setFilteredRequest(searchResults)
    }

    React.useEffect(() => {
        setFilteredRequest(rows);
        handleSearch(search);
    }, [rows])

    return (
        <Paper
            component="form"
            sx={{ paddingLeft: '10px', display: 'flex', alignItems: 'center', width: '100%', height: '100%' }}
        >
            <InputBase
                type='text'
                name='search'
                value={search}
                onChange={e => handleSearch(e.target.value)}
                autoComplete='off'

                sx={{ display: 'flex', flex: 1, ml: 1, }}
                placeholder={t('system.permissions.searchBar')}
                inputProps={{ 'aria-label': 'search google maps' }}
            />
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search" disabled>
                <SearchIcon />
            </IconButton>
        </Paper>
    );
}