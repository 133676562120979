import { useTranslation } from "react-i18next";

export const useTranslateType = ( data ) => {

    const { t } = useTranslation();
    
    const translations = {
        'fill_form': t('workflowManagement.flowState.status.fillForm'),
        'check_form': t('workflowManagement.flowState.status.checkForm'),
        'check_form_credentials': t('workflowManagement.flowState.status.checkFormCredentials'),
        'update_status': t('workflowManagement.flowState.status.updateStatus'),
        'edit_form': t('workflowManagement.flowState.status.editForm')
      };
    
      return translations[data] || ''
}