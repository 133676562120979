import { Box, FormControlLabel, Tabs, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useColors } from "../../../../Context/ColorsContext"
import LoopIcon from '@mui/icons-material/Loop';
import { MediaQuerys } from "../../../../styles/GlobalStyle";

const { mobile, tablet, desktop } = MediaQuerys;

export const Label = styled('label')(({nextline, istitle}) => {
    const { colors } = useColors();
    return {
        fontWeight:'bold',
        paddingRight:'5px',
        display: nextline ? 'block' : 'inherit',
        color: istitle ? colors.blue : colors.blackFont,
        paddingLeft: istitle ? 10 : 0,
        textTransform: istitle ? 'uppercase' : 'none',
        [desktop]: {
            fontSize: '0.85rem'
        }
    }
})

export const CustomTabs = styled(Tabs)(() => {
    const { colors } = useColors();
    return {
        '&.MuiButtonBase-root-MuiTab-root .Mui-selected': {
            color: `${colors.green} !important`
        },
        '.Mui-selected': {
            color: `${colors.green} !important`
        },
        '.MuiButtonBase-root': {
            fontWeight: 'bold',
            fontSize: '1.2rem',
            fontFamily: 'Montserrat, sans-serif',
            textTransform: 'inherit'
        },
    }
});

export const FormLabel = styled(FormControlLabel)(() => ({
    '.MuiTypography-root': {
        fontWeight: 'bold',
        fontSize: '1.2rem'
    }
}));

export const IconContainer = styled('div')(() => ({
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    width: '100%',
    padding: 10
}));

export const IconLoop = styled(LoopIcon)(() => {
    const { colors } = useColors();
    return {
        fontSize: 50,
        color: colors.grayFont
    }
});

export const Input = styled(TextField)(() => {
    const { colors } = useColors();
    return {
        width: '100%',
        '.MuiInputBase-root': {
            background: colors.white,
            borderRadius: 6,
            fontSize: 13,
            padding: 0
        }
    }
})

export const LineTop = styled('div')(() => {
    const { colors } = useColors();
    return {
        paddingBottom: 10,
        borderTop: `1px solid ${colors.gray}`
    }
})

export const LineBottom = styled('div')(() => {
    const { colors } = useColors();
    return {
        paddingTop: 10,
        borderBottom: `1px solid ${colors.gray}`
    }
})

export const ContainerInput = styled('div')(({ isFull }) => {
    return {
        display: 'flex',
        flexDirection: 'column',
        width: isFull ? '100%' : '50%',
    }
})

export const ContainerGroup = styled('div')(({ isFirsts }) => {
    return {
        display: 'flex',
        gap: '0.75rem',
        flexDirection: 'row',
        width: '100%',
        marginTop: isFirsts ? '0px' : '10px'
    }
})

export const ContainerOnly = styled('div')(() => {
    return {
        display: 'flex',
        marginTop: '10px',
        justifyContent: 'flex-start',
        width: '100%',
    }
})

export const ContainerContent = styled('div')(() => {
    return {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',

    }
})

export const ContainerBox = styled(Box)(({theme}) => {
    return {
        padding: 20,
        maxWidth: 800,
        // [theme.breakpoints.up('md')]: {
        //     minWidth: 700
        // }
    }
})