// i18n.js

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import EnLang from "./languages/en.json"
import EsLang from "./languages/es.json"
import PtLang from "./languages/pt.json"

const resources = {
    en: EnLang,
    es: EsLang,
    pt: PtLang
};

i18n.use(initReactI18next).init({
  resources,
  lng: window.localStorage.getItem('lang') || "es",
  fallbackLng: "es",
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
