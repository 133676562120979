import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

// Utils
import { useTranslation } from 'react-i18next'

export const SearchBarWorkflow = ({
    handleChangeFilters,
    handleFilters,
    clearFilter,
    workflow
}) => {
    const { t } = useTranslation();
    const [search, setSearch] = React.useState('')

    function handleSearch(e) {
        setSearch(e)
        handleChangeFilters({target: {value: e}}, "filter", "text")
    }

    React.useEffect(() => {
        handleSearch("")
    },[workflow])

    React.useEffect(() => {
        handleSearch("")
    },[clearFilter?.current])

    return (
        <Paper
            component="form"
            sx={{ paddingLeft: '10px', display: 'flex', alignItems: 'center', width: '100%', height: '100%' }}
        >
            <InputBase
                type='text'
                name='search'
                value={search}
                autoComplete='off'
                onChange={e => handleSearch(e.target.value)}
                onKeyDown={e => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    handleFilters()
                  }
                }}

                sx={{ display: 'flex', flex: 1, ml: 1, }}
                placeholder={t('common.searchBars.workflow')}
                inputProps={{ 'aria-label': 'search google maps' }}
            />
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search" disabled>
                <SearchIcon />
            </IconButton>
        </Paper>
    );
}