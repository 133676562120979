import React, { useEffect, useState } from 'react';
import { PageTitle } from '../../components/PageTitle';
import { Card, Grid, Button, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import Router from '../../router';
import { WorkflowApi } from '../../services/WorkflowApi';
import { WorkflowStatusApi } from '../../services/WorkflowStatusApi';
import { useLocation } from "react-router-dom";
import { ShowDialog } from '../../components/ShowDialog';
import {
    SectionPageTitle,
    Spacer,
    SelectInput,
    Input,
    Content,
    ContainerInputs,
    Form,
    ContainerChecks,
    ContainerInputMedium,
    ContainerInputSecond,
    ContainerInputLargeFirts,
    ContainerInputSmallFirts,
    CustomControlLabel,
    ContainerGroup,
    ContainerSelectInput
} from './styles';
import { Container, SearchButton, } from '../styles';
import { useNavigate } from 'react-router-dom';
import { LoadingProgress } from '../../components/LoadingProgress';
import { toast } from 'react-toastify';
import { Add, ArrowBack, Edit, ManageHistory } from '@mui/icons-material';
import TableWorkflowRelation from '../../components/atoms/tables/TableWorkflowRelation';
import { FormatDate } from '../../utils/formatDate';
import { CheckResponse } from '../../utils/checkResponse';
import { useTranslation } from 'react-i18next'
import { ColorsContext } from "../../Context/ColorsContext"

// Permissions
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../Context/PermissionsContext'

export const WorkflowManagementDetail = (props) => {

    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        id: '',
        name: '',
        queueSend: '',
        queueReceive: '',
        icon: '',
        prefix: null,
        initialWorkflowStatusId: null,
        relations: [],
        newRelations: [],
        deletedRelations: [],
        hasTitle: false,
        hasValidity: false,
        hasPlotPlan: false
    });

    const location = useLocation();
    const navigate = useNavigate();
    const { colors } = React.useContext(ColorsContext);
    const ability = useAbility(AbilityContext);

    const { row } = location.state;

    const [isModifing, setIsModifing] = useState(false);

    const [optionStatus, setOptionStatus] = useState([]);
    const [workflows, setWorkflows] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const workflowApi = new WorkflowApi();
    const workflowStatusApi = new WorkflowStatusApi();
    const { check } = new CheckResponse();
    const [selectedWokflow, setSelectedWorkflow] = useState(null);
    const [loading, setLoading] = React.useState(false)
    const cleanError = { hasError: false, errorMessage: '' }
    const [relations, setRelations] = useState([]);
    const [filteredRequest, setFilteredRequest] = React.useState([]);
    const [dialog, setDialog] = useState({
        status: false,
        title: '',
        message: '',
        type: null
    });
    const { format } = new FormatDate();

    const [errorForm, setErrorForm] = useState({
        id: { hasError: false, errorMessage: '' },
        name: { hasError: false, errorMessage: '' },
        queueSend: { hasError: false, errorMessage: '' },
        queueReceive: { hasError: false, errorMessage: '' },
        icon: { hasError: false, errorMessage: '' },
        prefix: { hasError: false, errorMessage: '' }
    })

    let [newRelations, setNewRelations] = useState([]);
    let [deletedRelations, setDeletedRelations] = useState([]);

    useEffect(() => {
        setLoading(true);
        if (row.id != undefined) {
            setFormData({ ...row });
            loadOptionsStatus();
            setIsModifing(true);
            loadInitialState();
            loadRelations(row.id);
        }
        loadWorkflows();
        setLoading(false);
    }, [])

    async function submit(event) {
        let formDataCopy = {
            ...formData,
            newRelations: newRelations,
            deletedRelations: deletedRelations,
            relations: newRelations
        }
        event.preventDefault();
        if (validationError()) {
            return;
        } else {
            if (!isModifing) {
                let response = await workflowApi.create(formDataCopy);
                let result = check(response)

                if (result.status) {
                    navigate(Router.appWorkflowManagement);
                }
                else {
                    toast.error(() =>
                        <div>{t('workflowManagement.messages.errors.creation')}<br />
                            {result.errors}
                        </div>
                    );
                }

            } else {
                let response = await workflowApi.edit(formDataCopy);
                let result = check(response)

                if (result.status) {
                    navigate(Router.appWorkflowManagement);
                } else {
                    toast.error(() =>
                        <div>{t('workflowManagement.messages.errors.edit')}<br />
                            {result.errors}
                        </div>
                    );
                }
            }
        }

    }

    async function loadInitialState() {
        if (ability.can("GetWorkflowStatus")) {
            let response = await workflowStatusApi.detail(row.initialWorkflowStatusId);
            if (response.status === 200) {
                setSelectedStatus({ id: response.data.id, title: response.data.title })
            } else {
                setSelectedStatus(null);
            }
        }
    }

    async function loadRelations(id) {
        if (ability.can("RelationWorkflow")) {
            let response = await workflowApi.getRelations(id);
            if (response.status === 200) {
                setRelations(response.data);
            }
        }
    }

    async function loadOptionsStatus() {
        let response = await workflowStatusApi.listAllByWorkflow(row.id);
        if (response.status === 200) {
            setOptionStatus(response.data);
        }
    }

    async function loadWorkflows() {
        let response = await workflowApi.list()
        if (response.status === 200) {
            setWorkflows(response.data);
        }
    }

    function handleChangeSelected(value) {
        setFormData({ ...formData, initialWorkflowStatusId: value.id });
        setSelectedStatus(value);
    }

    function handleDelete(row, index) {
        let relationsCopy = [...relations];
        let newRelationsCopy = [...newRelations];
        let deletedRelationsCopy = [...deletedRelations]

        relationsCopy.splice(index, 1);
        setRelations(relationsCopy);
        let indexRelation = newRelationsCopy.indexOf(row.id);
        newRelationsCopy.splice(indexRelation, 1);
        deletedRelationsCopy.push(row.id);

        if (row) {
            setDialog({
                status: true,
                title: t("workflowManagement.messages.editWorkflows.title"),
                message: t("workflowManagement.messages.editWorkflows.deleteRelations", { 
                  relation: row.name,
                  workflow: formData.name 
                }),
                type: null,
            });
            setNewRelations(newRelationsCopy);
            setDeletedRelations(deletedRelationsCopy);
        }

    }

    function handleSelectedWorkflow(value) {
        setSelectedWorkflow(value);
    }

    function handleAddRelation() {
        let relationsCopy = [...relations];
        let newRelationsCopy = [...newRelations];
        let deletedRelationsCopy = [...deletedRelations]
        if (selectedWokflow != null) {
            let id = selectedWokflow.id;
            if (searchInRelations(relations, id)) {
                toast.error(t('workflowManagement.messages.errors.duplicatedRelation'));
            } else {
                relationsCopy.push(selectedWokflow);
                setRelations(relationsCopy);
                let indexRelation = newRelationsCopy.indexOf(id);
                if (indexRelation == -1) {
                    newRelationsCopy.push(id);
                    setNewRelations(newRelationsCopy);
                    let indexDeleted = deletedRelationsCopy.indexOf(id);
                    if (indexDeleted != -1) {
                        deletedRelationsCopy.splice(indexDeleted, 1);
                        setDeletedRelations(deletedRelationsCopy);
                    }
                } else {
                    toast.error(t('workflowManagement.messages.errors.duplicatedRelation'));
                }
            }
        }
        setSelectedWorkflow(null);
    }

    function searchInRelations(array, id) {
        let exist = false;
        let relation = array.find((element, index) => {
            return element.id == id
        })

        if (relation) {
            exist = true;
        }

        return exist;
    }

    function validationError() {
        let requiredError = {
            hasError: true,
            errorMessage: t('workflowManagement.messages.errors.mandatory')
        }

        let errorFormCopy = { ...errorForm }
        if (!formData.name) {
            errorFormCopy.name = requiredError;
        } else {
            errorFormCopy.name = cleanError;
        }

        if (!formData.queueReceive) {
            errorFormCopy.queueReceive = requiredError;
        } else {
            errorFormCopy.queueReceive = cleanError;
        }

        if (!formData.queueSend) {
            errorFormCopy.queueSend = requiredError;
        } else {
            errorFormCopy.queueSend = cleanError;
        }

        if (!formData.icon) {
            errorFormCopy.icon = requiredError;
        } else {
            errorFormCopy.icon = cleanError;
        }


        setErrorForm(errorFormCopy);

        if (errorFormCopy.name.hasError || errorFormCopy.queueReceive.hasError || errorFormCopy.icon.hasError || errorFormCopy.queueSend.hasError) {
            return true;
        }

        return false;
    }

    const handleOpenDialog = async (row, index) => {
        const { name } = formData
        setDialog({
            status: true,
            title: t("forms.formManagement.messages.deletedForm.confirm"),
            message: t("workflowManagement.messages.editWorkflows.confirmMessage", {
                relation: row.name,
                workflow: name
            }),
            type: "confirmation",
            data: row,
            index: index
        });
    }

    const handleCloseDialog = async (value) => {
        const { data, index } = dialog;
        if (value) {
            setDialog({ ...dialog, status: false });
            await handleDelete(data, index);
        } else {
            setDialog({ status: false });
        }
    }

    return (
        <Container open={props.open}>
            <SectionPageTitle>
                <PageTitle title="" isbutton={true} navigate={Router.appWorkflowManagement} icon={<ArrowBack />} />
                {isModifing &&
                    <PageTitle title={t('workflowManagement.editFlows')} icon={<ManageHistory />} />
                }
                {!isModifing &&
                    <PageTitle title={t('workflowManagement.createFlows')} icon={<ManageHistory />} />
                }
            </SectionPageTitle>
            <Card sx={{ marginTop: '8px' }}>
                {loading && <LoadingProgress />}
                {!loading &&
                    <Content>
                        <Form onSubmit={submit}>
                            <ContainerInputs>
                                <ContainerGroup isFirst={true}>
                                    <ContainerInputLargeFirts>
                                        <Input
                                            placeholder={t('workflowManagement.table.name')}
                                            label={t('workflowManagement.table.name')}
                                            value={formData.name}
                                            error={errorForm.name.hasError}
                                            helperText={errorForm.name.errorMessage}
                                            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                        />
                                    </ContainerInputLargeFirts>

                                    <ContainerInputSmallFirts>
                                        <Input
                                            placeholder={t('workflowManagement.table.prefix')}
                                            label={t('workflowManagement.table.prefix')}
                                            value={formData.prefix}
                                            error={errorForm.prefix.hasError}
                                            inputProps={{ maxLength: 4 }}
                                            helperText={errorForm.prefix.errorMessage}
                                            onChange={(e) => setFormData({ ...formData, prefix: String.prototype.toUpperCase.call(e.target.value) })}
                                        />
                                    </ContainerInputSmallFirts>
                                </ContainerGroup>

                                <ContainerGroup>
                                    <ContainerInputSecond>
                                        <Input
                                            placeholder={t('workflowManagement.messageSend')}
                                            label={t('workflowManagement.queueSend')}
                                            value={formData.queueSend}
                                            error={errorForm.queueSend.hasError}
                                            helperText={errorForm.queueSend.errorMessage}
                                            onChange={(e) => setFormData({ ...formData, queueSend: e.target.value })}
                                        />
                                    </ContainerInputSecond>

                                    <ContainerInputSecond>
                                        <Input
                                            placeholder={t('workflowManagement.messageReceive')}
                                            label={t('workflowManagement.queueReceive')}
                                            value={formData.queueReceive}
                                            error={errorForm.queueReceive.hasError}
                                            helperText={errorForm.queueReceive.errorMessage}
                                            onChange={(e) => setFormData({ ...formData, queueReceive: e.target.value })}
                                        />
                                    </ContainerInputSecond>

                                    <ContainerInputMedium>
                                        <Input
                                            placeholder={t('workflowManagement.table.icon')}
                                            label={t('workflowManagement.table.icon')}
                                            value={formData.icon}
                                            error={errorForm.icon.hasError}
                                            helperText={errorForm.icon.errorMessage}
                                            onChange={(e) => setFormData({ ...formData, icon: e.target.value })}

                                        />
                                    </ContainerInputMedium>
                                </ContainerGroup>
                            </ContainerInputs>

                            <ContainerChecks>
                                {ability.can("GetWorkflowStatus") &&
                                <>
                                    {isModifing &&
                                        <ContainerSelectInput>
                                            <SelectInput
                                                id="combo-box-demo"
                                                disableClearable
                                                onChange={(_, value) => {
                                                    handleChangeSelected(value);
                                                }}
                                                value={selectedStatus}
                                                isOptionEqualToValue={(option, value) => option.id == value.id}
                                                getOptionLabel={(option) => option.title}
                                                options={optionStatus.map((obj) => { return { id: obj.id, title: obj.title } })}
                                                sx={{ width: '100%' }}
                                                renderInput={(params) => <Input {...params} label={t('workflowManagement.selectInit')} />}
                                            />
                                        </ContainerSelectInput>
                                    }
                                </>
                                }
                                <Grid item xs={3}>
                                    <FormGroup>
                                        <CustomControlLabel label={t('workflowManagement.hasTitle')}
                                            control={
                                                <Checkbox
                                                    checked={formData.hasTitle}
                                                    sx={{
                                                        color: colors.grayGraphic,
                                                        '&.Mui-checked': {
                                                            color: colors.buttonActionColor
                                                        },
                                                        '&.MuiCheckbox-indeterminate': {
                                                            color: colors.buttonActionColor
                                                        }
                                                    }}
                                                    onChange={(e) => setFormData({ ...formData, hasTitle: e.target.checked })}
                                                />
                                            } />
                                    </FormGroup>
                                </Grid>

                                <Grid item xs={3}>
                                    <FormGroup>
                                        <CustomControlLabel label={t('workflowManagement.hasValidity')}
                                            control={
                                                <Checkbox
                                                    checked={formData.hasValidity}
                                                    sx={{
                                                        color: colors.grayGraphic,
                                                        '&.Mui-checked': {
                                                            color: colors.buttonActionColor
                                                        },
                                                        '&.MuiCheckbox-indeterminate': {
                                                            color: colors.buttonActionColor
                                                        }
                                                    }}
                                                    onChange={(e) => setFormData({ ...formData, hasValidity: e.target.checked })}
                                                />
                                            } />
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormGroup>
                                        <CustomControlLabel label={t('workflowManagement.hasPlotPlans')}
                                            control={
                                                <Checkbox
                                                    checked={formData.hasPlotPlan}
                                                    sx={{
                                                        color: colors.grayGraphic,
                                                        '&.Mui-checked': {
                                                            color: colors.buttonActionColor
                                                        },
                                                        '&.MuiCheckbox-indeterminate': {
                                                            color: colors.buttonActionColor
                                                        }
                                                    }}
                                                    onChange={(e) => setFormData({ ...formData, hasPlotPlan: e.target.checked })}
                                                />
                                            } />
                                    </FormGroup>
                                </Grid>
                            </ContainerChecks>
                            <Grid item xs={12}>
                                <TableWorkflowRelation
                                    rows={relations}
                                    loading={loading}
                                    handleDelete={handleOpenDialog}
                                    workflows={workflows}
                                    handleAddRelation={handleAddRelation}
                                    handleSelectedWorkflow={handleSelectedWorkflow}
                                    filteredRequest={filteredRequest}
                                />
                            </Grid>
                            <Grid item xs={12} container direction="row" justifyContent="flex-end">
                                {!isModifing &&
                                    <SearchButton variant="contained" type="submit" isStandard={true}>{t('common.create')}</SearchButton>
                                }
                                {isModifing &&
                                    <SearchButton variant="contained" type="submit" isStandard={true}>{t('common.edit')}</SearchButton>
                                }
                            </Grid>
                        </Form>
                    </Content>
                }
            </Card>

            <ShowDialog
                openDialog={dialog.status}
                dialog={dialog}
                handleCloseDialog={handleCloseDialog}
            />
        </Container>
    );
}