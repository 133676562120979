import React, { useEffect, useCallback } from 'react'
import { toast } from 'react-toastify';

// import { Button, Card, CardContent, Grid, TextField, FormControlLabel, Switch } from '@mui/material';
import { PageTitle } from '../../components/PageTitle';
import { PasswordPolicy } from '../../components/PasswordPolicy'
import PasswordIcon from '@mui/icons-material/Password';


import { PasswordPolicyApi } from '../../services/PasswordPolicyApi';

import { Container, } from '../styles';
// Styles
import {
    SectionPageTitle,
    // TextSwitch
} from './styles'

// Utils
import { useTranslation } from 'react-i18next'

export const PasswordPolicyForm = (props) => {

    const { t } = useTranslation();
    const passwordPolicyApi = new PasswordPolicyApi();

    const actionRef = React.useRef(false)
    const [loading, setLoading] = React.useState(false)
    const [form, setForm] = React.useState({
        id: 0,
        minimumDay: 0,
        maximumDay: 0,
        isActive: true,
        passwordSecurity: true,
        passwordRule: '',
        passwordComments: '',
        passwordHistory: 0,
        passwordCharacterLimit: 0,
        passwordUsername: false,
        passwordDictionary: false,
        dictionaryPath: ''
    })
    const [error, setError] = React.useState({
        minimumDay: { hasError: false, message: '' },
        maximumDay: { hasError: false, message: '' },
        passwordRule: { hasError: false, message: '' },
        passwordComments: { hasError: false, message: '' },
        passwordHistory: { hasError: false, message: '' },
        passwordCharacterLimit: { hasError: false, message: '' },
        passwordUsername: { hasError: false, message: '' },
        dictionaryPath: { hasError: false, message: '' },
        general: { hasError: false, message: '' }
    })
    
    const resetError = () => {
        setError({
            minimumDay: { hasError: false, message: '' },
            maximumDay: { hasError: false, message: '' },
            passwordRule: { hasError: false, message: '' },
            passwordComments: { hasError: false, message: '' },
            passwordHistory: { hasError: false, message: '' },
            passwordCharacterLimit: { hasError: false, message: '' },
            passwordUsername: { hasError: false, message: '' },
            dictionaryPath: { hasError: false, message: '' },
            general: { hasError: false, message: '' }
        })
    }

    const handleChange = (event, key) => {
        setForm({
            ...form,
            [`${key}`]: event.target.value
        })
    }

    const handleState = (value) => {
        let data = form
        setForm({
            ...data,
            isActive: value
        })
    }

    const handlePasswordSecurity = (value) => {
        let data = form
        setForm({
            ...data,
            passwordSecurity: value
        })
    }

    const handlePasswordUsername = (value) => {
        let data = form
        setForm({
            ...data,
            passwordUsername: value
        })
    }

    const handlePasswordDictionary = (value) => {
        let data = form
        setForm({
            ...data,
            passwordDictionary: value,
            dictionaryPath: value ? data.dictionaryPath : ''
        })
    }

    const validateForm = () => {
        let errors = { ...error }
        let hasError = false

        if (isNaN(form.minimumDay) || (isNaN(form.minimumDay) && form.minimumDay < 0)) {
            errors.minimumDay = { hasError: true, message: t('system.passwordPolicy.validtions.biggerThan') }
            hasError = true
        }
        if (isNaN(form.maximumDay) || (isNaN(form.maximumDay) && form.maximumDay < 0)) {
            errors.maximumDay = { hasError: true, message: t('system.passwordPolicy.validations.biggerThan') }
            hasError = true
        }
        if (form.passwordSecurity && form.passwordRule === '') {
            errors.passwordRule = { hasError: true, message: t('system.passwordPolicy.validations.selectRegex') }
            hasError = true
        }
        if (form.passwordSecurity && form.passwordComments === '') {
            errors.passwordComments = { hasError: true, message: t('system.passwordPolicy.validations.passwordMessage') }
            hasError = true
        }
        if (isNaN(form.passwordHistory) || (isNaN(form.passwordHistory) && form.passwordHistory < 0)) {
            errors.passwordHistory = { hasError: true, message: t('system.passwordPolicy.validations.biggerThan') }
            hasError = true    
        }
        if (isNaN(form.passwordCharacterLimit) || (isNaN(form.passwordCharacterLimit) && form.passwordCharacterLimit < 0)) {
            errors.passwordCharacterLimit = { hasError: true, message: t('system.passwordPolicy.validations.biggerThan') }
            hasError = true    
        }
        if (form.passwordDictionary && (form.dictionaryPath === null || form.dictionaryPath === "" || form.dictionaryPath === undefined)) {
            errors.dictionaryPath = { hasError: true, message: t('system.passwordPolicy.validations.dictionaryEmpty') }
            hasError = true        
        }
        setError(errors)
        return hasError
    }

    const renderError = (message) => {
        let errors = {...error}
        errors.general = { hasError: true, message: message || t('system.errorSaving') }
        setError(errors)
    }

    const getData = () => {
        const data = { ...form }
        data.minimumDay = parseInt(data.minimumDay) || 0
        data.maximumDay = parseInt(data.maximumDay) || 0
        data.passwordCharacterLimit = parseInt(data.passwordCharacterLimit) || 0
        data.passwordHistory = parseInt(data.passwordHistory) || 0
        
        return data
    }
    
    const handlePasswordPolicy = () => {
        resetError()
        if (!validateForm()) {
            const data = getData()
            setLoading(true)
            passwordPolicyApi.update(data.id, data).then((resp) => {
                setLoading(false)
                if (resp?.status === 204) {
                    toast.success(t('system.passwordPolicy.messages.savedPolicy.title'))
                    loadPasswordPolicy()
                    resetError()
                } else {
                    renderError(resp.data.description || resp.data.data.description || resp?.response?.data?.description || resp?.response?.data?.data?.description)
                }
            })
        }
    }

    const loadPasswordPolicy = useCallback(async() => {
        let response = await passwordPolicyApi.get()
        if (response?.status === 200) {
            let resp = response.data
            setForm({
                ...resp,
                passwordRule: resp.passwordRule || '',
            })
        }
    }, [passwordPolicyApi])

    useEffect(() => {
      if (actionRef.current === false) {
        loadPasswordPolicy()
        actionRef.current = true
      }
    }, [loadPasswordPolicy])
    

    return (
        <>
            {actionRef.current && 
                <PasswordPolicy 
                    data={form}
                    errors={error}
                    loading={loading} 
                    handleChange={handleChange}
                    handleState={handleState}
                    handlePasswordSecurity={handlePasswordSecurity}
                    handlePasswordUsername={handlePasswordUsername}
                    handlePasswordDictionary={handlePasswordDictionary}
                    handlePasswordPolicy={handlePasswordPolicy}
                />
            }
        </>
    )
}