import { useColors } from "../Context/ColorsContext"
import Icon from '@mui/material/Icon';
import { theme } from './../components/Global/style';

// export function getIcon(status, sizeBg, sizeIcon) {
//     let bgColor = status?.color || colors.grayFont;
//     let icon = status?.icon || '';

//     // const { mobile, tablet, desktop } = MediaQuerys;

//     return <div style={{ 
//                 height: sizeBg || 30, 
//                 width: sizeBg || 30, 
//                 minWidth: sizeBg || 30, 
//                 background: bgColor, 
//                 borderRadius: '50%', 
//                 display: 'flex', 
//                 justifyContent:'center',
//                 alignItems:'center', 
//                 marginRight: '10px',
//                 [theme.breakpoints.up('sm') ]: {
//                    width: 15,
//                    height: 15 
//                 }
//             }}
//     >
//         <Icon sx={{ color: colors.white, fontSize: sizeIcon || 20 }}>{icon}</Icon>
//     </div>
// }

export function getIcon(status, sizeBg, sizeIcon, noneMargin) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { colors } = useColors();
    let bgColor = status?.color || colors.grayFont;
    let icon = status?.icon || '';
    let mobile = window.outerWidth <= 425

    let styles = { 
        height: sizeBg || 30, 
        width: sizeBg || 30,
        fontSize: sizeBg || 20, 
        minWidth: sizeBg || 30,
        background: bgColor, 
        borderRadius: '50%', 
        display: 'flex', 
        justifyContent:'center',
        alignItems:'center', 
        marginRight: noneMargin ? '' : '5px',
    }

    if (mobile) {
        styles.width = 25
        styles.height = 25
        styles.fontSize = 15
        styles.minWidth = 15
    }

    return <div style={{ ...styles }}
    >
        <Icon sx={{ color: colors.white, fontSize: sizeIcon || 20 }}>{icon}</Icon>
    </div>
}


export function getIconColor(title) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { colors } = useColors();

    let titleLower = title ? title.toLowerCase() : '';
    if (titleLower === 'aprobación pendiente' || titleLower === 'pendiente por aprobación') return colors.purple
    if (titleLower === 'en ejecución') return colors.green
    if (titleLower === 'cerrado' || titleLower === 'vigente') return colors.blue
    if (titleLower === 'vencido') return colors.red
    return colors.grayFont
}
