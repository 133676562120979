import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment';

import { Button } from '@mui/material';
import { Container } from '../styles';
import {
  SectionPageTitle,
  Spacer,
  SectionPageLeft,
  ContainerBarSearch,
  ContainersButtonsGroup,
  ContainerButtonDownload,
  IconAction,
  NewIcon,
  ContainerGroupPage,
} from './styles';
import { PageTitle } from '../../components/PageTitle';
import { SearchBar } from '../../components/atoms/searchBars/SearchBar';
import TableWorkflowStatus from '../../components/atoms/tables/TableWorkflowStatus';
import { WorkflowStatusApi } from '../../services/WorkflowStatusApi';
import { ColorsContext } from "../../Context/ColorsContext"
import Router from '../../router';
import { toast } from 'react-toastify';
import { FormatDate } from '../../utils/formatDate';
import { ArrowBack, CloudDownloadOutlined, ManageHistory } from '@mui/icons-material';
import { CheckResponse } from '../../utils/checkResponse';
import { useTranslation } from 'react-i18next'
import { ExportExcel } from '../../utils/exportExcel';
import { snakeToCamel } from '../../utils/commonFunctions/convert';
import { Tooltip } from "@mui/material";
import { useColors } from "../../Context/ColorsContext"
import { ShowDialog } from '../../components/ShowDialog';

// Permissions
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../Context/PermissionsContext'

export const WorkflowStatus = (props) => {

  const { i18n, t } = useTranslation();
  const { colors } = React.useContext(ColorsContext);
  const ability = useAbility(AbilityContext);
  const workflowStatusApi = new WorkflowStatusApi();

  // const { colors } = useColors();
  const params = useParams();
  const navigate = useNavigate();
  const [data, setData] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const { id } = useParams();
  const { format } = new FormatDate();
  const { check } = new CheckResponse();

  const [filteredRequest, setFilteredRequest] = React.useState([])
  const [finalDate, setFinalDate] = React.useState(moment(new Date()).format("YYYY-MM-DD"))
  const [initialDate, setInitialDate] = React.useState(moment(finalDate).subtract(1, 'months').format("YYYY-MM-DD"))
  const [datesForm, setDatesForm] = React.useState({
    initialDate: { initialDate },
    finalDate: { finalDate }
  })
	const [dialog, setDialog] = React.useState({
    status: false,
    title: "",
    message: "",
    type: null,
  });


  useEffect(() => {
    async function call() {
			setLoading(true);
      await loadData();
			setLoading(false);
    }
		call()
  }, [i18n.language, params])

    
    async function loadData(initialDate, finalDate) {
      let resp = [];

      if (ability.can("WorkflowStatusList")) {
        let response = await workflowStatusApi.listAllByWorkflow(id, initialDate, finalDate);
        if (response.status === 200) {
          resp = response.data
          resp.map(obj => {
            obj.lastUpdate = obj.updatedAt
            obj.updatedAt = format(obj.updatedAt, true, true);
						obj.typeDescription = t(`workflowManagement.flowState.status.${snakeToCamel(obj.type)}`)
          })
        }
      }
          
      let standard = [];
      if (ability.can("WorkflowStatusStandardList")) {
        standard = await loadStandardData()
        standard.map((el) => {
          el.isStandard = true
          el.idDetail = id
          el.lastUpdate = el.updatedAt
          el.updatedAt = format(el.updatedAt, true, true);
          el.typeDescription = t(`workflowManagement.flowState.status.${snakeToCamel(el.type)}`)
        })
      }

      let newArray = [...standard, ...resp]

      setData(newArray);
    }

    async function loadStandardData() {
      let response = await workflowStatusApi.listFilterStandard(id);
      if (response.status === 200) {
        return response.data;
      }
    }

    async function handleDatesFilter(e) {
      e.preventDefault()
      setDatesForm({
        ...datesForm,
        initialDate,
        finalDate
      })
      setData(response.data);
    }


  async function handleDatesFilter(e) {
    e.preventDefault()
    setDatesForm({
      ...datesForm,
      initialDate,
      finalDate
    })

    setLoading(true);
    await loadData(initialDate, finalDate);
    setLoading(false);

    e.target.reset()
  }

  async function handleClearDate() {
    setFinalDate(moment(new Date()).format("YYYY-MM-DD"))
    setInitialDate(moment(finalDate).subtract(1, 'months').format("YYYY-MM-DD"))

    setLoading(true);
    await loadData();
    setLoading(false);
  }
	
	async function handleOpenDialog(row) {
    setDialog({
      status: true,
      title: t('workflowManagement.flowState.messages.confirmDelete.title'),
      message: t('workflowManagement.flowState.messages.confirmDelete.message', {title: row.title}),
      type: "confirmation",
      row: row,
    });
  }

  async function handleDelete(data) {
    let response = await workflowStatusApi.delete(data);
    let result = check(response)

    if (result.status) {
      toast.success(t('workflow.messages.deleteFlow.deleted'));
    } else {
      toast.error(() =>
        <div>{t('workflow.messages.deleteFlow.error')}<br />
          {result.errors}
        </div>
      );
    }
    await loadData();
  }
	
  async function handleCloseDialog(value) {
    if (value) {
      setDialog({ ...dialog, status: false });
      await handleDelete(dialog.row);
    } else {
      setDialog({ status: false });
    }
  }

  async function handleCreate() {
    // await workflowApi.create();
    // await loadData(params.id);
    // toast.success(`El worflow para ha sido creado con éxito`);
    navigate(Router.appWorkflowStatusDetail.replace(':id', id), { state: { row: {} } });
  }

  const handleDownloadExcel = () => {

    let data = filteredRequest.map((item) => ({
      [t('workflowManagement.flowState.table.statusCode')]: item.id,
      [t('workflowManagement.flowState.table.title')]: item.title,
      [t('workflowManagement.flowState.table.subtitle')]: item.subtitle,
      [t('workflowManagement.flowState.table.statusType')]: item.typeDescription,
      [t('workflowManagement.flowState.table.color')]: item.color,
      [t('workflowManagement.flowState.table.responsibleRole')]: item.role?.name,
      [t('workflowManagement.flowState.table.lastUpdate')]: format({ date: item.lastUpdate, format:  true }).toString(),
    }))

    ExportExcel({ apiData: data, fileName: t('workflowManagement.flowState.title') });
  }

  return (
    <Container open={props.open}>
      <SectionPageTitle>
        <SectionPageLeft>
          <ContainerGroupPage>
            <PageTitle title="" isbutton={true} navigate={Router.appWorkflowManagement} icon={<ArrowBack />} />
            <PageTitle title={t('workflowManagement.flowState.title')} icon={<ManageHistory />} />
          </ContainerGroupPage>

          <ContainerBarSearch>
            <SearchBar
              rows={data}
              setFilteredRequest={setFilteredRequest}
              filterText={t('common.searchBars.workflowStatus')}
							filterKeys={['statusId', 'title', 'subtitle', 'typeDescription', 'e.role?.name', 'lastUpdate']}
            />
          </ContainerBarSearch>
        </SectionPageLeft>

        <ContainersButtonsGroup>
          <Tooltip title={t('workflowManagement.flowState.table.createFlowState')}>
            <IconAction
              size="medium"
              aria-label="new"
              onClick={handleCreate}
            >
              <NewIcon />
            </IconAction>
          </Tooltip>
 
          <ContainerButtonDownload>
            <Button
              variant="contained"
              endIcon={<CloudDownloadOutlined />}
              sx={{ 
                height: '100%',
                width: '100%', 
                padding: "0 20px", 
                backgroundColor: colors.buttonActionColor,
								color: colors.white,
								"&:hover": {
									backgroundColor: colors.buttonActionColor,
									opacity: 0.9,
									color: colors.white
								}
              
              }}
              aria-label="download"
              onClick={handleDownloadExcel}
            >
              {t('common.download')}
            </Button>
          </ContainerButtonDownload>

        </ContainersButtonsGroup>

      </SectionPageTitle>

      <TableWorkflowStatus
        filteredRequest={filteredRequest}
        loading={loading}
        rows={data}
        initialDate={initialDate}
        setInitialDate={setInitialDate}
        finalDate={finalDate}
        setFinalDate={setFinalDate}
        handleDatesFilter={handleDatesFilter}
        handleClearDate={handleClearDate}
        handleCreate={handleCreate}
        handleDelete={handleOpenDialog}
      />

			<ShowDialog
        openDialog={dialog.status}
        dialog={dialog}
        handleCloseDialog={handleCloseDialog}
      />

    </Container >
  )
}
