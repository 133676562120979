import {styled} from "@mui/material/styles";

import { IconButton, rgbToHex } from "@mui/material";

import { MediaQuerys } from "../../../styles/GlobalStyle";
import { Grid, Chip } from '@mui/material';
import { Grid4x4Outlined } from "@mui/icons-material";

import { useColors } from "../../../Context/ColorsContext";


const { mobile, tablet, desktop } = MediaQuerys;

export const Title = styled('span')(() => ({
    fontWeight: 'bold',
    [mobile]: {
        fontSize: '0.75rem',
    }
}))

export const Text = styled('span')(({ isCode, needMarginLeft }) => {
    const { colors } = useColors();

    return {
        display: 'block',
        wordWrap: 'break-word',
        whiteSpace: 'normal',
        marginLeft: needMarginLeft ? '0.3rem' : null,
        fontSize: isCode ? '0.8rem' : null,
        color: isCode && colors.buttonActionColor,
        fontWeight: isCode ? 'bold' : null,
        [desktop]: {
           fontSize: '0.75rem',
        },
        [mobile]: {
           fontSize: "0.70rem !important",
        },
    }
})

export const Subtitle = styled('span')(() => {
    const { colors } = useColors();
    return {
        color: colors.grayFont,
        display: 'block',
        fontSize: '0.75rem', 
        textAlign: 'right',
        wordWrap: 'break-word', 
        whiteSpace: 'inherit',
        [tablet]: {
          whiteSpace: 'initial',
        }
    }
})

export const ContainerNotification = styled('div')(() => {
    return {
        display: 'flex',
        flexDirection: 'row',
        
    }
})

export const ContainerGroupTop = styled('div')(() => {
    return {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
    }
});

export const ContainerDate = styled(Grid)(() => {
    return {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    }
})

export const ContainerLeft = styled('div')(() => {
    return {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '0.5rem',
        marginRight: '0.3rem',
        height: '100%',
        width: '100%',
    }
})


export const CustomChip = styled(Chip)(() => {
    const { colors } = useColors();
    return {
        height: '18px',
        background: 'rgb(234, 234, 234)',
        [desktop]: {
            height: '15px',
        },
    }
})