import React, { useEffect, useState } from 'react';
import { PageTitle } from '../../components/PageTitle';
import { Card, Checkbox, Grid, Switch } from '@mui/material';
import Router from '../../router';
import { WorkflowStatusApi } from '../../services/WorkflowStatusApi';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { SelectInput as MultiSelect } from '../../components/SelectInput';
import {
    SectionPageTitle,
    Spacer,
    SelectInput,
    Input,
    Content,
    Label,
    Form,
    ContainerInputColor,
    ContainerContentColor,
    ContainerSelectInput,
    CustomFormControl,
    ContainerButtonsDown,
    Subtitle,
    CustomDivider
} from './styles';
import { Container, SearchButton, } from '../styles';
import { ColorsContext } from "../../Context/ColorsContext"
import InputColor from 'react-input-color';
import { ArrowBack, ManageHistory } from '@mui/icons-material';
import { LoadingProgress } from '../../components/LoadingProgress';
import { toast } from 'react-toastify';
import { CheckResponse } from '../../utils/checkResponse';
import { useTranslation } from 'react-i18next'
import { getIcon } from '../../utils/iconConfig';
import { snakeToCamel } from '../../utils/commonFunctions/convert';
import { RoleApi } from '../../services/RoleApi';
import { WorkflowApi } from '../../services/WorkflowApi';
import { StandardWorkflowContainer } from '../../containers/StandardWorkflowContainer';

// Permissions
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../Context/PermissionsContext'

export const WorkflowStatusDetail = (props) => {
    const { i18n, t } = useTranslation();
    const location = useLocation();

    const { row } = location.state
    const { colors } = React.useContext(ColorsContext);
    const ability = useAbility(AbilityContext);

    const roleApi = new RoleApi();
    const workflowApi = new WorkflowApi();
    const workflowStatusApi = new WorkflowStatusApi();

    const navigate = useNavigate();
    const { id } = useParams();
    const { check } = new CheckResponse();

    const [formData, setFormData] = useState({
        id: '',
        title: '',
        subtitle: '',
        color: '#5e72e4',
        notificationMessage: '',
        description: '',
        workflowId: id,
        icon: '',
        statusId: '',
        nextStatusId: 0,
        type: '',
        maxTime: '',
        minTime: '',
        notificationEmail: false,
        roles: [],
        rolesId: [],
        executeOption: '',
        executeOptionId: null,
        startWorkflow: '',
        startWorkflowId: null,
        fillForm: false
    });

    const [errorForm, setErrorForm] = useState({
        id: { hasError: false, errorMessage: '' },
        title: { hasError: false, errorMessage: '' },
        subtitle: { hasError: false, errorMessage: '' },
        description: { hasError: false, errorMessage: '' },
        icon: { hasError: false, errorMessage: '' },
        statusId: { hasError: false, errorMessage: '' },
        type: { hasError: false, errorMessage: '' },
        roles: { hasError: false, errorMessage: '' },
        executeOption: { hasError: false, errorMessage: '' }
    })

    const [isModifing, setIsModifing] = useState(false);

    const [optionStatus, setOptionStatus] = useState([]);
    const [statusTypes, setStatusTypes] = useState([]);
    const [roles, setRoles] = useState([]);
    const [executeOptions, setExecuteOptions] = useState([]);
    const [workflows, setWorkflows] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [color, setColor] = useState({});
    const cleanError = { hasError: false, errorMessage: '' }
    const [loading, setLoading] = React.useState(false)

    useEffect(() => {
        setLoading(true);
        if (row.id !== undefined) {
            setFormData({...row, startWorkflowId: row.startWorkflow?.id ?? null});
            loadOptionsStatus(row.id);
            setColor({ hex: row.color });
            setIsModifing(true);
            if (row.nextStatusId !== null) loadInitialState();
        }

        async function call() {
            await loadStatusTypes();
            await loadWorkflows();
            let listRoles = await loadRoles();
            setRoles(listRoles);

            if (row.roles?.length > 0) {
                let selRoles = []
                row.roles.map((el) => {
                    let objRoles = listRoles.filter((obj) => obj.id === el.id)
                    selRoles.push(...objRoles)
                })
                setFormData({ ...row, roles: selRoles })
            }
        }
        call()
        setLoading(false);
    }, [])

    useEffect(() => {
        async function call() {
            await loadRoleExecuteOptions();
        }
        call()
    }, [i18n.language])

    async function submit(event) {
        if (validationError()) {
            event.preventDefault();
        } else {
            event.preventDefault();

            let data = {...formData}
			data.rolesId = formData.roles?.map((el) => el.id) 
            data.executeOptionId = formData.executeOption?.id

            if (!isModifing) {
                let response = await workflowStatusApi.create(data);
                let result = check(response)

                if (result.status) {
                    navigate(Router.appWorkflowStatus.replace(':id', id));
                } else {
                    toast.error(() =>
                        <div>{t('workflow.messages.workflowStatus.create')}<br />
                            {result.errors}
                        </div>
                    );
                }
            } else {
                let response = await workflowStatusApi.edit(data);
                let result = check(response)

                if (result.status) {
                    navigate(Router.appWorkflowStatus.replace(':id', id));
                } else {
                    toast.error(() =>
                        <div>{t('workflow.messages.workflowStatus.edit')}<br />
                            {result.errors}
                        </div>
                    );
                }
            }
        }

    }


    async function loadInitialState() {
        if (ability.can("GetWorkflowStatus")) {
            let response = await workflowStatusApi.detail(row.nextStatusId);
            if (response.status === 200) {
                setSelectedStatus({ id: response.data.id, title: response.data.title })
            } else {
                setSelectedStatus(null);
            }
        }
    }

    async function loadOptionsStatus(id) {
        if (ability.can("WorkflowStatusList")) {
            let response = await workflowStatusApi.listAllByWorkflow(id);
            if (response.status === 200) {
                setOptionStatus(response.data);
            }
        }
    }

    async function loadStatusTypes() {
        if (ability.can("TypeWorkflowStatus")) {
            let response = await workflowStatusApi.listTypes();
            if (response.status === 200) {
                setStatusTypes(response.data);
            }
        }
    }

    async function loadRoles() {
        let response = await roleApi.list();
        if (response.status === 200) {
            let data = response.data.data
            return data.filter((obj) => obj.isActive)
        }
    }

    async function loadRoleExecuteOptions() {
        if (ability.can("RoleExecuteWorkflowStatus")) {
            let response = await workflowStatusApi.listRoleExecuteOptions();
            if (response.status === 200) {
                setExecuteOptions(response.data)
            }
        }
    }

    async function loadWorkflows() {
        let response = await workflowApi.list();
        if (response.status === 200) {
            setWorkflows(response.data)
        }
    }

    function handleChangeSelected(value) {
        setFormData({ ...formData, nextStatusId: value.id });
        setSelectedStatus(value);
    }

    function handleColorChange(value) {
        setFormData({ ...formData, color: value.hex });
    }

    function validationError() {
        let requiredError = {
            hasError: true,
            errorMessage: t('workflow.messages.workflowStatus.mandatory')
        }

        let errorFormCopy = { ...errorForm }
        if (!formData.title) {
            errorFormCopy.title = requiredError;
        } else {
            errorFormCopy.title = cleanError;
        }

        if (!formData.statusId) {
            errorFormCopy.statusId = requiredError;
        } else {
            errorFormCopy.statusId = cleanError;
        }

        if (!formData.icon) {
            errorFormCopy.icon = requiredError;
        } else {
            errorFormCopy.icon = cleanError;
        }

        if (!formData.type) {
            errorFormCopy.type = requiredError;
        } else {
            errorFormCopy.type = cleanError;
        }


        setErrorForm(errorFormCopy);

        if (errorFormCopy.title.hasError || errorFormCopy.statusId.hasError || errorFormCopy.icon.hasError) {
            return true;
        }

        return false;
    }

    function standardFunction() { }

    return (
        <Container open={props.open}>
            <SectionPageTitle>
                <PageTitle title="" isbutton={true} navigate={Router.appWorkflowStatus.replace(':id', id)} icon={<ArrowBack />} />
                {isModifing &&
                    <PageTitle title={t('workflowManagement.flowState.editFlowState')} icon={<ManageHistory />} />
                }
                {!isModifing &&
                    <PageTitle title={t('workflowManagement.flowState.createFlowState')} icon={<ManageHistory />} />
                }
                <Spacer />
            </SectionPageTitle>
            <Card sx={{ marginTop: '10px' }}>
                {loading && <LoadingProgress />}
                {!loading &&
                    <Content>
                        {row.isStandard ?
                            <StandardWorkflowContainer
                                data={row}
                                roles={roles}
                                executeOptions={executeOptions}
                                workflowId={id}
                                isAssignRole={true}
                                loadData={standardFunction}
                                handleCloseForm={standardFunction}
                            />
                            :
                            <Form onSubmit={submit}>
                                <Grid container spacing={2} display={'flex'} direction={'row'} alignItems={'center'}>
                                    <Grid item xs={6} md={4}>
                                        <Input
                                            placeholder={t('workflowManagement.flowState.table.title')}
                                            label={t('workflowManagement.flowState.table.title')}
                                            error={errorForm.title.hasError}
                                            value={formData.title}
                                            helperText={errorForm.title.errorMessage}
                                            onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                                            sx={{ width: '100% !important'}}
                                        />
                                    </Grid>
                                    {ability.can("TypeWorkflowStatus") &&
                                        <Grid item xs={6} md={4}>
                                            <ContainerSelectInput>
                                                <SelectInput
                                                    id="combo-box-demo"
                                                    disableClearable
                                                    onChange={(_, value) => {
                                                        setFormData({ ...formData, type: value })
                                                    }}
                                                    value={formData.type}
                                                    getOptionLabel={
                                                        (option) => option ?
                                                            t(`workflowManagement.flowState.status.${snakeToCamel(option)}`) :
                                                            option
                                                    }
                                                    options={statusTypes}
                                                    renderInput={
                                                        (params) => <Input {...params}
                                                            label={t('workflowManagement.flowState.selectStatusType')}
                                                            error={errorForm.type.hasError}
                                                            helperText={errorForm.type.errorMessage}
                                                        />
                                                    }
                                                />
                                            </ContainerSelectInput>
                                        </Grid>
                                    }
                                    <Grid item xs={6} md={1}>
                                        <Input
                                            placeholder={t('workflowManagement.flowState.code')}
                                            label={t('workflowManagement.flowState.code')}
                                            type="number"
                                            value={formData.statusId}
                                            error={errorForm.statusId.hasError}
                                            helperText={errorForm.statusId.errorMessage}
                                            className={Input}
                                            onChange={(e) => setFormData({ ...formData, statusId: e.target.value })}
                                            sx={{ width: '100% !important'}}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={1.5}>
                                        <Input
                                            placeholder={t('workflowManagement.flowState.table.icon')}
                                            label={t('workflowManagement.flowState.table.icon')}
                                            value={formData.icon}
                                            error={errorForm.icon.hasError}
                                            helperText={errorForm.icon.errorMessage}
                                            onChange={(e) => setFormData({ ...formData, icon: e.target.value })}
                                            sx={{ width: '100% !important'}}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={1.5}>
                                        <ContainerInputColor>
                                            <ContainerContentColor>
                                                <Label>
                                                    {t('workflowManagement.flowState.changeColor')}
                                                </Label>
                                                <InputColor
                                                    initialValue={formData.color}
                                                    onChange={handleColorChange}
                                                    placement="start"
                                                />
                                            </ContainerContentColor>

                                            <Grid item xs={1}>
                                                {getIcon(formData)}
                                            </Grid>
                                        </ContainerInputColor>
                                    </Grid>
                                    <Grid item xs={12} sx={{ "&.MuiGrid-item": {padding: 0} }}></Grid>
                                    
                                    {ability.can("RoleList") &&
                                        <Grid item xs={6} md={4}>
                                            <MultiSelect
                                                label={t('workflowManagement.flowState.responsibleRole')}
                                                id="combo-box-demo"
                                                disableClearable
                                                onChange={(value) => {
                                                    setFormData({ ...formData, 
                                                        roles: value, 
                                                        rolesId: value.map((el) => el.id)
                                                    })
                                                }}
                                                keyValue="name"
                                                value={formData.roles}
                                                options={roles}
                                                multiple={true}
                                                error={errorForm.roles.hasError}
                                                helperText={errorForm.roles.errorMessage}
                                            />
                                        </Grid>
                                    }
                                    {ability.can("RoleExecuteWorkflowStatus") &&
                                        <Grid item xs={6} md={4}>
                                            <MultiSelect
                                                label={t('workflowManagement.flowState.table.roleExecute')}
                                                id="combo-box-demo"
                                                onChange={(event) => {
                                                    setFormData({ ...formData, 
                                                        executeOption: event
                                                    })
                                                }}
                                                keyValue="name"
                                                value={formData.executeOption}
                                                options={executeOptions}
                                                error={errorForm.executeOption.hasError}
                                                helperText={errorForm.executeOption.errorMessage}
                                            />
                                        </Grid>
                                    }
                                    {ability.can("WorkflowStatusList") &&
                                    <>
                                        {isModifing &&
                                            <Grid item xs={6} md={4}>
                                                <SelectInput
                                                    id="combo-box-demo"
                                                    disableClearable
                                                    onChange={(_, value) => {
                                                        let selected = optionStatus.filter((obj) => value.id === obj.id)
                                                        handleChangeSelected(value);
                                                    }}
                                                    value={selectedStatus}
                                                    isOptionEqualToValue={(option, value) => option.id == value.id}
                                                    getOptionLabel={(option) => option.title}
                                                    options={optionStatus.map((obj) => { return { id: obj.id, title: obj.title } })}
                                                    renderInput={(params) => <Input {...params} label={t('workflowManagement.flowState.selectNextStatus')} />}
                                                    sx={{ width: '100%' }}
                                                />
                                            </Grid>
                                        }
                                    </>
                                    }
                                    <Grid item xs={12} md={4}>
                                        <Input
                                            placeholder={t('workflowManagement.flowState.notificationMessage')}
                                            label={t('workflowManagement.flowState.notificationMessage')}
                                            value={formData.notificationMessage}
                                            multiline
                                            maxRows={5}
                                            onChange={(e) => setFormData({ ...formData, notificationMessage: e.target.value })}
                                            sx={{ width: '100%' }}
                                            isNotification={true}
                                        />
                                    </Grid>
                                    {(formData.type === 'create_instance' && ability.can("WorkflowList")) &&
                                        <Grid item xs={12} md={4}>
                                            <SelectInput
                                                id="combo-box-demo"
                                                disableClearable
                                                onChange={(_, value) => {
                                                    setFormData({ 
                                                        ...formData, 
                                                        startWorkflow: value,
                                                        startWorkflowId: value.id,
                                                    })
                                                }}
                                                value={formData.startWorkflow}
                                                getOptionLabel={(option) => option ? option.name : ''}
                                                options={workflows}
                                                renderInput={
                                                    (params) => <Input {...params}
                                                        label={t('workflowManagement.flowState.newInstanceFlow')}
                                                    />
                                                }
                                            />
                                        </Grid>
                                    }

                                    {(formData.type === 'check_form' || formData.type === 'check_form_credentials') &&
                                        <Grid item xs={12} md={4}>
                                            <CustomFormControl label={t('workflowManagement.flowState.fillForm')}
                                                control={
                                                    <Checkbox
                                                        sx={{
                                                            color: colors.grayGraphic,
                                                            '&.Mui-checked': {
                                                                color: colors.buttonActionColor
                                                            },
                                                            '&.MuiCheckbox-indeterminate': {
                                                                color: colors.buttonActionColor
                                                            }
                                                        }}
                                                        checked={formData.fillForm}
                                                        onChange={(e) => {
                                                            setFormData({...formData, fillForm: e.target.checked})
                                                        }}
                                                    />
                                                }
                                                />
                                        </Grid>
                                    }

                                    <Grid item xs={12}>
                                        <Subtitle>ANS (Acuerdo a Nivel de Servicio)</Subtitle>
                                        <CustomDivider/>
                                    </Grid>
                                    <Grid item xs={6} md={4}>
                                        <Input
                                            type={'number'}
                                            placeholder={t('workflowManagement.flowState.maxTime')}
                                            label={t('workflowManagement.flowState.maxTime')}
                                            value={formData.maxTime}
                                            onChange={(e) => setFormData({ ...formData, maxTime: e.target.value })}
                                            sx={{ width: '100% !important'}}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={4}>
                                        <Input
                                            type={'number'}
                                            placeholder={t('workflowManagement.flowState.minTime')}
                                            label={t('workflowManagement.flowState.minTime')}
                                            value={formData.minTime}
                                            onChange={(e) => setFormData({ ...formData, minTime: e.target.value })}
                                            sx={{ width: '100% !important'}}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <CustomFormControl
                                            control={
                                                <Switch checked={formData.notificationEmail} onChange={(e) => setFormData({ ...formData, notificationEmail: e.target.checked })} inputProps={{ 'aria-label': 'controlled' }}
                                                    sx={{
                                                        '& .MuiSwitch-switchBase.Mui-checked': {
                                                            color: colors.buttonActionColor
                                                        },
                                                        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                                            backgroundColor: colors.buttonActionColor
                                                        },
                                                    }} />
                                            }
                                            label={t('workflowManagement.flowState.notificacionEmail')}
                                            sx={{ width: '100% !important'}}
                                        />
                                    </Grid>
                                </Grid>

                                <ContainerButtonsDown>
                                    {!isModifing &&
                                        <SearchButton variant="contained" type="submit" isStandard={true}>{t('common.create')}</SearchButton>
                                    }
                                    {isModifing &&
                                        <SearchButton variant="contained" type="submit" isStandard={true}>{t('common.edit')}</SearchButton>
                                    }
                                </ContainerButtonsDown>

                            </Form>
                        }
                    </Content>
                }
            </Card>
        </Container>
    );
}