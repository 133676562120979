import React, { useEffect, useCallback } from 'react'
import Modal from '@mui/material/Modal';
import Router from '../../router';
import { Auth } from '../../utils/auth';

// Components
import { Button, Grid } from '@mui/material';

import { InactivityTimeApi } from '../../services/InactivityTimeApi';

import InactivityDetector from "../../components/InactivityDetector";

import { 
    BoxModal, 
    Container, 
    Header, 
    Body, 
    Footer, 
    ContainerInfo, 
    SectionRight,
    Text
} from './styles';

import { useTranslation } from 'react-i18next'

export const SessionCheckForm = () => {

    const { t } = useTranslation();
    const { logout } = new Auth()
    const inactivityTimeApi = new InactivityTimeApi();

    const actionRef = React.useRef(false)
    
    const [isInactive, setIsInactive] = React.useState(false);
    const [form, setForm] = React.useState({
        isActive: false,
        time: 0,
        timeMessage: ''
    })

    const handleInactivity = () => {
        setIsInactive(true);
        logout()
    };
    
    const handleRedirect = () => {
        window.location.href = Router.appLogin;
    };

    const loadInactivityTime = useCallback(async() => {
        let response = await inactivityTimeApi.get(false)
        if (response?.status === 200) {
            let resp = response.data
            setForm({
                ...resp,
                time: (resp.time * 60000)
            })
        }
    }, [inactivityTimeApi])

    useEffect(() => {
      if (actionRef.current === false) {
        loadInactivityTime()
        actionRef.current = true
      }
    }, [loadInactivityTime])
    

    return (
        <div>
            {isInactive && (
                <Modal 
                open={isInactive}>
                    <BoxModal>
                        <Container>
                            <Header>{t('system.inactivityTimes.detectedInactivity')}</Header>
                            <Body>
                                <ContainerInfo>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Text>{form.timeMessage}</Text>
                                        </Grid>
                                    </Grid>
                                </ContainerInfo>
                            </Body>
                            <Footer>
                                <SectionRight>
                                    <Button
                                        variant="contained"
                                        sx={{ height: 30, padding: '0 20px', border: 'none' }}
                                        onClick={handleRedirect}
                                    >
                                        {t('common.accept')}
                                    </Button>
                                </SectionRight>
                            </Footer>
                        </Container>
                    </BoxModal>
                </Modal>
            )}
            {actionRef.current && form.isActive && <InactivityDetector time={form.time} onInactivity={handleInactivity} />}
        </div>
    );
}