import { useState, useMemo } from 'react';
import { ListSubheader, TextField, InputAdornment, IconButton, Tooltip } from '@mui/material';
import ListItemText from "@mui/material/ListItemText";

import { useTranslation } from 'react-i18next'

import { Search } from "@mui/icons-material";
import Checkbox from "@mui/material/Checkbox";

// Component Date
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/es';
import dayjs from "dayjs";

import { ClearIconButton } from '../../../pages/styles';

import { SearchButton } from '../../../pages/styles';

import {
  ContainerFilters,
  CustomFormControl,
  CustomInputLabel,
  CustomBadge,
  CustomSelect,
  CustomMenuItem,
  CleanIcon,
  ContainerSearch,
  ContainerGroup,
  ContainerInputsDate,
  ContainerSelects,
} from './style';

export const FiltersDeviations = ({
  switchCompanies,
  filters,
  initialDate,
  finalDate,
  handleChangeFilters,
  handleClearFilters,
  setSearchVicePresidText,
  setSearchCoreText,
  setSearchSiteText,
  optionsPresidency,
  optionsSites,
  optionsCore,
  isAllSelected,
  isAllCoresSelected,
  isAllPresidencySelected,
  isAllSystemsSelected,
  isAllStationsSelected,
  isAllCompanySelected,
  isAllContractSelected,
  optionsCompany,
  optionsContract,
  setSearchCompanyText,
  setSearchContractText,
  filterCore,
  filterSites,
  filterContracts,
  handleSendFilters,
  errorsDate,
}) => {

  const { t } = useTranslation();
  dayjs.locale('es');
  const ITEM_HEIGHT = 50;
  const ITEM_PADDING_TOP = 8;

  const MenuPropsSelect = (needAuto) => {
    const MenuProps = {
      autoFocus: false,
      PaperProps: {
        style: {
          height: 'auto',
          maxHeight: '50vh',
          width: needAuto ? 'auto' : 250,
          left: needAuto && 1500
        },
        sx: {
          '&::-webkit-scrollbar': {
            width: '5px',
          },
        }
      },
    };

    return MenuProps;
  }


  return (
    <ContainerFilters activeswitch={switchCompanies}>

      <ContainerSelects>

        {/* Select VicePresidency */}
        <CustomFormControl>
          <CustomInputLabel id="filter-vicePresidency">
            {t("common.vicepresidency")}
          </CustomInputLabel>
          <CustomBadge
            badgeContent={filters?.vicePresidency?.length}
            color="primary"
          >
            <CustomSelect
              labelId="filter-vicePresidency"
              id="demo-multiple-checkbox"
              multiple
              value={filters?.vicePresidency || []}
              onChange={e => handleChangeFilters(e, "vicePresidency", "select")}
              onClose={() => setSearchVicePresidText("")}
              renderValue={() => ""}
              MenuProps={MenuPropsSelect(true)}
            >
              <ListSubheader>
                <TextField
                  size="small"
                  autoFocus
                  placeholder={t("common.search")}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    )
                  }}
                  onChange={(e) => setSearchVicePresidText(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key !== "Escape") {
                      e.stopPropagation();
                    }
                  }}
                />
              </ListSubheader>
              <CustomMenuItem value='allPresidency'>
                <Checkbox
                  checked={isAllPresidencySelected}
                  indeterminate={
                    filters?.vicePresidency?.length > 0 && filters?.vicePresidency?.length < optionsPresidency.length
                  }
                  sx={{ width: "30px", height: "30px" }}
                />
                <ListItemText primary={t('common.selectAll')} />
              </CustomMenuItem>
              {optionsPresidency
                .slice()
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(
                  (tag) => (
                    (
                      <CustomMenuItem
                        key={tag.id}
                        value={tag}
                        sx={{ fontSize: "0.6em" }}
                      >
                        <Checkbox
                          checked={filters?.vicePresidency?.indexOf(tag) > -1}
                          sx={{ width: "30px", height: "30px" }}
                        />
                        <ListItemText primary={tag.name} />
                      </CustomMenuItem>
                    )
                  )
                )}
            </CustomSelect>
          </CustomBadge>
        </CustomFormControl>

        {/* Select Core */}
        <CustomFormControl disabled={filterCore.length === 0}>
          <CustomInputLabel id="filter-core">
            {t("common.core")}
          </CustomInputLabel>
          <CustomBadge
            badgeContent={filters?.core?.length}
            color="primary"
          >
            <CustomSelect
              labelId="filter-core"
              id="demo-multiple-checkbox"
              multiple
              value={filters?.core || []}
              onChange={e => handleChangeFilters(e, "core", "select")}
              onClose={() => setSearchCoreText("")}
              renderValue={() => ""}
              MenuProps={MenuPropsSelect(true)}
            >
              <ListSubheader>
                <TextField
                  size="small"
                  autoFocus
                  placeholder={t("common.search")}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    )
                  }}
                  onChange={(e) => setSearchCoreText(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key !== "Escape") {
                      e.stopPropagation();
                    }
                  }}
                />
              </ListSubheader>
              <CustomMenuItem value='AllCore'>
                <Checkbox
                  checked={isAllCoresSelected}
                  indeterminate={
                    filters?.core?.length > 0 && filters?.core?.length < optionsCore.length
                  }
                  sx={{ width: "30px", height: "30px" }}
                />
                <ListItemText primary={t('common.selectAll')} />
              </CustomMenuItem>
              {optionsCore
                .slice()
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(
                  (tag) => (
                    (
                      <CustomMenuItem
                        key={tag.id}
                        value={tag}
                        sx={{ fontSize: "0.6em" }}
                      >
                        <Checkbox
                          checked={filters?.core?.indexOf(tag) > -1}
                          sx={{ width: "30px", height: "30px" }}
                        />
                        <ListItemText primary={tag.name} />
                      </CustomMenuItem>
                    )
                  )
                )}
            </CustomSelect>
          </CustomBadge>
        </CustomFormControl>

        {/* Select Sites */}
        <CustomFormControl disabled={filterSites.length === 0}>
          <CustomInputLabel id="filter-sites">
            {t("common.sites")}
          </CustomInputLabel>
          <CustomBadge
            badgeContent={filters?.site?.length}
            color="primary"
          >
            <CustomSelect
              labelId="filter-sites"
              id="demo-multiple-checkbox"
              multiple
              value={filters?.site || []}
              onChange={e => handleChangeFilters(e, "site", "select")}
              onClose={() => setSearchSiteText("")}
              renderValue={() => ""}
              MenuProps={MenuPropsSelect(true)}
            >
              <ListSubheader>
                <TextField
                  size="small"
                  autoFocus
                  placeholder={t("common.search")}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    )
                  }}
                  onChange={(e) => setSearchSiteText(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key !== "Escape") {
                      e.stopPropagation();
                    }
                  }}
                />
              </ListSubheader>
              <CustomMenuItem value='selectAll'>
                <Checkbox
                  checked={isAllSelected}
                  indeterminate={
                    filters?.site?.length > 0 && filters?.site?.length < optionsSites.length
                  }
                  sx={{ width: "30px", height: "30px" }}
                />
                <ListItemText primary={t('common.selectAll')} />
              </CustomMenuItem>

              <CustomMenuItem value='AllSystems'>
                <Checkbox
                  checked={isAllSystemsSelected}
                  indeterminate={
                    filters?.site?.length > 0 && filters?.site?.length < optionsSites.filter(site => site.category.name === 'Sistema').length
                  }
                  sx={{ width: "30px", height: "30px" }}
                />
                <ListItemText primary={t('common.selectAllSystems')} />
              </CustomMenuItem>

              <CustomMenuItem value='AllStations'>
                <Checkbox
                  checked={isAllStationsSelected}
                  indeterminate={
                    filters?.site?.length > 0 && filters?.site?.length < optionsSites.filter(station => station.category.name === '"Estación"').length
                  }
                  sx={{ width: "30px", height: "30px" }}
                />
                <ListItemText primary={t('common.selectAllSites')} />
              </CustomMenuItem>
              {optionsSites
                .slice()
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(
                  (tag) => (
                    (
                      <CustomMenuItem
                        key={tag.id}
                        value={tag}
                        sx={{ fontSize: "0.6em" }}
                      >
                        <Checkbox
                          checked={filters?.site?.indexOf(tag) > -1}
                          sx={{ width: "30px", height: "30px" }}
                        />
                        <ListItemText primary={tag.name} />
                      </CustomMenuItem>
                    )
                  )
                )}
            </CustomSelect>
          </CustomBadge>
        </CustomFormControl>

        {switchCompanies &&
          <>
            {/* Select Company */}
            <CustomFormControl>
              <CustomInputLabel id="filter-company">
                {t("common.company")}
              </CustomInputLabel>
              <CustomBadge
                badgeContent={filters?.company?.length}
                color="primary"
              >
                <CustomSelect
                  labelId="filter-company"
                  id="demo-multiple-checkbox"
                  multiple
                  value={filters?.company || []}
                  onChange={e => handleChangeFilters(e, "company", "select")}
                  onClose={() => setSearchCompanyText("")}
                  renderValue={() => ""}
                  MenuProps={MenuPropsSelect(true)}
                >
                  <ListSubheader>
                    <TextField
                      size="small"
                      autoFocus
                      placeholder={t("common.search")}
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        )
                      }}
                      onChange={(e) => setSearchCompanyText(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key !== "Escape") {
                          e.stopPropagation();
                        }
                      }}
                    />
                  </ListSubheader>
                  <CustomMenuItem value='allCompany'>
                    <Checkbox
                      checked={isAllCompanySelected}
                      indeterminate={
                        filters?.company?.length > 0 && filters?.company?.length < optionsCompany.length
                      }
                      sx={{ width: "30px", height: "30px" }}
                    />
                    <ListItemText primary={t('common.selectAll')} />
                  </CustomMenuItem>

                  {optionsCompany
                    .slice()
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map(
                      (tag) => (
                        (
                          <CustomMenuItem
                            key={tag.id}
                            value={tag}
                            sx={{ fontSize: "0.6em" }}
                          >
                            <Checkbox
                              checked={filters?.company?.indexOf(tag) > -1}
                              sx={{ width: "30px", height: "30px" }}
                            />
                            <ListItemText primary={tag.name} />
                          </CustomMenuItem>
                        )
                      )
                    )}
                </CustomSelect>
              </CustomBadge>
            </CustomFormControl>

            {/* Select Contracts */}
            <CustomFormControl disabled={filterContracts.length === 0}>
              <CustomInputLabel id="filter-contracts">
                {t("common.contracts")}
              </CustomInputLabel>
              <CustomBadge
                badgeContent={filters?.contracts?.length}
                color="primary"
              >
                <CustomSelect
                  labelId="filter-contracts"
                  id="demo-multiple-checkbox"
                  multiple
                  value={filters?.contracts || []}
                  onChange={e => handleChangeFilters(e, "contracts", "select")}
                  onClose={() => setSearchContractText("")}
                  renderValue={() => ""}
                  MenuProps={MenuPropsSelect(true)}
                >
                  <ListSubheader>
                    <TextField
                      size="small"
                      autoFocus
                      placeholder={t("common.search")}
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        )
                      }}
                      onChange={(e) => setSearchContractText(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key !== "Escape") {
                          e.stopPropagation();
                        }
                      }}
                    />
                  </ListSubheader>

                  <CustomMenuItem value='allContracts'>
                    <Checkbox
                      checked={isAllContractSelected}
                      indeterminate={
                        filters?.contracts?.length > 0 && filters?.contracts?.length < optionsContract.length
                      }
                      sx={{ width: "30px", height: "30px" }}
                    />
                    <ListItemText primary={t('common.selectAll')} />
                  </CustomMenuItem>

                  {optionsContract
                    .slice()
                    .sort((a, b) => a.code.localeCompare(b.code))
                    .map(
                      (tag) => (
                        (
                          <CustomMenuItem
                            key={tag.id}
                            value={tag}
                            sx={{ fontSize: "0.6em" }}
                          >
                            <Checkbox
                              checked={filters?.contracts?.indexOf(tag) > -1}
                              sx={{ width: "30px", height: "30px" }}
                            />
                            <ListItemText primary={tag.code} />
                          </CustomMenuItem>
                        )
                      )
                    )}
                </CustomSelect>
              </CustomBadge>
            </CustomFormControl>
          </>
        }


      </ContainerSelects>


      <ContainerGroup>
        {/* Input desde */}
        <ContainerInputsDate errorstart={errorsDate.startDate}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
              <DatePicker
                label={t("common.initDate")}
                value={filters?.startDate}
                minDate={dayjs('2020-03-20')}
                maxDate={dayjs()}
                onChange={e => handleChangeFilters(e || "", "startDate", "date")}
                slotProps={{ textField: { size: 'small', error: errorsDate.startDate } }}
                format="DD/MM/YYYY"
              />
            </DemoContainer>
          </LocalizationProvider>
        </ContainerInputsDate>
        {/* Input hasta */}
        <ContainerInputsDate errorend={errorsDate.endDate}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
              <DatePicker
                label={t("common.lastDate")}
                value={filters?.endDate}
                minDate={dayjs(filters?.startDate)}
                maxDate={dayjs()}
                onChange={(e) => handleChangeFilters(e || "", "endDate", "date")}
                slotProps={{ textField: { size: 'small', error: errorsDate.endDate } }}
                format="DD/MM/YYYY"
              />
            </DemoContainer>
          </LocalizationProvider>
        </ContainerInputsDate>
      </ContainerGroup>

      <ContainerSearch>
        <SearchButton variant="contained" type='submit' onClick={handleSendFilters} >
          {t("common.search")}
        </SearchButton>

        <Tooltip title={t("common.cleanFilters")}>
          <ClearIconButton
            size="small"
            aria-label="plot"
            onClick={handleClearFilters}
          >
            <CleanIcon />
          </ClearIconButton>
        </Tooltip>
      </ContainerSearch>

    </ContainerFilters>
  )
}
