import { useMemo } from "react";
import { ExportExcel } from "../utils/exportExcel";

export const useDownloadExcel = (arrayData, fieldConfig, nameFile) => {
  arrayData?.sort((a, b) => a.id - b.id);

  const mappedData = useMemo(() => {
    if (arrayData && fieldConfig) {
      return arrayData.map((item) => {
        const mappedItem = {};
        Object.keys(fieldConfig).forEach((field) => {
          const fieldName = fieldConfig[field];
          if (typeof fieldName === 'function') {
            mappedItem[field] = fieldName(item);
          } else {
            mappedItem[field] = item[fieldName];
          }
        });
        return mappedItem;
      });
    }
    return [];
  }, [arrayData, fieldConfig]);


  const handleDownloadExcelList = () => {
    ExportExcel({ apiData: mappedData, fileName: nameFile });
  };

  return {
    handleDownloadExcelList
  };
};
