import { Auth } from "../utils/auth";
import { HealthCheckApi } from "./HealthCheckApi";
import Router from "../router";
import axios from "./AxiosConfig";

// Permissions
import ability from '../config/defineAbility.js';

export class WorkflowApi {
    healthCheck = null;
    token = null;

    constructor() {
        let auth = new Auth();
        this.token = auth.getToken();
        this.healthCheck = new HealthCheckApi();
    }

    async list (params, startDate, endDate) {
        if (ability.can("WorkflowList")) {
            let data = {...params, startDate: startDate, endDate: endDate}
            return await axios.get(Router.apiWorkflowListAll, {
                params: data,
                headers: {
                    'Authorization': `Bearer ${this.token}`
                }
            })
            .then(async response => {
                return await this.healthCheck.checkApi(response)
            }).catch(async error => {
                return await this.healthCheck.checkApi(error)
            })
        }
        return {status: false};
    }

    async listAllParentList (params, startDate, endDate) {
        if (ability.can("WorkflowList")) {
            let data = {...params, startDate: startDate, endDate: endDate}
            return await axios.get(Router.apiWorkflowListAllParentChild, {
                params: data,
                headers: {
                    'Authorization': `Bearer ${this.token}`
                }
            })
            .then(async response => {
                return await this.healthCheck.checkApi(response)
            }).catch(async error => {
                return await this.healthCheck.checkApi(error)
            })
        }
        return {status: false};
    }

    async detail(id) {
        if (ability.can("GetWorkflow")) {
            return await axios.get(Router.apiWorkflowDetail.replace(':id', id))
            .then(async response => {
                return await this.healthCheck.checkApi(response)
            }).catch(async error => {
                return await this.healthCheck.checkApi(error)
            })
        }
        return {status: false};
    }

    async create(data) {
        if (ability.can("CreateWorkflow")) {
            return await axios.post(Router.apiWorkflow, data)
            .then(async response => {
                return await this.healthCheck.checkApi(response)
            }).catch(async error => {
                return await this.healthCheck.checkApi(error)
            });
        }
        return {status: false};
    }

    async delete(data) {
        if (ability.can("DeleteWorkflow")) {
            return await axios.delete(Router.apiWorkflow, {data: data})
            .then(async response => {
                return await this.healthCheck.checkApi(response)
            }).catch(async error => {
                return await this.healthCheck.checkApi(error)
            });
        }
        return {status: false};
    }

    async edit(data) {
        if (ability.can("UpdateWorkflow")) {
            return await axios.put(Router.apiWorkflow, data)
            .then(async response => {
                return await this.healthCheck.checkApi(response)
            }).catch(async error => {
                return await this.healthCheck.checkApi(error)
            });
        }
        return {status: false};
    }

    async changeOrder(data)  {
        return await axios.post(Router.apiWorkflowChangeOrder, data)
        .then(response => {
            return response
        }).catch(error => {
            return error.response;
        });
        
    }

    async getRelations(id)
    {
        return await axios.get(Router.apiWorkflowRelations.replace(':id', id))
        .then( response => {
            return response;
        }).catch(error => {
            return error.response;
        })
    }


    async LoadLogs(data) {
        return await axios.get(Router.apiBaseUrl + Router.apilogsList, {
            params: {
                initialDate: data?.initDate,
                finalDate: data?.finDate
            }
        })
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)
        })
    }


    async workflowDefault(id) {
        return await axios.get(Router.apiBaseUrl + Router.apiWorkflowDefault.replace(':id', id), {})
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)
        })
    }
}