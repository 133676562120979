import React from 'react'
import { useTranslation } from 'react-i18next';

import { ContainerFilterBar, InputFilter } from './styles'

export const FilterBarPermitsRole = ({listPermissions, setListPermissionsFilter, listPermissionsFilter, setItemsFilter, filterInput, setFilterInput, placeholder}) => {

  const { t } = useTranslation();
  const [search, setSearch] = React.useState('')

  React.useEffect(() => {
    if(listPermissionsFilter){  
      const ffilterFinal = listPermissionsFilter.map(objeto => objeto.id);
      setItemsFilter(ffilterFinal)
    }
  }, [listPermissionsFilter])

  const handleSearch = (e) => {
    setSearch(e)
    filterSearch(e)
  }

  const filterSearch = (data) => {
    let searchResult = listPermissions.filter((e) => {
      if(
        e.name?.toString().toLowerCase().includes(data.toLowerCase()) || 
        e.description?.toString().toLowerCase().includes(data.toLowerCase())
      ){
        return e
      }
    })
    setListPermissionsFilter(searchResult)
  }

  return (
    <ContainerFilterBar>
      <InputFilter 
        type='text'
        placeholder={placeholder === 'changePermissions' ? t('system.permissions.filterBarPermiss') : t('system.permissions.filterBarRole')}
        value={search}
        onChange={(e)=>handleSearch(e.target.value)}
      />
    </ContainerFilterBar>
  )
}
