import React from 'react';

// Components
import { Grid } from '@mui/material';

// Router
import { useNavigate, useLocation } from 'react-router-dom';
import Router from '../../router';

// Styles
import { Content, TextResource, Text, CustomButton, TextCode, TextLock, TextLockTop, TextLockBottom, Container } from './styles';
import { HealthCheckApi } from '../../services/HealthCheckApi';

// Utils
import { useTranslation } from 'react-i18next'
import { LanguageContainer } from '../styles';
import { LanguageSelect } from '../../components/atoms/languageSelect';

const useQuery = () => {
    return new URLSearchParams(useLocation().search)
  }

export const Forbidden = () => {
    const {check} = new HealthCheckApi();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const query = useQuery()

    async function refresh() {
        let response = await check();
        if (response.status === 200) {
            navigate(Router.appMyWork);
        }
    }

    const decodeHash = (hash) => {
        if (hash === null) return ''
        return decodeURIComponent(escape(window.atob(hash)))
    }

    const redirect = () => {
        navigate(Router.appLogin)
    }

    return (
        <Content container>
            <Grid item xs={12}>
                <LanguageContainer><LanguageSelect/></LanguageContainer>
            </Grid>
            <Container item xs={12}>
                <div>
                    <TextCode component="div">
                    4
                    </TextCode>
                    <TextLock component="div">
                        <TextLockTop component="div"></TextLockTop>
                        <TextLockBottom component="div"></TextLockBottom>
                    </TextLock>
                    <TextCode component="div">
                    3
                    </TextCode>
                </div>
                <Text>{t('forbidden.accessDenied')}</Text>
                <Text>{t('forbidden.accessResource')}</Text>
                <TextResource>{decodeHash(query.get('name'))}</TextResource>
                <CustomButton variant='contained' onClick={redirect}>{t('forbidden.home')}</CustomButton>
            </Container>
        </Content>
    )
}

