import { styled } from "@mui/material/styles";
import { useColors } from "../../Context/ColorsContext"
import { MediaQuerys } from "../../styles/GlobalStyle";
import { IconButton } from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';


const { mobile, tablet, desktop, desktoplarge } = MediaQuerys;

export const SectionPageTitle = styled("section")(() => ({
  display: "flex",
  height: "auto",
  width: "100%",
  [desktoplarge]: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  [mobile]: {
    flexDirection: "column",
    gap: '10px',
    marginBottom: '12px',
  }
}));

export const Spacer = styled("div")(() => ({
  display: "flex",
  flex: 1,
}));


export const SectionPageLeft = styled("div")(() => ({
  height: "auto",
  width: "auto",
  display: "flex",
  flexDirection: "row",
  gap: "1.875rem",
  [mobile]: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
}));


export const ContainerBarSearch = styled("div")(() => ({
  width: 500,
  height: 40,
  [desktop]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "450px",
    height: "",
    "& input": {
      fontSize: "0.75rem",
    },
  },
  [tablet]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "320px",
    height: "25px",
    "& input": {
      fontSize: "0.65rem",
    },
  },
}));


export const ContainersButtonsGroup = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  [mobile]: {
    justifyContent: 'space-between',
    alignItems: 'center',
  }
}))


export const ContainerButtonDownload = styled("div")(() => ({
  width: 140,
  height: 40,
  [desktop]: {
    width: "120px",
    height: "30px",
    "& button": {
      fontSize: "0.65rem",
    },
  },
  [tablet]: {
    width: "100px",
    height: 28,
    "& button": {
      fontSize: "7px",
    },
  },
}));

export const IconAction = styled(IconButton)(() => {
  const { colors } = useColors();
  return {
    display: 'none',
    borderRadius: 20,
    pointerEvents: "pointer",
    background: colors.buttonActionColor,
    marginLeft: 5,
    "&:hover": {
      backgroundColor: colors.buttonActionColor,
      opacity: 0.9,
      color: colors.white,
    },
     [desktop]: {
      fontSize: "0.25rem",
      width: '28px',
        height: '28px',
        "&MuiSvgIcon-root": {
          width: '16px'
        }
    },
    [tablet]: {
      height: 28,
      width: 28,
    },
    [mobile]: {
      display: "flex",
    },
  };
});



export const NewIcon = styled(AddCircleIcon)(() => {
  const { colors } = useColors();
  return {
    height: '20px',
    width: '20px',
    color: colors.white,
    // fontSize: 18,
    [desktop]: {
      height: '16px',
      width: '16px'
    }
  };
});


export const ContainerGroupPage = styled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'row',
  }
})