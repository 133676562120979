import { styled } from "@mui/material/styles";
import { Card, TableCell, Tabs } from "@mui/material";
import { useColors } from "../../Context/ColorsContext"
import { MediaQuerys } from "../../styles/GlobalStyle";

const { mobile, tablet, desktop, desktoplarge } = MediaQuerys;


export const CustomCard = styled(Card)(() => ({
  minHeight: 430,
  maxHeight: "calc(100vh - 100px)",
  width: "100%",
  borderRadius: 6,
  boxShadow: "rgb(61 71 82 / 20%) 0px 4px 8px",
  padding: "30px 20px",
}));

export const SectionPageTitle = styled("section")(() => ({
  display: "flex",
  width: "100%",
}));
export const CustomTableCell = styled(TableCell)(() => {
  const { colors } = useColors();
  return {
    color: colors.main,
    fontSize: "12px",
    fontWeight: 600,
  }
});


export const TableCellStatus = styled(TableCell)(() => ({
  display: "flex",
}));
export const ContainerCell = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
}));

export const SectionInfo = styled("section")(() => ({
  display: "flex",
  flexDirection: "column",
}));

export const InfoCell = styled("div")(() => ({}));

export const Spacer = styled("div")(() => ({
  display: "flex",
  flex: 1,
}));

export const Content = styled(Card)(() => ({
  padding: 20,
    marginTop: '8px',
}));

export const CustomTabs = styled(Tabs)(() => {
  // eslint-disable-next-line no-use-before-define
  const { colors } = useColors();
  return {
    '&.MuiButtonBase-root-MuiTab-root .Mui-selected': {
      color: `${colors.buttonActionColor} !important`
    },
    '.Mui-selected': {
      color: `${colors.buttonActionColor} !important`
    },
    '& .MuiButtonBase-root': {
      fontSize: '1rem !important'
    },
    [desktop]: {
      '& .MuiButtonBase-root': {
        fontSize: '0.75rem !important'
      },
    }
  }
});
