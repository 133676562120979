import { Button, Grid, TextField, Typography } from '@mui/material';
import styled from 'styled-components';
import { useColors } from "../../../../Context/ColorsContext";
import { MediaQuerys } from '../../../../styles/GlobalStyle';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DownloadIcon from '@mui/icons-material/Download';

const { mobile, tablet, desktop } =  MediaQuerys;


export const Container = styled('div')(() => {
  return {
    width: '100%',
    height: '100%',
  }
})

export const TextInput = styled(TextField)(() => ({
  width: '80%'
}))

export const Image = styled('img')(() => ({
  width: 500,
  height: 400,
  maxHeight: 500,
  maxWidth: 500,
}))

export const ImageContainer = styled('div')(() => ({
  width: 500,
  height: 400,
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  transition: 'opacity 0.4s ease-in',
  cursor: 'pointer',
}))

export const Area = styled('div')(({top, left}) => {
  const { colors } = useColors();
  return {
    position: 'absolute',
    color: colors.white,
    top: top,
    left: left
  }
})

export const IconArea = styled('div')(() => {
  const { colors } = useColors();
  return {
      background: colors.orange,
      color: colors.white,
      width: 30,
      height: 30,
      fontSize: 16,
      fontWeight: 'bold',
      borderRadius: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
  }
})

export const UploadButton = styled(Button)(() => ({
    background: 'none'
}))

export const GridCenter = styled(Grid)(() => {
  const { colors } = useColors();
  return {
    textAlign: 'center',
    height: '100%',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
    background: colors.white,
    border: '1px solid #d8e0e5'
  }
})

export const ErrorText = styled('p')(() => ({
  color: '#d32f2f',
  fontSize: '0.75rem',
  marginLeft: '14px'
}))

export const InfoContainer = styled(Grid)(() => ({
  alignItems: 'center',
  display: 'flex',
  flexWrap: 'wrap'
}))

export const InfoText = styled('span')(() => ({
  fontSize: 14,
  marginLeft: 4
}))

export const ContainerInput = styled('div')(() => {
  return {
    display: 'flex',
    width: '50%',
    flexDirection: 'column'
  }
})

export const ContainerContent = styled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    gap: '0.85rem',
    [mobile]: {
      flexDirection: 'column',
    }
  }
})

export const CustomTextField = styled(TextField)(() => {
  return {
    width: '100%',
    '.MuiInputBase-input': {
      [desktop]: {
        fontSize: '0.85rem'
      }
    }
  }
})

export const CustomTypography = styled(Typography)(() => {
  return {
    [desktop]: {
      fontSize: '0.85rem'
    }
  }
})

export const ContainerGroup = styled('div')(() => {
  return {
    width: '50%',
    display: 'flex',
    flexDirection: 'row',
    gap: '0.85rem',
    [mobile]: {
      width: '100%',
    }
  }
})

export const PdfUploadIcon = styled(FileUploadIcon)(() => {
  const { colors } = useColors();
  return {
      fontSize: 50,
      color: colors.buttonActionColor,
      padding: '.5rem',
      backgroundColor: colors.background,
      borderRadius: "10%",
      margin: '0 .5rem',
      "&:hover": {
        backgroundColor: colors.buttonActionColor,
        opacity: 0.9,
        color: colors.background,
        cursor: 'pointer'
      }
  }
})

export const PdfDownloadIcon = styled(DownloadIcon)(() => {
  const { colors } = useColors();
  return {
      fontSize: 50,
      color: colors.buttonActionColor,
      padding: '.5rem',
      backgroundColor: colors.background,
      borderRadius: "10%",
      margin: '0 .5rem',
      "&:hover": {
          backgroundColor: colors.buttonActionColor,
          opacity: 0.9,
          color: colors.background,
          cursor: 'pointer'
      }
  }
})