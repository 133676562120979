import React, { createContext } from 'react';
import defineAbility from '../config/defineAbility';

import { createContextualCan } from '@casl/react';

const AbilityContext = createContext();
export const Can = createContextualCan(AbilityContext.Consumer);

const PermissionsProvider = ({ children }) => {

  return (
    <AbilityContext.Provider value={defineAbility}>
      {children}
    </AbilityContext.Provider>
  );
};

export { PermissionsProvider, AbilityContext };
