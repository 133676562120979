import React, { useState, useEffect } from "react";

// Material UI - Table
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Tooltip from "@mui/material/Tooltip";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import SearchIcon from "@mui/icons-material/Search";
import { Button, Grid, Icon } from "@mui/material";

import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";
import { StickyColumn, CustomCell } from "../../../../styles/GlobalStyle";
import { MediaQuerys } from "../../../../styles/GlobalStyle";

import { ClearIconButton, SearchButton } from "../../../../pages/styles";
import { ColorsContext } from "../../../../Context/ColorsContext"

// Utils
import { FormatDate } from "../../../../utils/formatDate";
import { useTranslation } from "react-i18next";

//Hooks
import { useSortByColumn } from "../../../../Hooks/useSortByColumn";

import {
  TableContent,
  CustomTableCell,
  FigureHead,
  Text,
  Label,
  InputDate,
  CleanIcon,
  IconAction,
  NewIcon,
  ContainerInputsDate,
  ContainerContentHeader,
  ContainerSearch,
  Forms,
  ContainerGroup,
  CustomIconButton,
  CustomSearchIcon,
} from "./style";

const { mobile, tablet } = MediaQuerys;

const headCells = [
  {
    id: "createdAt",
    center: false,
    disablePadding: true,
    sortArrow: true,
    label: "system.logs.table.date",
    customSort: 'createdAt',
  },
  {
    id: "username",
    center: false,
    disablePadding: true,
    sortArrow: true,
    label: "system.logs.table.user",
    customSort: 'username',
  },
  {
    id: "module",
    center: false,
    disablePadding: true,
    sortArrow: true,
    label: "system.logs.table.module",
    customSort: 'module',
  },
  {
    id: "submodule",
    center: false,
    disablePadding: true,
    sortArrow: true,
    label: "system.logs.table.submodule",
    customSort: 'submodule',
  },
  {
    id: "action",
    center: false,
    disablePadding: true,
    sortArrow: true,
    label: "common.actions.title",
    customSort: 'action',
    sticky: true,
    direction: 'right',
  },
];

// Cabecera de la tablaaaaa

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
	const { t } = useTranslation();
  const { colors } = React.useContext(ColorsContext);

  const createSortHandler = (property, customSort) => (event) => {
    onRequestSort(event, property, customSort);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <CustomCell
            key={headCell.id}
            align={headCell.center ? "center" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            dataHead={headCell}
          >
            {headCell.sortArrow == true && (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id, headCell.customSort)}
              >
                {t(headCell.label) || headCell.label}
                {orderBy === headCell.id ? <Box sx={{ position: 'unset' }} component="span"></Box> : null}
              </TableSortLabel>
            )}
            {headCell.sortArrow == false && (
              <>
                <Box sx={{ position: 'unset' }} component="span">{t(headCell.label) || headCell.label}</Box>
              </>
            )}
          </CustomCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = (props) => {
	const { t } = useTranslation();
  const { colors } = React.useContext(ColorsContext);

  const { handleDatesFilter, handleClearDate, handleDownloadExcel, arrayLogs } = props;

  return (
    <Toolbar
      sx={{
        background: colors.gray,
        borderRadius: "5px 5px 0 0",
        minHeight: "60px !important",
        marginTop: "16px",
        [mobile]: {
          minHeight: "auto !important",
          padding: "5px 5px",
          marginTop: "15px",
        },
        [tablet]: {
          minHeight: "40px !important",
        },
      }}
    >
      <Forms onSubmit={handleDatesFilter}>
        <ContainerGroup>
          <ContainerInputsDate>
            <Label>{t("common.initDate")}</Label>
            <InputDate
              type="date"
              value={props.initialDate}
              min="2020-03-20"
              max={props.finalDate}
              onChange={(e) => {
                props.setInitialDate(e.target.value);
              }}
            />
          </ContainerInputsDate>

          <ContainerInputsDate>
            <Label>{t("common.lastDate")}</Label>
            <InputDate
              type="date"
              value={props.finalDate}
              min={props.initialDate}
              max={props.finalDate}
              onChange={(e) => {
                props.setFinalDate(e.target.value);
              }}
            />
          </ContainerInputsDate>
        </ContainerGroup>

        <ContainerSearch>
          <SearchButton variant="contained" type="submit" isMobile={true}>
            {t("common.search")}
          </SearchButton>

          <Tooltip title={t("common.search")}>
            <ClearIconButton isFirstClearButton={true} type="submit">
              <SearchIcon
                sx={{ height: "20px", width: "20px", color: "white" }}
              />
            </ClearIconButton>
          </Tooltip>

          <Tooltip title={t("common.cleanFilters")}>
            <ClearIconButton
              size="small"
              aria-label="plot"
              onClick={handleClearDate}
            >
              <CleanIcon />
            </ClearIconButton>
          </Tooltip>
          
        </ContainerSearch>
      </Forms>
    </Toolbar>
  );
};


export const TableLogs = ({
  arrayLogs,
  setArrayLogs,
  rows,
  setRows,

  initialDate,
  setInitialDate,
  finalDate,
  setFinalDate,

  handleDatesFilter,
  handleClearDate,
}) => {

	const { t } = useTranslation();
  const { format } = new FormatDate()
  const { colors } = React.useContext(ColorsContext);

  const { order, orderBy, stableSort, getComparator, handleRequestSort } = useSortByColumn('createdAt');

  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          initialDate={initialDate}
          setInitialDate={setInitialDate}
          finalDate={finalDate}
          setFinalDate={setFinalDate}
          arrayLogs={arrayLogs}
          handleDatesFilter={handleDatesFilter}
          handleClearDate={handleClearDate}
        />

        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableContent>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                    rows.sort(getComparator(order, orderBy)).slice() */}
              {stableSort(arrayLogs, getComparator())
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow hover tabIndex={-1} key={row.id}>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ border: "none", fontSize: "0.75rem" }}
                      >
                        {format({ date: row.createdAt, format: true })}
                      </TableCell>
                      <TableCell sx={{ border: "none" }}>
                        {row.username}
                      </TableCell>
                      <TableCell sx={{ border: "none" }}>
                        {row.module}
                      </TableCell>
                      <TableCell sx={{ border: "none" }}>
                        {row.submodule}
                      </TableCell>
                      <StickyColumn direction='right'>
                        {row.action}
                      </StickyColumn>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableContent>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          showFirstButton
          showLastButton
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t("common.itemsPerPage")}
          labelDisplayedRows={({ from, to, count }) => {
            return "" + from + "-" + to + ` ${t("common.of")} ` + count;
          }}
          sx={{
            borderTop: "1px solid " + colors.gray,
          }}
        />
      </Paper>
    </Box>
  );
};
