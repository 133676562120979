import React from 'react';

// Components
import { Notification } from '../../notification';

// Styles
import { Container, Header, Body, Footer, SectionRight, BoxContainer, MenuNotification } from './styles'
import { FormButton } from '../../../../pages/styles';

// Utils
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { redirectToAction } from '../../../../utils/commonFunctions';

export const InstanceNotificationsModal = ({ notifications, instance, handleClose }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <Container>
            <Header>{t('workflow.detail.instanceNotifications')}</Header>
            <Body>
                <BoxContainer>
                    {notifications?.map((row) => (
                        <MenuNotification
                            key={row.id}
                            onClick={() => {
                                handleClose()
                                redirectToAction(instance, [row], navigate, location)
                            }}
                            isread={"true"}
                        >
                            <Notification
                                data={row} 
                                showFromInstance={true}
                            />
                        </MenuNotification>
                    ))}
                </BoxContainer>
            </Body>
            <Footer>
                <SectionRight>
                    <FormButton 
                        variant="outlined"
                        type="close" 
                        onClick={() => handleClose()}
                        >
                        {t('common.close')}
                    </FormButton>
                </SectionRight>
            </Footer>
        </Container>
    )
}