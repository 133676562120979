import React, { useEffect, useState } from 'react';

// Components
import Dropzone from 'react-dropzone'
import { ShowDialog } from '../ShowDialog';
import { Grid, IconButton, Tooltip } from '@mui/material';
import { toast } from 'react-toastify';

// Services
import { UserApi } from '../../services/UserApi';
import Router from '../../router';

// Icons
import { Cancel, Download } from '@mui/icons-material';

// Styles
import { Container, SectionFiles, CustomSpan, CustomTypography, Text, ContainerFiles } from './styles';

// Utils
import { ColorsContext } from "../../Context/ColorsContext"
import { CheckResponse } from '../../utils/checkResponse';
import { UrlToFile } from '../../utils/urlToFile';
import { useTranslation } from 'react-i18next'

// Permissions
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../Context/PermissionsContext';

export const AttachmentUsers = (props) => {
    const { id, disabled, onFilesDrop } = props
    const userApi = new UserApi();
    const {check}= new CheckResponse();
    const { t } = useTranslation();
    const { colors } = React.useContext(ColorsContext);
    const ability = useAbility(AbilityContext);

    const [files, setFiles] = useState([])
    const [newFiles, setNewFiles] = useState([])
    const [toDelete, setToDelete] = useState({})
    const [loading, setLoading] = useState(false)
    const ACCEPTED_FILES = {
        'image/jpeg': [], 
        'image/png': [],
        'application/pdf': [],
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': []
    }
    const [dialog, setDialog] = useState({
        status: false,
        title: '',
        message: ''
    })

    useEffect(() => {
        async function call() {
            if (id) await callAttachments(id)
        }
        call()
    }, [])

    async function callAttachments(id) {
        if (ability.can("UserAttachmentList")) {
            setLoading(true)
    
            let response = await userApi.getFile(id);
    
            let result = check(response)
    
            if (result.status) {
                setFiles(response.data)
            }
            else {
                toast.error(() => 
                    <div>
                        {result.errors}
                    </div>
                );
            } 
    
            setLoading(false)
        }
    }

    function deleteAttach(index, isDb) {
        let array = [...files]

        if (array[index]?.id && isDb) {
            deleteFromDb(array, index)
            return
        }

        if (array.length > 0) {
            array.splice(index, 1)
            setFiles(array)
            setNewFiles(array.filter((el) => !el.id))
            onFilesDrop(array.filter((el) => !el.id));
        }
    }

    function deleteFromDb(array, index) {
        setToDelete(array[index])
        setDialog({
            status: true,
            title: t('files.messages.deleteFile.title'),
            message: t('files.messages.deleteFile.message', {file: array[index].route}),
            type: 'confirmation',
            index: index
        })
    }
    
    async function deleteFile(file) {
        setLoading(true)

        let response = await userApi.deleteFile(file?.id);
        let result = check(response)

        if (result.status) {
            setDialog({
                status: true, isClose: true,
                title: t('files.messages.deleteFile.confirm'),
                message: t('files.messages.deleteFile.confirmMessage')
            })
            await callAttachments(id)
        }
        else {
            toast.error(() => 
                <div>
                    {result.errors}
                </div>
            );
        } 
    
        setLoading(false)
    }

    function onDrop(droppedFiles) {
        let array = [...droppedFiles, ...files];
        setFiles(array)
        setNewFiles(droppedFiles)

        // Llama a la función de retorno para pasar los archivos al componente padre
        if (onFilesDrop) {
            onFilesDrop(droppedFiles);
        }
    };

    function onDropRejected() {
        toast.error(t('files.messages.errorFile.invalidFormat'))
    };

    async function handleCloseDialog(value) {
        setDialog({status: false})
        if (value) {
            await deleteFile(toDelete)
            setToDelete({})
        }
    }

    async function downloadFile(file) {
        let downloadFile = file

        if (file.id) {
            let urlToFile = new UrlToFile();
            let urlResource = file.source?.startsWith("http") ? `${(file.source)}` : `${Router.apiBaseUrl}/${(file.source)}`
            downloadFile = await urlToFile.convert(urlResource, null, false)
        }

        const url = window.URL.createObjectURL(new Blob([downloadFile]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file.name || file.source);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    }

    return (
        <Container>
            <Dropzone onDrop={onDrop} onDropRejected={onDropRejected} disabled={disabled} accept={ACCEPTED_FILES}>
                {({getRootProps, getInputProps}) => (
                    <section className="container" style={{ padding: 0 }}>
                        <SectionFiles {...getRootProps({className: 'dropzone'})} disabled={disabled}>
                            <input {...getInputProps()} />
                            <CustomSpan>{t('files.filesDropDown')}</CustomSpan>
                        </SectionFiles>
                        <aside>
                            <Text>{t('files.title')}</Text>
                            <ContainerFiles sx={{ height: props.filesHeight ? props.filesHeight : '100px' }}>
                                {files.map((file, index) => (
                                    <li key={`${file.route || file.name}-${index}`}>
                                        <Grid container alignItems="center">
                                            {ability.can("UserAttachmentRemove") &&
                                                <Grid item>
                                                    <Tooltip title={t('files.remove')}>
                                                        <span>
                                                            <IconButton onClick={() => deleteAttach(index, file.id ? true : false)} disabled={disabled}>
                                                                <Cancel sx={{ color: colors.red }}/>
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                            }
                                            <Grid item>
                                                <CustomTypography>{file.route || file.name}</CustomTypography>
                                            </Grid>
                                            <Grid item>
                                                <Tooltip title={t('common.download')}>
                                                    <IconButton onClick={() => downloadFile(file)}>
                                                        <Download sx={{ color: colors.buttonActionColor }}/>
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    </li>
                                ))}
                            </ContainerFiles>
                        </aside>
                    </section>
                )}
            </Dropzone>
            <ShowDialog openDialog={dialog.status} dialog={dialog} handleCloseDialog={handleCloseDialog}/>
        </Container>
    )
}