import React from 'react';
import { Box, Checkbox, Divider, FormControl, FormControlLabel, Paper, Chip } from '@mui/material';
import { Input, CustomAutocomplete } from './styles';
import { useTranslation } from 'react-i18next'

export const SelectAll = ({
    name, label, placeholder, onChange, value, options, keyValue, hasError, messageError,
    disabled=false, size, width
}) => {
    
	const { t } = useTranslation();
    const [selectAll, setSelectAll] = React.useState(false);

    React.useEffect(() => {
        if (value.length === options.length) setSelectAll(true)
        else setSelectAll(false)
    }, [options])

    const handleSelectAll = () => {
        setSelectAll((prev) => {
            if (!prev) onChange([...options], name);
            else onChange([], name);
            return !prev;
        });
    };

    const isAnyOptionDisabled = options.some((opt) => opt.disabled);

    return (
        <FormControl fullWidth>
            <CustomAutocomplete
                id="combo-box-demo"
                disabled={disabled}
                disableClearable
                disableCloseOnSelect={true}
                defaultValue={value}
                value={value || null}
                options={options}
                getOptionLabel={(option) => keyValue ? option?.[keyValue] || "" : option}
                sx={{ width: width || '100%' }}
                multiple={true}
                getOptionDisabled={(opt) => opt.disabled}
                onChange={(_, value, reason) => {
                    if (reason === "clear" || reason === "removeOption")
                        setSelectAll(false);
                    if (reason === "selectOption" && value.length === options.length)
                        setSelectAll(true);
            
                    if (onChange !== undefined) onChange(value, name);
                }}
                renderInput={
                    (params) => <Input {...params} 
                        label={label} 
                        placeholder={placeholder}
                        error={hasError} 
                        helperText={messageError} 
                        size={size || "medium"}
                    />
                }
                PaperComponent={(paperProps) => {
                    const { children, ...restPaperProps } = paperProps;
                    return (
                        <Paper {...restPaperProps}>
                            <Box
                                onMouseDown={(e) => e.preventDefault()} // prevent blur
                                pl={1.5}
                                py={0.5}
                            >
                                <FormControlLabel
                                    label={t('common.selectAll')}
                                    onClick={(e) => {
                                        e.preventDefault(); // prevent blur
                                        if (!isAnyOptionDisabled) {
                                            handleSelectAll();
                                        }
                                    }}
                                    control={
                                        <Checkbox checked={selectAll}/>
                                    }
                                    disabled={isAnyOptionDisabled}
                                />
                            </Box>
                            <Divider />
                            {children}
                        </Paper>
                    );
                }}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                        const optionIndex = options.findIndex(item => item.id === option.id);
                        const label = optionIndex !== -1 ? options[optionIndex][keyValue] : '';

                        return (
                            <Chip
                                label={label}
                                {...getTagProps({ index })}
                                disabled={option.disabled || disabled || false }
                            />
                        )
                    })
                }
            />
        </FormControl>
    )
}

