import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import { useColors } from "../../Context/ColorsContext";
import { MediaQuerys } from "../../styles/GlobalStyle";

const { mobile, tablet, desktop } =  MediaQuerys;

export const CustomTypography = styled('p')(() => {
    return {
        fontSize: '1rem',
        textTransform:'uppercase',
        [desktop]: {
            fontSize: '0.85rem'
        }
      }
})

export const Content = styled('div')(() => {
    return {
        display: 'flex',
        flexDirection: 'row',
        gap: '0.50rem',
        width: '100%',
    }
})

export const ContainerText = styled('div')(() => {
    return {
        width: '80%'
    }
})

export const CustomButton = styled(Button)(({ isMobile }) => {
    const { colors } = useColors();
    return {
        background: colors.buttonActionColor,
        color: colors.white,
        textTransform: 'uppercase',
        height: '100%',
        width: '100%',
        maxWidth: '150px',
        fontSize: 13,
        padding: '7px 0',
        "&:hover": {
            backgroundColor: colors.buttonActionColor,
            opacity: 0.9,
            color: colors.white,
        },
        [desktop]: {
            height: '30px',
            fontSize: '0.75rem',
        },
        [mobile]: {
            display: isMobile ? 'flex' : null,
        }
    }
})

export const ContainerMTop = styled('div')(() => {
    return {
        [tablet]: {
            marginTop: '.5rem'
        }
    }
})