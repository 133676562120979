import { Auth } from "./auth";

export class UrlToFile {

    async convert(url, fileName, sendHeaders = true) {
        const { getToken } = new Auth();
        let fileArr = [];
        let filename = fileName ? fileName : "imageName.jpg"; 

        async function addImageProcess(url) {
            const headers = sendHeaders
            ? { 'Authorization': `Bearer ${getToken()}` }
            : {};
            const toDataURL = async (url) => await fetch(url, { headers })
                .then(response => response.blob())
                .then(blob => new Promise((resolve, reject) => {
                    const reader = new FileReader()
                    reader.onloadend = () => resolve(reader.result)
                    reader.onerror = reject
                    reader.readAsDataURL(blob)
            }))

            function dataURLtoFile(dataurl, filename) {
                let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
                while(n--){
                    u8arr[n] = bstr.charCodeAt(n);
                }
                return new File([u8arr], filename, {type:mime});
            }

            return await toDataURL(url).then(dataUrl => {
                let fileData = dataURLtoFile(dataUrl, filename);
                fileArr.push(fileData)
                fileArr.map(file => Object.assign(file, {
                    preview: URL.createObjectURL(file)
                }))
                return fileData;
            })
        }
        return await addImageProcess(url);
    }
}
