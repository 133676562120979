import { Box, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { CustomCell } from "../../../../styles/GlobalStyle";
import { CustomTableCell, FigureHead } from "./styles";
import { useTranslation } from 'react-i18next'

export function TableHeader(props) {
    const { t } = useTranslation(); 
    const { order, orderBy, onRequestSort, headers } = props;
    const createSortHandler = (property, property2) => (event) => {
        onRequestSort(event, property, property2);
    };
  
    return (
        <TableHead>
            <TableRow>
                {headers.map((headCell) => (
                    <CustomCell
                        key={headCell.id}
                        align={headCell.center ? 'center' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        dataHead={headCell}
                    >
                    {headCell.sortArrow === true &&
                        <TableSortLabel
                            sx={{ width: 'max-content'}}
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id, headCell.customSort)}
                        >
                            {t(headCell.label) || headCell.label}
                            {orderBy === headCell.id ? 
                                (<Box component="span"> {order === 'desc' ? '' : ''} </Box>) 
                            : 
                                null
                            }
                        </TableSortLabel>
                    }
                    {headCell.sortArrow === false &&
                        <Box component="span" sx={{ width: 'max-content'}}>
                            {t(headCell.label) || headCell.label}
                        </Box>
                    }
                    </CustomCell>
                ))}
            </TableRow>
        </TableHead >
    );
}