import React from 'react';
import { Button, Dialog, DialogActions, DialogTitle, FormControl } from '@mui/material';

// Styles
import { CustomDialogContent, Title } from './styles';
import { ColorsContext } from "../../Context/ColorsContext"

// Utils
import { useTranslation } from 'react-i18next'
import { FormButton } from '../../pages/styles';

export const ChildrenDialog = (props) => {
    const {openDialog, title, handleCloseDialog, children} = props;
    const { t } = useTranslation();
    const { colors } = React.useContext(ColorsContext);

    return (
        <Dialog
            open={openDialog}
            onClose={() => handleCloseDialog(false)}
            maxWidth="lg"
            fullWidth={true}
        >
            <DialogTitle>
                <Title ml={1}>
                    {title}
                </Title>
            </DialogTitle>
            <CustomDialogContent dividers>
                {children}
            </CustomDialogContent>
            <DialogActions>
                <FormButton
                    onClick={() => handleCloseDialog(false)}
                >
                   {t('common.close')}
                </FormButton>
            </DialogActions>
        </Dialog>
    )
}
