import React, { useEffect, useState } from 'react';

// Components
import { toast } from 'react-toastify';

// Custom components
import { PageTitle } from '../../components/PageTitle';
import { SearchBar } from '../../components/atoms/searchBars/SearchBar';
import TableModel from '../../components/atoms/tables/TableModel';
import { ShowDialog } from '../../components/ShowDialog';
import { BackdropLoading } from '../../components/BackdropLoading';
import { Tooltip } from "@mui/material";
import { IconAction, NewIcon, ContainersButtonsGroup } from './styles';


// Services
import { FormatDate } from '../../utils/formatDate';
import { ListApi } from '../../services/ListApi';
import Router from '../../router';
import { useNavigate } from 'react-router-dom'

// Styles
import { Container, ContainerBarSearch, ContainerButtonDownload, SectionPageLeft, SectionPageTitle } from '../styles';

// Icons
import { List, CloudDownloadOutlined } from '@mui/icons-material';

// Utils
import { CheckResponse } from '../../utils/checkResponse';
import { useTranslation } from 'react-i18next';
import { ColorsContext } from "../../Context/ColorsContext"

//MUI Material 
import { Button } from '@mui/material';

// Hook
import { useDownloadExcel } from '../../Hooks/useDownloadExcel';

// Permissions
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../Context/PermissionsContext';

export const Lists = (props) => {
	const { t } = useTranslation();
	const { colors } = React.useContext(ColorsContext);
    const ability = useAbility(AbilityContext);

	const listApi = new ListApi();
	const { check } = new CheckResponse();
	const { format } = new FormatDate();
    const navigate = useNavigate();
	
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
	
    const [filteredRequest, setFilteredRequest] = useState([])
	const [dialog, setDialog] = useState({
		status: false,
		title: '',
		message: '',
		type: null
	})
  
    const [headers, setHeaders] = useState([]);

	//?? Prepare items-table config excel 
	const fieldConfig = {
		[t('lists.table.id')]: 'id',
		[t('lists.table.listName')]: 'name',
		[t('lists.table.integrationKey')]: 'integrationKey',
		[t('lists.table.lastUpdate')]: (item) => format({ date: item.updatedAt, format: true }).toString(),
	};

	//?? CustomHook Download Excel
	const { handleDownloadExcelList } = useDownloadExcel(filteredRequest, fieldConfig, t("lists.title"));


	useEffect(() => {
        returnHeaders();
        
		async function call() {
			await loadData()
		}
		call()
	}, [])

    const returnHeaders = () => {
        let heads = [
            {
                id: 'id',
                label: 'lists.table.id',
                center: false,
                disablePadding: true,
                sortArrow: true,
                isRender: true,
                sticky: true,
                direction: 'left',
            },
            {
                id: 'name',
                label: 'lists.table.listName',
                center: false,
                disablePadding: true,
                sortArrow: true,
                isRender: true,
            },
            {
                id: 'integrationKey',
                label: 'lists.table.integrationKey',
                center: false,
                disablePadding: true,
                sortArrow: true,
                isRender: true,
            },
            {
                id: 'updatedAt',
                label: 'lists.table.lastUpdate',
                center: false,
                disablePadding: true,
                sortArrow: true,
                type: 'date',
            }
        ];
  
        let newActions = [];
        if (ability.can("UpdateList")) {
            newActions.push('edit');
        }
        if (ability.can("DeleteList")) {
            newActions.push('delete');
        }
    
        if (newActions.length > 0) {
            heads.push(addActionsRow(newActions))
        }
    
        setHeaders(heads);
    }
  
    function addActionsRow(actions) {
      return {
            id: 'actions',
            label: 'common.actions.title',
            center: false,
            disablePadding: false,
            sortArrow: false,
            type: 'actions',
            sticky: true,
            direction: 'right',
            keys: actions
        }
    }

	async function loadData() {
		setLoading(true);
		let response = await listApi.list();
		if (response.status === 200) {
			setData(response.data);
		}
		setLoading(false);
		return response.data || [];
	}

	async function handleCreateNew() {
		navigate(Router.appListDetail)
	}

	async function handleEdit(row) {
		navigate(Router.appListDetail, { state: { id: row.id } })
	}

	async function handleDelete(id) {
		setLoading(true)
		let response = await listApi.delete(id);
		let result = check(response)

		if (result.status) {
			setDialog({
				status: true,
				title: t('lists.messages.deletedList.title'),
				message: t('lists.messages.deletedList.message'),
				type: null,
			})
			await loadData();
		}
		else {
			toast.error(() =>
				<div>{t('lists.messages.deletedList.error')}<br />
					{result.errors}
				</div>
			);
		}

		setLoading(false)
	}

	async function handleOpenDialog(row) {
		setDialog({
			status: true,
			title: t('lists.messages.deletedList.confirm'),
			message: t('lists.messages.deletedList.confirmMessage', { list: row.name }),
			type: 'confirmation',
			id: row.id,
		})
	}

	async function handleCloseDialog(value) {
		if (value) {
			setDialog({ ...dialog, status: false })
			await handleDelete(dialog.id);
		}
		else {
			setDialog({ status: false })
		}
	}

	return (
		<Container open={props.open}>
			<SectionPageTitle>
				<SectionPageLeft>
					<PageTitle title={t('lists.title')} icon={<List />} />
					<ContainerBarSearch>
                        <SearchBar 
                            rows={data} 
                            setFilteredRequest={setFilteredRequest}
                            filterText={t('common.searchBars.lists')}
                            filterKeys={['id', 'name', 'integrationKey']}
                        />
					</ContainerBarSearch>
				</SectionPageLeft>

				<ContainersButtonsGroup>
					{handleCreateNew && (
						<Tooltip title={t('lists.createNew')}>
								<IconAction size="medium" onClick={handleCreateNew}>
									<NewIcon />
								</IconAction>
						</Tooltip>
					)}
					<ContainerButtonDownload>
						<Button
							variant="contained"
							endIcon={<CloudDownloadOutlined />}
							sx={{
								height: '100%',
								width: '100%',
								padding: "0 20px",
								backgroundColor: colors.buttonActionColor,
								color: colors.white,
								"&:hover": {
									backgroundColor: colors.buttonActionColor,
									opacity: 0.9,
									color: colors.white
								}
							}}
							aria-label="download"
							onClick={handleDownloadExcelList}
						>
							{t('common.download')}
						</Button>

					</ContainerButtonDownload>
				</ContainersButtonsGroup>

			</SectionPageTitle>

			<TableModel
                headers={headers}
                filteredRequest={filteredRequest}
                rows={data}
                handleCreateNew={ability.can("CreateList") ? handleCreateNew : null}
                labelCreateNew={t('lists.createNew')}
                handleEdit={handleEdit}
                handleDelete={handleOpenDialog}
                isList={true}
            />

			<BackdropLoading open={loading} />
			<ShowDialog openDialog={dialog.status} dialog={dialog} handleCloseDialog={handleCloseDialog} />
		</Container >
	)
}
