import {styled} from "@mui/material/styles";
import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { useColors } from "../../Context/ColorsContext";
import { MediaQuerys } from "../../styles/GlobalStyle";
import { FormControlLabel } from '@mui/material';

const { mobile, tablet, desktop } = MediaQuerys;

export const SectionFiles = styled('section')(({disabled}) => {
    const { colors } = useColors();
    return {
        display: 'flex',
        justifyContent: 'center',
        height: 50,
        alignItems: 'center',
        marginTop: 0,
        border: `1px dashed ${colors.grayFont}`,
        borderRadius: 8,
        padding: 10,
        color: disabled ? colors.disabled : colors.blackFont,
        cursor: disabled ? 'not-allowed' : 'pointer'
    }
})

export const Container = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0px 16px'
}))

export const CustomSpan = styled('span')(() => {
    return {
        [desktop]: {
            fontSize: '0.90rem',
        }
    }
})

export const CustomTypography = styled(Typography)(() => {
    return {
        margin: '0 0.3rem',
        [desktop]: {
            fontSize: '0.90rem',
        }
    }
})

export const ContainerLeft = styled('div')(() => {
    return {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexDirection: 'row',
        gap: '0.5rem',
        margin: '0 0.3rem',
        height: '100%',
        width: '100%',
    }
})

export const Text = styled('span')(({ isCode, needMarginLeft }) => ({
    display: 'block',
    wordWrap: 'break-word',
    whiteSpace: 'normal',
    marginLeft: needMarginLeft ? '0.3rem' : null,
    fontSize: isCode ? '0.8rem' : null,
    fontWeight: isCode ? 'bold' : null,
    [mobile]: {
        fontSize: '0.75rem',
    }
}))

export const CustomGrid = styled(Grid)(() => {
    return {
        fontSize: 14,
        border: '1px solid #ccc',
        '> .MuiGrid-item': {
            padding: 4,
            paddingLeft: 6,
            border: '1px solid #ccc',
        }
    }
})

export const StickyHeader = styled(Grid)(() => {
    const { colors } = useColors();
    return {
        position: "sticky",
        top: 0,
        height: 'auto',
        color: colors.grayFont,
        backgroundColor: colors.gray, // Customize this background color as needed
        zIndex: 1, // Ensure the header stays above the content when scrolling
        fontWeight: 'bold',
        fontSize: '14px',
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center'
    };
});

export const CustomControlLabel = styled(FormControlLabel)(() => {
    return {
      [desktop]: {
        '& .MuiTypography-root': {
          fontSize: '0.85rem',
        }
      }
    }
  })