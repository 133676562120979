import React from 'react';

// Components
import Chart from "react-apexcharts";


export const CardGraphic = ({ type, options, series, chartRef, height, width }) => {

    return (
        <Chart
            options={options || {}}
            series={series || []}
            type={type || 'bar'}
            width={width || '100%'}
            height={height ||'300'}
            ref={chartRef}
        />
    )
}

