import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';

export const BackdropLoading = ({open}) => {

    return (
        <Backdrop
            sx={{ zIndex: (theme) => theme.zIndex.drawer * 2, background: 'rgba(0, 0, 0, 0.3)' }}
            open={open}
        >
            <CircularProgress color="info" size={80}/>
        </Backdrop>
    )
}

