import React from 'react'

// Components
import { TextField, FormControlLabel } from '@mui/material'

// Styles
import { Label, ContainerInput, ContainerGroup, ContainerContent, ContainerBox, CustomSwitch, TextSwitch } from './styles'

// Utils
import { useTranslation } from 'react-i18next'

export const Company = ({
    isNew, form, errors, disabled, handleChange, handleState
}) => {
    const { t } = useTranslation();

    return (
        <ContainerBox>
            <ContainerContent>

                <ContainerGroup isFirsts>
                    <ContainerInput>
                        <Label>{t('company.table.nit')}:</Label>
                        <TextField
                            type='number'
                            value={form.nit}
                            size="small"
                            onChange={e => handleChange(e, 'nit')}
                            error={!(!errors?.nit.error)}
                            helperText={errors?.nit.error}
                            style={{ width: '100%' }}
                            disabled={disabled}
                            required
                        />
                    </ContainerInput>

                    <ContainerInput>
                        <Label>{t('company.table.dv')}:</Label>
                        <TextField
                            type='number'
                            value={form.dv}
                            size="small"
                            onChange={e => handleChange(e, 'dv', 3)}
                            error={!(!errors?.dv.error)}
                            helperText={errors?.dv.error}
                            style={{ width: '100%' }}
                            disabled={disabled}
                            required
                        />
                    </ContainerInput>

                    {!isNew && 
                        <FormControlLabel
                            disabled={disabled}
                            control={
                                <CustomSwitch
                                    checked={form.isActive}
                                    onChange={() => handleState(!form.isActive)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                            label={
                                <TextSwitch enabled={form.isActive}>{form.isActive ? t('system.active') : t('system.inactive')}</TextSwitch>
                            } 
                        />
                    }
                    
                </ContainerGroup>
                <ContainerGroup>
                    <ContainerInput isFull>
                        <Label>{t('company.table.name')}:</Label>
                        <TextField
                            value={form.name}
                            size="small"
                            onChange={e => handleChange(e, 'name')}
                            error={!(!errors?.name.error)}
                            helperText={errors?.name.error}
                            style={{ width: '100%' }}
                            disabled={disabled}
                            required
                        />
                    </ContainerInput>
                </ContainerGroup>

            </ContainerContent>
        </ContainerBox>
    )
}