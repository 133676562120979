// Components MUI
import { InputLabel, MenuItem, ListItemText, Select, Checkbox, OutlinedInput } from '@mui/material';
// Styles
import { CustomFormControl } from './style';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export const MultiSelectField = ({ label, selectLabelAll, typeArea, selectedTypeArea, handleChangeTypeAreas, selectAll }) => {

    return (
        <CustomFormControl size='small'>
            <InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel>
            <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={selectedTypeArea}
                onChange={handleChangeTypeAreas}
                input={<OutlinedInput label={label} />}
                renderValue={(selected) =>
                    selected
                        .map(id => typeArea.find(item => item.id === id)?.name)
                        .filter(Boolean)
                        .join(', ')
                }
                MenuProps={MenuProps}
            >
                <MenuItem value="select-all">
                    <Checkbox
                        checked={selectAll}
                        indeterminate={selectedTypeArea.length > 0 && selectedTypeArea.length < typeArea.length}
                    />
                    <ListItemText primary={selectLabelAll} />
                </MenuItem>
                {typeArea.map((el) => (
                    <MenuItem key={el.id} value={el.id}>
                        <Checkbox checked={selectedTypeArea.includes(el.id)} />
                        <ListItemText primary={el.name} />
                    </MenuItem>
                ))}
            </Select>
        </CustomFormControl>
    );
}
