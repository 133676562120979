import {styled} from "@mui/material/styles";
import { Button, Grid, Typography } from "@mui/material";
import HandymanRoundedIcon from '@mui/icons-material/HandymanRounded';
import { useColors } from "../../Context/ColorsContext"

export const Content = styled(Grid)(() => {
    const { colors } = useColors();    
    return {
        background: colors.main,
        width: '100%',
        height: '100%'
    }
})

export const Container = styled(Grid)(() => ({
    direction: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
}))

export const Text = styled(Typography)(() => {
    const { colors } = useColors();    
    return {
        color: colors.white,
        fontSize: 26,
        margin: 0,
        padding: 0,
    }
})

export const HandymanIcon = styled(HandymanRoundedIcon)(() => {
    const { colors } = useColors();    
    return {
        color: colors.white,
        fontSize: 200,
        background: '#0a4d7d',
        borderRadius: '50%',
        padding: 20,
        marginBottom: 30
    }
})

export const CustomButton = styled(Button)(() => {
    const { colors } = useColors();    
    return {
        marginTop: 20,
        background: '#0a4d7d',
        fontSize: 18,
        textTransform: 'none'
    }
})
