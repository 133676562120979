import { useState, useEffect } from "react";

export const useSortByColumn = ({ defaultSort }) => {

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [customSort, setCustomSort] = useState("");

  useEffect(() => {
    if (defaultSort) {
      handleRequestSort(null, defaultSort, null, "desc");
    }
  }, [defaultSort]);

  const descendingComparator = (a, b) => {  
    let cond1 = b[orderBy] < a[orderBy];
    let cond2 = b[orderBy] > a[orderBy];

    if (customSort) {
      cond1 = eval(`b.${customSort}`) < eval(`a.${customSort}`);
      cond2 = eval(`b.${customSort}`) > eval(`a.${customSort}`);
      if (eval(`a.${customSort}`) === null) return 1;
      if (eval(`b.${customSort}`) === null) return -1;
    }

    if (cond1) return -1;
    if (cond2) return 1;

    return 0;
  };

  const getComparator = () => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy, customSort)
      : (a, b) => -descendingComparator(a, b, orderBy, customSort);
  };

  const stableSort = (data, comparator, preventOrderBy) => {
    if (preventOrderBy) return data;
    const stabilizedThis = data?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;

      return a[1] - b[1];
    });

    return stabilizedThis.map((el) => el[0]);
  };

  const handleRequestSort = (event, property, property2, defaultOrder) => {
    const isAsc = orderBy === property && order === "asc";
    if (defaultOrder) {
      setOrder(defaultOrder);
    } else {
      setOrder(isAsc ? "desc" : "asc");
    }
    setOrderBy(property);
    setCustomSort(property2);
  };

  return {
    order,
    orderBy,

    //?? Functions
    getComparator,
    stableSort,
    handleRequestSort,
  };
};
