
import React, { Fragment } from 'react'

// Custom components
import { SelectInput } from '../../../SelectInput'
import { Grid, Tooltip, Typography } from '@mui/material'

// Styles
import {
  Area, Container, ErrorText, Form, GridCenter, IconArea, Image, ImageContainer, InfoContainer,
  InfoText,
  TextInput,
  UploadButton,
  ContainerLeft,
  ContainerRight,
  ContainerInput,
  ContainerButtons,
  CustomTypography,
  ContainerImage,
  ContainerImageEdit
} from './styles';

import { CustomIconButton, FormButton } from '../../../../pages/styles';

// Icons
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { ImageNotSupported, Info, Visibility } from '@mui/icons-material';

// Utils
import { ColorsContext } from "../../../../Context/ColorsContext"
import { useTranslation } from 'react-i18next'

export const PlotPlanForm = ({
  plotPlanRef,
  form,
  errors,
  plotPlans,
  categories,
  areaTypes,
  handleChange,
  handleClickImage,
  handleSubmit,
  handleBack,
  loadingChildren,
  avoidEnterKey,
}) => {
  const { t } = useTranslation();
  const { colors } = React.useContext(ColorsContext);

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <ContainerLeft>
          <ContainerInput>
            <CustomTypography>{t('plotPlans.plotPlanForm.name')}:</CustomTypography>
            <TextInput
              name='name'
              value={form.name}
              size="small"
              onChange={e => handleChange(e, 'name')}
              onKeyDown={avoidEnterKey}
              error={!(!errors.name.error)}
              helperText={errors.name.error}
              required
            />
          </ContainerInput>

          <ContainerInput>
            <CustomTypography>{t('plotPlans.plotPlanForm.image')}:</CustomTypography>
            <UploadButton component="label" startIcon={<CloudUploadIcon />} sx={{ color: colors.buttonActionColor }}>
              {t('plotPlans.plotPlanForm.uploadImage')}
              <input type="file" accept=".jpeg, .jpg, .png" hidden onChange={e => handleChange(e, 'image')} />
            </UploadButton>
            <span style={{ marginLeft: 10, fontSize: 14 }}>{form.image}</span>
            {errors.image.error && <ErrorText>{errors.image.error}</ErrorText>}
          </ContainerInput>

          <ContainerInput>
            <CustomTypography>{t('plotPlans.plotPlanForm.parentNode')}:</CustomTypography>
            <SelectInput
              name='parent'
              onChange={e => handleChange(e, 'parent')}
              avoidEnterKey={true}
              value={form.parent}
              keyValue="nodePath"
              options={plotPlans}
              error={errors.parent.error}
              size="small"
              width="100%"
              disabled={form.isEdit}
            />
          </ContainerInput>

          {form.parentNodeLevel >= 1 &&
            <ContainerInput>
              <CustomTypography>{t('plotPlans.plotPlanForm.areaType')}:</CustomTypography>
              <SelectInput
                name='areaType'
                onChange={e => {
                  handleChange({target: {value: e, type: 'text'}}, 'areaType')
                }}
                avoidEnterKey={true}
                value={form.areaType}
                keyValue="name"
                options={areaTypes}
                error={errors.areaType?.error}
                size="small"
                width="100%"
              />
            </ContainerInput>
          }

          {form.parent?.id === 1 &&
						<>
							<ContainerInput>
								<CustomTypography>{t('plotPlans.plotPlanForm.category')}:</CustomTypography>
								<SelectInput
									name='category'
									onChange={e => {
										handleChange({target: {value: e, type: 'text'}}, 'category')
									}}
                  avoidEnterKey={avoidEnterKey}
									value={form.category}
									keyValue="name"
									options={categories}
									error={errors.category.error}
									size="small"
									width="100%"
								/>
							</ContainerInput>
							<ContainerInput>
								<CustomTypography>{t('plotPlans.plotPlanForm.email')}:</CustomTypography>
								<TextInput
									value={form.email}
									size="small"
									type="email"
									onChange={e => handleChange(e, 'email')}
                  onKeyDown={avoidEnterKey}
									error={!(!errors.email.error)}
									helperText={errors.email.error}
								/>
							</ContainerInput>
						</>
          }

          <ContainerInput>
            <CustomTypography>{t('plotPlans.plotPlanForm.xPosition')}:</CustomTypography>
            <TextInput
              value={form.parentX || 0}
              size="small"
              type="number"
              onChange={e => handleChange(e, 'parentX')}
              onKeyDown={avoidEnterKey}
              error={!(!errors.parentX.error)}
              helperText={errors.parentX.error}
              required
              disabled
            />
          </ContainerInput>

          <ContainerInput>
            <CustomTypography>{t('plotPlans.plotPlanForm.yPosition')}:</CustomTypography>
            <TextInput
              value={form.parentY || 0}
              size="small"
              type="number"
              onChange={e => handleChange(e, 'parentY')}
              onKeyDown={avoidEnterKey}
              error={!(!errors.parentY.error)}
              helperText={errors.parentY.error}
              required
              disabled
            />
          </ContainerInput>

          <ContainerInput>
            <CustomTypography>{t('plotPlans.height')}:</CustomTypography>
            <TextInput
              value={form.height || 0}
              size="small"
              type="number"
              onChange={e => handleChange(e, 'height')}
              onKeyDown={avoidEnterKey}
              error={!(!errors.height.error)}
              helperText={errors.height.error}
              required
            />
          </ContainerInput>

          <ContainerButtons>
            <Grid item xs={6}>
              <FormButton
                variant="outlined"
                type="close"
                // sx={{ width: '50%' }} 
                onClick={handleBack}>
                {t('common.cancel')}
              </FormButton>
            </Grid>
            <Grid item xs={6}>
              <FormButton
                variant="contained"
                sx={{ color: colors.white }}
                onClick={handleSubmit}>
                {t('common.save')}
              </FormButton>
            </Grid>
          </ContainerButtons>
        </ContainerLeft>

        <ContainerRight>
          {!loadingChildren &&
            <ContainerImage id="CONTAINER IMAGE ">
              {form.parentImg &&
                <InfoContainer>
                  <Info sx={{ color: colors.main }} />
                  <InfoText>{t('plotPlans.plotPlanForm.locateNode')}</InfoText>
                </InfoContainer>
              }
              <ImageContainer>
                {
                  !form.parentImg ?
                    <GridCenter container direction="column">
                      <ImageNotSupported sx={{ color: colors.grayFont, width: '70px', height: '70px' }} />
                      <CustomTypography>{t('common.empty.noImage')}</CustomTypography>
                    </GridCenter>
                    :
                    <Fragment>
                      <Image
                        id="img-plot"
                        src={form.parentImg}
                        onClick={handleClickImage}
                        ref={plotPlanRef}
                      />
                      {form.children?.map((obj, index) => (
                        <Area
                          key={"child-area-" + index}
                          top={obj.coordY}
                          left={obj.coordX}
                          zIndex={obj.height}
                        >
                          {obj.element &&
                            <Tooltip title={
                              <span>{obj.name}<br></br>
                                {t('plotPlans.height')}: {obj.height}m</span>
                            }>
                              <IconArea selected={obj.isEdit && (obj.id === form.id)}>{obj.element}</IconArea>
                            </Tooltip>
                          }
                        </Area>
                      ))}
                    </Fragment>
                }
              </ImageContainer>
            </ContainerImage>
          }
        </ContainerRight>
      </Form>
    </Container>
  )
}
