import React, { useEffect, useRef, useState } from 'react';

// Custom components
import { Form } from '@formio/react';
import { PageTitle } from '../../components/PageTitle';
import { BackdropLoading } from '../../components/BackdropLoading';

// Styles
import { Container, PdfIconButton, PdfDownloadIcon } from '../styles';
import { FormContainer, SectionPageTitle, Text, Title, ContainerContent, ContainerTitle} from './styles';

// API
import { FormApi } from '../../services/FormApi';

// Router
import { useLocation } from 'react-router-dom'
import Router from '../../router';

// Icons
import { ViewList, ArrowBack } from '@mui/icons-material';
import { Tooltip } from '@mui/material';

// Utils
import { useTranslation } from 'react-i18next'
import { downloadPDF } from '../../utils/commonFunctions/downloadPdf';
import { FormIo } from '../../components/molecules/forms/FormIo';

export const FormManagementDetailV2 = (props) => {
    const { t } = useTranslation();
    const formApi = new FormApi();
    const location = useLocation();
	const [row, setRow] = useState({id: null, name: ''})
	const [form, setForm] = useState({structure: []})
	const [formStructure, setFormStructure] = useState([])
	const formRef = useRef(null);
	const [loading, setLoading] = useState(false)
	const [loadingPdf, setLoadingPdf] = useState(false)

    useEffect(() => {
		let row = location?.state?.row;

		if (row?.id) {
			setRow(row)
	
			async function call() {
				setLoading(true)
				const response = await formApi.detail(row.id);
				if (response.status === 200) {
					setForm(response.data)

					let dataStructure = response.data?.structure?.structure
					setFormStructure({ components: dataStructure })
				}
				setLoading(false)
			}
			call()
		}
    }, [props.isDemo])

	const generatePdf = async() => {
		await downloadPDF(form.structure?.structure, formRef, form.structure?.form?.name || 'form_pdf', setLoadingPdf)
	}

    return (
        <Container open={props.open} mb={6}>
			<SectionPageTitle>
				<PageTitle title="" isbutton={true} navigate={Router.appFormManagementV2} icon={<ArrowBack />} />
				<PageTitle title={t('forms.formManagement.formDetail') + ' ' + row?.id} icon={<ViewList/>} />
			</SectionPageTitle>
            {!loading &&
                <FormContainer>
                    <ContainerContent>
                        <ContainerTitle>
                            <Title>{t('forms.formManagement.formName')}:</Title> <Text>{form.name}</Text>
                        </ContainerTitle>
                        <ContainerTitle>
                            <Title>{t('forms.formManagement.workflow')}:</Title><Text>{form.workflow?.name}</Text>
                        </ContainerTitle>
                        <ContainerTitle>
                            <Title>{t('forms.formManagement.integrationKey')}:</Title><Text>{form.integrationKey}</Text>
                        </ContainerTitle>
                        <ContainerTitle>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', width: '100%' }}>
                                <Tooltip title={t("common.downloadPdf")}>
                                    <PdfDownloadIcon onClick={generatePdf}/>
                                </Tooltip>
                            </div>
                        </ContainerTitle>
                    </ContainerContent>
                    <div ref={formRef}>
                        <FormIo 
                            form={formStructure.components}
                            data={{}}
                            readOnly={false}
                        />
                    </div>
                </FormContainer>
            }
            <BackdropLoading open={loading}/>
            <BackdropLoading open={loadingPdf}/>
		</Container>
	)
}
