import React from 'react';
import { Card, Divider, Grid } from '@mui/material';
import { Content, ContentTitle, IconArea, ListAlt, Title, CustomCard } from './styles';

import { WorkPermitCardArea } from '../atoms/workPermitCardArea';
import { useTranslation } from 'react-i18next'

export const CardArea = ({area, index, selectArea, selectedItem}) => {
    const { t } = useTranslation();

    return (
        <CustomCard index={index}>
            <ContentTitle container alignItems="center" justifyContent="center">
                <Grid item xs={2}>
                    <IconArea>{area.element}</IconArea>
                </Grid>
                <Grid item xs={8}>
                    <Title>{area.name}</Title>
                </Grid>
                <Grid item xs={2}>
                    <ListAlt/>
                </Grid>
            </ContentTitle>
            <Divider/>
            {area.workflowDatas?.length > 0 ? 
                <WorkPermitCardArea 
                    data={area.workflowDatas} 
                    area={area} 
                    index={index} 
                    selectArea={selectArea}
                    selectedItem={selectedItem}
                />
            :
                <Content>
                    <Title sx={{ textAlign: 'center', margin: 'auto' }}>{t('common.empty.noData')}</Title>
                </Content>
            }
        </CustomCard>
    )
}

