import {styled} from "@mui/material/styles";
import { CardContent, Typography } from '@mui/material';
import { useColors } from "../../../Context/ColorsContext"

export const Title = styled(Typography)(() => {
    const { colors } = useColors();
    return {
        color: colors.grayFont,
        fontSize: 12,
        lineHeight: 1.2
    }
})

export const Content = styled(CardContent)(() => ({
    padding: '10px 10px 6px 15px'
}))

export const CustomDivider = styled('div')(() => ({
    ':not(:last-child)': { 
        borderBottom: '1px solid #f0f1f1 !important',
    }
}))

export const CustomLink = styled('span')(() => {
    const { colors } = useColors();
    return {
        cursor: 'pointer',
        color: colors.detailTextColor,
        fontWeight: 'bold',
        fontSize: 12,
        lineHeight: 1.2
    }
})