import React, { useEffect } from 'react';

// Components
import { Grid } from '@mui/material';

// Router
import { useNavigate } from 'react-router-dom';
import Router from '../../router';

// API
import { HealthCheckApi } from '../../services/HealthCheckApi';

// Styles
import { Content, HandymanIcon, Text, CustomButton, Container } from './styles';

// Utils
import { useTranslation } from 'react-i18next'
import { LanguageContainer } from '../styles';
import { LanguageSelect } from '../../components/atoms/languageSelect';

export const Maintenance = () => {
    const { t } = useTranslation();
    const {check} = new HealthCheckApi();
    const navigate = useNavigate();
	const MINUTE_MS = 30000;

    useEffect(() => {
		const interval = setInterval(async() => {
			await refresh();
		}, MINUTE_MS);

        return () => {
            clearInterval(interval);
        };
	}, []);

    async function refresh() {
        let response = await check();
        if (response.status === 200) {
            navigate(Router.appMyWork);
        }
    }

    return (
        <>
        <Content container>
            <Grid item xs={12}>
                <LanguageContainer><LanguageSelect/></LanguageContainer>
            </Grid>
            <Container item xs={12}>
                <HandymanIcon/>
                <Text>{t('maintenance.outOfService')}</Text>
                <Text>{t('maintenance.message1')}</Text>
                <Text>{t('maintenance.message2')}</Text>
                <CustomButton variant='contained' onClick={refresh}>{t('maintenance.check')}</CustomButton>
            </Container>
        </Content>
        </>
    )
}

