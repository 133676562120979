import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ButtonContainer, CustomButton } from './style';
import { useTranslation } from 'react-i18next';
import { CheckBox, DisabledByDefault } from '@mui/icons-material';
import { ColorsContext } from '../../../Context/ColorsContext';
import { Tooltip } from '@mui/material';

const StyledMenu = styled((props) => (
	<Menu
		elevation={0}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'right',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'right',
		}}
		{...props}
	/>
	))(({ theme }) => ({
	'& .MuiPaper-root': {
		borderRadius: 6,
		marginTop: theme.spacing(1),
		minWidth: 180,
		color:
		theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
		boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
		'& .MuiMenu-list': {
			padding: '4px 0',
		},
		'& .MuiMenuItem-root': {
			'& .MuiSvgIcon-root': {
				fontSize: 18,
				color: theme.palette.text.secondary,
				marginRight: theme.spacing(1.5),
			},
			'&:active': {
				backgroundColor: alpha(
					theme.palette.primary.main,
					theme.palette.action.selectedOpacity,
				),
			},
		},
	},
}));

export default function CustomMenu({ tooltip, title, row, disabled, handleSelected }) {
	const { t } = useTranslation()
	const { colors } = React.useContext(ColorsContext);

	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = (value, action) => {
		setAnchorEl(null);
		if (action === 'clicked') {
			handleSelected(value, row)
		}
	};

	return (
		<ButtonContainer>
			<Tooltip title={tooltip} sx={{ display: open ? 'none' : 'inherit' }}>
				<span>
					<CustomButton
						id="demo-customized-button"
						aria-controls={open ? 'demo-customized-menu' : undefined}
						aria-haspopup="true"
						aria-expanded={open ? 'true' : undefined}
						variant="contained"
						disableElevation
						disabled={disabled}
						onClick={handleClick}
						endIcon={<KeyboardArrowDownIcon />}
					>
						{title}
					</CustomButton>
					<StyledMenu
						id="demo-customized-menu"
						MenuListProps={{
							'aria-labelledby': 'demo-customized-button',
						}}
						anchorEl={anchorEl}
						open={open}
						onClose={handleClose}
					>
						<MenuItem onClick={() => handleClose(true, 'clicked')} disableRipple>
							<CheckBox sx={{ color: colors.buttonActionColor, marginRight: 1 }}/>
							{t('common.approve')}
						</MenuItem>
						<MenuItem onClick={() => handleClose(false, 'clicked')} disableRipple>
							<DisabledByDefault sx={{ color: colors.buttonActionColor, marginRight: 1 }}/>
							{t('common.reject')}
						</MenuItem>
					</StyledMenu>
				</span>
			</Tooltip>
		</ButtonContainer>
	);
}
